/**
 * const string SET_MAINTENANCE_MESSAGE_DEVICE: schedule new maintenance message on
 * device level POST / device/{deviceId}
 */
export const SET_MAINTENANCE_MESSAGE_DEVICE = 'SET_MAINTENANCE_MESSAGE_DEVICE';
/**
 * const string SET_MAINTENANCE_MESSAGE_GROUP: schedule new maintenance message on
 * group level POST /group/{groupId}
 */
export const SET_MAINTENANCE_MESSAGE_GROUP = 'SET_MAINTENANCE_MESSAGE_GROUP';

/**
 * const string GET_MAINTENANCE_MESSAGE_BY_DEVICE: get schedule maintenance messages
 * on device level GET /device/{deviceId}
 */
export const GET_MAINTENANCE_MESSAGES_BY_DEVICE =
  'GET_MAINTENANCE_MESSAGES_BY_DEVICE';

/**
 * const string GET_MAINTENANCE_MESSAGE_BY_GROUP: get scheduled maintenance message
 * on group level GET /group/{groupId}
 */
export const GET_MAINTENANCE_MESSAGE_BY_GROUP =
  'GET_MAINTENANCE_MESSAGE_BY_GROUP';

/**
 * const string UPDATE_MAINTENANCE_MESSAGE_DEVICE: update an existing maintenance message
 * on device level PUT /{deviceId}/{messageId}
 */
export const UPDATE_MAINTENANCE_MESSAGE_DEVICE =
  'UPDATE_MAINTENANCE_MESSAGE_DEVICE';

/**
 * const string UPDATE_MAINTENANCE_MESSAGE_GROUP: Update an existing maintenance message
 * on group level PUT /group/{groupId}/{messageId}
 */
export const UPDATE_MAINTENANCE_MESSAGE_GROUP =
  'UPDATE_MAINTENANCE_MESSAGE_GROUP';

/**
 * const string DELETE_MAINTENANCE_MESSAGE_BY_DEVICE: remove schedule maintenance
 * message on device level DELETE /device/{deviceId}/{messageId}
 */
export const DELETE_MAINTENANCE_MESSAGE_BY_DEVICE =
  'DELETE_MAINTENANCE_MESSAGE_BY_DEVICE';

/**
 * const string DELETE_MAINTENANCE_MESSAGE_BY_GROUP: remove scheduled maintenance
 * message on group level DELETE /group/{groupId}/{messageId}
 */
export const DELETE_MAINTENANCE_MESSAGE_BY_GROUP =
  'DELETE_MAINTENANCE_MESSAGE_BY_GROUP';

export const LOADING_MAINTENANCE_MESSAGES_DEVICE =
  'LOADING_MAINTENANCE_MESSAGES_DEVICE';
export const LOADING_MAINTENANCE_MESSAGES_GROUP =
  'LOADING_MAINTENANCE_MESSAGES_GROUP';
