/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class Calls {
  constructor(restCalls) {
    this.restCalls = restCalls;
  }

  /**
   * Get integration registry
   * @TODO: right now we returns always [users], missing implementation in
   * backend
   * This calls returns
   */
  async getIntegrationRegistry() {
    const promise = new Promise((resolve, reject) => {
      const handleSuccessful = (response) => {
        let notificationConfig = response;
        notificationConfig = ['users'];
        resolve(notificationConfig);
      };

      this.restCalls.getIntegrationRegistry(handleSuccessful, reject);
    });
    return promise;
  }
}

export default Calls;
