import { MonitoringScreen } from './MonitoringScreen';
import { withHandlingErrors } from '../../../../../../handlingErrors';
import { withPolyglot } from '../../../../../../i18n';
import { withSnackbar } from 'stoerk-ui-components';
import { connect, ConnectedProps } from 'react-redux';
import { getMonitoringSectionsNavigationSelector } from './navigation';
import { RootState } from '../../../../../../redux/store.model';
import { getMonitoringError } from '../../../../../../redux/monitoring/selectors';
import { NavQueryParams } from './monitoring.model';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: RootState, props: NavQueryParams) => ({
  sectionsNavigation: getMonitoringSectionsNavigationSelector(state, props),
  error: getMonitoringError(state),
});

const connector = connect(mapStateToProps, {});
export type ConnectedComponentProps = ConnectedProps<typeof connector>;

export const ConnectedMonitoringScreen = withHandlingErrors(
  withRouter(withPolyglot(withSnackbar(connector(MonitoringScreen))))
);
