import {
  TYPE_SETPOINT_H,
  TYPE_SETPOINT_P1,
  TYPE_SETPOINT_P2,
  TYPE_SETPOINT_T,
} from 'stoerk-ui-components';

export const DEFAULT_DECIMALS = 2;
export const DEFAULT_MAIN_SENSOR = 'Curr00_01';
export const SENSOR_F01 = 'F01';
export const SENSOR_F02 = 'F02';
export const SENSOR_F03 = 'F03';
export const SENSOR_F04 = 'F04';
export const SENSOR_F05 = 'F05';

export const SECONDARY_SENSORS = [
  SENSOR_F01,
  SENSOR_F02,
  SENSOR_F03,
  SENSOR_F04,
  SENSOR_F05,
];
export const GENERAL_ALARM_EVENT = 'alarmall';
export const ALARM_DISCONNECT = '_disconn';
export const GENERAL_ALARM_EVENT_TXT = 'TXT_alarmall';

export const GROUP_ALARM = 'a';
export const GROUP_WARNING = 'w';
export const GROUP_STATUS = 'r'; /* Read */

export const VALUES_STSOURCE_SETPOINT = 'SETPOINT';
export const VALUES_STSOURCE_RAM = 'RAM';

export const EVENT_NAME_SET1SET2 = 'Set1Set2';

export const SETPOINT_1 = 'S01';
export const SETPOINT_2 = 'S02';
export const SETPOINT_3 = 'S03';

export const SETPOINT_I_S01 = 'I_S01';
export const SETPOINT_I_S02 = 'I_S02';
export const SETPOINT_I_S03 = 'I_S03';

export const HENGEL_PRODUCTNUMBER_STORAGE = '900317133';
export const HENGEL_PRODUCTNUMBER_FERMENTATION = '900317128';
export const HENGEL_PRODUCTNUMBER_DEEP_FREEZING = '900317132';
export const HENGEL_PRODUCTNUMBER_DEEP_CHOCOLATE = '900317134';

export type SET_POINT_TYPES =
  | typeof TYPE_SETPOINT_P1
  | typeof TYPE_SETPOINT_P2
  | typeof TYPE_SETPOINT_T
  | typeof TYPE_SETPOINT_H;
export interface SetPointsTypes {
  [SetPointId: string]: SET_POINT_TYPES;
  S01: SET_POINT_TYPES;
  S02: SET_POINT_TYPES;
}

export const customSetPoints: Record<
  string | number,
  SetPointsTypes | undefined
> = {
  [HENGEL_PRODUCTNUMBER_STORAGE]: {
    [SETPOINT_1]: TYPE_SETPOINT_P1,
    [SETPOINT_2]: TYPE_SETPOINT_P2,
  },
  [HENGEL_PRODUCTNUMBER_DEEP_CHOCOLATE]: {
    [SETPOINT_1]: TYPE_SETPOINT_T,
    [SETPOINT_2]: TYPE_SETPOINT_H,
  },
};

export const DEFAULT_VALUE_SENSOR = '-/-';
export const DEFAULT_EVENT_ON = 'On';

export const MAX_MINUTES_PER_DAY = 1440;
export const MAX_DAYS_PER_YEAR = 366;
