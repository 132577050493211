/* eslint-disable eqeqeq */
import { Switch } from '@mui/material';
import React from 'react';
import { ParameterEditControlProps } from './ParameterEditControl';

export default function ParameterEditControlSwitch(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value } = props;
  const id = `switch-parameter-${parameter.name}`;
  const handleChangeCheckbox = () => {
    onChangeValue(value == '1' ? '0' : '1');
  };
  return (
    <Switch
      checked={value == '1'}
      onChange={handleChangeCheckbox}
      inputProps={{ 'data-testid': id } as any}
    />
  );
}
