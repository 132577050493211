import { useEffect, useMemo } from 'react';
import { getControlUnitIdsActiveById } from '../../../redux/controlUnit/selectors';
import { warmUpControlUnitsTranslations } from '../../../redux/CUTranslation/action/thunks';
import {
  getCUTranslationFromDevice,
  getLoadingCUTranslationFromDevice,
} from '../../../redux/CUTranslation/selectors';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../redux/store.model';
import { getTranslateFunction } from '../getTranslateFunction';

export const useCUTranslate = (deviceId: string, controlUnitId?: string) => {
  const controlUnitIds = useAppSelector((state: RootState) =>
    getControlUnitIdsActiveById(state, { deviceId })
  );
  const loading = useAppSelector((state: RootState) =>
    getLoadingCUTranslationFromDevice(state, { deviceId })
  );
  const translations = useAppSelector((state: RootState) =>
    getCUTranslationFromDevice(state, { deviceId })
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (controlUnitIds) {
      dispatch(warmUpControlUnitsTranslations({ controlUnitIds, deviceId }));
    }
  }, [controlUnitIds, deviceId, dispatch]);

  const CUTranslate = useMemo(
    () => getTranslateFunction(translations, controlUnitId),
    [translations, controlUnitId]
  );
  return [CUTranslate, loading] as const;
};
