export const USER_ACTION_NON = 'NON';
export const USER_ACTION_CONFIRM_EVENT = 'CONFIRM_EVENT';

export const STATE_VALID = 0;
export const STATE_INVALID = 1 << 23; /* used to get the value of the bit 23 */
export const STATE_NOT_AVAILABLE =
  1 << 16; /* used to get the value of the bit 16 */
export const STATE_DEACTIVATED =
  1 << 10; /* used to get the value of the bit 10 */
export const STATE_OVERFLOW = 1 << 9; /* used to get the value of the bit 9 */
export const STATE_UNDERFLOW = 1 << 8; /* used to get the value of the bit 8 */

/* -16 = 11110000 => two complement 8 bits =  240 */
export const COMMANDER_VALUE_ERROR_NONE = 240;
/* -8 = 11111000 => two complement 8 bits = 248 */
export const COMMANDER_VALUE_ERROR_RECORDING_SHUTDOWN = 248;
/* -7 = 11111001 => two complement 8 bits = 249 */
export const COMMANDER_VALUE_ERROR_VALUE_NAME_NOT_SPECIFIED = 249;
/* -6 = 11111010 => two complement 8 bits = 250 */
export const COMMANDER_VALUE_ERROR_ACCESS_DENIED = 250;
/* -5 = 11111011 => two complement 8 bits = 251 */
export const COMMANDER_VALUE_ERROR_RECORDING_OUT_OF_RANGE = 251;
/* -4 = 11111100 => two complement 8 bits = 252 */
export const COMMANDER_VALUE_ERROR_RECORDING_ON = 252;
/* -3 = 11111101 => two complement 8 bits = 253 */
export const COMMANDER_VALUE_ERROR_RECORDING_START = 253;
/* -2 = 11111110 => two complement 8 bits = 254 */
export const COMMANDER_VALUE_ERROR_CORRUPT_RECORD = 254;
/* -1 = 11111111 => two complement 8 bits = 255 */
export const COMMANDER_VALUE_ERROR_DISCONNECTED = 255;
/* 0 = 00000000 => two complement 8 bits = 0 */
export const COMMANDER_VALUE_ERROR_UNKNOWN = 0;
/* 1 = 00000001 => two complement 8 bits = 1 */
export const COMMANDER_VALUE_ERROR_DEACTIVATED = 1;
/* 2 = 00000010 => two complement 8 bits = 2 */
export const COMMANDER_VALUE_ERROR_POWER_FAIL = 2;
/* 3 = 00000011 => two complement 8 bits = 3 */
export const COMMANDER_VALUE_ERROR_SENSOR_FAILURE = 3;
/* 4 = 00000100 => two complement 8 bits = 4 */
export const COMMANDER_VALUE_ERROR_NO_HARDWARE = 4;
/* 5 = 00000101 => two complement 8 bits = 5 */
export const COMMANDER_VALUE_ERROR_ADDRESS_OVERFLOW = 5;
