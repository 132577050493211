import React, { Component } from 'react';
import { Button, Grid, Icon, Popup, Loader } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import {
  TableSort,
  COLUMN_TYPE_TIMESTAMP,
  withSnackbar,
  UiMessage,
} from 'stoerk-ui-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getLanguage, PolyglotComponentProps, withPolyglot } from '../../i18n';
import BrowserUtil from '../../util/BrowserUtil';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../handlingErrors';
import SearchComponent from '../commons/search';
import { fetchIncidentsByDevicesIds } from '../../redux/incidents/actions/asyncThunks';
import { warmUpGroups } from '../../redux/groups/actions/thunks';
import {
  getAllDevicesIdsOnGroupsByGroupIdQueryParamSelector,
  getAllDevicesIdsOnGroupsSelector,
} from '../../redux/groups/selectors';

import { ICON_PRIORITY, INCIDENT_STATUS, INCIDENT_CATEGORY } from './Constants';
import './index.css';
import { RootState } from '../../redux/store.model';
import { getIncidentsByQueryParams } from '../../redux/incidents/selectors';
import { IncidentAPIResponse } from '../../model/incidentManager/incident.model';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { getDevicesDataById } from '../../redux/devices/selectors';

const browserUtil = new BrowserUtil();
// each 2 minutes
const INTERVAL_FETCH_INCIDENTS_MS = 1000 * 60 * 2;

interface Props
  extends ConnectedComponentProps,
    RouteComponentProps<{ groupId?: string; deviceId?: string }>,
    HandlingErrorWrappedProps,
    RightsUserUtilComponentProps,
    PolyglotComponentProps,
    OpenSnackbarProps {}

type State = {
  valueSearch: string;
  error: null | boolean;
  showLoadingMessage: boolean;
  intervaleId: NodeJS.Timeout;
};

/**
 * Incident manager
 * This class show a list of incidents for a device or a group
 */
export class IncidentManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.dataTable = this.dataTable.bind(this);
    this.getDevicesIds = this.getDevicesIds.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.rightsReadIncidentsDevices =
      this.rightsReadIncidentsDevices.bind(this);
    this.rightsUpdateIncidentsDevices =
      this.rightsUpdateIncidentsDevices.bind(this);

    const intervaleId = setInterval(() => {
      this.loadData();
    }, INTERVAL_FETCH_INCIDENTS_MS);

    /* the url has the following format incidentmanager/device/:id/group/:id
    the variable router.params has 2 parameters [deviceId, groupId]
    the incidents of this device might be find in the props variable groups */
    this.state = {
      intervaleId,
      valueSearch: '',
      error: null,
      showLoadingMessage: false,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervaleId);
  }

  /**
   * Load data
   */
  async loadData() {
    const { handlingErrorsApi } = this.props;
    try {
      this.setState({ showLoadingMessage: true });

      await this.props.warmUpGroups();
      await this.loadIncidents();
    } catch (error: any) {
      this.setState({
        error: typeof error === 'object' ? error.message : error,
      });
      handlingErrorsApi(error);
    }
    this.setState({ showLoadingMessage: false });
  }

  /**
   * Get devices ids
   * This function returns devices ids:
   * 1. if the deviceId is not null => return deviceId
   * 2. If the deviceId is null and groupId is not null => return all devices that
   *    belong to the group
   * 3. If the deviceId and groupId is null => return all devices including the
   *    associated devices without a group
   * @return array[string] devicesIds
   */
  getDevicesIds() {
    const { match, devicesIds = [], allDevicesIds = [] } = this.props;
    const { groupId, deviceId } = match.params;
    if (deviceId) return [deviceId];
    if (groupId) return devicesIds;
    return allDevicesIds;
  }

  /**
   * Rights read incidents devices
   * This function get the rights to read the incidents for each device
   * @return object {device: right} rightReadIncidentsDevices
   */
  rightsReadIncidentsDevices() {
    const { match, rightsUserUtil } = this.props;
    const { groupId } = match.params;
    const rightsReadIncidentsDevicesObj = {};
    const devicesIds = this.getDevicesIds();
    devicesIds.forEach((id) => {
      const rightsReadIncidentDevice = rightsUserUtil.hasRightsToReadIncident(
        id,
        groupId
      );
      Object.assign(rightsReadIncidentsDevicesObj, {
        [id]: rightsReadIncidentDevice,
      });
    });
    return rightsReadIncidentsDevicesObj;
  }

  /**
   * Rights read incidents devices
   * This function get the rights to read the incidents for each device
   * @return object {device: right} rightReadIncidentsDevices
   */
  filterDevicesByRightsReadIncidentsDevices() {
    const { match, rightsUserUtil } = this.props;
    const { groupId } = match.params;
    const devicesIds = this.getDevicesIds();
    return devicesIds.filter((id) =>
      rightsUserUtil.hasRightsToReadIncident(id, groupId)
    );
  }

  /**
   * Rights update incidents devices
   * This function get the rights to read the incidents for each device
   * @return object {device: right} rightsUpdateIncidentsDevices
   */
  rightsUpdateIncidentsDevices() {
    const { match, rightsUserUtil } = this.props;
    const { groupId } = match.params;
    const rightsUpdateIncidentsDevicesObj = {};
    const devicesIds = this.getDevicesIds();
    devicesIds.forEach((id) => {
      const rightsUpdateIncidentDevice =
        rightsUserUtil.hasRightsToUpdateIncident(id, groupId);
      Object.assign(rightsUpdateIncidentsDevicesObj, {
        [id]: rightsUpdateIncidentDevice,
      });
    });
    return rightsUpdateIncidentsDevicesObj;
  }

  /**
   * Load the incidents for the DevicesIds
   */
  async loadIncidents() {
    const devicesIds = this.filterDevicesByRightsReadIncidentsDevices();
    await this.props.fetchIncidentsByDevicesIds(devicesIds);
  }

  /**
   * Load button to display
   */
  loadButtonsToDisplay() {
    const { match, rightsUserUtil } = this.props;
    const { groupId, deviceId } = match.params;
    let showIncidents = true;
    let rightsReadIncidentsDevices = {};
    let rightsUpdateIncidentsDevices = {};
    const rightsReadIncidentsGroups = {};
    const rightsUpdateIncidentsGroups = {};
    try {
      if (deviceId) {
        /** read rights */
        const rightsReadIncident = rightsUserUtil.hasRightsToReadIncident(
          deviceId,
          groupId
        );
        Object.assign(rightsReadIncidentsDevices, {
          [deviceId]: rightsReadIncident,
        });
        /** update rights */
        const rightsUpdateIncident = rightsUserUtil.hasRightsToUpdateIncident(
          deviceId,
          groupId
        );
        Object.assign(rightsUpdateIncidentsDevices, {
          [deviceId]: rightsUpdateIncident,
        });
      } else if (groupId) {
        /** read rights */
        const rightsReadIncident = rightsUserUtil.hasRightsToReadIncident(
          deviceId,
          groupId
        );
        Object.assign(rightsReadIncidentsGroups, {
          [groupId]: rightsReadIncident,
        });
        /** update rights */
        const rightsUpdateIncident = rightsUserUtil.hasRightsToUpdateIncident(
          deviceId,
          groupId
        );
        Object.assign(rightsUpdateIncidentsGroups, {
          [groupId]: rightsUpdateIncident,
        });
        /* if the group has not rights, we should load the rights from the devices */
        if (rightsReadIncident === false) {
          rightsReadIncidentsDevices = this.rightsReadIncidentsDevices();
        }
        if (rightsUpdateIncident === false) {
          rightsUpdateIncidentsDevices = this.rightsUpdateIncidentsDevices();
        }
      } else {
        rightsReadIncidentsDevices = this.rightsReadIncidentsDevices();
        rightsUpdateIncidentsDevices = this.rightsUpdateIncidentsDevices();
      }

      /* we don't need to evaluated if the user has rights to read the incidents,
      because the incidentes are getting from the groups and devices structure */
      if (groupId || deviceId) {
        showIncidents = rightsUserUtil.hasRightsToReadIncident(
          deviceId,
          groupId
        );
      }
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error.message : error;
      const { openSnackbar } = this.props;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
      this.setState({
        error: errorDescription,
      });
    }

    return {
      rightsUpdateIncidentsDevices,
      rightsUpdateIncidentsGroups,
      showIncidents,
      showNoRightsMessage: !showIncidents,
    };
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { history } = this.props;
    history.goBack();
  }

  /**
   * Data table
   * Create the columns and row data for the table
   * @param array incidents
   * @param string groupId
   * @param string deviceId
   * @return object { columnsFormat, data }
   */
  dataTable(
    incidents: IncidentAPIResponse[],
    rightsUpdateIncidentsDevices: Record<string, boolean>,
    rightsUpdateIncidentsGroups: Record<string, boolean>,
    groupId?: string,
    deviceId?: string
  ) {
    const { polyglot, history, getDevicesDataById } = this.props;

    let columnsFormat = {};
    let data: any[] | false | undefined = [];
    if (incidents !== undefined && incidents.length > 0) {
      columnsFormat = {
        prioritySymbol: {
          allowSort: false,
          title: '',
        },
        priority: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.priority'),
        },
        date: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.date'),
          type: COLUMN_TYPE_TIMESTAMP,
        },
        status: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.status'),
        },
        category: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.category'),
        },
        title: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.title'),
        },
        cu: {
          allowSort: true,
          title: polyglot.t('incident_manager.titles_columns.cu'),
        },
      };

      if (!deviceId || deviceId === undefined) {
        Object.assign(columnsFormat, {
          device: {
            allowSort: true,
            title: polyglot.t('incident_manager.titles_columns.device'),
          },
        });
      }
      Object.assign(columnsFormat, {
        buttons: {
          allowSort: false,
          title: '',
        },
      });

      data =
        incidents !== undefined &&
        incidents.length > 0 &&
        incidents.map((incident) => {
          let urlIncident = `/incident/${incident.id}`;
          if (groupId) {
            urlIncident = `${urlIncident}/group/${groupId}`;
          }
          let buttonEdit = (
            <Button
              id="iconEditIncident"
              icon="write"
              size="small"
              basic
              onClick={() => history.push(urlIncident)}
            />
          );
          buttonEdit = !browserUtil.getIsMobile() ? (
            <Popup
              trigger={buttonEdit}
              content={polyglot.t('incident_manager.tooltip.open_window_edit')}
            />
          ) : (
            buttonEdit
          );
          let prioritySymbol: any = '';
          if (ICON_PRIORITY[incident.priority]) {
            prioritySymbol = (
              <Icon
                name={ICON_PRIORITY[incident.priority].icon as SemanticICONS}
                style={{ color: ICON_PRIORITY[incident.priority].color }}
              />
            );
          }
          /** Title: the title is found under the fields: summary or
        localizedSummaries {[en]: , [fr] }. First it will check if the title is available
        in the field localizedSummaries[locale]. If this is not available, then it will
        check if the field summary is not empty. If this is empty, then it will be used
        the first entry in the object localizedSummaries
        */
          let title;
          if (
            incident.localizedSummaries &&
            Object.keys(incident.localizedSummaries).length > 0 &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore languages not typed well
            incident.localizedSummaries[getLanguage()]
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore languages not typed well
            title = incident.localizedSummaries[getLanguage()];
          } else {
            title = incident.summary ? incident.summary : '';
          }

          if (
            !title &&
            incident.localizedSummaries &&
            Object.keys(incident.localizedSummaries).length > 0
          ) {
            title =
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore languages not typed well
              incident.localizedSummaries[
                Object.keys(incident.localizedSummaries)[0]
              ];
          }
          const row = {
            prioritySymbol,
            priority: polyglot.t(
              `incident_manager.priority_options.${
                ICON_PRIORITY[incident.priority].polyglot
              }`
            )
              ? polyglot.t(
                  `incident_manager.priority_options.${
                    ICON_PRIORITY[incident.priority].polyglot
                  }`
                )
              : '',
            date: Math.floor(incident.reportTimestamp / 1000),
            status:
              INCIDENT_STATUS[incident.status] &&
              polyglot.t(
                `incident_manager.status_options.${
                  INCIDENT_STATUS[incident.status].polyglot
                }`
              )
                ? polyglot.t(
                    `incident_manager.status_options.${
                      INCIDENT_STATUS[incident.status].polyglot
                    }`
                  )
                : '',
            category:
              INCIDENT_CATEGORY[incident.category] !== undefined &&
              polyglot.t(
                `incident_manager.category_options.${
                  INCIDENT_CATEGORY[incident.category].polyglot
                }`
              )
                ? polyglot.t(
                    `incident_manager.category_options.${
                      INCIDENT_CATEGORY[incident.category].polyglot
                    }`
                  )
                : '',
            title,
            cu: incident.cu ? incident.cu : '',
          };

          if (!deviceId || deviceId === undefined) {
            Object.assign(row, {
              device:
                (incident.device !== undefined &&
                  getDevicesDataById(incident.device)?.name) ||
                '',
            });
          }
          /* The button edit is only show when the group is defined and the update
        incident rights is true */
          Object.assign(row, {
            buttons:
              groupId !== null &&
              groupId !== undefined &&
              (rightsUpdateIncidentsGroups[groupId] ||
                rightsUpdateIncidentsDevices[incident.device]) ? (
                <Button.Group>{buttonEdit}</Button.Group>
              ) : null,
          });
          return row;
        });
    }
    return { columnsFormat, data };
  }

  render() {
    const { valueSearch, error, showLoadingMessage } = this.state;
    const {
      showIncidents,
      showNoRightsMessage,
      rightsUpdateIncidentsDevices,
      rightsUpdateIncidentsGroups,
    } = this.loadButtonsToDisplay();
    const { match, incidents, polyglot } = this.props;
    const { groupId, deviceId } = match.params;

    if (error !== null) {
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                {error}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    if (!showIncidents && showNoRightsMessage) {
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                {polyglot.t('incident_manager.no_rights_read_incidents')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    const { columnsFormat, data } = this.dataTable(
      incidents,
      rightsUpdateIncidentsDevices,
      rightsUpdateIncidentsGroups,
      groupId,
      deviceId
    );
    let dataFilter = data;
    if (valueSearch && data) {
      dataFilter = data.filter(
        (d) =>
          (d.priority !== undefined &&
            d.priority.toLowerCase().search(valueSearch.toLowerCase()) !==
              -1) ||
          (d.status !== undefined &&
            d.status.toLowerCase().search(valueSearch.toLowerCase()) !== -1) ||
          (d.title !== undefined &&
            d.title.toLowerCase().search(valueSearch.toLowerCase()) !== -1) ||
          (d.description !== undefined &&
            d.description.toLowerCase().search(valueSearch.toLowerCase()) !==
              -1) ||
          (d.category !== undefined &&
            d.category.toLowerCase().search(valueSearch.toLowerCase()) !==
              -1) ||
          (d.device !== undefined &&
            d.device.toLowerCase().search(valueSearch.toLowerCase()) !== -1)
      );
    }

    return (
      <div className="incident-manager">
        <Grid>
          <Grid.Row className="filter-options">
            <Grid.Column width={16}>
              <SearchComponent
                polyglot={polyglot}
                isLoading={false}
                handleSearchChange={(_: unknown, { value }: any) =>
                  this.setState({ valueSearch: value })
                }
                value={valueSearch}
              />
            </Grid.Column>
          </Grid.Row>
          {showLoadingMessage && (
            <Grid.Row className="loader-container">
              <Grid.Column width={16} textAlign="center">
                <Loader active inline />
                {polyglot.t('group.loading_data_message')}
              </Grid.Column>
              <br />
            </Grid.Row>
          )}
          {!showLoadingMessage && incidents.length === 0 && (
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <UiMessage
                  content={polyglot.t('incident_manager.no_new_incidents')}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        {dataFilter &&
          dataFilter.length > 0 &&
          Object.keys(columnsFormat).length > 0 && (
            <TableSort
              data={dataFilter.slice()}
              columnsFormat={columnsFormat}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string; deviceId?: string }>
) => ({
  devicesIds: getAllDevicesIdsOnGroupsByGroupIdQueryParamSelector(state, props),
  allDevicesIds: getAllDevicesIdsOnGroupsSelector(state),
  getDevicesDataById: (deviceId: string) =>
    getDevicesDataById(state, { deviceId }),
  incidents: getIncidentsByQueryParams(state, props),
});

const connector = connect(mapStateToProps, {
  fetchIncidentsByDevicesIds,
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withRouter(
  connect(mapStateToProps, {
    fetchIncidentsByDevicesIds,
    warmUpGroups,
  })(
    withHandlingErrors(
      withUserRightUtil(withSnackbar(withPolyglot(IncidentManager)))
    )
  )
);
