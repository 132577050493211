import { useEffect } from 'react';
import { Device } from '../../../../../model/device/device.model';

export const useDevice = (
  device: Device | undefined,
  warmUpGroups: () => void
) => {
  useEffect(() => {
    if (!device?.data && !device?.loadingData) {
      warmUpGroups();
    }
  }, [device, warmUpGroups]);
};
