import React, { Component } from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import GroupIncidentConfigurationEditView from './GroupIncidentConfigurationEditView';
import {
  fetchIncidentConfigurationByGroupId,
  putIncidentConfiguration,
} from '../../../redux/incidentConfiguration/actions';
import fetchEventsByDevicesId from '../../../redux/events/actions';
import { fetchIntegrationRegistry } from '../../../redux/integrationRegistry/actions';
import { withPolyglot } from '../../../i18n';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import { getGroupByPropGroupIdSelector } from '../../../redux/groups/selectors';
import { RootState } from '../../../redux/store.model';
import { getUsersByUsersIdsPropsSelector } from '../../../redux/users/selectors';
import { UserAPIResponse } from '../../../model/user/user.model';
import Polyglot from 'node-polyglot';
import { cloneDeep } from 'lodash';
import { IncidentConfigAPI } from '../../../model/incidentManager/incident.model';
import IncidentModel from '../../../model/incidentManager/Model';

const notificationConfigTypeUser = 'user';

type Props = ConnectedComponentProps &
  OpenSnackbarProps &
  HandlingErrorWrappedProps & {
    polyglot: Polyglot;
    groupId?: string;
    usersIds: string[];
    activeTransition(...args: unknown[]): unknown;
    closeEditGroup(...args: unknown[]): unknown;
    setIsPossibleToChangeTab(...args: unknown[]): unknown;
  };

type State = {
  groupId?: string;
  loadData: boolean;
  incidentConfiguration: IncidentConfigAPI;
  notificationConfigUsers?: string[];
  showLoadingData: boolean;
  optionsUsers?: {
    key: string;
    value: string;
  }[];
};
/**
 * Group edit incident configuration
 *
 * -> the group's incident configuration is not saved in the table groupManagement,
 * it will be saved in the table incident-config. because of that the contructor and
 * by props changes, the data need to be loaded
 * The loadData methode is not neccesary
 */
export class GroupIncidentConfigurationEdit extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.groupId !== prevState.groupId) {
      return {
        incidentConfiguration: {},
        groupId: nextProps.groupId,
        loadData: true,
      };
    }

    return null;
  }

  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  constructor(props: Props) {
    super(props);
    this.updateFields = this.updateFields.bind(this);
    this.updateDefaultDescriptionsFields =
      this.updateDefaultDescriptionsFields.bind(this);
    this.updateDiagnosisOptionsFields =
      this.updateDiagnosisOptionsFields.bind(this);
    this.validationInputData = this.validationInputData.bind(this);
    this.getIncidentConfiguration = this.getIncidentConfiguration.bind(this);
    this.updateUsersNotificationConfig =
      this.updateUsersNotificationConfig.bind(this);
    this.serializeData = this.serializeData.bind(this);
    this.getNotificationConfigUser = this.getNotificationConfigUser.bind(this);
    this.save = this.save.bind(this);
    const { groupId } = props;

    this.state = {
      groupId,
      loadData: true,
      showLoadingData: false,
      incidentConfiguration: IncidentModel.parseIncidentConfiguration(
        undefined,
        groupId || ''
      ),
    };
  }

  /**
   * Component did mount
   * will be run after constructor
   * the needed data will be load here
   */
  componentDidMount() {
    const { loadData, groupId } = this.state;
    const { users } = this.props;
    if (loadData) {
      this.getIncidentConfiguration(groupId || '', users);
    }
  }

  /**
   * Component did update
   * will be run after getDerivedStateFromProps
   * the needed data will be load here
   */
  componentDidUpdate() {
    const { loadData, groupId } = this.state;
    const { users } = this.props;

    if (loadData) {
      this.getIncidentConfiguration(groupId || '', users);
      GroupIncidentConfigurationEdit.fixBody();
    }
  }

  /**
   * Get incident configuration
   * This function call the service incident manager to get the incident configuration
   * by group and the users data
   * @param string groupId
   * @param array users
   */
  async getIncidentConfiguration(groupId: string, users: UserAPIResponse[]) {
    try {
      this.setState({ loadData: false, showLoadingData: true });
      await this.props.fetchIncidentConfigurationByGroupId(groupId);
      // FIXME: uncomment
      // await this.props.fetchIntegrationRegistry();
      // notificationConfigUsers = this.props.integrationRegistry;
      let incidentConfiguration = cloneDeep(
        this.props.incidentConfiguration
      ) as IncidentConfigAPI;
      const { group } = this.props;
      const deviceIDs = group?.devices ?? [];
      await this.props.fetchEventsByDevicesId(deviceIDs);
      const { events } = this.props;
      const defaultDescriptions = {
        ...incidentConfiguration?.defaultDescriptions,
      };
      /* add the missing events (descriptions) in the default descriptions */
      events.forEach((event) => {
        if (!defaultDescriptions[event]) {
          defaultDescriptions[event] = '';
        }
      });
      incidentConfiguration = { ...incidentConfiguration, defaultDescriptions };
      const notificationConfigUsers = this.getNotificationConfigUser();
      /* optionsUsers: array with the available users to be assigned to the the
      incident: if the incident has not assigned user, then we should have a empty
      option */
      const optionsUsers = users.map((user) => ({
        key: user.id,
        value: user.name ? user.name : user.email,
      }));

      this.setState({
        incidentConfiguration,
        notificationConfigUsers,
        optionsUsers,
        showLoadingData: false,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      this.setState({ showLoadingData: false });
      handlingErrorsApi(error);
    }
  }

  /**
   * Get notification config user
   * this function builds and array with the users from the notificationConfig type user
   * @return array notificationConfigUsers
   */
  getNotificationConfigUser() {
    const { incidentConfiguration } = this.props;
    const notificationConfigUsers: string[] = [];
    incidentConfiguration?.notificationConfig?.forEach((notification) => {
      if (notificationConfigTypeUser === notification.type) {
        notificationConfigUsers.push(notification.integrationConfig.user);
      }
    });

    return notificationConfigUsers;
  }

  /**
   * Update fields
   * this function will be called by input field changes
   * @param object event
   * @param string field
   */
  updateFields(
    event: { target: { value: any } },
    field: keyof IncidentConfigAPI
  ) {
    const { setIsPossibleToChangeTab } = this.props;
    const { incidentConfiguration } = this.state;
    incidentConfiguration[field] = event.target.value;
    this.setState({ incidentConfiguration });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Update default descriptions fields
   * this function will be called by input field default descriptions changes
   * @param object event
   * @param string field
   */
  updateDefaultDescriptionsFields(
    event: { target: { value: any } },
    field: string | number
  ) {
    const { incidentConfiguration } = this.state;
    const { setIsPossibleToChangeTab } = this.props;
    incidentConfiguration.defaultDescriptions[field] = event.target.value;
    this.setState({ incidentConfiguration });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Update diagnosis options fields
   * this function will be called by input field diagnosis options changes
   * @param object event
   * @param string field
   */
  updateDiagnosisOptionsFields(
    event: { target: { value: string } },
    index: number
  ) {
    const { incidentConfiguration } = this.state;
    const { setIsPossibleToChangeTab } = this.props;
    incidentConfiguration.diagnosisOptions[index] = event.target.value;
    if (
      !incidentConfiguration.diagnosisOptions[index] ||
      incidentConfiguration.diagnosisOptions[index] === ''
    ) {
      incidentConfiguration.diagnosisOptions.splice(index, 1);
    }
    if (
      incidentConfiguration.diagnosisOptions[
        incidentConfiguration.diagnosisOptions.length - 1
      ]
    ) {
      incidentConfiguration.diagnosisOptions[
        incidentConfiguration.diagnosisOptions.length
      ] = '';
    }
    this.setState({ incidentConfiguration });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Update users notification config
   * @param object event
   * @param object data
   */
  updateUsersNotificationConfig(event: any, data: { value?: any }) {
    this.setState({ notificationConfigUsers: data.value });
  }

  /**
   * Validation input data
   */
  validationInputData() {
    /* if (!this.state.incidentConfiguration.defaultAssignee) {
      return false;
    } */
    return true;
  }

  /**
   * Save
   * save the incident configuration for a group (table incident config)
   */
  async save() {
    const {
      activeTransition,
      setIsPossibleToChangeTab,
      openSnackbar,
      polyglot,
      handlingErrorsApi,
    } = this.props;
    const { groupId } = this.state;
    if (!this.validationInputData() || !groupId) {
      activeTransition();
    } else {
      try {
        await this.props.putIncidentConfiguration(
          groupId,
          this.serializeData()
        );
        setIsPossibleToChangeTab(true);
        /* Snackbar message ok */
        const message = {
          text: polyglot.t(
            'group.incident_configuration.save_successful_message'
          ),
          type: 'ok',
        };
        openSnackbar(message);
      } catch (error) {
        handlingErrorsApi(error);
      }
    }
  }

  /**
   * serialize data
   * this function transforms the data from the variables incidentConfiguration and
   * notificationConfigUsers into a new object incidentConfiguration with valid
   * data to be saved
   */
  serializeData() {
    const { notificationConfigUsers } = this.state;
    const incidentConfiguration = cloneDeep(this.state.incidentConfiguration);
    /* notificationConfig users */
    let notificationConfig: any[] = notificationConfigUsers?.map((user) => ({
      type: notificationConfigTypeUser,
      integrationConfig: { user },
    })) || [{}];

    /* to delete all the notification users, we need to send the following
    format [{}] */
    if (notificationConfig?.length === 0) {
      notificationConfig = [{}];
    }
    incidentConfiguration.notificationConfig = notificationConfig;

    if (!incidentConfiguration.defaultAssignee) {
      incidentConfiguration.defaultAssignee = null;
    }

    /* diagnosis options */
    const diagnosisOptions: string[] = [];
    incidentConfiguration.diagnosisOptions.forEach((option: any) => {
      if (option) {
        diagnosisOptions.push(option);
      }
    });
    incidentConfiguration.diagnosisOptions = diagnosisOptions;

    return incidentConfiguration;
  }

  render() {
    const {
      showLoadingData,
      incidentConfiguration,
      optionsUsers,
      notificationConfigUsers,
    } = this.state;
    const { polyglot, closeEditGroup, users } = this.props;
    return (
      <>
        {showLoadingData && (
          <Grid container>
            <Grid.Row>
              <Grid.Column width="16" textAlign="center" verticalAlign="middle">
                <Loader active inline />
                {polyglot.t('group.loading_data_message')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <GroupIncidentConfigurationEditView
          incidentConfiguration={incidentConfiguration}
          optionsUsers={optionsUsers}
          users={users}
          notificationConfigUsers={notificationConfigUsers}
          updateFields={this.updateFields}
          updateDefaultDescriptionsFields={this.updateDefaultDescriptionsFields}
          updateDiagnosisOptionsFields={this.updateDiagnosisOptionsFields}
          updateUsersNotificationConfig={this.updateUsersNotificationConfig}
          save={this.save}
          closeEditGroup={closeEditGroup}
        />
      </>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: { groupId?: string; usersIds?: string[] }
) => ({
  incidentConfiguration: state.incidentConfiguration.item as
    | IncidentConfigAPI
    | undefined,
  events: (state.events.item as string[]) || [],
  integrationRegistry: state.integrationRegistry.items || [],
  group: getGroupByPropGroupIdSelector(state, props),
  users: getUsersByUsersIdsPropsSelector(state, props),
});
const connector = connect(mapStateToProps, {
  fetchIncidentConfigurationByGroupId,
  fetchIntegrationRegistry,
  putIncidentConfiguration,
  fetchEventsByDevicesId,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withSnackbar(withPolyglot(connector(GroupIncidentConfigurationEdit)))
);
