import React from 'react';
import { Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import ControlUnitModel from '../../../../../../../../../../model/controlUnit/Model';
import { DeviceControlUnitsAPIResponse } from '../../../../../../../../../../model/device/device.model';
import ListItem from '../../../../../../../../../commons/ListItem';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../i18n';
import TooltipLight from '../../../../../../../../../commons/TooltipLight';
import { STButtonIcon } from '../../../../../../../../../commons/STButtonIcon';

interface ControlUnitCardListProps
  extends RouteComponentProps,
    PolyglotComponentProps {
  // currentAlarms: DigitalChannelEvent[] | undefined;
  showButtonHistory: boolean;
  controlUnit?: DeviceControlUnitsAPIResponse;
  loading?: boolean;
  deviceId: string;
  controlUnitId: string;
  groupId?: string;
  moduleSelected?: string;
}
export function ControlUnitCardListSimple(props: ControlUnitCardListProps) {
  const {
    controlUnit,
    groupId,
    deviceId,
    controlUnitId,
    loading,
    history,
    moduleSelected,
  } = props;
  const openControlUnitDetail = () => {
    if (moduleSelected === '') return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}`;
    }
    history.push(url);
  };

  return (
    <Paper variant="outlined" sx={{ paddingRight: 1 }}>
      <ListItem
        id={controlUnitId}
        name={controlUnit?.name}
        loading={loading}
        iconNode={ControlUnitModel.getControlUnitIconDefault()}
        onClick={openControlUnitDetail}
        buttons={
          <Button.Group>
            <ControlUnitHistoryButton {...props} />
            <ControlUnitEventsButton {...props} />
            <ControlUnitParametersButton {...props} />
          </Button.Group>
        }
      />
    </Paper>
  );
}
const sizeIcons = 'medium';

function ControlUnitHistoryButton(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    history,
    moduleSelected,
    showButtonHistory,
  } = props;
  if (!showButtonHistory) return null;
  const selected = moduleSelected === 'history';

  const openControlUnitHistory = () => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/history`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/history`;
    }
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.monitoring.title')}>
      <STButtonIcon
        icon="chart line"
        size={sizeIcons}
        onClick={openControlUnitHistory}
        selected={selected}
      />
    </TooltipLight>
  );
}

function ControlUnitEventsButton(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    history,
    moduleSelected,
  } = props;
  const selected = moduleSelected === 'events';

  const openControlUnitEvents = () => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/events`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/events`;
    }
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.events.events')}>
      <STButtonIcon
        icon="calendar alternate"
        size={sizeIcons}
        onClick={openControlUnitEvents}
        selected={selected}
      />
    </TooltipLight>
  );
}

function ControlUnitParametersButton(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    history,
    moduleSelected,
  } = props;
  const selected = moduleSelected === 'parameters';

  const openControlUnitParameters = () => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/parameters`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/parameters`;
    }
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.parameters.title')}>
      <STButtonIcon
        icon="cogs"
        size={sizeIcons}
        onClick={openControlUnitParameters}
        selected={selected}
      />
    </TooltipLight>
  );
}
export default withRouter(withPolyglot(ControlUnitCardListSimple));
