import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ValidationUtil from '../../../../../../util/ValidationUtil';
import BrowserUtil from '../../../../../../util/BrowserUtil';
import ConnectDetails from './Details';
import MaintenanceMessages from '../../../GroupEdit/MaintenanceMessages/Configuration';
import DeviceIcon from '../../../../../../assets/IconST7Landscape.svg';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import {
  getMaintenanceMessagesDevice,
  deleteMaintenanceMessageDevice,
  updateMaintenanceMessageDevice,
  setMaintenanceMessageDevice,
} from '../../../../../../redux/maintenanceMessage/actions';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../../../util/rights';
import { getDeviceFromProps } from '../../../../../../redux/devices/selectors';
import { RootState } from '../../../../../../redux/store.model';
import { Device } from '../../../../../../model/device/device.model';
import { ModalTabs, STModal } from '../../../../../commons/Modal';
import { Button } from '@mui/material';

const browserUtil = new BrowserUtil();

type Props = {
  groupId?: string;
  showEditDevice: boolean;
  closeEditDevice(...args: unknown[]): unknown;
} & PolyglotComponentProps &
  RightsUserUtilComponentProps &
  ConnectedComponentProps;

type State = {
  showAddDevice?: boolean;
  allowToChangeTab: boolean;
  activeIndex: number;
  newActiveIndex: number;
  showDataNoSavedModal: boolean;
};

/**
 * Device edit
 */
export class DeviceEdit extends Component<Props, State> {
  validationUtil: ValidationUtil;
  constructor(props: Props) {
    super(props);
    this.closeEditDevice = this.closeEditDevice.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.setIsPossibleToChangeTab = this.setIsPossibleToChangeTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.remainTab = this.remainTab.bind(this);
    this.solveBugModalWindowModalDevices =
      this.solveBugModalWindowModalDevices.bind(this);
    this.loadRights = this.loadRights.bind(this);
    this.validationUtil = new ValidationUtil(this.props.polyglot);
    this.state = {
      allowToChangeTab: true,
      activeIndex: 0,
      newActiveIndex: 0,
      showDataNoSavedModal: false,
    };
  }

  componentDidMount() {
    this.solveBugModalWindowModalDevices();
  }

  componentDidUpdate() {
    /**
     * Bug iOS devices: allow the scrollbar to large windows
     */
    if (
      browserUtil.getIsiPad() ||
      browserUtil.getIsiPhone() ||
      browserUtil.getIsiPod()
    ) {
      const modal = document.getElementById('modal');
      if (modal !== null) {
        modal.classList.add('modal-ios');
      }
    }
  }

  /**
   * on Tab change:
   * this function will be called everytime that a panel is clicked
   * @param object event
   * @param object data
   */
  onTabChange(
    event: React.SyntheticEvent<Element, Event>,
    activeIndex: number
  ) {
    if (this.state.allowToChangeTab) {
      this.setState({
        activeIndex: activeIndex as number,
      });
    } else {
      this.setState({
        showDataNoSavedModal: true,
        newActiveIndex: activeIndex as number,
      });
    }
  }

  /**
   * Set is possible to change tab
   * @param bool allowToChangeTab
   */
  setIsPossibleToChangeTab(allowToChangeTab: boolean) {
    this.setState({
      allowToChangeTab,
    });
  }

  /**
   * Load rights
   * @param object device
   * @return bool showMaintenanceMessages
   */
  loadRights(device: Device) {
    let showMaintenanceMessages = false;
    const { rightsUserUtil } = this.props;
    if (device.uuid) {
      showMaintenanceMessages = rightsUserUtil.hasRightsToReadMessagesGroup(
        device.uuid
      );
    }
    return showMaintenanceMessages;
  }

  /**
   * Solve bug model window modal devices
   */
  solveBugModalWindowModalDevices() {
    const { showAddDevice } = this.state;
    /**
     * Bug modal windows by mobile devices
     */
    if (
      (browserUtil.getIsiPad() ||
        browserUtil.getIsiPhone() ||
        browserUtil.getIsiPod()) &&
      showAddDevice
    ) {
      /**
       * Bug iOS Mobile devices: by modal windows with input fields, the property
       * position <> fixed produces a weird behavior: change from a input to other
       * input makes that the window spring (scroll down in the window behind) and the
       * cursor is placed in the wrong position
       */
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
  }

  /**
   * Change tab:
   * this function is called if the user wants to leave this panel without
   * to save the changes
   */
  changeTab() {
    this.setState({
      allowToChangeTab: true,
      activeIndex: this.state.newActiveIndex,
      showDataNoSavedModal: false,
    });
  }

  /**
   * Remain tab
   * this function is called if the user wants to remain in the panel to save the changes
   */
  remainTab() {
    this.setState({
      allowToChangeTab: false,
      showDataNoSavedModal: false,
    });
  }

  /**
   * Close edit device
   */
  closeEditDevice() {
    document.body.style.position = '';
    document.body.style.width = '';
    const modal = document.getElementById('modal');
    if (modal !== null) {
      modal.classList.remove('modal-ios');
    }
    this.props.closeEditDevice();
  }

  render() {
    const { showDataNoSavedModal, activeIndex } = this.state;
    const { groupId, showEditDevice, device } = this.props;
    const DeviceEditDetailsForm = (
      <ConnectDetails
        device={device}
        groupId={groupId}
        closeEditDevice={this.closeEditDevice}
        setIsPossibleToChangeTab={this.setIsPossibleToChangeTab}
      />
    );
    const DeviceEditMaintenanceMessagesForm = (
      <MaintenanceMessages
        id={device.uuid}
        hasRightsToUpdate={this.props.rightsUserUtil.hasRightsToUpdateMessageDevice(
          device.uuid
        )}
        hasRightsToDelete={this.props.rightsUserUtil.hasRightsToDeleteMessageDevice(
          device.uuid
        )}
        showCloseWindow={false}
        getMaintenanceMessages={this.props.getMaintenanceMessagesDevice}
        deleteMaintenanceMessage={this.props.deleteMaintenanceMessageDevice}
        updateMaintenanceMessage={this.props.updateMaintenanceMessageDevice}
        setMaintenanceMessage={this.props.setMaintenanceMessageDevice}
        messages={this.props.messagesDevices}
        loading={this.props.messagesDevicesLoading}
      />
    );

    const tabs = [
      {
        title: this.props.polyglot.t('device.tabs.details'),
        content: DeviceEditDetailsForm,
      },
      {
        title: this.props.polyglot.t(
          'device.tabs.maintenance_messages_configuration'
        ),
        content: DeviceEditMaintenanceMessagesForm,
      },
    ];

    const icon = device.iconURL ? device.iconURL : DeviceIcon;

    return (
      <div>
        <ModalTabs
          open={showEditDevice}
          id="modal"
          onClose={this.closeEditDevice}
          title={device.name}
          iconURL={icon}
          tabs={tabs}
          onChange={this.onTabChange}
          value={activeIndex}
          buttonActions={
            <Button onClick={this.props.closeEditDevice}>
              {this.props.polyglot.t('button.close')}
            </Button>
          }
        ></ModalTabs>
        {/* Modal window called when the user wants to go to other tab and there
          are unsaved changes */}
        <STModal
          open={showDataNoSavedModal}
          onClose={this.remainTab}
          buttonActions={
            <>
              <Button onClick={this.remainTab}>
                {this.props.polyglot.t('general.no')}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={this.changeTab}
              >
                {this.props.polyglot.t('general.yes')}
              </Button>
            </>
          }
        >
          <p>{this.props.polyglot.t('group.usaved_changes_message')}</p>
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: { deviceId: string }) => ({
  messagesDevices: state.maintenanceMessage.devices,
  messagesDevicesLoading: state.maintenanceMessage.devicesLoading,
  device: getDeviceFromProps(state, props),
});

const connector = connect(mapStateToProps, {
  getMaintenanceMessagesDevice,
  deleteMaintenanceMessageDevice,
  updateMaintenanceMessageDevice,
  setMaintenanceMessageDevice,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withUserRightUtil(withPolyglot(connector(DeviceEdit)));
