import {
  IncidentAPIResponse,
  IncidentsAttachmentsAPIResponse,
  PublicIncidentAPIResponse,
} from '../../model/incidentManager/incident.model';
import { ErrorRestST } from '../../rest/rest.model';

export const nameReducer = 'incidents';

type ID_INCIDENT_BASE_64 = string;
type ID_DEVICE = string;
export interface IncidentsState {
  items: Record<ID_INCIDENT_BASE_64, IncidentAPIResponse>;
  item?: IncidentAPIResponse;
  attachments: Record<string, IncidentsAttachmentsAPIResponse[]>;
  /** type not resolved */
  attachment: any;
  publicIncidentsItems: Record<ID_INCIDENT_BASE_64, PublicIncidentAPIResponse>;
  loadingIncident: boolean;
  loadingPublicIncidentById: Record<ID_DEVICE, boolean>;
  loadingIncidentByDeviceId: boolean;
  loadingIncidentByIncidentId: boolean;
  error?: ErrorRestST;
}
