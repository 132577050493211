import React from 'react';
import { Search } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './index.css';
import { withPolyglot } from '../../../i18n';

// eslint-disable-next-line react/prefer-stateless-function
class SearchComponent extends React.PureComponent {
  render() {
    return (
      <Search
        loading={this.props.isLoading}
        onSearchChange={this.props.handleSearchChange}
        noResultsMessage={this.props.polyglot.t(
          'device.search.message_no_results_found'
        )}
        open={false}
        input={{ fluid: true }}
        id="search"
        value={this.props.value}
      />
    );
  }
}

SearchComponent.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  handleSearchChange: PropTypes.func.isRequired,
};

SearchComponent.defaultProps = {
  isLoading: false,
  value: '',
};

export default withPolyglot(SearchComponent);
