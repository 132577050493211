import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid, Popup, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SortUtil from '../../../util/SortUtil';
import { getUnreadNotifications } from '../../../redux/notifications/actions';
import { initializeLoadUnreadNotificationsPeriodically } from '../../../redux/notifications';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import { DATE_FORMAT } from '../../../util/Constants';
import { STButtonIcon } from '../../commons/STButtonIcon';
import { RootState } from '../../../redux/store.model';

interface Props
  extends PolyglotComponentProps,
    RouteComponentProps,
    ConnectedComponentProps,
    HandlingErrorWrappedProps {}
/**
 * Notificatins box
 */
export class NotificationBox extends Component<Props> {
  state = { open: false };
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  onClickShowAll = () => {
    this.setState({ open: false });
    this.props.history.push('/notifications');
  };

  /**
   * Load Data
   */
  async loadData() {
    try {
      const { activatedLoadingUnreadNotifications } = this.props;
      if (!activatedLoadingUnreadNotifications) {
        await this.props.getUnreadNotifications();
        initializeLoadUnreadNotificationsPeriodically();
      }
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  render() {
    let { unreadNotifications } = this.props;
    const { polyglot } = this.props;
    let totalUnreadNotifications =
      unreadNotifications && unreadNotifications.length;

    if (totalUnreadNotifications) {
      /* sort by the newest */
      unreadNotifications = SortUtil.multisort(
        unreadNotifications,
        ['timestamp'],
        ['DESC']
      );
      totalUnreadNotifications = unreadNotifications.length;
      unreadNotifications = unreadNotifications.slice(0, 5);
    }
    const trigger = (
      <STButtonIcon
        badge={totalUnreadNotifications}
        color="inherit"
        sx={{
          // withHandlingErrors create extra div :(
          height: '100%',
        }}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        <Icon name="bell" />
      </STButtonIcon>
    );
    return (
      <Popup
        trigger={trigger}
        on={['click']}
        position="bottom left"
        open={this.state.open}
      >
        <div className="notification-box">
          <Grid padded>
            {unreadNotifications.length <= 0 ? (
              <Grid.Row>
                <Grid.Column>
                  {polyglot.t('notifications.no_unread_message')}
                </Grid.Column>
              </Grid.Row>
            ) : (
              unreadNotifications.map((notification: any, index: number) => (
                <Grid.Row key={index.toString()}>
                  <div>
                    {moment(notification.timestamp).format(DATE_FORMAT)}
                  </div>
                  <br />
                  <div>{notification.summary}</div>
                </Grid.Row>
              ))
            )}
            <Grid.Row>
              <Grid.Column>
                <Button onClick={this.onClickShowAll} primary>
                  {polyglot.t('notifications.show_all_button')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  unreadNotifications: state.notifications.unread || [],
  activatedLoadingUnreadNotifications:
    state.notifications.activatedLoadingUnreadNotifications,
});
const connector = connect(mapStateToProps, { getUnreadNotifications });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(withPolyglot(withRouter(NotificationBox)))
);
