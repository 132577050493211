import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { saveAs } from 'file-saver';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../util/rights';
import { putIncident } from '../../../redux/incidents/actions/thunks';
import {
  addIncidentAttachment,
  getIncidentAttachments,
  getIncidentAttachmentContent,
} from '../../../redux/incidents/actions/thunks';
import ValidationUtil from '../../../util/ValidationUtil';
import {
  getLanguage,
  PolyglotComponentProps,
  withPolyglot,
} from '../../../i18n';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import {
  INCIDENT_STATUS_RESOLVED,
  INCIDENT_STATUS_NEW,
  INCIDENT_STATUS_INPROGRESS,
  INCIDENT_PRIORITY_LOW,
  INCIDENT_PRIORITY_HIGH,
  INCIDENT_PRIORITY_MEDIUM,
  ICON_PRIORITY,
  INCIDENT_STATUS,
} from '../Constants';
import './index.css';
import { IncidentAPIResponse } from '../../../model/incidentManager/incident.model';
import { UserAPIResponse } from '../../../model/user/user.model';
import { ErrorRestST } from '../../../rest/rest.model';
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import TextFieldFile from '../../commons/TextFieldFile';
import { getAttachmentsFromIncidentParam } from '../../../redux/incidents/selectors';
import { RootState } from '../../../redux/store.model';
import TextFieldReadOnly from '../../commons/TextFieldReadOnly';
/**
 * OwnProps needed because withSnackProps has bad types
 */
type OwnProps = {
  groupId?: string;
  incident: IncidentAPIResponse;
  users: UserAPIResponse[];
};
type Props = OwnProps &
  ConnectedComponentProps &
  RouteComponentProps &
  OpenSnackbarProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps &
  RightsUserUtilComponentProps;

type State = {
  incident: IncidentAPIResponse;
};

type EditableFields = 'comment' | 'summary' | 'description' | 'assignedUser';

/**
 * Group add: this component allows to create a new group
 */
export class Edit extends Component<Props, State> {
  validationUtil: ValidationUtil;
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.save = this.save.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.loadData = this.loadData.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.addIncidentAttachment = this.addIncidentAttachment.bind(this);
    this.attachments = this.attachments.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    const incident = { ...props.incident };
    this.fileInputRef = React.createRef();
    this.state = {
      incident,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * Load data
   * load rights and the attachments will be laoded here
   */
  loadData() {
    const { incident } = this.state;
    const { openSnackbar } = this.props;
    try {
      this.props.getIncidentAttachments(incident.id);
    } catch (error: any) {
      const errorDescription =
        error && typeof error === 'object'
          ? (error as ErrorRestST).message
          : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Update fields
   * @param string value
   * @param string field
   */
  updateFields(value: any, field: EditableFields) {
    const { incident } = this.state;
    incident[field] = value;
    this.setState({ incident: { ...incident, [field]: value } });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<any>
  ) => {
    this.updateFields(e.target.value, e.target.name as EditableFields);
  };

  /**
   *  Save
   */
  async save() {
    const { polyglot, openSnackbar, handlingErrorsApi } = this.props;
    try {
      const { incident } = this.state;
      const incidentSave = {
        assignedUser: incident.assignedUser,
        category: incident.category,
        comment: incident.comment,
        description: incident.description,
        priority: incident.priority,
        status: incident.status,
        summary: incident.summary,
        id: incident.id,
      };
      await this.props.putIncident(incident.id, incidentSave);
      const message = {
        text: polyglot.t('incident_manager.save_incident_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * addIncidentAttachment:
   * this function adds an attachment to the incident
   * @param object e
   */
  async addIncidentAttachment(e: React.ChangeEvent<HTMLInputElement>) {
    const { openSnackbar, polyglot, handlingErrorsApi } = this.props;
    try {
      const { incident } = this.state;
      const file = e.target.files && e.target.files[0];
      if (file) {
        await this.props.addIncidentAttachment(incident.id, file);
        const message = {
          text: polyglot.t(
            'incident_manager.add_incident_attachment_successful_message'
          ),
          type: 'ok',
        };
        openSnackbar(message);
      }
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Download attachment
   */
  async downloadAttachment(url: string, fileName: string | undefined) {
    const { incident, handlingErrorsApi } = this.props;
    try {
      const attachmentParameters = url.split('/');
      await this.props.getIncidentAttachmentContent(
        incident.id,
        attachmentParameters[attachmentParameters.length - 1]
      );
      const { attachment } = this.props;
      const blob = new Blob([attachment.content], {
        type: attachment.contentType,
      });
      await saveAs(blob, fileName);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Validation input data
   * this function check if the input data is valid
   */
  validationInputData() {
    /* Validation summary */
    const { incident } = this.state;
    return this.validationUtil.validateName(incident.summary);
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { history } = this.props;
    history.goBack();
  }

  /**
   * Attachments
   */
  attachments() {
    const { attachments, polyglot, groupId, rightsUserUtil, incident } =
      this.props;
    const updateIncidentRights = rightsUserUtil.hasRightsToUpdateIncident(
      incident.device,
      groupId
    );
    return (
      <div>
        <Card className="list-attachments">
          <CardContent>
            <Typography variant="subtitle1">
              {polyglot.t('incident_manager.attachments')}
            </Typography>

            {/* FIXME: Something is not working well here */}
            <Grid container spacing={2} marginTop={2}>
              {updateIncidentRights && (
                <Grid item xs={12}>
                  <TextFieldFile
                    label={polyglot.t('button.upload')}
                    onChange={this.addIncidentAttachment}
                    fullWidth
                  />
                </Grid>
              )}

              {attachments &&
                attachments.length > 0 &&
                attachments.map(
                  (
                    attachment: { fileName: any; size: any; url: any },
                    index: { toString: () => React.Key | null | undefined }
                  ) =>
                    updateIncidentRights ? (
                      <Grid
                        key={attachment.fileName}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                      >
                        <Button
                          startIcon={<DownloadIcon />}
                          fullWidth
                          variant="outlined"
                          onClick={() =>
                            this.downloadAttachment(
                              attachment.url,
                              attachment.fileName
                            )
                          }
                        >{`${attachment.fileName} (${attachment.size}B)`}</Button>
                      </Grid>
                    ) : (
                      `${attachment.fileName} (${attachment.size}B)`
                    )
                )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  render() {
    const { incident } = this.state;
    const { users, polyglot, groupId, rightsUserUtil } = this.props;
    const updateIncidentRights = rightsUserUtil.hasRightsToUpdateIncident(
      this.props.incident.device,
      groupId
    );
    const summary =
      (incident.localizedSummaries &&
        incident.localizedSummaries[getLanguage()]) ||
      incident.summary;

    const description =
      (incident.localizedDescriptions &&
        incident.localizedDescriptions[getLanguage()]) ||
      incident.description;

    const optionsStatus = [
      {
        key: INCIDENT_STATUS_NEW,
        value: INCIDENT_STATUS_NEW,
        text: polyglot.t('incident_manager.status_options.new'),
      },
      {
        key: INCIDENT_STATUS_INPROGRESS,
        value: INCIDENT_STATUS_INPROGRESS,
        text: polyglot.t('incident_manager.status_options.inprogress'),
      },
      {
        key: INCIDENT_STATUS_RESOLVED,
        value: INCIDENT_STATUS_RESOLVED,
        text: polyglot.t('incident_manager.status_options.resolved'),
      },
    ];

    const optionsPriority = [
      {
        key: INCIDENT_PRIORITY_LOW,
        value: INCIDENT_PRIORITY_LOW,
        text: polyglot.t('incident_manager.priority_options.low'),
        icon: ICON_PRIORITY[INCIDENT_PRIORITY_LOW].icon,
      },
      {
        key: INCIDENT_PRIORITY_MEDIUM,
        value: INCIDENT_PRIORITY_MEDIUM,
        text: polyglot.t('incident_manager.priority_options.medium'),
        icon: ICON_PRIORITY[INCIDENT_PRIORITY_MEDIUM].icon,
      },
      {
        key: INCIDENT_PRIORITY_HIGH,
        value: INCIDENT_PRIORITY_HIGH,
        text: polyglot.t('incident_manager.priority_options.high'),
        icon: ICON_PRIORITY[INCIDENT_PRIORITY_HIGH].icon,
      },
    ];
    const optionsUsers =
      users && users !== undefined && users.length > 0
        ? users.map((user) => ({
            key: user.id,
            value: user.id,
            text: user.name ? user.name : user.email,
          }))
        : [];

    return (
      <div>
        {/* Summary */}
        {updateIncidentRights ? (
          <>
            <form>
              <Grid container spacing={2}>
                {/* Readonly for summary and description to avoid <http://jira.st.local/jira/browse/P900004016-1063> */}
                <Grid item xs={12}>
                  <TextFieldReadOnly
                    label={polyglot.t('incident_manager.summary')}
                  >
                    {summary}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldReadOnly
                    label={polyglot.t('incident_manager.description')}
                  >
                    {description}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {polyglot.t('incident_manager.status')}
                    </InputLabel>
                    <Select
                      value={incident.status}
                      name="status"
                      id="status"
                      label={polyglot.t('incident_manager.status')}
                      onChange={this.onChange}
                      fullWidth
                    >
                      {optionsStatus.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {polyglot.t('incident_manager.priority')}
                    </InputLabel>
                    <Select
                      value={incident.priority}
                      name="priority"
                      id="priority"
                      label={polyglot.t('incident_manager.priority')}
                      onChange={this.onChange}
                      fullWidth
                    >
                      {optionsPriority.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {polyglot.t('incident_manager.assigned_user')}
                    </InputLabel>
                    <Select
                      value={incident.assignedUser}
                      name="assignedUser"
                      id="assignedUser"
                      label={polyglot.t('incident_manager.assigned_user')}
                      onChange={this.onChange}
                      fullWidth
                    >
                      {optionsUsers.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* Comment */}
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    id="comment"
                    name="comment"
                    onChange={this.onChange}
                    label={polyglot.t('incident_manager.comment')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.attachments()}
                </Grid>
                <Grid item container justifyContent="flex-end" flexGrow={1}>
                  <Button
                    type="button"
                    id="closeButton"
                    onClick={this.closeWindow}
                  >
                    {polyglot.t('button.close')}
                  </Button>
                  <Button
                    variant="contained"
                    id="saveButton"
                    type="button"
                    onClick={this.save}
                    disabled={!this.validationInputData}
                  >
                    {polyglot.t('button.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldReadOnly label={polyglot.t('incident_manager.summary')}>
                {summary}
              </TextFieldReadOnly>
            </Grid>
            <Grid item xs={12}>
              <TextFieldReadOnly
                label={polyglot.t('incident_manager.description')}
              >
                {description}
              </TextFieldReadOnly>
            </Grid>
            <Grid item xs={6}>
              <TextFieldReadOnly label={polyglot.t('incident_manager.status')}>
                {INCIDENT_STATUS[incident.status]
                  ? polyglot.t(
                      `incident_manager.status_options.${
                        INCIDENT_STATUS[incident.status].polyglot
                      }`
                    )
                  : incident.status}
              </TextFieldReadOnly>
            </Grid>
            <Grid item xs={6}>
              <TextFieldReadOnly
                label={polyglot.t('incident_manager.priority')}
              >
                {ICON_PRIORITY[incident.priority] !== undefined
                  ? polyglot.t(
                      `incident_manager.priority_options.${
                        ICON_PRIORITY[incident.priority].polyglot
                      }`
                    )
                  : incident.priority}
              </TextFieldReadOnly>
            </Grid>
            {incident.assignedUser && (
              <Grid item xs={12}>
                <TextFieldReadOnly
                  label={polyglot.t('incident_manager.assigned_user')}
                >
                  {users.length > 0 && users !== undefined
                    ? users.find((u) => u.id === incident.assignedUser)?.name
                    : ''}
                </TextFieldReadOnly>
              </Grid>
            )}
            {incident.comment && (
              <Grid item xs={12}>
                <TextFieldReadOnly
                  label={polyglot.t('incident_manager.comment')}
                >
                  {incident.comment}
                </TextFieldReadOnly>
              </Grid>
            )}
            {incident.cu && (
              <Grid item xs={12}>
                <TextFieldReadOnly
                  label={polyglot.t('incident_manager.titles_columns.cu')}
                >
                  {incident.cu}
                </TextFieldReadOnly>
              </Grid>
            )}
            <Grid item xs={12}>
              {this.attachments()}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  attachments: getAttachmentsFromIncidentParam(state, props),
  attachment: state.incidents.attachment,
});
const connector = connect(mapStateToProps, {
  putIncident,
  addIncidentAttachment,
  getIncidentAttachments,
  getIncidentAttachmentContent,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withSnackbar(withRouter(withPolyglot(withUserRightUtil(Edit))))
  )
);
