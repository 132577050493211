import {
  CardActions,
  CardContent,
  Card,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { IncidentConfigAPI } from '../../../model/incidentManager/incident.model';
import { UserAPIResponse } from '../../../model/user/user.model';

interface Props extends PolyglotComponentProps {
  incidentConfiguration?: IncidentConfigAPI;
  optionsUsers?: {
    key: string;
    value: string;
  }[];
  users?: UserAPIResponse[];
  notificationConfigUsers: string[] | undefined;
  updateFields: (
    event: {
      target: {
        value: any;
      };
    },
    field: keyof IncidentConfigAPI
  ) => void;
  updateDefaultDescriptionsFields: (
    event: {
      target: {
        value: any;
      };
    },
    field: string | number
  ) => void;
  updateDiagnosisOptionsFields: (
    event: {
      target: {
        value: string;
      };
    },
    index: number
  ) => void;
  updateUsersNotificationConfig: (
    event: any,
    data: {
      value?: any;
    }
  ) => void;
  save: () => Promise<unknown>;
  closeEditGroup: () => unknown;
}

/**
 * Group edit incident configuration desktop
 */
export function GroupIncidentConfigurationEditView(props: Props) {
  const {
    incidentConfiguration = {} as IncidentConfigAPI,
    users = [],
    optionsUsers = [],
    notificationConfigUsers = [],
    updateFields,
    polyglot,
    updateDefaultDescriptionsFields,
    updateDiagnosisOptionsFields,
    updateUsersNotificationConfig,
    save,
    closeEditGroup,
  } = props;
  if (Object.keys(incidentConfiguration).length === 0) {
    return <div />;
  }
  const notificationConfigUserOptions =
    users.length > 0
      ? users.map((user) => {
          const name = user.name ? user.name : user.email;
          return {
            key: user.id,
            value: user.id,
            text: name,
          };
        })
      : [];

  const defaultDescriptionsKeys = Object.keys(
    incidentConfiguration.defaultDescriptions
  );
  return (
    <form>
      <Stack spacing={2}>
        {/* Assigned user */}
        <FormControl fullWidth>
          <InputLabel>
            {polyglot.t('group.incident_configuration.default_assignee')}
          </InputLabel>
          <Select
            id="assignedUser"
            label={polyglot.t('group.incident_configuration.default_assignee')}
            onChange={(event) => updateFields(event, 'defaultAssignee')}
            value={incidentConfiguration.defaultAssignee}
          >
            {optionsUsers.map((record) => (
              <MenuItem key={record.key} value={record.key}>
                {record.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Default descriptions */}
        <Card>
          <CardContent id="defaultDescriptions">
            <Typography variant="subtitle1" paddingY={1}>
              {polyglot.t('group.incident_configuration.default_descriptions')}
            </Typography>
            <Stack spacing={2}>
              {defaultDescriptionsKeys.map((key) => (
                <TextField
                  key={key}
                  label={key}
                  fullWidth
                  onChange={(event) =>
                    updateDefaultDescriptionsFields(event, key)
                  }
                  value={incidentConfiguration.defaultDescriptions[key]}
                />
              ))}
            </Stack>
          </CardContent>
        </Card>
        {/* Diagnosis descriptions */}
        <Card>
          <CardContent id="diagnosisOptions">
            <Typography variant="subtitle1" paddingY={1}>
              {polyglot.t('group.incident_configuration.diagnosis_options')}
            </Typography>
            <Stack spacing={2}>
              {incidentConfiguration.diagnosisOptions.map(
                (diagnosisOption, index) => (
                  <TextField
                    key={index}
                    fullWidth
                    onChange={(event) =>
                      updateDiagnosisOptionsFields(event, index)
                    }
                    value={diagnosisOption}
                  />
                )
              )}
            </Stack>
          </CardContent>
        </Card>
        {/* Notifications */}
        <div>
          <Typography variant="subtitle1">
            {polyglot.t('group.incident_configuration.notification_users')}
          </Typography>

          <Dropdown
            placeholder={polyglot.t('group.users.user')}
            fluid
            multiple
            search
            selection
            options={notificationConfigUserOptions}
            onChange={(event, data) =>
              updateUsersNotificationConfig(event, data)
            }
            noResultsMessage={polyglot.t('group.users.no_users_to_assign')}
            value={notificationConfigUsers}
            id="notificationConfigUsers"
          />
        </div>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={closeEditGroup}>
            {polyglot.t('group.close_button_title')}
          </Button>
          <Button id="saveButton" onClick={save} variant="contained">
            {polyglot.t('group.save_button_title')}
          </Button>
        </CardActions>
      </Stack>
    </form>
  );
}

export default withPolyglot(GroupIncidentConfigurationEditView);
