import { Paper, Typography } from '@mui/material';
import React from 'react';
import { ControlUnitAPIResponse } from '../../../../../../../../../../../../model/controlUnit/ControlUnit.model';
import { SetPointEventMixed } from '../../../../../../../../../../../../model/controlUnit/model/SetPoint.model';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import SetPoint from './SetPoint';

interface SetPointsProps {
  usedSetPoint: SetPointEventMixed | undefined;
  controlUnit: ControlUnitAPIResponse | undefined;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export default function SetPoints(props: SetPointsProps) {
  const { usedSetPoint, controlUnit } = props;
  return (
    <div className="ControlUnit-SetPoints-container">
      <Paper variant="outlined" sx={{ padding: 1 }}>
        <Typography variant="body1">SetPoints:</Typography>
        {usedSetPoint && controlUnit ? (
          <SetPoint
            {...props}
            setPoint={usedSetPoint}
            controlUnit={controlUnit}
          />
        ) : null}
      </Paper>
    </div>
  );
}
