import {
  IncidentAPIResponse,
  IncidentConfigAPI,
  PublicIncidentAPIResponse,
} from './incident.model';
import * as Constants from '../Constants';

/**
 * Model Group
 * This class transform the groups received by  the backend to the needed structure
 */
class IncidentModel {
  /**
   * Has incidents in progress
   * this function checks if the incidents array has all the incidents inProgress
   * (no incident in status new)
   */
  static hasIncidentInProgress = (
    incidents: (PublicIncidentAPIResponse | IncidentAPIResponse | undefined)[]
  ) => {
    const found = incidents.find(
      (incident) => (incident?.status as string) === Constants.STATUS_INPROGRESS
    );
    const foundNew = incidents.find(
      (incident) => (incident?.status as string) === Constants.STATUS_NEW
    );
    return found !== undefined && foundNew === undefined
      ? Constants.STATUS_INPROGRESS
      : '';
  };
  /**
   * Parse incident configuration
   * transform the response for the backend to the needed structure.
   * if there is not incident configuration for the groupId, we should still
   * return a incident configuration structure
   */
  static parseIncidentConfiguration(
    incidentConfigurationArg: IncidentConfigAPI | undefined,
    groupId: string
  ) {
    let incidentConfiguration = incidentConfigurationArg;
    /* if there is not incident configuration, we should create and empty object
    with the necessary structure */
    if (!incidentConfigurationArg) {
      incidentConfiguration = {
        groupID: groupId,
        defaultAssignee: '',
        defaultDescriptions: {},
        diagnosisOptions: [''],
        notificationConfig: [],
      };
    } else if (incidentConfiguration) {
      incidentConfiguration.defaultAssignee =
        incidentConfigurationArg.defaultAssignee
          ? incidentConfigurationArg.defaultAssignee
          : '';

      incidentConfiguration.defaultDescriptions =
        incidentConfigurationArg.defaultDescriptions
          ? incidentConfigurationArg.defaultDescriptions
          : {};

      incidentConfiguration.diagnosisOptions =
        incidentConfigurationArg.diagnosisOptions
          ? incidentConfigurationArg.diagnosisOptions
          : [''];

      incidentConfiguration.notificationConfig =
        incidentConfigurationArg.notificationConfig
          ? incidentConfigurationArg.notificationConfig
          : [];
    }
    /* add a new diagnosis options */
    if (
      incidentConfiguration?.diagnosisOptions &&
      incidentConfiguration?.diagnosisOptions !== null
    ) {
      if (
        incidentConfiguration.diagnosisOptions[
          incidentConfiguration.diagnosisOptions.length - 1
        ]
      ) {
        incidentConfiguration.diagnosisOptions.push('');
      }
    }

    return incidentConfiguration as IncidentConfigAPI;
  }

  static getAllUsersIdFromIncident(incident: IncidentAPIResponse): string[] {
    return (
      [
        incident.assignedUser,
        ...incident.history.map(
          (record) => record.user || record.assignedUser || ''
        ),
      ]
        // remove empty
        .filter((i) => i)
    );
  }
}

export default IncidentModel;
