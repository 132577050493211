import { createSelector } from '@reduxjs/toolkit';
import { getGroups } from '../../groups/selectors';
import { RootState } from '../../store.model';
import RightsUserUtil from '../../../util/rights/RightsUserUtil';
import { getFeatureToggle } from '../../../featureToggle';

export const getRightsList = (state: RootState) => state.rights.items;

export const getRightUserUtilSelector = createSelector(
  [getRightsList, getGroups, getFeatureToggle],
  (rightsLust, groups, featureToggle) =>
    new RightsUserUtil(rightsLust, groups, featureToggle)
);
