import moment from 'moment';
import { FlexeserveZone } from '../model';
import menus from './menu';
import { settings } from './settings';

const namesSettings = settings.map((item) => item.settingName);
export const maxNumberTimers = 4;
export const maxNumberZones = 5;

const getMenu = (index: number, settingName: string) => {
  const menusAvailable = menus.filter((item) => item.setting === settingName);
  if (!menusAvailable.length) return undefined;
  return menusAvailable[index % menusAvailable.length];
};
const getMockTimer = (index: number, settingName: string) => {
  const menu = getMenu(index, settingName);
  return menu
    ? { menu, dateStart: moment().add(-Math.round(Math.random() * 60)) }
    : undefined;
};
const getMockZone = (index: number): FlexeserveZone => {
  const active = index % 4 !== 0;
  const light = index % 10 === 0;
  const warning = index % 3 === 0 ? moment().add(2, 'minutes') : undefined;
  const settingName = namesSettings[index % namesSettings.length];
  return {
    settingName,
    active,
    light,
    warning,
    timers: new Array(active ? maxNumberTimers : 0)
      .fill(1)
      .map((v, i) => getMockTimer(i, settingName)),
  };
};
const zones = new Array(maxNumberZones).fill(1).map((v, i) => getMockZone(i));
export default zones;
