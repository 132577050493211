import { IconUpload } from '../../redux/groups/groups.model';
import { restClient as restClientHttp } from '../../rest';
import RestCalls from '../../rest/RestCalls';
import Util from '../../util/Util';

import * as callType from './CallTypes';
import {
  DeviceControlUnitsAPIResponse,
  DeviceDataAPIResponse,
  DeviceStatusAPIResponse,
} from './device.model';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class Calls {
  readonly backendCommanderManagerURL = new Util().getCommanderManagementURL();
  readonly backendDeviceStatusServiceURL =
    new Util().getDeviceStatusServiceURL();
  readonly backendIconURL = new Util().getCommanderIconURL();
  constructor(
    private restCalls = new RestCalls(restClientHttp),
    private restClient = restClientHttp
  ) {}

  /**
   * Get associated devices
   * @return array devices
   */
  async getAssociatedDevices() {
    // https://staging.commander-cloud.eu/api/commander/
    const url = `${this.backendCommanderManagerURL}/`;
    return this.restClient.callPromise<DeviceDataAPIResponse[]>(
      { url, method: 'GET' },
      callType.getAssociatedDevices
    );
  }

  /**
   * Get device data
   * this function calls the service POST deviceManager/data to get the data of
   * a list of ids passed into the body
   * @param array devicesId
   * @return array devices
   */
  async getDevicesDataByIds(devicesIds: string[]) {
    // https://staging.commander-cloud.eu/api/commander/data?ids=["ZmVmMGJkNGItNDNiOS00NzE0LWE4ZjEtYTAxNzhiZTE2Yzdh"]
    const url = `${this.backendCommanderManagerURL}/data?ids=${JSON.stringify(
      devicesIds
    )}`;
    return this.restClient.callPromise<DeviceDataAPIResponse[]>(
      { url, method: 'GET' },
      callType.getCommanderByIds
    );
  }

  /**
   * Get device by id
   * @param string deviceId
   * @return object device
   */
  async getDeviceById(deviceId: string) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.getCommanderById(
        deviceId,
        (response: { entity: unknown }) => resolve(response),
        reject
      );
    });

    return promise;
  }

  /**
   * Get device status
   * get the status of a device
   * @param string(encode base64) deviceId
   */
  getDeviceStatusById(deviceId: string) {
    const url = `${this.backendDeviceStatusServiceURL}/${deviceId}`;
    // return Promise.resolve({
    //   deviceID: deviceId,
    //   state: 'OK',
    // } as DeviceStatusAPIResponse);
    return this.restClient.callPromise<DeviceStatusAPIResponse | null>(
      {
        url,
        method: 'GET',
      },
      callType.getDeviceStatusById
    );
  }
  /**
   * Get device IP by id
   * this function call the service devices to get the device
   * @param string deviceId
   */
  async getDeviceIPById(deviceId: string) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}/ip`;
    return this.restClient.callPromise<{
      ip: string;
    }>(
      {
        url,
        method: 'GET',
      },
      callType.getDeviceIPById
    );
  }

  /**
   * Get device by cloud connect id
   * this function call the service devices to get the total of associated devices
   * @param string cloudConnectID
   */
  async getDeviceByCloudConnectId(cloudConnectID: string) {
    const cloudConnectIDBase64 = btoa(cloudConnectID);
    const commanderByCloudIdUrl = `${this.backendCommanderManagerURL}/cloudConnectID/${cloudConnectIDBase64}`;

    return this.restClient.callPromise<DeviceDataAPIResponse>(
      { url: commanderByCloudIdUrl, method: 'GET' },
      callType.getDeviceByCloudConnectId
    );
  }

  /**
   * Get events by devices id
   * @param array devicesIds
   */
  async getControlUnitsByDeviceId(deviceId: string) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}/controlunits`;

    return this.restClient
      .callPromise<DeviceControlUnitsAPIResponse[]>(
        {
          url,
          method: 'GET',
        },
        callType.getEventsByDeviceId
      )
      .then((data) => ({
        id: deviceId,
        controlunits: data,
      }));
  }

  /**
   * Get events by devices id
   * @param array devicesIds
   */
  async getControlUnitsByDevicesId(devicesIds: string[]) {
    if (!devicesIds || devicesIds.length === 0) {
      return [];
    }
    const promises = devicesIds.map(async (id) =>
      this.getControlUnitsByDeviceId(id)
    );
    return Promise.all(promises);
  }

  /**
   * Post associated device
   * this function associated a device to the user
   * @param string cloudConnectID
   */
  async postAssociatedDevice(cloudConnectID: string) {
    const entity = {
      cloudConnectID,
    };
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.restClient.callPromise(
      {
        url: this.backendCommanderManagerURL,
        method: 'POST',
        entity,
        headers,
      },
      callType.postAssociatedDevice
    );
  }

  /**
   * Delete associated device
   * this function delete a device to the user
   * @param string deviceId
   */
  async deleteAssociatedDevice(deviceId: string) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}`;
    return this.restClient
      .callPromise<void>(
        { url, method: 'DELETE' },
        callType.deleteAssociatedDevice
      )
      .then((_) => deviceId);
  }

  /**
   * Update device
   * @param string deviceId
   * @param object deviceData
   * @return promise
   */
  updateDevice(deviceId: string, deviceData: any) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to send the commander data
    return this.restClient.callPromise<DeviceDataAPIResponse>(
      {
        url,
        method: 'PUT',
        headers,
        entity: deviceData,
      },
      callType.updateDevice
    );
  }

  /**
   * Save avatar
   * @param string deviceId
   * @param object iconUpload
   * @return promise
   */
  saveIcon(deviceId: string, iconUpload: any) {
    const form = new FormData();
    form.append('file', iconUpload.file, iconUpload.fileName);
    const url = `${this.backendCommanderManagerURL}/${deviceId}/${this.backendIconURL}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: form,
      },

      callType.saveIcon
    );
  }

  /**
   * Delete icon
   * @param string deviceId
   * @return promise
   */
  deleteIcon(deviceId: string) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}/${this.backendIconURL}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to delete/reset the icon
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callType.getAssociatedDevices
    );
  }

  /**
   * Put device
   * update a device
   * @param string deviceId
   * @param object deviceData
   * @param object iconUpload
   * @return promise
   */
  async putDevice(
    deviceId: string,
    deviceData: DeviceDataAPIResponse,
    iconUpload?: IconUpload
  ) {
    if (iconUpload) {
      await this.saveIcon(deviceId, iconUpload);
    }
    const device = await this.updateDevice(deviceId, deviceData);

    if (iconUpload) {
      /**
       * We add the current date in the icon URL to force to reload the updated icon
       * NOTE: not work enough fast
       */
      // return { ...device, iconURL: `${device.iconURL}?${Moment().valueOf()}` };
      /**
       * Use imagePreview to avoid the cache in cloudfront
       */
      return { ...device, iconURL: iconUpload.imagePreviewUrl };
    }
    return device;
  }
}

export default Calls;
