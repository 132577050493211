import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import { DigitalChannelEventMixed } from '../../../../../../../model/monitoring/monitoring.model';
import moment from 'moment';
import { useCUTranslate } from '../../../../../../../util/CUTranslation/hook/useCUTranslate';

interface AlarmsListProps {
  alarms: DigitalChannelEventMixed[];
  deviceId: string;
  controlUnitId: string;
}
export default function AlarmsList(props: AlarmsListProps) {
  const { alarms, deviceId, controlUnitId } = props;
  const [CUTranslate] = useCUTranslate(deviceId);
  return (
    <List
      sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
      aria-label="alarms"
    >
      {alarms.map((alarm) => (
        <ListItem key={`${alarm.eventID}-${alarm.timestamp}`}>
          <ListItemIcon sx={{ minWidth: '3rem' }}>
            <CircleIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary={
              CUTranslate(
                alarm.name ?? alarm.eventID,
                controlUnitId,
                'ram',
                'longText'
              ) as string
            }
            secondary={moment(alarm.timestamp).fromNow()}
            secondaryTypographyProps={{
              title: moment(alarm.timestamp).format('LLL'),
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}
