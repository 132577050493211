import { FlexeserveMenu } from '../model';

/* eslint-disable prettier/prettier */
const menus: FlexeserveMenu[] = [
    {itemName:'Soup', displayName:'Soup', holdTime:60*10, prepTime:30, setting:'Std Medium'},
    {itemName:'Porridge', displayName:'Prgde',holdTime: 60*1+30,prepTime:30, setting:'Std Medium'},
    {itemName:'Hot Wrap', displayName:'HotW',holdTime: 60*8,prepTime:15, setting:'Std Medium'},
    {itemName:'Breakfast Bun',displayName: 'BF B', holdTime:60*7, prepTime:10, setting:'Std Medium'},
    {itemName:'Savoury Croissant',displayName:'SavC', holdTime:60*20,prepTime:20, setting:'Std Medium'},
    {itemName:'Sweet Crois sant',displayName: 'SwtC',holdTime: 60*10,prepTime:20, setting:'Std Low'},
    {itemName:'Mac & Cheese', displayName:'M&C', holdTime:60*40,prepTime:20, setting:'Std Medium'},
    {itemName:'Sausage Roll',displayName: 'M&C',holdTime:60*10,prepTime:20, setting:'Std High'},
    {itemName:'Whole Chicken',displayName: 'Chic', holdTime:60*10+30,prepTime:30, setting:'Std High'},
]
export default menus