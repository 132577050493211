import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { Box, Grid } from '@mui/material';
import { connect } from 'react-redux';
import GroupMyDevicesOverview from '../GroupMyDevices/Overview/GroupMyDeviceOverviewList';
import { withPolyglot } from '../../../../i18n';
import {
  STATUS_OK,
  STATUS_ALERT,
  STATUS_OK_INPROGRESS,
  STATUS_ALERT_INPROGRESS,
} from '../Constants';
import GroupIconDefault from '../../../../assets/GroupIconDefaultLandscape.svg';
import DeviceIcon from '../../../../assets/IconST7Landscape.svg';
import { getUnAssignedDevices } from '../../../../redux/groups/selectors';
import ListItem from '../../../commons/ListItem';

const cssGroupsStatus = {};
cssGroupsStatus[STATUS_OK] = 'ok';
cssGroupsStatus[STATUS_ALERT] = 'alert';
cssGroupsStatus[STATUS_OK_INPROGRESS] = 'ok_inprogress';
cssGroupsStatus[STATUS_ALERT_INPROGRESS] = 'alert_inprogress';

export function GroupsOverviewList(props) {
  const {
    groups,
    groupIdParent,
    navigate,
    polyglot,
    buttonsActions,
    myDevices,
  } = props;
  /* get devices associated to the user and not located in a group */
  const devices = myDevices;
  return (
    <Grid container className="groups-overview-list">
      {/* If the group is the root group and the variable devicesTotal > 0,
          then the seudo group mydevices will be shown */}
      {(groupIdParent === null || groupIdParent === undefined) &&
        devices.length > 0 && <GroupMyDevicesOverview devices={devices} />}
      {groups.map((group) => (
        <ListItem
          key={group.id}
          id={group.id}
          className={`group ${cssGroupsStatus[group.status]}`}
          name={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {group.name}
              {group.devices && group.devices.length > 0 && (
                <div>
                  <Image
                    src={DeviceIcon}
                    size="mini"
                    inline
                    label={{
                      content: group.devices.length,
                      color: 'red',
                      size: 'mini',
                      floating: true,
                      circular: true,
                      className: 'label-quantity-device',
                    }}
                    className="icon-devices"
                    id="iconQuantityDevices"
                  />
                </div>
              )}
            </Box>
          }
          iconURL={group.iconURL ? group.iconURL : GroupIconDefault}
          onClick={() => navigate(group.id)}
          enable
          buttons={buttonsActions(group)}
        />
      ))}
    </Grid>
  );
}

GroupsOverviewList.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      iconURL: PropTypes.string,
      status: PropTypes.string,
      childGroups: PropTypes.array,
      devices: PropTypes.array,
      attributes: PropTypes.object,
    })
  ).isRequired,
  myDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupIdParent: PropTypes.string,
  buttonsActions: PropTypes.func.isRequired,
};

GroupsOverviewList.defaultProps = {
  groupIdParent: null,
};
const mapStateToProps = (state, props) => ({
  myDevices: getUnAssignedDevices(state, props),
});

export default connect(mapStateToProps)(withPolyglot(GroupsOverviewList));
