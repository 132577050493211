import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { Button } from '@mui/material';
import BrowserUtil from '../../../util/BrowserUtil';
import FleetManagerInfoImageDesktop from '../../../assets/FleetManagerInfo.svg';
import FleetManagerInfoImagePhone from '../../../assets/FleetManagerInfoPhone.jpg';
import FleetManagerInfoImageTablet from '../../../assets/FleetManagerInfoTablet.jpg';
import { withPolyglot } from '../../../i18n';
import './index.css';
import { STModal } from '../../commons/Modal';

const browserUtil = new BrowserUtil();
const isMobile = browserUtil.getIsMobile();
const isTablet = browserUtil.getIsTablet();

class FleetManagerInfo extends Component {
  static getDerivedStateFromProps(nextProps) {
    return {
      showFleetManagerInfo: nextProps.showFleetManagerInfo,
    };
  }

  constructor(props) {
    super(props);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.state = {
      showFleetManagerInfo: props.showFleetManagerInfo,
    };
  }

  closeFleetManagerInfo() {
    this.props.closeFleetManagerInfo();
  }

  render() {
    let fleetManagerInfoImage = FleetManagerInfoImageDesktop;
    if (isMobile) {
      fleetManagerInfoImage = isTablet
        ? FleetManagerInfoImageTablet
        : FleetManagerInfoImagePhone;
    }

    return (
      <STModal
        open={this.state.showFleetManagerInfo}
        onClose={this.closeFleetManagerInfo}
        title={this.props.polyglot.t('fleet_manager_info.title')}
        buttonActions={
          <Button onClick={this.closeFleetManagerInfo}>
            {this.props.polyglot.t('fleet_manager_info.close_button_title')}
          </Button>
        }
      >
        <p>{this.props.polyglot.t('fleet_manager_info.description_header')}</p>
        <Image src={fleetManagerInfoImage} alt="" />
      </STModal>
    );
  }
}

FleetManagerInfo.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  showFleetManagerInfo: PropTypes.bool.isRequired,
  closeFleetManagerInfo: PropTypes.func.isRequired,
};

export default withPolyglot(FleetManagerInfo);
