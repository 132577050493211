import SettingsCalls from '../../../../../../model/settings/Calls';
import { AppDispatch } from '../../../../../../redux/store.model';
import { FlexeserveZone } from '../../model';
import { KEY_FLEXESERVEZONES } from '../constants';
// --------------
// Simple thunks
// --------------
export const saveZones =
  (zones: FlexeserveZone[]) => (dispatch: AppDispatch) => {
    const settingsCalls = new SettingsCalls();
    return settingsCalls.setSetting(KEY_FLEXESERVEZONES, JSON.stringify(zones));
  };
