interface OptionsNavProps {
  controlUnitId?: string;
  groupId?: string;
  deviceId?: string;
}
// ALL_GROUPS in not supported
export type ShowLevelOption =
  | 'GROUP'
  | 'DEVICE'
  | 'CONTROL_UNIT'
  | 'ALL_GROUPS';
export const getLevelOfView = ({
  controlUnitId,
  deviceId,
  groupId,
}: OptionsNavProps): ShowLevelOption => {
  if (deviceId && controlUnitId) return 'CONTROL_UNIT';
  if (groupId && !deviceId) return 'GROUP';
  if (deviceId) return 'DEVICE';
  return 'ALL_GROUPS';
};
