import {
  FETCH_GROUP_CAMERAS,
  FETCH_PAIRABLE_CAMERAS,
  FETCH_CAMERA_IMAGE,
  FETCH_CAMERA_VIDEO,
} from '../actions/Types';

const initialState = {
  groups: {},
  pairable: {},
  images: {},
  videos: {},
};

export default function reducer(state = initialState, action) {
  const groups = { ...state.groups };
  const pairable = { ...state.pairable };
  const images = { ...state.images };
  const videos = { ...state.videos };

  switch (action.type) {
    case FETCH_GROUP_CAMERAS:
      groups[action.payload.groupID] = action.payload.cameras;
      return {
        ...state,
        groups,
      };
    case FETCH_PAIRABLE_CAMERAS:
      pairable[action.payload.groupID] = action.payload.cameras;
      return {
        ...state,
        pairable,
      };
    case FETCH_CAMERA_IMAGE:
      images[action.payload.cameraID] = action.payload.image;
      return {
        ...state,
        images,
      };
    case FETCH_CAMERA_VIDEO:
      videos[action.payload.cameraID] = action.payload.video;
      return {
        ...state,
        videos,
      };
    default:
      return state;
  }
}
