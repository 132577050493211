import initialTogglesDictionary from './initialTogglesDictionary';
import { FeatureToggleState } from './redux/featureToggle.model';
import { DictionaryComponents, InferToggleComponent } from './ToggleComponent';

export default function getToggleUtil<T>(
  defaultValue: T,
  dictionaryComponents: DictionaryComponents<T> = {},
  featureToggles: FeatureToggleState = initialTogglesDictionary
) {
  return InferToggleComponent({
    DefaultComponent: defaultValue,
    dictionaryComponents,
    featureToggles,
  });
}
