import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader, Image } from 'semantic-ui-react';
import './index.css';
import { Typography } from '@mui/material';

/**
 * User profile mobil
 * Note: this component must be not written as a pure function because by props changes
 * the component doesn't render
 */
export default function UserPicture(props) {
  const { showImageLoad, iconUpload, user } = props;
  let avatar = user.avatarLocation;
  if (iconUpload && iconUpload.imagePreviewUrl) {
    avatar = iconUpload.imagePreviewUrl;
  }
  const avatarShow = avatar ? (
    <Image inline size="tiny" src={avatar} floated="left" />
  ) : (
    <Icon name="user" size="huge" className="right" floated="right" />
  );

  return (
    <div className="user-profile-header">
      <div>{showImageLoad ? <Loader active inline /> : avatarShow}</div>
      <div>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="subtitle2">
          <Icon name="point" />
          {user.location}
        </Typography>
      </div>
    </div>
  );
}

UserPicture.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    email: PropTypes.string,
    avatarLocation: PropTypes.string,
    preferredLanguage: PropTypes.string,
  }),
  iconUpload: PropTypes.shape({
    fileName: PropTypes.string,
    file: PropTypes.object,
    imagePreviewUrl: PropTypes.string,
  }).isRequired,
  showImageLoad: PropTypes.bool,
};

UserPicture.defaultProps = {
  user: {},
  showImageLoad: false,
};
