import React, { Component } from 'react';
import { Segment, Image, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchImage } from '../../../../redux/cameras/actions';
import { withPolyglot } from '../../../../i18n';
import './index.css';
import { STModal } from '../../../commons/Modal';
import { Button } from '@mui/material';

export class ImageView extends Component {
  constructor(props) {
    super(props);
    this.getSnapshot = this.getSnapshot.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { group, camera, images, show } = this.props;
    if (
      (group && camera && !images[camera.id] && show) ||
      (show && !prevProps.show)
    ) {
      this.getSnapshot();
    }
  }

  getSnapshot() {
    const { group, camera, show } = this.props;
    this.props.fetchImage(group, camera.id);
    setTimeout(() => {
      if (show) {
        this.getSnapshot();
      }
    }, 10000);
  }

  render() {
    const { images, camera, show, close, polyglot } = this.props;
    const image = images[camera.id];
    return (
      <STModal
        open={show}
        title={camera.name}
        onClose={close}
        buttonActions={
          <Button onClick={close}>
            {polyglot.t('device.cancel_button_title')}
          </Button>
        }
      >
        <Segment className="camera-container">
          {image ? (
            <Image
              className="camera-image"
              src={`data:image/jpeg;base64,${image}`}
            />
          ) : (
            <Loader active inline />
          )}
        </Segment>
      </STModal>
    );
  }
}

ImageView.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
  }).isRequired,
  group: PropTypes.any.isRequired,
  camera: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired,
  images: PropTypes.object.isRequired,
  fetchImage: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  images: state.cameras.images,
});

export default connect(mapStateToProps, { fetchImage })(
  withPolyglot(ImageView)
);
