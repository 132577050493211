/**
 * Code calls
 */
export const getUnresolvedIncidentsByIds =
  'incidentManager/getUnresolvedIncidentsByIds';
export const getPublicIncidentsByIds =
  'incidentManager/getPublicIncidentsByIds';
export const getIncidentsByIds = 'incidentManager/getIncidentsByIds';
export const getIncidentsByDeviceId = 'incidentManager/getIncidentsByDeviceId';
export const getIncidentByIncidentId =
  'incidentManager/getIncidentByIncidentId';
export const addIncidentAttachment = 'incidentManager/addIncidentAttachment';
export const getIncidentAttachments = 'incidentManager/getIncidentAttachments';
export const putIncident = 'incidentManager/putIncident';
export const getIncidentConfigurationByGroupId =
  'incidentManager/getIncidentConfigurationByGroupId';
export const putIncidentConfiguration =
  'incidentManager/putIncidentConfiguration';
