import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

interface ButtonLoadingProps extends ButtonProps {
  loading?: boolean;
}
/**
 * Same button but with loader
 */
export default function ButtonLoading(props: ButtonLoadingProps) {
  const { loading, disabled, children, ...buttonProps } = props;
  return (
    <Button disabled={loading || disabled} {...buttonProps}>
      {!loading ? children : <CircularProgress size={20} />}
    </Button>
  );
}
