import { AnyAction } from 'redux';
import { GET_MODULES, ADD_VOUCHER } from '../actions/Types';

// FIXME: bad types
interface State {
  modules: any[];
  voucher: Record<string, any>;
}
const initialState = {
  modules: [],
  voucher: {},
};

export default function reducer(
  state: State = initialState,
  action: AnyAction
): State {
  switch (action.type) {
    case GET_MODULES:
      return {
        ...state,
        modules: [...state.modules, ...action.payload],
      };
    case ADD_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      };
    default:
      return state;
  }
}
