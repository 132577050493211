import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withPolyglot } from '../i18n';

class CookiePolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    const { polyglot } = this.state;
    return (
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h2>{polyglot.t('cookie_policy.title')}</h2>
              <p>{polyglot.t('cookie_policy.intro')}</p>
              <h3>{polyglot.t('cookie_policy.definition_title')}</h3>
              <p>{polyglot.t('cookie_policy.definition')}</p>
              <h3>{polyglot.t('cookie_policy.usage_title')}</h3>
              <p>{polyglot.t('cookie_policy.usage')}</p>
              <h3>{polyglot.t('cookie_policy.control_title')}</h3>
              <p>{polyglot.t('cookie_policy.control')}</p>
              <p>{polyglot.t('cookie_policy.agree_by_use')}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

CookiePolicyPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(CookiePolicyPage);
