import { createSelector } from '@reduxjs/toolkit';
import { Device } from '../../../model/device/device.model';
import { getRightUserUtilSelector } from '../../rights/selectors';

const getDeviceIdFromDeviceFromProps = (_: any, props: { device: Device }) =>
  props.device.uuid;
const getGroupIdFromProps = (_: any, props: { groupId?: string }) =>
  props.groupId;
export const deviceButtonsRightsSelector = createSelector(
  [
    getDeviceIdFromDeviceFromProps,
    getGroupIdFromProps,
    getRightUserUtilSelector,
  ],
  (deviceId, groupId, rightsUserUtil) => {
    const showButtonReadIncidents = rightsUserUtil.hasRightsToReadIncident(
      deviceId,
      groupId
    );
    const showDeviceInfo = rightsUserUtil.isSupportUser();
    const showDeviceSensordataIcon = rightsUserUtil.hasRightsToReadHistory();
    const showDeviceEditIcon = rightsUserUtil.hasRightsToUpdateDevice(
      deviceId,
      groupId
    );

    return {
      showDeviceInfo,
      showDeviceEditIcon,
      showButtonReadIncidents,
      showDeviceSensordataIcon,
    };
  }
);
