import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Transition } from 'semantic-ui-react';
import { withSnackbar } from 'stoerk-ui-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  passwordTokenValidation,
  updatePassword,
} from '../../redux/auth/actions';
import ValidationUtil from '../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { OpenSnackbarProps } from '../../handlingErrors';
import { RootState } from '../../redux/store.model';
import {
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
  Button,
  Container,
} from '@mui/material';
import ErrorText from '../commons/ErrorText';

interface ResetPasswordPageProps
  extends OpenSnackbarProps,
    PolyglotComponentProps,
    RouteComponentProps,
    ConnectedComponentProps {}

interface State {
  animation: any;
  duration: number;
  visible: boolean;
  tokenValid: boolean;
  passwordConfirmed: null | boolean;
  password: string;
  passwordConfirm: string;
  token?: string;
}

export class ResetPasswordPage extends Component<
  ResetPasswordPageProps,
  State
> {
  validationUtil: ValidationUtil;
  constructor(props: ResetPasswordPageProps) {
    super(props);
    const { polyglot } = props;
    this.activeTransition = this.activeTransition.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.validationPasswordToken = this.validationPasswordToken.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);

    this.state = {
      animation: null,
      duration: 500,
      visible: true,
      tokenValid: false,
      passwordConfirmed: null,
      password: '',
      passwordConfirm: '',
    };
  }

  async componentDidMount() {
    this.validationPasswordToken();
    this.onCompleteTransition();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  /**
   * Set password
   * @param object event
   */
  setPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { passwordConfirm } = this.state;
    const pass = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      pass,
      passwordConfirm
    );
    this.setState({ password: pass, passwordConfirmed: confirmed });
  }

  /**
   * Validation password token
   */
  async validationPasswordToken() {
    const { location, polyglot, openSnackbar } = this.props;
    const { search } = location;
    const params = search.split('&');
    let token;

    params.forEach((p) => {
      const split = p.split('=');
      if (split[0] === 'token' || split[0] === '?token') {
        token = split[1];
      }
    });
    this.setState({ token });
    try {
      await this.props.passwordTokenValidation(token);
      const { passwordTokenValid } = this.props;
      if (!passwordTokenValid) {
        /* show snack bar - error messsage */
        const message = {
          text: polyglot.t('reset_password.invalid_token_message'),
          type: 'error',
        };
        openSnackbar(message);
      }
      this.setState({ tokenValid: passwordTokenValid });
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Confirm password
   * @param object event
   */
  confirmPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { password } = this.state;
    const passConfirm = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      password,
      passConfirm
    );
    this.setState({
      passwordConfirm: passConfirm,
      passwordConfirmed: confirmed,
    });
  }

  /**
   * Update password
   */
  async updatePassword() {
    const { passwordConfirmed, token, password } = this.state;
    const { openSnackbar, polyglot, history } = this.props;
    try {
      if (!passwordConfirmed) {
        this.activeTransition();
        return;
      }
      await this.props.updatePassword(token, password);
      const message = {
        text: polyglot.t('reset_password.successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
      history.push('/');
    } catch (error: any) {
      /* show snack bar - error messsage */
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  render() {
    const {
      animation,
      duration,
      visible,
      passwordConfirm,
      passwordConfirmed,
      password,
      tokenValid,
    } = this.state;
    const { polyglot } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Transition
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <Paper>
            <Typography component="h1" variant="h4" textAlign="center">
              {polyglot.t('reset_password.title')}
            </Typography>
            <Box
              sx={{
                width: '100%', // Fix IE 11 issue.
                marginTop: (theme) => theme.spacing(3),
                padding: (theme) => theme.spacing(2),
              }}
            >
              <form>
                <Stack spacing={2}>
                  <TextField
                    type="password"
                    onChange={this.setPassword}
                    label={polyglot.t('login.password')}
                    required
                  />
                  <TextField
                    type="password"
                    onChange={this.confirmPassword}
                    label={polyglot.t('register.confirm_pass')}
                    required
                    error={Boolean(passwordConfirm && !passwordConfirmed)}
                    helperText={
                      password &&
                      passwordConfirm &&
                      !passwordConfirmed && (
                        <ErrorText>
                          {this.validationUtil.getErrorMessage(
                            'passwordConfirmation'
                          )}
                        </ErrorText>
                      )
                    }
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    disabled={!tokenValid}
                    onClick={this.updatePassword}
                  >
                    {polyglot.t('register.button_title')}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Paper>
        </Transition>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  passwordTokenValid: state.auth.passwordTokenValid,
});

const connector = connect(mapStateToProps, {
  passwordTokenValidation,
  updatePassword,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, {
  passwordTokenValidation,
  updatePassword,
})(withRouter(withSnackbar(withPolyglot(ResetPasswordPage))));
