export const styles = {
  '@global': {
    '.landing-page .fleet-manager, .landing-page .recipes, .landing-page .contact':
      {
        'padding-left': '10px',
        'padding-right': '10px',
      },
    '.landing-page .fleet-manager': {
      'background-color': '#073288 !important;',
    },
    '.landing-page .contact': {
      'background-color': '#ef8900 !important;',
    },
    '.landing-page .fleet-manager:hover a, .landing-page .fleet-manager:hover':
      {
        color: '#073288 !important;',
      },
    '.landing-page .contact:hover a, .landing-page .contact:hover': {
      color: '#ef8900 !important;',
    },
  },
};
