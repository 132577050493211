import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../redux/store.model';
import zonesDefault from '../mocks/zones';
import { FlexeserveZone } from '../model';
import { KEY_FLEXESERVEZONES } from './constants';

const getZonesFeatureToggle = (state: RootState) =>
  state.featureToggle[KEY_FLEXESERVEZONES] as string | undefined;
export const getFlexeserveZones = createSelector(
  [getZonesFeatureToggle],
  (zonesSaved) => {
    const zones: FlexeserveZone[] = zonesSaved
      ? JSON.parse(zonesSaved)
      : zonesDefault;
    return zones;
  }
);
