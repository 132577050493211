import { Typography, Box, BoxProps } from '@mui/material';
import React from 'react';

interface Props extends BoxProps {
  label: string;
}
/**
 * Use TextFieldReadOnly to show fields readonly in forms
 *
 */
export default function TextFieldReadOnly(props: Props) {
  const { label, children } = props;
  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
}
