import { Container, Paper } from '@mui/material';
import React, { useState } from 'react';
import { maxNumberTimers } from '../../mocks/zones';
import { FlexeserveMenu, FlexeserveTimer, FlexeserveZone } from '../../model';
import FlexeserveTimerCell from './components/FlexeserveTimerCell';
import { FlexeserveZoneCellHeader } from './components/FlexeserveZoneCellHeader';
import { useTick } from '../../util/hook/useTick';
import moment from 'moment';
import produce from 'immer';
import { saveZones } from '../../redux/actions/thunks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { getFlexeserveZones } from '../../redux/selectors';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../handlingErrors';

export function FlexeserveLayoutGridSimple(props: HandlingErrorWrappedProps) {
  // swap texts
  const [showTime, setShowTime] = useState(false);
  useTick(
    () => () => {
      setShowTime(!showTime);
    },
    5 * 1000
  );
  const dispatch = useAppDispatch();
  /**
   * Update zones with cloud to sync all devices
   * FIXME: handlingErrorsApi errors
   * @param zonesToSave
   */
  const updateZones = async (zonesToSave: FlexeserveZone[]) => {
    try {
      await dispatch(saveZones(zonesToSave));
    } catch (error) {
      props.handlingErrorsApi(error);
    }
  };
  const flexeserveZonesLoaded = useAppSelector(getFlexeserveZones);
  const [zones, setZonesState] = useState(flexeserveZonesLoaded);
  const setSettingZone = (
    settingName: string,
    _zone: FlexeserveZone,
    i: number
  ) => {
    const light = settingName === 'ZONE_LIGHT';
    const active = !(settingName === 'ZONE_OFF' || light);
    const newZone: FlexeserveZone = {
      active,
      light,
      timers: new Array(active ? maxNumberTimers : 0).fill(undefined),
      settingName,
      warning: moment().add(2, 'minutes'),
    };

    setZonesState(
      produce((draftZones) => {
        draftZones[i] = newZone;
        updateZones(draftZones);
      })
    );
  };

  const setTimer = (menu: FlexeserveMenu, zone: FlexeserveZone, i: number) => {
    const indexZone = zones.findIndex((zoneItem) => zoneItem === zone);
    const newTimer: FlexeserveTimer = { menu, dateStart: moment() };
    setZonesState(
      produce((draftZones) => {
        draftZones[indexZone].timers[i] = newTimer;
        updateZones(draftZones);
      })
    );
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ overflow: 'auto' }}
      className="FlexeserveLayoutGridPage"
    >
      <Paper
        className="FlexeserveLayoutGrid"
        variant="outlined"
        // Remove paper in mobile
        sx={{
          padding: { md: 2 },
          borderWidth: { xs: '0px', md: '1px' },
        }}
      >
        <table>
          <tbody>
            {zones.map((zone, i) => (
              <FlexeserveZoneRow
                key={i}
                index={i}
                zone={zone}
                showTime={showTime}
                setSettingZone={setSettingZone}
                setTimer={setTimer}
              />
            ))}
          </tbody>
        </table>
      </Paper>
    </Container>
  );
}

const ConnectedFlexeserveLayoutGrid = withHandlingErrors(
  FlexeserveLayoutGridSimple
);
export default ConnectedFlexeserveLayoutGrid;

interface FlexeserveZoneRow {}
const FlexeserveZoneRow = (props: {
  index: number;
  zone: FlexeserveZone;
  showTime: boolean;
  setSettingZone: (setting: string, zone: FlexeserveZone, i: number) => void;
  setTimer: (menu: FlexeserveMenu, zone: FlexeserveZone, i: number) => void;
}) => (
  <tr className="FlexeserveLayoutGrid-row row">
    <th>
      <FlexeserveZoneCellHeader
        index={props.index}
        zone={props.zone}
        setSettingZone={props.setSettingZone}
      />
    </th>

    {props.zone.timers.map((timer, j) => (
      <td key={j}>
        <FlexeserveTimerCell
          index={j}
          timer={timer}
          zone={props.zone}
          showTime={props.showTime}
          setTimer={props.setTimer}
        />
      </td>
    ))}
  </tr>
);
