import React from 'react';
import { Box } from '@mui/material';
import { ConnectedMonitoringScreen } from '../Devices/components/Monitoring/ConnectedMonitoringScreen';

export default function GroupMonitoringPage() {
  return (
    <Box sx={{ mb: 2 }}>
      <ConnectedMonitoringScreen />
    </Box>
  );
}
