/**
 * Compare util
 * help call to compare objects
 */
export default class CompareUtil {
  /**
   * Shallow equal
   * this function compares two objects to check if they are equal or not
   * Performs equality by iterating through keys on an object and returning false
   * when any key has values which are not strictly equal between the arguments.
   * Returns true when the values of all keys are strictly equal.
   */
  static shallowEqual(objA, objB) {
    if (objA === objB) {
      return true;
    }

    if (
      typeof objA !== 'object' ||
      objA === null ||
      typeof objB !== 'object' ||
      objB === null
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    // Test for A's keys different from B.
    const bHasOwnProperty = hasOwnProperty.bind(objB);
    for (let i = 0; i < keysA.length; i += 1) {
      if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }

    return true;
  }

  /**
   * shallowCompare
   * this function is used to help the methode shouldComponentUpdate to decide if
   * the component should be rendered or not
   */
  static shallowCompare(instance, nextProps, nextState) {
    return (
      !CompareUtil.shallowEqual(instance.props, nextProps) ||
      !CompareUtil.shallowEqual(instance.state, nextState)
    );
  }

  /**
   * Objects compare
   * return true if both compare are the same
   */
  static objectsCompare(obj1, obj2) {
    return CompareUtil.shallowEqual(obj1, obj2);
  }

  /**
   * Array compare
   * @param array array1
   * @param array array1
   * @return bool
   */
  static arrayCompare(array1, array2) {
    const i = array1.length;
    if (i !== array2.length) return false;
    for (let cont = 0; cont < i; cont += 1) {
      if (array1[cont] !== array2[cont]) return false;
    }
    return true;
  }
}
