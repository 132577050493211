import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Dropdown, Transition, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { withHandlingErrors } from '../../../../../handlingErrors';
import ValidationUtil from '../../../../../util/ValidationUtil';
import CompareUtil from '../../../../../util/CompareUtil';
import fetchRoles from '../../../../../redux/roles/actions';
import { putRolesGroupUser } from '../../../../../redux/users/actions';
import { withPolyglot } from '../../../../../i18n';
import { STModal } from '../../../../commons/Modal';
import getRolesNamesDescriptions from './Constants';

/**
 * Group user update role
 * this class uses to update the user's role
 */
export class GroupUserUpdateRole extends Component {
  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  constructor(props) {
    super(props);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.setRolesId = this.setRolesId.bind(this);
    this.activeTransition = this.activeTransition.bind(this);
    this.updateRoleGroupUser = this.updateRoleGroupUser.bind(this);
    this.validationData = this.validationData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    /* the variable userInvitations will have all the added users by email */
    this.userInvitations = [];
    this.state = {
      rolesId: props.rolesId.slice(),
      animation: null,
      duration: 500,
      visible: true,
    };
  }

  async componentDidMount() {
    this.onCompleteTransition();
    await this.loadData();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  setRolesId(event, data) {
    this.setState({ rolesId: data.value });
  }

  /**
   * Load data
   */
  async loadData() {
    try {
      await this.props.fetchRoles();
    } catch (error) {
      const { openSnackbar } = this.props;
      const message = { text: error, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  /**
   * Add role group user
   */
  async updateRoleGroupUser() {
    const { rolesId } = this.state;
    const {
      closeGroupUserUpdateRole,
      polyglot,
      openSnackbar,
      handlingErrorsApi,
    } = this.props;
    if (!rolesId || rolesId.length === 0) {
      this.activeTransition();
      return;
    }
    if (!CompareUtil.arrayCompare(this.props.rolesId, rolesId)) {
      try {
        const { userId, groupId } = this.props;
        await this.props.putRolesGroupUser(
          userId,
          rolesId,
          this.props.rolesId,
          groupId
        );
        closeGroupUserUpdateRole(true);
        const message = {
          text: polyglot.t('group.users.update_role_successful_message'),
          type: 'ok',
        };
        openSnackbar(message);
      } catch (error) {
        handlingErrorsApi(error);
      }
    } else {
      /* if the user didn't select other role, still we should close the
      window after click the button save */
      closeGroupUserUpdateRole();
    }
  }

  /**
   * Validation data
   * this function validates the data before to be saved
   */
  validationData() {
    const { rolesId } = this.state;
    if (!rolesId || rolesId.length === 0) {
      return false;
    }

    return true;
  }

  render() {
    const { userName, roles, rolesId, polyglot, closeGroupUserUpdateRole } =
      this.props;
    const { animation, duration, visible } = this.state;
    /* options for the drowpdown roles */
    const rolesOptions = getRolesNamesDescriptions(roles).map((r) => ({
      key: r.id,
      value: r.id,
      text: r.name,
      icon: r.description,
    }));
    return (
      <div>
        <Transition
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
          onHide={this.onHideTransition}
        >
          <STModal
            title={polyglot.t('group.users.assign_dialog_title')}
            onClose={closeGroupUserUpdateRole}
            maxWidth="md"
            fullWidth
            // SemanticDropDown popup
            sx={{
              '&& .MuiDialogContent-root,.MuiPaper-root': {
                overflowY: 'initial',
              },
            }}
            open
          >
            <Form className="formular-material-design">
              <Form.Field>
                {`${polyglot.t('group.users.user')}: ${userName} `}
              </Form.Field>
              {rolesOptions.length > 0 && (
                <Form.Field>
                  <Dropdown
                    placeholder={polyglot.t('group.users.role')}
                    fluid
                    selection
                    multiple
                    options={rolesOptions}
                    defaultValue={rolesId}
                    onChange={(event, data) => this.setRolesId(event, data)}
                    id="roles"
                  />
                </Form.Field>
              )}
              <Segment basic id="placeHolderDropdowOpen" />
              <Button
                primary
                onClick={(event) => this.updateRoleGroupUser(event)}
              >
                {polyglot.t('group.users.update_role_button_title')}
              </Button>
              <Button
                type="button"
                floated="right"
                onClick={closeGroupUserUpdateRole}
              >
                {polyglot.t('group.cancel_button_title')}
              </Button>
            </Form>
          </STModal>
        </Transition>
      </div>
    );
  }
}

GroupUserUpdateRole.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  rolesId: PropTypes.arrayOf(PropTypes.string),
  userName: PropTypes.string,
  closeGroupUserUpdateRole: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  putRolesGroupUser: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GroupUserUpdateRole.defaultProps = {
  userId: null,
  userName: null,
  rolesId: [],
};

const mapStateToProps = (state) => ({
  roles: state.roles.items,
});

export default connect(mapStateToProps, {
  fetchRoles,
  putRolesGroupUser,
})(withHandlingErrors(withSnackbar(withPolyglot(GroupUserUpdateRole))));
