import Model from '../../../model';
import {
  SET_MAINTENANCE_MESSAGE_DEVICE,
  GET_MAINTENANCE_MESSAGES_BY_DEVICE,
  UPDATE_MAINTENANCE_MESSAGE_DEVICE,
  DELETE_MAINTENANCE_MESSAGE_BY_DEVICE,
  LOADING_MAINTENANCE_MESSAGES_DEVICE,
  SET_MAINTENANCE_MESSAGE_GROUP,
  GET_MAINTENANCE_MESSAGE_BY_GROUP,
  UPDATE_MAINTENANCE_MESSAGE_GROUP,
  DELETE_MAINTENANCE_MESSAGE_BY_GROUP,
  LOADING_MAINTENANCE_MESSAGES_GROUP,
} from './Types';

/**
 * Set maintenance message device
 * @param string deviceId
 * @param object message
 */
export const setMaintenanceMessageDevice =
  (deviceId, message) => async (dispatch) => {
    const model = new Model();
    const messageNew = await model.setMaintenanceMessageDevice(
      deviceId,
      message
    );
    Object.assign(message, { id: messageNew.id });
    dispatch({
      type: SET_MAINTENANCE_MESSAGE_DEVICE,
      payload: {
        messageId: messageNew.id,
        deviceId,
        message,
      },
    });
  };

/**
 * Get maintenance message device
 * @param string deviceId
 */
export const getMaintenanceMessagesDevice = (deviceId) => async (dispatch) => {
  dispatch({
    type: LOADING_MAINTENANCE_MESSAGES_DEVICE,
    payload: {
      deviceId,
      loading: true,
    },
  });

  const model = new Model();
  let messages = await model.getMaintenanceMessagesDevice(deviceId);
  /* by update a maintenance message, the fields descriptions and messages can not
  be null, because of that we need to change this value to {} */
  messages.forEach((message) => {
    if (message.messages === null) {
      Object.assign(message, { messages: {} });
    }
    if (message.descriptions === null) {
      Object.assign(message, { descriptions: {} });
    }
  });
  /* the frontend need the following structure: { id, descriptions, interval, messages}  */
  messages = messages.map((m) => ({
    id: m.id,
    descriptions: m.descriptions,
    interval: m.interval,
    messages: m.messages,
  }));
  setTimeout(() => {
    dispatch({
      type: GET_MAINTENANCE_MESSAGES_BY_DEVICE,
      payload: {
        deviceId,
        messages,
      },
    });

    dispatch({
      type: LOADING_MAINTENANCE_MESSAGES_DEVICE,
      payload: {
        deviceId,
        loading: false,
      },
    });
  }, 2000);
};

/**
 * Update maintenance message device
 * @param string deviceId
 * @param string messageId
 * @param object message
 */
export const updateMaintenanceMessageDevice =
  (deviceId, messageId, message) => async (dispatch) => {
    const model = new Model();
    await model.updateMaintenanceMessageDevice(deviceId, messageId, message);
    dispatch({
      type: UPDATE_MAINTENANCE_MESSAGE_DEVICE,
      payload: {
        deviceId,
        messageId,
        message,
      },
    });
  };

/**
 * Delete maintenance message device
 * @param string deviceId
 * @param string messageId
 */
export const deleteMaintenanceMessageDevice =
  (deviceId, messageId) => async (dispatch) => {
    const model = new Model();
    await model.deleteMaintenanceMessageDevice(deviceId, messageId);
    dispatch({
      type: DELETE_MAINTENANCE_MESSAGE_BY_DEVICE,
      payload: {
        deviceId,
        messageId,
      },
    });
  };

/**
 * Set maintenance message group
 * @param string groupId
 * @param object message
 */
export const setMaintenanceMessageGroup =
  (groupId, message) => async (dispatch) => {
    const model = new Model();
    const messageNew = await model.setMaintenanceMessageGroup(groupId, message);
    Object.assign(message, { id: messageNew.id });
    dispatch({
      type: SET_MAINTENANCE_MESSAGE_GROUP,
      payload: {
        messageId: messageNew.id,
        groupId,
        message,
      },
    });
  };

/**
 * Get maintenance message group
 * @param string groupId
 */
export const getMaintenanceMessagesGroup = (groupId) => async (dispatch) => {
  dispatch({
    type: LOADING_MAINTENANCE_MESSAGES_GROUP,
    payload: {
      groupId,
      loading: true,
    },
  });
  const model = new Model();
  let messages = await model.getMaintenanceMessagesGroup(groupId);
  /* by update a maintenance message, the fields descriptions and messages can not
  be null, because of that we need to change this value to {} */
  messages.forEach((message) => {
    if (message.messages === null) {
      Object.assign(message, { messages: {} });
    }
    if (message.descriptions === null) {
      Object.assign(message, { descriptions: {} });
    }
  });
  /* the frontend need the following structure: { id, descriptions, interval, messages}  */
  messages = messages.map((m) => ({
    id: m.id,
    descriptions: m.descriptions,
    interval: m.interval,
    messages: m.messages,
  }));
  setTimeout(() => {
    dispatch({
      type: GET_MAINTENANCE_MESSAGE_BY_GROUP,
      payload: {
        groupId,
        messages,
      },
    });
    dispatch({
      type: LOADING_MAINTENANCE_MESSAGES_GROUP,
      payload: {
        groupId,
        loading: false,
      },
    });
  }, 2000);
};

/**
 * Update maintenance message group
 * @param string groupId
 * @param string messageId
 * @param object message
 */
export const updateMaintenanceMessageGroup =
  (groupId, messageId, message) => async (dispatch) => {
    const model = new Model();
    await model.updateMaintenanceMessageGroup(groupId, messageId, message);
    dispatch({
      type: UPDATE_MAINTENANCE_MESSAGE_GROUP,
      payload: {
        groupId,
        messageId,
        message,
      },
    });
  };

/**
 * Delete maintenance message group
 * @param string groupId
 * @param string messageId
 */
export const deleteMaintenanceMessageGroup =
  (groupId, messageId) => async (dispatch) => {
    const model = new Model();
    await model.deleteMaintenanceMessageGroup(groupId, messageId);
    dispatch({
      type: DELETE_MAINTENANCE_MESSAGE_BY_GROUP,
      payload: {
        groupId,
        messageId,
      },
    });
  };
