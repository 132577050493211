import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import store from '../../redux/Store';
import BrowserUtil from '../../util/BrowserUtil';
import ConnectContactUs from '../contactUs';
import ConnectFleetManagerInfo from './fleetManagerInfo';
import ConnectRecipesInfo from './recipesInfo';
import { withPolyglot } from '../../i18n';
import './index.css';

import imageLandingPage1Desktop from '../../assets/LandingPage.jpg';
import imageLandingPage2Desktop from '../../assets/LandingPageBakeTechnique.jpg';
import imageLandingPage3Desktop from '../../assets/LandingPageFryingTechnique1_Desktop.jpg';
import imageLandingPage4Desktop from '../../assets/LandingPageBakeTechnique2.jpg';
import imageLandingPage5Desktop from '../../assets/LandingPageBakeTechnique3.jpg';
import imageLandingPage6Desktop from '../../assets/LandingPageIndustrialRefrigerationTechnology.jpg';
import imageLandingPage7Desktop from '../../assets/LandingPageIndustrialRefrigeration.jpg';
import imageLandingPage8Desktop from '../../assets/LandingPageIndustrialRefrigerationTechnology2.jpg';
import imageLandingPage9Desktop from '../../assets/LandingPageMedicalTechnology.jpg';
import imageLandingPage10Desktop from '../../assets/LandingPageFryingTechnique2_Desktop.jpg';

import imageLandingPage1Phone from '../../assets/LandingPagePhone.jpg';
import imageLandingPage2Phone from '../../assets/LandingPageBakeTechniquePhone.jpg';
import imageLandingPage3Phone from '../../assets/LandingPageFryingTechnique1_PhonePortrait.jpg';
import imageLandingPage4Phone from '../../assets/LandingPageBakeTechnique2Phone.jpg';
import imageLandingPage5Phone from '../../assets/LandingPageBakeTechnique3Phone.jpg';
import imageLandingPage6Phone from '../../assets/LandingPageIndustrialRefrigerationTechnologyPhone.jpg';
import imageLandingPage7Phone from '../../assets/LandingPageIndustrialRefrigerationPhone.jpg';
import imageLandingPage8Phone from '../../assets/LandingPageIndustrialRefrigerationTechnology2Phone.jpg';
import imageLandingPage9Phone from '../../assets/LandingPageMedicalTechnologyPhone.jpg';
import imageLandingPage10Phone from '../../assets/LandingPageFryingTechnique2_Phone.jpg';

import imageLandingPage1PhoneLandscape from '../../assets/LandingPagePhoneLandscape.jpg';
import imageLandingPage2PhoneLandscape from '../../assets/LandingPageBakeTechniquePhoneLandscape.jpg';
import imageLandingPage3PhoneLandscape from '../../assets/LandingPageFryingTechnique1_PhoneLandscape.jpg';
import imageLandingPage4PhoneLandscape from '../../assets/LandingPageBakeTechnique2PhoneLandscape.jpg';
import imageLandingPage5PhoneLandscape from '../../assets/LandingPageBakeTechnique3PhoneLandscape.jpg';
import imageLandingPage6PhoneLandscape from '../../assets/LandingPageIndustrialRefrigerationTechnologyPhoneLandscape.jpg';
import imageLandingPage7PhoneLandscape from '../../assets/LandingPageIndustrialRefrigerationPhoneLandscape.jpg';
import imageLandingPage8PhoneLandscape from '../../assets/LandingPageIndustrialRefrigerationTechnology2PhoneLandscape.jpg';
import imageLandingPage9PhoneLandscape from '../../assets/LandingPageMedicalTechnologyPhoneLandscape.jpg';
import imageLandingPage10PhoneLandscape from '../../assets/LandingPageFryingTechnique2_PhoneLandscape.jpg';

import imageLandingPage1Tablet from '../../assets/LandingPageTablet.jpg';
import imageLandingPage2Tablet from '../../assets/LandingPageBakeTechniqueTablet.jpg';
import imageLandingPage3Tablet from '../../assets/LandingPageFryingTechnique1_TabletPortrait.jpg';
import imageLandingPage4Tablet from '../../assets/LandingPageBakeTechnique2Tablet.jpg';
import imageLandingPage5Tablet from '../../assets/LandingPageBakeTechnique3Tablet.jpg';
import imageLandingPage6Tablet from '../../assets/LandingPageIndustrialRefrigerationTechnologyTablet.jpg';
import imageLandingPage7Tablet from '../../assets/LandingPageIndustrialRefrigerationTablet.jpg';
import imageLandingPage8Tablet from '../../assets/LandingPageIndustrialRefrigerationTechnology2Tablet.jpg';
import imageLandingPage9Tablet from '../../assets/LandingPageMedicalTechnologyTablet.jpg';
import imageLandingPage10Tablet from '../../assets/LandingPageFryingTechnique2_Tablet.jpg';

import imageLandingPage1TabletLandscape from '../../assets/LandingPageTabletLandscape.jpg';
import imageLandingPage2TabletLandscape from '../../assets/LandingPageBakeTechniqueTabletLandscape.jpg';
import imageLandingPage3TabletLandscape from '../../assets/LandingPageFryingTechnique1_TabletLandscape.jpg';
import imageLandingPage4TabletLandscape from '../../assets/LandingPageBakeTechnique2TabletLandscape.jpg';
import imageLandingPage5TabletLandscape from '../../assets/LandingPageBakeTechnique3TabletLandscape.jpg';
import imageLandingPage6TabletLandscape from '../../assets/LandingPageIndustrialRefrigerationTechnologyTabletLandscape.jpg';
import imageLandingPage7TabletLandscape from '../../assets/LandingPageIndustrialRefrigerationTabletLandscape.jpg';
import imageLandingPage8TabletLandscape from '../../assets/LandingPageIndustrialRefrigerationTechnology2TabletLandscape.jpg';
import imageLandingPage9TabletLandscape from '../../assets/LandingPageMedicalTechnologyTabletLandscape.jpg';
import imageLandingPage10TabletLandscape from '../../assets/LandingPageFryingTechnique2_TabletLandscape.jpg';

const browserUtil = new BrowserUtil();
let isPhone = browserUtil.getIsPhone();
let isTablet = browserUtil.getIsTablet();
const isMobile = browserUtil.getIsMobile();

export class LandingPage extends Component {
  /**
   * Columns grid boxes isMobile
   * in mobile devices tablet and phones by the landscape orientation the boxes should be
   * showed in the same row. by the portrait rotation the boxes will be showed vertically
   */
  static columnsGridBoxesMobile() {
    return browserUtil.getOrientation() === browserUtil.getLandscape() ? 5 : 9;
  }

  constructor(props) {
    super(props);
    this.orientationChange = this.orientationChange.bind(this);
    this.openContactUs = this.openContactUs.bind(this);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.openFleetManagerInfo = this.openFleetManagerInfo.bind(this);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.openRecipesInfo = this.openRecipesInfo.bind(this);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.selectPicturesGalery = this.selectPicturesGalery.bind(this);
    this.resizeBackgroundImages = this.resizeBackgroundImages.bind(this);
    this.state = {
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
      showContactUs: false,
      showFleetManagerInfo: false,
      showRecipesInfo: false,
    };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.orientationChange);
    this.resizeBackgroundImages();
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.orientationChange);
  }

  /**
   * Resize background images
   * this funcion will be called by device monitor and layout relation (width:hight)
   * bigger as 16:9. in this case we should push the margin to fill out the complete
   * background
   */
  resizeBackgroundImages() {
    if (this.allowNegativeMargin === true) {
      /*
       * rationScreenSize = width - (heigt * 100) / defaultRationScreenSizeDesktop
       * defaultRationScreenSizeDesktop = 16:9 = 56
       */
      const ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
      if (ratioScreenSize > browserUtil.defaultRationScreenSizeDesktop) {
        let addMargin =
          browserUtil.getOuterWidth() -
          (browserUtil.getOuterHeight() * 100) /
            browserUtil.defaultRationScreenSizeDesktop;
        addMargin = Math.round(addMargin / 2, 0);

        const updateMargins = (element) => {
          const item = element;
          item.style.marginLeft = `${addMargin}px`;
          item.style.marginRight = `${addMargin}px`;
        };
        [...document.getElementsByClassName('background-images')].forEach(
          updateMargins
        );
        [...document.getElementsByClassName('image-gallery')].forEach(
          updateMargins
        );
      }
    }
  }

  selectPicturesGalery() {
    /* Ratio screen size: this variable has the relation between the width and the
     * height of the screen. Phone and monitor (16:9 = 56.25), Tablet (3:4 = 75)
     */
    let ratioScreenSize = 0;
    if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
    } else {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterWidth() * 100) / browserUtil.getOuterHeight(),
        0
      );
    }

    this.allowNegativeMargin = false;

    if (
      isTablet &&
      ratioScreenSize < browserUtil.defaultRationScreenSizeTablet
    ) {
      isPhone = true;
      isTablet = false;
    }

    let imagesGalleryLandingPage = [];
    if (isPhone) {
      this.allowNegativeMargin = false;
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imagesGalleryLandingPage = [
          { original: imageLandingPage1PhoneLandscape },
          { original: imageLandingPage2PhoneLandscape },
          { original: imageLandingPage3PhoneLandscape },
          { original: imageLandingPage4PhoneLandscape },
          { original: imageLandingPage5PhoneLandscape },
          { original: imageLandingPage6PhoneLandscape },
          { original: imageLandingPage7PhoneLandscape },
          { original: imageLandingPage8PhoneLandscape },
          { original: imageLandingPage9PhoneLandscape },
          { original: imageLandingPage10PhoneLandscape },
        ];
      } else {
        imagesGalleryLandingPage = [
          { original: imageLandingPage1Phone },
          { original: imageLandingPage2Phone },
          { original: imageLandingPage3Phone },
          { original: imageLandingPage4Phone },
          { original: imageLandingPage5Phone },
          { original: imageLandingPage6Phone },
          { original: imageLandingPage7Phone },
          { original: imageLandingPage8Phone },
          { original: imageLandingPage9Phone },
          { original: imageLandingPage10Phone },
        ];
      }
    } else if (isTablet) {
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imagesGalleryLandingPage = [
          { original: imageLandingPage1TabletLandscape },
          { original: imageLandingPage2TabletLandscape },
          { original: imageLandingPage3TabletLandscape },
          { original: imageLandingPage4TabletLandscape },
          { original: imageLandingPage5TabletLandscape },
          { original: imageLandingPage6TabletLandscape },
          { original: imageLandingPage7TabletLandscape },
          { original: imageLandingPage8TabletLandscape },
          { original: imageLandingPage9TabletLandscape },
          { original: imageLandingPage10TabletLandscape },
        ];
      } else {
        imagesGalleryLandingPage = [
          { original: imageLandingPage1Tablet },
          { original: imageLandingPage2Tablet },
          { original: imageLandingPage3Tablet },
          { original: imageLandingPage4Tablet },
          { original: imageLandingPage5Tablet },
          { original: imageLandingPage6Tablet },
          { original: imageLandingPage7Tablet },
          { original: imageLandingPage8Tablet },
          { original: imageLandingPage9Tablet },
          { original: imageLandingPage10Tablet },
        ];
      }
    } else {
      imagesGalleryLandingPage = [
        { original: imageLandingPage1Desktop },
        { original: imageLandingPage2Desktop },
        { original: imageLandingPage3Desktop },
        { original: imageLandingPage4Desktop },
        { original: imageLandingPage5Desktop },
        { original: imageLandingPage6Desktop },
        { original: imageLandingPage7Desktop },
        { original: imageLandingPage8Desktop },
        { original: imageLandingPage9Desktop },
        { original: imageLandingPage10Desktop },
      ];

      this.allowNegativeMargin = true;
    }
    return imagesGalleryLandingPage;
  }

  /**
   * Orientation change
   * this function will be called by a orientation change between landscape and Portrait
   * in mobile devices
   */
  orientationChange() {
    this.setState({
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
    });
  }

  openContactUs() {
    this.setState({
      showContactUs: true,
    });
  }

  closeContactUs() {
    this.setState({ showContactUs: false });
  }

  openFleetManagerInfo() {
    const { loggedIn, history } = this.props;
    if (!loggedIn) {
      this.setState({ showFleetManagerInfo: true });
    } else {
      history.push('/devicemanager');
    }
  }

  closeFleetManagerInfo() {
    this.setState({ showFleetManagerInfo: false });
  }

  openRecipesInfo() {
    this.setState({ showRecipesInfo: true });
  }

  closeRecipesInfo() {
    this.setState({ showRecipesInfo: false });
  }

  render() {
    const { polyglot } = this.props;
    const {
      showContactUs,
      showFleetManagerInfo,
      showRecipesInfo,
      imagesGalleryLandingPage,
      columnsGridBoxesMobile,
    } = this.state;
    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <Provider store={store}>
        <div className="landing-page">
          <ConnectContactUs
            polyglot={polyglot}
            showContactUs={showContactUs}
            closeContactUs={this.closeContactUs}
          />
          <ConnectFleetManagerInfo
            polyglot={polyglot}
            showFleetManagerInfo={showFleetManagerInfo}
            closeFleetManagerInfo={this.closeFleetManagerInfo}
          />
          <ConnectRecipesInfo
            polyglot={polyglot}
            showRecipesInfo={showRecipesInfo}
            closeRecipesInfo={this.closeRecipesInfo}
          />

          <div className="container">
            <div className="background-images">
              <ImageGallery
                items={imagesGalleryLandingPage}
                showThumbnails={false}
                showBullets={!isMobile}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay
                slideDuration={4000}
                Id="imageGallery"
              />
            </div>
            <div className="information">
              <Grid centered columns={16} padded>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={columnsGridBoxesMobile}
                  mobile={columnsGridBoxesMobile}
                >
                  <div
                    className="fleet-manager"
                    onClick={this.openFleetManagerInfo}
                    onKeyPress={this.openFleetManagerInfo}
                    tabIndex="0"
                    role="link"
                  >
                    <Icon name="configure" size={isPhone ? 'large' : 'huge'} />
                    <p>{polyglot.t('landing_page.fleet_manager')}</p>
                  </div>
                </Grid.Column>

                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={columnsGridBoxesMobile}
                  mobile={columnsGridBoxesMobile}
                >
                  <div
                    className="contact"
                    onClick={this.openContactUs}
                    onKeyPress={this.openContactUs}
                    tabIndex="0"
                    role="link"
                  >
                    <Icon
                      name="info circle"
                      size={isPhone ? 'large' : 'huge'}
                      className="icon"
                    />
                    <p>{polyglot.t('landing_page.contact_us')}</p>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </Provider>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withPolyglot(LandingPage)));
