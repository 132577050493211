import {
  Device,
  DEVICE_STATUS_TYPE,
} from '../../../../../../model/device/device.model';
import { CLOUD_CONNECTIVITY, NO_CLOUD_CONNECTIVITY } from '../Constants';

export type DevicesOverviewProps = {
  devices: Device[];
  showButtonReadIncidentsDevices?: Record<string, boolean>;
  getDeviceInfoIcon(device: Device): React.ReactNode;
  getDeviceEditIcon(device: Device): React.ReactNode;
  showDeviceEditIcon: (deviceId: string) => boolean;
  getDeviceUIIcon(device: Device): React.ReactNode;
  getDeviceAlarmsIcon(device: Device): React.ReactNode;
  getDeviceCloudConnectivityIcon(device: Device): React.ReactNode;
  getDeviceSensordataIcon(device: Device): React.ReactNode;
  showDeviceSensordataIcon: boolean;
  getDeviceLabel(device: Device): React.ReactNode;
  onClickItem(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  itemDisabled(device: Device): boolean;
  deviceInfoEnabled?: boolean;
};

const cssDeviceStates = {
  [DEVICE_STATUS_TYPE.OK]: 'ok',
  [DEVICE_STATUS_TYPE.OK_INPROGRESS]: 'ok_inprogress',
  [DEVICE_STATUS_TYPE.ALERT]: 'alert',
  [DEVICE_STATUS_TYPE.ALERT_INPROGRESS]: 'alert_inprogress',
  [DEVICE_STATUS_TYPE.NEW]: '',
  [DEVICE_STATUS_TYPE.INPROGRESS]: '',
};
const cssDeviceCloudConnectivity = {
  [`${CLOUD_CONNECTIVITY}`]: 'cloud_connectivity',
  [`${NO_CLOUD_CONNECTIVITY}`]: 'no_cloud_connectivity',
};

const getCssDeviceStates = (device: Device) =>
  device.status ? cssDeviceStates[device.status] : '';

export const getClassesNamesFromDevice = (device: Device) =>
  `device ${
    cssDeviceCloudConnectivity[`${device.cloudConnectivity}`]
  } ${getCssDeviceStates(device)}`;
