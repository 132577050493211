/**
 * FIXME: WARN: This files is toooooo large, if we want it to be easy to maintain
 * it must be divided into several smaller files
 * we should split using Composition or Aggregation Pattern to have many files
 */

import axios from 'axios';
import Util from '../util/Util';

import {
  callTypeRemoveAssociatedDevice,
  callTypeGetCommanders,
  callTypeGetCommanderById,
  callTypeGetControlUnitsByDeviceId,
  callTypeUpdateGroup,
  callTypeSaveGroupAvatar,
  callTypeAddUserRights,
  callTypeDeleteUserRights,
  callTypeGetAvailableUsersToAssignGroup,
  callTypeAddAttachmentIncident,
  callTypeGetIntegrationRegistry,
  callTypeSetMaintenanceMessageDevice,
  callTypeSetMaintenanceMessageGroup,
  callTypeSetMaintenanceMessageVendor,
  callTypeUpdataMaintenanceMessageDevice,
  callTypeUpdataMaintenanceMessageGroup,
  callTypeUpdataMaintenanceMessageVendor,
  callTypeGetMaintenanceMessagesDevice,
  callTypeGetMaintenanceMessagesGroup,
  callTypeGetMaintenanceMessagesVendor,
  callTypeDeleteMaintenanceMessageDevice,
  callTypeDeleteMaintenanceMessageGroup,
  callTypeDeleteMaintenanceMessageVendor,
  callTypeGetCameras,
  callTypeGetPairableCameras,
  callTypePairCamera,
  callTypeSetRecipeGroup,
  callTypeGetRecipesGroup,
  callTypeGetRecipesUser,
  callTypeGetDevicesCoordinates,
} from './CallTypes';

/**
 * Backend commander manager url: url uses to get and put information from/to commanders
 */
const backendCommanderManagerURL = new Util().getCommanderManagementURL();

/**
 * Backend user service url: url uses for the userdata service
 */
const backendUserServiceURL = new Util().geUserServiceURL();

/**
 * Backend group service url: urls users for the group service
 */
const backendGroupServiceURL = new Util().getGroupServiceURL();

/**
 * Backend rights service urls
 */
const backendRightsServiceURL = new Util().getRightsServiceURL();

/**
 * Backend incident manager service url
 */
const backendIncidentManagerServiceURL =
  new Util().getIncidentManagerServiceURL();

/**
 * Backend integration registry service url
 */
const backendIntegrationRegistryServiceURL =
  new Util().getIntegrationRegistryServiceURL();

/**
 * Backend maintenance messages service URL
 */
const backendMaintenanceMessagesServiceURL =
  new Util().getMaintenanceMessageServiceURL();

/**
 * Backend recipe manager
 */
const backedRecipeManagmentServiceURL =
  new Util().getRecipeManagmentServiceURL();

const logitechIntegrationURL = new Util().getLogitechIntegrationURL();

/**
 * token mapbox
 */
const tokenMapBox = new Util().getTokenMapBox();

/**
 * This class build a layer to manager all the call to backend
 */
class RestCalls {
  constructor(restClient) {
    /**
     * Identification of each call: if we used to know which error messages will be used
     */
    this.restClient = restClient;
  }

  /**
   * Remove associated device
   * this function call the backed to remove a associated a commander from the user account
   * @param string deviceId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  removeAssociatedDevice(deviceId, handleSuccessful, handleError) {
    const url = `${backendCommanderManagerURL}/${deviceId}`;
    this.restClient.call(
      { url, method: 'DELETE' },
      handleSuccessful,
      handleError,
      callTypeRemoveAssociatedDevice
    );
  }

  /**
   * Get commanders
   * this function call the backend to get all the commanders associated
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getCommanders(handleSuccessful, handleError) {
    this.restClient.call(
      {
        url: backendCommanderManagerURL,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetCommanders
    );
  }

  /**
   * Get commanders
   * this function call the backend to get a commander by uuid
   * @param string commaderUUID
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getCommanderById(commaderUUID, handleSuccessful, handleError) {
    const commanderByIdUrl = `${backendCommanderManagerURL}/${commaderUUID}`;

    this.restClient.call(
      {
        url: commanderByIdUrl,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetCommanderById
    );
  }

  /**
   * Get device coordinates
   *
   * @param string locations
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getDevicesCoordinates(locations, handleSuccessful, handleError) {
    // Encode uri because, some address have special characters like  #
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      locations
    )}.json?access_token=${tokenMapBox}`;
    this.restClient.call(
      {
        url: url,
        method: 'GET',
        withCredentials: false,
      },
      handleSuccessful,
      handleError,
      callTypeGetDevicesCoordinates
    );
  }

  /**
   * Get control units by device id
   * used by the incident manager defaultDescriptions
   * @param string deviceId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getControlUnitsByDeviceId(deviceId, handleSuccessful, handleError) {
    const commanderByIdUrl = `${backendCommanderManagerURL}/${deviceId}/controlunits`;
    this.restClient.call(
      {
        url: commanderByIdUrl,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetControlUnitsByDeviceId
    );
  }

  /**
   * Save user avatar
   * this funcion calls the service userdata to update the avatar
   * @param array iconUpload
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  saveUserAvatar(iconUpload, handleSuccessful, handleError) {
    const form = new FormData();
    form.append('file', iconUpload.file, iconUpload.fileName);
    form.append('source', 'local');
    const url = `${backendUserServiceURL}/self/avatar`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: form,
      },
      handleSuccessful,
      handleError,
      callTypeSaveGroupAvatar
    );
  }

  /**
   * Get available users to assign group
   * @param string groupId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getAvailableUsersToAssignGroup(groupId, handleSuccessful, handleError) {
    const url = `${backendGroupServiceURL}/${groupId}/users/available-adds`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetAvailableUsersToAssignGroup
    );
  }

  /*
   * Update group
   * this function calls the backend to update the group data
   * @param string groupId
   * @param array group
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  updateGroup(groupId, group, handleSuccessful, handleError) {
    const url = `${backendGroupServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to send the commander data
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: group,
      },
      handleSuccessful,
      handleError,
      callTypeUpdateGroup
    );
  }

  /**
   * Save group avatar
   * this function calls the backend to save a new avatar to the group
   */
  saveGroupAvatar(groupId, iconUpload, handleSuccessful, handleError) {
    const form = new FormData();
    form.append('file', iconUpload.file, iconUpload.fileName);
    const url = `${backendGroupServiceURL}/${groupId}/icon`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: form,
      },
      handleSuccessful,
      handleError,
      callTypeSaveGroupAvatar
    );
  }

  /**
   * Add user rights
   * this function call the service rights to add new rights to a user
   * the rights will be passed in a mapping [role: scope]
   * scope = [deviceId, GroupId, *]
   * @param string userId
   * @param array mappingRoleScope = [role: scope]
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  addUserRights(userId, mappingRoleScope, handleSuccessful, handleError) {
    const url = `${backendRightsServiceURL}/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: mappingRoleScope,
      },
      handleSuccessful,
      handleError,
      callTypeAddUserRights
    );
  }

  /**
   * Delete user rights
   * this function call the service rights to delete the rights from a user
   * @param string userId
   * @param string scope
   * @param string roleId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  deleteUserRights(userId, scope, roleId, handleSuccessful, handleError) {
    const url = `${backendRightsServiceURL}/${userId}/${scope}/${roleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    this.restClient.call(
      {
        url,
        method: 'DELETE',
        headers,
      },
      handleSuccessful,
      handleError,
      callTypeDeleteUserRights
    );
  }

  /**
   * Add incident attachment
   * this function add an attachment to an incident
   * @param string incidentId
   * @param object attachment
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  addIncidentAttachment(incidentId, attachment, handleSuccessful, handleError) {
    const form = new FormData();
    form.append('file', attachment);
    const url = `${backendIncidentManagerServiceURL}/${incidentId}/attachments`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: form,
      },
      handleSuccessful,
      handleError,
      callTypeAddAttachmentIncident
    );
  }

  /**
   * Get incident attachment content
   * retrieve attachment content
   * the axios rest client is used because the rest library doens't support the
   * response types arraybuffer and blob
   * @param string incidentId
   * @param string attachmentKey
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  async getIncidentAttachmentContent(
    incidentId,
    attachmentKey,
    handleSuccessful,
    handleError
  ) {
    try {
      const url = `${backendIncidentManagerServiceURL}/${incidentId}/attachments/${attachmentKey}`;
      const res = await axios.get(url, {
        withCredentials: false,
        headers: this.restClient.buildAuthHeader({}),
        responseType: 'arraybuffer',
      });

      handleSuccessful(res);
    } catch (e) {
      handleError(e.message, e.response);
    }
  }

  /**
   * Get integration registry
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getIntegrationRegistry(handleSuccessful, handleError) {
    this.restClient.call(
      {
        url: backendIntegrationRegistryServiceURL,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetIntegrationRegistry
    );
  }

  /**
   * Set maintenance message group
   * this function set a new maintenance message for a group
   * @param int groupId
   * @param object message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  setMaintenanceMessageGroup(groupId, message, handleSuccessful, handleError) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeSetMaintenanceMessageGroup
    );
  }

  /**
   * Set maintenance message device
   * this function set a new maintenance message for a device
   * @param int deviceId
   * @param object message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  setMaintenanceMessageDevice(
    deviceId,
    message,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeSetMaintenanceMessageDevice
    );
  }

  /**
   * Set maintenance message for a vendor
   * this function set a new maintenance message for a vendor
   * @param int vendorId
   * @param object message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  setMaintenanceMessageVendor(
    vendorId,
    message,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeSetMaintenanceMessageVendor
    );
  }

  /**
   * Updata maintenance message device
   * this funcion calls the service maintenance message to update an existing maintenance
   * message on device level
   * @param string deviceId
   * @param string messageId
   * @param array message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  updateMaintenanceMessageDevice(
    deviceId,
    messageId,
    message,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeUpdataMaintenanceMessageDevice
    );
  }

  /**
   * Update maintenance message group
   * this funcion calls the service maintenance message to update an existing maintenance
   * message on group level
   * @param string groupId
   * @param string messageId
   * @param array message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  updateMaintenanceMessageGroup(
    groupId,
    messageId,
    message,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeUpdataMaintenanceMessageGroup
    );
  }

  /**
   * Update maintenance message vendor
   * this funcion calls the service maintenance message to update an existing maintenance
   * message on vendor level
   * @param string vendorId
   * @param string messageId
   * @param array message
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  updateMaintenanceMessageVendor(
    vendorId,
    messageId,
    message,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'PUT',
        headers,
        entity: message,
      },
      handleSuccessful,
      handleError,
      callTypeUpdataMaintenanceMessageVendor
    );
  }

  /**
   * Get maintenance messages device
   * this function call the backend to get maintenance messages of a device
   * @param string deviceId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getMaintenanceMessagesDevice(deviceId, handleSuccessful, handleError) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetMaintenanceMessagesDevice
    );
  }

  /**
   * Get maintenance messages group
   * this function call the backend to get maintenance messages of a group
   * @param string groupId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getMaintenanceMessagesGroup(groupId, handleSuccessful, handleError) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetMaintenanceMessagesGroup
    );
  }

  /**
   * Get maintenance messages vendor
   * this function call the backend to get maintenance messages of a vendor
   * @param string vendorId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getMaintenanceMessagesVendor(vendorId, handleSuccessful, handleError) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetMaintenanceMessagesVendor
    );
  }

  /**
   * Delete maintenance message device
   * @param string deviceId
   * @param string messageId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  deleteMaintenanceMessageDevice(
    deviceId,
    messageId,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'DELETE',
        headers,
      },
      handleSuccessful,
      handleError,
      callTypeDeleteMaintenanceMessageDevice
    );
  }

  /**
   * Delete maintenance message group
   * @param string groupId
   * @param string messageId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  deleteMaintenanceMessageGroup(
    groupId,
    messageId,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'DELETE',
        headers,
      },
      handleSuccessful,
      handleError,
      callTypeDeleteMaintenanceMessageGroup
    );
  }

  /**
   * Delete maintenance message vendor
   * @param string vendorId
   * @param string messageId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  deleteMaintenanceMessageVendor(
    vendorId,
    messageId,
    handleSuccessful,
    handleError
  ) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'DELETE',
        headers,
      },
      handleSuccessful,
      handleError,
      callTypeDeleteMaintenanceMessageVendor
    );
  }

  getGroupCameras(groupID, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetCameras
    );
  }

  getPairableCameras(groupID, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/pairable`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetPairableCameras
    );
  }

  pairCamera(groupID, camera, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: camera,
      },
      handleSuccessful,
      handleError,
      callTypePairCamera
    );
  }

  async getCameraImage(groupID, cameraID, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras/${cameraID}/snapshot`;

    try {
      const res = await axios.get(url, {
        withCredentials: false,
        headers: this.restClient.buildAuthHeader({}),
        responseType: 'arraybuffer',
      });

      handleSuccessful(res);
    } catch (e) {
      handleError(e.message, e.response);
    }
  }

  async getCameraVideo(groupID, cameraID, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras/${cameraID}/video`;

    try {
      const res = await axios.get(url, {
        withCredentials: false,
        headers: this.restClient.buildAuthHeader({}),
        responseType: 'arraybuffer',
      });

      handleSuccessful(res);
    } catch (e) {
      handleError(e.message, e.response);
    }
  }

  /**
   * Get recipes group
   * this function get all the recipes associated with a group
   * @param string groupId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getRecipesGroup(groupId, handleSuccessful, handleError) {
    const url = `${backedRecipeManagmentServiceURL}/${groupId}`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetRecipesGroup
    );
  }

  /**
   * Get recipes user
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  getRecipesUser(handleSuccessful, handleError) {
    const url = backedRecipeManagmentServiceURL;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetRecipesUser
    );
  }

  /**
   * Set recipe group
   * @param string gropId
   * @param object recipe
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  setRecipeGroup(groupId, recipe, handleSuccessful, handleError) {
    const form = new FormData();
    form.append('file', recipe.file, recipe.fileName);
    const url = `${backedRecipeManagmentServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity: form,
      },
      handleSuccessful,
      handleError,
      callTypeSetRecipeGroup
    );
  }

  /**
   * Recipes transmission
   * @param object recipesTransmission: list of device which will be not get the recipes
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  recipesTransmission(recipesTransmission, handleSuccessful, handleError) {
    const entity = recipesTransmission;
    const url = `${backedRecipeManagmentServiceURL}/transmissions`;
    const headers = {
      'Content-Type': 'application/json',
    };
    this.restClient.call(
      {
        url,
        method: 'POST',
        headers,
        entity,
      },
      handleSuccessful,
      handleError,
      callTypeSetRecipeGroup
    );
  }

  async getCameraAuthLink(groupID, handleSuccessful, handleError) {
    const url = `${logitechIntegrationURL}/${groupID}/authorize`;
    this.restClient.call(
      {
        url,
        method: 'GET',
      },
      handleSuccessful,
      handleError,
      callTypeGetCameras
    );
  }
}

export default RestCalls;
