import { Grid } from '@mui/material';
import React from 'react';
import { ComposedParametersValue } from '../../../../../../../../../../../redux/CUParameters/selectors';
import { QueryParamControlUnitPage } from '../CUPage.model';
import Parameter from './Parameter';

export interface ParametersValuesProps extends QueryParamControlUnitPage {
  values: ComposedParametersValue[];
  parameterLevelId: string;
}

export default function ParametersValues(props: ParametersValuesProps) {
  const { values, groupId, deviceId, controlUnitId, parameterLevelId } = props;
  return (
    <Grid container spacing={2}>
      {values.map((parameter) => {
        return (
          <Grid item xs={12} md={4} key={parameter.name}>
            <Parameter
              parameter={parameter}
              groupId={groupId}
              deviceId={deviceId}
              controlUnitId={controlUnitId}
              parameterLevelId={parameterLevelId}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
