export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const SAVE_USER_AVATAR = 'SAVE_USER_AVATAR';
export const RESET_AVATAR = 'RESET_AVATAR';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const FETCH_USERS_ROLES = 'FETCH_USERS_ROLES';
export const FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP =
  'FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP';
export const POST_USERS_RIGHTS_BY_EMAIL = 'POST_USERS_RIGHTS_BY_EMAIL';
export const PUT_ROLE_GROUP_USER = 'PUT_ROLE_GROUP_USER';
export const PUT_ROLES_GROUP_USER = 'PUT_ROLES_GROUP_USER';
