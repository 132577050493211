import React from 'react';
import { DialogContentText, Stack } from '@mui/material';
import { create, InstanceProps } from 'react-modal-promise';
import { STDialog } from '../../../../commons/Modal';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';

interface Props extends InstanceProps<boolean>, PolyglotComponentProps {
  emails: string[];
}
export function NewUsersConfirmationDialog(props: Props) {
  const { onResolve, onReject, isOpen, emails, polyglot } = props;
  return (
    <STDialog
      title={polyglot.t('group.users.invite_user_title')}
      open={isOpen}
      onClose={() => onReject(false)}
      onSave={() => onResolve(true)}
    >
      <Stack spacing={2}>
        <DialogContentText>
          {polyglot.t('group.users.invite_user_first')}
        </DialogContentText>
        <DialogContentText>{emails.join(', ')}</DialogContentText>
        <DialogContentText>
          {polyglot.t('general.want_continue')}
        </DialogContentText>{' '}
      </Stack>
    </STDialog>
  );
}

export const openNewUsersConfirmationDialog = create(
  withPolyglot(NewUsersConfirmationDialog)
);
