import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  UiInput,
  UiButton,
  UiDropdown,
  withSnackbar,
} from 'stoerk-ui-components';
import { withHandlingErrors } from '../../../../../../handlingErrors';
import { getLanguage, withPolyglot } from '../../../../../../i18n';
import LanguagesUtil from '../../../../../../util/LanguagesUtil';
import Validate from '../../../../../../util/ValidationUtil';
import {
  timeUnits,
  convertSecondsGreaterToMinutes,
  hourId,
} from '../../../../../../util/TimeUnitsUtil';
import './index.css';

/**
 * Maintenance messages configuration
 */
export class MaintenanceMessagesConfigurationEdit extends Component {
  constructor(props) {
    super(props);
    this.updateFields = this.updateFields.bind(this);
    this.addLanguage = this.addLanguage.bind(this);
    this.removeLanguage = this.removeLanguage.bind(this);
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.showFieldMultipleLanguages =
      this.showFieldMultipleLanguages.bind(this);
    this.onChangeUnitTime = this.onChangeUnitTime.bind(this);
    this.save = this.save.bind(this);
    const languageBrowser = getLanguage();
    let message = {
      messages: { [languageBrowser]: '' },
      descriptions: { [languageBrowser]: '' },
      interval: 0,
    };
    const languagesUtil = new LanguagesUtil(props.polyglot);
    this.languages = languagesUtil.getAllLanguages();
    let unitTime = 's';
    if (props.messageId !== null) {
      const messageUpdate = props.messages.find(
        (c) => c.id === props.messageId
      );
      if (Object.keys(messageUpdate.messages).length === 0) {
        Object.assign(messageUpdate.messages, { [languageBrowser]: '' });
      }
      if (Object.keys(messageUpdate.descriptions).length === 0) {
        Object.assign(messageUpdate.descriptions, { [languageBrowser]: '' });
      }
      message = { ...messageUpdate };
      /* convert to a biger unit time: used to maintain compatibility with previously
      created messages, which were saved with a time unit of seconds or minutes */
      const intervalUnitTime = convertSecondsGreaterToMinutes(message.interval);
      if (Object.keys(intervalUnitTime).length > 0) {
        ({ unitTime } = intervalUnitTime);
        Object.assign(message, { interval: intervalUnitTime.value });
      }
    }
    this.state = {
      message,
      unitTime,
      index: {
        messages: 0,
        descriptions: 0,
      },
    };
  }

  /**
   * On change language
   * @param object event
   * @param string value: new key/language selected
   * @param string key
   * @param string field
   */
  onChangeLanguage(event, { value }, key, field) {
    const { message } = this.state;
    message[field][value] = message[field][key];
    delete message[field][key];
    this.setState({
      message,
    });
  }

  /**
   * On change unit time
   * @param object event
   * @param string value
   */
  onChangeUnitTime(event, { value }) {
    this.setState({
      unitTime: value,
    });
  }

  /**
   * Save:
   * this function is used to create a new message or to update an existing message
   * If the messageId (props) is null means that the message will be created, so the function
   * setMaintenanceMessage is called before to get the new message id
   * If the message Id is not null means that the message already exist and only the
   * function updateMaintenanceMessage is called
   */
  async save() {
    try {
      const { message, unitTime } = this.state;
      const { id, messageId, polyglot, openSnackbar, closeWindow } = this.props;
      let { interval } = message;
      /* convert the time to secods */
      if (unitTime !== 's') {
        const { convertToSeconds } = timeUnits().find((t) => t.id === unitTime);
        interval *= convertToSeconds;
      }
      /* remove from the object messages the messages without selected language
      and without message */
      if (message.messages) {
        Object.keys(message.messages).forEach((key) => {
          if (!Number.isNaN(Number(key))) {
            delete message.messages[key];
          } else if (message.messages[key].length === 0) {
            delete message.messages[key];
          }
        });
      }

      /* remove from the object descriptions the messages without selected language
      and without description */
      if (message.descriptions) {
        Object.keys(message.descriptions).forEach((key) => {
          if (!Number.isNaN(Number(key))) {
            delete message.descriptions[key];
          } else if (message.descriptions[key].length === 0) {
            delete message.descriptions[key];
          }
        });
      }

      const messageUpdate = {
        ...{
          interval,
          messages: message.messages,
          descriptions: message.descriptions,
        },
      };

      /* if the messageId (props) is null then we need to set first the message and
      after add the fields */
      if (messageId === null) {
        await this.props.setMaintenanceMessage(id, messageUpdate);
      } else {
        await this.props.updateMaintenanceMessage(
          id,
          message.id,
          messageUpdate
        );
      }

      const messageSnackbar = {
        text: polyglot.t(
          'maintenance_messages.save_maintenance_message_successful'
        ),
        type: 'ok',
      };
      openSnackbar(messageSnackbar);
      closeWindow();
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Update fields
   * @param object event
   * @param string value
   * @param string id
   */
  updateFields(event, value, id) {
    const { message } = this.state;
    const keys = id.split('.');
    if (keys.length === 2) {
      message[keys[0]][keys[1]] = value;
    } else {
      message[id] = value;
    }
    this.setState({
      message,
    });
  }

  /**
   * Add language
   * @param string field
   */
  addLanguage(field) {
    const { message, index } = this.state;
    message[field][index[field]] = '';
    index[field] += 1;
    this.setState({
      message,
      index,
    });
  }

  /*
   * Remove language
   * @param string field
   * @param string language
   */
  removeLanguage(field, language) {
    const { message, index } = this.state;
    delete message[field][language];
    this.setState({
      message,
      index,
    });
  }

  /**
   * Show field multiple languages
   * @param string field: [messages, descriptions]
   * @param string label
   * @param object
   */
  showFieldMultipleLanguages(field, label) {
    const { message } = this.state;
    const { polyglot } = this.props;
    let languagesOptions = null;
    if (message[field]) {
      languagesOptions = this.languages.map((language) => ({
        key: language.id,
        text: language.name,
        value: language.id,
      }));
      languagesOptions = languagesOptions.filter(
        (c) =>
          Object.keys(message[field]).find((m) => m === c.key) === undefined
      );
    }

    return (
      <div>
        <Grid>
          <Grid.Column width={16}>
            <div className="label-field-multiple-languages">
              {`${label}: ${polyglot.t(
                `maintenance_messages.explanation_${field}`
              )}`}
            </div>
          </Grid.Column>
          {/* reverse is used in order to sort the keys desc: show at the end the new keys */}
          {languagesOptions &&
            Object.keys(message[field])
              .reverse()
              .map((key) => (
                <Grid.Row stretched key={`message${key}`}>
                  {!Number.isNaN(Number(key)) ? (
                    <Grid.Column
                      width={2}
                      verticalAlign="middle"
                      textAlign="right"
                    >
                      <UiDropdown
                        options={languagesOptions}
                        selection
                        compact
                        fluid={false}
                        search
                        onChange={(event, data) =>
                          this.onChangeLanguage(event, data, key, field)
                        }
                      />
                    </Grid.Column>
                  ) : (
                    <Grid.Column
                      width={!Number.isNaN(Number(key)) ? 2 : 1}
                      verticalAlign="middle"
                      textAlign="right"
                    >
                      {key}
                    </Grid.Column>
                  )}
                  <Grid.Column width={12} textAlign="left">
                    <UiInput
                      id={`${field}.${key}`}
                      value={message[field][key]}
                      onChange={this.updateFields}
                    />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Button
                      className="button-without-border"
                      id="iconDeleteTranslation"
                      icon="delete"
                      size="small"
                      basic
                      onClick={() => this.removeLanguage(field, key)}
                    />
                  </Grid.Column>
                </Grid.Row>
              ))}
          {/* add new language */}
          <Grid.Column width={2} textAlign="right">
            <UiButton
              showLabels={false}
              iconName="add"
              onClick={() => this.addLanguage(field)}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  render() {
    const { message, unitTime } = this.state;
    const { polyglot, closeWindow } = this.props;
    const timeUnitsOptions = timeUnits(hourId).map((t) => ({
      key: t.id,
      text: t.name,
      value: t.id,
    }));

    /* the save button will be only active if the data is valid = interval not empty and > 0 */
    return (
      <Form>
        {/* messages/titles */}
        {this.showFieldMultipleLanguages(
          'messages',
          polyglot.t('maintenance_messages.title')
        )}
        {/* messages/titles */}
        {this.showFieldMultipleLanguages(
          'descriptions',
          polyglot.t('maintenance_messages.description')
        )}
        <br />
        <div className="label-field-multiple-languages">
          {polyglot.t('maintenance_messages.interval')}
        </div>
        <Form.Group>
          <UiInput
            id="interval"
            value={message.interval}
            onChange={this.updateFields}
            validate={() =>
              Validate.validateDecimalNumber(message.interval, false)
            }
            errorMessage={polyglot.t(
              'error.save_maintenance_message_interval_empty_number'
            )}
            width={4}
          />
          <UiDropdown
            options={timeUnitsOptions}
            selection
            compact
            fluid={false}
            value={unitTime}
            onChange={this.onChangeUnitTime}
            width={4}
          />
        </Form.Group>
        <UiButton
          primary
          iconName="save"
          size="small"
          id="saveButton"
          showLabels
          label={polyglot.t('button.save')}
          onClick={this.save}
          disabled={
            !(
              Validate.validateDecimalNumber(message.interval) &&
              message.interval > 0
            )
          }
        />
        <UiButton
          iconName="cancel"
          size="small"
          id="cancelButton"
          showLabels
          label={polyglot.t('button.cancel')}
          onClick={closeWindow}
        />
      </Form>
    );
  }
}

MaintenanceMessagesConfigurationEdit.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  openSnackbar: PropTypes.func.isRequired,
  id: PropTypes.string
    .isRequired /* could by groupId or deviceId, or vendorId */,
  updateMaintenanceMessage: PropTypes.func.isRequired,
  setMaintenanceMessage: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(Object).isRequired,
  messageId: PropTypes.string,
  closeWindow: PropTypes.func.isRequired,
};

MaintenanceMessagesConfigurationEdit.defaultProps = {
  messageId: null,
};

export default connect()(
  withHandlingErrors(
    withSnackbar(withPolyglot(MaintenanceMessagesConfigurationEdit))
  )
);
