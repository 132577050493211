import { Accordion, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import ParametersValues from './ParametersValues';
import { ComposedParameterLevel } from '../../../../../../../../../../../redux/CUParameters/selectors';
import { useRouteMatch } from 'react-router-dom';
import { QueryParamControlUnitPage } from '../CUPage.model';

export default function ParameterLevel(props: {
  parameterLevel: ComposedParameterLevel;
  searchText: string;
}) {
  const { parameterLevel, searchText } = props;
  const match = useRouteMatch<QueryParamControlUnitPage>();
  let title = parameterLevel.id;
  if (parameterLevel.titleTranslation)
    title = `${title}: ${parameterLevel.titleTranslation}`;
  const [expanded, setExpanded] = useState(false);
  const onClickAccordion = () => setExpanded(!expanded);
  // open when are searching
  useEffect(() => {
    if (searchText.trim()) setExpanded(true);
    else setExpanded(false);
  }, [searchText]);

  return (
    <Accordion
      defaultExpanded={!!(searchText || false)}
      expanded={expanded}
      key={parameterLevel.id}
      onChange={onClickAccordion}
    >
      <AccordionSummary
        id={`accordion-parameter-title-${parameterLevel.id}`}
        aria-controls={`accordion-parameter-title-${parameterLevel.id}`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <ParametersValues
        values={parameterLevel.values}
        {...match.params}
        parameterLevelId={parameterLevel.id}
      />
    </Accordion>
  );
}
