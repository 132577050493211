import React, { useMemo } from 'react';
import { Paper, Container } from '@mui/material';
import { getLastPath, navigationPath } from '../navigations/navigationPath';
import BreadcrumbNew from '../../commons/breadcrumbNew';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../redux/groups/selectors';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from '../../../redux/store.model';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { useGroupById } from '../../../redux/groups/hooks/useGroupById';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../handlingErrors';
import GroupCard from './GroupCard';
import GroupManagerRoutes from './GroupManagerRoutes';

interface GroupManagerLayoutProps
  extends ConnectedComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    RouteComponentProps<{ groupId?: string | undefined }> {}
export function GroupManagerLayoutSimple(props: GroupManagerLayoutProps) {
  const {
    match,
    groups,
    handlingErrorsApi,
    location: { pathname },
  } = props;
  const { groupId } = match.params;

  const [group, loading] = useGroupById(groupId, handlingErrorsApi);

  /* Navigation */
  const moduleSelected = useMemo(() => {
    return getLastPath(pathname);
  }, [pathname]);
  const sectionsNavigation = navigationPath(
    props,
    groupId,
    groups,
    group,
    moduleSelected
  );

  return (
    <div>
      <BreadcrumbNew sections={sectionsNavigation} />
      <Container maxWidth="lg">
        <Paper
          variant="outlined"
          // Remove paper in mobile
          sx={{
            padding: { md: 2 },
            borderWidth: { xs: '0px', md: '1px' },
          }}
        >
          {groupId && (
            <GroupCard
              group={group}
              moduleSelected={moduleSelected}
              groupId={groupId}
              loading={loading}
            />
          )}
          <GroupManagerRoutes />
        </Paper>
      </Container>
    </div>
  );
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string | undefined }>
) => ({
  groups: getGroups(state),
  group: getGroupSelectedByQueryParamSelector(state, props),
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(GroupManagerLayoutSimple)))
);
