import React, { Component } from 'react';
import { Grid, Image, Popup, Segment, Loader, Button } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import BrowserUtil from '../../../../../util/BrowserUtil';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import { withUserRightUtil } from '../../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import SortUtil from '../../../../../util/SortUtil';
import ConnectedGroupUserAssign from './GroupUserAssign';
import ConnectdGroupUserUpdateRole from './GroupUserUpdateRole';
import {
  fetchUsersRoles,
  fetchAvailableUsersToAssignGroup,
} from '../../../../../redux/users/actions';
import { deleteGroupUser } from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import getRolesNamesDescriptions from './Constants';
import './index.css';
import Polyglot from 'node-polyglot';
import { Group } from '../../../../../model/group/group.model';
import { RootState } from '../../../../../redux/store.model';
import { getUsersByUsersIdsPropsSelector } from '../../../../../redux/users/selectors';
import { CardActions, Button as ButtonMUI } from '@mui/material';
import { STModal } from '../../../../commons/Modal';

const browserUtil = new BrowserUtil();
const ASC = 'ASC';
const DESC = 'DESC';
type OwnProps = {
  group?: Group;
  closeEditGroup(...args: unknown[]): unknown;
};

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & OwnProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps &
  OpenSnackbarProps;

type State = {
  sort: {
    name: string;
    role: string;
  };
  sortField: 'name' | 'role';
  showMessageDeleteGroupUser: boolean;
  deleteUserId: null | string;
  showGroupUserAssign: boolean;
  showGroupUserUpdateRole: boolean;
  showMessageLoadData: boolean;
  userId: string;
  userName: string;
  rolesId: string[];
};
/**
 * Group edit users
 */
export class GroupEditUsers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.closeMessageDeleteGroupUser =
      this.closeMessageDeleteGroupUser.bind(this);
    this.openMessageDeleteGroupUser =
      this.openMessageDeleteGroupUser.bind(this);
    this.removeGroupUser = this.removeGroupUser.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.openGroupUserAssign = this.openGroupUserAssign.bind(this);
    this.openGroupUserUpdateRole = this.openGroupUserUpdateRole.bind(this);
    this.closeGroupUserUpdateRole = this.closeGroupUserUpdateRole.bind(this);
    this.loadUsersRoles = this.loadUsersRoles.bind(this);
    this.loadData = this.loadData.bind(this);
    this.closeGroupUserAssign = this.closeGroupUserAssign.bind(this);

    this.loadComponentDisplayProperties =
      this.loadComponentDisplayProperties.bind(this);
    const sort = { name: ASC, role: ASC };
    this.state = {
      sort,
      sortField: 'name',
      showMessageDeleteGroupUser: false,
      deleteUserId: null,
      showGroupUserAssign: false,
      showGroupUserUpdateRole: false,
      showMessageLoadData: false,
      rolesId: [],
      userId: '',
      userName: '',
    };
  }

  /**
   * Component did mount
   * will be run after constructor
   * the needed data will be load here
   */
  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load data
   */
  async loadData(showMessageLoadData = true) {
    const { group } = this.props;
    if (showMessageLoadData) {
      this.setState({ showMessageLoadData });
    }
    if (group && group.users && group.users.length > 0) {
      await this.loadUsersRoles();
    }
    if (showMessageLoadData) {
      this.setState({ showMessageLoadData: false });
    }
  }

  /**
   * Load componet display propierties
   */
  loadComponentDisplayProperties() {
    const { group, rightsUserUtil } = this.props;
    const showRemoveGroupUserBotton =
      group && rightsUserUtil.hasRightsToRemoveGroupUser(group.id);
    const showAssignGroupUserBotton =
      group && rightsUserUtil.hasRightsToAssignGroupUser(group.id);
    const showUserRights =
      group && rightsUserUtil.hasRightsToReadUserRights(group.id);
    const showUpdateRoleGroupUser =
      group && rightsUserUtil.hasRightsToAssignGroupUser(group.id);
    return {
      showRemoveGroupUserBotton,
      showAssignGroupUserBotton,
      showUserRights,
      showUpdateRoleGroupUser,
    };
  }

  /**
   * Load users roles
   * This function loads the roles of the users associated to the group
   */
  async loadUsersRoles() {
    const { group, handlingErrorsApi } = this.props;
    try {
      if (group) await this.props.fetchUsersRoles(group.users, group.id);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Remove group user
   * this function call the rest api to remove a user from a group
   */
  async removeGroupUser(event: unknown, userId: string) {
    const { group, polyglot, openSnackbar, handlingErrorsApi } = this.props;
    try {
      if (group) await this.props.deleteGroupUser(group.id, userId);

      /* show snack bar with successful message */
      this.setState({ showMessageDeleteGroupUser: false, deleteUserId: null });
      const message = {
        text: polyglot.t('group.remove_group_user_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      this.setState({ showMessageDeleteGroupUser: false, deleteUserId: null });
      handlingErrorsApi(error);
    }
  }

  /**
   * Sort by
   * @param string field : [name, role]
   */
  sortBy(field: 'name' | 'role') {
    const { sort } = this.state;
    sort[field] = sort[field] === ASC ? DESC : ASC;
    this.setState({ sort, sortField: field });
  }

  /**
   * Open message delete group user
   * this function opens a modal window with a message question
   */
  openMessageDeleteGroupUser(event: unknown, userId: string) {
    this.setState({ showMessageDeleteGroupUser: true, deleteUserId: userId });
  }

  /**
   * Close message delete group user
   */
  closeMessageDeleteGroupUser() {
    this.setState({ showMessageDeleteGroupUser: false, deleteUserId: null });
  }

  /**
   * Open group assign
   * open modal window to assign users to the group. the list of the
   * candidate users to be assigned must be passed (usersToAssign)
   */
  async openGroupUserAssign() {
    const { group, handlingErrorsApi } = this.props;
    try {
      if (group) await this.props.fetchAvailableUsersToAssignGroup(group.id);
      this.setState({ showGroupUserAssign: true });
    } catch (error) {
      this.setState({ showGroupUserAssign: true });
      handlingErrorsApi(error);
    }
  }

  /**
   * Open group user update role
   * @param object event
   * @param string userId
   * @param string userName
   * @param array rolesId
   */
  openGroupUserUpdateRole(
    event: unknown,
    userId: string,
    userName: string,
    rolesId: string[]
  ) {
    this.setState({
      userId,
      userName,
      rolesId,
      showGroupUserUpdateRole: true,
    });
  }

  /**
   * Close group user update role
   */
  async closeGroupUserUpdateRole(updatedUser = false) {
    if (updatedUser) {
      this.loadUsersRoles();
    }
    this.setState({ showGroupUserUpdateRole: false });
  }

  /**
   * Close group user assign
   * @param bool addedUser
   */
  async closeGroupUserAssign(addedUser = false) {
    if (addedUser) {
      await this.loadUsersRoles();
    }
    this.setState({ showGroupUserAssign: false });
  }

  render() {
    const {
      group,
      usersRoles,
      availableUsersToAssignGroup,
      userLogged,
      polyglot,
      closeEditGroup,
      users,
    } = this.props;

    const {
      showGroupUserAssign,
      showGroupUserUpdateRole,
      showMessageDeleteGroupUser,
      showMessageLoadData,
      sort,
      sortField,
      userId,
      userName,
      rolesId,
      deleteUserId,
    } = this.state;

    const {
      showRemoveGroupUserBotton,
      showAssignGroupUserBotton,
      showUserRights,
      showUpdateRoleGroupUser,
    } = this.loadComponentDisplayProperties();

    //Why split it :face.palm:
    const usersWhitRoles = users.map((user) => {
      const found = usersRoles.find((userRole) => userRole.userId === user.id);
      return {
        ...user,
        role: found ? found.role : '',
        roleId: found && found.roles.length > 0 ? found.roles[0].id : '',
        roles: found ? found.roles : [],
      };
    });
    const resultUsers = SortUtil.multisort(
      usersWhitRoles,
      [sortField],
      undefined
    );

    let buttonAdd = (
      <Button
        primary
        circular
        icon="add"
        size="huge"
        className="raised-button"
        floated="right"
        onClick={this.openGroupUserAssign}
        id="ButtonAssignGroupUser"
      />
    );
    if (browserUtil.getIsMobile()) {
      buttonAdd = (
        <Popup
          trigger={buttonAdd}
          content={polyglot.t('group.tooltip.open_window_assign_user')}
        />
      );
    }

    return (
      <>
        {showGroupUserAssign && group && (
          <ConnectedGroupUserAssign
            groupId={group?.id}
            usersToAssign={availableUsersToAssignGroup}
            closeGroupUserAssign={this.closeGroupUserAssign}
          />
        )}
        {showGroupUserUpdateRole && (
          <ConnectdGroupUserUpdateRole
            // @ts-ignore FIXME: ConnectdGroupUserUpdateRole not typed
            groupId={group?.id}
            userId={userId}
            userName={userName}
            rolesId={rolesId}
            showGroupUserUpdateRole={showGroupUserUpdateRole}
            closeGroupUserUpdateRole={this.closeGroupUserUpdateRole}
          />
        )}

        {showMessageLoadData ? (
          <Segment>
            <Grid columns={4} className="groups-users-list" container>
              <Grid.Row className="user">
                <Grid.Column
                  width="16"
                  textAlign="center"
                  verticalAlign="middle"
                >
                  <Loader active inline />
                  {polyglot.t('group.loading_data_message')}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : (
          <Segment>
            <Grid columns={4} className="groups-users-list" container>
              {/* titles */}
              <Grid.Row className="user">
                <Grid.Column
                  width={!browserUtil.getIsPhone() ? 1 : 3}
                  verticalAlign="middle"
                />
                <Grid.Column
                  width={showUserRights && !browserUtil.getIsPhone() ? 6 : 10}
                  verticalAlign="top"
                >
                  {polyglot.t('group.users.name')}
                  <Button
                    basic
                    icon={sort.name === ASC ? 'caret down' : 'caret up'}
                    className="sort"
                    onClick={() => this.sortBy('name')}
                  />
                </Grid.Column>
                {showUserRights && !browserUtil.getIsPhone() && (
                  <Grid.Column width={4} verticalAlign="middle">
                    {polyglot.t('group.users.role')}
                    <Button
                      basic
                      icon={sort.role === ASC ? 'caret down' : 'caret up'}
                      className="sort"
                      onClick={() => this.sortBy('role')}
                    />
                  </Grid.Column>
                )}
                {!browserUtil.getIsPhone() && (
                  <Grid.Column width={3} verticalAlign="middle">
                    {polyglot.t('group.users.email')}
                  </Grid.Column>
                )}
              </Grid.Row>
              {resultUsers &&
                resultUsers.length > 0 &&
                resultUsers.map((user, index) => {
                  const userNameDisplay = user.name
                    ? user.name
                    : polyglot.t('group.users.no_name');
                  let userRoles;
                  if (user.roles && user.roles.length > 0) {
                    userRoles = getRolesNamesDescriptions(user.roles).map(
                      (r, id) => (
                        <div key={id.toString()}>
                          {`${r.name} `}
                          {r.description}
                        </div>
                      )
                    );
                  } else {
                    userRoles = user.role
                      .split(',')
                      .sort()
                      .map((name, id) => <div key={id.toString()}>{name}</div>);
                  }
                  let buttonEdit = (
                    <Button
                      id={`iconEditUser${index}`}
                      icon="write"
                      size="small"
                      onClick={(event) =>
                        this.openGroupUserUpdateRole(
                          event,
                          user.id,
                          user.name ? user.name : user.email,
                          user.roles.map((r) => r.id)
                        )
                      }
                    />
                  );
                  let buttonDelete = (
                    <Button
                      id={`iconDeleteUser${index}`}
                      icon="delete"
                      size="small"
                      onClick={(event) =>
                        this.openMessageDeleteGroupUser(event, user.id)
                      }
                    />
                  );
                  if (!browserUtil.getIsMobile()) {
                    buttonEdit = (
                      <Popup
                        trigger={buttonEdit}
                        content={polyglot.t('group.tooltip.update_role_user')}
                      />
                    );
                    buttonDelete = (
                      <Popup
                        trigger={buttonDelete}
                        content={polyglot.t('group.tooltip.remove_group_user')}
                      />
                    );
                  }
                  return (
                    <Grid.Row
                      key={user.id}
                      id={`user${index}`}
                      verticalAlign="top"
                    >
                      {/* Avatar */}
                      <Grid.Column
                        width={!browserUtil.getIsPhone() ? 1 : 3}
                        verticalAlign="top"
                      >
                        <Image src={user.avatarLocation} size="tiny" />
                      </Grid.Column>
                      {/* username */}
                      <Grid.Column
                        width={
                          showUserRights && !browserUtil.getIsPhone() ? 6 : 10
                        }
                        verticalAlign="top"
                      >
                        {userNameDisplay}
                        {browserUtil.getIsPhone() && <p>{user.role}</p>}
                      </Grid.Column>
                      {/* user role */}
                      {showUserRights && !browserUtil.getIsPhone() && (
                        <Grid.Column width={4} verticalAlign="top">
                          {userRoles}
                        </Grid.Column>
                      )}
                      {/* user email */}
                      {!browserUtil.getIsPhone() && (
                        <Grid.Column width={3} verticalAlign="top">
                          {user.email}
                        </Grid.Column>
                      )}
                      {/* bottom */}
                      <Grid.Column
                        textAlign="right"
                        className="toolbar-buttons"
                        verticalAlign="top"
                        width={!browserUtil.getIsPhone() ? 2 : 1}
                      >
                        <Button.Group>
                          {/* Button edit */}
                          {showUpdateRoleGroupUser && buttonEdit}
                          {/* Button delete */}
                          {userLogged !== user.id &&
                            showRemoveGroupUserBotton &&
                            buttonDelete}
                        </Button.Group>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
            </Grid>
            <Grid padded="vertically">
              <Grid.Row />
              <Grid.Row>
                <Grid.Column width={16}>
                  {showAssignGroupUserBotton && buttonAdd}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <ButtonMUI onClick={closeEditGroup}>
            {polyglot.t('group.close_button_title')}
          </ButtonMUI>
        </CardActions>
        {/* Modal window called when the user wants to go to other tab and there
          are unsaved changes */}
        <STModal
          open={showMessageDeleteGroupUser}
          onClose={this.closeMessageDeleteGroupUser}
          buttonActions={
            <>
              <ButtonMUI
                variant="contained"
                onClick={(event) => {
                  if (deleteUserId) this.removeGroupUser(event, deleteUserId);
                }}
              >
                {polyglot.t('general.yes')}
              </ButtonMUI>
              <ButtonMUI onClick={this.closeMessageDeleteGroupUser}>
                {polyglot.t('general.no')}
              </ButtonMUI>
            </>
          }
        >
          <p>{polyglot.t('group.remove_group_user_message')}</p>
        </STModal>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  usersRoles: state.users.usersRoles,
  availableUsersToAssignGroup: state.users.availableUsersToAssignGroup,
  userLogged: state.auth.id,
  users: getUsersByUsersIdsPropsSelector(state, {
    usersIds: props.group?.users,
  }),
});

const connector = connect(mapStateToProps, {
  fetchUsersRoles,
  deleteGroupUser,
  fetchAvailableUsersToAssignGroup,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withUserRightUtil(withSnackbar(withPolyglot(GroupEditUsers)))
  )
);
