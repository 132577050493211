import Polyglot from 'node-polyglot';
import {
  COMMANDER_VALUE_ERROR_ACCESS_DENIED,
  COMMANDER_VALUE_ERROR_ADDRESS_OVERFLOW,
  COMMANDER_VALUE_ERROR_CORRUPT_RECORD,
  COMMANDER_VALUE_ERROR_DEACTIVATED,
  COMMANDER_VALUE_ERROR_DISCONNECTED,
  COMMANDER_VALUE_ERROR_NONE,
  COMMANDER_VALUE_ERROR_NO_HARDWARE,
  COMMANDER_VALUE_ERROR_POWER_FAIL,
  COMMANDER_VALUE_ERROR_RECORDING_ON,
  COMMANDER_VALUE_ERROR_RECORDING_OUT_OF_RANGE,
  COMMANDER_VALUE_ERROR_RECORDING_SHUTDOWN,
  COMMANDER_VALUE_ERROR_RECORDING_START,
  COMMANDER_VALUE_ERROR_SENSOR_FAILURE,
  COMMANDER_VALUE_ERROR_UNKNOWN,
  COMMANDER_VALUE_ERROR_VALUE_NAME_NOT_SPECIFIED,
} from './constants';

/**
 * Decode error
 * @param float stateNew => event.sn
 * @param object polygot
 * @return strung error
 */
export const decodeError = (stateNew: number, polyglot: Polyglot) => {
  const low = stateNew & 0xff;
  let errorDescription;
  switch (low) {
    case COMMANDER_VALUE_ERROR_NONE:
      errorDescription = polyglot.t('device.events.error.no_value');
      break;
    case COMMANDER_VALUE_ERROR_RECORDING_SHUTDOWN:
      errorDescription = polyglot.t('device.events.error.recording_shutdown');
      break;
    case COMMANDER_VALUE_ERROR_VALUE_NAME_NOT_SPECIFIED:
      errorDescription = polyglot.t(
        'device.events.error.value_name_not_specified'
      );
      break;
    case COMMANDER_VALUE_ERROR_ACCESS_DENIED:
      errorDescription = polyglot.t('device.events.error.access_denied');
      break;
    case COMMANDER_VALUE_ERROR_RECORDING_OUT_OF_RANGE:
      errorDescription = polyglot.t(
        'device.events.error.recording_out_of_range'
      );
      break;
    case COMMANDER_VALUE_ERROR_RECORDING_ON:
      errorDescription = polyglot.t('device.events.error.recording_on');
      break;
    case COMMANDER_VALUE_ERROR_RECORDING_START:
      errorDescription = polyglot.t('device.events.error.recording_restart');
      break;
    case COMMANDER_VALUE_ERROR_CORRUPT_RECORD:
      errorDescription = polyglot.t('device.events.error.corrupt_record');
      break;
    case COMMANDER_VALUE_ERROR_DISCONNECTED:
      errorDescription = polyglot.t('device.events.error.disconnnected');
      break;
    case COMMANDER_VALUE_ERROR_UNKNOWN:
      errorDescription = polyglot.t('device.events.error.unknown');
      break;
    case COMMANDER_VALUE_ERROR_DEACTIVATED:
      errorDescription = polyglot.t('device.events.error.channel_off');
      break;
    case COMMANDER_VALUE_ERROR_POWER_FAIL:
      errorDescription = polyglot.t('device.events.error.power_fail');
      break;
    case COMMANDER_VALUE_ERROR_SENSOR_FAILURE:
      errorDescription = polyglot.t('device.events.error.sensor_failure');
      break;
    case COMMANDER_VALUE_ERROR_NO_HARDWARE:
      errorDescription = polyglot.t('device.events.error.no_hardware');
      break;
    case COMMANDER_VALUE_ERROR_ADDRESS_OVERFLOW:
      errorDescription = polyglot.t('device.events.error.address_overflow');
      break;
    default:
      errorDescription = low.toString();
  }

  return errorDescription;
};
