import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import { UiMessage } from 'stoerk-ui-components';

import { ChartsFilters } from './components/ChartsFilters';
import { ComboCharts } from './components/ComboCharts';
import { useMetrics } from './hooks/useMetrics';
import './MonitoringScreen.scss';
import { Moment } from 'moment';
import { MonitoringScreenProps } from './monitoring.model';
import WidthRefResize from '../../../../../../util/renderProps/WidthRefResize';
import { useDevice } from '../../../../../../redux/devices/hooks/useDevice';

/**
 * https://www.commander-cloud.eu/api/frontend/device/sensordata?ids=[%ZjZmMzAwZjktZTBmNC00NWRjLWE3ZTUtNWI5MTU4NjljZDMz%22]&start=1623325610392&end=1623412010393
 * devicemanager/YWI2ZmI0YTMtOWVlYy00MWJlLTkzNGYtNzliMGIzYzZmOTM1/device/ZjZmMzAwZjktZTBmNC00NWRjLWE3ZTUtNWI5MTU4NjljZDMz/monitoring
 */

export function MonitoringScreen(props: MonitoringScreenProps) {
  const { match, polyglot, handlingErrorsApi, error } = props;
  const { deviceId, controlUnitId, groupId } = match.params;

  /** Init Groups */
  useDevice(deviceId, handlingErrorsApi);

  /** Init Monitoring */
  const {
    timeControls: { timeFrame, setTimeFrame },
    timeControls,
    analogControls,
    digitalControls,
    loading,
    metrics,
  } = useMetrics({});

  /** View */
  const setTimeFrameSelection = (start: Moment, end: Moment) => {
    setTimeFrame({ start, end });
  };
  return (
    <WidthRefResize
      render={(width) => (
        <div id="monitoring-charts">
          {
            <div>
              <ChartsFilters
                onChangeDatePicker={setTimeFrameSelection}
                polyglot={polyglot}
                groupId={groupId}
                deviceId={deviceId}
                controlUnitId={controlUnitId}
                analogControls={analogControls}
                digitalControls={digitalControls}
              />
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted>{polyglot.t('general.loading')}</Loader>
                </Dimmer>
              ) : error ? (
                <UiMessage
                  attached
                  icon="warning"
                  header=""
                  content={polyglot.t('error.unknown')}
                />
              ) : (
                <ComboCharts
                  {...props}
                  digitalChannels={metrics.digitalChannels}
                  sensordata={metrics.sensordata}
                  timeControls={timeControls}
                  timeFrame={timeFrame}
                  maxWidth={width - 32}
                />
              )}
            </div>
          }
        </div>
      )}
    />
  );
}
