import React, { Component } from 'react';
import { Button, Popup, StrictPopupProps } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSnackbar } from 'stoerk-ui-components';
import { withPolyglot } from '../../../../../i18n';
import BrowserUtil from '../../../../../util/BrowserUtil';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import { withUserRightUtil } from '../../../../../util/rights';
import Contacts from '../../Contacts';
import ConnectCameras from '../../Cameras';
import ConnectGroupDelete from '../../GroupDelete';
import ConnectGroupEdit from '../../GroupEdit';
import ConnectGroupsOverviewGrid from '../GroupsOverviewGrid';
import ConnectGroupsOverviewGridSmall from '../GroupsOverviewGridSmall';
import ConnectGroupsOverviewList from '../GroupsOverviewList';
import {
  VIEW_GRID,
  VIEW_LIST,
  VIEW_GRID_SMALL,
} from '../../../../commons/topBarMenu';
import './GroupsOverview.css';
import Polyglot from 'node-polyglot';
import { OpenSnackbarProps } from '../../../../../handlingErrors';
import { Group } from '../../../../../model/group/group.model';
import { RootState } from '../../../../../redux/store.model';
import { VIEW_OPTIONS } from '../../GroupManager.model';
import {
  STButtonIcon,
  STButtonIconLoadingProps,
} from '../../../../commons/STButtonIcon';
import { getIsLoadingAllPublicIncidents } from '../../../../../redux/incidents/selectors';
import { GroupHistoryButton } from '../../GroupCard';

const componentsGroupsOverview = {
  [VIEW_GRID]: ConnectGroupsOverviewGrid,
  [VIEW_LIST]: ConnectGroupsOverviewList,
  [VIEW_GRID_SMALL]: ConnectGroupsOverviewGridSmall,
};

const browserUtil = new BrowserUtil();

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
  groups: Group[];
  view?: string;
  incidentsGroups: Record<string, number>;
} & ConnectedComponentProps &
  RouteComponentProps<{ groupId: string }> &
  OpenSnackbarProps;

type State = {
  /** Record<GroupUUID,boolean> */
  showButtonsGroups: Record<
    string,
    Record<
      | 'showButtonReadIncidents'
      | 'showButtonUpdateGroup'
      | 'showButtonDeleteGroup'
      | 'showButtonRecipeGroup',
      boolean
    >
  >;
  showContacts: boolean;
  showDeleteGroup: boolean;
  showEditGroup: boolean;
  showCameras: boolean;
  groupContacts: {};
  groupId: string;
  groupIdUpdate?: string;
};
export class GroupsOverview extends Component<Props, State> {
  /**
   * Get group icon
   * show the group icon
   * @param array group
   * @param string group [medium]
   * @return object deviceGroupIcon
   */
  static getGroupIcon(
    group: Group,
    size: STButtonIconLoadingProps['size'],
    open: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      group: Group
    ) => void,
    icon: STButtonIconLoadingProps['icon'],
    tooltip: StrictPopupProps['content'],
    loading?: boolean,
    badge?: React.ReactNode
  ) {
    let deviceGroupIcon = null;
    if (!browserUtil.getIsMobile()) {
      deviceGroupIcon = (
        <Popup
          key={`${icon}-${group.id}`}
          trigger={
            <STButtonIcon
              icon={icon}
              size={size}
              onClick={(event) => open(event, group)}
              loading={loading}
              badge={badge}
            />
          }
          content={tooltip}
        />
      );
    } else {
      deviceGroupIcon = (
        <STButtonIcon
          key={`${icon}-${group.id}`}
          icon={icon}
          size={size}
          onClick={(event) => open(event, group)}
          badge={badge}
        />
      );
    }

    return deviceGroupIcon;
  }

  constructor(props: Props) {
    super(props);
    this.openContacts = this.openContacts.bind(this);
    this.closeContacts = this.closeContacts.bind(this);
    this.closeDeleteGroup = this.closeDeleteGroup.bind(this);
    this.openDeleteGroup = this.openDeleteGroup.bind(this);
    this.closeEditGroup = this.closeEditGroup.bind(this);
    this.openEditGroup = this.openEditGroup.bind(this);
    this.openIncidents = this.openIncidents.bind(this);
    this.openRecipes = this.openRecipes.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    this.loadButtonsByGroupToDisplay =
      this.loadButtonsByGroupToDisplay.bind(this);
    this.openCameras = this.openCameras.bind(this);
    this.openRecipes = this.openRecipes.bind(this);
    this.closeCameras = this.closeCameras.bind(this);
    this.buttonsActions = this.buttonsActions.bind(this);
    this.buttonsActionsGrid = this.buttonsActionsGrid.bind(this);
    this.buttonContact = this.buttonContact.bind(this);
    this.buttonEdit = this.buttonEdit.bind(this);
    this.buttonAlarms = this.buttonAlarms.bind(this);
    this.buttonCamera = this.buttonCamera.bind(this);
    this.buttonDelete = this.buttonDelete.bind(this);
    this.buttonRecipes = this.buttonRecipes.bind(this);
    this.navigate = this.navigate.bind(this);
    const { match } = this.props;
    const { groupId } = match.params;
    this.state = {
      showButtonsGroups: {},
      showContacts: false,
      showDeleteGroup: false,
      showEditGroup: false,
      showCameras: false,
      groupContacts: {},
      groupId: groupId,
    };
  }

  async componentDidMount() {
    this.loadButtonsToDisplay();
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { groupId } = match.params;
    if (groupId !== this.state.groupId) {
      this.setState({ groupId });
      /* if the group is changed, then the rights for the new group need
      to be found */
      this.loadButtonsToDisplay();
    }
  }

  /**
   * Button contact
   * @param object group
   * @param string sizeIcon
   */
  buttonContact(group: Group, sizeIcon: STButtonIconLoadingProps['size']) {
    const { polyglot } = this.props;
    return GroupsOverview.getGroupIcon(
      group,
      sizeIcon,
      this.openContacts,
      'address book outline',
      polyglot.t('group.contact_list')
    );
  }

  /**
   * Button edit
   * @param object group
   * @param string sizeIcon
   */
  buttonEdit(group: Group, sizeIcon: STButtonIconLoadingProps['size']) {
    const { polyglot } = this.props;
    return GroupsOverview.getGroupIcon(
      group,
      sizeIcon,
      this.openEditGroup,
      'write',
      polyglot.t('group.tooltip.open_window_edit')
    );
  }

  /**
   * Button alarms
   * @param object group
   * @param string sizeIcon
   */
  buttonAlarms(
    group: Group,
    numIncidents: number | undefined,
    sizeIcon: STButtonIconLoadingProps['size']
  ) {
    const { polyglot, isLoadingIncidents } = this.props;
    return GroupsOverview.getGroupIcon(
      group,
      sizeIcon,
      this.openIncidents,
      'warning sign',
      polyglot.t('group.tooltip.open_window_alarms'),
      isLoadingIncidents,
      numIncidents
    );
  }

  /**
   * Button camera
   * @param object group
   * @param string sizeIcon
   */
  buttonCamera(group: Group, sizeIcon: STButtonIconLoadingProps['size']) {
    const { polyglot } = this.props;
    return (
      <div className="label-cameras-container" key="camera">
        {GroupsOverview.getGroupIcon(
          group,
          sizeIcon,
          this.openCameras,
          'camera',
          polyglot.t('group.tooltip.open_window_cameras')
        )}
      </div>
    );
  }

  /**
   * Button delete
   * @param object group
   * @param string sizeIcon
   */
  buttonDelete(group: Group, sizeIcon: STButtonIconLoadingProps['size']) {
    const { polyglot } = this.props;
    return GroupsOverview.getGroupIcon(
      group,
      sizeIcon,
      this.openDeleteGroup,
      'trash alternate',
      polyglot.t('group.tooltip.delete_group')
    );
  }

  /**
   * Button recipes
   * @param object group
   * @param string sizeIcon
   */
  buttonRecipes(group: Group, sizeIcon: STButtonIconLoadingProps['size']) {
    const { polyglot } = this.props;
    return GroupsOverview.getGroupIcon(
      group,
      sizeIcon,
      this.openRecipes,
      'food',
      polyglot.t('group.tooltip.open_window_recipes')
    );
  }

  /**
   * Buttons actions
   * @param object group
   */
  buttonsActions(group: Group) {
    const { showButtonsGroups } = this.loadButtonsToDisplay();
    const { featureToggle, incidentsGroups, rightsUserUtil } = this.props;
    const showButtonHistory = rightsUserUtil.hasRightsToReadHistory();
    const sizeIcon = 'medium';

    return (
      <Button.Group floated="right">
        {
          /* Button contact */
          group.attributes.contacts &&
            group.attributes.contacts.length > 0 &&
            !browserUtil.getIsPhone() &&
            this.buttonContact(group, sizeIcon)
        }
        {
          /* Button edit group with a popup als tooltip */
          showButtonsGroups[group.id] &&
            showButtonsGroups[group.id].showButtonUpdateGroup &&
            this.buttonEdit(group, sizeIcon)
        }
        <GroupHistoryButton
          {...this.props}
          showButtonHistory={showButtonHistory}
          groupId={group.id}
          moduleSelected=""
        />
        {
          /* Button alarms */
          showButtonsGroups[group.id] &&
            showButtonsGroups[group.id].showButtonReadIncidents &&
            this.buttonAlarms(group, incidentsGroups[group.id], sizeIcon)
        }
        {
          /* Button recipes */
          showButtonsGroups[group.id] &&
            featureToggle.MODULE_RECIPES &&
            showButtonsGroups[group.id].showButtonRecipeGroup &&
            this.buttonRecipes(group, sizeIcon)
        }
        {featureToggle.camera && this.buttonCamera(group, sizeIcon)}
        {
          /* Button delete */
          showButtonsGroups[group.id] &&
            showButtonsGroups[group.id].showButtonDeleteGroup &&
            this.buttonDelete(group, sizeIcon)
        }
      </Button.Group>
    );
  }

  /**
   * Buttons actions grid
   * @param object group
   */
  buttonsActionsGrid(group: Group) {
    const { showButtonsGroups } = this.loadButtonsToDisplay();
    const { featureToggle, incidentsGroups, rightsUserUtil } = this.props;
    const showButtonHistory = rightsUserUtil.hasRightsToReadHistory();
    const sizeIcon = 'medium';

    return (
      <div>
        <Button.Group>
          {
            /* Button edit group with a popup als tooltip */
            showButtonsGroups[group.id] &&
              showButtonsGroups[group.id].showButtonUpdateGroup &&
              this.buttonEdit(group, sizeIcon)
          }
          <GroupHistoryButton
            {...this.props}
            showButtonHistory={showButtonHistory}
            groupId={group.id}
            moduleSelected=""
          />
          {
            /* Button alarms */
            showButtonsGroups[group.id] &&
              showButtonsGroups[group.id].showButtonReadIncidents &&
              this.buttonAlarms(group, incidentsGroups[group.id], sizeIcon)
          }
          {
            /* Button recipes */
            showButtonsGroups[group.id] &&
              featureToggle.MODULE_RECIPES &&
              showButtonsGroups[group.id].showButtonRecipeGroup &&
              this.buttonRecipes(group, sizeIcon)
          }
          {featureToggle.camera && this.buttonCamera(group, sizeIcon)}
        </Button.Group>
        <Button.Group floated="right">
          {
            /* Button delete */
            showButtonsGroups[group.id] &&
              showButtonsGroups[group.id].showButtonDeleteGroup &&
              this.buttonDelete(group, sizeIcon)
          }
        </Button.Group>
      </div>
    );
  }

  /**
   * Load buttons by group to display:
   * this function loads the rights to show the buttons for a group
   * rights to read incidents, rights to update group and rights to delete group
   * @param object group
   */
  loadButtonsByGroupToDisplay(group: Group) {
    const { rightsUserUtil } = this.props;
    const showButtonReadIncidents = rightsUserUtil.hasRightsToReadIncident(
      null,
      group.id
    );
    const showButtonUpdateGroup = rightsUserUtil.hasRightsToUpdateGroup(
      group.id
    );
    const showButtonDeleteGroup = rightsUserUtil.hasRightsToDeleteGroup(
      group.id
    );
    const showButtonRecipeGroup = rightsUserUtil.hasRightsToReadRecipe(
      null,
      group.id
    );
    const response: State['showButtonsGroups'] = {};
    response[group.id] = {
      showButtonReadIncidents,
      showButtonUpdateGroup,
      showButtonDeleteGroup,
      showButtonRecipeGroup,
    };
    return response;
  }

  /**
   * Load buttons to display:
   * this function loads the rights to show the buttons for each group
   * rights to read incidents, rights to update group and rights to delete group
   */
  loadButtonsToDisplay() {
    const { groups } = this.props;
    let showButtonsGroups: State['showButtonsGroups'] = {};
    const response = groups.map((group) =>
      this.loadButtonsByGroupToDisplay(group)
    );
    response.forEach(
      (group) => (showButtonsGroups = { ...group, ...showButtonsGroups })
    );
    return { showButtonsGroups };
  }

  /**
   * Open add contacts
   * @param object event
   * @param object group
   */
  openContacts(event: any, group: Group) {
    this.setState({
      groupContacts: group,
      showContacts: true,
    });
  }

  /**
   * Close add contacts
   */
  closeContacts() {
    this.setState({ showContacts: false });
  }

  /**
   * Open delete group
   */
  openDeleteGroup(event: any, group: Group) {
    this.setState({
      showDeleteGroup: true,
      groupIdUpdate: group.id,
    });
  }

  /**
   * Close delete group
   */
  closeDeleteGroup() {
    this.setState({ showDeleteGroup: false });
  }

  /**
   * Open edit group
   * @param object event
   * @para, object groupUpdate
   */
  openEditGroup(event: any, groupUpdate: Group) {
    this.setState({
      showEditGroup: true,
      groupIdUpdate: groupUpdate.id,
    });
  }

  /**
   * Close edit group
   */
  closeEditGroup() {
    this.setState({ showEditGroup: false });
  }

  /**
   * Open incidents
   * @param object event
   * @para, object groupUpdate
   */
  openIncidents(event: any, groupUpdate: Group) {
    const { history } = this.props;
    const url = `/devicemanager/${groupUpdate.id}/incidents`;
    history.push(url);
  }

  /**
   * Open recipes
   * @param object event
   * @para, object groupUpdate
   */
  openRecipes(event: any, groupUpdate: Group) {
    const { history } = this.props;
    const url = `/devicemanager/${groupUpdate.id}/recipes`;
    history.push(url);
  }

  openCameras(event: any, groupUpdate: Group) {
    this.setState({ groupIdUpdate: groupUpdate.id, showCameras: true });
  }

  closeCameras() {
    this.setState({ showCameras: false });
  }

  navigate(groupID: any) {
    const { history } = this.props;
    history.push(`/devicemanager/${groupID}`);
  }

  render() {
    const {
      showContacts,
      groupContacts,
      showDeleteGroup,
      showEditGroup,
      groupIdUpdate,
      showCameras,
    } = this.state;
    const { view, match } = this.props;
    const groupIdParent = match.params.groupId;
    const groups = this.props.groups.slice();
    const GroupsOverviewView = componentsGroupsOverview[view as VIEW_OPTIONS];
    return (
      <div className="GroupsOverview">
        {showContacts && (
          <Contacts
            group={groupContacts}
            showContacts={showContacts}
            closeContacts={this.closeContacts}
          />
        )}
        {showDeleteGroup && (
          <ConnectGroupDelete
            // @ts-ignore FIXME: ConnectGroupDelete not typed
            showDeleteGroup={showDeleteGroup}
            closeDeleteGroup={this.closeDeleteGroup}
            groupId={groupIdUpdate}
          />
        )}
        {showEditGroup && (
          <ConnectGroupEdit
            showEditGroup={showEditGroup}
            closeEditGroup={this.closeEditGroup}
            groupId={groupIdUpdate}
          />
        )}
        {showCameras && (
          <ConnectCameras
            showCameras={showCameras}
            groupId={groupIdUpdate}
            close={this.closeCameras}
          />
        )}

        <GroupsOverviewView
          navigate={this.navigate}
          groups={groups}
          groupIdParent={groupIdParent}
          buttonsActions={
            view === VIEW_LIST ? this.buttonsActions : this.buttonsActionsGrid
          }
          openContacts={this.openContacts}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  featureToggle: state.featureToggle,
  isLoadingIncidents: getIsLoadingAllPublicIncidents(state),
});

const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps)(
  withPolyglot(withSnackbar(withUserRightUtil(withRouter(GroupsOverview))))
);
