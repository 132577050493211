import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import DeviceIncidentsPage from './components/DeviceIncidentsPage/DeviceIncidentsPage';
import { DeviceMonitoringPage } from './components/DeviceMonitoringPage/DeviceMonitoringPage';
import DeviceOverviewPage from './components/DeviceOverviewPage/DeviceOverviewPage';

const routes = [
  { path: 'history', render: DeviceMonitoringPage },
  { path: 'incidents', render: DeviceIncidentsPage },
  { path: '', render: DeviceOverviewPage },
];

interface DeviceRoutesProps extends RouteComponentProps {}
export function DeviceRoutesSimple(props: DeviceRoutesProps) {
  return (
    <React.Fragment>
      <Switch>
        {routes.map(({ path, render }) => (
          <Route
            path={`${props.match.path}/${path}`}
            render={render}
            key={path}
            exact
          />
        ))}
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(DeviceRoutesSimple);
