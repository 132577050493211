import React from 'react';
import { Button, Card } from 'semantic-ui-react';

import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import DeviceModel from '../../../../../../model/device/Model';

import {
  DevicesOverviewProps,
  getClassesNamesFromDevice,
} from './DeviceOverview.util';
import './index.css';

/**
* Devices overview grid
* this class show a new overview for the device and allow the following actions:
* 1. Open a page to edit a device
* 2. Open the chart page
* 3. Open the alert page
* 4. Open a page to remove a device
+ 5. Open a page to add a new device
*
* Note: by iphone the button add device will be showed like a floating action buttons
* at the bottom right side. because the snackbar message is also showed "fix" at the bottom right
* we need to use the function onChildChanged to know when this snackbar will be showed to push this
* bottom up
* the devices array should have the following structure:
* {  cloudConnectivity,
*    cloudConnectivityIcon,
*    cloudConnectivityTooltip,
*    iconURL,
*    lastPing,
*    name,
*    state: { state: }
*    uuid
*  }
*/
// eslint-disable-next-line react/prefer-stateless-function
export class DevicesOverviewGrid extends React.PureComponent<
  DevicesOverviewProps & PolyglotComponentProps
> {
  render() {
    const {
      devices,
      getDeviceLabel,
      onClickItem,
      itemDisabled,
      getDeviceEditIcon,
      showDeviceEditIcon,
      getDeviceUIIcon,
      getDeviceSensordataIcon,
      showDeviceSensordataIcon,
      getDeviceCloudConnectivityIcon,
      getDeviceInfoIcon,
      deviceInfoEnabled = false,
      showButtonReadIncidentsDevices = {},
      getDeviceAlarmsIcon,
      polyglot,
    } = this.props;

    return (
      <Card.Group className="devices-overview-grid">
        {devices.map((device) => (
          <Card
            id={device.uuid}
            key={device.uuid}
            className={getClassesNamesFromDevice(device)}
          >
            <Card.Content className="toolbar-buttons">
              <Button.Group>
                {
                  /* Button edit device with a popup als tooltip */
                  showDeviceEditIcon(device.uuid) && getDeviceEditIcon(device)
                }

                {
                  /* Button ui device with a popup als tooltip */
                  getDeviceUIIcon(device)
                }

                {
                  /* Button ui device with a popup als tooltip */
                  showDeviceSensordataIcon && getDeviceSensordataIcon(device)
                }

                {
                  /* Button alarms  a popup als tooltip */
                  showButtonReadIncidentsDevices[device.uuid] &&
                    getDeviceAlarmsIcon(device)
                }
              </Button.Group>
              <Button.Group floated="right">
                {
                  /* Icon status device with a popup als tooltip */
                  getDeviceCloudConnectivityIcon(device)
                }

                {deviceInfoEnabled && getDeviceInfoIcon(device)}
              </Button.Group>
            </Card.Content>

            <Card.Content
              textAlign="center"
              className={
                !itemDisabled(device) ? 'image-group link' : 'image-group'
              }
              onClick={(event: any) => onClickItem(event, device)}
            >
              {getDeviceLabel(device)}
              <img
                src={DeviceModel.getDeviceIcon(device.iconURL)}
                className="image"
                alt={polyglot.t('device.icon')}
              />
            </Card.Content>

            <Card.Content
              className={
                !itemDisabled(device) ? 'description link' : 'description'
              }
              textAlign="center"
              onClick={(event: any) => onClickItem(event, device)}
            >
              {device.name}
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    );
  }
}

export default withPolyglot(DevicesOverviewGrid);
