import React from 'react';
import { createRoot } from 'react-dom/client';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import store from './redux/Store';
import App from './App';
import routes from './routes';
import { initializeRestClient } from './rest';

import packageInfo from '../package.json';

import '@fontsource/roboto';
import { CssBaseline } from '@mui/material';
import ThemeProviderWithTheme from './theme';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'stoerk-ui-components/build/index.css';
import { MODE_CLOUD_LIGHT, setDefaultMode } from 'stoerk-ui-components';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './polyfills';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import initialTogglesDictionary from './featureToggle/initialTogglesDictionary';

// theme
/**
 * Features toggles: allow the integrate features in the code before they're
 * finished and ready to release
 */

// Only active is production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8a70625927e34ca8be25f9b8bea9a771@sentry.io/170564',
    release: `${packageInfo.name}@${packageInfo.version}-${initialTogglesDictionary['StyledComponentsVariants']}`,
    environment: process.env.NODE_ENV,
  });
}

initializeRestClient(store);

setDefaultMode(MODE_CLOUD_LIGHT);

const container = document.getElementById('root');
const root = createRoot(container);

/*
 * Redirect from shopmanager -> devicemanager
 */
root.render(
  <Provider store={store}>
    <CssBaseline />
    <ThemeProviderWithTheme>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App store={store}>
            <Switch>
              {routes.map((props, index) => (
                <Route {...props} key={index.toString()} />
              ))}
            </Switch>
          </App>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProviderWithTheme>
    <ModalContainer />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Silent update of the service worker, so the app doesn't have to reload the page
    // to get the latest version of the app (if the service worker is updated) the user needs to reload the page.
    // at the moment we don't have/want a way to show a message to the user to reload the page
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  },
});
