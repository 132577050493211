import React from 'react';
import Polyglot from 'node-polyglot';
import { ICON_CONTROL_UNIT, SIZE_SMALL, UiIcon } from 'stoerk-ui-components';
import { getPolyglot } from '../../i18n';

/**
 * Model ControlUnit
 * This class transform ControlUnits with the Business Logic
 */
class ControlUnitModel {
  polyglot: Polyglot;
  static readonly controlUnitIcon = (
    <UiIcon icon={ICON_CONTROL_UNIT} size={SIZE_SMALL} />
  );
  constructor(polyglot: Polyglot = getPolyglot()) {
    this.polyglot = polyglot;
  }

  /**
   * Get device icon default
   * return the default icon, this icon is used by devices without icon
   * return string deviceIcon
   */
  static getControlUnitIconDefault = () => {
    return ControlUnitModel.controlUnitIcon;
  };
}

export default ControlUnitModel;
