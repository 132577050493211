import moment from 'moment';
import React from 'react';
import { BoxSensor, MODE_CLOUD_LIGHT } from 'stoerk-ui-components';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../../i18n';
import { DEFAULT_VALUE_SENSOR } from '../../../../../../../../../../../../model/controlUnit/util/constants';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../../../../../../../model/controlUnit/util/sensor/getValueStrFromSensor';
import { SensordataNewestMixed } from '../../../../../../../../../../../../model/monitoring/monitoring.model';
import { decodeError } from '../../../../../../../../../../../../model/monitoring/util/decodeError';
import { decodeState } from '../../../../../../../../../../../../model/monitoring/util/decodeState';
import { SensorsProps } from './Sensors';

interface SensorProps extends SensorsProps, PolyglotComponentProps {
  sensorData: SensordataNewestMixed;
  /** number of the list to change colors */
  index: number;
}
function Sensor(props: SensorProps) {
  const { sensorData, isOn, index, polyglot, CUTranslate } = props;

  const value = getValueStrFromSensor(sensorData, isOn, polyglot);
  const name =
    CUTranslate(sensorData?.metadata?.name ?? sensorData?.channel ?? '') ??
    DEFAULT_VALUE_SENSOR;

  const unitValue = getUnitStrFromSensor(sensorData, isOn);
  const title = sensorData?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        sensorData?.timestamp
      ).fromNow()}`
    : undefined;

  const stateString = decodeState(sensorData.state, polyglot);
  const errorString = decodeError(sensorData.state, polyglot);
  const titleAlarm =
    stateString && errorString ? `${errorString}: ${stateString}` : errorString;
  const sensorBoxColor = errorString ? -1 : index + 1;
  return (
    <BoxSensor
      titleValue={isOn ? name : null}
      unitValue={unitValue}
      value={isOn ? value : null}
      title={title}
      titleAlarm={titleAlarm}
      mode={MODE_CLOUD_LIGHT}
      sensorBoxColor={sensorBoxColor}
    />
  );
}

export default withPolyglot(Sensor);
