import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { withPolyglot } from '../../i18n';

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    const { polyglot } = this.state;
    return (
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h2>{polyglot.t('privacy.title')}</h2>
              <p>{polyglot.t('privacy.importance_statement')}</p>
              <p>{polyglot.t('privacy.intro')}</p>
              <p>{polyglot.t('privacy.lawful_collection')}</p>
              <p>{polyglot.t('privacy.committment')}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

PrivacyPolicyPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(PrivacyPolicyPage);
