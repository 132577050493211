import Polyglot from 'node-polyglot';

import { getPolyglot } from '../i18n';
import { restClient } from '../rest';
import { RestClientFacade } from '../rest/rest.model';

export class ServiceBase {
  constructor(
    private _polyglot?: Polyglot,
    private _restClient?: RestClientFacade
  ) {}

  protected get polyglot() {
    return this._polyglot ? this._polyglot : getPolyglot();
  }

  protected get restClient() {
    return this._restClient ? this._restClient : restClient;
  }
}
