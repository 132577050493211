import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import GroupEditContactsView from './GroupEditContactsView';
import ValidationUtil from '../../../../../util/ValidationUtil';
import { putGroup } from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import './index.css';
import produce from 'immer';
import Polyglot from 'node-polyglot';
import {
  Group,
  GroupAttributesAPI,
  GroupCreateAPI,
} from '../../../../../model/group/group.model';

type Props = {
  polyglot: Polyglot;
  group: Group;
  closeEditGroup(...args: unknown[]): unknown;
  activeTransition(...args: unknown[]): unknown;
  setIsPossibleToChangeTab(...args: unknown[]): unknown;
} & HandlingErrorWrappedProps &
  OpenSnackbarProps &
  ConnectedComponentProps;

type State = {
  contacts: Group['attributes']['contacts'];
};

/**
 * Group edit contacts
 * this component are localed in a Tab component inside a panel
 * when the panel is click the constructor method will be called
 *
 * the input for this class is an object as below:
 * group = {
 *   id,
 *   name,
 *   iconURL,
 *   attributes: {},
 *  }
 * and will be transformed:
 * group = {
 *   id,
 *   iconURL,
 *   contacts: {},
 *   otherFields: {}, => the others field will be not used here, but it is neccessary at the
 *     save event in order to build the attributes field
 *  }
 */
export class GroupEditContacts extends Component<Props, State> {
  validationUtil: ValidationUtil;
  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  /**
   * Wrapper group input
   * this function transform the group input to a valid object for the formular
   * edit details.
   * add a new element to the array contacts
   */
  static wrapperGroupInput(groupInput: Group) {
    const { attributes } = groupInput;
    let { contacts } = attributes;
    if (!contacts) {
      contacts = [];
    }

    const otherFields: { field: string; value: string }[] = [];
    Object.keys(attributes).forEach((key) => {
      if (key !== 'contacts') {
        const paar = { field: key, value: attributes[key] as string };
        otherFields.push(paar);
      }
    });

    const group = {
      id: groupInput.id,
      name: groupInput.name,
      iconURL: groupInput.iconURL,
      contacts,
      otherFields,
    };
    return group;
  }

  /**
   * Wrapper group input
   * this function transform the group input to a valid object for the formular
   * edit details.
   * add a new element to the array contacts
   */
  static wrapperContactsInput(groupInput: Group) {
    const { attributes } = groupInput;
    let contacts: Group['attributes']['contacts'] = [];
    Object.assign(contacts, attributes.contacts);
    if (!contacts) {
      contacts = [];
    }

    if (
      contacts.length === 0 ||
      contacts[contacts.length - 1].name ||
      contacts[contacts.length - 1].email ||
      contacts[contacts.length - 1].phone
    ) {
      contacts.push({
        name: '',
        email: '',
        phone: '',
      });
    }
    return contacts;
  }

  constructor(props: Props) {
    super(props);
    this.validationInputData = this.validationInputData.bind(this);
    this.serializeData = this.serializeData.bind(this);
    this.save = this.save.bind(this);
    this.updateFieldsContact = this.updateFieldsContact.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
    this.removeEmptyContacts = this.removeEmptyContacts.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    const contacts = GroupEditContacts.wrapperContactsInput(props.group);
    this.state = {
      contacts,
    };
    GroupEditContacts.fixBody();
  }

  /**
   * Remove empty contacts
   * this function will be called before the contact are saved to remove the contacts
   * with all the fields empty.
   * @return array contacts
   */
  removeEmptyContacts() {
    const { contacts } = this.state;
    /* if the all the fields from a contact are empty, it should be not saved */
    const contactsNew = [];
    for (let index = 0; index < contacts.length; index += 1) {
      if (
        contacts[index].name ||
        contacts[index].email ||
        contacts[index].phone
      ) {
        contactsNew.push(contacts[index]);
      }
    }
    return contactsNew;
  }

  /**
   * Validation input data
   * Validation: name should be not empty
   */
  validationInputData() {
    const { contacts } = this.state;
    let isInputDataValid = true;
    /* Validation email in the contact list */
    contacts.forEach((contact) => {
      if (!this.validationUtil.validateEmailAddress(contact.email, false)) {
        isInputDataValid = false;
      }
    });

    return isInputDataValid;
  }

  /**
   * Save
   */
  async save() {
    const { group } = this.props;
    const {
      polyglot,
      activeTransition,
      openSnackbar,
      handlingErrorsApi,
      setIsPossibleToChangeTab,
    } = this.props;
    try {
      if (!this.validationInputData()) {
        activeTransition();
      } else {
        await this.props.putGroup(group.id, this.serializeData());
        const message = {
          text: polyglot.t('group.save_group_successful_message'),
          type: 'ok',
        };
        openSnackbar(message);
        setIsPossibleToChangeTab(true);
      }
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Serialize data
   * this function prepares the data structure for the group
   * @return array group
   */
  serializeData() {
    const group = GroupEditContacts.wrapperGroupInput(this.props.group);
    const groupSerialized: Partial<GroupCreateAPI> = {
      id: group.id,
    };

    const contacts = this.removeEmptyContacts();
    const attributes: GroupAttributesAPI = {
      contacts: JSON.stringify(contacts),
    };

    /* Other Fields */
    group.otherFields.forEach((field) => {
      /* Check if the field was fillout - per default when the compenent is mounted
      the field this.state.contacts has a empty contact object */
      if (field.field !== '' && field.value !== '') {
        attributes[field.field] = field.value;
      }
    });

    if (JSON.stringify(attributes) !== '{}') {
      groupSerialized.attributes = attributes;
    }
    return groupSerialized;
  }

  /**
   * Update fields contact
   * this function will be called by input (fields contacts) changes
   * @param object event
   * @param string field
   * @param integer index
   */
  updateFieldsContact(
    event: { target: { value: string } },
    field: 'phone' | 'email' | 'name',
    index: number
  ) {
    const { setIsPossibleToChangeTab } = this.props;
    // use immer.produce to avoid mutations
    this.setState(
      produce(this.state, (draftState) => {
        const { contacts } = draftState;
        contacts[index][field] = event.target.value;
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore I'm not sure why .contact is here
          contacts[contacts.length - 1].contact ||
          contacts[contacts.length - 1].phone ||
          contacts[contacts.length - 1].email ||
          contacts[contacts.length - 1].name
        ) {
          contacts.push({
            name: '',
            email: '',
            phone: '',
          });
        }
      })
    );
    setIsPossibleToChangeTab(false);
  }

  /**
   * Delete contacts
   * @param object event
   * @param integer index
   */
  deleteContact(event: any, index: number) {
    const { setIsPossibleToChangeTab } = this.props;
    this.setState(
      produce(this.state, (draftState) => {
        draftState.contacts.splice(index, 1);
      })
    );
    setIsPossibleToChangeTab(false);
  }

  render() {
    const { contacts } = this.state;
    const { closeEditGroup } = this.props;
    return (
      <GroupEditContactsView
        contacts={contacts}
        save={this.save}
        closeEditGroup={closeEditGroup}
        updateFieldsContact={this.updateFieldsContact}
        deleteContact={this.deleteContact}
      />
    );
  }
}

const connector = connect(null, { putGroup });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(null, { putGroup })(
  withHandlingErrors(withSnackbar(withPolyglot(connector(GroupEditContacts))))
);
