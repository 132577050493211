import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import {
  getUserInformation,
  updateUserData,
  saveUserAvatar,
  resetAvatar,
} from '../../../redux/users/actions';
import UserProfileView from './UserProfileView';
import ValidationUtil from '../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import { RootState } from '../../../redux/store.model';
import { UserAPIResponse } from '../../../model/user/user.model';
import { Container, CircularProgress, Box } from '@mui/material';
import { IconUpload } from '../../../redux/groups/groups.model';
import {
  getApplicationsFeatureToggle,
  getDefaultApplicationFeatureToggle,
} from '../../../featureToggle';
import { saveDefaultApplication } from '../../../featureToggle/redux/thunks';

const Kbyte = 1024;
const maxSizeImageKbyte = 100; // maximal size in kilobytes 100kb
const maxHeightImage = 500; // 500px
const maxWidthImage = 500; // 500px

interface Props
  extends PolyglotComponentProps,
    ConnectedComponentProps,
    HandlingErrorWrappedProps,
    OpenSnackbarProps {}

interface State {
  animation: any;
  duration: number;
  visible: boolean;
  user: any | Partial<UserAPIResponse>;
  iconUpload: Partial<IconUpload>;
  showImageLoad?: boolean;
  loading?: boolean;
}

export class UserProfile extends Component<Props, State> {
  validationUtil: ValidationUtil;
  constructor(props: Props) {
    super(props);
    this.getUserInformation = this.getUserInformation.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.imageValidation = this.imageValidation.bind(this);
    this.save = this.save.bind(this);
    this.activeTransition = this.activeTransition.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.resetAvatar = this.resetAvatar.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    this.state = {
      loading: true,
      animation: null,
      duration: 500,
      visible: true,
      user: {},
      iconUpload: {
        fileName: '',
        imagePreviewUrl: '',
      },
    };
  }

  async componentDidMount() {
    await this.getUserInformation();
  }

  /**
   * On change change
   *
   * saves the file data in state variables
   * show a preview of the file
   * @param file
   */
  onChangeImage(file: React.ChangeEvent<HTMLInputElement>) {
    const iconFile = file.target.files && file.target.files[0];
    const { iconUpload } = this.state;
    if (!iconFile) {
      return;
    }
    this.imageValidation(iconFile)
      .then(() => {
        iconUpload.fileName = iconFile.name;
        iconUpload.file = iconFile;
        this.setState({
          iconUpload,
          showImageLoad: true,
        });
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          iconUpload.imagePreviewUrl = fileReader.result as string;
          this.setState({
            iconUpload,
            showImageLoad: false,
          });
        };
        fileReader.readAsDataURL(iconFile);
      })
      .catch(this.props.openSnackbar);
  }

  /**
   * On complete transtion
   */
  onCompleteTransition() {
    const { visible } = this.state;
    if (visible) {
      this.setState({ visible: false, animation: null });
    }
  }

  /**
   * Get user information
   * this call get the avatar information of the logged user
   */
  async getUserInformation() {
    try {
      await this.props.getUserInformation();
      const { user } = this.props;
      this.setState({
        user,
        loading: false,
        visible: false,
        animation: null,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  showApplications = () => {
    return this.props.listApplications.length > 1;
  };

  async saveDefaultApplication(defaultApplication?: string) {
    if (!this.showApplications() || !defaultApplication) return;
    await this.props.saveDefaultApplication(defaultApplication);
  }

  /**
   * Image validation
   *
   * 1. is image
   * 2. size
   * 3. height
   * 4. width
   * @param file image
   * @return boolean|1
   */
  imageValidation(image: File) {
    const { polyglot } = this.props;
    const promise = new Promise((resolve, reject) => {
      let message = {};
      if (image.type.indexOf('image') === -1) {
        /* Snackbar message error */
        message = {
          text: polyglot.t('error.only_format_images'),
          type: 'error',
        };
        reject(message);
      }
      if (image.size > maxSizeImageKbyte * Kbyte) {
        /* Snackbar message error */
        message = {
          text: `${polyglot.t(
            'error.images_size_restriction'
          )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`,
          type: 'error',
        };
        reject(message);
      }

      /* check height and width */
      const URL = window.URL || window.webkitURL;
      const img = new Image();
      img.onload = () => {
        if (img.width > maxWidthImage) {
          /* Snackbar message error */
          message = {
            text: `${polyglot.t('error.images_width_restriction')} ${polyglot.t(
              'general.max_width'
            )}: ${maxWidthImage} ${polyglot.t('general.px')}`,
            type: 'error',
          };
          reject(message);
        } else if (img.height > maxHeightImage) {
          /* Snackbar message error */
          message = {
            text: `${polyglot.t(
              'error.images_height_restriction'
            )} ${polyglot.t(
              'general.max_height'
            )}: ${maxHeightImage} ${polyglot.t('general.px')}`,
            type: 'error',
          };
          reject(message);
        }
        resolve(true);
      };
      img.src = URL.createObjectURL(image);
    });
    return promise;
  }

  /**
   * Reset avatar
   */
  async resetAvatar() {
    const { openSnackbar, polyglot, handlingErrorsApi } = this.props;
    try {
      await this.props.resetAvatar();
      const { user } = this.state;
      this.setState({
        iconUpload: {
          fileName: '',
          imagePreviewUrl: '',
        },
        user: { ...user, avatarLocation: null },
      });
      /* Snackbar message ok */
      const message = {
        text: polyglot.t('user.reset_avatar_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Save
   * 1. call rest api to send the group data details [name, fields]
   * 2. call rest api to send the group avatar
   *
   * @params event
   */
  async save(user: Partial<UserAPIResponse & { defaultApplication: string }>) {
    /* show snack bar with successful message */
    try {
      const { iconUpload } = this.state;
      await this.props.updateUserData(user as Partial<UserAPIResponse>);
      if (iconUpload.fileName !== '') {
        await this.props.saveUserAvatar(iconUpload as IconUpload);
      }
      await this.saveDefaultApplication(user.defaultApplication);
      this.setState({ user });
      const { polyglot, openSnackbar } = this.props;
      /* Snackbar message ok */
      const message = {
        text: polyglot.t('user.save_group_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Active Transition
   */
  activeTransition() {
    let { visible, animation } = this.state;
    visible = !visible;
    animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  render() {
    const {
      animation,
      duration,
      visible,
      user,
      iconUpload,
      showImageLoad,
      loading,
    } = this.state;

    return (
      <Transition
        animation={animation}
        duration={duration}
        visible={visible}
        onComplete={this.onCompleteTransition}
      >
        <Container maxWidth="md">
          {loading ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <UserProfileView
              user={user}
              onChangeImage={this.onChangeImage}
              iconUpload={iconUpload}
              resetAvatar={this.resetAvatar}
              showImageLoad={showImageLoad}
              save={this.save}
              imageValidation={this.imageValidation}
              onSubmitInvalid={this.activeTransition}
              showApplications={this.showApplications()}
              listApplications={this.props.listApplications}
              defaultApplication={this.props.defaultApplication}
            />
          )}
        </Container>
      </Transition>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.users.item,
  listApplications: getApplicationsFeatureToggle(state),
  defaultApplication: getDefaultApplicationFeatureToggle(state),
});
const connector = connect(mapStateToProps, {
  getUserInformation,
  updateUserData,
  saveUserAvatar,
  saveDefaultApplication,
  resetAvatar,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(withSnackbar(withPolyglot(UserProfile)))
);
