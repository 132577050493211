import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { setAutoFreeze } from 'immer';

// Fixes "Cannot assign to read only property" error message
// when modifying objects from Redux state directly.
// it is only for compatible production bundle meanwhile whe fix all mutation errors
// https://github.com/reduxjs/redux-toolkit/issues/424
if (process.env.NODE_ENV === 'production') {
  setAutoFreeze(false);
}

const initialState = {};
const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: process.env.NODE_ENV !== 'production',
    }).concat(middleware),
  devTools: true,
  preloadedState: initialState,
});

export default store;
