import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConnectHeader from '../components/header';
import ConnectFooter from '../components/footer';
import './App.css';
import Util from '../util/Util';
import InformationMessageBox from '../components/commons/informationMessageBox';
import { getPolyglot, getLanguage } from '../i18n';
import { loadSession } from '../redux/auth/actions';
import infoMessage from '../infoMessage.json';
import { Dimmer, Loader } from 'semantic-ui-react';
import withHandlingErrors from '../handlingErrors';
import { initOneSignal } from '../util/OneSignal/init';

const util = new Util();

const loginlessPaths = [
  '/',
  '/login',
  '/privacy',
  '/impressum',
  '/cookies',
  '/register',
  '/signup',
  '/registered',
  '/activate',
  '/resetpassword',
  '/forgotpassword',
  '/resettriggered',
  '/commander_login',
];
const sitesWithFooter = ['/login', '/privacy', '/impressum', '/cookies'];
const showCookieNoticeKey = util.getShowCookieNoticeKey();

class App extends Component {
  constructor(props) {
    super(props);
    this.shouldShowFooter = this.shouldShowFooter.bind(this);
    this.cookieNoticeConfirmed = this.cookieNoticeConfirmed.bind(this);
    this.authentication = this.authentication.bind(this);
    this.polyglot = getPolyglot();
    let cookieNoticeConfirmed = localStorage?.getItem(showCookieNoticeKey);
    if (!cookieNoticeConfirmed) {
      cookieNoticeConfirmed = cookie.load(showCookieNoticeKey);
    }
    this.authentication();
    initOneSignal();
    this.state = {
      showCookieNotice: !cookieNoticeConfirmed,
      isAuthenticating: true,
    };
  }

  /**
   * Authentication
   */

  async authentication() {
    try {
      /* fill in the variable accessToken */
      await this.props.loadSession();
      this.setState({ isAuthenticating: false });
    } catch (error) {
      this.props.handlingErrorsApi(error);
      this.setState({ isAuthenticating: false });
    }
  }

  /**
   * Should show footer
   */
  shouldShowFooter() {
    const { location } = this.props;
    if (sitesWithFooter.indexOf(location.pathname) > -1) {
      return true;
    }

    return false;
  }

  /**
   * Auth required
   */
  authRequired() {
    const { location } = this.props;
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_DEV_LOGIN === 'false'
    ) {
      return false;
    }

    if (loginlessPaths.indexOf(location.pathname) > -1) {
      return false;
    }

    return true;
  }

  /**
   * Cookie notice confirmed
   */
  cookieNoticeConfirmed() {
    cookie.save(showCookieNoticeKey, true, { path: '/' });
    localStorage.setItem(showCookieNoticeKey, true);
    this.setState({ showCookieNotice: false });
  }

  render() {
    const {
      location,
      loggedIn,
      history,
      containerExtraClassName = '',
    } = this.props;
    const { isAuthenticating } = this.state;
    if (
      location &&
      this.authRequired(location.pathname) &&
      !loggedIn &&
      !isAuthenticating
    ) {
      history.replace({
        pathname: '/login',
        state: { nextPathname: location.pathname },
      });
    }

    const { children } = this.props;
    const { showCookieNotice } = this.state;
    const childrenWithProps =
      children && !isAuthenticating ? (
        React.cloneElement(children, {
          router: history,
          showCookieNotice,
          cookieNoticeConfirmed: this.cookieNoticeConfirmed,
          location,
        })
      ) : (
        <Dimmer active inverted>
          <Loader inverted></Loader>
        </Dimmer>
      );

    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <div className={`main-container container ${containerExtraClassName}`}>
        {/* Header */}
        <ConnectHeader />
        {/* Container */}
        <div id="container">
          <main>{childrenWithProps}</main>
          {/* Footer */}
          <ConnectFooter
            cookieNoticeConfirmed={this.cookieNoticeConfirmed}
            showCookieNotice={showCookieNotice}
          />
        </div>
        <InformationMessageBox locale={getLanguage()} message={infoMessage} />
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  location: PropTypes.shape({
    state: PropTypes.objectOf(PropTypes.any),
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.objectOf(Object).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loadSession: PropTypes.func.isRequired,
  handlingErrorsApi: PropTypes.func,
  containerExtraClassName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps, {
  loadSession,
})(withHandlingErrors(withRouter(App)));
