import React, { Component } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Grid, Container, Image, Icon, Divider } from 'semantic-ui-react';
import ValidationUtil from '../../../util/ValidationUtil';
import SortUtil from '../../../util/SortUtil';
import { fetchUsers } from '../../../redux/users/actions';
import { withPolyglot } from '../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import {
  INCIDENT_STATUS_RESOLVED,
  INCIDENT_STATUS_NEW,
  INCIDENT_STATUS_INPROGRESS,
  INCIDENT_PRIORITY_LOW,
  INCIDENT_PRIORITY_HIGH,
  INCIDENT_PRIORITY_MEDIUM,
  ICON_PRIORITY,
} from '../Constants';
import './index.css';
import Polyglot from 'node-polyglot';
import { UserAPIResponse } from '../../../model/user/user.model';
import {
  IncidentAPIResponse,
  IncidentHistoryAPIResponse,
} from '../../../model/incidentManager/incident.model';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

type Props = {
  polyglot: Polyglot;
  historyIncident: IncidentAPIResponse['history'];
  users?: UserAPIResponse[];
} & HandlingErrorWrappedProps;

type RecordIncidentHistoryWithUserData = IncidentHistoryAPIResponse & {
  userName: string;
  userAvatarLocation: string;
};

/**
 * Incident history
 */
export class History extends Component<Props> {
  validationUtil: ValidationUtil;
  status: Record<string, string>;
  priority: Record<string, string>;
  constructor(props: Props) {
    super(props);
    this.getHistoryIncidentWithUserData =
      this.getHistoryIncidentWithUserData.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);

    this.status = {};
    this.status[INCIDENT_STATUS_NEW] = props.polyglot.t(
      'incident_manager.status_options.new'
    );
    this.status[INCIDENT_STATUS_INPROGRESS] = props.polyglot.t(
      'incident_manager.status_options.inprogress'
    );
    this.status[INCIDENT_STATUS_RESOLVED] = props.polyglot.t(
      'incident_manager.status_options.resolved'
    );
    this.priority = {};
    this.priority[INCIDENT_PRIORITY_LOW] = props.polyglot.t(
      'incident_manager.priority_options.low'
    );
    this.priority[INCIDENT_PRIORITY_MEDIUM] = props.polyglot.t(
      'incident_manager.priority_options.medium'
    );
    this.priority[INCIDENT_PRIORITY_HIGH] = props.polyglot.t(
      'incident_manager.priority_options.high'
    );
  }

  componentDidMount() {
    this.getHistoryIncidentWithUserData();
  }

  /**
   * Load missing data
   * @param array history
   */
  getHistoryIncidentWithUserData(): RecordIncidentHistoryWithUserData[] {
    /* if there are records, the user assigned of each record will be searched */
    const { polyglot, users, historyIncident } = this.props;
    if (historyIncident.length === 0) {
      return [];
    }
    /* users from the groups including the user assigned */
    const allUsers = users || [];

    const historyIncidentWithUserData = historyIncident.map((record, index) => {
      let userName = undefined;
      let avatar = undefined;
      if (index === historyIncident.length - 1) {
        userName = polyglot.t('incident_manager.created_incident');
      }

      const user = allUsers.find(
        (userData) =>
          userData.id === record.user || userData.id === record.assignedUser
      );
      if (user) {
        userName = user.name;
        avatar = user.avatarLocation;
      } else {
        userName = polyglot.t('incident_manager.title');
      }
      return { ...record, userName, userAvatarLocation: avatar };
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return historyIncidentWithUserData;
  }

  render() {
    const { polyglot } = this.props;
    const historyIncident = SortUtil.multisort(
      this.getHistoryIncidentWithUserData(),
      ['timestamp'],
      ['DESC']
    );

    /**
     * Show history entry
     * @param object record
     * @return bool
     */
    const showHistoryEntryNoComment = (
      record: RecordIncidentHistoryWithUserData
    ) =>
      record.description ||
      record.assignedUser ||
      record.category ||
      record.priority ||
      record.status ||
      record.summary;

    /**
     * Display field
     * @param string title
     * @param string value
     */
    const displayField = (
      title: string | null | undefined,
      value: string | null | undefined
    ) => (
      <Grid.Row verticalAlign="middle">
        <Grid.Column mobile={5} tablet={4} computer={4}>
          {title ?? ''}
        </Grid.Column>
        <Grid.Column mobile={10} tablet={12} computer={12}>
          {value ?? ''}
        </Grid.Column>
      </Grid.Row>
    );

    /**
     * Display fields table
     * @param object record
     * @return object view
     */
    const displayFieldsTable = (record: RecordIncidentHistoryWithUserData) => (
      <Grid>
        <Grid.Row />
        <Grid.Row
          className="incident-manager-titles"
          verticalAlign="bottom"
          textAlign="left"
        >
          <Grid.Column
            mobile={5}
            tablet={4}
            computer={4}
            verticalAlign="bottom"
          >
            {polyglot.t('incident_manager.titles_columns.field')}
          </Grid.Column>
          <Grid.Column
            mobile={10}
            tablet={12}
            computer={12}
            verticalAlign="bottom"
          >
            {polyglot.t('incident_manager.titles_columns.new_value')}
          </Grid.Column>
        </Grid.Row>
        {/* Description */}
        {record.description &&
          displayField(
            polyglot.t('incident_manager.description'),
            record.description
          )}
        {/* Assigned user */}
        {record.assignedUser &&
          displayField(
            polyglot.t('incident_manager.assigned_user'),
            record.userName
          )}
        {/* Category */}
        {record.category &&
          displayField(
            polyglot.t('incident_manager.category'),
            record.category
          )}
        {/* Priority */}
        {record.priority && (
          <Grid.Row verticalAlign="middle">
            <Grid.Column mobile={5} tablet={4} computer={4}>
              {polyglot.t('incident_manager.priority')}
            </Grid.Column>
            <Grid.Column mobile={10} tablet={12} computer={12}>
              <Icon
                name={`${ICON_PRIORITY[record.priority].icon}` as SemanticICONS}
                className={`priority ${record.priority}`}
              />
              {this.priority[record.priority]}
            </Grid.Column>
          </Grid.Row>
        )}
        {/* Status */}
        {record.status &&
          displayField(
            polyglot.t('incident_manager.status'),
            this.status[record.status]
          )}
        {/* Summary */}
        {record.summary &&
          displayField(polyglot.t('incident_manager.summary'), record.summary)}
      </Grid>
    );

    /**
     * Display field comment
     * @param object record
     * @return object view
     */
    const displayFieldComment = (record: RecordIncidentHistoryWithUserData) => (
      <div className="incident-manager-history-record">
        <Grid>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column
              mobile={15}
              tablet={16}
              computer={16}
              verticalAlign="bottom"
            >
              {showHistoryEntryNoComment(record) &&
                polyglot.t('incident_manager.added_comment')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column
              mobile={15}
              tablet={16}
              computer={16}
              verticalAlign="bottom"
            >
              {record.comment}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );

    /**
     * Display title user
     * @param object record
     * @param string user
     * @param datetime datetime
     * @param string description
     * @return object view
     */
    const displayTitleUser = (
      record: { userAvatarLocation: string },
      user: string | null,
      datetime: string,
      description: string | undefined = undefined
    ) => (
      <div>
        {record.userAvatarLocation && (
          <Image src={record.userAvatarLocation} size="mini" avatar />
        )}
        {user && `${user} `}
        {description && `${description} `}
        {datetime && datetime}
      </div>
    );

    /**
     * Display title history
     * @param object record
     * @param int index
     * @param int totalRecordsHistory
     * @return object view
     */
    const displayTitleHistory = (
      record: RecordIncidentHistoryWithUserData,
      index: number
    ) => {
      /* first register (sort by date desc) */
      if (index === historyIncident.length - 1) {
        return displayTitleUser(
          record,
          record.userName,
          Moment(record.timestamp).format('L LTS')
        );
      }
      /* Title for no entries only comment */
      const user = !record.userName ? null : `${record.userName}:`;
      const datetime = `${polyglot.t('general.on')} ${Moment(
        record.timestamp
      ).format('L LTS')}`;
      if (!showHistoryEntryNoComment(record) && record.comment) {
        return displayTitleUser(
          record,
          user,
          datetime,
          polyglot.t('incident_manager.added_comment')
        );
      }
      return displayTitleUser(
        record,
        user,
        datetime,
        polyglot.t('incident_manager.made_changes')
      );
    };

    return (
      <Grid>
        {historyIncident &&
          historyIncident.map((record, index) => (
            <Container fluid key={index.toString()} id={`Id${index}`}>
              {displayTitleHistory(record, index)}
              {showHistoryEntryNoComment(record) && displayFieldsTable(record)}
              {record.comment && displayFieldComment(record)}
              {index < historyIncident.length && <Divider />}
            </Container>
          ))}
      </Grid>
    );
  }
}

export default connect(null, {
  fetchUsers,
})(withHandlingErrors(withPolyglot(History)));
