import React from 'react';
import { SetPointEventMixed } from '../../../../../../../../../../../../model/controlUnit/model/SetPoint.model';
import {
  customSetPoints,
  DEFAULT_DECIMALS,
} from '../../../../../../../../../../../../model/controlUnit/util/constants';
import { ControlUnitAPIResponse } from '../../../../../../../../../../../../model/controlUnit/ControlUnit.model';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { getSetPointName } from '../../../../../../../../../../../../model/controlUnit/util/setPoint/getSetPointName';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../../i18n';
// import SetPointEdit from './SetPointEdit';
import { MODE_CLOUD_LIGHT, SingleDataSetPoint } from 'stoerk-ui-components';
export interface SetPointProps extends PolyglotComponentProps {
  setPoint: SetPointEventMixed;
  controlUnit: ControlUnitAPIResponse;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export function SetPoint(props: SetPointProps) {
  const { setPoint, controlUnit, CUTranslate, polyglot } = props;

  // const [showEditSetPoint, setShowEditSetPoint] = useState(false);
  // const openEditSetPoint = () => setShowEditSetPoint(true);
  // const onCloseEditSetPoint = () => setShowEditSetPoint(false);

  const setPointDec = setPoint.metadata?.dec ?? DEFAULT_DECIMALS;
  const setPointUnit = setPoint.metadata?.unit;
  const setPointValue = parseFloat(setPoint.value).toFixed(setPointDec);
  const setPointMinValue = setPoint.metadata?.min.toFixed(setPointDec);
  const setPointMaxValue = setPoint.metadata?.max.toFixed(setPointDec);

  const setPointButton =
    customSetPoints[controlUnit.version.progNum]?.[setPoint.name ?? ''];

  const titleSetPoint = getSetPointName(
    setPoint.eventID,
    CUTranslate(setPoint.name ?? '') as string,
    polyglot,
    setPointButton,
    setPointMinValue,
    setPointMaxValue,
    setPointUnit
  );
  // FIXME when write action are available
  // if (showEditSetPoint) return <SetPointEdit {...props} />;

  return (
    <SingleDataSetPoint
      title={titleSetPoint}
      value={`${setPointValue !== undefined ? setPointValue : '---'}`}
      showIconUpdate={setPointValue !== undefined}
      onClickIconUpdate={() => {
        alert('WIP: action not supported');
      }}
      unitValue={setPointUnit}
      mode={MODE_CLOUD_LIGHT}
    />
  );
}

export default withPolyglot(SetPoint);
