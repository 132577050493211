import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import ValidationUtil from '../../../util/ValidationUtil';
import { login, logOut } from '../../../redux/auth/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { RootState } from '../../../redux/store.model';
import { OpenSnackbarProps } from '../../../handlingErrors';
import {
  Box,
  Container,
  Divider,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import LoginForm, { LoginFromValues } from './LoginForm';
import { getDefaultApplicationFeatureToggle } from '../../../featureToggle';

interface LoginProps
  extends ConnectedComponentProps,
    OpenSnackbarProps,
    RouteComponentProps,
    PolyglotComponentProps {}

interface LoginState {
  animation: any;
  duration: number;
  visible: boolean;
}

export class Login extends Component<LoginProps, LoginState> {
  validationUtil: ValidationUtil;
  constructor(props: LoginProps) {
    super(props);
    const { polyglot } = props;
    this.login = this.login.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);
  }

  componentDidMount() {
    /** When the user is redirected to /login, we want logOuted user */
    this.props.logOut();
  }

  /**
   * Login
   * @param object event
   */
  async login(values: LoginFromValues) {
    const { openSnackbar, history } = this.props;
    try {
      const { email, password, keepLogin } = values;
      await this.props.login(email, password, keepLogin);
      /* The user is redirected to the device manager without to wait to set the rights.
       In the device manager page a loader component is shown until all the data
       is loaded */
      history.push(`/${this.props.defaultApplication}`);
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  render() {
    const { polyglot } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Paper>
          <Typography component="h1" variant="h4" textAlign="center">
            {polyglot.t('login.title')}
          </Typography>
          <LoginForm
            validationUtil={this.validationUtil}
            onSubmit={this.login}
          />
          <Box sx={{ marginY: 1 }}>
            <Divider />
          </Box>

          <Box sx={{ marginY: 1, textAlign: 'center' }}>
            <Box sx={{ marginY: 1, textAlign: 'center' }}>
              <Typography component="p" variant="body1">
                {polyglot.t('login.register_message')}
              </Typography>
              <Link to="/signup">
                <Button>{polyglot.t('login.register_link_title')}</Button>
              </Link>
            </Box>
            <Box sx={{ marginY: 1, textAlign: 'center' }}>
              <Typography component="p" variant="body1">
                {polyglot.t('login.forgot_password_message')}
              </Typography>
              <Link to="/forgotpassword">
                <Button>
                  {polyglot.t('login.forgot_password_link_title')}
                </Button>
              </Link>
            </Box>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
  fetchingRightsCycle: state.auth.fetchingRightsCycle,
  defaultApplication: getDefaultApplicationFeatureToggle(state),
});
const connector = connect(mapStateToProps, { login, logOut });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withSnackbar(withPolyglot(Login)));
