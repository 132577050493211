import { IconUpload } from '../../redux/groups/groups.model';
import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';

import * as callTypes from './CallTypes';
import { UserAPIResponse, UserSelfAPIResponse } from './user.model';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class UserCalls {
  readonly backendUserServiceURL = new Util().geUserServiceURL();
  readonly backendGroupServiceURL = new Util().getGroupServiceURL();
  constructor(private restClient = restClientHttp) {}

  /**
   * Get users
   * get users data by multiple users ids
   * @param array usersId
   */
  async getUsers(usersId: string[]) {
    if (!usersId || usersId.length === 0) {
      return [];
    }
    return Promise.all(usersId.map((id) => this.getUserDataById(id)));
  }

  /**
   * Get user information
   * this function call the backend to get the user information
   * @param {string} userId
   */
  getUserDataById(userId: string) {
    const url = `${this.backendUserServiceURL}/${userId}`;
    return this.restClient.callPromise<UserAPIResponse>(
      {
        url,
        method: 'GET',
      },
      callTypes.getUserDataById
    );
  }

  /**
   * Get user information
   * this function call the backend to get the of the logged user information
   */
  async getUserInformation() {
    const url = `${this.backendUserServiceURL}/self`;
    return this.restClient.callPromise<UserSelfAPIResponse>(
      {
        url,
        method: 'GET',
      },
      callTypes.getUserInformation
    );
  }

  /**
   * Update user data
   * this function call the backend to save the user data
   * @param object userData
   */
  async updateUserData(userData: Partial<UserSelfAPIResponse>) {
    const url = `${this.backendUserServiceURL}/self`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: userData,
      },
      callTypes.updateUserData
    );
  }

  /**
   * Save user avatar
   * this function call the backend to save the user's profile picture
   * @param object iconUpload
   */
  async saveUserAvatar(iconUpload: IconUpload) {
    const form = new FormData();
    form.append('file', iconUpload.file, iconUpload.fileName);
    form.append('source', 'local');
    const url = `${this.backendUserServiceURL}/self/avatar`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: form,
      },
      callTypes.saveUserAvatar
    );
  }

  /**
   * Reset avatar
   * this function call the backend to reset the user's profile picture
   */
  async resetAvatar() {
    const url = `${this.backendUserServiceURL}/self/avatar`;
    return this.restClient.callPromise(
      {
        url,
        method: 'DELETE',
      },
      callTypes.resetAvatar
    );
  }

  /**
   * get available users to assign group
   * @param string groupId
   */
  async getAvailableUsersToAssignGroup(groupId: string) {
    const url = `${this.backendGroupServiceURL}/${groupId}/users/available-adds`;
    const usersIds = await this.restClient.callPromise<string[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getAvailableUsersToAssignGroup
    );
    return this.getUsers([...new Set(usersIds)]);
  }
}

export default UserCalls;
