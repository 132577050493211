/* eslint-disable eqeqeq */
import { DeviceControlSetPointsMetadataAPIResponse } from '../../../device/device.model';
import { DigitalChannelEventMixed } from '../../../monitoring/monitoring.model';
import { EVENT_NAME_SET1SET2, SETPOINT_1, SETPOINT_2 } from '../constants';

export const getUsedSetPointById = (
  setPoints: DeviceControlSetPointsMetadataAPIResponse[] | undefined,
  actions: Record<string, DigitalChannelEventMixed | undefined>
) => {
  if (!setPoints || setPoints.length == 0) {
    return undefined;
  }
  const actionSet1Set2 = actions?.[EVENT_NAME_SET1SET2];
  if (actionSet1Set2?.state === 0) {
    const setPointToSearch =
      // if the value is 0, then the used set point is 1.
      actionSet1Set2.value == '0'
        ? SETPOINT_1
        : // if the value is 1, then the used setPoint is 2
          SETPOINT_2;
    const currentSetPoints = setPoints.find(
      (setPoint) => setPoint.id === setPointToSearch
    );
    if (currentSetPoints && actions?.[currentSetPoints.id]) {
      return {
        ...(actions[currentSetPoints.id] as DigitalChannelEventMixed),
        metadata: currentSetPoints,
      };
    }
  }
  return undefined;
};
