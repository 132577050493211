import store from '../Store';
import { loadGroupsDevices } from './actions/thunks';
import { actionsGroups } from './slice';

/**
 * Load groups devices periodically
 */
export const loadGroupsDevicesPeriodically = async () => {
  setTimeout(async () => {
    if (store.getState().groups.activatedLoadingGroupsDevices) {
      try {
        await store.dispatch(loadGroupsDevices());
        loadGroupsDevicesPeriodically();
      } catch (error) {
        console.error('Backend errors service groups devices :', error);
        /* try again to reload the groups and devices */
        loadGroupsDevicesPeriodically();
      }
    }
  }, 600000);
};

export const initializeLoadGroupsDevicesPeriodically = async () => {
  await store.dispatch(actionsGroups.activatedLoadingGroupsDevices(true));
  loadGroupsDevicesPeriodically();
};
