import { Container, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../../../handlingErrors';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../i18n';
import { useControlUnitById } from '../../../../../../../../../../redux/controlUnit/hooks/useControlUnitById';
import { useDevice } from '../../../../../../../../../../redux/devices/hooks/useDevice';
import { RootState } from '../../../../../../../../../../redux/store.model';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../../../../../../../util/rights';
import BreadcrumbNew from '../../../../../../../../../commons/breadcrumbNew';
import {
  getLastPath,
  NavigationPathProps,
} from '../../../../../../../../navigations/navigationPath';
import ControlUnitCardList from './ControlUnitCardList';
import ControlUnitRoutes from './ControlUnitRoutes';
import { getDeviceSectionsNavigationSelector } from './navigation';

interface ControlUnitPageLayoutProps
  extends RouteComponentProps<{
      groupId?: string;
      controlUnitId: string;
      deviceId: string;
    }>,
    HandlingErrorWrappedProps,
    PolyglotComponentProps,
    ConnectedComponentProps,
    RightsUserUtilComponentProps {}
export function ControlUnitPageLayoutSimple(props: ControlUnitPageLayoutProps) {
  const {
    sectionsNavigation,
    match: {
      params: { controlUnitId, deviceId, groupId },
    },
    location: { pathname },
    handlingErrorsApi,
    rightsUserUtil,
  } = props;
  const showButtonHistory = rightsUserUtil.hasRightsToReadHistory();

  /** Init Groups */
  useDevice(deviceId, handlingErrorsApi);

  const [controlUnit, loadingControlUnits, errorControlUnits] =
    useControlUnitById(deviceId, controlUnitId, handlingErrorsApi);

  // Error handler
  useEffect(() => {
    if (errorControlUnits) handlingErrorsApi(errorControlUnits);
  }, [handlingErrorsApi, errorControlUnits]);

  const moduleSelected = getLastPath(pathname);

  return (
    <React.Fragment>
      <BreadcrumbNew sections={sectionsNavigation} />
      <Container maxWidth="lg">
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <ControlUnitCardList
            moduleSelected={moduleSelected}
            loading={loadingControlUnits}
            controlUnit={controlUnit}
            controlUnitId={controlUnitId}
            deviceId={deviceId}
            groupId={groupId}
            showButtonHistory={showButtonHistory}
          />
          <ControlUnitRoutes />
        </Paper>
      </Container>
    </React.Fragment>
  );
}
const mapStateToProps = (
  state: RootState,
  props: NavigationPathProps &
    RouteComponentProps<{
      groupId?: string | undefined;
      controlUnitId: string;
      deviceId: string;
    }>
) => ({
  sectionsNavigation: getDeviceSectionsNavigationSelector(state, props),
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedControlUnitLayout = withHandlingErrors(
  withUserRightUtil(withPolyglot(connector(ControlUnitPageLayoutSimple)))
);
export default ConnectedControlUnitLayout;
