import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { STModal } from '../../../../../../../components/commons/Modal';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../i18n';
import menus from '../../../mocks/menu';
import {
  FlexeserveMenu,
  FlexeserveTimer,
  FlexeserveZone,
} from '../../../model';
import getDuration from '../../../util/getDuration';
import { useTick } from '../../../util/hook/useTick';
import TextTransition from './TextTransition';

interface FlexeserveTimerCellProps extends PolyglotComponentProps {
  index: number;
  setTimer: (menu: FlexeserveMenu, zone: FlexeserveZone, i: number) => void;
  timer?: FlexeserveTimer;
  zone: FlexeserveZone;
  // animation
  showTime?: boolean;
}
const FlexeserveTimerCell = (props: FlexeserveTimerCellProps) => {
  const { polyglot, zone, index, setTimer } = props;
  const [openModal, setOpenModal] = useState(false);
  const onClickTimer = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const menusAvailable = menus.filter(
    (item) => item.setting === zone.settingName
  );

  const onClickMenu = (menu: FlexeserveMenu) => {
    setTimer(menu, zone, index);
    onCloseModal();
  };

  return (
    <>
      <Button
        sx={{ padding: 0 }}
        onClick={onClickTimer}
        disabled={!zone.active}
      >
        <FlexeserveTimerCellBody {...props} />
      </Button>
      <STModal
        open={openModal}
        onClose={onCloseModal}
        title={polyglot.t('nuttall.flexeserve.select_menu')}
        fullWidth
        maxWidth="sm"
      >
        {menusAvailable.length ? (
          <List>
            {menusAvailable.map((item) => (
              <ListItem disablePadding key={item.itemName}>
                <ListItemButton onClick={() => onClickMenu(item)}>
                  <ListItemText
                    primary={item.itemName}
                    secondary={`${item.displayName} - ${getDuration(
                      item.holdTime * 1000
                    )}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>
            {polyglot.t('nuttall.flexeserve.no_menus_availables')}
          </Typography>
        )}
      </STModal>
    </>
  );
};

function getDiffFromTimer(timer?: FlexeserveTimer) {
  if (!timer) return 0;
  const { dateStart, menu } = timer;
  const holdLastDate = moment(dateStart).add(menu?.holdTime, 'seconds');
  let diff = holdLastDate.diff(moment());
  if (diff < 0) diff = 0;
  return diff;
}

function getBackgroundColor(
  inactive: boolean,
  warning: boolean,
  danger: boolean
) {
  if (inactive) return undefined;
  if (danger) return 'error.dark';
  if (warning) return 'warning.dark';
  return 'success.dark';
}
const TextTick = ({ timer }: { timer?: FlexeserveTimer }) => {
  const diff = useTick(() => getDiffFromTimer(timer), 1000, [timer]);
  const text = getDuration(diff);
  return <>{text}</>;
};

const FlexeserveTimerCellBody = (props: FlexeserveTimerCellProps) => {
  const { timer, showTime } = props;
  // time text
  const holdTime = timer?.menu?.holdTime ?? 0;

  const diff = useTick(() => getDiffFromTimer(timer), 1000, [timer]);
  const duration = moment.duration(diff);

  const inactive = !timer;
  const warning = holdTime * 0.2 > duration.asSeconds();
  const danger = diff <= 0;
  const content: React.ReactNode = useMemo(() => {
    let content: React.ReactNode = timer?.menu?.displayName ?? '';
    if (showTime) content = <TextTick timer={timer} />;
    if (inactive) content = '';
    return content;
  }, [inactive, showTime, timer]);

  return (
    <Paper
      className="FlexeserveLayoutGrid-cell cell"
      sx={{
        height: (theme) => theme.spacing(12),
        minWidth: (theme) => theme.spacing(18),
        maxWidth: (theme) => theme.spacing(18),
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: getBackgroundColor(inactive, warning, danger),
        margin: (theme) => theme.spacing(0.5),
      }}
    >
      <Typography variant="h4" component={'span'}>
        <TextTransition text={content} />
      </Typography>
    </Paper>
  );
};
export default withPolyglot(FlexeserveTimerCell);
