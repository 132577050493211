import { createSelector } from '@reduxjs/toolkit';
import {
  navigationPath,
  NavigationPathProps,
} from '../../../../navigations/navigationPath';
import { getPolyglot } from '../../../../../../i18n';
import { Group } from '../../../../../../model/group/group.model';
import { getDeviceSelectedName } from '../../../../../../redux/devices/selectors';
import {
  getGroupIdQueryParam,
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../../../../redux/groups/selectors';
import { RootState } from '../../../../../../redux/store.model';
import { NavQueryParams } from './monitoring.model';

export const getMonitoringSectionsNavigation = (
  props: NavigationPathProps,
  groupId: string | undefined,
  groups: Group[],
  group: Group | undefined,
  deviceName: string
) => {
  return navigationPath(props, groupId, groups, group).concat(
    getSectionMonitoring(deviceName, groupId, props.history)
  );
};

const getSectionMonitoring = (
  deviceName: string,
  groupId: string | undefined,
  history: NavigationPathProps['history']
) => {
  const polyglot = getPolyglot();
  const content = polyglot.t('device.monitoring.title');

  return [
    {
      content: deviceName || '...',
      // No screen for device info, so return to previous group
      onClick: () => history.push(`/devicemanager/${groupId || ''}`),
    },
    {
      content,
      onClick: () => {
        // Do nothing
      },
    },
  ];
};

const getProps = (_: RootState, props: NavQueryParams) => props;

export const getMonitoringSectionsNavigationSelector = createSelector(
  [
    getProps,
    getGroupIdQueryParam,
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDeviceSelectedName,
  ],
  getMonitoringSectionsNavigation
);
