import React, { Component } from 'react';
import { Button, Segment, Loader, Popup, Grid } from 'semantic-ui-react';
import { TableSort, withSnackbar } from 'stoerk-ui-components';
import { CardActions, Button as ButtonMUI } from '@mui/material';

import BrowserUtil from '../../../../../../util/BrowserUtil';
import { convertSecondsToHumanReadable } from '../../../../../../util/TimeUnitsUtil';
import ConnectMaintenanceMessagesConfigurationEdit from './Edit';
import {
  getLanguage,
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../i18n';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../../handlingErrors';
import { STDialog } from '../../../../../commons/Modal';
import './index.css';

const browserUtil = new BrowserUtil();

interface OwnProps {
  showCloseWindow?: boolean;
  closeWindow?: () => unknown;
  id?: string;
  hasRightsToUpdate: boolean;
  hasRightsToDelete: boolean;
  getMaintenanceMessages: (id: string) => unknown;
  deleteMaintenanceMessage: (id: string, messageId: string) => unknown;
  updateMaintenanceMessage: (
    deviceId: any,
    messageId: any,
    message: any
  ) => Promise<void>;
  setMaintenanceMessage: (deviceId: any, message: any) => Promise<void>;
  messages: any;
  loading: any;
}
interface Props
  extends OwnProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    OpenSnackbarProps {}

interface State {
  showLoading: boolean;
  showMessageDelete?: boolean;
  showMessageEdit?: boolean;
  messageId?: null | undefined | string;
}
/**
 * Maintenance messages configuration
 * Since the maintenance messages for vendors, gruops and devices have the same structure
 * this component will be used to set the configurations for all of them
 */
export class MaintenanceMessagesConfiguration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.openDeleteConfirmWindow = this.openDeleteConfirmWindow.bind(this);
    this.closeDeleteConfirmWindow = this.closeDeleteConfirmWindow.bind(this);
    this.deleteConfirmWindow = this.deleteConfirmWindow.bind(this);
    this.delete = this.delete.bind(this);
    this.dataTable = this.dataTable.bind(this);
    this.openEditWindow = this.openEditWindow.bind(this);
    this.closeEditWindow = this.closeEditWindow.bind(this);
    this.state = {
      showLoading: false,
    };
  }

  /**
   * Component did mount
   * will be run after constructor
   * the needed data will be load here
   */
  async componentDidMount() {
    const { messages, id = '', loading } = this.props;
    if (messages[id] === undefined && !loading[id]) {
      await this.loadData();
    }
  }

  /**
   * Load data
   */
  async loadData() {
    try {
      const { id = '' } = this.props;
      this.setState({ showLoading: true });
      await this.props.getMaintenanceMessages(id);
      this.setState({ showLoading: false });
    } catch (error) {
      this.setState({ showLoading: false });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Delete
   */
  delete() {
    try {
      const { messageId } = this.state;
      const { id = '', polyglot, openSnackbar } = this.props;
      this.props.deleteMaintenanceMessage(id, messageId || '');
      /* show snack bar with successful message */
      this.setState({
        showMessageDelete: false,
        messageId: null,
      });
      const message = {
        text: polyglot.t(
          'maintenance_messages.remove_maintenance_message_successful_message'
        ),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      this.setState({
        showMessageDelete: false,
        messageId: null,
      });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Open delete confirm window
   * @param object event
   * @param string deleteId
   */
  openDeleteConfirmWindow(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    messageId: any
  ) {
    this.setState({
      showMessageDelete: true,
      messageId,
    });
  }

  /**
   * Close delete confirm window
   */
  closeDeleteConfirmWindow() {
    this.setState({
      showMessageDelete: false,
      messageId: null,
    });
  }

  /**
   * Delete confirm window
   */
  deleteConfirmWindow() {
    const { showMessageDelete } = this.state;
    const { polyglot } = this.props;
    return (
      <STDialog
        id="deleteConfirmWindow"
        open={!!showMessageDelete}
        onClose={this.closeDeleteConfirmWindow}
        buttonActions={
          <>
            <ButtonMUI type="button" onClick={this.closeDeleteConfirmWindow}>
              {polyglot.t('general.no')}
            </ButtonMUI>
            <ButtonMUI
              type="button"
              variant="contained"
              color="error"
              onClick={this.delete}
            >
              {polyglot.t('general.yes')}
            </ButtonMUI>
          </>
        }
      >
        <p>
          {polyglot.t(
            'maintenance_messages.remove_maintenance_message_confirm'
          )}
        </p>
      </STDialog>
    );
  }

  /**
   * Open edit window
   * @param object event
   * @param string messageId
   */
  openEditWindow(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    messageId: any
  ) {
    this.setState({
      messageId,
      showMessageEdit: true,
    });
  }

  /**
   *  Close edit window
   * @param object event
   * @param string messageId
   */
  closeEditWindow() {
    this.setState({
      messageId: null,
      showMessageEdit: false,
    });
  }

  /**
   * Data table
   * Create the columns and row data for the table
   * @return object { columnsFormat, data }
   */
  dataTable() {
    const {
      messages,
      id = '',
      polyglot,
      hasRightsToUpdate,
      hasRightsToDelete,
    } = this.props;

    const language = getLanguage();
    let columnsFormat = {};
    let data = {};
    if (messages[id] !== undefined && messages[id].length > 0) {
      columnsFormat = {
        title: {
          allowSort: true,
          title: polyglot.t('maintenance_messages.title'),
        },
        description: {
          allowSort: true,
          title: polyglot.t('maintenance_messages.description'),
        },
        interval: {
          allowSort: true,
          title: `${polyglot.t('maintenance_messages.interval')}`,
        },
        buttons: {
          allowSort: false,
          title: '',
        },
      };

      data = messages[id].map((message: any) => {
        let buttonEdit = (
          <Button
            id="iconEditMaintenanceMessage"
            icon="write"
            size="small"
            basic
            onClick={(event) => this.openEditWindow(event, message.id)}
          />
        );
        buttonEdit = !browserUtil.getIsMobile() ? (
          <Popup
            trigger={buttonEdit}
            content={polyglot.t(
              'maintenance_messages.tooltip.update_maintenance_message'
            )}
          />
        ) : (
          buttonEdit
        );

        let buttonDelete = (
          <Button
            id="iconDeleteMaintenacenMessage"
            icon="delete"
            size="small"
            basic
            onClick={(event) => this.openDeleteConfirmWindow(event, message.id)}
          />
        );
        buttonDelete = !browserUtil.getIsMobile() ? (
          <Popup
            trigger={buttonDelete}
            content={polyglot.t(
              'maintenance_messages.tooltip.remove_maintenance_message'
            )}
          />
        ) : (
          buttonDelete
        );

        let title = '';
        if (message.messages !== undefined && message.messages) {
          title = message.messages[language]
            ? message.messages[language]
            : message.messages[Object.keys(message.messages)[0]];
        }

        let description = '';
        if (message.descriptions !== undefined && message.descriptions) {
          description = message.descriptions[language]
            ? message.descriptions[language]
            : message.descriptions[Object.keys(message.descriptions)[0]];
        }
        return {
          title,
          description,
          interval: convertSecondsToHumanReadable(message.interval, polyglot),
          buttons:
            hasRightsToUpdate || hasRightsToDelete ? (
              <Button.Group>
                {/* Button edit */}
                {hasRightsToUpdate && buttonEdit}
                {/* Button delete */}
                {hasRightsToDelete && buttonDelete}
              </Button.Group>
            ) : null,
        };
      });
    }

    return { columnsFormat, data };
  }

  render() {
    const {
      messages,
      loading,
      id = '',
      closeWindow,
      showCloseWindow,
      deleteMaintenanceMessage,
      setMaintenanceMessage,
      polyglot,
    } = this.props;
    const { showMessageDelete, showMessageEdit, messageId, showLoading } =
      this.state;

    const showLoadingGeneral =
      showLoading && (!loading[id] === undefined || loading[id]);

    const { columnsFormat, data } = this.dataTable();
    return (
      <div>
        <Segment>
          {showMessageEdit ? (
            <ConnectMaintenanceMessagesConfigurationEdit
              // @ts-ignore FIXME: MaintenanceMessagesConfigurationEdit not typed
              id={id}
              messageId={messageId}
              messages={messages[id]}
              updateMaintenanceMessage={deleteMaintenanceMessage}
              setMaintenanceMessage={setMaintenanceMessage}
              closeWindow={this.closeEditWindow}
            />
          ) : (
            <div>
              {showMessageDelete && this.deleteConfirmWindow()}
              {showLoadingGeneral && <Loader />}
              {messages[id] !== undefined && messages[id].length > 0 && (
                <div>
                  <TableSort data={data} columnsFormat={columnsFormat} />
                </div>
              )}
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      primary
                      circular
                      icon="add"
                      size="huge"
                      className="raised-button"
                      floated="right"
                      onClick={(event) => this.openEditWindow(event, null)}
                      id="ButtonAddMaintenanceMessage"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </Segment>
        {showCloseWindow && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <ButtonMUI onClick={closeWindow}>
              {polyglot.t('button.close')}
            </ButtonMUI>
          </CardActions>
        )}
      </div>
    );
  }
}

export default withHandlingErrors(
  withSnackbar(withPolyglot(MaintenanceMessagesConfiguration))
);
