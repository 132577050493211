import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';
import {
  CUParametersAllDevicesAPIResponse,
  CUParametersObjAPIResponse,
} from './CUParameters.model';

class CUParametersCalls {
  readonly backendParametersServiceURL = new Util().getParameterServiceURL();
  constructor(private restClient = restClientHttp) {}

  async getCUParametersFromCU(
    deviceId: string,
    controlUnitId: number | string
  ) {
    const url = `${this.backendParametersServiceURL}/${deviceId}/${controlUnitId}`;

    return this.restClient
      .get<CUParametersObjAPIResponse | undefined>(url)
      .then(
        (data) =>
          data ??
          // When controlUnit not found it respond empty
          ({
            idCU: controlUnitId,
            parameters: {},
          } as CUParametersObjAPIResponse)
      );
  }

  async getCUParametersFromDevice(deviceId: string) {
    const url = `${this.backendParametersServiceURL}/${deviceId}`;

    return this.restClient.get<CUParametersAllDevicesAPIResponse>(url);
  }

  async setCUParameters(
    deviceId: string,
    controlUnitId: number | string,
    parameterLevelName: string,
    parameterName: string,
    parameterValue: string
  ) {
    const url = `${this.backendParametersServiceURL}/${deviceId}/${controlUnitId}`;
    const parameterObject = {
      idCU: controlUnitId,
      parameters: {
        [parameterLevelName]: [
          {
            name: parameterName,
            value: parameterValue,
          },
        ],
      },
    };

    return this.restClient.patch<undefined>(url, parameterObject);
  }
}

export default CUParametersCalls;
