import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store.model';

export function getFeatureToggle(state: RootState) {
  return state.featureToggle;
}

const getApplicationsListFeatureToggle = (state: RootState) =>
  getFeatureToggle(state).ApplicationsList;
const getApplicationFeatureToggle = (state: RootState) =>
  getFeatureToggle(state).ApplicationDefault ?? 'devicemanager';

export const getApplicationsFeatureToggle = createSelector(
  [getApplicationsListFeatureToggle],
  (applicationsListString) =>
    applicationsListString?.split(',') ?? ['devicemanager']
);

export const getDefaultApplicationFeatureToggle = createSelector(
  [getApplicationFeatureToggle, getApplicationsFeatureToggle],
  (defaultApplication, listApplications) => {
    if (listApplications.includes(defaultApplication))
      return defaultApplication ?? 'devicemanager';
    return 'devicemanager';
  }
);
