import withStyles from 'react-jss';
import ButtonAdd from '../../../../../components/commons/buttonAdd/ButtonAdd';

const styles = {
  '@global': {
    '.add-device-button button, .add-device-button-fix button, .raised-button':
      {
        'box-shadow': '0 2px 5px 0 rgba(0, 0, 0, 0.26) !important',
        'background-color': '#d94d15 !important',
      },
    '.add-device-button button:active, .add-device-button button:hover, .add-device-button-fix button:active, .add-device-button-fix button:hover, .raised-button:active, .raised-button:hover':
      { 'box-shadow': '0 8px 17px 0 rgba(0, 0, 0, 0.2) !important;' },
  },
};

export default withStyles(styles)(ButtonAdd);
