import { Stack } from '@mui/material';
import { Moment } from 'moment';
import Polyglot from 'node-polyglot';
import React from 'react';
import { TimeFrameSelection, LAST_6_HOURS } from 'stoerk-ui-components';
import { getLanguage } from '../../../../../../../../i18n';
import DigitalChannelFilters from './DigitalChannelFilters';
import { CommanderMonitoringFilterLogicalObject } from '../../util/filter.model';
import { useDevicesControlUnits } from '../../../../../../../../redux/controlUnit/hooks/useDevicesControlUnits';

export type ChartsFiltersProps = {
  polyglot: Polyglot;
  onChangeDatePicker(...args: Moment[]): void;
  deviceId?: string;
  controlUnitId?: string;
  groupId?: string;
  analogControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
  digitalControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
};

export const ChartsFilters = (props: ChartsFiltersProps) => {
  const [devicesControlUnits] = useDevicesControlUnits();
  const {
    analogControls: [analogFilterValue, setAnalogFilterValue],
    digitalControls: [digitalFilterValue, setDigitalFilterValue],
  } = props;

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ margin: 2 }}
    >
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="channels"
        value={analogFilterValue}
        onSave={setAnalogFilterValue}
      />
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="events"
        value={digitalFilterValue}
        onSave={setDigitalFilterValue}
      />

      <div className="TimeFrame">
        <TimeFrameSelection
          timeFrame={LAST_6_HOURS}
          onChange={props.onChangeDatePicker}
          nameButtonSend={props.polyglot.t('button.go')}
          language={getLanguage()}
        />
      </div>
    </Stack>
  );
};
