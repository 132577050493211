import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { ActionButton, MODE_CLOUD_LIGHT } from 'stoerk-ui-components';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../../i18n';
import { actionViewMapping } from '../../../../../../../../../../../../model/controlUnit/util/action/actionViewMapping';
import { ActionExtendedValue } from '../../../../../../../../../../../../model/controlUnit/util/action/mergeActionMetadataAndEvent';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { filterNotEmpty } from '../../../../../../../../../../../../util/ArrayUtil';

interface ActionsProps extends PolyglotComponentProps {
  actions: ActionExtendedValue[];
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export function Actions(props: ActionsProps) {
  const { actions, CUTranslate, polyglot } = props;
  const actionsProps = actions
    .map((action) =>
      actionViewMapping(action, polyglot, CUTranslate, true, true, true)
    )
    .filter(filterNotEmpty);

  return (
    <div className="ControlUnit-Actions-container">
      <Paper variant="outlined" sx={{ padding: 1 }}>
        <Typography variant="body1">Actions:</Typography>
        <Grid container spacing={3} alignContent="flex-start">
          {actionsProps.map((actionProps) => (
            <Grid
              item
              xs="auto"
              key={actionProps.key}
              className="action-container-button"
            >
              <ActionButton
                {...actionProps}
                onClick={() => {
                  alert('WIP: unsupported action');
                }}
                size={'big'}
                mode={MODE_CLOUD_LIGHT}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
}

export default withPolyglot(Actions);
