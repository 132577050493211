import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { withSnackbar } from 'stoerk-ui-components';
import { deleteGroup } from '../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../i18n';
import { withHandlingErrors } from '../../../handlingErrors';
import { getGroups } from '../../../redux/groups/selectors';
import { STModal } from '../../commons/Modal';
import ButtonLoading from '../../commons/ButtonLoading/ButtonLoading';

/**
 * Group Delete
 * This class allow remove a group
 */
export class GroupDelete extends Component {
  constructor(props) {
    super(props);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.state = { loading: false };
  }

  /**
   * Delete group
   * this function call the backend to remove a group
   */
  async deleteGroup() {
    const {
      handlingErrorsApi,
      openSnackbar,
      closeDeleteGroup,
      groupId,
      polyglot,
    } = this.props;
    this.setState({ loading: true });
    try {
      await this.props.deleteGroup(groupId);
      /* show snack bar with successful message */
      const message = {
        text: polyglot.t('group.delete_successful_message'),
        type: 'ok',
      };
      closeDeleteGroup();
      openSnackbar(message);
    } catch (error) {
      handlingErrorsApi(error);
    }
    this.setState({ loading: false });
  }

  render() {
    const { polyglot, showDeleteGroup, closeDeleteGroup } = this.props;
    return (
      <div>
        <STModal
          open={showDeleteGroup}
          title={polyglot.t('group.delete_dialog_title')}
          onClose={closeDeleteGroup}
          buttonActions={
            <>
              <Button onClick={closeDeleteGroup}>
                {polyglot.t('group.cancel_button_title')}
              </Button>
              <ButtonLoading
                variant="contained"
                color="error"
                onClick={this.deleteGroup}
                loading={this.state.loading}
              >
                {polyglot.t('group.delete_button_title')}
              </ButtonLoading>
            </>
          }
        >
          <p>{polyglot.t('group.delete_dialog_text')}</p>
        </STModal>
      </div>
    );
  }
}

GroupDelete.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  openSnackbar: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  showDeleteGroup: PropTypes.bool.isRequired,
  closeDeleteGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  groups: getGroups(state),
});

export default connect(mapStateToProps, { deleteGroup })(
  withHandlingErrors(withSnackbar(withPolyglot(GroupDelete)))
);
