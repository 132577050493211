import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { Group } from '../../../model/group/group.model';
import GroupModel from '../../../model/group/Model';
import { ErrorRestST } from '../../../rest/rest.model';
import { fetchGroup, fetchGroups } from '../actions/thunks-creator';
import { GroupsState, nameReducer } from '../groups.model';

export const initialStateGroups: GroupsState = {
  treeGroups: [],
  loading: false,
};

export const GroupsSlice = createSlice({
  name: nameReducer,
  initialState: initialStateGroups,
  reducers: {
    activatedLoadingGroupsDevices(state, action: PayloadAction<boolean>) {
      state.activatedLoadingGroupsDevices = action.payload;
    },
    deleteGroupDevice(
      state,
      action: PayloadAction<{ groupId: string; deviceId: string }>
    ) {
      const group = GroupModel.getGroupByGroupId(
        action.payload.groupId,
        state.treeGroups,
        state.individualGroup
      );
      if (group) {
        group.devices = group.devices.filter(
          (device) => device !== action.payload.deviceId
        );
      }
    },
    deleteGroupUser(
      state,
      action: PayloadAction<{ groupId: string; userId: string }>
    ) {
      const group = GroupModel.getGroupByGroupId(
        action.payload.groupId,
        state.treeGroups,
        state.individualGroup
      );
      if (group) {
        group.users = group.users.filter(
          (user) => user !== action.payload.userId
        );
      }
    },
    addGroupUser(
      state,
      action: PayloadAction<{ groupId: string; usersIds: string[] }>
    ) {
      const group = GroupModel.getGroupByGroupId(
        action.payload.groupId,
        state.treeGroups,
        state.individualGroup
      );
      if (group) {
        group.users = uniq([...group.users, ...action.payload.usersIds]);
      }
    },
    addGroup(
      state,
      action: PayloadAction<{ group: Group; parentGroupID?: string }>
    ) {
      let groups = state.treeGroups;
      if (action.payload.parentGroupID) {
        groups = (
          GroupModel.getGroupByGroupId(
            action.payload.parentGroupID,
            state.treeGroups,
            state.individualGroup
          ) as Group
        )?.childGroups;
      }
      groups.push(action.payload.group);
    },
    updateGroup(
      state,
      action: PayloadAction<{ groupId: string; partialGroup: Partial<Group> }>
    ) {
      const group = GroupModel.getGroupByGroupId(
        action.payload.groupId,
        state.treeGroups,
        state.individualGroup
      );
      Object.assign(group, action.payload.partialGroup);
    },
    deleteGroup(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const group = GroupModel.getGroupHasChildWhitGroupId(
        groupId,
        state.treeGroups,
        state.individualGroup
      );
      if (group) {
        group.childGroups = group.childGroups.filter(
          (group) => group.id !== groupId
        );
      } else {
        // search in root in groupTree if group not exist
        if (state.treeGroups.find((group) => group.id === groupId)) {
          state.treeGroups = state.treeGroups.filter(
            (group) => group.id !== groupId
          );
        }
      }
    },
  },
  extraReducers: (builder) => {
    // fetchGroups Thunk
    builder.addCase(fetchGroups.pending, (state, action) => {
      // Notice it mutate state safety because it use immer https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
      state.loading = true;
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      return {
        ...state,
        treeGroups: action.payload,
        loading: false,
      };
    });
    builder.addCase(fetchGroups.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorRestST;
    });
    // fetchGroup Thunk
    builder.addCase(fetchGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGroup.fulfilled, (state, action) => ({
      ...state,
      individualGroup: action.payload,
      loading: false,
    }));
    builder.addCase(fetchGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorRestST;
    });
  },
});

export const {
  actions: actionsGroups,
  reducer: reducerGroups,
  name: nameReducerGroups,
} = GroupsSlice;
