import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../../../../handlingErrors';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { QueryParamControlUnitPage } from '../CUPage.model';
import './ControlUnitDetailPage.scss';
import Actions from './Actions';
import MainSensor from './MainSensor/MainSensor';
import Sensors from './Sensors';
import SetPoints from './SetPoints';
import Status from './Status';

interface ControlUnitDetailPageProps extends HandlingErrorWrappedProps {}
export function ControlUnitDetailPage(props: ControlUnitDetailPageProps) {
  const { handlingErrorsApi } = props;
  const match = useRouteMatch<QueryParamControlUnitPage>();
  const {
    params: { deviceId = '', controlUnitId = '' },
  } = match;

  const {
    loading,
    controlUnit,
    currentAlarms,
    currentSensorData,
    usedSetPoint,
    hasAlarms,
    actions,
    isOn,
    mainSensor,
    type,
    CUTranslate,
  } = useCurrentStateByControlUnitId(
    deviceId,
    controlUnitId,
    handlingErrorsApi
  );
  return (
    <div className="ControlUnitDetailPage-container">
      <Loader active={loading} />
      <Sensors
        sensorsData={currentSensorData}
        isOn={isOn}
        CUTranslate={CUTranslate}
        type={type}
      />
      <MainSensor
        mainSensor={mainSensor}
        isOn={isOn}
        currentAlarms={currentAlarms}
        hasAlarms={hasAlarms}
        CUTranslate={CUTranslate}
        type={type}
      />
      <SetPoints
        usedSetPoint={usedSetPoint}
        controlUnit={controlUnit}
        CUTranslate={CUTranslate}
      />
      <Actions actions={actions} CUTranslate={CUTranslate} />
      <Status CUTranslate={CUTranslate} />
    </div>
  );
}

export default withHandlingErrors(ControlUnitDetailPage);
