import FETCH_EVENTS_BY_DEVICES_ID from '../actions/Types';

const initialState = {
  items: [],
  item: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS_BY_DEVICES_ID:
      return {
        ...state,
        item: action.payload,
      };
    default:
      return state;
  }
}
