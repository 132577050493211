import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message, Image, Container } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'stoerk-ui-components';
import { connect } from 'react-redux';
import logo from '../../assets/stoerkIcon.png';
import { accountActivation } from '../../redux/auth/actions';
import { withPolyglot } from '../../i18n';

export class AccountActivationPage extends Component {
  constructor(props) {
    super(props);
    this.activationAccount = this.activationAccount.bind(this);
    this.state = {
      message: '',
    };
  }

  componentDidMount() {
    const { location, polyglot, openSnackbar } = this.props;
    const { search } = location;
    const params = search.split('&');
    let token;
    params.forEach((p) => {
      const split = p.split('=');
      if (split[0] === 'token' || split[0] === '?token') {
        token = split[1];
      }
    });

    if (token) {
      this.activationAccount(token);
    } else {
      this.setState({
        message: polyglot.t('account_activation.invalid_token_message'),
      });
      const message = {
        text: polyglot.t('account_activation.invalid_token_message'),
        type: 'error',
      };
      openSnackbar(message);
    }
  }

  /**
   * Activation account
   * @param string token
   */
  async activationAccount(token) {
    const { polyglot, history, openSnackbar } = this.props;
    try {
      this.setState({
        message: `${polyglot.t('account_activation.activating_user')} ${token}`,
      });
      await this.props.accountActivation(token);
      // Delay redirection a bit. Otherwise, we might not see the activation screen at all
      // and just land on the login page which feels weird.
      this.setState({
        message: polyglot.t('account_activation.message'),
      });
      setTimeout(() => {
        history.replace('/');
      }, 2000);
    } catch (error) {
      const errorDescription =
        typeof error === 'object' ? error.message : error;
      this.setState({
        message: errorDescription,
      });
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  render() {
    const { message } = this.state;
    return (
      <Container>
        <Message info>
          <Image
            src={logo}
            alt="Störk-Tronic logo"
            size="mini"
            floated="left"
          />
          <p className="lead">{message}</p>
        </Message>
      </Container>
    );
  }
}

AccountActivationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  openSnackbar: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  accountActivation: PropTypes.func.isRequired,
};

export default connect(null, {
  accountActivation,
})(withSnackbar(withRouter(withPolyglot(AccountActivationPage))));
