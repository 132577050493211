/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList } from 'react';
import { useEffect, useState } from 'react';
/**
 * Hook to refresh component every 1 second
 *
 * Creates an interval which will update the current data every <interval> ms
 * This will trigger a rerender every component that uses the useTick hook.
 */
export const useTick = <T>(
  factory: () => T,
  interval: number = 1000,
  deps?: DependencyList
) => {
  const [state, setState] = useState(factory());
  useEffect(() => {
    const timer = setInterval(() => {
      setState(factory());
    }, interval);
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (deps) setState(factory());
  }, deps);

  return state;
};
