import { Buffer } from 'buffer';

export default class Calls {
  constructor(restCalls) {
    this.restCalls = restCalls;
  }

  getGroupCameras(groupID) {
    return new Promise((resolve, reject) => {
      this.restCalls.getGroupCameras(
        groupID,
        (response) => resolve(response),
        reject
      );
    });
  }

  getPairableCameras(groupID) {
    return new Promise((resolve, reject) => {
      this.restCalls.getPairableCameras(
        groupID,
        (response) => resolve(response),
        reject
      );
    });
  }

  pairCameras(groupID, cameras) {
    return new Promise((resolve, reject) => {
      const promises = cameras.map(
        (camera) =>
          new Promise((resolve1, reject1) => {
            this.restCalls.pairCamera(
              groupID,
              camera,
              (response) => resolve1(response),
              reject1
            );
          })
      );

      Promise.all(promises)
        .then((res) => resolve(res[0]))
        .catch((error) => reject(error));
    });
  }

  getCameraImage(groupID, cameraID) {
    return new Promise((resolve, reject) => {
      this.restCalls.getCameraImage(
        groupID,
        cameraID,
        (response) => resolve(Buffer.from(response.data).toString('base64')),
        reject
      );
    });
  }

  getCameraVideo(groupID, cameraID) {
    return new Promise((resolve, reject) => {
      this.restCalls.getCameraVideo(
        groupID,
        cameraID,
        (response) => resolve(Buffer.from(response.data).toString('base64')),
        reject
      );
    });
  }

  getAuthURL(groupID) {
    return new Promise((resolve, reject) => {
      this.restCalls.getCameraAuthLink(
        groupID,
        (response) => resolve(response),
        reject
      );
    });
  }
}
