import withStyles from 'react-jss';
import GroupsOverview from '../../../../../components/GroupManager/components/Overview/GroupsOverview/GroupsOverview';

const styles = {
  '@global': {
    'div[class*="groups-overview-"] .group .image.icon-devices .label-quantity-device':
      {
        'background-color': '#d94d15 !important',
      },
  },
};

export default withStyles(styles)(GroupsOverview);
