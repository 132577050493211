import { useMemo } from 'react';
import { DEFAULT_EVENT_ON } from '../../../model/controlUnit/util/constants';
import { getMainSensor } from '../../../model/controlUnit/util/sensor/getMainSensor';
import { getTypeFromMainSenor } from '../../../model/controlUnit/util/sensor/getTypeFromMainSenor';
import { DigitalChannelEventMixed } from '../../../model/monitoring/monitoring.model';
import { filterAlarmsFromEvents } from '../../../model/monitoring/util/filterAlarmsFromEvents';
import { getDigitalEventValue } from '../../../model/monitoring/util/mapDigitalEvent';
import { useCUTranslate } from '../../../util/CUTranslation/hook/useCUTranslate';
import { useAppSelector } from '../../store.model';
import { getActionExtendedSelectorById } from '../selectors/actions';
import { getUsedSetPointSelectorById } from '../selectors/setPoint';
import { useControlUnitById } from './useControlUnitById';
import { useCurrentEventsControlUnitById } from './useCurrentEventsControlUnitById';
import { useCurrentSensorDataControlUnitsByDeviceId } from './useCurrentSensorDataControlUnitsByDeviceId';

/**
 * Get and fetch all the data needed from specific control unit, but loading all the controlUnits from devices related
 * @param deviceId
 * @param controlUnitId
 * @param onError
 * @returns {
    loading,
    currentEvents,
    currentAlarms,
    currentSensorData,
    hasAlarms,
    onEvent,
    mainSensor,
    type,
    CUTranslate,
  }
 */
export function useCurrentStateByControlUnitId(
  deviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown
) {
  const [currentEvents, loadingCurrentEvents] = useCurrentEventsControlUnitById(
    deviceId,
    controlUnitId,
    onError
  );
  const [controlUnit, loadingControlUnits] = useControlUnitById(
    deviceId,
    controlUnitId,
    onError
  );

  const [currentSensorDataControlUnits, loadingCurrentSensorData] =
    useCurrentSensorDataControlUnitsByDeviceId(deviceId, onError);
  const currentSensorData = currentSensorDataControlUnits[controlUnitId];
  const filterAlarms = (event: DigitalChannelEventMixed) =>
    filterAlarmsFromEvents(event.metadata, event);

  const [CUTranslateDevice, loadingCUTranslate] = useCUTranslate(deviceId);

  const loading = !!(
    loadingControlUnits ||
    loadingCurrentEvents ||
    loadingCurrentSensorData ||
    loadingCUTranslate
  );

  // Alarms
  const currentAlarms = useMemo(() => {
    return Object.values(
      (currentEvents as Record<string, DigitalChannelEventMixed>) ?? {}
    ).filter(filterAlarms);
  }, [currentEvents]);

  // Sensor
  const mainSensor = getMainSensor(
    currentSensorDataControlUnits[controlUnitId]
  );

  const hasAlarms = currentAlarms.length;
  const onEvent = currentEvents?.[DEFAULT_EVENT_ON];

  const isOn =
    onEvent && getDigitalEventValue(onEvent.value, onEvent?.metadata);
  const type = getTypeFromMainSenor(loading, hasAlarms, isOn);

  // SetPoints
  const usedSetPoint = useAppSelector((state) =>
    getUsedSetPointSelectorById(state, { deviceId, controlUnitId })
  );

  // Actions
  const actions = useAppSelector((state) =>
    getActionExtendedSelectorById(state, { deviceId, controlUnitId })
  );

  const CUTranslate = (keyTranslation: string, ...otherArgs: any[]) =>
    CUTranslateDevice(keyTranslation, controlUnitId, ...otherArgs);

  return {
    loading,
    controlUnit,
    currentEvents,
    currentAlarms,
    currentSensorData,
    usedSetPoint,
    hasAlarms,
    actions,
    isOn,
    onEvent,
    mainSensor,
    type,
    CUTranslate,
  };
}
