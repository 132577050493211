import { GlobalStyles, Theme } from '@mui/material';
import React from 'react';
import ThemeProviderWithThemeDark from '../../theme/ThemeProviderWithThemeDark';
import FlexeserveLayoutGrid from './components/FlexeserveLayoutGrid';

export default function FlexeserveUI() {
  return (
    <ThemeProviderWithThemeDark>
      <GlobalStyles
        styles={(theme: Theme) => ({
          '#container': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      />
      <FlexeserveLayoutGrid />
    </ThemeProviderWithThemeDark>
  );
}
