import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import { componentsOverrides } from '../../../theme/ThemeProviderWithTheme';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2185d0',
    },
    secondary: {
      // Nav color
      main: '#5d5d5c',
    },
  },
  components: componentsOverrides,
});

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
