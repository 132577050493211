import { Container, Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { getDeviceSelected } from '../../../../../../redux/devices/selectors';
import { warmUpGroups } from '../../../../../../redux/groups/actions/thunks';
import { getGroups } from '../../../../../../redux/groups/selectors';
import { RootState } from '../../../../../../redux/store.model';
import BreadcrumbNew from '../../../../../commons/breadcrumbNew';
import { useDevice } from '../../hooks/useDevice';
import ConnectedDeviceCard from './components/DeviceCard';
import DeviceRoutes from './DeviceRoutes';
import { getDeviceSectionsNavigationSelector } from './navigation';

export function DeviceLayoutSimple(props: DeviceLayoutProps) {
  /** Init Groups */
  const {
    sectionsNavigation,
    warmUpGroups,
    device,
    match: { params },
    location: { pathname },
  } = props;
  useDevice(device, warmUpGroups);
  const moduleSelected = useMemo(
    () => pathname.split('device-detail/')[1] ?? '',
    [pathname]
  );

  return (
    <React.Fragment>
      <BreadcrumbNew sections={sectionsNavigation} />
      <Container maxWidth="lg">
        <Paper variant="outlined" sx={{ paddingTop: 2 }}>
          <ConnectedDeviceCard
            device={device}
            groupId={params.groupId}
            moduleSelected={moduleSelected}
          />
          <DeviceRoutes />
        </Paper>
      </Container>
    </React.Fragment>
  );
}
export interface QueryParams {
  groupId?: string;
  deviceId: string;
}

export type NavQueryParams = RouteComponentProps<QueryParams> &
  HandlingErrorWrappedProps &
  PolyglotComponentProps;

interface DeviceLayoutProps
  extends RouteComponentProps<QueryParams>,
    HandlingErrorWrappedProps,
    PolyglotComponentProps,
    ConnectedComponentProps {}

const mapStateToProps = (state: RootState, props: NavQueryParams) => ({
  groups: getGroups(state),
  device: getDeviceSelected(state, props),
  sectionsNavigation: getDeviceSectionsNavigationSelector(state, props),
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedDeviceLayout = withHandlingErrors(
  withPolyglot(connector(DeviceLayoutSimple))
);
export default ConnectedDeviceLayout;
