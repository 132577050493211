import GroupIconDefault from '../../assets/GroupIconDefaultLandscape.svg';
import * as Constants from '../Constants';
import { Device, DEVICE_STATUS_TYPE } from '../device/device.model';
import DeviceModel from '../device/Model';
import {
  GroupAPIResponse,
  Group,
  GroupAPIResponseWhitChild,
} from './group.model';

export const mappingStatusGroups: Record<DEVICE_STATUS_TYPE, number> = {
  [DEVICE_STATUS_TYPE.ALERT]: 1,
  [DEVICE_STATUS_TYPE.ALERT_INPROGRESS]: 2,
  [DEVICE_STATUS_TYPE.OK_INPROGRESS]: 3,
  [DEVICE_STATUS_TYPE.INPROGRESS]: 3,
  [DEVICE_STATUS_TYPE.OK]: 4,
  [DEVICE_STATUS_TYPE.NEW]: 4,
};

const cssGroupsStatus: Record<DEVICE_STATUS_TYPE, string | undefined> = {
  [Constants.STATUS_OK]: 'ok',
  [Constants.STATUS_ALERT]: 'alert',
  [Constants.STATUS_OK_INPROGRESS]: 'ok_inprogress',
  [Constants.STATUS_ALERT_INPROGRESS]: 'alert_inprogress',
  [Constants.STATUS_NEW]: 'new',
  [Constants.STATUS_INPROGRESS]: 'inprogress',
};
interface PathToGroup {
  id: string;
  name: string;
}

class GroupModel {
  deviceModel: DeviceModel;
  STATUS_INPROGRESS: string;
  STATUS_NEW: string;
  constructor() {
    this.deviceModel = new DeviceModel();
    this.STATUS_INPROGRESS = Constants.STATUS_INPROGRESS;
    this.STATUS_NEW = Constants.STATUS_NEW;
  }

  /**
   * Sort object keys
   * @param object obj
   * @return object sort object
   */
  static sortObjectKeys(obj: { [x: string]: any }) {
    return Object.keys(obj)
      .sort()
      .reduce<any>((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  }

  static getGroupIcon(iconURL?: string | null) {
    if (!iconURL) {
      return GroupIconDefault;
    } else {
      return iconURL;
    }
  }

  static getClassesNamesFromDevice = (group?: Group) =>
    `group ${cssGroupsStatus[group?.status ?? Constants.STATUS_OK]}`;

  /**
   * Get main parent group ID
   * This function returns the group ID from the main parent
   * if the passed group ID is located in the first level then the main parent is null
   * @param string groupId
   * @param array groups
   * @return string mainParentGroupId
   */
  static getMainParentGroupId(groupId: string, groups: Group[]) {
    let mainParentGroupId;
    for (let i = 0; i < groups.length; i += 1) {
      /* if the groupId is found in the first level, then the parent Id is null */
      if (groups[i].id === groupId) {
        mainParentGroupId = null;
        i = groups.length + 1;
      } else {
        mainParentGroupId = groups[i].id;
        const group = GroupModel.getGroupByGroupId(groupId, [groups[i]]);
        if (group !== undefined) {
          i = groups.length + 1;
        }
      }
    }
    return mainParentGroupId;
  }

  /**
   * Get path to group
   * This function returns an array with the route to the passed group
   * @param object group
   * @param array groups
   * @return array route = [{ id, name}]
   *
   */
  static getPathToGroup(
    group: Group | { id: string } | undefined,
    groups: Group[]
  ) {
    const route: PathToGroup[] = [];
    if (!group) return [];
    route.push();

    return [
      ...GroupModel.getPathToGroupRecursive(group, groups).map(
        (currentGroup) => ({
          id: currentGroup.id,
          name: currentGroup.name,
        })
      ),
    ];
  }

  static getPathToGroupRecursive(
    group: Group | { id: string },
    groups: Group[],
    currentPath: Group[] = []
  ): Group[] {
    const groupInGroups = groups.find(
      (currentGroup) => currentGroup.id === group.id
    );
    if (groupInGroups) return [...currentPath, groupInGroups];

    const groupInGroupsChildren = groups
      .map((currentGroup) =>
        GroupModel.getPathToGroupRecursive(group, currentGroup.childGroups, [
          ...currentPath,
          currentGroup,
        ])
      )
      .find((result) => result.length > 0);
    if (groupInGroupsChildren) return groupInGroupsChildren;

    return [];
  }

  // ^ Functionality inherit

  /**
   * Get users ids from current and nested groups
   */
  static getAllUsersIdFromGroups(
    groups: Group[],
    individualGroup?: Group | undefined
  ): string[] {
    let usersIds: string[] = [];

    // add ids from individualGroup
    if (individualGroup) {
      usersIds = [...usersIds, ...individualGroup.users];
    }

    // add ids from nested groups
    usersIds = [...usersIds, ...groups.flatMap((group) => group.users)];

    // add ids from child groups
    const usersChild = groups.map((group) =>
      GroupModel.getAllUsersIdFromGroups(group.childGroups)
    );
    usersIds = [...usersIds, ...usersChild.flatMap((id) => id)];

    // remove duplicates
    usersIds = [...new Set(usersIds)];

    return usersIds;
  }

  /**
   * Get devices ids from current and nested groups
   */
  static getAllDevicesIdFromGroups(
    groups: Group[],
    individualGroup?: Group | undefined
  ): string[] {
    let devicesIds: string[] = [];

    // add ids from individualGroup
    if (individualGroup) {
      devicesIds = [...devicesIds, ...individualGroup.devices];
    }

    // add ids from nested groups
    devicesIds = [...devicesIds, ...groups.flatMap((group) => group.devices)];

    // add ids from child groups
    const devicesChild = groups.map((group) =>
      GroupModel.getAllDevicesIdFromGroups(group.childGroups)
    );
    devicesIds = [...devicesIds, ...devicesChild.flatMap((id) => id)];

    // remove duplicates
    devicesIds = [...new Set(devicesIds)];

    return devicesIds;
  }

  static getGroupsIdByDeviceId(
    deviceId: string | undefined,
    groups: Group[],
    individualGroup?: Group | undefined
  ): string[] {
    return GroupModel.getGroupsByDeviceId(
      deviceId,
      groups,
      individualGroup
    ).map((group) => group.id);
  }
  /**
   * Get groups ids by device id
   * this function returns all the groupsId where a device are located
   */
  static getGroupsByDeviceId(
    deviceId: string | undefined,
    groups: Group[],
    individualGroup?: Group | undefined
  ): Group[] {
    return GroupModel.getGroupsByPredicate(
      (group) =>
        group?.devices.find((currentDeviceId) => currentDeviceId === deviceId),
      groups,
      individualGroup
    );
  }

  /**
   * Get group that has a childGroup with certain ID
   */
  static getGroupHasChildWhitGroupId(
    groupId: string | undefined,
    groups: Group[],
    individualGroup?: Group | undefined
  ): Group | undefined {
    return GroupModel.getGroupByPredicate(
      (group) => group?.childGroups.find((group) => group.id === groupId),
      groups,
      individualGroup
    );
  }

  /**
   * Get all devices ids user
   * this function get all devices including child groups devices and user devices
   * @param array groups
   * @param array associatedDevices
   * @param array devicesIds
   */
  static getAllDevicesIdsUser(groups: Group[], associatedDevices: Device[]) {
    let devicesIds: string[] = [];
    associatedDevices.map((device: { uuid: any }) =>
      devicesIds.push(device.uuid)
    );
    devicesIds = [
      ...devicesIds,
      ...GroupModel.getAllDevicesIdFromGroups(groups),
    ];
    devicesIds = [...new Set(devicesIds)];
    return devicesIds;
  }
  /**
   * Get group by group ID
   * recursive function to find a groupId and get the child structure of them
   * @param string groupId
   * @param array groups
   * @return object group
   */
  static getGroupByGroupId(
    groupId: string | undefined,
    groups: Group[],
    individualGroup?: Group | undefined
  ): Group | undefined {
    return GroupModel.getGroupByPredicate(
      (group) => group?.id === groupId,
      groups,
      individualGroup
    );
  }

  /**
   * Get group by predicate, using invert delegation pattern
   * search in nested groups of the @param groups
   * search in the child
   * use recursive function to find a groupId and get the child structure of them
   * @param string groupId
   * @param array groups
   * @return object group
   */
  static getGroupsByPredicate(
    predicate: (group: Group | undefined) => any,
    groupsArg: Group[],
    individualGroup?: Group | undefined
  ): Group[] {
    // search in nested groups
    let groups = groupsArg.filter(predicate);

    // search in child items recursively
    const groupsOfChild = groupsArg
      .map((group) =>
        GroupModel.getGroupsByPredicate(predicate, group.childGroups)
      )
      .flatMap((group) => group);
    groups = [...groups, ...groupsOfChild];

    // search in individual group
    if (predicate(individualGroup))
      groups = [...groups, individualGroup as Group];

    // not found
    return groups;
  }

  /**
   * Get group by predicate, using invert delegation pattern
   * search in nested groups of the @param groups
   * search in the child
   * use recursive function to find a groupId and get the child structure of them
   * @param string groupId
   * @param array groups
   * @return object group
   */
  static getGroupByPredicate(
    predicate: (group: Group | undefined) => any,
    groups: Group[],
    individualGroup?: Group | undefined
  ): Group | undefined {
    // search in nested groups
    const group = groups.find(predicate);
    if (group) return group;

    // search in child items recursively
    const groupOfChild = groups
      .map((group) =>
        GroupModel.getGroupByPredicate(predicate, group.childGroups)
      )
      .find((group) => !!group);
    if (groupOfChild) return groupOfChild;

    // search in individual group
    if (predicate(individualGroup)) return individualGroup;

    // not found
    return undefined;
  }

  //
  // PARSERS
  //

  /**
   * Mapping status groups
   * this funcion set the stateValues for a groups according the field status
   * this values is used to sort by status
   * @param array groups
   * @return array groups
   */
  parseStatusGroup(groupStatus: GroupAPIResponse['status']) {
    // FIXME: incidents status need rework with incidents/device/public API

    // const statusIncidents = IncidentModel.hasIncidentInProgress(group.incidents);
    const statusIncidents = '';
    const status: keyof typeof mappingStatusGroups = statusIncidents
      ? `${groupStatus}_${statusIncidents}`
      : groupStatus;
    return mappingStatusGroups[status];
  }
  /**
   * Parse attributes: recursive function
   * parse attributes.contacts and add this array to the group.attributes
   * @param array groups
   */
  parseAttributes(
    currentAttributes: GroupAPIResponse['attributes']
  ): Group['attributes'] {
    let attributes = { contacts: [] };
    if (currentAttributes !== null && currentAttributes !== undefined) {
      attributes = {
        ...GroupModel.sortObjectKeys(currentAttributes),
        ...attributes,
      };
      // Parsing contacts
      if (currentAttributes.contacts) {
        attributes.contacts = JSON.parse(currentAttributes.contacts);
        if (!Array.isArray(attributes.contacts)) {
          attributes.contacts = [];
        }
      }
    }

    return attributes;
  }
  /**
   * apply transformation on group
   * parse devices
   * parse users
   * parse attributes
   */
  parseGroup(group: GroupAPIResponseWhitChild) {
    return {
      ...group,
      attributes: this.parseAttributes(group.attributes),
      devices: Array.isArray(group.devices) ? group.devices : [],
      users: Array.isArray(group.users) ? group.users : [],
      statusValue: this.parseStatusGroup(group.status),
    };
  }

  parseGroups(groups: GroupAPIResponseWhitChild[]): Group[] {
    return groups.map((group) => ({
      ...this.parseGroup(group),
      childGroups: this.parseGroups(group.childGroups),
    }));
  }

  /**
   * Put a empty array if not have childGroups
   * @param groups
   * @returns
   */
  parseChildGroups(groups: GroupAPIResponse[]): GroupAPIResponseWhitChild[] {
    return groups.map((group) => {
      const childGroups =
        group.childGroups !== null && group.childGroups !== undefined
          ? this.parseChildGroups(group.childGroups)
          : [];

      return { ...group, childGroups };
    });
  }
  /**
   * Transform the array GroupAPIResponse to Group
   */
  parseFromAPI(groups: GroupAPIResponse[]): Group[] {
    const groupWhitChildParsed = this.parseChildGroups(groups);
    return this.parseGroups(groupWhitChildParsed);
  }
}

export default GroupModel;
