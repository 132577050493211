import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import store from '../../../../redux/Store';
import BrowserUtil from '../../../../util/BrowserUtil';
import ConnectContactUs from '../../../../components/contactUs';
import ConnectFleetManagerInfo from '../../../../components/LandingPage/fleetManagerInfo';
import ConnectRecipesInfo from '../../../../components/LandingPage/recipesInfo';
import { withPolyglot } from '../../../../i18n';

import imageLandingPageDesktop from '../../assets/ofc-serenity-eu__BackgroundOCF_Desktop.jpg';
// import imageLandingPage1Desktop from '../../assets/ofc-serenity-eu__OCF1_Desktop.jpg';
// import imageLandingPage2Desktop from '../../assets/ofc-serenity-eu__OCF2_Desktop.jpg';

import imageLandingPagePhone from '../../assets/ofc-serenity-eu__BackgroundOCF_Phone.jpg';

// import imageLandingPage1Phone from '../../assets/ofc-serenity-eu__OCF1_Phone.jpg';
// import imageLandingPage2Phone from '../../assets/ofc-serenity-eu__OCF2_Phone.jpg';

import imageLandingPagePhoneLandscape from '../../assets/ofc-serenity-eu__BackgroundOCF_PhoneLandscape.jpg';
// import imageLandingPage1PhoneLandscape from '../../assets/ofc-serenity-eu__OCF1_PhoneLandscape.jpg';
// import imageLandingPage2PhoneLandscape from '../../assets/ofc-serenity-eu__OCF2_PhoneLandscape.jpg';

import imageLandingPageTablet from '../../assets/ofc-serenity-eu__BackgroundOCF_Tablet.jpg';
// import imageLandingPage1Tablet from '../../assets/ofc-serenity-eu__OCF1_Tablet.jpg';
// import imageLandingPage2Tablet from '../../assets/ofc-serenity-eu__OCF2_Tablet.jpg';

import imageLandingPageTabletLandscape from '../../assets/ofc-serenity-eu__BackgroundOCF_TabletLandscape.jpg';
// import imageLandingPage1TabletLandscape from '../../assets/ofc-serenity-eu__OCF1_TabletLandscape.jpg';
// import imageLandingPage2TabletLandscape from '../../assets/ofc-serenity-eu__OCF2_TabletLandscape.jpg';

const browserUtil = new BrowserUtil();
let isPhone = browserUtil.getIsPhone();
let isTablet = browserUtil.getIsTablet();
// const isMobile = browserUtil.getIsMobile();

export class LandingPage extends Component {
  /**
   * Columns grid boxes isMobile
   * in mobile devices tablet and phones by the landscape orientation the boxes should be
   * showed in the same row. by the portrait rotation the boxes will be showed vertically
   */
  static columnsGridBoxesMobile() {
    return browserUtil.getOrientation() === browserUtil.getLandscape() ? 5 : 9;
  }

  constructor(props) {
    super(props);
    this.orientationChange = this.orientationChange.bind(this);
    this.openContactUs = this.openContactUs.bind(this);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.openFleetManagerInfo = this.openFleetManagerInfo.bind(this);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.openRecipesInfo = this.openRecipesInfo.bind(this);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.selectPicturesGalery = this.selectPicturesGalery.bind(this);
    this.resizeBackgroundImages = this.resizeBackgroundImages.bind(this);
    this.state = {
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
      showContactUs: false,
      showFleetManagerInfo: false,
      showRecipesInfo: false,
    };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.orientationChange);
    this.resizeBackgroundImages();
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.orientationChange);
  }

  /**
   * Resize background images
   * this funcion will be called by device monitor and layout relation (width:hight)
   * bigger as 16:9. in this case we should push the margin to fill out the complete
   * background
   */
  resizeBackgroundImages() {
    if (this.allowNegativeMargin === true) {
      /*
       * rationScreenSize = width - (heigt * 100) / defaultRationScreenSizeDesktop
       * defaultRationScreenSizeDesktop = 16:9 = 56
       */
      // const ratioScreenSize = Math.round(
      //   (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
      //   0
      // );
      // if (ratioScreenSize > browserUtil.defaultRationScreenSizeDesktop) {
      //   let addMargin =
      //     browserUtil.getOuterWidth() -
      //     (browserUtil.getOuterHeight() * 100) /
      //       browserUtil.defaultRationScreenSizeDesktop;
      //   addMargin = Math.round(addMargin / 2, 0);
      //   const updateMargins = (element) => {
      //     const item = element;
      //     item.style.marginLeft = `${addMargin}px`;
      //     item.style.marginRight = `${addMargin}px`;
      //   };
      //   [...document.getElementsByClassName('background-images')].forEach(
      //     updateMargins
      //   );
      //   [...document.getElementsByClassName('image-gallery')].forEach(
      //     updateMargins
      //   );
      // }
    }
  }

  selectPicturesGalery() {
    /* Ratio screen size: this variable has the relation between the width and the
     * height of the screen. Phone and monitor (16:9 = 56.25), Tablet (3:4 = 75)
     */
    let ratioScreenSize = 0;
    if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
    } else {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterWidth() * 100) / browserUtil.getOuterHeight(),
        0
      );
    }

    this.allowNegativeMargin = false;

    if (
      isTablet &&
      ratioScreenSize < browserUtil.defaultRationScreenSizeTablet
    ) {
      isPhone = true;
      isTablet = false;
    }

    let imagesGalleryLandingPage = [];
    if (isPhone) {
      this.allowNegativeMargin = false;
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imagesGalleryLandingPage = [
          { original: imageLandingPagePhoneLandscape },
        ];
      } else {
        imagesGalleryLandingPage = [{ original: imageLandingPagePhone }];
      }
    } else if (isTablet) {
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imagesGalleryLandingPage = [
          { original: imageLandingPageTabletLandscape },
        ];
      } else {
        imagesGalleryLandingPage = [{ original: imageLandingPageTablet }];
      }
    } else {
      imagesGalleryLandingPage = [{ original: imageLandingPageDesktop }];

      this.allowNegativeMargin = true;
    }
    return imagesGalleryLandingPage;
  }

  /**
   * Orientation change
   * this function will be called by a orientation change between landscape and Portrait
   * in mobile devices
   */
  orientationChange() {
    this.setState({
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
    });
  }

  openContactUs() {
    this.setState({
      showContactUs: true,
    });
  }

  closeContactUs() {
    this.setState({ showContactUs: false });
  }

  openFleetManagerInfo() {
    const { loggedIn, history } = this.props;
    if (!loggedIn) {
      this.setState({ showFleetManagerInfo: true });
    } else {
      history.push('/devicemanager');
    }
  }

  closeFleetManagerInfo() {
    this.setState({ showFleetManagerInfo: false });
  }

  openRecipesInfo() {
    this.setState({ showRecipesInfo: true });
  }

  closeRecipesInfo() {
    this.setState({ showRecipesInfo: false });
  }

  render() {
    const { polyglot } = this.props;
    const {
      showContactUs,
      showFleetManagerInfo,
      showRecipesInfo,
      imagesGalleryLandingPage,
      columnsGridBoxesMobile,
    } = this.state;
    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <Provider store={store}>
        <div className="landing-page">
          <ConnectContactUs
            polyglot={polyglot}
            showContactUs={showContactUs}
            closeContactUs={this.closeContactUs}
          />
          <ConnectFleetManagerInfo
            polyglot={polyglot}
            showFleetManagerInfo={showFleetManagerInfo}
            closeFleetManagerInfo={this.closeFleetManagerInfo}
          />
          <ConnectRecipesInfo
            polyglot={polyglot}
            showRecipesInfo={showRecipesInfo}
            closeRecipesInfo={this.closeRecipesInfo}
          />

          <div className="container padded">
            <div className="background-images">
              <ImageGallery
                items={imagesGalleryLandingPage}
                showThumbnails={false}
                showBullets={false}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay
                slideDuration={4000}
                Id="imageGallery"
              />
            </div>
            <div className="information">
              <Grid centered columns={16} padded>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={columnsGridBoxesMobile}
                  mobile={columnsGridBoxesMobile}
                >
                  <div
                    className="fleet-manager"
                    onClick={this.openFleetManagerInfo}
                    onKeyPress={this.openFleetManagerInfo}
                    tabIndex="0"
                    role="link"
                  >
                    <Icon name="configure" size={isPhone ? 'large' : 'huge'} />
                    <p>{polyglot.t('landing_page.fleet_manager')}</p>
                  </div>
                </Grid.Column>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={columnsGridBoxesMobile}
                  mobile={columnsGridBoxesMobile}
                >
                  <div
                    className="contact"
                    onClick={this.openContactUs}
                    onKeyPress={this.openContactUs}
                    tabIndex="0"
                    role="link"
                  >
                    <Icon
                      name="info circle"
                      size={isPhone ? 'large' : 'huge'}
                      className="icon"
                    />
                    <p>{polyglot.t('landing_page.contact_us')}</p>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </Provider>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withPolyglot(LandingPage)));
