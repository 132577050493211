import withStyles from 'react-jss';
import TopBarMenu from '../../../../../components/commons/topBarMenu/TopBarMenu';

const styles = {
  '@global': {
    '.top-bar-menu .icon.button': {
      color: '#d94d15',
      'background-color': '#f5f5f6',
    },
    '.top-bar-menu .icon.button:hover': {
      color: '#c13e0e',
      'background-color': '#e1e2e1',
    },
    '.top-bar-menu .icon.button.selected': {
      color: '#c13e0e',
      'background-color': '#e1e2e1',
    },
  },
};

export default withStyles(styles)(TopBarMenu);
