import { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import {
  getControlUnitsSensorDataErrorById,
  getControlUnitsSensorDataLoadingById,
  getControlUnitsSensorDataById,
  getSensorDataMetadataFromControlUnitState,
} from '../selectors';
import { fetchCurrentSensorDataControlUnits } from '../actions/thunks';
import produce from 'immer';
import { ControlUnitAPIResponse } from '../../../model/controlUnit/ControlUnit.model';
import {
  SensordataNewest,
  SensordataNewestMixed,
} from '../../../model/monitoring/monitoring.model';
import { forEachLevel2, RecordLVL2 } from '../../../util/types/Records';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [currentAlarms, loading, error]
 * 
 * @usage
´´´ts
const [currentAlarms, loading, error] = useCurrentSensorDataControlUnitsByDeviceId("UUUID_DEVICE")
´´´
 */
export function useCurrentSensorDataControlUnitsByDeviceId(
  deviceId: string,
  onError: (error: unknown) => unknown
) {
  const currentSensorDataControlUnits = useAppSelector((state: RootState) =>
    getControlUnitsSensorDataById(state, { deviceId })
  );
  const controlUnitState = useAppSelector((state) => state.controlUnit);
  // find Metadata
  const mixedCurrentSensorData = useMemo(
    () =>
      produce<
        RecordLVL2<
          ControlUnitAPIResponse['id'],
          SensordataNewest['channel'],
          SensordataNewestMixed
        >
      >(currentSensorDataControlUnits ?? {}, (draftCurrentSensorData) => {
        forEachLevel2(
          draftCurrentSensorData ?? {},
          (currentValue: SensordataNewestMixed, controlUnitId, eventId) => {
            currentValue.metadata = getSensorDataMetadataFromControlUnitState(
              controlUnitState.dictionaryControlUnit,
              deviceId,
              controlUnitId,
              eventId
            );
          }
        );
      }),
    [
      controlUnitState.dictionaryControlUnit,
      currentSensorDataControlUnits,
      deviceId,
    ]
  );

  const loading = !!useAppSelector((state: RootState) =>
    getControlUnitsSensorDataLoadingById(state, { deviceId })
  );
  const error = useAppSelector((state: RootState) =>
    getControlUnitsSensorDataErrorById(state, { deviceId })
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!currentSensorDataControlUnits && !loading) {
      dispatch(fetchCurrentSensorDataControlUnits(deviceId))
        .unwrap()
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSensorDataControlUnits, deviceId, dispatch]);
  return [mixedCurrentSensorData ?? {}, loading, error] as const;
}
