import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';

import { withSnackbar } from 'stoerk-ui-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { resetPassword } from '../../redux/auth/actions';
import ValidationUtil from '../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import {
  Paper,
  Stack,
  TextField,
  Container,
  Button,
  Box,
  Typography,
} from '@mui/material';
import ErrorText from '../commons/ErrorText';
import { OpenSnackbarProps } from '../../handlingErrors';

interface ForgotPasswordPageProps
  extends PolyglotComponentProps,
    OpenSnackbarProps,
    ConnectedComponentProps,
    RouteComponentProps {}

interface ForgotPasswordPageState {
  animation: any;
  duration: number;
  visible: boolean;
  emailValid: null | boolean;
  email: string;
}
export class ForgotPasswordPage extends Component<
  ForgotPasswordPageProps,
  ForgotPasswordPageState
> {
  validationUtil: ValidationUtil;
  constructor(props: ForgotPasswordPageProps) {
    super(props);
    const { polyglot } = props;
    this.activeTransition = this.activeTransition.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.submit = this.submit.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);
    this.state = {
      animation: null,
      duration: 500,
      visible: true,
      emailValid: null,
      email: '',
    };
  }

  componentDidMount() {
    this.onCompleteTransition();
  }

  onCompleteTransition() {
    const { visible } = this.state;
    if (visible) {
      this.setState({ visible: false, animation: null });
    }
  }

  setEmail(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const email = event.target.value;
    const confirmed = this.validationUtil.validateEmailAddress(email, true);
    this.setState({ email, emailValid: confirmed });
  }

  async submit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { emailValid, email } = this.state;
    const { history, openSnackbar } = this.props;
    try {
      event.preventDefault();
      if (!emailValid) {
        this.activeTransition();
        return;
      }
      await this.props.resetPassword(email);
      history.replace('/resettriggered');
    } catch (error) {
      const errorDescription =
        typeof error === 'object' ? error.message : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  render() {
    const { animation, duration, visible, email, emailValid } = this.state;

    const { polyglot } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Transition
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <Paper>
            <Typography component="h1" variant="h4" textAlign="center">
              {polyglot.t('login.forgot_password_link_title')}
            </Typography>
            <Box
              sx={{
                width: '100%', // Fix IE 11 issue.
                marginTop: (theme) => theme.spacing(3),
                padding: (theme) => theme.spacing(2),
              }}
            >
              <form>
                <Stack spacing={2}>
                  <TextField
                    label={polyglot.t('login.email')}
                    type="email"
                    onChange={this.setEmail}
                    required
                    error={Boolean(email && !emailValid)}
                    helperText={
                      email &&
                      !emailValid && (
                        <ErrorText>
                          {this.validationUtil.getErrorMessage('email')}
                        </ErrorText>
                      )
                    }
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={this.submit}
                  >
                    {polyglot.t('reset_password.button_title')}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Paper>
        </Transition>
      </Container>
    );
  }
}

const connector = connect(null, {
  resetPassword,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(null, {
  resetPassword,
})(withRouter(withSnackbar(withPolyglot(ForgotPasswordPage))));
