export const nameReducer = 'featureToggle';
/**
 * Example:
 * 
 * 
```json
{
  "StyledComponentsVariants": "alan-nuttall-flexeserve-eu",
  "CreateVoucher": true,
  "MODULE_MAINTENANCE_MESSAGE": true,
  "incident-manager": false,
  "map": true,
  "MODULE_RECIPES": true,
  "camera": false
}
```

 */
export interface FeatureToggleState {
  [nameToggle: string]: string | boolean | undefined;
  // know FeatureToggles
  StyledComponentsVariants?: string;
  CreateVoucher?: boolean | undefined;
  MODULE_MAINTENANCE_MESSAGE?: boolean | undefined;
  map?: boolean | undefined;
  MODULE_RECIPES?: boolean | undefined;
  camera?: boolean | undefined;
  'incident-manager'?: boolean;
  ControlUnitsOverview?: boolean;
  /** List of aplications splited by comma: devicemanager,flexeserve,flexeserve-admin*/
  ApplicationsList?: string;
  ApplicationDefault?: string;
}
