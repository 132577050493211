export const STATE_ALERT = 'ALERT';
export const STATE_ALERT_INPROGRESS = 'ALERT_INPROGRESS';
export const STATE_OK = 'OK';
export const STATE_OK_INPROGRESS = 'OK_INPROGRESS';
export const STATE_INPROGRESS = 'INPROGRESS';
export const CLOUD_CONNECTIVITY = true;
export const NO_CLOUD_CONNECTIVITY = false;

export const INCIDENT_STATUS_NEW = 'NEW';
export const INCIDENT_STATUS_INPROGRESS = 'INPROGRESS';
export const INCIDENT_STATUS_RESOLVED = 'RESOLVED';

export const INCIDENT_PRIORITY_LOW = 'LOW';
export const INCIDENT_PRIORITY_MEDIUM = 'MEDIUM';
export const INCIDENT_PRIORITY_HIGH = 'HIGH';
