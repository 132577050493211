import DeviceCalls from '../../../model/device/Calls';
import DeviceModel from '../../../model/device/Model';
import { AppDispatch } from '../../store.model';
import FETCH_EVENTS_BY_DEVICES_ID from './Types';

const fetchEventsByDevicesId =
  (devicesId: string[]) => async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();
    const devicesControlUnits = await deviceCalls.getControlUnitsByDevicesId(
      devicesId
    );

    dispatch({
      type: FETCH_EVENTS_BY_DEVICES_ID,
      payload: DeviceModel.getEvents(devicesControlUnits),
    });
  };

export default fetchEventsByDevicesId;
