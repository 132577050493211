import Calls from '../../../model/incidentManager/Calls';
import { IncidentConfigAPI } from '../../../model/incidentManager/incident.model';
import IncidentModel from '../../../model/incidentManager/Model';
import { AppDispatch } from '../../store.model';
import {
  FETCH_INCIDENT_CONFIGURATION_BY_GROUP_ID,
  PUT_INCIDENT_CONFIGURATION,
} from './Types';

/**
 * Fetch incident configuration by group id
 * @param string groupId
 */
export const fetchIncidentConfigurationByGroupId =
  (groupId: string) => async (dispatch: AppDispatch) => {
    const calls = new Calls();
    const incidentConfiguration = await calls.getIncidentConfigurationByGroupId(
      groupId
    );

    dispatch({
      type: FETCH_INCIDENT_CONFIGURATION_BY_GROUP_ID,
      payload: IncidentModel.parseIncidentConfiguration(
        incidentConfiguration,
        groupId
      ),
    });
  };

/**
 * Put incident configuration
 * @param string groupId
 * @param object incidentConfiguration
 */
export const putIncidentConfiguration =
  (groupId: string, incidentConfiguration: Partial<IncidentConfigAPI>) =>
  async (dispatch: AppDispatch) => {
    const calls = new Calls();

    const response = await calls.putIncidentConfiguration(
      groupId,
      incidentConfiguration
    );
    dispatch({
      type: PUT_INCIDENT_CONFIGURATION,
      payload: response,
    });
  };
