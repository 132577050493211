import { chunk } from 'lodash';
import IncidentsCalls from '../../../model/incidentManager/Calls';
import { IncidentUpdateAPIRequest } from '../../../model/incidentManager/incident.model';
import { AppDispatch } from '../../store.model';
import { actionsIncidents } from '../slice';
import {
  fetchIncidentsByDevicesIds,
  fetchPublicIncidentsByDevicesIds,
} from './asyncThunks';

/**
 * Fetch incidents by device id by status
 * @param string deviceId
 * @param string status
 */
export const fetchIncidentsByDeviceId =
  (deviceId: string) => async (dispatch: AppDispatch) => {
    await dispatch(fetchIncidentsByDevicesIds([deviceId]));
  };

/**
 * Add incident attachment
 * @param string incidentId
 * @param object attachment
 */
export const addIncidentAttachment =
  (incidentId: string, attachment: File) => async (dispatch: AppDispatch) => {
    const incidentsCalls = new IncidentsCalls();
    const attachmentResponse = await incidentsCalls.addIncidentAttachment(
      incidentId,
      attachment
    );
    dispatch(
      actionsIncidents.addAttachment({
        incidentId,
        attachment: attachmentResponse,
      })
    );
  };

/**
 * Get incident attachments
 * @param string incidentId
 */
export const getIncidentAttachments =
  (incidentId: string) => async (dispatch: AppDispatch) => {
    const incidentsCalls = new IncidentsCalls();
    const attachments = await incidentsCalls.getIncidentAttachments(incidentId);
    dispatch(actionsIncidents.assignAttachments({ incidentId, attachments }));
  };

/**
 * Get incident attachment content
 * @param string incidentId
 * @param string attachmentKey
 */
export const getIncidentAttachmentContent =
  (incidentId: string, attachmentKey: string) =>
  async (dispatch: AppDispatch) => {
    const incidentsCalls = new IncidentsCalls();
    /* attachment = { content, contentType} */
    const attachment = await incidentsCalls.getIncidentAttachmentContent(
      incidentId,
      attachmentKey
    );
    dispatch(actionsIncidents.assignAttachment(attachment));
  };

/**
 * Put group update incident
 * update a incident
 * @param string groupId
 * @param string deviceId
 * @param string incidentId
 * @param object incidentData
 */
export const putIncident =
  (incidentId: string, incidentData: IncidentUpdateAPIRequest) =>
  async (dispatch: AppDispatch) => {
    const incidentsCalls = new IncidentsCalls();
    await incidentsCalls.putIncident(incidentId, incidentData);
    dispatch(
      actionsIncidents.updateIncident({
        incidentId,
        incident: incidentData,
      })
    );
  };

/**
 * fetchPublicIncidentsByDevicesIdsInChunks split the calls per 50 deviceIds per call,
 * because the headers's calls would be too large if we use >100 deviceIds per call
 *
 * @param devicesIds
 * @returns chunks
 */
export const fetchPublicIncidentsByDevicesIdsInChunks =
  (deviceIds: string[]) => async (dispatch: AppDispatch) => {
    const chunksDeviceIds = chunk(deviceIds, 50);
    return Promise.all(
      chunksDeviceIds.map((chunkDeviceIds) =>
        dispatch(fetchPublicIncidentsByDevicesIds(chunkDeviceIds))
      )
    );
  };
