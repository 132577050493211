/**
 * Code calls
 */
export const checkUsers = 'auth/checkUsers';
export const accountActivation = 'auth/accountActivation';
export const resetPassword = 'auth/resetPassword';
export const registerClient = 'auth/registerClient';
export const updatePassword = 'auth/updatePassword';
export const passwordTokenValidation = 'auth/passwordTokenValidation';
export const login = 'auth/login';
export const getToken = 'auth/getToken';
