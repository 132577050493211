import store from '../Store';
import {
  getUnreadNotifications,
  activatedLoadingUnreadNotifications,
} from './actions';

/**
 * Load unread notifications periodically
 */
export const loadUnreadNotificationsPeriodically = async () => {
  setTimeout(async () => {
    if (store.getState().notifications.activatedLoadingUnreadNotifications) {
      try {
        await store.dispatch(getUnreadNotifications());
        loadUnreadNotificationsPeriodically();
      } catch (error) {
        console.error('Backend errors service notifications:', error);
      }
    }
  }, 120000);
};

/**
 * Initialize load unread notifications periodically
 */
export const initializeLoadUnreadNotificationsPeriodically = async () => {
  await store.dispatch(activatedLoadingUnreadNotifications(true));
  loadUnreadNotificationsPeriodically();
};
