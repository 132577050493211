import React from 'react';
import { Form, Dropdown, DropdownProps } from 'semantic-ui-react';
import { DialogContentText, DialogProps, Box } from '@mui/material';
import { withSnackbar } from 'stoerk-ui-components';
import { withPolyglot, PolyglotComponentProps } from '../../../../i18n';
import { useFormik } from 'formik';
import ValidationUtil from '../../../../util/ValidationUtil';
import withHandlingErrors, {
  OpenSnackbarProps,
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { STDialog } from '../../../commons/Modal';
import ErrorText from '../../../commons/ErrorText';
import { connect, ConnectedProps } from 'react-redux';
import { inviteUsers } from '../../../../redux/auth/actions';
import { GroupUserAssign } from '../GroupEdit/Users/GroupUserAssign';

interface InviteUserModalProps
  extends PolyglotComponentProps,
    OpenSnackbarProps,
    DialogProps,
    HandlingErrorWrappedProps,
    ConnectedComponentProps {
  onClose?: () => unknown;
  onSuccess?: (emails: string[]) => unknown;
  open: boolean;
  initialEmails?: string[];
  feedback?: string;
}

export function InviteUserModal(props: InviteUserModalProps) {
  const {
    polyglot,
    onSuccess,
    inviteUsers,
    openSnackbar,
    handlingErrorsApi,
    initialEmails = [],
    feedback,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore https://stackoverflow.com/questions/49358560/react-wrapper-react-does-not-recognize-the-staticcontext-prop-on-a-dom-elemen
    staticContext,
    ...modalProps
  } = props;
  const validationUtil = new ValidationUtil(polyglot);
  const formik = useFormik({
    initialValues: {
      emails: initialEmails,
      options: initialEmails.map((email) => ({
        key: email,
        value: email,
        text: email,
      })),
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      const mergedEmails = values.options
        .map((option) => option.value)
        .filter((valueOption) => values.emails.includes(valueOption));
      if (mergedEmails.length === 0) {
        errors.emails = polyglot.t('error.formular.empty_list');
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      const mergedEmails = values.options
        .map((option) => option.value)
        .filter((valueOption) => values.emails.includes(valueOption));

      try {
        await inviteUsers(mergedEmails);
        if (onSuccess) await onSuccess(mergedEmails);
      } catch (error) {
        handlingErrorsApi(error);
      }
    },
  });
  const handleAddItem = (event: unknown, data: DropdownProps) => {
    const value = data.value as string;
    if (validationUtil.validateEmailAddress(value, false)) {
      let { options = [] } = formik.values;
      options = [{ key: value, value, text: value }, ...options];
      formik.setValues({
        emails: [...new Set([...formik.values.emails, value])],
        options,
      });
    } else {
      const message = {
        text: validationUtil.getErrorMessage('email'),
        type: 'error',
      };
      openSnackbar(message);
    }
  };

  const handleOnChange = (event: unknown, data: DropdownProps) => {
    formik.setFieldValue('emails', data.value);
  };

  return (
    <STDialog
      onSave={() => formik.handleSubmit()}
      disableSave={formik.isSubmitting}
      title={polyglot.t('group.users.invite_user_title')}
      // SemanticDropDown popup
      sx={{
        '&& .MuiDialogContent-root,.MuiPaper-root': {
          overflowY: 'initial',
        },
      }}
      {...modalProps}
    >
      {feedback && (
        <DialogContentText sx={{ mb: 1 }}>{feedback}</DialogContentText>
      )}
      <DialogContentText>
        {polyglot.t('group.users.invite_user_info')}
      </DialogContentText>
      <Box>
        <Form.Field>
          <Dropdown
            placeholder={polyglot.t('group.users.email')}
            fluid
            multiple
            search
            selection
            allowAdditions
            options={formik.values.options}
            defaultValue={formik.values.emails}
            onAddItem={handleAddItem}
            onChange={handleOnChange}
            noResultsMessage={polyglot.t('group.users.enter_email_address')}
            additionLabel={`${polyglot.t(
              'group.users.enter_email_address'
            )} : `}
            onOpen={GroupUserAssign.onOpenDropdown}
            id="emails"
          />
          {formik.errors.emails && (
            <ErrorText sx={{ marginX: 2, marginY: 1 }}>
              {formik.errors.emails}
            </ErrorText>
          )}
        </Form.Field>
      </Box>
    </STDialog>
  );
}

const connector = connect(null, { inviteUsers });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withPolyglot(withSnackbar(withHandlingErrors(InviteUserModal)))
);
