import React from 'react';
import PropTypes from 'prop-types';
import { Container, Message, Image } from 'semantic-ui-react';
import logo from '../../assets/stoerkIcon.png';
import { withPolyglot } from '../../i18n';

export function PostResetTriggerPage(props) {
  const { polyglot } = props;
  return (
    <Container>
      <Message info>
        <Image src={logo} alt="Störk-Tronic logo" size="mini" floated="left" />
        <p className="lead">
          {polyglot.t('reset_password.post_reset_message')}
        </p>
      </Message>
    </Container>
  );
}

PostResetTriggerPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(PostResetTriggerPage);
