import {
  SET_LOGGED_IN,
  GET_USER_INFO,
  UPDATE_USER_INFO,
  PASSWORD_TOKEN_VALIDATION,
  UPDATE_PASSWORD,
  GET_AUTH_CODE,
  GET_ACCESS_TOKEN,
  GET_REFRESH_TOKEN,
  LOGOUT,
  FETCHING_TOKEN,
  FETCHING_RIGHTS_CYCLE,
  REFRESH_TOKEN,
  LOGIN,
  REGISTER_CLIENT,
  RESET_PASSWORD,
  ACCOUNT_ACTIVATION,
} from '../actions/Types';

const initialState = {
  authCode: '',
  accessToken: '',
  refreshToken: '',
  keepLogin: false,
  loggedIn: false,
  passwordTokenValid: false,
  fetchingToken: false,
  fetchingRightsCycle: false,
  avatarLocation: '',
  userName: '',
  userEmail: '',
  id: '',
};

export default function reducer(state = initialState, action) {
  let avatarLocation;
  let userName;
  let userEmail;
  let id;
  let keepLogin;
  let authCode;
  let accessToken;
  let refreshToken;
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
      };
    case GET_USER_INFO:
      ({ avatarLocation, userName, userEmail, id } = action.payload);
      return {
        ...state,
        avatarLocation,
        userName,
        userEmail,
        id,
      };
    case UPDATE_USER_INFO:
      ({ avatarLocation, userName } = action.payload);
      return {
        ...state,
        avatarLocation,
        userName,
      };
    case PASSWORD_TOKEN_VALIDATION:
      return {
        ...state,
        passwordTokenValid: action.payload,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
      };
    case GET_AUTH_CODE:
      ({ authCode, keepLogin } = action.payload);
      return {
        ...state,
        authCode: typeof authCode === 'undefined' ? '' : authCode,
        keepLogin,
      };
    case GET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case GET_REFRESH_TOKEN:
      ({ refreshToken, keepLogin } = action.payload);
      return {
        ...state,
        refreshToken: typeof refreshToken === 'undefined' ? '' : refreshToken,
        keepLogin,
      };
    case LOGIN:
      ({ keepLogin, authCode } = action.payload);
      return {
        ...state,
        authCode,
        keepLogin,
      };
    case LOGOUT:
      return {
        ...state,
        authCode: '',
        accessToken: '',
        refreshToken: '',
        avatarLocation: '',
        userName: '',
        userEmail: '',
        keepLogin: false,
      };
    case FETCHING_TOKEN:
      return {
        ...state,
        fetchingToken: action.payload,
      };
    case REFRESH_TOKEN:
      ({ accessToken, refreshToken } = action.payload);
      return {
        ...state,
        accessToken: typeof accessToken === 'undefined' ? '' : accessToken,
        refreshToken: typeof refreshToken === 'undefined' ? '' : refreshToken,
      };
    case FETCHING_RIGHTS_CYCLE:
      return {
        ...state,
        fetchingRightsCycle: action.payload,
      };
    case REGISTER_CLIENT:
      return {
        ...state,
      };
    case RESET_PASSWORD:
      return {
        ...state,
      };
    case ACCOUNT_ACTIVATION:
      return {
        ...state,
      };
    default:
      return state;
  }
}
