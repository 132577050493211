import {
  isMobile,
  isMobileOnly,
  isTablet,
  isSafari,
  isChrome,
  isFirefox,
  isIOS,
} from 'react-device-detect';

const landscape = 'LANDSCAPE';
const portrait = 'PORTRAIT';

/**
 * Brower util
 *
 * Help class to return the browser information
 * some information like isMobile, isTable is get by using the component
 * react-device-detect unfortunately this library can not identify for example
 * the new versions of the IE browser.
 */
class BrowserUtil {
  constructor() {
    /* Ratio screen size: this variable has the relation between the width and the
     * height of the screen. Phone and monitor (16:9 = 56.25), Tablet (3:4 = 75)
     */
    this.defaultRationScreenSizeDesktop = 56.25;
    this.defaultRationScreenSizePhone = 56.25;
    this.defaultRationScreenSizeTablet = 75;
    this.IEversion = 0; /* if the browser is IE then the version will be setted */
    this.setIsBrowserIE();
    this.setIsBrowserSafari();
    this.setIsBrowserChrome();
    this.setIsBrowserFirefox();
    this.setIsiOs();
    if (this.setIsiOs) {
      this.setiOsVersion();
    }
    this.setIsTablet();
    this.setIsPhone();
    this.setIsMobile();
    this.setIsiPad();
    this.setIsiPod();
    this.setIsiPhone();
    this.landscape = landscape;
    this.portrait = portrait;
    this.outerWidth = window.outerWidth;
    this.width = window.innerWidth;
  }

  /**
   * set is iOs
   */
  setIsiOs() {
    this.setIsiOs = isIOS;
  }

  /**
   * Get is iOs
   * @return boolean
   */
  getIsiOs() {
    return this.setIsiOs;
  }

  /**
   * set is browser Chrome
   */
  setIsBrowserChrome() {
    this.setIsBrowserChrome = isChrome;
  }

  /**
   * Get is browser Chrome
   * @return boolean
   */
  getIsBrowserChrome() {
    return this.setIsBrowserChrome;
  }

  /**
   * set is browser Firefox
   */
  setIsBrowserFirefox() {
    this.setIsBrowserFirefox = isFirefox;
  }

  /**
   * Get is browser Firefox
   * @return boolean
   */
  getIsBrowserFirefox() {
    return this.setIsBrowserFirefox;
  }

  /**
   * set is browser Safari
   */
  setIsBrowserSafari() {
    this.setIsBrowserSafari = isSafari;
  }

  /**
   * Get is browser Safari
   * @return boolean
   */
  getIsBrowserSafari() {
    return this.setIsBrowserSafari;
  }

  /**
   * Set is browser IE
   * the component react-device-detect provides the variable isIE, unfortunately
   * this has the wrong value for IE 11
   */
  setIsBrowserIE() {
    this.isBrowserIE = false;
    this.IEversion = 0;
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      this.IEversion = parseInt(
        ua.substring(msie + 5, ua.indexOf('.', msie)),
        10
      );
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      this.IEversion = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      this.IEversion = parseInt(
        ua.substring(edge + 5, ua.indexOf('.', edge)),
        10
      );
    }

    if (this.IEversion > 0) {
      this.isBrowserIE = true;
    }
  }

  /**
   * Get IE version
   * @return int IEversion
   */
  getIEVersion() {
    return this.IEversion;
  }

  /**
   * Set iOs Version
   * this function will be called if the device is iOs
   * the version will be save in an array with all positions
   * of the version => [0] => 10, [1] => 3, [2] => 3
   */
  setiOsVersion() {
    let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (!v) {
      v = navigator.appVersion.match(/Version\/(\d+).(\d+).(\d+)/);
    }
    if (v !== null && v !== undefined && v.length > 2) {
      const iOsversion = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || 0, 10),
      ];
      this.iOsversion = iOsversion.length > 0 ? iOsversion : 0;
    } else {
      this.iOsversion = 0;
    }
  }

  /**
   * Get iOs version
   * @return array iOsversion => for example: [0] => 9, [1] => 3, [2] => 5 : Version 9.3.5
   */
  getiOsVersion() {
    return this.iOsversion;
  }
  /**
   * Get is browser IE
   * @return boolean
   */
  getIsBrowserIE() {
    return this.isBrowserIE;
  }

  /**
   * Set is ipad
   */
  setIsiPad() {
    this.isiPad = /iPad/i.test(navigator.userAgent);
  }

  /**
   * Get is ipad
   * @return boolean
   */
  getIsiPad() {
    return this.isiPad;
  }

  /**
   * Set is ipod
   */
  setIsiPod() {
    this.isiPod = /iPod/i.test(navigator.userAgent);
  }

  /**
   * Get is ipod
   * @return boolean
   */
  getIsiPod() {
    return this.isiPod;
  }

  /**
   * Set is iphone
   */
  setIsiPhone() {
    this.isiPhone = /iPhone/i.test(navigator.userAgent);
  }

  /**
   * Get is iphone
   * @return boolean
   */
  getIsiPhone() {
    return this.isiPhone;
  }

  /**
   * Get is tablet
   * @return boolean
   */
  setIsTablet() {
    this.isTablet = isTablet;
  }

  /**
   * Get is tablet
   * @return boolean
   */
  getIsTablet() {
    return this.isTablet;
  }

  /**
   * Set is phone
   */
  setIsPhone() {
    this.isPhone = isMobileOnly;
  }

  /**
   * Get is phone
   * @return boolean
   */
  getIsPhone() {
    return this.isPhone;
  }

  /**
   * set is mobile
   */
  setIsMobile() {
    this.isMobile = isMobile;
  }

  /**
   * Get is mobile
   * @return boolean
   */
  getIsMobile() {
    return this.isMobile;
  }

  /**
   * set width
   */
  setWidth() {
    this.width = window.innerWidth;
  }

  /**
   * Get width
   * @return int
   */
  getWidth() {
    this.setWidth();
    return this.width;
  }

  /**
   * set height
   */
  setHeight() {
    if (this.isBrowserIE === false) {
      this.height = window.innerHeight;
    } else {
      this.height = window.navigator.outerHeight;
    }

    if (this.height === null || this.height === undefined) {
      this.height = window.innerHeight;
    }
  }

  /**
   * Get height
   * @return int
   */
  getHeight() {
    this.setHeight();
    return this.height;
  }

  /**
   * set outer width
   */
  setOuterWidth() {
    this.outerWidth = window.outerWidth;
  }

  /**
   * Get outer width
   * @return int
   */
  getOuterWidth() {
    this.setOuterWidth();

    return this.outerWidth;
  }

  /**
   * set outer height
   */
  setOuterHeight() {
    this.outerHeight = window.outerHeight;
  }

  /**
   * Get outher height
   * @return int
   */
  getOuterHeight() {
    this.setOuterHeight();

    return this.outerHeight;
  }

  /**
   * Get landscape
   * returns the variable landscape
   */
  getLandscape() {
    return this.landscape;
  }

  /**
   * Get Portrait
   * returns the variable portrait
   */
  getPortrait() {
    return this.portrait;
  }

  /**
   * Get orientation
   * returns the orientation of the device
   * window orientation 90, -90 = landscape, 0 = portrait
   * for testing in chrome (dekstop) the orientation is undefined, because of that
   * we use the width and height to calculate the landscape
   */
  getOrientation() {
    if (window.orientation === undefined) {
      if (window.screen.width > window.screen.height) {
        return this.landscape;
      }
      return this.portrait;
    }

    if (window.orientation === 90 || window.orientation === -90) {
      return this.landscape;
    }
    return this.portrait;
  }

  hasOrientationLandScape() {
    return this.getOrientation() === this.landscape;
  }

  hasOrientationPortrait() {
    return this.getOrientation() === this.portrait;
  }
}

export default BrowserUtil;
