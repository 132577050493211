import { Box } from '@mui/material';
import React from 'react';
import IncidentManager from '../../../../../../../incidentManager/IncidentManager';

export default function DeviceIncidentsPage() {
  return (
    <Box sx={{ padding: 2 }}>
      <IncidentManager />
    </Box>
  );
}
