import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import ConnectDevicesOverview from './components/Overview';
import ConnectDeviceEdit from './components/DeviceEdit';
import DeviceInformation from './components/DeviceInformation';
import Util from '../../../../util/Util';
import { VIEW_LIST } from '../../../commons/topBarMenu';
import { withPolyglot } from '../../../../i18n';
import './index.css';
import Polyglot from 'node-polyglot';
import { Device } from '../../../../model/device/device.model';
import { RootState } from '../../../../redux/store.model';

/**
 * Devices overview
 * The incident record by device will be shown here
 */

const proxyBackendURL = new Util().getCommanderProxyURL();

type Props = {
  polyglot: Polyglot;
  devices: Device[];
  groupId?: string;
  view?: 'viewList' | 'viewGrid' | 'viewGridSmall';
} & RouteComponentProps &
  ConnectedComponentProps;

type State = {
  deviceUpdate?: Device;
  showEditDevice: boolean;
  showDeviceInformation: boolean;
  device?: Device;
};

export class Devices extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.openEditDevice = this.openEditDevice.bind(this);
    this.closeEditDevice = this.closeEditDevice.bind(this);
    this.openDeviceUI = this.openDeviceUI.bind(this);
    this.openIncidentManager = this.openIncidentManager.bind(this);
    this.openDeviceInformation = this.openDeviceInformation.bind(this);
    this.closeDeviceInformation = this.closeDeviceInformation.bind(this);
    this.openDeviceSensordata = this.openDeviceSensordata.bind(this);
    this.state = {
      deviceUpdate: undefined,
      showEditDevice: false,
      showDeviceInformation: false,
      device: undefined,
    };
  }

  /**
   * Open edit device
   * @param object event
   * @param object device
   */
  openEditDevice(_: any, device: Device) {
    this.setState({
      deviceUpdate: device,
      showEditDevice: true,
    });
  }

  /**
   * Close edit device
   */
  closeEditDevice() {
    this.setState({ showEditDevice: false });
  }

  /**
   * Open device sensor data
   * @param object event
   * @param object device
   */
  openDeviceSensordata(_: any, device: Device) {
    const { groupId, history } = this.props;
    let url = `/devicemanager/device/${device.uuid}/device-detail/history`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/history`;
    }
    history.push(url);
  }

  /**
   * Open device ui
   * @param object event
   * @param object device
   */
  openDeviceUI(_: any, device: Device) {
    const { accessToken } = this.props;
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  }

  openDeviceOverviewPage = (_: any, device: Device) => {
    const { groupId, history } = this.props;
    let url = `/devicemanager/device/${device.uuid}/device-detail/`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/`;
    }
    history.push(url);
  };

  /**
   * Open incident manager
   * @param object event
   * @param object deviceUpdate
   */
  openIncidentManager(_: any, device: Device) {
    const { groupId, history } = this.props;
    let url = `/devicemanager/device/${device.uuid}/device-detail/incidents`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/incidents`;
    }
    history.push(url);
  }

  /**
   * Open device information
   * @param object event
   * @param object device
   */
  openDeviceInformation(_: any, device: Device) {
    this.setState({ showDeviceInformation: true, device });
  }

  /**
   * Close device information
   */
  closeDeviceInformation() {
    this.setState({ showDeviceInformation: false });
  }

  render() {
    const { device, showDeviceInformation, showEditDevice, deviceUpdate } =
      this.state;
    const { groupId, view = VIEW_LIST, devices } = this.props;
    return (
      <div>
        <DeviceInformation
          showDeviceInformation={showDeviceInformation}
          closeDeviceInformation={this.closeDeviceInformation}
          device={device}
        />
        {deviceUpdate && showEditDevice && (
          <ConnectDeviceEdit
            deviceId={deviceUpdate.uuid}
            showEditDevice={showEditDevice}
            closeEditDevice={this.closeEditDevice}
            groupId={groupId}
          />
        )}
        <ConnectDevicesOverview
          devices={devices}
          view={view}
          groupId={groupId}
          openEditDevice={this.openEditDevice}
          openDeviceUI={this.openDeviceUI}
          openDeviceOverviewPage={this.openDeviceOverviewPage}
          openIncidentManager={this.openIncidentManager}
          openDeviceInformation={this.openDeviceInformation}
          openDeviceSensordata={this.openDeviceSensordata}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  accessToken: state.auth.accessToken,
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withPolyglot(withRouter(connector(Devices)));
