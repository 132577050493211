import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Card, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { BoxPictureData } from 'stoerk-ui-components';
import DeviceIcon from '../../../../../assets/IconST7Landscape.svg';

/**
 * Select devices
 */
export class SelectDevices extends Component {
  constructor(props) {
    super(props);
    this.showDevices = this.showDevices.bind(this);
  }

  showDevices() {
    const { devicesGroup } = this.props;
    /* Show recipe list */
    const devices = devicesGroup.map((device, index) => (
      <BoxPictureData
        key={index.toString()}
        title={device.name}
        picture={device.iconURL !== null ? device.iconURL : DeviceIcon}
        icons={[
          {
            button: (
              <Checkbox
                onClick={(event, data) =>
                  this.props.selectDevices(device, data)
                }
              />
            ),
          },
        ]}
      />
    ));

    return devices;
  }

  render() {
    return (
      <Container fluid>
        <Card.Group textAlign="left">{this.showDevices()}</Card.Group>
      </Container>
    );
  }
}

SelectDevices.propTypes = {
  devicesGroup: PropTypes.arrayOf(Object).isRequired,
  selectDevices: PropTypes.func.isRequired,
};

export default connect()(SelectDevices);
