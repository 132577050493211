import { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import {
  getControlUnitsEventsErrorById,
  getControlUnitsEventsLoadingById,
  getControlUnitsEventsById,
  getMixedCurrentEventsFromId,
} from '../selectors/events';
import { fetchCurrentEventsControlUnits } from '../actions/thunks';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [currentAlarms, loading, error]
 * 
 * @usage
´´´ts
const [currentAlarms, loading, error] = useCurrentEventsControlUnitById("UUUID_DEVICE","1003", onError)
´´´
 */
export function useCurrentEventsControlUnitById(
  deviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown
) {
  const mixedControlUnitCurrentEvents = useAppSelector((state: RootState) =>
    getMixedCurrentEventsFromId(state, { deviceId, controlUnitId })
  );
  const currentEventsControlUnits = useAppSelector((state: RootState) =>
    getControlUnitsEventsById(state, { deviceId })
  );
  const loading = !!useAppSelector((state: RootState) =>
    getControlUnitsEventsLoadingById(state, { deviceId })
  );
  const error = useAppSelector((state: RootState) =>
    getControlUnitsEventsErrorById(state, { deviceId })
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!currentEventsControlUnits && !loading) {
      dispatch(fetchCurrentEventsControlUnits(deviceId))
        .unwrap()
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEventsControlUnits, deviceId, dispatch]);
  return [mixedControlUnitCurrentEvents ?? {}, loading, error] as const;
}
