import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import ControlUnitEventsPage from './ControlUnitEventsPage';
import ControlUnitDetailPage from './ControlUnitDetailPage/ControlUnitDetailPage';
import ControlUnitMonitoringPage from './ControlUnitMonitoringPage';
import ControlUnitParametersPage from './ControlUnitParametersPage/ControlUnitParametersPage';
import { QueryParamControlUnitPage } from './CUPage.model';

const routes = [
  { path: 'history', component: ControlUnitMonitoringPage },
  { path: 'events', component: ControlUnitEventsPage },
  { path: 'parameters', component: ControlUnitParametersPage },
  { path: '', component: ControlUnitDetailPage },
];

interface ControlUnitRoutesProps
  extends RouteComponentProps<QueryParamControlUnitPage> {}
export function ControlUnitRoutesSimple(props: ControlUnitRoutesProps) {
  return (
    <React.Fragment>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route
            path={`${props.match.path}/${path}`}
            component={component}
            key={path}
            exact
          />
        ))}
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(ControlUnitRoutesSimple);
