import React from 'react';
import Login from './components/auth/Login';
import RegisterForm from './components/auth/RegisterForm';
import AccountActivationPage from './components/auth/AccountActivationPage';
import PostRegisterPage from './components/auth/PostRegisterPage';
import ResetPasswordPage from './components/auth/ResetPasswordPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
import PostResetTriggerPage from './components/auth/PostResetTriggerPage';
import GroupsPage from './components/GroupManager';
import ImpressumPage from './components/ImpressumPage';
import CookiePolicyPage from './components/Cookies';
import NotFound from './components/notFound';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import LandingPage from './components/LandingPage';
import NotificationPage from './components/notifications';
import MyDevices from './components/GroupManager/components/GroupMyDevices/Devices';
import ConnectMapDevices from './components/GroupManager/components/MapDevices';
import UserProfile from './components/users/Profile';
import ConnectIncidentManager from './components/incidentManager';
import ConnectIncident from './components/incidentManager/details';

import ConnectRecipeManager from './components/recipeManager';
import ConnectRecipeManagerExport from './components/recipeManager/export';

import ConnectCreationVoucher from './components/vouchers/CreationVoucher';
import { ConnectedMonitoringScreen } from './components/GroupManager/components/Devices/components/Monitoring/ConnectedMonitoringScreen';
import PlayGround from './components/PlayGround/PlayGround';
import DeviceOverview from './components/GroupManager/components/Devices/components/DeviceOverview';
import ControlUnitPage from './components/GroupManager/components/Devices/components/DeviceOverview/components/DeviceOverviewPage/components/ControlUnitPage';
import extendedRoutes from './util/route-dom/extendedRoutes';

// TODO: TS: need withSnackBar with types

// Solve https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/display-name.md
const withSimpleWrap = (Component) =>
  function RouterWrapRender(props) {
    return <Component {...props} />;
  };
const routes = [
  { exact: true, path: '/login', render: withSimpleWrap(Login) },
  { exact: true, path: '/impressum', render: withSimpleWrap(ImpressumPage) },
  { exact: true, path: '/privacy', render: withSimpleWrap(PrivacyPolicyPage) },
  { exact: true, path: '/cookies', render: withSimpleWrap(CookiePolicyPage) },
  { exact: true, path: '/register', render: withSimpleWrap(RegisterForm) },
  { exact: true, path: '/signup', render: withSimpleWrap(RegisterForm) },
  {
    exact: true,
    path: '/registered',
    render: withSimpleWrap(PostRegisterPage),
  },
  {
    exact: true,
    path: '/activate',
    render: withSimpleWrap(AccountActivationPage),
  },
  {
    exact: true,
    path: '/resetpassword',
    render: withSimpleWrap(ResetPasswordPage),
  },
  {
    exact: true,
    path: '/resettriggered',
    render: withSimpleWrap(PostResetTriggerPage),
  },
  {
    exact: true,
    path: '/forgotpassword',
    render: withSimpleWrap(ForgotPasswordPage),
  },
  {
    path: '/resettriggered',
    render: withSimpleWrap(PostResetTriggerPage),
  },
  { exact: true, path: '/map', render: withSimpleWrap(ConnectMapDevices) },
  {
    exact: true,
    path: '/devicemanager/:groupId/device/:deviceId/monitoring',
    render: withSimpleWrap(ConnectedMonitoringScreen),
  },
  {
    exact: true,
    path: '/devicemanager//device/:deviceId/monitoring',
    render: withSimpleWrap(ConnectedMonitoringScreen),
  },
  {
    path: '/devicemanager/device/:deviceId/group/:groupId/device-detail/control-unit/:controlUnitId',
    render: withSimpleWrap(ControlUnitPage),
  },
  {
    path: '/devicemanager/device/:deviceId/device-detail/control-unit/:controlUnitId',
    render: withSimpleWrap(ControlUnitPage),
  },
  {
    path: '/devicemanager/device/:deviceId/group/:groupId/device-detail',
    render: withSimpleWrap(DeviceOverview),
  },
  {
    path: '/devicemanager/device/:deviceId/device-detail',
    render: withSimpleWrap(DeviceOverview),
  },
  {
    exact: true,
    path: '/devicemanager',
    render: withSimpleWrap(GroupsPage),
  },
  {
    exact: false,
    path: '/devicemanager/:groupId',
    render: withSimpleWrap(GroupsPage),
  },

  {
    exact: true,
    path: '/recipemanager/group/:groupId',
    render: withSimpleWrap(ConnectRecipeManager),
  },
  {
    exact: true,
    path: '/recipemanager/export/group/:groupId',
    render: withSimpleWrap(ConnectRecipeManagerExport),
  },

  {
    exact: true,
    path: '/mydevices',
    render: withSimpleWrap(MyDevices),
  },
  { exact: true, path: '/userprofile', render: withSimpleWrap(UserProfile) },
  {
    exact: true,
    path: '/notifications',
    render: withSimpleWrap(NotificationPage),
  },
  {
    exact: true,
    path: '/incidentmanager/device/:deviceId/group/:groupId',
    render: withSimpleWrap(ConnectIncidentManager),
  },
  {
    exact: true,
    path: '/incidentmanager/device/:deviceId',
    render: withSimpleWrap(ConnectIncidentManager),
  },
  {
    exact: true,
    path: '/incidentmanager/group/:groupId',
    render: withSimpleWrap(ConnectIncidentManager),
  },
  {
    exact: true,
    path: '/incidentmanager',
    render: withSimpleWrap(ConnectIncidentManager),
  },

  {
    exact: true,
    path: '/incident/:incidentId/group/:groupId',
    render: withSimpleWrap(ConnectIncident),
  },
  {
    exact: true,
    path: '/incident/:incidentId',
    render: withSimpleWrap(ConnectIncident),
  },

  {
    exact: true,
    path: '/createvoucher',
    render: withSimpleWrap(ConnectCreationVoucher),
  },
  ...extendedRoutes,
  { path: '/', render: withSimpleWrap(LandingPage), exact: true },

  {
    exact: true,
    path: '*',
    render: withSimpleWrap(NotFound),
  },
];

export default routes;
