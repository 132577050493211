import { DEVICE_STATUS_TYPE } from './device/device.model';

export const STATUS_ALERT = DEVICE_STATUS_TYPE.ALERT;
export const STATUS_ALERT_INPROGRESS = DEVICE_STATUS_TYPE.ALERT_INPROGRESS;
export const STATUS_OK = DEVICE_STATUS_TYPE.OK;
export const STATUS_OK_INPROGRESS = DEVICE_STATUS_TYPE.OK_INPROGRESS;
export const STATUS_INPROGRESS = DEVICE_STATUS_TYPE.INPROGRESS;
export const STATUS_NEW = DEVICE_STATUS_TYPE.NEW;

/**
 * const string LABEL_NEW: this constant is used to mark a device that has been added
 * to the group or user account
 */
export const LABEL_NEW = 'new';
export const LABEL_ALREADY_ADDED = 'already_added';
