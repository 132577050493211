import React, { Component } from 'react';
import { Divider, Transition } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { withSnackbar } from 'stoerk-ui-components';
import ValidationUtil from '../../util/ValidationUtil';
import { registerClient } from '../../redux/auth/actions';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { OpenSnackbarProps } from '../../handlingErrors';
import {
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
  Button,
  Container,
} from '@mui/material';
import ErrorText from '../commons/ErrorText';

const URLParameterEmail = 'email';
interface RegisterFormProps
  extends OpenSnackbarProps,
    PolyglotComponentProps,
    RouteComponentProps,
    ConnectedComponentProps {}

interface State {
  email: string;
  emailValid: null | boolean;
  animation: any;
  duration: number;
  visible: boolean;
  password: string;
  passwordConfirm: string;
  passwordConfirmed: null | boolean;
}

export class RegisterForm extends Component<RegisterFormProps, State> {
  validationUtil: ValidationUtil;
  constructor(props: RegisterFormProps) {
    super(props);
    const { polyglot } = props;
    this.activeTransition = this.activeTransition.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);
    this.errorMessageInputFields = this.errorMessageInputFields.bind(this);

    /* the url could have an email (user invitation): in this case we should
    show the email in the field email */
    let email = '';
    let emailValid = null;
    if (props.location.search) {
      const URLParams = props.location.search.split('=');
      if (URLParams.length > 0) {
        if (URLParams[0].indexOf(URLParameterEmail) > 0) {
          email = URLParams[1];
          emailValid = this.validationUtil.validateEmailAddress(email, false);
        }
      }
    }

    this.state = {
      email,
      emailValid,
      animation: null,
      duration: 500,
      visible: true,
      password: '',
      passwordConfirm: '',
      passwordConfirmed: null,
    };
  }

  componentDidMount() {
    this.onCompleteTransition();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  setEmail(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const email = event.target.value;
    const confirmed = this.validationUtil.validateEmailAddress(email, true);
    this.setState({ email, emailValid: confirmed });
  }

  setPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { passwordConfirm } = this.state;
    const pass = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      pass,
      passwordConfirm
    );
    this.setState({ password: pass, passwordConfirmed: confirmed });
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  confirmPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { password } = this.state;
    const passConfirm = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      password,
      passConfirm
    );
    this.setState({
      passwordConfirm: passConfirm,
      passwordConfirmed: confirmed,
    });
  }

  errorMessageInputFields(field: string) {
    return this.validationUtil.getErrorMessage(field);
  }

  /**
   * Submit user
   * Call the rest api to register a new user
   * @param object event
   */
  async submitUser(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event?.preventDefault();
    const { passwordConfirmed, emailValid, email, password } = this.state;
    const { history, openSnackbar } = this.props;
    try {
      if (!passwordConfirmed || !emailValid) {
        this.activeTransition();
        return;
      }
      await this.props.registerClient(email, password);
      history.replace('/registered');
    } catch (error: any) {
      /* show snack bar - error messsage */
      const errorDescription =
        typeof error === 'object' ? error.message : error;
      const message = { text: errorDescription, type: 'error' };
      openSnackbar(message);
    }
  }

  render() {
    const {
      animation,
      duration,
      visible,
      email,
      emailValid,
      passwordConfirm,
      passwordConfirmed,
      password,
    } = this.state;

    const { polyglot } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Transition
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <Paper>
            <Typography component="h1" variant="h4" textAlign="center">
              {polyglot.t('register.title')}
            </Typography>
            <Box
              sx={{
                width: '100%', // Fix IE 11 issue.
                marginTop: (theme) => theme.spacing(3),
                padding: (theme) => theme.spacing(2),
              }}
            >
              <form>
                <Stack spacing={2}>
                  <TextField
                    label={polyglot.t('login.email')}
                    type="email"
                    onChange={this.setEmail}
                    required
                    error={Boolean(email && !emailValid)}
                    value={email}
                    helperText={
                      email &&
                      !emailValid && (
                        <ErrorText>
                          {this.validationUtil.getErrorMessage('email')}
                        </ErrorText>
                      )
                    }
                  />
                  <TextField
                    type="password"
                    onChange={this.setPassword}
                    label={polyglot.t('login.password')}
                    required
                  />
                  <TextField
                    type="password"
                    onChange={this.confirmPassword}
                    label={polyglot.t('register.confirm_pass')}
                    required
                    error={Boolean(passwordConfirm && !passwordConfirmed)}
                    helperText={
                      password &&
                      passwordConfirm &&
                      !passwordConfirmed && (
                        <ErrorText>
                          {this.errorMessageInputFields('passwordConfirmation')}
                        </ErrorText>
                      )
                    }
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={this.submitUser}
                  >
                    {polyglot.t('register.button_title')}
                  </Button>
                </Stack>
              </form>
              <Box>
                <Divider />
              </Box>

              <Box sx={{ textAlign: 'center' }}>
                <Typography component="p" variant="body1">
                  {polyglot.t('register.login_link_message')}
                </Typography>
                <Link to="/login">
                  <Button>{polyglot.t('register.login_link_title')}</Button>
                </Link>
              </Box>
            </Box>
          </Paper>
        </Transition>
      </Container>
    );
  }
}

const connector = connect(null, {
  registerClient,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withRouter(withSnackbar(withPolyglot(RegisterForm))));
