export default class Calls {
  constructor(restCalls) {
    this.restCalls = restCalls;
  }

  /**
   * Fetch recipes group
   * @param string groupId
   * @return array recipes
   */
  fetchRecipesGroup(groupId) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.getRecipesGroup(
        groupId,
        (response) => resolve(response),
        reject
      );
    });
    return promise;
  }

  /**
   * Fetch recipe associated to the user
   * @return array recipes
   */
  fetchRecipesUser() {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.getRecipesUser((response) => resolve(response), reject);
    });
    return promise;
  }

  /**
   * Post recipe group
   * Upload a new recipe file to a group
   * @param string groupId
   * @param object recipe
   * @return
   */
  postRecipeGroup(groupId, recipe) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.setRecipeGroup(
        groupId,
        recipe,
        (response) => resolve(response),
        reject
      );
    });
    return promise;
  }

  /**
   * Recipes transmission
   * @param string groupId
   * @param array recipesIds
   * @param array devicesIdsBlackList: list of device which will be not get the recipes
   */
  recipesTransmission(groupId, recipesIds, devicesIdsBlackList) {
    const promise = new Promise((resolve, reject) => {
      const recipesTransmission = {
        groups: [
          {
            groupID: groupId,
            recipes: recipesIds,
          },
        ],
        deviceBlackList: devicesIdsBlackList,
      };
      this.restCalls.recipesTransmission(
        recipesTransmission,
        (response) => resolve(response),
        reject
      );
    });
    return promise;
  }

  /**
   * Put recipe group
   * Update an existing recipe
   * @param string groupId
   * @param string recipeId
   * @param object recipe
   * @return
   */
  putRecipeGroup(groupId, recipeId, recipe) {
    return true;
  }
}
