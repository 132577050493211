import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IncidentAPIResponse,
  IncidentsAttachmentsAPIResponse,
} from '../../../model/incidentManager/incident.model';
import { ErrorRestST } from '../../../rest/rest.model';
import {
  fetchIncidentsByDevicesIds,
  fetchPublicIncidentsByDevicesIds,
  getIncidentByIncidentId,
} from '../actions/asyncThunks';
import { IncidentsState, nameReducer } from '../incidents.model';

export const initialStateIncidents: IncidentsState = {
  items: {},
  item: undefined,
  // Stop using unresolved items, use /api/incidents/device/public instead
  publicIncidentsItems: {},
  attachments: {},
  attachment: {} /* { content: , content-type } */,
  loadingIncident: false,
  loadingIncidentByDeviceId: false,
  loadingIncidentByIncidentId: false,
  loadingPublicIncidentById: {},
};

export const IncidentsSlice = createSlice({
  name: nameReducer,
  initialState: initialStateIncidents,
  reducers: {
    addAttachment(
      state,
      action: PayloadAction<{
        incidentId: string;
        attachment: IncidentsAttachmentsAPIResponse;
      }>
    ) {
      const { attachment, incidentId } = action.payload;
      if (!state.attachments[incidentId]) state.attachments[incidentId] = [];
      state.attachments[incidentId].push(attachment);
    },
    assignAttachments(
      state,
      action: PayloadAction<{
        incidentId: string;
        attachments: IncidentsAttachmentsAPIResponse[];
      }>
    ) {
      const { attachments, incidentId } = action.payload;
      state.attachments[incidentId] = attachments;
    },
    assignAttachment(state, action: PayloadAction<any>) {
      state.attachment = action.payload;
    },
    updateIncident(
      state,
      action: PayloadAction<{
        incident: Partial<IncidentAPIResponse>;
        incidentId: string;
      }>
    ) {
      state.items[action.payload.incidentId] = {
        ...state.items[action.payload.incidentId],
        ...action.payload.incident,
      };
    },
  },
  extraReducers: (builder) => {
    // fetchPublicIncidentsByDevicesIds Thunk
    builder.addCase(
      fetchPublicIncidentsByDevicesIds.pending,
      (state, action) => {
        const devicesIds = action.meta.arg;
        devicesIds.forEach(
          (deviceId) => (state.loadingPublicIncidentById[deviceId] = true)
        );
      }
    );
    builder.addCase(
      fetchPublicIncidentsByDevicesIds.fulfilled,
      (state, action) => {
        // extract incidents from array
        const publicIncidents = Object.values(action.payload).flatMap(
          (incidentListByDevice) => incidentListByDevice
        );
        // Map in state
        state.publicIncidentsItems = publicIncidents.reduce(
          (acc, current) => ({ ...acc, [current.id]: current }),
          state.publicIncidentsItems
        );
        const devicesIds = action.meta.arg;
        devicesIds.forEach(
          (deviceId) => (state.loadingPublicIncidentById[deviceId] = false)
        );
      }
    );
    builder.addCase(
      fetchPublicIncidentsByDevicesIds.rejected,
      (state, action) => {
        const devicesIds = action.meta.arg;
        devicesIds.forEach(
          (deviceId) => (state.loadingPublicIncidentById[deviceId] = false)
        );
        state.error = action.error as ErrorRestST;
      }
    );
    // fetchIncidentsByDeviceIds Thunk
    builder.addCase(fetchIncidentsByDevicesIds.pending, (state, action) => {
      state.loadingIncidentByDeviceId = true;
    });
    builder.addCase(fetchIncidentsByDevicesIds.fulfilled, (state, action) => {
      // extract incidents from array
      const incidents = Object.values(action.payload).flatMap(
        (incidentListByDevice) => incidentListByDevice
      );
      // Map in state
      state.items = incidents.reduce(
        (acc, current) => ({ ...acc, [current.id]: current }),
        state.items
      );
      state.loadingIncidentByDeviceId = false;
    });
    builder.addCase(fetchIncidentsByDevicesIds.rejected, (state, action) => {
      state.loadingIncidentByDeviceId = false;
      state.error = action.error as ErrorRestST;
    });

    // getIncidentByIncidentId Thunk
    builder.addCase(getIncidentByIncidentId.pending, (state, action) => {
      state.loadingIncidentByIncidentId = true;
    });
    builder.addCase(getIncidentByIncidentId.fulfilled, (state, action) => {
      state.items[action.payload.id] = action.payload;
      state.loadingIncidentByIncidentId = false;
    });
    builder.addCase(getIncidentByIncidentId.rejected, (state, action) => {
      state.loadingIncidentByIncidentId = false;
      state.error = action.error as ErrorRestST;
    });
  },
});

export const {
  actions: actionsIncidents,
  reducer: reducerIncidents,
  name: nameReducerIncidents,
} = IncidentsSlice;
