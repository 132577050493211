import React from 'react';
import Header from '../../../../components/header/Header';
import logo from '../../assets/hengel-eu__logoHengelOriginal.png';

type HeaderType = typeof Header;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore exotic component
const HeaderHengel: HeaderType = (props) => (
  <Header {...props} title="" logoIcon={logo} />
);
export default HeaderHengel;
