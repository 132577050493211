import ISO6391 from 'i18n-iso-639-1';
import CommunLanguages from './IETFLanguagesUtil';

/**
 * Languages util
 */
export default class LanguagesUtil {
  constructor(polyglot) {
    this.languages = ISO6391.getAllCodes();
    this.communLanguages = new CommunLanguages(polyglot).getAllLanguages();
  }

  /**
   * Get all languages
   * This function will returns all the languages but at the top will the common
   * languages
   * @return array languages [{id, name}]
   */
  getAllLanguages() {
    const languages = this.communLanguages.map((c) => ({
      id: c.code,
      name: c.name,
    }));

    this.languages.forEach((c) => {
      if (languages.find((l) => l.id === c) === undefined) {
        languages.push({
          id: c,
          name: ISO6391.getName(c),
        });
      }
    });
    return languages;
  }
}
