import { RouteComponentProps } from 'react-router';
import { PolyglotComponentProps } from '../../../i18n';
import { Group } from '../../../model/group/group.model';
import GroupModel from '../../../model/group/Model';

export interface NavigationPathProps
  extends RouteComponentProps,
    PolyglotComponentProps {}

export function getLastPath(pathname: string) {
  const list = pathname.split('/');
  const moduleSelected = list[list.length - 1] ?? '';
  return moduleSelected;
}

const dicTitles: Record<string, string> = {
  incidents: 'incident_manager.title',
  recipes: 'recipe_manager.title',
};
/**
 * Navigation path
 * Build the navigation path used in the breadcrumb
 * @param props {NavigationPathProps} need router-dom and polyglot
 * @param string groupId
 * @param array groups
 * @param object group
 * @return array sectionsNavigation
 */
export function navigationPath(
  props: NavigationPathProps,
  groupId: string | undefined,
  groups: Group[],
  group: Group | undefined,
  moduleSelected?: string
) {
  const { polyglot, history } = props;
  const sectionsNavigation = [
    { content: polyglot.t('nav.home'), onClick: () => history.push('/') },
    {
      content: polyglot.t('nav.menu.devicemanager'),
      onClick: () => history.push('/devicemanager'),
    },
  ];

  /* build path */
  if (groupId && groups.length > 0 && group !== undefined) {
    const pathToGroup = GroupModel.getPathToGroup(group, groups);
    pathToGroup.forEach((p) => {
      sectionsNavigation.push({
        content: p.name,
        onClick: () => history.push(`/devicemanager/${p.id}`),
      });
    });
  }

  /** module selected */
  if (moduleSelected && Object.keys(dicTitles).includes(moduleSelected ?? '')) {
    sectionsNavigation.push({
      content: polyglot.t(dicTitles[moduleSelected] ?? ''),
      onClick: () =>
        history.push(`/devicemanager/${groupId}/${moduleSelected}`),
    });
  }
  return sectionsNavigation;
}
