import withStyles from 'react-jss';

import Footer from '../../../../components/footer/Footer';

const styles = {
  '@global': {
    '.footer-navigation .menu': {
      background: 'rgba(145, 145, 146, 1) !important;',
    },
  },
};

export default withStyles(styles)(Footer);
