import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Paper } from '@mui/material';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import ListItem from '../../commons/ListItem';
import { Group } from '../../../model/group/group.model';
import GroupModel from '../../../model/group/Model';
import { RootState } from '../../../redux/store.model';
import { groupButtonsRightsSelector } from '../../../redux/groups/selectors/groupRightsSelectors';
import ConnectGroupEdit from './GroupEdit';
import TooltipLight from '../../commons/TooltipLight';
import { STButtonIcon } from '../../commons/STButtonIcon';
import {
  getIsLoadingAllPublicIncidents,
  getNumNewIncidentsByGroupSelected,
} from '../../../redux/incidents/selectors';
import Contacts from './Contacts';
import ConnectCameras from './Cameras';
import ConnectGroupDelete from './GroupDelete';

interface GroupCardProps
  extends PolyglotComponentProps,
    RouteComponentProps,
    OwnProps,
    ConnectedComponentProps {}
interface OwnProps {
  group?: Group;
  loading?: boolean;
  groupId: string;
  moduleSelected: string | '';
}

export function GroupCardSimple(props: GroupCardProps) {
  const { group, groupId, history, moduleSelected, loading } = props;
  const openDeviceDetail = () => {
    if (moduleSelected === '') return;
    const url = `/devicemanager/${groupId}`;
    history.push(url);
  };

  return (
    <Paper variant="outlined" sx={{ paddingRight: 1 }}>
      <ListItem
        key={groupId}
        id={groupId}
        name={group?.name}
        loading={loading}
        iconURL={GroupModel.getGroupIcon(group?.iconURL)}
        className={GroupModel.getClassesNamesFromDevice(group)}
        onClick={openDeviceDetail}
        buttons={
          <Button.Group>
            <GroupContactButton {...props} />
            <GroupEditButton {...props} />
            <GroupHistoryButton {...props} />
            <GroupAlarmsButton {...props} />
            <GroupRecipesButton {...props} />
            <GroupCamerasButton {...props} />
            {/* It's wire remove it self */}
            {/* <GroupDeleteButton {...props} /> */}
          </Button.Group>
        }
      />
    </Paper>
  );
}
const sizeIcons = 'medium';

function GroupContactButton(props: GroupCardProps) {
  const { group, polyglot, showButtonContacts } = props;
  const [showContactGroup, setShowContactGroup] = useState(false);
  if (!showButtonContacts) return null;

  const openContact = () => {
    setShowContactGroup(true);
  };
  const closeContactGroup = () => setShowContactGroup(false);
  return (
    <React.Fragment>
      <Contacts
        group={group}
        showContacts={showContactGroup}
        closeContacts={closeContactGroup}
      />
      <TooltipLight title={polyglot.t('group.contact_list')}>
        <STButtonIcon
          icon="address book outline"
          size={sizeIcons}
          onClick={openContact}
        />
      </TooltipLight>
    </React.Fragment>
  );
}

function GroupEditButton(props: GroupCardProps) {
  const { groupId, polyglot, showButtonUpdateGroup } = props;
  const [showEditGroup, setShowEditGroup] = useState(false);
  if (!showButtonUpdateGroup) return null;

  const openEditGroup = () => {
    setShowEditGroup(true);
  };

  const closeEditGroup = () => setShowEditGroup(false);

  return (
    <React.Fragment>
      <ConnectGroupEdit
        showEditGroup={showEditGroup}
        closeEditGroup={closeEditGroup}
        groupId={groupId}
      />
      <TooltipLight title={polyglot.t('group.tooltip.open_window_edit')}>
        <STButtonIcon icon="write" size={sizeIcons} onClick={openEditGroup} />
      </TooltipLight>
    </React.Fragment>
  );
}

function GroupAlarmsButton(props: GroupCardProps) {
  const {
    groupId,
    polyglot,
    showButtonReadIncidents,
    history,
    moduleSelected,
    isLoadingIncidents,
    numNewIncidents,
  } = props;
  if (!showButtonReadIncidents) return null;
  const selected = moduleSelected === 'incidents';
  const openIncidentManager = () => {
    if (selected) return;
    const url = `/devicemanager/${groupId}/incidents`;

    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.tooltip.open_window_alarms')}>
      <STButtonIcon
        icon="warning sign"
        size={sizeIcons}
        onClick={openIncidentManager}
        loading={isLoadingIncidents}
        badge={numNewIncidents}
        selected={selected}
      />
    </TooltipLight>
  );
}

function GroupRecipesButton(props: GroupCardProps) {
  const {
    groupId,
    polyglot,
    showButtonReadIncidents,
    history,
    moduleSelected,
  } = props;
  if (!showButtonReadIncidents) return null;
  const selected = moduleSelected === 'recipes';
  const openRecipesManager = () => {
    if (selected) return;
    const url = `/devicemanager/${groupId}/recipes`;
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('group.tooltip.open_window_recipes')}>
      <STButtonIcon
        icon="food"
        size={sizeIcons}
        onClick={openRecipesManager}
        selected={selected}
      />
    </TooltipLight>
  );
}

function GroupCamerasButton(props: GroupCardProps) {
  const { groupId, polyglot, showButtonCameraGroups } = props;
  const [showCamerasGroup, setShowCamerasGroup] = useState(false);
  if (!showButtonCameraGroups) return null;

  const openCamerasGroup = () => {
    setShowCamerasGroup(true);
  };

  const closeCamerasGroup = () => setShowCamerasGroup(false);

  return (
    <React.Fragment>
      <ConnectCameras
        showCameras={showCamerasGroup}
        close={closeCamerasGroup}
        groupId={groupId}
      />
      <TooltipLight title={polyglot.t('group.tooltip.open_window_cameras')}>
        <STButtonIcon
          icon="camera"
          size={sizeIcons}
          onClick={openCamerasGroup}
        />
      </TooltipLight>
    </React.Fragment>
  );
}

export function GroupHistoryButton(
  props: Pick<
    GroupCardProps,
    'groupId' | 'history' | 'moduleSelected' | 'polyglot' | 'showButtonHistory'
  >
) {
  const { groupId, history, moduleSelected, polyglot, showButtonHistory } =
    props;
  if (!showButtonHistory) return null;
  const selected = moduleSelected === 'history';

  const openControlUnitHistory = () => {
    if (selected) return;
    const url = `/devicemanager/${groupId}/history`;
    history.push(url);
  };
  return (
    <TooltipLight title={polyglot.t('device.monitoring.title')}>
      <STButtonIcon
        icon="chart line"
        size={sizeIcons}
        onClick={openControlUnitHistory}
        selected={selected}
      />
    </TooltipLight>
  );
}

/* It's wire remove it self */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function GroupDeleteButton(props: GroupCardProps) {
  const { groupId, polyglot, showButtonDeleteGroup } = props;
  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  if (!showButtonDeleteGroup) return null;

  const openDeleteGroup = () => {
    setShowDeleteGroup(true);
  };

  const closeDeleteGroup = () => setShowDeleteGroup(false);

  return (
    <React.Fragment>
      <ConnectGroupDelete
        // @ts-ignore FIXME: ConnectGroupDelete not typed
        showDeleteGroup={showDeleteGroup}
        closeDeleteGroup={closeDeleteGroup}
        groupId={groupId}
      />

      <TooltipLight title={polyglot.t('group.tooltip.delete_group')}>
        <STButtonIcon
          icon="delete"
          size={sizeIcons}
          onClick={openDeleteGroup}
        />
      </TooltipLight>
    </React.Fragment>
  );
}

const mapStateToProps = (
  state: RootState,
  props: OwnProps &
    RouteComponentProps<{
      groupId?: string;
    }>
) => ({
  ...groupButtonsRightsSelector(state, props),
  accessToken: state.auth.accessToken,
  isLoadingIncidents: getIsLoadingAllPublicIncidents(state),
  numNewIncidents: getNumNewIncidentsByGroupSelected(state, props),
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedGroupCard = withPolyglot(withRouter(connector(GroupCardSimple)));
export default ConnectedGroupCard;
