import React, { Component } from 'react';
import {
  Form,
  Dropdown,
  Transition,
  Label,
  Segment,
  StrictDropdownProps,
  DropdownProps,
} from 'semantic-ui-react';
import { Button } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import ValidationUtil from '../../../../../util/ValidationUtil';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import { withUserRightUtil } from '../../../../../util/rights';
import fetchRoles from '../../../../../redux/roles/actions';
import {
  putGroupUsers,
  putGroupUsersWithRoles,
  putGroupUsersByEmail,
} from '../../../../../redux/groups/actions/thunks';
import { postUsersRightsByEmail } from '../../../../../redux/users/actions';
import { withPolyglot } from '../../../../../i18n';
import getRolesNamesDescriptions from './Constants';
import { RootState } from '../../../../../redux/store.model';
import { UserAPIResponse } from '../../../../../model/user/user.model';
import Polyglot from 'node-polyglot';
import { getAllUsers } from '../../../../../redux/users/selectors';
import AuthCalls from '../../../../../model/auth/Calls';
import { USER_REGISTER_STATUS } from '../../../../../model/auth/auth.model';
import { openNewUsersConfirmationDialog } from './NewUsersConfirmationDialog';
import { STModal } from '../../../../commons/Modal';

type OwnProps = {
  groupId: string;
  usersToAssign?: UserAPIResponse[];
  closeGroupUserAssign: (event: any) => any;
};
type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & OwnProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps &
  OpenSnackbarProps;

type State = {
  usersOptions: StrictDropdownProps['options'];
  animation: any;
  duration: number;
  visible: boolean;
  usersEmails: string[];
  rolesId: string[];
};
/**
 * Group user assign
 * this class assign a user with role to a group
 */
export class GroupUserAssign extends Component<Props, State> {
  validationUtil: ValidationUtil;
  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  /**
   * On open dropdown
   * this function will be called when the dropdown menu is open (users to assign)
   */
  static onOpenDropdown() {
    const options = document.getElementsByClassName('menu transition visible');
    if (options.length > 0) {
      const height = options[0].clientHeight;
      const dropDown = document.getElementById('placeHolderDropdowOpen');
      if (dropDown) dropDown.style.height = `${height}px`;
    }
  }

  constructor(props: Props) {
    super(props);
    this.setUserEmails = this.setUserEmails.bind(this);
    this.assignGroupUser = this.assignGroupUser.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.activeTransition = this.activeTransition.bind(this);
    this.setRolesId = this.setRolesId.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.getUsersOptions = this.getUsersOptions.bind(this);
    this.loadRights = this.loadRights.bind(this);
    this.loadData = this.loadData.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    /* the variable userInvitations will have all the added users by email */
    this.state = {
      usersOptions: [],
      animation: null,
      duration: 500,
      visible: true,
      usersEmails: [],
      rolesId: [],
    };
  }

  async componentDidMount() {
    this.onCompleteTransition();
    await this.loadData();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  /**
   * Add and item to the dropdown list.
   */
  onAddItem(e: any, data: DropdownProps) {
    const value = data.value as string;
    const { openSnackbar } = this.props;
    if (this.validationUtil.validateEmailAddress(value, false)) {
      let { usersOptions = [] } = this.state;
      usersOptions = [{ key: value, value, text: value }, ...usersOptions];
      this.setState({ usersOptions });
    } else {
      const message = {
        text: this.validationUtil.getErrorMessage('email'),
        type: 'error',
      };
      openSnackbar(message);
    }
  }

  /**
   * Get users options
   * this function builds an array with the assigned users to be used in a
   * drowpdown component
   * @param array usersToAssign
   * @return array usersOptions
   */
  getUsersOptions(usersToAssign: UserAPIResponse[]) {
    const usersOptions = usersToAssign.map((user) => {
      const name = user.name ? user.name : user.email;
      return {
        key: user.id,
        value: user.email,
        text: !name ? user.id : name,
      };
    });

    this.setState({ usersOptions });
  }

  /**
   * Set user id
   * @param object event
   * @param object data
   */
  setUserEmails(e: any, data: DropdownProps) {
    const values = data.value as string[];
    const validValues = values.filter((item) =>
      this.validationUtil.validateEmailAddress(item, false)
    );
    this.setState({ usersEmails: validValues });
  }

  /**
   * Set role id
   * @param object event
   * @param object data
   */
  setRolesId(e: any, data: DropdownProps) {
    const value = data.value as string[];
    this.setState({ rolesId: value });
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  /**
   * Load data
   */
  async loadData() {
    try {
      const { usersToAssign = [] } = this.props;
      const showRoles = this.loadRights();
      this.getUsersOptions(usersToAssign);
      if (showRoles) {
        await this.props.fetchRoles();
      }
    } catch (error: any) {
      const { openSnackbar } = this.props;
      const message = { text: error, type: 'error' };
      openSnackbar(message);
    }
  }

  /**
   * Load rights
   */
  loadRights() {
    const { groupId, rightsUserUtil } = this.props;
    const showRoles = rightsUserUtil.hasRightsToReadRoles(groupId);
    return showRoles;
  }

  /**
   * Associate group user
   * this function assign group users
   */
  async assignGroupUser() {
    const {
      groupId,
      closeGroupUserAssign,
      handlingErrorsApi,
      polyglot,
      openSnackbar,
      users,
    } = this.props;
    const { usersEmails, rolesId } = this.state;
    try {
      if (usersEmails.length === 0) {
        this.activeTransition();
      } else {
        /* filter users via email */
        const usersByEmail = usersEmails.filter(
          (email) => !users.find((user) => user.email === email)
        );
        /* filter users via id */
        const usersFoundIds = users
          .filter((user) => usersEmails.includes(user.email))
          .map((user) => user.id);

        /* Invite users via email */
        if (usersByEmail.length > 0) {
          const newUsers = await this.getNewUsers(usersByEmail);
          if (newUsers.length > 0) {
            /** Invite those users first, stopping this flow */
            const resultonfirmation = await openNewUsersConfirmationDialog({
              emails: newUsers,
            }).catch((value) => value);
            if (!resultonfirmation) return;
          }
        }

        /* Assign users with id */
        if (usersFoundIds.length > 0) {
          if (rolesId && rolesId.length > 0) {
            await this.props.putGroupUsersWithRoles(
              groupId,
              usersFoundIds,
              rolesId
            );
          } else {
            await this.props.putGroupUsers(groupId, usersFoundIds);
          }
        }

        /* Assign users via email */
        if (usersByEmail.length > 0) {
          await this.props.putGroupUsersByEmail(groupId, usersByEmail);

          if (rolesId && rolesId.length > 0) {
            await this.props.postUsersRightsByEmail(
              usersByEmail,
              rolesId,
              groupId
            );
          }
        }
        closeGroupUserAssign(true);
        const message =
          usersEmails.length > 1
            ? {
                text: polyglot.t(
                  'group.users.assign_several_successful_message'
                ),
                type: 'ok',
              }
            : {
                text: polyglot.t('group.users.assign_successful_message'),
                type: 'ok',
              };
        openSnackbar(message);
      }
    } catch (error) {
      /* show snack bar with error message */
      handlingErrorsApi(error);
    }
  }

  getNewUsers = (emails: string[]): Promise<string[]> => {
    const calls = new AuthCalls();
    return calls
      .checkUsers(emails)
      .then((usersChecked: any) =>
        Object.keys(usersChecked).filter(
          (email) => usersChecked[email] === USER_REGISTER_STATUS.UNKNOWN
        )
      );
  };

  render() {
    const { animation, duration, visible, usersOptions } = this.state;
    const { roles, polyglot, closeGroupUserAssign } = this.props;
    /* options for the drowpdown roles */
    const rolesOptions = getRolesNamesDescriptions(roles).map(
      (r: { id: any; name: any; description: any }) => ({
        key: r.id,
        value: r.id,
        text: r.name,
        icon: r.description,
      })
    );

    return (
      <div>
        <Transition
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <STModal
            open
            onClose={() => closeGroupUserAssign(false)}
            title={polyglot.t('group.users.assign_dialog_title')}
            scroll="body"
            // SemanticDropDown popup
            sx={{
              '&& .MuiDialogContent-root,.MuiPaper-root': {
                overflowY: 'initial',
              },
            }}
            buttonActions={
              <>
                <Button onClick={closeGroupUserAssign}>
                  {polyglot.t('group.cancel_button_title')}
                </Button>
                <Button variant="contained" onClick={this.assignGroupUser}>
                  {polyglot.t('group.users.assign_user_button_title')}
                </Button>
              </>
            }
          >
            <Form className="formular-material-design">
              <Form.Field>
                <Dropdown
                  placeholder={polyglot.t('group.users.user')}
                  fluid
                  multiple
                  search
                  selection
                  allowAdditions
                  value={this.state.usersEmails}
                  options={usersOptions}
                  onAddItem={this.onAddItem}
                  onChange={this.setUserEmails}
                  noResultsMessage={polyglot.t(
                    'group.users.no_users_to_assign'
                  )}
                  additionLabel={`${polyglot.t(
                    'group.users.enter_email_address'
                  )} : `}
                  onOpen={GroupUserAssign.onOpenDropdown}
                  id="users"
                />
                <Label pointing>
                  {`${polyglot.t(
                    'group.users.select_user_or_insert_email_address'
                  )} ${polyglot.t(
                    'group.users.invite_user_via_email_needs_role_id'
                  )}`}
                </Label>
              </Form.Field>
              {rolesOptions.length > 0 && (
                <Form.Field>
                  <Dropdown
                    placeholder={polyglot.t('group.users.role')}
                    fluid
                    search
                    selection
                    multiple
                    options={rolesOptions}
                    onOpen={GroupUserAssign.onOpenDropdown}
                    onChange={this.setRolesId}
                    id="roles"
                  />
                </Form.Field>
              )}
              <Segment basic id="placeHolderDropdowOpen" />
            </Form>
          </STModal>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  roles: state.roles.items || [],
  users: getAllUsers(state),
});

const connector = connect(mapStateToProps, {
  fetchRoles,
  putGroupUsers,
  putGroupUsersWithRoles,
  postUsersRightsByEmail,
  putGroupUsersByEmail,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withSnackbar(withPolyglot(withUserRightUtil(connector(GroupUserAssign))))
);
