import { Badge, Box, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import './index.css';

export interface STButtonIconLoadingProps extends IconButtonProps {
  /** red circle with number */
  badge?: React.ReactNode;
  loading?: boolean;
  selected?: boolean;
  /** some time we don't want to have a clickable button, so you can use 'initial' */
  cursor?: string;
  icon?: SemanticICONS;
}

/**
 * Same functionality of SemanticButton but it include a loading
 *
 * To work in children is necessary put manually the icon
 * @param props
 * @returns
 */
export const STButtonIcon = React.forwardRef(function STButtonIconWrapper(
  props: STButtonIconLoadingProps,
  ref: IconButtonProps['ref']
) {
  const {
    icon,
    loading,
    selected,
    children,
    cursor,
    className,
    badge,
    ...othersProps
  } = props;
  let parsedClassName = `ButtonLoading`;
  if (className) {
    parsedClassName += ` ${className}`;
  }

  return (
    <IconButton
      {...othersProps}
      disabled={props.disabled || loading}
      className={parsedClassName}
      style={{ cursor }}
      ref={ref}
    >
      <Badge badgeContent={badge} color="primary" invisible={!Boolean(badge)}>
        {icon && <Icon name={icon as SemanticICONS} />}

        {/* {badge ? <div className="badge">{badge}</div> : null} */}
        {children ? children : null}
      </Badge>
      {/* add loading conditional */}
      {loading && (
        <i className="ButtonLoading__loading">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </i>
      )}
      {selected && (
        <Box
          className="ButtonLoading__selected"
          sx={{
            height: '3px',
            width: '100%',
            bgcolor: 'primary.main',
            position: 'absolute',
            bottom: '0px',
          }}
        ></Box>
      )}
    </IconButton>
  );
});
