import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { withHandlingErrors } from '../../../../../handlingErrors';
import GroupEditDetailsView from './GroupEditDetailsView';
import {
  putGroup,
  deleteGroupIcon,
} from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import './index.css';

/**
 * Group edit
 * the input for this class is an object as below:
 * group = {
 *   id,
 *   name,
 *   iconURL,
 *   attributes: {},
 *  }
 * and will be transformed:
 * group = {
 *   id,
 *   name,
 *   iconURL,
 *   contacts: {},
 *   otherFields: {},
 *  }
 *  Note:
 *  The groups details update are splitted in 4 tabs, if the customer does any change in the data
 *  and click in other panel without to save, then the system will be inform him, if he really wants
 * to go to the other tab without to save the changes
 */
export class GroupEditDetails extends Component {
  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  /**
   * Wrapper group input
   * this function transform the group input to a valid object for the formular
   * edit details
   */
  static wrapperGroupInput(groupInput) {
    const { attributes } = groupInput;
    const { contacts } = attributes;
    const otherFields = [];
    Object.keys(attributes).forEach((key) => {
      if (key !== 'contacts') {
        const paar = { field: key, value: attributes[key] };
        otherFields.push(paar);
      }
    });

    if (
      otherFields.length === 0 ||
      otherFields[otherFields.length - 1].field ||
      otherFields[otherFields.length - 1].value
    ) {
      otherFields.push({
        field: '',
        value: '',
      });
    }

    const group = {
      id: groupInput.id,
      name: groupInput.name,
      iconURL: groupInput.iconURL,
      contacts,
      otherFields,
    };
    return group;
  }

  constructor(props) {
    super(props);
    this.updateFields = this.updateFields.bind(this);
    this.updateOtherFields = this.updateOtherFields.bind(this);
    this.deleteOtherFields = this.deleteOtherFields.bind(this);
    this.validationInputData = this.validationInputData.bind(this);
    this.serializeData = this.serializeData.bind(this);
    this.save = this.save.bind(this);
    this.deleteIcon = this.deleteIcon.bind(this);
    const group = GroupEditDetails.wrapperGroupInput(props.group);
    this.state = {
      group,
    };
    GroupEditDetails.fixBody();
  }

  /**
   * Update fields
   * this function will be called by input field changes
   * @param object event
   * @param string field
   */
  updateFields(event, field) {
    const { group } = this.state;
    const { setIsPossibleToChangeTab } = this.props;
    group[field] = event.target.value;
    this.setState({ group });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Update other fields
   * this function will be called by input (fields other fields) changes
   * add automatically a new element to the array othersFields (when the last
   * element is filled out)
   * @param object event
   * @param string field
   * @param integer index
   */
  updateOtherFields(event, field, index) {
    const { setIsPossibleToChangeTab } = this.props;
    const { group } = this.state;
    const { otherFields } = group;
    otherFields[index][field] = event.target.value;
    if (
      otherFields[otherFields.length - 1].field ||
      otherFields[otherFields.length - 1].value
    ) {
      otherFields.push({ field: '', value: '' });
    }
    group.otherFields = otherFields;
    this.setState({ group });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Delete other fields
   * @param object event
   * @param integer index
   */
  deleteOtherFields(event, index) {
    const { setIsPossibleToChangeTab } = this.props;
    const { group } = this.state;
    const { otherFields } = group;
    otherFields.splice(index, 1);
    group.otherFields = otherFields;
    this.setState({ group });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Validation input data
   * Validation: name should be not empty
   */
  validationInputData() {
    const { group } = this.state;
    /* Validation name */
    const isInputDataValid = !group.name === false;

    return isInputDataValid;
  }

  /**
   * Save
   * 1. call rest api to send the group data details [name, fields]
   * 2. call rest api to send the group avatar
   *
   * @params event
   */
  async save() {
    const {
      activeTransition,
      iconUpload,
      polyglot,
      openSnackbar,
      setIsPossibleToChangeTab,
      handlingErrorsApi,
    } = this.props;
    const { group } = this.state;
    try {
      if (!this.validationInputData()) {
        activeTransition();
      } else {
        await this.props.putGroup(
          group.id,
          this.serializeData(),
          iconUpload?.fileName ? iconUpload : undefined
        );
        const message = {
          text: polyglot.t('group.save_group_successful_message'),
          type: 'ok',
        };
        openSnackbar(message);
        setIsPossibleToChangeTab(true);
      }
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Delete icon
   * this function deletes/reset the current icon from the group
   */
  async deleteIcon() {
    const { polyglot, openSnackbar, resetIcon, handlingErrorsApi } = this.props;
    const { group } = this.state;
    try {
      await this.props.deleteGroupIcon(group.id);
      const message = {
        text: polyglot.t('group.delete_icon_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
      resetIcon();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Serialize data
   * this function prepares the data structure for the group
   * @return array group
   */
  serializeData() {
    let { group } = this.state;
    const attributes = {};
    Object.assign(attributes, { contacts: JSON.stringify(group.contacts) });
    const otherFields = group.otherFields.slice();

    /* Other Fields */
    otherFields.forEach((field) => {
      /* Check if the field was fillout - per default when the compenent is mounted
      the field this.state.contacts has a empty contact object */
      if (field.field !== '' && field.value !== '') {
        attributes[field.field] = field.value;
      }
    });
    group = {
      name: group.name,
      id: group.id,
    };
    if (JSON.stringify(attributes) !== '{}') {
      Object.assign(group, { attributes });
    }

    return group;
  }

  render() {
    const {
      iconUpload,
      polyglot,
      onChangeImage,
      showImageLoad,
      closeEditGroup,
    } = this.props;
    const { group } = this.state;

    return (
      <GroupEditDetailsView
        group={group}
        iconUpload={iconUpload}
        polyglot={polyglot}
        updateFields={this.updateFields}
        updateOtherFields={this.updateOtherFields}
        imageValidation={this.imageValidation}
        onChangeImage={onChangeImage}
        showImageLoad={showImageLoad}
        save={this.save}
        closeEditGroup={closeEditGroup}
        deleteIcon={this.deleteIcon}
        deleteOtherFields={this.deleteOtherFields}
      />
    );
  }
}

GroupEditDetails.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    iconURL: PropTypes.string,
    attributes: PropTypes.object,
  }).isRequired,
  showImageLoad: PropTypes.bool,
  iconUpload: PropTypes.shape({
    fileName: PropTypes.string,
    file: PropTypes.object,
    imagePreviewUrl: PropTypes.string,
  }).isRequired,
  onChangeImage: PropTypes.func.isRequired,
  resetIcon: PropTypes.func.isRequired,
  activeTransition: PropTypes.func.isRequired,
  closeEditGroup: PropTypes.func.isRequired,
  setIsPossibleToChangeTab: PropTypes.func.isRequired,
  putGroup: PropTypes.func.isRequired,
  deleteGroupIcon: PropTypes.func.isRequired,
};

GroupEditDetails.defaultProps = {
  showImageLoad: false,
};

export default connect(null, { putGroup, deleteGroupIcon })(
  withHandlingErrors(withSnackbar(withPolyglot(GroupEditDetails)))
);
