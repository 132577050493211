import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { withSnackbar } from 'stoerk-ui-components';
import { withHandlingErrors } from '../../../../../handlingErrors';
import { deleteAssociatedDevice } from '../../../../../redux/devices/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import { getNumDevices } from '../../../../../redux/devices/selectors';
import { STModal } from '../../../../commons/Modal';

/**
 * Device remove
 * This class allow to remove a device
 */
export class DeviceRemove extends Component {
  constructor(props) {
    super(props);
    this.removeAssociatedDevice = this.removeAssociatedDevice.bind(this);
  }

  /**
   * Remove associated device:
   * this function call the backend to remove a associated devices from the user account
   */
  async removeAssociatedDevice() {
    const {
      closeRemoveDevice,
      closeEditDevice,
      polyglot,
      openSnackbar,
      handlingErrorsApi,
      deviceId,
    } = this.props;
    try {
      await this.props.deleteAssociatedDevice(deviceId);
      closeRemoveDevice();
      closeEditDevice();
      /* show snack bar with successful message */
      const message = {
        text: polyglot.t('device.remove_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  render() {
    const { showRemoveDevice, polyglot, closeRemoveDevice } = this.props;
    return (
      <div>
        <STModal
          open={showRemoveDevice}
          title={polyglot.t('device.remove_dialog_title')}
          onClose={closeRemoveDevice}
          buttonActions={
            <>
              <Button onClick={closeRemoveDevice}>
                {polyglot.t('device.cancel_button_title')}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={this.removeAssociatedDevice}
              >
                {polyglot.t('device.remove_button_title')}
              </Button>
            </>
          }
        >
          <p>{polyglot.t('device.remove_dialog_text')}</p>
        </STModal>
      </div>
    );
  }
}

DeviceRemove.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  deviceId: PropTypes.string,
  showRemoveDevice: PropTypes.bool.isRequired,
  closeRemoveDevice: PropTypes.func.isRequired,
  closeEditDevice: PropTypes.func.isRequired,
  deleteAssociatedDevice: PropTypes.func.isRequired,
};

DeviceRemove.defaultProps = {
  deviceId: '',
};

const mapStateToProps = (state) => ({
  totalDevices: getNumDevices(state),
});

export default connect(mapStateToProps, { deleteAssociatedDevice })(
  withHandlingErrors(withSnackbar(withPolyglot(DeviceRemove)))
);
