import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'semantic-ui-react';
import { Button } from '@mui/material';
import moment from 'moment';
import URLSafeBase64 from 'urlsafe-base64';
import { withPolyglot } from '../../../../../i18n';
import { DATE_FORMAT } from '../../../../../util/Constants';
import { STModal } from '../../../../commons/Modal';
import { Typography } from '@mui/material';
import { Buffer } from 'buffer';

export class DeviceInformation extends Component {
  constructor(props) {
    super(props);
    this.setValueOrUnknown = this.setValueOrUnknown.bind(this);
  }

  setValueOrUnknown(field) {
    const { polyglot } = this.props;
    if (field) {
      return field;
    }

    return polyglot.t('device.info.unknown');
  }

  render() {
    const { device, polyglot, showDeviceInformation, closeDeviceInformation } =
      this.props;
    if (!device) {
      return null;
    }
    const decodedUUID = device.uuid
      ? URLSafeBase64.decode(Buffer.from(device.uuid)).toString('utf8')
      : '';
    let { version, project, deviceID } = device;
    version = this.setValueOrUnknown(version);
    project = this.setValueOrUnknown(project);
    deviceID = this.setValueOrUnknown(deviceID);
    const lastPing = moment(device.lastPing).format(DATE_FORMAT);
    return (
      <div>
        <STModal
          open={showDeviceInformation}
          title={device ? device.name : ''}
          iconURL={device ? device.iconURL : undefined}
          onClose={closeDeviceInformation}
          buttonActions={
            <Button onClick={closeDeviceInformation}>
              {polyglot.t('device.cancel_button_title')}
            </Button>
          }
        >
          <Typography variant="h6" textAlign="center" sx={{ mb: 3 }}>
            {polyglot.t('device.menu.deviceinfo')}
          </Typography>
          <Container textAlign="center">
            <Grid columns={2} container>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.uuid')}
                </Grid.Column>
                <Grid.Column width={12}>{decodedUUID}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.vpnip')}
                </Grid.Column>
                <Grid.Column width={12}>{device.ip}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.lastping')}
                </Grid.Column>
                <Grid.Column width={12}>{lastPing}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.version')}
                </Grid.Column>
                <Grid.Column width={12}>{version}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.projectid')}
                </Grid.Column>
                <Grid.Column width={12}>{project}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.deviceid')}
                </Grid.Column>
                <Grid.Column width={12}>{deviceID}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.connected')}
                </Grid.Column>
                <Grid.Column width={12}>
                  {device?.state?.connected === undefined
                    ? polyglot.t('general.unknown')
                    : device?.state?.connected + ''}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.vpnConnected')}
                </Grid.Column>
                <Grid.Column width={12}>
                  {device?.state?.vpnConnected === undefined
                    ? polyglot.t('general.unknown')
                    : device?.state?.vpnConnected + ''}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {polyglot.t('device.info.mqttConnected')}
                </Grid.Column>
                <Grid.Column width={12}>
                  {device?.state?.mqttConnected === undefined
                    ? polyglot.t('general.unknown')
                    : device?.state?.mqttConnected + ''}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </STModal>
      </div>
    );
  }
}

DeviceInformation.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  device: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    registration: PropTypes.number,
    street: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    lastPing: PropTypes.number,
    iconURL: PropTypes.string,
    state: PropTypes.object,
    ip: PropTypes.string,
    version: PropTypes.string,
    project: PropTypes.string,
    deviceID: PropTypes.string,
  }),
  showDeviceInformation: PropTypes.bool,
  closeDeviceInformation: PropTypes.func.isRequired,
};

DeviceInformation.defaultProps = {
  showDeviceInformation: false,
};

export default withPolyglot(DeviceInformation);
