import { Box, BoxProps } from '@mui/material';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import React from 'react';

interface ErrorTextProps extends BoxProps {
  children: React.ReactNode;
}

export default function ErrorText({
  children,
  sx,
  ...boxProps
}: ErrorTextProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& span': { marginLeft: 1 },
        color: (theme) => theme.palette.error.main,
        ...sx,
      }}
      component={'span'}
      {...boxProps}
    >
      <ErrorOutlineRounded fontSize={'small'} spacing={2} />
      <span>{children}</span>
    </Box>
  );
}
