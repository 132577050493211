import React from 'react';
import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../util/ValidationUtil';
import Languages from '../../../util/IETFLanguagesUtil';
import UserPicture from './UserPicture';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { UserSelfAPIResponse } from '../../../model/user/user.model';
import {
  Paper,
  Select,
  MenuItem,
  Stack,
  Button,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { IconUpload } from '../../../redux/groups/groups.model';
import TextFieldFile from '../../commons/TextFieldFile';
import ErrorText from '../../commons/ErrorText';
import { useFormik } from 'formik';

interface Props
  extends PolyglotComponentProps,
    ValidationUtilComponentProps,
    OwnProps {}
interface OwnProps {
  user: Partial<UserSelfAPIResponse & { attachmentName: any }>;
  onChangeImage(file: React.ChangeEvent<HTMLInputElement>): void;
  iconUpload: Partial<IconUpload>;
  resetAvatar(): Promise<void>;
  showImageLoad?: boolean;
  save(values: Partial<UserSelfAPIResponse>): Promise<void>;
  imageValidation(image: File): Promise<unknown>;
  onSubmitInvalid(): unknown;
  showApplications: boolean;
  listApplications: string[];
  defaultApplication: string;
}

export function UserProfileView(props: Props) {
  const {
    showImageLoad,
    polyglot,
    validationUtil,
    iconUpload,
    user,
    save,
    resetAvatar,
    onChangeImage,
    imageValidation,
    onSubmitInvalid,
    showApplications,
    listApplications,
    defaultApplication,
  } = props;

  const formik = useFormik({
    initialValues: { ...user, defaultApplication },
    validate: async (values) => {
      const errors: Record<string, string> = {};
      if (!validationUtil.validateName(values.name)) {
        errors.name = validationUtil.getErrorMessage('name');
      }
      if (!validationUtil.validateEmailAddress(values.email, true)) {
        errors.email = validationUtil.getErrorMessage('email');
      }
      if (values['attachmentName']) {
        const imageValid = await imageValidation(values['attachmentName'])
          .then(() => undefined)
          .catch((message) => message.text);
        if (imageValid) errors.attachmentName = imageValid;
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      await save(values);
    },
  });

  const onSubmit = (e: any) => {
    if (!formik.isValid) {
      onSubmitInvalid();
    }
    formik.handleSubmit(e);
  };

  const languages = new Languages(polyglot);
  let avatar = user.avatarLocation;
  if (iconUpload && iconUpload.imagePreviewUrl) {
    avatar = iconUpload.imagePreviewUrl;
  }
  return (
    <Paper sx={{ padding: 2 }}>
      <form onSubmit={onSubmit} noValidate>
        <Stack spacing={2}>
          <UserPicture
            user={user}
            iconUpload={iconUpload}
            showImageLoad={showImageLoad}
          />
          <TextFieldFile
            label={polyglot.t('group.avatar')}
            id="attachmentName"
            name="attachmentName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeImage(e);
              e.target.files &&
                e.target.files[0] &&
                formik.setFieldValue('attachmentName', e.target.files[0]);
            }}
            InputProps={{ onBlur: formik.handleBlur }}
            error={Boolean(formik.errors.attachmentName)}
            helperText={
              formik.errors.attachmentName && (
                <ErrorText>{formik.errors.attachmentName as string}</ErrorText>
              )
            }
            fullWidth
          />
          {avatar && (
            <div>
              <Button
                variant="outlined"
                color="error"
                onClick={resetAvatar}
                id="resetAvatarButton"
              >
                {polyglot.t('user.reset_avatar')}
              </Button>
            </div>
          )}

          {/* Field Name - Validation Name (not empty) */}
          <TextField
            label={polyglot.t('user.name')}
            id="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={
              formik.touched.name &&
              formik.errors.name && <ErrorText>{formik.errors.name}</ErrorText>
            }
          />

          {/* Email */}
          <TextField
            name="email"
            label={polyglot.t('user.email')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=" "
            value={formik.values.email}
            required
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={
              Boolean(formik.touched.email && formik.errors.email) && (
                <ErrorText>{formik.errors.email}</ErrorText>
              )
            }
          />

          {/* Field location */}
          <TextField
            label={polyglot.t('user.location')}
            name="location"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
          />

          {/* Field language  */}
          <FormControl fullWidth>
            <InputLabel>{polyglot.t('user.language')}</InputLabel>
            <Select
              value={formik.values.preferredLanguage || ''}
              id="language"
              name="preferredLanguage"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={polyglot.t('user.language')}
              error={Boolean(!formik.values.preferredLanguage)}
              fullWidth
            >
              {languages.getAllLanguages().map((language) => (
                <MenuItem key={language.code} value={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Field applications  */}
          {showApplications && (
            <FormControl fullWidth>
              <InputLabel>{polyglot.t('user.default_application')}</InputLabel>
              <Select
                value={formik.values.defaultApplication || ''}
                id="defaultApplication"
                name="defaultApplication"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={polyglot.t('user.defaultApplication')}
                fullWidth
              >
                {listApplications.map((application) => (
                  <MenuItem key={application} value={application}>
                    {polyglot.t(`nav.menu.${application}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            variant="contained"
            id="saveButton"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!formik.isSubmitting ? (
              polyglot.t('user.save_button_title')
            ) : (
              <CircularProgress size={20} />
            )}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}

export default withValidationUtil(withPolyglot(UserProfileView));
