import { useControlUnitsById } from './useControlUnitsById';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @param controlUnitId
 * @returns [controlUnit, loading, error]
 * 
 * @usage
´´´ts
const [controlUnits, loading, error] = useControlUnitsById("UUUID_DEVICE")
´´´
 */
export function useControlUnitById(
  deviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown
) {
  const [controlUnits, loadingControlUnits, errorControlUnits] =
    useControlUnitsById(deviceId, onError, false);
  let controlUnit = undefined;
  if (controlUnits)
    controlUnit = controlUnits.find(
      (controlUnitCurrent) => controlUnitCurrent.id === controlUnitId
    );

  return [controlUnit, loadingControlUnits, errorControlUnits] as const;
}
