export type SortDirections = 'ASC' | 'DESC';
/**
 * Compare util
 * help call to sort multidimensional array by columns (sort numbers and string)
 */
export default class SortUtil {
  /**
   * Multi sort recursive
   */
  static multisortRecursive<T>(
    a: T,
    b: T,
    columns: Array<keyof T>,
    orderBy: SortDirections[],
    index: number
  ): number {
    const direction = orderBy[index] === 'DESC' ? 1 : 0;

    const isNumeric = !Number.isNaN(+a[columns[index]] - +b[columns[index]]);
    let x: string | number = '';
    let y: string | number = '';
    if (a[columns[index]]) {
      x = isNumeric
        ? +a[columns[index]]
        : (a[columns[index]] + '').toLowerCase();
    }
    if (b[columns[index]]) {
      y = isNumeric
        ? +b[columns[index]]
        : (b[columns[index]] + '').toLowerCase();
    }

    if (x < y) {
      return direction === 0 ? -1 : 1;
    }

    if (x === y) {
      return columns.length - 1 > index
        ? SortUtil.multisortRecursive(a, b, columns, orderBy, index + 1)
        : 0;
    }

    return direction === 0 ? 1 : -1;
  }

  /**
   * Function to sort multidimensional array
   *
   * @param {array} [arr] Source array
   * @param {array} [columns] List of columns to sort
   * @param {array} [order_by] List of directions (ASC, DESC)
   * @returns {array}
   */
  static multisort<T>(
    arr: T[],
    columns?: Array<keyof T>,
    orderBy?: SortDirections[]
  ) {
    if (!arr || arr.length === 0) {
      return arr;
    }
    let columnsSort = columns;
    let orderBySort = orderBy;
    if (!columnsSort) {
      const sample = arr[0];
      columnsSort = Object.keys(sample) as Array<keyof T>;
    }
    if (!orderBySort) {
      const sample = arr[0];
      orderBySort = Object.keys(sample).map((_) => 'ASC');
    }
    /**
     * Array.sort is impure function for this reason is better use a copy of the array
     */
    const sorted = [...arr].sort((a, b) =>
      SortUtil.multisortRecursive(
        a,
        b,
        columnsSort as Array<keyof T>,
        orderBySort as SortDirections[],
        0
      )
    );
    return sorted;
  }
}
