import { Paper, Typography } from '@mui/material';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { SECONDARY_SENSORS } from '../../../../../../../../../../../../model/controlUnit/util/constants';
import { SensordataNewestMixed } from '../../../../../../../../../../../../model/monitoring/monitoring.model';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import Sensor from './Sensor';

export interface SensorsProps {
  sensorsData:
    | Record<
        SensordataNewestMixed['channel'],
        SensordataNewestMixed | undefined
      >
    | undefined;
  isOn?: boolean;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
  type: ReturnType<typeof useCurrentStateByControlUnitId>['type'];
}
export default function Sensors(props: SensorsProps) {
  const { sensorsData } = props;
  const sensorList = Object.values(sensorsData || {}).filter((sensorData) =>
    SECONDARY_SENSORS.includes(sensorData?.channel ?? '')
  ) as SensordataNewestMixed[];

  return (
    <div className="ControlUnit-Sensors-container">
      <Paper variant="outlined" sx={{ padding: 1 }}>
        <Typography variant="body1">Sensors:</Typography>
        <Card.Group>
          {sensorList.map((sensorData, i) => (
            <Sensor
              sensorData={sensorData}
              {...props}
              key={sensorData.channel}
              index={i}
            />
          ))}
        </Card.Group>
      </Paper>
    </div>
  );
}
