import { RouteProps } from 'react-router-dom';
import PlayGround from '../../../components/PlayGround/PlayGround';

const extendedRoutes: RouteProps<string>[] = [];

if (process.env.NODE_ENV === 'development')
  extendedRoutes.push({
    path: '/playground',
    render: PlayGround,
  });

export default extendedRoutes;
