import Model from '../../../model';
import { FETCH_INTEGRATION_REGISTRY } from './Types';

export const fetchIntegrationRegistry =
  (restClient, polyglot) => async (dispatch) => {
    const model = new Model(restClient, polyglot);
    const integrationRegistry = await model.getIntegrationRegistry();
    dispatch({
      type: FETCH_INTEGRATION_REGISTRY,
      payload: integrationRegistry,
    });
  };
