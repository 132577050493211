import {
  FETCH_INCIDENT_CONFIGURATION_BY_GROUP_ID,
  PUT_INCIDENT_CONFIGURATION,
} from '../actions/Types';

const initialState = {
  items: [],
  item: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INCIDENT_CONFIGURATION_BY_GROUP_ID:
      return {
        ...state,
        item: action.payload,
      };
    case PUT_INCIDENT_CONFIGURATION:
      return {
        ...state,
        item: action.payload,
      };
    default:
      return state;
  }
}
