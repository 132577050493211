import React, { Component } from 'react';
import {
  Form,
  Dropdown,
  Transition,
  Label,
  Segment,
  Loader,
  DropdownProps,
} from 'semantic-ui-react';
import { Button } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import SortUtil from '../../../util/SortUtil';
import { fetchDeviceByCloudConnectId } from '../../../redux/devices/actions/thunks';
import { putGroupAddDevices } from '../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../i18n';
import { RootState } from '../../../redux/store.model';
import Polyglot from 'node-polyglot';
import {
  Device,
  DeviceDataAPIResponse,
} from '../../../model/device/device.model';
import {
  getAllavailableDevicesToAddByGroupIdSelector,
  getGroupByPropGroupIdSelector,
} from '../../../redux/groups/selectors';
import { STModal } from '../../commons/Modal';

type Props = {
  polyglot: Polyglot;
  showGroupDeviceAssign: boolean;
  groupId?: string;
  closeGroupDeviceAssign(...args: unknown[]): unknown;
} & HandlingErrorWrappedProps &
  OpenSnackbarProps &
  ConnectedComponentProps;

type State = {
  animation: null | string;
  duration: number;
  visible: boolean;
  devicesId: string[];
  newDevices: DeviceDataAPIResponse[];
  showLoading: boolean;
  lastSearchValue: string;
  devicePreview?: DeviceDataAPIResponse;
};

/**
 * Group device assign
 * this class assign a device/devices to a group
 * the paramenter devicesGroup are the devices that are already assigned to the group
 * the function filterDevicesToBeAssign will be used to filter the devices to be assigned
 */
export class GroupDeviceAssign extends Component<Props, State> {
  /**
   * Remove devices id not valid
   * @param array devicesId
   * @return array devicesId
   */
  static removeDevicesIdNotValid(devicesId: string[]) {
    return devicesId.filter((deviceId) => deviceId && deviceId.length === 48);
  }

  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  /**
   * On open dropdown
   * this function will be called when the dropdown menu is open (devices to assign)
   */
  static onOpenDropdown() {
    const options = document.getElementsByClassName('menu transition visible');
    if (options.length > 0) {
      const height = options[0].clientHeight;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore danger mutation allowed
      document.getElementById(
        'placeHolderDropdowOpen'
      ).style.height = `${height}px`;
    }
  }

  constructor(props: Props) {
    super(props);
    GroupDeviceAssign.onOpenDropdown =
      GroupDeviceAssign.onOpenDropdown.bind(this);
    this.setDeviceId = this.setDeviceId.bind(this);
    this.assignGroupDevice = this.assignGroupDevice.bind(this);
    this.closeGroupDeviceAssign = this.closeGroupDeviceAssign.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.activeTransition = this.activeTransition.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.showSuccessfulSnackbarAndCloseWindow =
      this.showSuccessfulSnackbarAndCloseWindow.bind(this);
    this.state = {
      animation: null,
      duration: 500,
      visible: true,
      devicesId: [],
      newDevices: [] /* new devicess added using the cloudConnectId */,
      showLoading: false,
      lastSearchValue: '',
    };
  }

  componentDidMount() {
    this.onCompleteTransition();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  /**
   * Set options
   * This function uses the arrays:
   * - availableDevicesToAdd (all the existing devices in the groups without devices in the current group )
   * - newDevices (????)
   * Returns an array with the devices options ( = availableDevicesToAdd + newDevices)
   * to be displayed in the dropdown component.
   * This options are available devices to be associated to the group
   * @return array Devices for options
   */
  setOptions() {
    const { availableDevicesToAdd } = this.props;

    let devicesOptions: Array<Device | DeviceDataAPIResponse> =
      availableDevicesToAdd;
    /* add the new devices */
    const { newDevices } = this.state;
    if (newDevices && newDevices.length > 0) {
      newDevices.forEach((device) => {
        if (devicesOptions.find((d) => d.uuid === device.uuid) === undefined) {
          devicesOptions = [...devicesOptions, device];
        }
      });
    }

    devicesOptions = SortUtil.multisort(devicesOptions, ['name'], ['ASC']);
    return devicesOptions.map((device) => ({
      key: device.uuid,
      value: device.uuid,
      text: device.name,
    }));
  }

  /**
   * Set device id
   * This function is called by enter a cloud id manually
   * @param object event
   * @param object data
   */
  async setDeviceId(event: unknown, data: DropdownProps) {
    const { openSnackbar, polyglot, group } = this.props;
    try {
      let { newDevices } = this.state;
      let { devicesId } = this.state;
      const device = await this.getDeviceByCloudId(data.value as string);
      if (device) {
        /* if the device already exists in the group then a message is displayed
         * and not added to the list of options */
        if (group?.devices.find((uuid) => uuid === device.uuid)) {
          const message = {
            text: polyglot
              .t('group.devices.device_already_associated_to_group')
              .replace('#text', device.name),
            type: 'ok',
          };
          openSnackbar(message);
        } else {
          if (!newDevices.find((d) => d.uuid === device.uuid)) {
            /* Add device to the list of new devices */
            newDevices = [...newDevices, device];
          }
          /* Add device to the list of selected devices */
          if (!devicesId.find((d) => d === device.uuid)) {
            devicesId.push(device.uuid);
          } else {
            const message = {
              text: polyglot.t('group.devices.device_already_in_the_list'),
              type: 'ok',
            };
            openSnackbar(message);
          }
        }
      }
      /* since the cloud id is also added to the devicesIds, we should remove it */
      devicesId = devicesId.filter((d) => d !== data.value);
      this.setState({ devicesId, newDevices });
    } catch (error: any) {
      const message = { text: error.message, type: 'error' };
      openSnackbar(message);
      this.setState({ showLoading: false });
    }
    /* the last search value should be deleted */
    this.setState({ lastSearchValue: '' });
  }

  /**
   * Get device by cloud id
   * @param string cloudConnectID
   * @return object device
   */
  async getDeviceByCloudId(
    cloudConnectID: string
  ): Promise<DeviceDataAPIResponse | undefined> {
    const { polyglot } = this.props;
    this.setState({ showLoading: true });
    if (cloudConnectID === null || !cloudConnectID.trim()) {
      throw new Error(polyglot.t('error.empty_ccid'));
    }
    const devicePreview = await this.props.fetchDeviceByCloudConnectId(
      cloudConnectID.trim()
    );
    this.setState({ showLoading: false, devicePreview });
    return devicePreview;
  }

  /**
   * Select device
   * This function is called when a device is selected from the dropdown component
   * @param object eventº
   * @param object data = { value }
   */
  selectDevice(event: unknown, data: DropdownProps) {
    this.setState({
      devicesId: data.value as string[],
    });
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  /**
   * Associate group user
   * this function does a rest call to backed to associate a user to a group
   */
  async assignGroupDevice() {
    let { devicesId } = this.state;
    const { polyglot, openSnackbar, group, handlingErrorsApi } = this.props;
    let messageCloudIdDoesNotExist = null;
    /* if the user writes a cloud id but forget to press enter, then
    we should check if a valid cloud id was introduced, this value is saved in the
    state variable lastSearchValue  */
    if (
      this.state.lastSearchValue.trim() ||
      // http://jira.st.local/jira/browse/P900004016-1111
      (!this.state.lastSearchValue.trim() && devicesId.length === 0)
    ) {
      /* if the searched cloud id doesn't exist, then the rest api throw an error,
      this error will be catched here, but still the other devices will be added */
      try {
        const device = await this.getDeviceByCloudId(
          this.state.lastSearchValue
        );
        // Empty cloud id
        if (!device) return;
        if (group?.devices.find((uuid) => uuid === device?.uuid)) {
          const message = {
            text: polyglot
              .t('group.devices.device_already_associated_to_group')
              .replace('#text', device.name || ''),
            type: 'ok',
          };
          openSnackbar(message);
        } else {
          devicesId = [...devicesId, device.uuid];
        }
      } catch (error: any) {
        messageCloudIdDoesNotExist = `${polyglot.t('device.connect_id')}: ${
          this.state.lastSearchValue
        }: ${error.message}`;
        if (devicesId.length === 0) {
          openSnackbar({ text: messageCloudIdDoesNotExist, type: 'feedback' });
        }
      }
      this.setState({ lastSearchValue: '' });
    }

    if (devicesId.length === 0) {
      this.activeTransition();
    } else {
      try {
        this.setState({ showLoading: true });
        /* before adding the devices to the group, it must be checked that all the uuid are valid */
        devicesId = GroupDeviceAssign.removeDevicesIdNotValid(devicesId);
        if (devicesId.length === 0) {
          throw polyglot.t('error.unknown_ccid');
        }
        /* remove duplicates before the function putGroupAddDevices is called */
        await this.props.putGroupAddDevices(this.props.groupId || '', [
          ...new Set(devicesId),
        ]);
        /* show snack bar with successful message */
        const message = {
          text:
            devicesId.length > 1
              ? polyglot.t('group.devices.assign_several_successful_message')
              : polyglot.t('group.devices.assign_successful_message'),
          type: 'ok',
        };
        /* if there is an error because a wrong cloud connect id, then it will be shown first */
        if (messageCloudIdDoesNotExist) {
          openSnackbar({ text: messageCloudIdDoesNotExist, type: 'feedback' });
          setTimeout(() => {
            this.showSuccessfulSnackbarAndCloseWindow(message);
          }, 1500);
        } else {
          this.showSuccessfulSnackbarAndCloseWindow(message);
        }
      } catch (error) {
        handlingErrorsApi(error);
      }
    }
    this.setState({ showLoading: false });
  }

  /**
   * Show successful snackbar and close window
   * @param object message
   */
  showSuccessfulSnackbarAndCloseWindow(message: {
    text: string;
    type: string;
  }) {
    const { openSnackbar } = this.props;
    openSnackbar(message);
    this.closeGroupDeviceAssign();
  }

  /**
   * Close group device assign
   */
  closeGroupDeviceAssign() {
    const { closeGroupDeviceAssign } = this.props;
    this.setState({ devicesId: [] });
    closeGroupDeviceAssign();
  }

  render() {
    const { showGroupDeviceAssign, polyglot } = this.props;
    const { animation, duration, visible, devicesId, showLoading } = this.state;

    const options = this.setOptions();
    return (
      <div>
        <Transition
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //  @ts-ignore leave with that
          animation={animation}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <STModal
            open={showGroupDeviceAssign}
            // SemanticDropDown popup
            sx={{
              '&& .MuiDialogContent-root,.MuiPaper-root': {
                overflowY: 'initial',
              },
            }}
            id="modalAddDevice"
            title={polyglot.t('group.devices.assign_dialog_title')}
            onClose={this.closeGroupDeviceAssign}
            buttonActions={
              <>
                <Button type="button" onClick={this.closeGroupDeviceAssign}>
                  {polyglot.t('group.cancel_button_title')}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={this.assignGroupDevice}
                >
                  {polyglot.t('group.devices.assign_device_button_title')}
                </Button>
              </>
            }
          >
            <Form className="formular-material-design">
              {showLoading && <Loader active />}
              <Form.Field>
                <Dropdown
                  id="dropdownDevices"
                  placeholder={polyglot.t('group.devices.device')}
                  multiple
                  search
                  selection
                  allowAdditions
                  scrolling
                  value={devicesId}
                  options={options}
                  onSearchChange={(event, data) => {
                    this.setState({ lastSearchValue: data.searchQuery });
                  }}
                  onAddItem={(event, data) => this.setDeviceId(event, data)}
                  onChange={(event, data) => this.selectDevice(event, data)}
                  noResultsMessage={polyglot.t(
                    'group.devices.no_devices_to_assign'
                  )}
                  additionLabel={`${polyglot.t(
                    'group.devices.enter_cloud_connect_id'
                  )} : `}
                  className="dropdown-fix-height"
                  onOpen={GroupDeviceAssign.onOpenDropdown}
                />
                <Label pointing>
                  {polyglot.t(
                    'group.devices.select_device_or_insert_cloud_connect_id'
                  )}
                </Label>
              </Form.Field>
              <Segment basic id="placeHolderDropdowOpen" />
            </Form>
          </STModal>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: { groupId?: string }) => ({
  availableDevicesToAdd: getAllavailableDevicesToAddByGroupIdSelector(
    state,
    props
  ),
  group: getGroupByPropGroupIdSelector(state, props),
});

const connector = connect(mapStateToProps, {
  // Not need dispatch an action
  fetchDeviceByCloudConnectId: (str: any) => () =>
    fetchDeviceByCloudConnectId(str),
  putGroupAddDevices,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withSnackbar(withPolyglot(connector(GroupDeviceAssign)))
);
