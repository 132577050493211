import {
  SET_MAINTENANCE_MESSAGE_DEVICE,
  GET_MAINTENANCE_MESSAGES_BY_DEVICE,
  UPDATE_MAINTENANCE_MESSAGE_DEVICE,
  DELETE_MAINTENANCE_MESSAGE_BY_DEVICE,
  LOADING_MAINTENANCE_MESSAGES_DEVICE,
  SET_MAINTENANCE_MESSAGE_GROUP,
  GET_MAINTENANCE_MESSAGE_BY_GROUP,
  UPDATE_MAINTENANCE_MESSAGE_GROUP,
  DELETE_MAINTENANCE_MESSAGE_BY_GROUP,
  LOADING_MAINTENANCE_MESSAGES_GROUP,
} from '../actions/Types';

const initialState = {
  devices: {},
  devicesLoading: {},
  groups: {},
  groupsLoading: {},
  newMessageId: null,
};

/**
 * Merge messages device
 * This function update a message
 * @param array messagesDevice
 * @param object messageUpdated
 * @param string messageId
 * @return array messagesDevice
 */
const mergeMessagesDevice = (messagesDevice, messageUpdated, messageId) => {
  messagesDevice.forEach((message) => {
    if (message.id === messageId) {
      Object.assign(message, messageUpdated);
    }
  });
  return messagesDevice;
};

/**
 * Merge messages group
 * This function update a message
 * @param array messagesGroup
 * @param object messageUpdated
 * @param string messageId
 * @return array messagesDevice
 */
const mergeMessagesGroup = (messagesGroup, messageUpdated, messageId) => {
  messagesGroup.forEach((message) => {
    if (message.id === messageId) {
      Object.assign(message, messageUpdated);
    }
  });
  return messagesGroup;
};

/**
 * Delete message device
 * This function deletes a message from a device
 * @param array messagesDevice
 * @param string messageId
 * @param array messages
 */
const deleteMessageDevice = (messagesDevice, messageId) => {
  const messages = [];
  messagesDevice.forEach((message) => {
    if (message.id !== messageId) {
      messages.push(message);
    }
  });
  return messages;
};

/**
 * Delete message group
 * This function deletes a message from a device
 * @param array messagesGroup
 * @param string messageId
 * @param array messages
 */
const deleteMessageGroup = (messagesGroup, messageId) => {
  const messages = [];
  messagesGroup.forEach((message) => {
    if (message.id !== messageId) {
      messages.push(message);
    }
  });
  return messages;
};

export default function reducer(state = initialState, action) {
  const devices = {};
  const groups = {};
  const devicesLoading = {};
  const groupsLoading = {};
  switch (action.type) {
    case SET_MAINTENANCE_MESSAGE_DEVICE:
      /* action.payload: { messageId, deviceId }  */
      Object.assign(devices, {}, state.devices);
      if (devices[action.payload.deviceId] !== undefined) {
        devices[action.payload.deviceId].push(action.payload.message);
      } else {
        Object.assign(devices, {
          [action.payload.deviceId]: action.payload.message,
        });
      }

      return {
        ...state,
        devices,
        newMessageId: action.payload.messageId,
      };
    case GET_MAINTENANCE_MESSAGES_BY_DEVICE:
      /* action.payload: { deviceId, messages }  */
      Object.assign(devicesLoading, {}, state.devices);
      devices[action.payload.deviceId] = action.payload.messages;
      return {
        ...state,
        devices,
      };
    case LOADING_MAINTENANCE_MESSAGES_DEVICE:
      /* action.payload: { deviceId, loading }  */
      Object.assign(devicesLoading, {}, state.devicesLoading);
      devicesLoading[action.payload.deviceId] = action.payload.loading;
      return {
        ...state,
        devicesLoading,
      };

    case UPDATE_MAINTENANCE_MESSAGE_DEVICE:
      /* action.payload: { deviceId, messageId, message } */
      Object.assign(devices, {}, state.devices);
      Object.assign(devices, {
        [action.payload.deviceId]: mergeMessagesDevice(
          devices[action.payload.deviceId],
          action.payload.message,
          action.payload.messageId
        ),
      });
      return {
        ...state,
        devices,
      };
    case DELETE_MAINTENANCE_MESSAGE_BY_DEVICE:
      /* action.payload: { deviceId, messageId } */
      Object.assign(devices, {}, state.devices);
      Object.assign(devices, {
        [action.payload.deviceId]: deleteMessageDevice(
          devices[action.payload.deviceId],
          action.payload.messageId
        ),
      });
      return {
        ...state,
        devices,
      };
    case SET_MAINTENANCE_MESSAGE_GROUP:
      /* action.payload: { messageId, groupId, message }  */
      Object.assign(groups, {}, state.groups);
      if (groups[action.payload.groupId] !== undefined) {
        groups[action.payload.groupId].push(action.payload.message);
      } else {
        Object.assign(groups, {
          [action.payload.groupId]: action.payload.message,
        });
      }
      return {
        ...state,
        groups,
        newMessageId: action.payload.messageId,
      };

    case GET_MAINTENANCE_MESSAGE_BY_GROUP:
      /* action.payload: { groupId, messages }  */
      Object.assign(groups, {}, state.groups);
      groups[action.payload.groupId] = action.payload.messages;
      return {
        ...state,
        groups,
      };
    case LOADING_MAINTENANCE_MESSAGES_GROUP:
      /* action.payload: { groupId, loading }  */
      Object.assign(groupsLoading, {}, state.groupsLoading);
      groupsLoading[action.payload.groupId] = action.payload.loading;
      return {
        ...state,
        groupsLoading,
      };

    case UPDATE_MAINTENANCE_MESSAGE_GROUP:
      /* action.payload: { groupId, messageId, message } */
      Object.assign(groups, {}, state.groups);
      Object.assign(groups, {
        [action.payload.groupId]: mergeMessagesGroup(
          groups[action.payload.groupId],
          action.payload.message,
          action.payload.messageId
        ),
      });
      return {
        ...state,
        groups,
      };

    case DELETE_MAINTENANCE_MESSAGE_BY_GROUP:
      /* action.payload: { groupId, messageId } */
      Object.assign(groups, {}, state.groups);
      Object.assign(groups, {
        [action.payload.groupId]: deleteMessageGroup(
          groups[action.payload.groupId],
          action.payload.messageId
        ),
      });
      return {
        ...state,
        groups,
      };

    default:
      return state;
  }
}
