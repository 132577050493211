import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Grid, Image } from 'semantic-ui-react';
import {
  STATUS_OK,
  STATUS_ALERT,
  STATUS_OK_INPROGRESS,
  STATUS_ALERT_INPROGRESS,
} from '../../Constants';
import GroupIconDefault from '../../../../../assets/GroupIconDefaultLandscape.svg';
import ModelDevices from '../../../../../model/device/Model';
import './index.css';
import DeviceIcon from '../../../../../assets/IconST7Landscape.svg';
import { withPolyglot } from '../../../../../i18n';

const cssGroupsStatus = {};
cssGroupsStatus[STATUS_OK] = 'ok';
cssGroupsStatus[STATUS_ALERT] = 'alert';
cssGroupsStatus[STATUS_OK_INPROGRESS] = 'ok_inprogress';
cssGroupsStatus[STATUS_ALERT_INPROGRESS] = 'alert_inprogress';

/**
 * Groups overview grid
 *
 * devicesTotal: this parameter will be used to show/create the group mydevices,
 * but only by the group of the root
 */
function GroupMyDeviceOverviewGrid(props) {
  const { devices } = props;
  const status = ModelDevices.getStatusByDevicesList(devices);
  return (
    <Card key="myDevices" className={`group ${cssGroupsStatus[status]}`}>
      <Card.Content />

      <Card.Content textAlign="center" className="image-group link no-border">
        <Link to="/mydevices">
          <img
            src={GroupIconDefault}
            className="image"
            alt={props.polyglot.t('group.icon')}
          />
        </Link>
      </Card.Content>

      <Card.Content className="description" textAlign="center">
        <Grid>
          <Grid.Row columns="1" textAlign="center">
            <Grid.Column
              textAlign="center"
              verticalAlign="middle"
              className="link"
            >
              <Link to="/mydevices">
                {props.polyglot.t('group.my_devices.name')}
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="left" verticalAlign="middle">
              {devices && devices.length > 0 && (
                <div>
                  <Image
                    src={DeviceIcon}
                    size="mini"
                    inline
                    label={{
                      content: devices.length,
                      color: 'red',
                      size: 'mini',
                      floating: true,
                      circular: true,
                      className: 'label-quantity-device',
                    }}
                    className="icon-devices"
                  />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
}

GroupMyDeviceOverviewGrid.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string,
      registration: PropTypes.number,
      street: PropTypes.string,
      zipcode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      lastPing: PropTypes.number,
      iconURL: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
};

export default withPolyglot(GroupMyDeviceOverviewGrid);
