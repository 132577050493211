import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';
import * as callTypes from './CallTypes';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
export default class NotificationsCalls {
  readonly backendUserNotificationServiceURL =
    new Util().getUserNotificationServiceURL();

  constructor(private restClient = restClientHttp) {}

  async getUnreadNotifications() {
    const url = `${this.backendUserNotificationServiceURL}/unread`;
    return this.restClient.callPromise(
      {
        url,
        method: 'GET',
      },
      callTypes.getUnreadNotifications
    );
  }

  async getNotifications() {
    const url = `${this.backendUserNotificationServiceURL}/`;
    return this.restClient.callPromise(
      {
        url,
        method: 'GET',
      },
      callTypes.getNotifications
    );
  }

  /**
   * Set notification read status
   * @param number timestamp
   * @param string status
   */
  async setNotificationReadStatus(timestamp: number, status: boolean) {
    const url = `${this.backendUserNotificationServiceURL}/${timestamp}?read=${status}`;
    return this.restClient.callPromise(
      {
        url,
        method: 'PUT',
      },
      callTypes.setNotificationReadStatus
    );
  }

  /**
   * Set all user notifications read status
   * @param bool status
   */
  async setAllNotificationsReadStatus(status: boolean) {
    const url = `${this.backendUserNotificationServiceURL}/all?read=${status}`;
    return this.restClient.callPromise(
      {
        url,
        method: 'PUT',
      },
      callTypes.setAllNotificationsReadStatus
    );
  }
}
