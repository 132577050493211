/* eslint-disable eqeqeq */
import Polyglot from 'node-polyglot';
import {
  TYPE_CYCLE,
  TYPE_DEFROST,
  TYPE_DIRECT,
  TYPE_DOOR_HEATER,
  TYPE_FLASHCOOL,
  TYPE_FLASHFREEZE,
  TYPE_GLASS_HEATER,
  TYPE_HUMIDITY,
  TYPE_KNIFE_WIPER,
  TYPE_LIGHT,
  TYPE_SAVE_ENERGY,
  TYPE_SETPOINT,
  TYPE_SETPOINT_H,
  TYPE_SETPOINT_P1,
  TYPE_SETPOINT_P2,
  TYPE_SETPOINT_T,
  TYPE_SUPERFROST,
  TYPE_TURN_ON_OFF,
} from 'stoerk-ui-components';
import { useCurrentStateByControlUnitId } from '../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { ActionExtendedValue } from './mergeActionMetadataAndEvent';

/**
 * Action (actions readonly: false) mapping
 * @param object action
 * @param bool showTooltips
 * @param object polyglot
 * @param function onClickAction
 * @param object controlUnit: this variable is used to get if the control unit is on
 *   or off: if the control unit is off all the actions (except the on/off)
 *   should be disabled
 * @param bool displayDisableButtons : if this variable is true then the disable buttons
 *   will be shown
 * @return react component statusButton
 */
export const actionViewMapping = (
  action: ActionExtendedValue,
  polyglot: Polyglot,
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'],
  showTooltips = true,
  displayDisableButtons = false,
  showLabel = true
) => {
  let key = 'UnknownButton';
  let label = 'UnknownButton';
  let type: string | undefined;
  let tooltip = 'UnknownButton';
  let messageOn = '';
  let messageOff = '';
  let setpoint: number | undefined;
  // FIXME: be sure that enable and changable is the same
  const disable = !action.value && !action.metadata.changeable;
  const active = parseInt(action.value ?? '0');

  if (!displayDisableButtons) {
    // FIXME: be sure that enable and changable is the same
    if (!action.metadata.changeable) {
      return undefined;
    }
  }

  // is invalid?
  if (action.state !== 0) {
    return undefined;
  }

  /* turn on off */
  if (action.metadata.id === 'On') {
    const number = action.metadata.id.substring(
      'On'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_turn_on_off')} ${number}`;

    key = 'StatusTurnOnOff';
    type = TYPE_TURN_ON_OFF;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_turn_on')
      : polyglot.t('device.controlUnit.tooltip.status_turn_off');

    messageOn = polyglot.t('device.controlUnit.message_cu_turn_on');
    messageOff = polyglot.t('device.controlUnit.message_cu_turn_off');
  }

  /* Defrost */
  if (action.metadata.id.search(/Defrost/i) !== -1) {
    const number = action.metadata.id.substring(
      'Defrost'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_defrost')} ${number}`;

    key = 'StatusDefrost';
    type = TYPE_DEFROST;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.action_defrost_on')
      : polyglot.t('device.controlUnit.tooltip.action_defrost_off');

    messageOn = polyglot.t('device.controlUnit.message_defrost_on');
    messageOff = polyglot.t('device.controlUnit.message_defrost_off');
  }

  /* Save energy */
  if (action.metadata.id.search(/DayNightSwitch/i) !== -1) {
    const number = action.metadata.id.substring(
      'DayNightSwitch'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_eco')} ${number}`;

    key = 'StatusSaveEnergy';
    type = TYPE_SAVE_ENERGY;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_save_energy_on')
      : polyglot.t('device.controlUnit.tooltip.status_save_energy_off');

    messageOn = polyglot.t('device.controlUnit.message_save_energy_on');
    messageOff = polyglot.t('device.controlUnit.message_save_energy_off');
  }

  /* Set setPoint */
  if (action.metadata.id.search(/Set1Set2/i) !== -1) {
    const number = action.metadata.id.substring(
      'Set1Set2'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_set_setpoint')} ${number}`;

    key = 'StatusSetPoint';
    type = TYPE_SETPOINT;
    tooltip =
      action.value == '0'
        ? polyglot.t('device.controlUnit.tooltip.status_soll_parameter_s01')
        : polyglot.t('device.controlUnit.tooltip.status_soll_parameter_s02');
    setpoint = action.value == '0' ? 1 : 2;

    messageOn = polyglot.t(
      'device.controlUnit.message_change_soll_parameter_to_s02'
    );
    messageOff = polyglot.t(
      'device.controlUnit.message_change_soll_parameter_to_s01'
    );
  }

  /* Light */
  if (action.metadata.id.search(/Light/i) !== -1) {
    const number = action.metadata.id.substring(
      'Light'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_light')} ${number}`;

    key = 'StatusLight';
    type = TYPE_LIGHT;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_light_on')
      : polyglot.t('device.controlUnit.tooltip.status_light_off');

    messageOn = polyglot.t('device.controlUnit.message_light_turn_on');
    messageOff = polyglot.t('device.controlUnit.message_light_turn_off');
  }

  /* GlassHeater */
  if (action.metadata.id.search(/GlassHeater/i) !== -1) {
    const number = action.metadata.id.substring(
      'GlassHeater'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_glass_heater')} ${number}`;

    key = 'StatusGlassHeater';
    type = TYPE_GLASS_HEATER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_glass_heater_on')
      : polyglot.t('device.controlUnit.tooltip.status_glass_heater_off');

    messageOn = polyglot.t('device.controlUnit.message_glass_heater_on');
    messageOff = polyglot.t('device.controlUnit.message_glass_heater_off');
  }

  /* Knife wiper */
  if (action.metadata.id.search(/KnifeWiper/i) !== -1) {
    const number = action.metadata.id.substring(
      'KnifeWiper'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_knife_wiper')} ${number}`;

    key = 'StatusKnifeWiper';
    type = TYPE_KNIFE_WIPER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_knife_wiper_on')
      : polyglot.t('device.controlUnit.tooltip.status_knife_wiper_off');

    messageOn = polyglot.t('device.controlUnit.message_knife_wiper_on');
    messageOff = polyglot.t('device.controlUnit.message_knife_wiper_off');
  }

  /* Door heater */
  if (action.metadata.id.search(/DoorHeater/i) !== -1) {
    const number = action.metadata.id.substring(
      'DoorHeater'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_door_heater')} ${number}`;

    key = 'StatusDoorHeater';
    type = TYPE_DOOR_HEATER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_door_heater_on')
      : polyglot.t('device.controlUnit.tooltip.status_door_heater_off');

    messageOn = polyglot.t('device.controlUnit.message_door_heater_on');
    messageOff = polyglot.t('device.controlUnit.message_door_heater_off');
  }

  /* Super frost */
  if (action.metadata.id.search(/SuperFrost/i) !== -1) {
    const number = action.metadata.id.substring(
      'SuperFrost'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_superfrost')} ${number}`;

    key = 'StatusSuperFrost';
    type = TYPE_SUPERFROST;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_superfrost_on')
      : polyglot.t('device.controlUnit.tooltip.status_superfrost_off');

    messageOn = polyglot.t('device.controlUnit.message_superfrost_on');
    messageOff = polyglot.t('device.controlUnit.message_superfrost_off');
  }

  /* Humidity */
  if (action.metadata.id.search(/Humidity/i) !== -1) {
    const number = action.metadata.id.substring(
      'Humidity'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_humidity')} ${number}`;

    key = 'StatusHumidity';
    type = TYPE_HUMIDITY;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_humidity_on')
      : polyglot.t('device.controlUnit.tooltip.status_humidity_off');

    messageOn = polyglot.t('device.controlUnit.message_humidity_on');
    messageOff = polyglot.t('device.controlUnit.message_humidity_off');
  }

  /* Flashfreeze */
  if (action.metadata.id.search(/Flashfreeze/i) !== -1) {
    const number = action.metadata.id.substring(
      'Flashfreeze'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_flashfreeze')} ${number}`;

    key = 'StatusFlashfreeze';
    type = TYPE_FLASHFREEZE;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_flashfreeze_on')
      : polyglot.t('device.controlUnit.tooltip.status_flashfreeze_off');

    messageOn = polyglot.t('device.controlUnit.message_flashfreeze_on');
    messageOff = polyglot.t('device.controlUnit.message_flashfreeze_off');
  }

  /* Flashcool */
  if (action.metadata.id.search(/Flashcool/i) !== -1) {
    const number = action.metadata.id.substring(
      'Flashcool'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_flashcool')} ${number}`;

    key = 'StatusFlashcool';
    type = TYPE_FLASHCOOL;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_flashcool_on')
      : polyglot.t('device.controlUnit.tooltip.status_flashcool_off');

    messageOn = polyglot.t('device.controlUnit.message_flashcool_on');
    messageOff = polyglot.t('device.controlUnit.message_flashcool_off');
  }

  if (action.metadata.id === TYPE_SETPOINT_P1) {
    const number = action.metadata.id.substring(
      TYPE_SETPOINT_P1.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_setpoint_p1')} ${number}`;

    key = 'StatusSetPointP1';
    type = TYPE_SETPOINT_P1;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_p1_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_p1_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_p1_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_p1_off');
  }

  if (action.metadata.id === TYPE_SETPOINT_P2) {
    const number = action.metadata.id.substring(
      TYPE_SETPOINT_P2.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_setpoint_p2')} ${number}`;

    key = 'StatusSetPointP2';
    type = TYPE_SETPOINT_P2;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_p2_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_p2_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_p2_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_p2_off');
  }

  if (action.metadata.id === TYPE_SETPOINT_T) {
    const number = action.metadata.id.substring(
      TYPE_SETPOINT_T.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_setpoint_t')} ${number}`;

    key = 'StatusSetPointT';
    type = TYPE_SETPOINT_T;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_t_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_t_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_t_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_t_off');
  }

  if (action.metadata.id === TYPE_SETPOINT_H) {
    const number = action.metadata.id.substring(
      TYPE_SETPOINT_H.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_setpoint_h')} ${number}`;

    key = 'StatusSetPointH';
    type = TYPE_SETPOINT_H;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_h_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_h_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_h_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_h_off');
  }

  if (action.metadata.id === TYPE_CYCLE) {
    const number = action.metadata.id.substring(
      TYPE_CYCLE.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_cycle')} ${number}`;

    key = 'StatusCycle';
    type = TYPE_CYCLE;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_cycle_on')
      : polyglot.t('device.controlUnit.tooltip.status_cycle_off');

    messageOn = polyglot.t('device.controlUnit.message_cycle_on');
    messageOff = polyglot.t('device.controlUnit.message_cycle_off');
  }

  if (action.metadata.id === TYPE_DIRECT) {
    const number = action.metadata.id.substring(
      TYPE_DIRECT.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ??
      `${polyglot.t('device.controlUnit.button_title_direct')} ${number}`;

    key = 'StatusDirect';
    type = TYPE_DIRECT;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_direct_on')
      : polyglot.t('device.controlUnit.tooltip.status_direct_off');

    messageOn = polyglot.t('device.controlUnit.message_direct_on');
    messageOff = polyglot.t('device.controlUnit.message_direct_off');
  }
  const found = !!type;
  if (found) {
    return {
      key,
      label: showLabel ? label : undefined,
      type,
      tooltip: showTooltips ? tooltip : undefined,
      messageOn,
      messageOff,
      setpoint,
      disable,
      active,
    };
  }

  return undefined;
};
