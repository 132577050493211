import { Box, InputAdornment, TextField } from '@mui/material';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { UiMessage } from 'stoerk-ui-components';
import { useCUParameters } from '../../../../../../../../../../../redux/CUParameters/hooks/useCUParameters';
import { QueryParamControlUnitPage } from '../CUPage.model';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../../../../handlingErrors';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../i18n';
import useSearchComposedParameters from './hooks/useSearchComposedParameters';
import ParameterLevel from './ParameterLevel';

interface ControlUnitParametersPageProps
  extends HandlingErrorWrappedProps,
    PolyglotComponentProps {}
export function ControlUnitParametersPageSimple(
  props: ControlUnitParametersPageProps
) {
  const { polyglot } = props;
  const match = useRouteMatch<QueryParamControlUnitPage>();
  const {
    params: { deviceId = '', controlUnitId = '' },
  } = match;
  const { composedParameters, loading, error } = useCUParameters(
    deviceId,
    controlUnitId,
    props.handlingErrorsApi
  );

  const [searchText, setSearchText] = useState('');
  const composedParametersFiltered = useSearchComposedParameters(
    composedParameters,
    searchText
  );
  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchText(event.target.value);

  if (loading)
    return (
      <Box textAlign={'center'} margin={2}>
        <Loader active inline />
      </Box>
    );
  if (error)
    return (
      <UiMessage attached icon="warning" header="" content={error?.message} />
    );

  if (composedParameters.length === 0)
    return (
      <UiMessage
        attached
        icon="warning"
        header=""
        content={polyglot.t('device.parameters.empty')}
      />
    );

  return (
    <div className="ControlUnitParametersPage">
      <Box marginY={2}>
        <TextField
          label={polyglot.t('device.parameters.search')}
          type="search"
          onChange={onChangeSearch}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {composedParametersFiltered.map((parameterLevel) => (
        <ParameterLevel
          key={parameterLevel.id}
          parameterLevel={parameterLevel}
          searchText={searchText}
        />
      ))}
    </div>
  );
}

export default withPolyglot(
  withHandlingErrors(ControlUnitParametersPageSimple)
);
