import React, { Component } from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { UiMessage, withSnackbar } from 'stoerk-ui-components';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Util, { saveLocalStorage, getValueLocalStore } from '../../../util/Util';
import BrowserUtil from '../../../util/BrowserUtil';
import SortUtil from '../../../util/SortUtil';
import ConnectGroupsOverview from './Overview/index';
import ConnectDeviceAdd from './Devices/components/DeviceAdd';
import ConnectGroupAdd from './GroupAdd';
import ConnectGroupDeviceAssign from './GroupDeviceAssign';
import ConnectDevices from './Devices';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import ConnectTopBarMenu, {
  VIEW_GRID,
  VIEW_LIST,
  VIEW_GRID_SMALL,
  VIEW_MAP,
  SORT_FIELD_NAME,
  SORT_FIELD_STATUS,
  ASC,
  DESC,
} from '../../commons/topBarMenu';
import ButtonAdd from '../../commons/buttonAdd';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import './index.css';
import {
  getDevicesFromGroupSelectedByQueryParamSelector,
  getGroups,
  getGroupSelectedByQueryParamSelector,
  isGroupsLoading,
} from '../../../redux/groups/selectors';
import { isAssociatedDevicesLoading } from '../../../redux/devices/selectors';
import { RootState } from '../../../redux/store.model';
import { getDicNumNewIncidentsByGroupSelected } from '../../../redux/incidents/selectors';
import { ConnectedInviteUserModal } from './InviteUser';

type SortDirection = typeof DESC | typeof ASC;
type SortFiled = typeof SORT_FIELD_NAME | typeof SORT_FIELD_STATUS;
const browserUtil = new BrowserUtil();
const util = new Util();
const showViewGroupsAndDevicesKey = util.getShowViewGroupsAndDevicesKey();
const sortGroupsDevicesFieldKey = util.getSortGroupsDeviceFieldKey();
const sortGroupsDevicesDirectiondKey = util.getSortGroupsDeviceDirectionKey();

type Props = ConnectedComponentProps &
  HandlingErrorWrappedProps &
  PolyglotComponentProps &
  RightsUserUtilComponentProps &
  OpenSnackbarProps &
  RouteComponentProps<{ groupId: string }>;

type State = {
  view: 'viewList' | 'viewGrid' | 'viewGridSmall' | 'viewMap' | undefined;
  sortDirections: Record<string, SortDirection>;
  sortField: SortFiled;
  showAddDevice: boolean;
  showAddGroup: boolean;
  showAddUser?: boolean;
  showGroupDeviceAssign: boolean;
  showMessageNoDeviceNoGroupsNoRights: boolean;
  message?: string | null;
  /** value of input search */
  value?: string;
  routesGroupsDeviceAlreadyAdded: string[];
};

/**
 * Class group manager
 * this class is responsible for managing the hierarchy of groups and associated devices
 */
export class GroupManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.loadData = this.loadData.bind(this);
    this.openAddDevice = this.openAddDevice.bind(this);
    this.closeAddDevice = this.closeAddDevice.bind(this);
    this.openAddGroup = this.openAddGroup.bind(this);
    this.closeAddGroup = this.closeAddGroup.bind(this);
    this.openGroupDeviceAssign = this.openGroupDeviceAssign.bind(this);
    this.closeGroupDeviceAssign = this.closeGroupDeviceAssign.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    this.sort = this.sort.bind(this);

    /* get the sort  used by  the user in the last session */
    let sortField = getValueLocalStore(sortGroupsDevicesFieldKey) as SortFiled;
    if (!sortField) {
      sortField = SORT_FIELD_NAME;
    }
    /* get the sort direction used by the users in the last session
     */
    const sortDirectionStorage = getValueLocalStore(
      sortGroupsDevicesDirectiondKey
    );
    const sortDirection: SortDirection = sortDirectionStorage
      ? (sortDirectionStorage as SortDirection)
      : ASC;

    const sortDirections: Record<string, SortDirection> = {};
    sortDirections[SORT_FIELD_NAME] =
      sortField === SORT_FIELD_NAME ? sortDirection : ASC;
    sortDirections[SORT_FIELD_STATUS] =
      sortField === SORT_FIELD_STATUS ? sortDirection : ASC;

    /* get the view that has used the user */
    let view = getValueLocalStore(showViewGroupsAndDevicesKey) as State['view'];
    if (!view) {
      view = VIEW_LIST;
    }
    this.state = {
      view,
      sortDirections,
      sortField,
      showAddDevice: false,
      showAddGroup: false,
      showGroupDeviceAssign: false,
      showMessageNoDeviceNoGroupsNoRights: false,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    };
  }

  async componentDidMount() {
    const { activatedLoadingGroupsDevices } = this.props;
    if (!activatedLoadingGroupsDevices) {
      await this.loadData();
    }
  }

  /**
   * Load buttons to display
   */
  loadButtonsToDisplay() {
    const { match, rightsUserUtil } = this.props;
    const { groupId } = match.params;
    let showButtonAddDevice = false;
    let showButtonAddGroup = false;
    if (!groupId) {
      /* If the user are in the high group level we should check in the masterScope if
      the user has the right Authorized User */
      showButtonAddGroup = rightsUserUtil.isAuthorizedUser();
      showButtonAddDevice = showButtonAddGroup;
    } else {
      showButtonAddDevice =
        rightsUserUtil.hasRightsToAssignGroupDevice(groupId);
      showButtonAddGroup = rightsUserUtil.hasRightsToUpdateGroup(groupId);
    }
    return { showButtonAddDevice, showButtonAddGroup };
  }

  /**
   * Load data: this function calls the backend to get the data from groups
   * devices. only when the call are done, the page will be rendered
   */
  async loadData() {
    try {
      this.setState({
        showMessageNoDeviceNoGroupsNoRights: true,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Change view
   * this function is called when the view icons are click
   * @param object event
   * @param string view
   */
  changeView(event: unknown, view: State['view']) {
    /* the selected view, will be saved into the localstore and cookie */
    saveLocalStorage(showViewGroupsAndDevicesKey, view, '/');
    this.setState({ view });
  }

  openAddDevice() {
    this.setState({
      showAddDevice: true,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    });
  }

  closeAddDevice(message = null, routesGroupsDeviceAlreadyAdded = []) {
    this.setState({
      showAddDevice: false,
      message,
      routesGroupsDeviceAlreadyAdded,
    });
  }

  openAddGroup() {
    this.setState({
      showAddGroup: true,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    });
  }

  closeAddGroup() {
    this.setState({ showAddGroup: false });
  }

  openAddUser = () => {
    this.setState({
      showAddUser: true,
      message: null,
    });
  };

  closeAddUser = () => {
    this.setState({ showAddUser: false });
  };

  onSuccessAddUser = () => {
    const { polyglot, openSnackbar } = this.props;
    openSnackbar({
      text: polyglot.t('group.users.assign_several_successful_message'),
      type: 'ok',
    });
    this.closeAddUser();
  };

  /**
   * Open group devices assign
   */
  openGroupDeviceAssign() {
    this.setState({
      showGroupDeviceAssign: true,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    });
  }

  closeGroupDeviceAssign() {
    this.setState({ showGroupDeviceAssign: false });
  }

  /**
   * Sort field
   * This funciton set the state variables sortField and sortDirections
   * @param string sortField
   */
  sort(sortField: SortFiled) {
    const { sortDirections } = this.state;
    sortDirections[sortField] = sortDirections[sortField] === ASC ? DESC : ASC;
    saveLocalStorage(sortGroupsDevicesFieldKey, sortField, '/');
    saveLocalStorage(
      sortGroupsDevicesDirectiondKey,
      sortDirections[sortField],
      '/'
    );
    this.setState({
      sortField,
      sortDirections,
    });
  }

  render() {
    const {
      sortField,
      sortDirections,
      value,
      showMessageNoDeviceNoGroupsNoRights,
      showAddGroup,
      showAddUser,
      showGroupDeviceAssign,
      showAddDevice,
      message,
      routesGroupsDeviceAlreadyAdded,
    } = this.state;
    let { view } = this.state;
    const {
      match,
      loadingGroups,
      loadingDevices,
      featureToggle,
      polyglot,
      history,
      incidentsGroups,
    } = this.props;
    const { groups, group, devices } = this.props;

    if (view === VIEW_MAP) {
      if (featureToggle.map) {
        history.push('/map');
        return null;
      } else {
        view = VIEW_LIST;
      }
    }

    const { groupId } = match.params;

    const { showButtonAddDevice, showButtonAddGroup } =
      this.loadButtonsToDisplay();

    const showAddButton = !!showButtonAddGroup || !!showButtonAddDevice;
    const menuButtonAdd = [];
    let onClickButtonAdd = null;

    if (showButtonAddDevice) {
      menuButtonAdd.push({
        key: polyglot.t('device.add'),
        text: polyglot.t('device.add'),
        onClick: groupId ? this.openGroupDeviceAssign : this.openAddDevice,
      });
    }

    if (showButtonAddGroup) {
      menuButtonAdd.push({
        key: polyglot.t('group.add'),
        text: polyglot.t('group.add'),
        onClick: this.openAddGroup,
      });
    }
    if (showButtonAddGroup) {
      menuButtonAdd.push({
        key: polyglot.t('group.users.assign_dialog_title'),
        text: polyglot.t('group.users.assign_dialog_title'),
        onClick: this.openAddUser,
      });
    }

    if (showButtonAddDevice && !showButtonAddGroup) {
      onClickButtonAdd = groupId
        ? this.openGroupDeviceAssign
        : this.openAddDevice;
    }

    if (!showButtonAddDevice && showButtonAddGroup) {
      onClickButtonAdd = this.openAddGroup;
    }

    /* if the groupId is not defined, it means that it is showing the groups from the first level */
    let groupsResults = !groupId ? groups : group?.childGroups || [];
    groupsResults = !groupsResults ? [] : groupsResults;

    let devicesResults = !groupId ? [] : devices;

    /* search */
    if (value) {
      const re = new RegExp(value, 'i');
      groupsResults = groupsResults.filter((g) => re.test(g.name));
      devicesResults = devicesResults.filter((d) => re.test(d.name || ''));
    }
    /* Sort */
    if (sortField !== undefined && sortDirections !== undefined) {
      devicesResults =
        devicesResults.length > 0
          ? SortUtil.multisort(
              devicesResults,
              [sortField],
              [sortDirections[sortField]]
            )
          : [];
      groupsResults =
        groupsResults.length > 0
          ? SortUtil.multisort(
              groupsResults,
              [sortField],
              [sortDirections[sortField]]
            )
          : [];
    }

    return (
      <div>
        {showButtonAddGroup && (
          <ConnectGroupAdd
            groupIdParent={groupId}
            groupNameParent={group?.name}
            showAddGroup={showAddGroup}
            closeAddGroup={this.closeAddGroup}
          />
        )}
        {showButtonAddDevice && !groupId ? (
          <ConnectDeviceAdd
            showAddDevice={showAddDevice}
            closeAddDevice={this.closeAddDevice}
          />
        ) : (
          showButtonAddDevice && (
            <ConnectGroupDeviceAssign
              groupId={groupId}
              showGroupDeviceAssign={showGroupDeviceAssign}
              closeGroupDeviceAssign={this.closeGroupDeviceAssign}
            />
          )
        )}
        {showAddUser && (
          <ConnectedInviteUserModal
            open={showAddUser}
            onClose={this.closeAddUser}
            onSuccess={this.onSuccessAddUser}
          />
        )}

        <ConnectTopBarMenu
          showSearch
          onSearchChange={(event: any) =>
            this.setState({ value: event.target.value })
          }
          changeViewList={(event: unknown) => this.changeView(event, VIEW_LIST)}
          changeViewGrid={(event: unknown) => this.changeView(event, VIEW_GRID)}
          changeViewGridSmall={(event: unknown) =>
            this.changeView(event, VIEW_GRID_SMALL)
          }
          selectedView={view}
          onClickAddButton={onClickButtonAdd}
          menuAddButton={menuButtonAdd}
          showAddButton={showAddButton}
          showSortByStatus
          sort={sortDirections}
          sortField={sortField}
          sortByName={() => this.sort(SORT_FIELD_NAME)}
          sortByStatus={() => this.sort(SORT_FIELD_STATUS)}
          showViewMap={featureToggle.map}
        />
        {(message || routesGroupsDeviceAlreadyAdded.length > 0) && (
          <Grid>
            <Grid.Column width="16" textAlign="center" verticalAlign="middle">
              <UiMessage
                list={routesGroupsDeviceAlreadyAdded}
                header={message}
              />
            </Grid.Column>
          </Grid>
        )}
        <div>
          {groupsResults.length > 0 ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                id="accordion-groups-title"
                aria-controls="accordion-groups-title"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5">
                  {polyglot.t('group.groups')}
                </Typography>
              </AccordionSummary>
              <div>
                <ConnectGroupsOverview
                  groups={groupsResults}
                  incidentsGroups={incidentsGroups}
                  view={view}
                />
              </div>
            </Accordion>
          ) : (
            <ConnectGroupsOverview
              groups={groupsResults}
              incidentsGroups={incidentsGroups}
              view={view}
            />
          )}
          {devicesResults.length > 0 && (
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                id="accordion-devices-title"
                aria-controls="accordion-devices-title"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5">
                  {polyglot.t('group.devices.devices') + ' '}
                  {group ? group.name : ''}
                </Typography>
              </AccordionSummary>
              <div>
                <ConnectDevices
                  devices={devicesResults}
                  view={view}
                  groupId={groupId}
                />
              </div>
            </Accordion>
          )}
        </div>
        {/* Snackbar: brief feedback about an operation through a message at the
          bottom of the screen. */}
        <Box marginTop={2}>
          {(loadingGroups || loadingDevices) && (
            <Grid className="mt-1">
              <Grid.Column width="16" textAlign="center" verticalAlign="middle">
                <Loader active inline />
                {polyglot.t('group.loading_data_message')}
              </Grid.Column>
            </Grid>
          )}
        </Box>

        {!loadingGroups &&
          !loadingDevices &&
          showMessageNoDeviceNoGroupsNoRights &&
          !showAddButton &&
          devicesResults.length === 0 &&
          groupsResults.length === 0 && (
            <Grid>
              <Grid.Column width="16" textAlign="center" verticalAlign="middle">
                {polyglot.t('group.no_device_no_groups_and_no_rights')}
              </Grid.Column>
            </Grid>
          )}
        {browserUtil.getIsPhone() &&
          !loadingGroups &&
          !loadingDevices &&
          showAddButton &&
          devicesResults.length === 0 &&
          groupsResults.length === 0 && (
            <Grid>
              <Grid.Column width="16" textAlign="center" verticalAlign="middle">
                {polyglot.t('device.add_no_devices_message')}
              </Grid.Column>
            </Grid>
          )}
        {browserUtil.getIsPhone() &&
          !loadingGroups &&
          !loadingDevices &&
          showAddButton && (
            <ButtonAdd onClick={onClickButtonAdd} menu={menuButtonAdd} raised />
          )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string | undefined }>
) => ({
  groups: getGroups(state),
  group: getGroupSelectedByQueryParamSelector(state, props),
  incidentsGroups: getDicNumNewIncidentsByGroupSelected(state, props),
  devices: getDevicesFromGroupSelectedByQueryParamSelector(state, props),
  featureToggle: state.featureToggle,
  loadingGroups: isGroupsLoading(state),
  loadingDevices: isAssociatedDevicesLoading(state),
  activatedLoadingGroupsDevices: !!state.groups.activatedLoadingGroupsDevices,
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withSnackbar(
  withHandlingErrors(
    withRouter(withPolyglot(withUserRightUtil(connector(GroupManager))))
  )
);
