import withStyles from 'react-jss';

import Footer from '../../../../components/footer/Footer';

const styles = {
  '@global': {
    '.footer-navigation .menu': {
      background: 'rgba(75, 75, 74, 0.75) !important;',
    },
  },
};

export default withStyles(styles)(Footer);
