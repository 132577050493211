import { createSelector } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import { Group } from '../../../model/group/group.model';
import { IncidentAPIResponse } from '../../../model/incidentManager/incident.model';
import {
  getDeviceFromProps,
  getDeviceIdFromProps,
} from '../../devices/selectors';
import {
  getAllDevicesIdsOnGroupsByGroupIdQueryParamSelector,
  getAllDevicesIdsOnGroupsSelector,
  getGroups,
  getGroupsByDeviceIdByPropsSelector,
  getGroupSelectedByQueryParamSelector,
} from '../../groups/selectors';
import { RootState } from '../../store.model';

export const getQueryParams = (
  _: RootState,
  props: RouteComponentProps<{
    groupId?: string;
    deviceId?: string;
  }>
) => props.match.params;

export const getIncidentIdFromQueryParams = (
  _: RootState,
  props: RouteComponentProps<{
    incidentId?: string;
  }>
) => props.match.params.incidentId;

export const getIncidents = (state: RootState) =>
  Object.values(state.incidents.items);

export const getIncidentState = (state: RootState) => state.incidents;

export const getIncidentByIncidentIdFromQueryParam = createSelector(
  [getIncidentIdFromQueryParams, getIncidentState],
  (incidentId, incidentState) => incidentState.items[incidentId || '']
);

export const getAttachmentsFromIncidentParam = (
  state: RootState,
  props: {
    incident?: IncidentAPIResponse;
  }
) => state.incidents.attachments[props.incident?.id || ''] || [];

export const getDeviceByIncidentIdFromQueryParam = (
  state: RootState,
  props: RouteComponentProps<{
    incidentId?: string;
  }>
) => {
  const incident = getIncidentByIncidentIdFromQueryParam(state, props);
  return getDeviceFromProps(state, { deviceId: incident?.device });
};

export const getGroupByIncidentIdFromQueryParam = (
  state: RootState,
  props: RouteComponentProps<{
    incidentId?: string;
  }>
): Group | undefined => {
  const incident = getIncidentByIncidentIdFromQueryParam(state, props);
  const groups: Group[] = getGroupsByDeviceIdByPropsSelector(state, {
    deviceId: incident?.device,
  });
  if (groups.length > 0) return groups[0];
  return undefined;
};

export const getIncidentsByDeviceParamDeviceId = createSelector(
  [getDeviceIdFromProps, getIncidents],
  (deviceId, incidents) =>
    incidents.filter((incident) => incident.device === deviceId)
);

export const getIncidentById = (
  state: RootState,
  props: { incidentId: string }
) => state.incidents.items[props.incidentId];

export const getDictionaryIncidents = (state: RootState) =>
  state.incidents.items;

export const getDeviceNewPublicIncidentsFromPros = (
  state: RootState,
  props: { deviceId: string }
) => state.devices.dictionaryIncidents[props.deviceId] || [];

export const getDicDeviceNewPublicIncidentsFromPros = (state: RootState) =>
  state.devices.dictionaryIncidents;

export const getAllPublicIncidents = (state: RootState) => {
  const devicesInGroups = getAllDevicesIdsOnGroupsSelector(state);
  return Object.values(state.incidents.publicIncidentsItems).filter(
    (publicIncident) => devicesInGroups.includes(publicIncident.device)
  );
};

export const getIsLoadingAllPublicIncidents = (
  state: RootState,
  props?: unknown
): boolean =>
  Object.values(state.incidents.loadingPublicIncidentById).some(
    (loading) => loading
  );

export const getDicNumNewIncidentsByGroupSelected = createSelector(
  [
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDicDeviceNewPublicIncidentsFromPros,
  ],
  (groupsState, groupSelected, dictionaryIncidents) => {
    const groups = groupSelected ? groupSelected.childGroups : groupsState;
    const dicIncidents = groups.reduce(
      (acc, currentGroup) => ({
        ...acc,
        [currentGroup.id]: currentGroup.devices
          .map((deviceId) => dictionaryIncidents[deviceId] ?? [])
          .flatMap((deviceIncidents) => deviceIncidents).length,
      }),
      {} as Record<string, number>
    );
    return dicIncidents || {};
  }
);

export const getNumNewIncidentsByGroupSelected = createSelector(
  [
    getGroupSelectedByQueryParamSelector,
    getDicDeviceNewPublicIncidentsFromPros,
  ],
  (groupSelected, dictionaryIncidents) => {
    const devices = groupSelected?.devices ?? [];
    const numIncidents = devices
      .map((deviceId) => dictionaryIncidents[deviceId] ?? [])
      .flatMap((deviceIncidents) => deviceIncidents).length;
    return numIncidents;
  }
);

export const getIncidentsByQueryParams = createSelector(
  [
    (state: RootState) => state,
    getQueryParams,
    getAllDevicesIdsOnGroupsByGroupIdQueryParamSelector,
    getIncidents,
  ],
  (state, queryParams, devicesIds, allIncidents) => {
    if (queryParams.deviceId) {
      const incidents = getIncidentsByDeviceParamDeviceId(state, {
        deviceId: queryParams.deviceId,
      });
      return incidents;
    }
    // when is only group, get all device's incidents
    if (queryParams.groupId) {
      const incidents = devicesIds
        .map((deviceId) =>
          getIncidentsByDeviceParamDeviceId(state, { deviceId })
        )
        .flatMap((listIncidents) => listIncidents);
      return incidents;
    }
    // default all incidents
    return allIncidents;
  }
);
