import { memoize, setWith } from 'lodash';
import { ControlUnitAPIResponse } from '../../../../../../../model/controlUnit/ControlUnit.model';
import { Device } from '../../../../../../../model/device/device.model';
import { ZipDeviceControlUnit } from '../../../../../../../redux/controlUnit/controlUnit.model';

// It need one for digital and one for analog
export type CommanderMonitoringFilterLogicalObject = Record<
  Device['uuid'],
  CommanderControlUnitChannelFilterLogicalObject | undefined
>;
export type CommanderControlUnitChannelFilterLogicalObject = Record<
  ControlUnitAPIResponse['id'],
  ControlUnitChannelFilterLogicalObject | undefined
>;
type EventOrChannelId = string;
export type ControlUnitChannelFilterLogicalObject = Record<
  EventOrChannelId,
  boolean | undefined
>;

export const KEYS_CONTROL_UNITS_BY_TYPE: Record<
  'events' | 'channels',
  ('channels' | 'events')[]
> = {
  events: ['events'],
  channels: ['channels'],
};
export function getFilterLogicalObjectFromZipDeviceControlUnits(
  zipDevicesControlUnits: ZipDeviceControlUnit[],
  type: 'events' | 'channels',
  fillWith = false
) {
  const dictionary: CommanderMonitoringFilterLogicalObject = {};
  zipDevicesControlUnits.forEach((zipDeviceControlUnit) => {
    zipDeviceControlUnit.controlUnits?.forEach((controlUnit) => {
      KEYS_CONTROL_UNITS_BY_TYPE[type].forEach((keyControlIUnit) => {
        controlUnit[keyControlIUnit]?.forEach((event) => {
          setWith(
            dictionary,
            [
              zipDeviceControlUnit.deviceId,
              controlUnit.id,
              event?.id ?? event.name,
            ],
            fillWith,
            Object
          );
        });
      });
    });
  });
  return dictionary;
}

export function getNumberOfChannels(
  filterLogicalObject: CommanderMonitoringFilterLogicalObject
) {
  return Object.values(filterLogicalObject ?? {})
    .flatMap((devicesValues) => Object.values(devicesValues ?? {}))
    .flatMap((controlUnitValues) => Object.values(controlUnitValues ?? {}))
    .reduce(
      (acc, current, index, list) => {
        const currentActive = acc.active + (current ? 1 : 0);
        return {
          active: currentActive,
          total: list.length,
        };
      },

      {
        active: 0,
        total: 0,
      }
    );
}

export type ControlUnitsActiveByDevice = Record<
  Device['uuid'],
  undefined | ControlUnitAPIResponse['id'][]
>;
export const getControlUnitsActiveFromObject: (
  filterObject: CommanderMonitoringFilterLogicalObject
) => ControlUnitsActiveByDevice = memoize(
  (filterObject: CommanderMonitoringFilterLogicalObject) => {
    const dic: ControlUnitsActiveByDevice = {};
    Object.entries(filterObject).reduce(
      (acc, [keyDevice, DeviceFilterObject]) => {
        const listControlUnits = Object.entries(DeviceFilterObject || {})
          .filter(([, controlUnitObjectFilter]) =>
            Object.values(controlUnitObjectFilter || {}).some(
              (eventFilterObject) => eventFilterObject
            )
          )
          .map(([keyControlUnit]) => keyControlUnit);

        if (listControlUnits.length) acc[keyDevice] = listControlUnits;
        return acc;
      },
      dic
    );
    return dic;
  }
);
