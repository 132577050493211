import { AppStore } from '../redux/store.model';
import RestClient from './RestClient';

/**
 * We need initialize RestClient because it has a cycle dependencies
 */
export const initializeRestClient = (store: AppStore) => {
  restClient.store = store;
};
/**
 * HTTP client with many interceptors
 */
export const restClient: RestClient = new RestClient();
export { RestClient };
