import React, { Component } from 'react';
import { Menu, Message, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import BrowserUtil from '../../util/BrowserUtil';
import { withPolyglot } from '../../i18n';
import './index.css';

const browserUtil = new BrowserUtil();

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    /*
     * Fix footer bottom class= css class to fix the footer in bottom
     */
    this.fixFooterBottomClass = 'fix-bottom';

    /*
     * Footer push down =  css class used to push the footer down (space between the
     * container and footer)
     */
    this.footerPushDown = 'footer-push-down';
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    window.addEventListener('orientationchange', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('orientationchange', this.resize);
  }

  /**
   * Resize
   * this function change the position of the footer. if the container is smaller
   * as the viewport height then the footer should be not more fixed
   * also by pages <> landing page we should push down the bottom
   */
  resize() {
    const footer = document.getElementById('footer-navigation');
    if (footer === null) {
      return;
    }

    if (document.getElementsByClassName('landing-page').length > 0) {
      footer.classList.remove(this.footerPushDown);
      return;
    }

    footer.classList.add(this.footerPushDown);
    /**
     * for the browsers that not supports CSS Grid we should use the function resize.
     * if the browser support the CSS Grid will showed remove the footer propertie fix
     * Support:
     *  Mobile Tablet:
     *  => iOS Safari  >= 10.3 => It doesn't matter which browser you use on iOS as
     *     all of them use the rendering engine that comes with the iOS. That's the apple policy.
     *  => Android >= 62
     *  => Android Chrome >= 64
     *  => Android Firefox >= 57
     *  => opera for android >= 44
     *  => Edge mobile >= 16
     *  Desktop:
     *  => Chrome > 57
     *  => Opera > 44
     *  => Firefox > 52
     *  => IE >= 12  (Edge (IE 12) >= 16)
     *  => Safari >= 10.1
     */
    let CSSGridSupport = false;
    if (browserUtil.getIsiOs()) {
      /**
       * iOsVersion array : for example: [0] => 9, [1] => 3, [2] => 5 : Version 9.3.5
       */
      const iOsVersion = browserUtil.getiOsVersion();
      const iOsVersionMinor = iOsVersion[1] ? iOsVersion[1] : 0;

      if (
        browserUtil.getIsMobile() &&
        ((iOsVersion[0] === 10 && iOsVersionMinor >= 3) || iOsVersion[0] > 10)
      ) {
        CSSGridSupport = true;
      } else if (
        !browserUtil.getIsMobile() &&
        ((iOsVersion[0] === 10 && iOsVersionMinor >= 1) || iOsVersion[0] > 10)
      ) {
        CSSGridSupport = true;
      }
    } else if (
      browserUtil.getIsBrowserChrome() ||
      browserUtil.getIsBrowserFirefox() ||
      browserUtil.getIsBrowserSafari() ||
      (browserUtil.getIsBrowserIE() && browserUtil.getIEVersion() >= 12)
    ) {
      CSSGridSupport = true;
    }
    if (CSSGridSupport) {
      footer.classList.remove(this.fixFooterBottomClass);
      return;
    }

    const container = document.getElementById('container');
    if (container == null) {
      return;
    }

    const containerHeight = container.clientHeight;
    const footerHeight = footer.clientHeight;

    if (containerHeight + footerHeight >= browserUtil.getHeight()) {
      footer.classList.remove(this.fixFooterBottomClass);
    } else {
      footer.classList.add(this.fixFooterBottomClass);
    }
  }

  render() {
    this.resize();
    const {
      cookieNoticeConfirmed,
      showCookieNotice,
      location,
      polyglot,
      urlAboutUs = 'http://stoerk-tronic.com/',
    } = this.props;

    /* the id=footer-navigation is neccessary because the event resize */
    return (
      <div id="footer-navigation" className="footer-navigation">
        {/* Cookies message */}
        <Message
          icon
          onDismiss={cookieNoticeConfirmed}
          className={
            showCookieNotice && location.pathname === '/login'
              ? ''
              : 'ui hidden message'
          }
        >
          <Icon name="warning sign" size="mini" />
          <Message.Content>
            {polyglot.t('cookie_notice')}
            <Link href="/cookies" to="/cookies">
              {polyglot.t('footer.cookie_policy')}
            </Link>
          </Message.Content>
        </Message>
        <Menu className="menu" inverted borderless fluid compact>
          <Menu.Item className="menu-item">
            <a href={urlAboutUs}>{polyglot.t('footer.about_us')}</a>
          </Menu.Item>
          <Menu.Item className="menu-item">
            <Link href="/cookies" to="/cookies">
              {polyglot.t('footer.cookie_policy')}
            </Link>
          </Menu.Item>
          <Menu.Item className="menu-item">
            <Link href="/impressum" to="/impressum">
              {polyglot.t('footer.impressum')}
            </Link>
          </Menu.Item>
          <Menu.Item className="menu-item">
            <Link href="/privacy" to="/privacy">
              {polyglot.t('footer.privacy_policy')}
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

Footer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.objectOf(PropTypes.any),
    pathname: PropTypes.string,
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  cookieNoticeConfirmed: PropTypes.func.isRequired,
  showCookieNotice: PropTypes.bool.isRequired,
  urlAboutUs: PropTypes.string,
};

export default withRouter(withPolyglot(Footer));
