import { getPolyglot } from '../../i18n';
import { restClient as restClientHttp } from '../../rest';
import { ErrorRestST, RestClientFacade } from '../../rest/rest.model';
import { objectToFormURLEncoded } from '../../util/objectToFormURLEncoded';
import Util from '../../util/Util';
import {
  AuthorizeRedirectionAPIResponse,
  TokenAPIResponse,
} from './auth.model';
import * as callTypes from './CallTypes';

export const API_AUTH_ERROR_EMAIL_ALREADY_IN_USE =
  'Provided email address is already in use!';

export const API_AUTH_ERROR_EMAIL_INVALID =
  'Provided email address is invalid!';
class AuthCalls {
  restClient: RestClientFacade;
  readonly backendAuthServiceURL = new Util().getAuthServiceURL();
  readonly clientIDandSecret = new Util().getClientIDAndSecret();

  constructor(restClient = restClientHttp) {
    this.restClient = restClient;
  }

  /**
   * Refresh token
   * @param string refreshToken
   */
  async refreshToken(refreshToken: string) {
    const tokenURL = `${this.backendAuthServiceURL}/token?grant_type=refresh_token&refresh_token=${refreshToken}&redirect_uri=/&client_id=${this.clientIDandSecret.id}&client_secret=${this.clientIDandSecret.secret}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.restClient
      .callPromise<TokenAPIResponse>(
        {
          url: tokenURL,
          method: 'POST',
          headers,
          entity: objectToFormURLEncoded({}),
        },

        // use the same to get the same behaviour
        callTypes.getToken
      )
      .then((entity) => {
        const token = entity.access_token;
        const newRefreshToken = entity.refresh_token;
        const expiration = entity.expires_in;
        return { token, newRefreshToken, expiration };
      });
  }

  /**
   * Refresh token
   * @param string refreshToken
   */
  async getToken(authCode: any) {
    const tokenURL = `${this.backendAuthServiceURL}/token?grant_type=authorization_code&code=${authCode}&redirect_uri=/&client_id=${this.clientIDandSecret.id}&client_secret=${this.clientIDandSecret.secret}`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.restClient
      .callPromise<TokenAPIResponse>(
        {
          url: tokenURL,
          method: 'POST',
          headers,
          entity: objectToFormURLEncoded({}),
        },
        callTypes.getToken
      )
      .then((entity) => {
        const token = entity.access_token;
        const refreshToken = entity.refresh_token;
        const expiration = entity.expires_in;
        return { token, refreshToken, expiration };
      });
  }

  /**
   * Login
   * @param string email
   * @param string password
   */
  async login(email: string, password: string) {
    const redirectUri = `${this.backendAuthServiceURL}/redirect`;
    const authorizeUrl = `${this.backendAuthServiceURL}/authorize?response_type=code&client_id=${this.clientIDandSecret.id}&redirect_uri=${redirectUri}`;
    const body = {
      email,
      password,
    };

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.restClient
      .callPromise<AuthorizeRedirectionAPIResponse>(
        {
          url: authorizeUrl,
          method: 'POST',
          headers,
          entity: objectToFormURLEncoded(body),
        },
        callTypes.login
      )
      .then((entity) => entity.Authcode);
  }

  /**
   * Password token validation
   * @param string token
   */
  async passwordTokenValidation(token: string) {
    const url = `${this.backendAuthServiceURL}/password/token/valid?token=${token}`;
    /* the call passwordTokenValidation returns false or true but as string */
    return this.restClient.callPromise<true | false>(
      { url, method: 'GET' },

      callTypes.updatePassword
    );
  }

  /**
   * Update password
   * @param string token
   * @param string password
   */
  async updatePassword(token: string, password: string) {
    const url = `${this.backendAuthServiceURL}/password`;
    const entity = objectToFormURLEncoded({
      token,
      password,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.restClient.callPromise(
      {
        url,
        method: 'POST',
        entity,
        headers,
      },

      callTypes.updatePassword
    );
  }

  /**
   * Register client
   * @param string email
   * @param string password
   */
  async registerClient(email: string, password: string) {
    const url = `${this.backendAuthServiceURL}/register`;
    const entity = objectToFormURLEncoded({
      email,
      password,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.restClient.callPromise(
      {
        url,
        method: 'POST',
        entity,
        headers,
      },
      callTypes.registerClient
    );
  }

  /**
   * Reset Password
   * @param string email
   * @param string password
   */
  async resetPassword(email: string) {
    const url = `${this.backendAuthServiceURL}/password/resetmail?email=${email}`;
    return this.restClient.callPromise(
      { url, method: 'GET' },
      callTypes.resetPassword
    );
  }

  /**
   * Account activation
   * @param string token
   */
  async accountActivation(token: string) {
    const url = `${this.backendAuthServiceURL}/activate?token=${token}`;
    return this.restClient.callPromise(
      { url, method: 'GET' },
      callTypes.accountActivation
    );
  }

  async checkUsers(emails: string[]) {
    const url = `${this.backendAuthServiceURL}/users?users=${JSON.stringify(
      emails
    )}`;
    return this.restClient.callPromise(
      {
        url,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      callTypes.checkUsers
    );
  }

  async inviteUser(email: string) {
    const polyglot = getPolyglot();

    const url = `${this.backendAuthServiceURL}/invite`;
    return this.restClient
      .callPromise(
        {
          url,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          entity: objectToFormURLEncoded({
            email,
          }),
        },
        callTypes.checkUsers
      )
      .catch((error: ErrorRestST) => {
        let message = error.message;
        switch (error.response?.data) {
          case API_AUTH_ERROR_EMAIL_ALREADY_IN_USE:
            message = `${email}: ${polyglot.t(
              'error.api_auth_error_email_already_in_use'
            )}`;
            break;
          case API_AUTH_ERROR_EMAIL_INVALID:
            message = `${email}: ${polyglot.t(
              'error.api_auth_error_email_invalid'
            )}`;
            break;
        }
        return Promise.reject({
          ...error,
          message,
        });
      });
  }
}

export default AuthCalls;
