import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SnackbarProps, withSnackbar } from 'stoerk-ui-components';
import { Subtract } from 'utility-types';
import { ErrorRestST } from '../rest/rest.model';

export const ERROR_LOGIN_EXPIRED = 'ERROR_LOGIN_EXPIRED';
export const ERROR_ABORTED = 'ERROR_ABORTED';

export type OpenSnackbarProps = SnackbarProps;

interface HandlingErrorProps extends RouteComponentProps, OpenSnackbarProps {}
export interface HandlingErrorWrappedProps {
  handlingErrorsApi(error: ErrorRestST | unknown): Promise<void>;
}

export function withHandlingErrors<P extends HandlingErrorWrappedProps>(
  WrappedComponent: React.ComponentType<P>
): (props: Subtract<P, HandlingErrorWrappedProps>) => JSX.Element {
  const WrappedComponentWithHandlingError = (
    props: Subtract<P, HandlingErrorWrappedProps> & HandlingErrorProps
  ) => {
    const { openSnackbar, history } = props;

    const handlingErrorsApi = (error: ErrorRestST) => {
      return new Promise((resolve) => {
        const message = { text: error.message, type: 'error' };
        process.env.NODE_ENV !== 'production' && console.error(error);
        // Bypass aborts errors
        if (error?.errorId === ERROR_ABORTED) return resolve(undefined);
        openSnackbar(message);
        if (error && error.errorId === ERROR_LOGIN_EXPIRED) {
          setTimeout(() => {
            history.replace('/login');
            resolve(undefined);
          }, 4000);
        } else {
          resolve(undefined);
        }
      });
    };
    // WARN router & snackbar props forwarded
    const propsForwarded: unknown = props;
    return (
      <WrappedComponent
        {...(propsForwarded as P)}
        handlingErrorsApi={handlingErrorsApi}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore router & snackbar props forwarded
  return withRouter(withSnackbar(WrappedComponentWithHandlingError));
}
export default withHandlingErrors;
