import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Transition } from 'semantic-ui-react';
import { withSnackbar } from 'stoerk-ui-components';
import { Stack, TextField, Button } from '@mui/material';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';

import {
  fetchDeviceByCloudConnectId,
  postAssociatedDevice,
} from '../../../../../redux/devices/actions/thunks';
import {
  getAllDevicesIdsOnGroupsSelector,
  getGroups,
} from '../../../../../redux/groups/selectors';
import { withPolyglot } from '../../../../../i18n';
import { getNumDevices } from '../../../../../redux/devices/selectors';
import { DeviceDataAPIResponse } from '../../../../../model/device/device.model';
import Polyglot from 'node-polyglot';
import { RootState } from '../../../../../redux/store.model';
import GroupModel from '../../../../../model/group/Model';
import { STModal } from '../../../../commons/Modal';

type State = {
  animation: null | string;
  duration: number;
  visible: boolean;
  cloudConnectID?: string;
};

type DeviceAddProps = {
  polyglot: Polyglot;
  showAddDevice: boolean;
  closeAddDevice(...args: unknown[]): unknown;
} & RouteComponentProps &
  OpenSnackbarProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps;
export class DeviceAdd extends Component<DeviceAddProps, State> {
  constructor(props: DeviceAddProps) {
    super(props);
    this.setCloudConnectID = this.setCloudConnectID.bind(this);
    this.associateDevice = this.associateDevice.bind(this);
    this.onCompleteTransition = this.onCompleteTransition.bind(this);
    this.showSuccessfulMessage = this.showSuccessfulMessage.bind(this);
    this.getGroupsDeviceAlreadyAdded =
      this.getGroupsDeviceAlreadyAdded.bind(this);
    this.state = {
      animation: null,
      duration: 500,
      visible: true,
    };
  }

  componentDidMount() {
    this.onCompleteTransition();
  }

  onCompleteTransition() {
    this.setState({ visible: false, animation: null });
  }

  /**
   * Set cloud connect id
   * @param object event
   */
  setCloudConnectID(event: { target: { value: string } }) {
    const id = event.target.value.trim();
    this.setState({ cloudConnectID: id });
  }

  /**
   * Get groups devices already added
   * This function returns a list with the groups (including the route to it) where
   * the device is associated
   * @param int totalDevicesAdded
   * @return array routes
   */
  getGroupsDeviceAlreadyAdded(
    deviceAlreadyAdded: boolean,
    devicePreview: DeviceDataAPIResponse | undefined
  ): JSX.Element[] {
    const { groups } = this.props;
    if (!deviceAlreadyAdded) {
      return [];
    }
    const groupsIds = GroupModel.getGroupsIdByDeviceId(
      devicePreview?.uuid,
      groups
    );
    return groupsIds.map((groupId) => {
      const groupDevice = GroupModel.getGroupByGroupId(groupId, groups);
      const routeParent = GroupModel.getPathToGroup(groupDevice, groups);
      return routeParent.length ? (
        <Link to={`/devicemanager/${groupId}`}>
          {routeParent.map((r: { name: any }) => r.name).join(' • ')}
        </Link>
      ) : (
        <></>
      );
    });
  }

  /**
   * Show successful message
   * @param int totalDevicesAdded
   * @return string message
   */
  showSuccessfulMessage(
    totalDevicesAdded: number | undefined,
    devicePreview: DeviceDataAPIResponse | undefined
  ) {
    const { totalDevices, polyglot, openSnackbar } = this.props;
    const deviceAlreadyAdded = totalDevicesAdded === totalDevices;
    const text = deviceAlreadyAdded
      ? polyglot
          .t('device.add_already_added_message')
          .replace(
            '#text',
            devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
          )
      : polyglot
          .t('device.add_successful_message')
          .replace(
            '#text',
            devicePreview && devicePreview.name ? `"${devicePreview.name}"` : ''
          );
    const message = {
      text,
      type: totalDevicesAdded === totalDevices ? 'feedback' : 'ok',
    };
    openSnackbar(message);
    const list = this.getGroupsDeviceAlreadyAdded(
      deviceAlreadyAdded,
      devicePreview
    );
    if (deviceAlreadyAdded && list.length > 0)
      return polyglot
        .t('device.add_already_added_messages_with_groups')
        .replace(
          '#text',
          devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
        );
    if (deviceAlreadyAdded && list.length === 0)
      return polyglot
        .t('device.add_already_added_message')
        .replace(
          '#text',
          devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
        );
    return null;
  }

  /**
   * Associate device:
   * this function does a rest call to backed to associate a device to the user account
   */
  async associateDevice() {
    const {
      totalDevices,
      closeAddDevice,
      handlingErrorsApi,
      allDevicesIdsOnGroups,
    } = this.props;
    const { cloudConnectID } = this.state;
    if (!cloudConnectID) {
      this.activeTransition();
      return;
    }
    try {
      const devicePreview = await this.getDeviceByCloudId(cloudConnectID);
      const deviceAlreadyAdded = allDevicesIdsOnGroups.includes(
        devicePreview?.uuid || ''
      );

      await this.props.postAssociatedDevice(cloudConnectID);
      const message = this.showSuccessfulMessage(totalDevices, devicePreview);
      closeAddDevice(
        message,
        this.getGroupsDeviceAlreadyAdded(deviceAlreadyAdded, devicePreview)
      );
    } catch (error) {
      /* show snack bar with successful message */
      handlingErrorsApi(error);
    }
  }

  /**
   * Get device by cloud id
   * @param string cloudConnectID
   * @return object device
   */
  async getDeviceByCloudId(
    cloudConnectID: string
  ): Promise<DeviceDataAPIResponse | undefined> {
    const { polyglot } = this.props;
    if (cloudConnectID === null || !cloudConnectID.trim()) {
      throw new Error(polyglot.t('error.empty_ccid'));
    }
    const devicePreview = await fetchDeviceByCloudConnectId(
      cloudConnectID.trim()
    );
    return devicePreview;
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  render() {
    const { animation, duration, visible } = this.state;
    const { showAddDevice, polyglot, closeAddDevice } = this.props;
    return (
      <div>
        <Transition
          animation={animation || ''}
          duration={duration}
          visible={visible}
          onComplete={this.onCompleteTransition}
        >
          <STModal
            open={showAddDevice}
            title={polyglot.t('device.add_dialog_title')}
            onClose={() => closeAddDevice()}
            fullWidth
            maxWidth="sm"
            buttonActions={
              <>
                <Button type="button" onClick={() => closeAddDevice()}>
                  {polyglot.t('device.cancel_button_title')}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={this.associateDevice}
                >
                  {polyglot.t('device.add_button_title')}
                </Button>
              </>
            }
          >
            <Stack spacing={2}>
              <TextField
                label={polyglot.t('device.connect_id')}
                onChange={this.setCloudConnectID}
                required
              />
            </Stack>
          </STModal>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  totalDevices: getNumDevices(state),
  allDevicesIdsOnGroups: getAllDevicesIdsOnGroupsSelector(state),
  groups: getGroups(state),
});
const connector = connect(mapStateToProps, { postAssociatedDevice });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withSnackbar(withPolyglot(withRouter(connector(DeviceAdd))))
);
