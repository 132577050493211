import DeviceCalls from '../../../model/device/Calls';
import { LABEL_ALREADY_ADDED, LABEL_NEW } from '../../../model/Constants';
import { DeviceDataAPIResponse } from '../../../model/device/device.model';
import { AppDispatch, AppStore, RootState } from '../../store.model';
import { getDevicesDataById, isDeviceInAssociatedDevices } from '../selectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../device.model';
import { actionsDevices } from '../slice';
import { ErrorRestST } from '../../../rest/rest.model';
import { IconUpload } from '../../groups/groups.model';
import { chunk } from 'lodash';
import { fetchPublicIncidentsByDevicesIdsInChunks } from '../../incidents/actions/thunks';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchedDeviceData = createAsyncThunk(
  `${nameReducer}/fetchedDeviceData`,
  async (promiseArg: Promise<DeviceDataAPIResponse>) => {
    return await promiseArg;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchedDevicesData = createAsyncThunk(
  `${nameReducer}/fetchedDevicesData`,
  async (promiseArg: Promise<DeviceDataAPIResponse[]>) => {
    return await promiseArg;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchedDevicesStatus = createAsyncThunk(
  `${nameReducer}/fetchedDevicesStatus`,
  async (deviceId: string) => {
    const deviceCalls = new DeviceCalls();

    return await deviceCalls.getDeviceStatusById(deviceId);
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const loadDevicesAssociated = createAsyncThunk<
  any,
  undefined,
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/loadDevicesAssociated`,
  async (_, thunkAPI) => {
    return await thunkAPI.dispatch(fetchDevicesAssociated());
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

// --------------
// Simple thunks
// --------------
export const fetchDevicesByIds =
  (devicesIds: string[]) => async (dispatch: AppDispatch) => {
    return Promise.all([
      dispatch(fetchedDevicesDataInChunks(devicesIds)),
      Promise.all(
        devicesIds.map((deviceId) => dispatch(fetchedDevicesStatus(deviceId)))
      ),
      dispatch(fetchPublicIncidentsByDevicesIdsInChunks(devicesIds)),
    ]);
  };

/**
 * fetchedDevicesDataInChunks split the calls per 50 deviceIds per call,
 * because the call's headers would be too large if we use >100 deviceIds per call
 *
 * @param devicesIds
 * @returns chunks
 */
export const fetchedDevicesDataInChunks =
  (devicesIds: string[]) => async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();
    const chunksDeviceIds: string[][] = chunk(devicesIds, 50);
    return Promise.all(
      chunksDeviceIds.map((chunkDeviceIds) =>
        dispatch(
          fetchedDevicesData(deviceCalls.getDevicesDataByIds(chunkDeviceIds))
        )
      )
    );
  };
export const fetchDevicesByDevicesData =
  (devicesData: DeviceDataAPIResponse[]) => async (dispatch: AppDispatch) => {
    const devicesIds = devicesData.map((deviceData) => deviceData.uuid);
    return Promise.all([
      Promise.all(
        devicesIds.map((deviceId) => dispatch(fetchedDevicesStatus(deviceId)))
      ),
      dispatch(fetchPublicIncidentsByDevicesIdsInChunks(devicesIds)),
    ]);
  };

export const fetchDevicesAssociated = () => async (dispatch: AppDispatch) => {
  const deviceCalls = new DeviceCalls();
  const deviceDataPromise = deviceCalls.getAssociatedDevices();
  await dispatch(fetchedDevicesData(deviceDataPromise));

  // Don't wait all the calls to show myDevices
  return deviceDataPromise.then((devicesData) => {
    const devicesIds = devicesData.map((deviceData) => deviceData.uuid);
    dispatch(actionsDevices.associateDevices(devicesIds));
    return Promise.all([
      devicesData,
      Promise.all(
        devicesIds.map((deviceId) => dispatch(fetchedDevicesStatus(deviceId)))
      ),
      dispatch(fetchPublicIncidentsByDevicesIdsInChunks(devicesIds)),
    ]);
  });
};

/**
 * Fetch device by cloud connect id
 * @param string cloudConnectId
 */
export const fetchDeviceByCloudConnectId = async (cloudConnectId: string) => {
  const deviceCalls = new DeviceCalls();
  return await deviceCalls.getDeviceByCloudConnectId(cloudConnectId);
};

export const postAssociatedDevice =
  (cloudConnectId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const deviceCalls = new DeviceCalls();
    await deviceCalls.postAssociatedDevice(cloudConnectId);
    const deviceDataPromise =
      deviceCalls.getDeviceByCloudConnectId(cloudConnectId);
    const deviceData = await deviceDataPromise;
    if (
      isDeviceInAssociatedDevices(getState(), { deviceId: deviceData.uuid })
    ) {
      dispatch(
        actionsDevices.addLabel({
          deviceId: deviceData.uuid,
          label: LABEL_ALREADY_ADDED,
        })
      );
    } else {
      // load & add to list
      await dispatch(fetchedDeviceData(deviceDataPromise));
      await dispatch(fetchDevicesByDevicesData([deviceData]));

      dispatch(actionsDevices.addAssociateDevices(deviceData.uuid));
      dispatch(
        actionsDevices.addLabel({
          deviceId: deviceData.uuid,
          label: LABEL_NEW,
        })
      );
    }
  };

export const putDevice =
  (
    deviceId: string,
    deviceData: DeviceDataAPIResponse,
    iconUpload?: IconUpload
  ) =>
  async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();

    const deviceDataResponse = await deviceCalls.putDevice(
      deviceId,
      deviceData,
      iconUpload
    );
    dispatch(actionsDevices.updateDevice(deviceDataResponse));
  };

/**
 * Delete associated device
 * @param string deviceId
 * @param bool onlyReduxStore: used to remove the device only to the store,
 * without to call the model
 */
export const deleteAssociatedDevice =
  (deviceId: string, onlyReduxStore = false) =>
  async (dispatch: AppDispatch) => {
    let deviceIdDeleted = deviceId;
    if (!onlyReduxStore) {
      const deviceCalls = new DeviceCalls();
      deviceIdDeleted = await deviceCalls.deleteAssociatedDevice(deviceId);
    }
    dispatch(actionsDevices.deleteAssociateDevice(deviceIdDeleted));
  };

/**
 * Delete associated devices redux store,
 * because the devices can be in associated devices is part of a group
 * @param array devicesId
 */
export const deleteAssociatedDevicesReduxStore =
  (devicesId: string[]) => async (dispatch: AppDispatch) =>
    devicesId.map((deviceId) =>
      dispatch(deleteAssociatedDevice(deviceId, true))
    );

/**
 * Delete device icon
 * @param class restClient
 * @param class polyglot
 * @param string deviceId
 */
export const deleteDeviceIcon =
  (deviceId: string) => async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();
    await deviceCalls.deleteIcon(deviceId);
    dispatch(actionsDevices.deleteIcon(deviceId));
  };

/**
 * Get device by device id
 * this function tries to get the device from the groups structure, if this is not
 * found then a call will be done to get the device from the service
 * @param class restClient
 * @param class polyglot
 * @param string deviceId
 */
export const getDeviceByDeviceId =
  (deviceId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const deviceData = getDevicesDataById(getState(), { deviceId });
    /* if the device is not found in the groups array then it will ask for this device */
    if (!deviceData) {
      await dispatch(fetchDevicesByIds([deviceId]));
    }
  };
