/**
 * Model interface class:
 */
class Calls {
  constructor(restCalls) {
    this.restCalls = restCalls;
  }

  /**
   * Get device coordinates by location
   * @param string location
   * @param func reject
   * @return array devicesCoordinates
   */
  async getDeviceCoordinatesByLocation(location, reject) {
    const promise = new Promise((resolve) => {
      this.restCalls.getDevicesCoordinates(
        location,
        (response) => resolve(response),
        reject
      );
    });

    return promise;
  }

  /**
   * Get devices coordinates
   * @param array devices [{street, zipcode, country, city}]
   * @return array devices [{street, zipcode, country, city, cordinates: []}]
   */
  async getDevicesCoordinates(devices) {
    return new Promise((resolve, reject) => {
      const results = devices.map(async (device) => {
        const location = `${device.street} ${device.zipcode} ${device.city} ${device.country}`;
        const coordinates = await this.getDeviceCoordinatesByLocation(
          location,
          reject
        );
        /* coordinates = { features: [{center: [long, latitud], relevance: }] } */
        let features = [];
        if (coordinates.features && coordinates.features.length > 0) {
          features = coordinates.features.map((c) => ({
            center: c.center,
            relevance: c.relevance,
          }));
        }
        Object.assign(device, { coordinates: features });
        return coordinates;
      });

      Promise.all(results).then(() => resolve(devices));
    });
  }
}

export default Calls;
