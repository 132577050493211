import { RouteProps } from 'react-router-dom';
import extendedRoutesDefault from '../../../../../util/route-dom/extendedRoutes/extendedRoutes';
import FlexeserveUI from '../../../components/FlexeserveUI';

const extendedRoutes: RouteProps<string>[] = [
  ...extendedRoutesDefault,
  {
    path: '/flexeserve',
    component: FlexeserveUI,
  },
];

export default extendedRoutes;
