import { Paper, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon, Loader } from 'semantic-ui-react';
import {
  BoxSingleData,
  RotationList,
  TYPE_NEGATIVE,
} from 'stoerk-ui-components';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../../handlingErrors';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../i18n';
import { DEFAULT_VALUE_SENSOR } from '../../../../../../../../../model/controlUnit/util/constants';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../../../../model/controlUnit/util/sensor/getValueStrFromSensor';
import { DeviceControlUnitsAPIResponse } from '../../../../../../../../../model/device/device.model';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import TooltipLight from '../../../../../../../../commons/TooltipLight';
import AlarmsActiveList from '../../AlarmsList';
import './ControlUnitCardGrid.scss';

interface ControlUnitCardGridProps
  extends RouteComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps {
  controlUnit: DeviceControlUnitsAPIResponse;
  deviceId: string;
  groupId?: string;
}
export function ControlUnitCardGridSimple(props: ControlUnitCardGridProps) {
  const {
    controlUnit,
    history,
    deviceId,
    groupId,
    polyglot,
    handlingErrorsApi,
  } = props;
  const {
    loading,
    currentAlarms,
    hasAlarms,
    isOn,
    mainSensor,
    type,
    CUTranslate,
  } = useCurrentStateByControlUnitId(
    deviceId,
    controlUnit.id,
    handlingErrorsApi
  );

  const titleAlarm = hasAlarms ? (
    <RotationList
      list={currentAlarms.map((item) =>
        CUTranslate(item?.name ?? item?.eventID ?? '', 'ram', 'longText')
      )}
    />
  ) : undefined;

  const onClickCard = () => {
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnit.id}`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnit.id}`;
    }
    history.push(url);
  };

  let icons: any[] = [];
  if (type === TYPE_NEGATIVE && hasAlarms) {
    const popup = (
      <TooltipLight
        title={
          <AlarmsActiveList
            alarms={currentAlarms}
            deviceId={deviceId}
            controlUnitId={controlUnit.id}
          />
        }
      >
        <Typography color="error">
          <Icon name="warning sign" size="large" key="alarms" />
        </Typography>
      </TooltipLight>
    );
    icons = [{ button: popup }];
  }

  if (loading) {
    icons = [{ button: <Loader active inline /> }];
  }
  const value = getValueStrFromSensor(mainSensor, isOn, polyglot);
  const unitValue = getUnitStrFromSensor(mainSensor, isOn);
  const title = mainSensor?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        mainSensor?.timestamp
      ).fromNow()}`
    : undefined;

  return (
    <Paper sx={{ margin: 1 }}>
      <BoxSingleData
        title={controlUnit.name}
        titleAlarm={titleAlarm}
        type={type}
        icons={icons}
        onClick={onClickCard}
        titleValue={
          CUTranslate(
            mainSensor?.metadata?.name ?? mainSensor?.channel ?? ''
          ) ?? DEFAULT_VALUE_SENSOR
        }
        value={<span title={title}>{value}</span>}
        unitValue={unitValue}
        mode={'MODE_LIGHT'}
        onClickIconUpdate={null}
        showIconUpdate={false}
      />
    </Paper>
  );
}

export default withHandlingErrors(
  withRouter(withPolyglot(ControlUnitCardGridSimple))
);
