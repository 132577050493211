/**
 * Code calls
 */
export const getCommanderByIds = 'device/getCommanderByIds';
export const getAssociatedDevices = 'device/getAssociatedDevices';
export const getDeviceStatusById = 'device/getDeviceStatusById';
export const getUnresolvedIncidentsByDeviceId =
  'device/getUnresolvedIncidentsByDeviceId';
export const getDeviceByCloudConnectId = 'device/getDeviceByCloudConnectId';
export const getDeviceIPById = 'device/getDeviceIPById';
export const postAssociatedDevice = 'device/postAssociatedDevice';
export const updateDevice = 'device/updateDevice';
export const saveIcon = 'device/saveIcon';
export const deleteAssociatedDevice = 'device/deleteAssociatedDevice';
export const deleteIcon = 'device/deleteIcon';
export const getEventsByDeviceId = 'device/getEventsByDeviceId';
