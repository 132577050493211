import { useEffect } from 'react';
import { warmUpGroups } from '../../groups/actions/thunks';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getDeviceFromProps } from '../selectors';

/**
 * Use redux to get and fetch device
 * @param deviceId usually is the queryParam
 * @param onError callback when call fail
 * @returns
 */
export const useDevice = (
  deviceId: string,
  onError: (error: unknown) => unknown
) => {
  const device = useAppSelector((state: RootState) =>
    getDeviceFromProps(state, { deviceId })
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!device?.data && !device?.loadingData) {
      Promise.resolve(dispatch(warmUpGroups())).catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.data, device?.loadingData, dispatch]);
  return [device, device?.loadingData] as const;
};
