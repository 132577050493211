import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { Box } from '@mui/material';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import {
  STATUS_OK,
  STATUS_ALERT,
  STATUS_OK_INPROGRESS,
  STATUS_ALERT_INPROGRESS,
} from '../../Constants';
import GroupIconDefault from '../../../../../assets/GroupIconDefaultLandscape.svg';
import ModelDevices from '../../../../../model/device/Model';
import DeviceIcon from '../../../../../assets/IconST7Landscape.svg';
import './index.css';
import ListItem from '../../../../commons/ListItem';
import { Device } from '../../../../../model/device/device.model';

const cssGroupsStatus: Record<string, string> = {};
cssGroupsStatus[STATUS_OK] = 'ok';
cssGroupsStatus[STATUS_ALERT] = 'alert';
cssGroupsStatus[STATUS_OK_INPROGRESS] = 'ok_inprogress';
cssGroupsStatus[STATUS_ALERT_INPROGRESS] = 'alert_inprogress';

interface Props extends RouteComponentProps, PolyglotComponentProps {
  devices: Device[];
}

/**
 * Groups overview grid
 *
 * devicesTotal: this parameter will be used to show/create the group mydevices,
 * but only by the group of the root
 */
function GroupMyDeviceOverviewList(props: Props) {
  const { devices, history } = props;
  const status = ModelDevices.getStatusByDevicesList(devices);
  return (
    <ListItem
      className={`group ${cssGroupsStatus[status]}`}
      name={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {props.polyglot.t('group.my_devices.name')}
          {devices && devices.length > 0 && (
            <div>
              <Image
                src={DeviceIcon}
                size="mini"
                inline
                label={{
                  content: devices.length,
                  color: 'red',
                  size: 'mini',
                  floating: true,
                  circular: true,
                  className: 'label-quantity-device',
                }}
                className="icon-devices"
              />
            </div>
          )}
        </Box>
      }
      iconURL={GroupIconDefault}
      onClick={() => history.push('/mydevices')}
    />
  );
}

export default withPolyglot(withRouter(GroupMyDeviceOverviewList));
