import RestCalls from '../../rest/RestCalls';
import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';

import * as callTypes from './CallTypes';
import {
  IncidentAPIResponse,
  IncidentConfigAPI,
  IncidentsAttachmentsAPIResponse,
  PublicIncidentAPIResponse,
} from './incident.model';
import Axios from 'axios';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class Calls {
  readonly backendIncidentManagerServiceURL =
    new Util().getIncidentManagerServiceURL();

  constructor(
    private restClient = restClientHttp,
    private restCalls = new RestCalls(restClientHttp)
  ) {
    this.restCalls = restCalls;
  }

  /**
   * Get incident configuration by group id
   * @param string groupId
   * @return promise
   */
  getIncidentConfigurationByGroupId(groupId: string) {
    const incidentManagerServiceURL = `${this.backendIncidentManagerServiceURL}/config/${groupId}`;
    return this.restClient.callPromise<IncidentConfigAPI | undefined>(
      {
        url: incidentManagerServiceURL,
        method: 'GET',
      },
      callTypes.getIncidentConfigurationByGroupId
    );
  }

  /**
   * Get incidents by device id
   * @param string deviceId
   */
  async getIncidentsByDeviceId(deviceId: string) {
    const incidentManagerServiceURL = `${this.backendIncidentManagerServiceURL}/device/${deviceId}`;
    return this.restClient.callPromise<IncidentAPIResponse[]>(
      {
        url: incidentManagerServiceURL,
        method: 'GET',
      },
      callTypes.getIncidentsByIds
    );
  }

  /**
   * Get incident by incident id
   * @param string incidentId
   * @return object incident
   */
  async getIncidentByIncidentId(incidentId: string) {
    const incidentManagerServiceURL = `${this.backendIncidentManagerServiceURL}/${incidentId}`;
    return this.restClient.callPromise<IncidentAPIResponse>(
      {
        url: incidentManagerServiceURL,
        method: 'GET',
      },
      callTypes.getIncidentByIncidentId
    );
  }

  /**
   * Get unresolved incidents by devices ids
   * @param array devicesIds
   */
  getIncidentsByDeviceIds(devicesIds: string[]) {
    const url = `${
      this.backendIncidentManagerServiceURL
    }/device?ids=${JSON.stringify(devicesIds)}`;
    return this.restClient.callPromise<Record<string, IncidentAPIResponse[]>>(
      {
        url,
        method: 'GET',
      },
      callTypes.getIncidentsByIds
    );
  }

  /**
   * Get unresolved incidents by devices ids
   * @param array devicesIds
   */
  getUnresolvedIncidentsByIds(devicesIds: string[]) {
    const url = `${
      this.backendIncidentManagerServiceURL
    }/device/unresolved?ids=${JSON.stringify(devicesIds)}`;
    return this.restClient.callPromise<Record<string, IncidentAPIResponse[]>>(
      {
        url,
        method: 'GET',
      },
      callTypes.getUnresolvedIncidentsByIds
    );
  }

  /**
   * Get public incidents by devices ids, this incidents not need
   * `readIncident` rights to works, so we can show even if not are payed
   * for this service
   * @param array devicesIds
   */
  getPublicIncidentsByIds(devicesIds: string[]) {
    const url = `${
      this.backendIncidentManagerServiceURL
    }/device/public?ids=${JSON.stringify(devicesIds)}`;
    return this.restClient.callPromise<
      Record<string, PublicIncidentAPIResponse[]>
    >(
      {
        url,
        method: 'GET',
      },
      callTypes.getPublicIncidentsByIds
    );
  }
  /**
   * Put incident configuration
   * @param string groupId
   * @param object incidentConfiguration
   * @return promise
   */
  async putIncidentConfiguration(
    groupId: string,
    incidentConfiguration: Partial<IncidentConfigAPI>
  ) {
    const url = `${this.backendIncidentManagerServiceURL}/config/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to asign a device to a group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: incidentConfiguration,
      },
      callTypes.putIncidentConfiguration
    );
  }

  /**
   * Put incident
   * update an incident
   * @param string incidentId
   * @param object incidentData
   * @return promise
   */
  async putIncident(incidentId: string, incidentData: any) {
    const url = `${this.backendIncidentManagerServiceURL}/${incidentId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.restClient.callPromise(
      {
        url,
        method: 'PUT',
        headers,
        entity: incidentData,
      },
      callTypes.getIncidentByIncidentId
    );
  }

  /**
   * Add incident attachment
   * @param string incidentId
   * @param object attachment
   */
  async addIncidentAttachment(incidentId: string, attachment: File) {
    const form = new FormData();
    form.append('file', attachment);
    const url = `${this.backendIncidentManagerServiceURL}/${incidentId}/attachments`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return this.restClient.callPromise<IncidentsAttachmentsAPIResponse>(
      {
        url,
        method: 'POST',
        headers,
        entity: form,
      },
      callTypes.addIncidentAttachment
    );
  }

  /**
   * Get incident attachments
   * @param string incidentId
   */
  async getIncidentAttachments(incidentId: string) {
    const url = `${this.backendIncidentManagerServiceURL}/${incidentId}/attachments`;
    return this.restClient.callPromise<IncidentsAttachmentsAPIResponse[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getIncidentAttachments
    );
  }

  /**
   * Get incident attachment content
   * retrieve attchament content
   * @param string incidentId
   * @param string attachmentKey
   * @return object { content, contentType}
   *
   * @deprecated FIXME: Current is not working :(
   */
  async getIncidentAttachmentContent(incidentId: string, attachmentKey: any) {
    const url = `${this.backendIncidentManagerServiceURL}/${incidentId}/attachments/${attachmentKey}`;
    return await Axios.get(url, {
      withCredentials: false,
      headers: this.restClient.buildAuthHeader({}),
      responseType: 'arraybuffer',
    });
  }
}

export default Calls;
