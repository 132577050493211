import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Loader, Grid } from 'semantic-ui-react';
import { withSnackbar, UiMessage } from 'stoerk-ui-components';
import {
  Paper,
  Container,
  Accordion,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ConnectDeviceAdd from '../../../components/Devices/components/DeviceAdd';
import ButtonAdd from '../../../../commons/buttonAdd';
import DevicesOverview from '../../../components/Devices';
import DeviceInformation from '../../../components/Devices/components/DeviceInformation';
import ConnectMapDevices from '../../../components/MapDevices';
import BrowserUtil from '../../../../../util/BrowserUtil';
import BreadcrumbNew from '../../../../commons/breadcrumbNew';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import Util, {
  saveLocalStorage,
  getValueLocalStore,
} from '../../../../../util/Util';
import { warmUpGroups } from '../../../../../redux/groups/actions/thunks';
import SortUtil, { SortDirections } from '../../../../../util/SortUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import ConnectTopBarMenu, {
  VIEW_GRID,
  VIEW_LIST,
  VIEW_GRID_SMALL,
  VIEW_MAP,
  SORT_FIELD_NAME,
  SORT_FIELD_STATUS,
  ASC,
  DESC,
} from '../../../../commons/topBarMenu';

import '../../../components/Devices/index.css';
import { getUnAssignedDevices } from '../../../../../redux/groups/selectors';
import { RootState } from '../../../../../redux/store.model';
import { RouteComponentProps } from 'react-router-dom';
import { Device } from '../../../../../model/device/device.model';

const browserUtil = new BrowserUtil();
const util = new Util();

const showViewGroupsAndDevicesKey = util.getShowViewGroupsAndDevicesKey();
const sortGroupsDevicesFieldKey = util.getSortGroupsDeviceFieldKey();
const sortGroupsDevicesDirectiondKey = util.getSortGroupsDeviceDirectionKey();

type Props = ConnectedComponentProps &
  PolyglotComponentProps &
  OpenSnackbarProps &
  HandlingErrorWrappedProps &
  RightsUserUtilComponentProps &
  RouteComponentProps;

type State = {
  sortField: 'name' | 'statusValue';
  sortDirections: {
    name: SortDirections;
    statusValue: SortDirections;
  };
  view: 'viewList' | 'viewGrid' | 'viewGridSmall' | 'viewMap';
  showAddDevice: boolean;
  showDeviceInformation: boolean;
  message: null;
  routesGroupsDeviceAlreadyAdded: never[];
  device?: Device;
  value?: string;
};

/**
 * Devices overview for the devices that don't belong to any group
 */
export class Devices extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.openAddDevice = this.openAddDevice.bind(this);
    this.closeAddDevice = this.closeAddDevice.bind(this);
    this.changeView = this.changeView.bind(this);
    this.openDeviceInformation = this.openDeviceInformation.bind(this);
    this.closeDeviceInformation = this.closeDeviceInformation.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    this.loadData = this.loadData.bind(this);
    this.sort = this.sort.bind(this);
    /* get the sort  used by  the user in the last session */
    let sortField = getValueLocalStore(
      sortGroupsDevicesFieldKey
    ) as State['sortField'];
    if (!sortField) {
      sortField = SORT_FIELD_NAME;
    }

    /* get the sort direction used by the users in the last session
     */
    let sortDirection = getValueLocalStore(
      sortGroupsDevicesDirectiondKey
    ) as SortDirections;
    if (!sortDirection) {
      sortDirection = ASC;
    }

    const sortDirections = {
      [SORT_FIELD_NAME]: sortField === SORT_FIELD_NAME ? sortDirection : ASC,
      [SORT_FIELD_STATUS]:
        sortField === SORT_FIELD_STATUS ? sortDirection : ASC,
    };

    /* get the view that has used the user */
    const view: State['view'] =
      (getValueLocalStore(showViewGroupsAndDevicesKey) as State['view']) ??
      VIEW_LIST;

    this.state = {
      sortField,
      sortDirections,
      view,
      showAddDevice: false,
      showDeviceInformation: false,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    };
  }

  async componentDidMount() {
    const { activatedLoadingGroupsDevices } = this.props;
    if (!activatedLoadingGroupsDevices) {
      await this.loadData();
    }
  }

  /**
   * Load data: this function calls the backend to get the data from groups
   * devices. only when the call are done, the page will be rendered
   */
  async loadData() {
    const { handlingErrorsApi } = this.props;
    try {
      await this.props.warmUpGroups();
      this.loadButtonsToDisplay();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Load buttons to display
   */
  loadButtonsToDisplay() {
    const { rightsUserUtil } = this.props;
    const showButtonAddDevice = rightsUserUtil.isAuthorizedUser();
    return { showButtonAddDevice };
  }

  /**
   * Change view
   * this function is called when the view icons are click
   */
  changeView(event: unknown, view: State['view']) {
    /* the selected view, will be saved into the localstore and cookie */
    saveLocalStorage(showViewGroupsAndDevicesKey, view, '/');
    this.setState({ view });
  }

  /**
   * Sort field
   * This function set the state variables sortField and sortDirections
   * @param string sortField
   */
  sort(sortField: keyof typeof sortDirections) {
    const { sortDirections } = this.state;
    sortDirections[sortField] = sortDirections[sortField] === ASC ? DESC : ASC;
    saveLocalStorage(sortGroupsDevicesFieldKey, sortField, '/');
    saveLocalStorage(
      sortGroupsDevicesDirectiondKey,
      sortDirections[sortField],
      '/devicemanager'
    );
    this.setState({
      sortField,
      sortDirections,
    });
  }

  openAddDevice() {
    this.setState({
      showAddDevice: true,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    });
  }

  closeAddDevice(message: any, routesGroupsDeviceAlreadyAdded = []) {
    this.setState({
      showAddDevice: false,
      message,
      routesGroupsDeviceAlreadyAdded,
    });
  }

  openDeviceInformation(event: any, device: any) {
    this.setState({
      showDeviceInformation: true,
      device,
      message: null,
      routesGroupsDeviceAlreadyAdded: [],
    });
  }

  closeDeviceInformation() {
    this.setState({ showDeviceInformation: false });
  }

  render() {
    const {
      featureToggle,
      loadingGroups,
      loadingDevices,
      polyglot,
      history,
      myDevices,
    } = this.props;
    const {
      value,
      sortDirections,
      sortField,
      view,
      showAddDevice,
      showDeviceInformation,
      device,
      routesGroupsDeviceAlreadyAdded,
      message,
    } = this.state;
    const onClickButtonAdd = this.openAddDevice;

    const { showButtonAddDevice } = this.loadButtonsToDisplay();
    /* search */
    let devices = myDevices;

    if (value) {
      const re = new RegExp(value, 'i');
      devices = devices.filter((d) => re.test(d.name || ''));
    }
    /* Sort */
    if (sortField !== undefined && sortDirections !== undefined) {
      devices =
        devices.length > 0
          ? SortUtil.multisort(
              devices,
              [sortField],
              [sortDirections[sortField]]
            )
          : [];
    }
    /* Navigation */
    const sectionsNavigation = [
      { content: polyglot.t('nav.home'), onClick: () => history.push('/') },
      {
        content: polyglot.t('nav.menu.devicemanager'),
        onClick: () => history.push('/devicemanager'),
      },
      { content: polyglot.t('group.my_devices.name') },
    ];

    return (
      <div>
        <BreadcrumbNew sections={sectionsNavigation} />
        <ConnectDeviceAdd
          showAddDevice={showAddDevice}
          closeAddDevice={this.closeAddDevice}
        />
        {showDeviceInformation && (
          <DeviceInformation
            showDeviceInformation={showDeviceInformation}
            closeDeviceInformation={this.closeDeviceInformation}
            device={device}
          />
        )}
        <Container maxWidth="lg">
          <Paper
            variant="outlined"
            // Remove paper in mobile
            sx={{
              padding: { md: 2 },
              borderWidth: { xs: '0px', md: '1px' },
            }}
          >
            <ConnectTopBarMenu
              showSearch
              onSearchChange={(event: { target: { value: string } }) =>
                this.setState({ value: event.target.value })
              }
              changeViewList={(event: any) => this.changeView(event, VIEW_LIST)}
              changeViewGrid={(event: any) => this.changeView(event, VIEW_GRID)}
              changeViewGridSmall={(event: any) =>
                this.changeView(event, VIEW_GRID_SMALL)
              }
              selectedView={view}
              onClickAddButton={onClickButtonAdd}
              showAddButton={showButtonAddDevice}
              showSortByStatus
              sort={sortDirections}
              sortField={sortField}
              sortByName={() => this.sort(SORT_FIELD_NAME)}
              sortByStatus={() => this.sort(SORT_FIELD_STATUS)}
              showViewMap={featureToggle.map}
            />
            {featureToggle.map && view === VIEW_MAP ? (
              <ConnectMapDevices />
            ) : (
              <div>
                {(loadingGroups || loadingDevices) && (
                  <Grid>
                    <Grid.Column
                      width="16"
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      <Loader active inline />
                      {polyglot.t('group.loading_data_message')}
                    </Grid.Column>
                  </Grid>
                )}
                <br />
                <br />
                {(message || routesGroupsDeviceAlreadyAdded.length > 0) && (
                  <Grid>
                    <Grid.Column
                      width="16"
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      <UiMessage
                        list={routesGroupsDeviceAlreadyAdded}
                        header={message}
                      />
                    </Grid.Column>
                  </Grid>
                )}
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    id="accordion-devices-title"
                    aria-controls="accordion-devices-title"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="h5">
                      {polyglot.t('group.my_devices.name')}
                    </Typography>
                  </AccordionSummary>
                  <div>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore checked by conditional */}
                    <DevicesOverview devices={devices} view={view} />
                  </div>
                </Accordion>
              </div>
            )}
          </Paper>
        </Container>

        {browserUtil.getIsMobile() && showButtonAddDevice && (
          <ButtonAdd
            showAddButtonLarge={false}
            onClick={onClickButtonAdd}
            messageAddLarge={polyglot.t('device.add_no_devices_message')}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // devices: state.devices.items,
  myDevices: getUnAssignedDevices(state),
  featureToggle: state.featureToggle,
  loadingGroups: state.groups.loading,
  loadingDevices: state.devices.loadingDevicesData,
  activatedLoadingGroupsDevices: state.groups.activatedLoadingGroupsDevices,
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(withUserRightUtil(withSnackbar(withPolyglot(Devices))))
);
