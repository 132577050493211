import {
  AccordionSummary,
  Accordion,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Box,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useMemo, useState } from 'react';
import { ZipDeviceControlUnit } from '../../../../../../../../redux/controlUnit/controlUnit.model';
import { useFilterChannelOrEvent } from './useFilterChannelOrEvent';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../i18n';
import { STModal } from '../../../../../../../commons/Modal';
import { DeviceControlUnitsEventsMetadataAPIResponse } from '../../../../../../../../model/device/device.model';
import {
  CommanderMonitoringFilterLogicalObject,
  getNumberOfChannels,
  KEYS_CONTROL_UNITS_BY_TYPE,
} from '../../util/filter.model';
import { getCUsTranslationsSelector } from '../../../../../../../../redux/CUTranslation/selectors';
import { useAppSelector } from '../../../../../../../../redux/store.model';

export interface DigitalChannelFiltersModalButtonProps
  extends PolyglotComponentProps {
  devicesControlUnits: ZipDeviceControlUnit[];
  type: 'events' | 'channels';
  value: CommanderMonitoringFilterLogicalObject;
  onSave: (value: CommanderMonitoringFilterLogicalObject) => unknown;
}
export const DigitalChannelFiltersModalButton = (
  props: DigitalChannelFiltersModalButtonProps
) => {
  const { devicesControlUnits, type, polyglot, value, onSave } = props;
  const [open, setOpen] = useState(false);
  const controls = useFilterChannelOrEvent(type, devicesControlUnits, value);
  const title =
    type === 'events'
      ? polyglot.t('device.monitoring.digital_channels')
      : polyglot.t('device.monitoring.analog_channels');
  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    controls.reset();
    setOpen(true);
  };

  const save = () => {
    onSave(controls.filterLogicalObject);
    closeModal();
  };
  const numberOfChannels = useMemo(
    () => getNumberOfChannels(value ?? {}),
    [value]
  );
  const selected =
    numberOfChannels.active === numberOfChannels.total
      ? ''
      : ` (${numberOfChannels.active}/${numberOfChannels.total})`;
  return (
    <>
      <Button
        onClick={openModal}
        startIcon={<FilterListIcon />}
        variant="outlined"
      >
        {title + selected}
      </Button>
      <STModal
        open={open}
        onClose={closeModal}
        title={title}
        fullWidth
        maxWidth="md"
        buttonActions={
          <Button onClick={save}>{polyglot.t('button.save')}</Button>
        }
      >
        <DigitalChannelFiltersView {...props} controls={controls} />
      </STModal>
    </>
  );
};

export interface DigitalChannelFiltersViewProps {
  devicesControlUnits: ZipDeviceControlUnit[];
  type: 'events' | 'channels';
  controls: ReturnType<typeof useFilterChannelOrEvent>;
}
export const DigitalChannelFiltersView = (
  props: DigitalChannelFiltersViewProps
) => {
  const { devicesControlUnits, type, controls } = props;
  const CUsTranslate = useAppSelector(getCUsTranslationsSelector);

  const {
    toggleItem,
    toggleControlUnit,
    toggleDevice,
    getStateItem,
    getStateControlUnit,
    getStateDevice,
  } = controls;

  return (
    <Box className="DigitalChannelFilters" sx={{ width: '100%' }}>
      {devicesControlUnits.map((zipDeviceControlUnit) => (
        <Accordion
          key={zipDeviceControlUnit.deviceId}
          id={`device-title-${zipDeviceControlUnit.deviceId}`}
          aria-controls={`device-title-${zipDeviceControlUnit.deviceId}`}
          className={`DigitalChannelFilters__Device Device-${zipDeviceControlUnit.deviceId}`}
          defaultExpanded={true}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Checkbox
              id={`device-checkbox-${zipDeviceControlUnit.deviceId}`}
              inputProps={
                {
                  'data-testid': `device-checkbox-${zipDeviceControlUnit.deviceId}`,
                } as any
              }
              edge="start"
              checked={getStateDevice(zipDeviceControlUnit) === 'checked'}
              onClick={(e) => {
                e.stopPropagation();
                toggleDevice(zipDeviceControlUnit);
              }}
              indeterminate={
                getStateDevice(zipDeviceControlUnit) === 'indeterminate'
              }
              tabIndex={-1}
            />
            <Typography variant="h5" display="flex" alignItems="center">
              {zipDeviceControlUnit.deviceName}
            </Typography>
          </AccordionSummary>
          <Box sx={{ padding: 1 }}>
            {zipDeviceControlUnit.controlUnits?.map((controlUnit) => (
              <Accordion
                key={controlUnit.id}
                className={`DigitalChannelFilters__ControlUnit Device-${zipDeviceControlUnit.deviceId} ControlUnit-${controlUnit.id}`}
                id={`control-unit-title-${zipDeviceControlUnit.deviceId}-${controlUnit.id}`}
                aria-controls={`control-unit-title-${zipDeviceControlUnit.deviceId}-${controlUnit.id}`}
                defaultExpanded={true}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" display="flex" alignItems="center">
                    <Checkbox
                      inputProps={
                        {
                          'data-testid': `control-unit-checkbox-${zipDeviceControlUnit.deviceId}-${controlUnit.id}`,
                        } as any
                      }
                      id={`control-unit-checkbox-${zipDeviceControlUnit.deviceId}-${controlUnit.id}`}
                      edge="start"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleControlUnit(
                          zipDeviceControlUnit.deviceId,
                          controlUnit
                        );
                      }}
                      checked={
                        getStateControlUnit(
                          zipDeviceControlUnit.deviceId,
                          controlUnit.id
                        ) === 'checked'
                      }
                      indeterminate={
                        getStateControlUnit(
                          zipDeviceControlUnit.deviceId,
                          controlUnit.id
                        ) === 'indeterminate'
                      }
                      tabIndex={-1}
                    />
                    {controlUnit.name ?? controlUnit.id}
                  </Typography>
                </AccordionSummary>
                <List>
                  {KEYS_CONTROL_UNITS_BY_TYPE[type].map((keyControlIUnit) =>
                    controlUnit[keyControlIUnit]?.map((event) => {
                      const longTranslation = CUsTranslate(
                        (event as DeviceControlUnitsEventsMetadataAPIResponse)
                          ?.name ?? event.id,
                        zipDeviceControlUnit.deviceId,
                        controlUnit.id,
                        'ram',
                        'longText'
                      ) as string;
                      const shortTranslation = CUsTranslate(
                        (event as DeviceControlUnitsEventsMetadataAPIResponse)
                          ?.name ?? event.id,
                        zipDeviceControlUnit.deviceId,
                        controlUnit.id,
                        'ram'
                      ) as string;
                      return (
                        <ListItem
                          key={event.id}
                          className={`DigitalChannelFilters__Event Event-${event.id}`}
                          disablePadding
                        >
                          <ListItemButton
                            role={undefined}
                            onClick={() =>
                              toggleItem(
                                zipDeviceControlUnit.deviceId,
                                controlUnit.id,
                                event.id
                              )
                            }
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                id={`event-checkbox-${zipDeviceControlUnit.deviceId}-${controlUnit.id}-${event.id}`}
                                edge="start"
                                checked={
                                  getStateItem(
                                    zipDeviceControlUnit.deviceId,
                                    controlUnit.id,
                                    event.id
                                  ) === 'checked'
                                }
                                indeterminate={
                                  getStateItem(
                                    zipDeviceControlUnit.deviceId,
                                    controlUnit.id,
                                    event.id
                                  ) === 'indeterminate'
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={
                                  {
                                    'aria-labelledby': `event-title-${zipDeviceControlUnit.deviceId}-${controlUnit.id}-${event.id}-label`,
                                    'data-testid': `event-checkbox-${zipDeviceControlUnit.deviceId}-${controlUnit.id}-${event.id}`,
                                  } as any
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={`event-title-${zipDeviceControlUnit.deviceId}-${controlUnit.id}-${event.id}-label`}
                              primary={longTranslation}
                              secondary={
                                shortTranslation !== longTranslation
                                  ? shortTranslation
                                  : undefined
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                  )}
                </List>
              </Accordion>
            ))}
          </Box>
        </Accordion>
      ))}
    </Box>
  );
};

export default withPolyglot(DigitalChannelFiltersModalButton);
