import { createSelector } from '@reduxjs/toolkit';
import { match } from 'react-router-dom';
import { Device } from '../../../model/device/device.model';
import DeviceModel from '../../../model/device/Model';
import { RootState } from '../../store.model';

interface RouteComponentPropsDevice {
  match: match<{ deviceId?: string }>;
}
// ^ Functionality inherit
export const getDevicesData = (state: RootState) =>
  state.devices.dictionaryDevicesData;

export const getDevicesDataById = (
  state: RootState,
  props: { deviceId: string }
) => state.devices.dictionaryDevicesData[props.deviceId];

export const getDevicesDataLoadingById = (state: RootState) =>
  state.devices.loadingDevicesData;

export const getDevicesStatusById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.dictionaryDevicesStatus[props.deviceId];

export const getDevicesStatusLoadingById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.loadingDevicesStatus[props.deviceId];

export const getDevicesLabelById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.devicesLabels[props.deviceId];

export const getPublicIncidentsByDeviceIdProps = (
  state: RootState,
  props: { deviceId: string }
) =>
  (state.devices.dictionaryIncidents[props.deviceId] || []).map(
    (publicIncidentId) => state.incidents.publicIncidentsItems[publicIncidentId]
  );

export const getLoadingDevicesPublicIncidents = (
  state: RootState,
  props: { deviceId?: string }
) => !!state.incidents.loadingPublicIncidentById[props?.deviceId || ''];

export const getAssociatedDevices = (state: RootState) =>
  state.devices.associatedDevices;

export const isAssociatedDevicesLoading = (state: RootState) =>
  !!state.devices.loadingAssociatedDevices;

export const getDeviceIdFromQueryParam = (
  _: RootState,
  props: RouteComponentPropsDevice
) => props.match.params.deviceId;

export const getDeviceIdFromProps = (
  _: RootState,
  props: { deviceId: string }
) => props.deviceId;

export const queryPramsToProps = (
  _: RootState,
  props: RouteComponentPropsDevice
) => props.match.params;

const model = new DeviceModel();

export const getDevicesFromProps = (
  state: RootState,
  props: { deviceIds: string[] }
) => props.deviceIds.map((deviceId) => getDeviceFromProps(state, { deviceId }));

export const getDeviceFromProps = createSelector(
  [
    getDeviceIdFromProps,
    getDevicesDataById,
    getDevicesDataLoadingById,
    getDevicesStatusById,
    getDevicesStatusLoadingById,
    getPublicIncidentsByDeviceIdProps,
    getLoadingDevicesPublicIncidents,
    getDevicesLabelById,
  ],
  model.parseDevice
);

export const getAllDevicesId = (state: RootState) =>
  Object.keys(state.devices.dictionaryDevicesData);

export const getDevicesByIds = (
  state: RootState,
  props: { devicesIds: string[] }
): Device[] =>
  props.devicesIds.map((deviceId) => getDeviceFromProps(state, { deviceId }));

export const getDevices = (state: RootState) =>
  getDevicesByIds(state, {
    devicesIds: getAllDevicesId(state),
  });

export const getNumDevices = (state: RootState) =>
  Object.keys(state.devices.dictionaryDevicesData).length;

export const getDeviceSelected = createSelector(
  [(state) => state, getDeviceIdFromQueryParam],
  (state, deviceId = '') => getDeviceFromProps(state, { deviceId })
);
export const getDeviceSelectedName = createSelector(
  [getDeviceSelected],
  (device) => device?.name || ''
);

export const isDeviceInAssociatedDevices = createSelector(
  [getDeviceIdFromProps, getAssociatedDevices],
  (deviceId, devicesIds) => devicesIds.includes(deviceId)
);

export const getDevicesAssociated = createSelector(
  [(state) => state, getAssociatedDevices],
  (state, devicesIds) =>
    devicesIds.map((deviceId) => getDeviceFromProps(state, { deviceId }))
);
