import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import GroupMyDevicesOverview from '../GroupMyDevices/Overview/GroupMyDeviceOverviewGrid';
import { withPolyglot } from '../../../../i18n';

import {
  STATUS_OK,
  STATUS_ALERT,
  STATUS_OK_INPROGRESS,
  STATUS_ALERT_INPROGRESS,
} from '../Constants';
import GroupIconDefault from '../../../../assets/GroupIconDefaultLandscape.svg';
import DeviceIcon from '../../../../assets/IconST7Landscape.svg';
import { getUnAssignedDevices } from '../../../../redux/groups/selectors';

const cssGroupsStatus = {};
cssGroupsStatus[STATUS_OK] = 'ok';
cssGroupsStatus[STATUS_ALERT] = 'alert';
cssGroupsStatus[STATUS_OK_INPROGRESS] = 'ok_inprogress';
cssGroupsStatus[STATUS_ALERT_INPROGRESS] = 'alert_inprogress';

export function GroupsOverviewGrid(props) {
  const {
    groups,
    groupIdParent,
    navigate,
    buttonsActions,
    polyglot,
    openContacts,
    myDevices,
  } = props;

  /* get devices associated to the user and not located in a group */
  const devices = myDevices;
  return (
    <div>
      <Card.Group className="groups-overview-grid">
        {/* If the group is the root group and the variable devicesTotal > 0,
            then the seudo group mydevices will be shown */}
        {groupIdParent === null && devices.length > 0 && (
          <GroupMyDevicesOverview devices={devices} />
        )}

        {groups.map((group) => (
          <Card
            key={group.id}
            className={`group ${cssGroupsStatus[group.status]}`}
          >
            <Card.Content className="toolbar-buttons">
              {buttonsActions(group)}
            </Card.Content>

            <Card.Content
              textAlign="center"
              className="image-group link"
              onClick={() => navigate(group.id)}
            >
              <img
                src={group.iconURL ? group.iconURL : GroupIconDefault}
                className="image"
                alt={polyglot.t('group.icon')}
              />
            </Card.Content>

            <Card.Content className="description" textAlign="center">
              <Grid>
                <Grid.Row
                  columns="1"
                  onClick={() => navigate(group.id)}
                  textAlign="center"
                >
                  <Grid.Column
                    textAlign="center"
                    verticalAlign="middle"
                    className="link name"
                  >
                    {group.name}
                  </Grid.Column>
                </Grid.Row>
                {((group.attributes.contacts &&
                  group.attributes.contacts.length > 0) ||
                  (group.devices && group.devices.length > 0)) && (
                  <Grid.Row columns="2">
                    <Grid.Column
                      textAlign="left"
                      verticalAlign="middle"
                      width={6}
                    >
                      {group.devices && group.devices.length > 0 && (
                        <div>
                          <Image
                            src={DeviceIcon}
                            size="mini"
                            inline
                            label={{
                              content: group.devices.length,
                              color: 'red',
                              size: 'mini',
                              floating: true,
                              circular: true,
                              className: 'label-quantity-device',
                            }}
                            className="icon-devices"
                            id="iconQuantityDevices"
                          />
                        </div>
                      )}
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      verticalAlign="middle"
                      width={10}
                      onClick={(event) => openContacts(event, group)}
                    >
                      {group.attributes.contacts.length > 0 && (
                        <label
                          htmlFor="iconContactList"
                          className="contact-list link"
                        >
                          {polyglot.t('group.contact_list')}
                          <Icon
                            className="no-border"
                            name="address book outline"
                            size="large"
                            id="iconContactList"
                          />
                        </label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </div>
  );
}

GroupsOverviewGrid.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      iconURL: PropTypes.string,
      status: PropTypes.string,
      childGroups: PropTypes.array,
      devices: PropTypes.array,
      attributes: PropTypes.object,
    })
  ).isRequired,
  myDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupIdParent: PropTypes.string,
  buttonsActions: PropTypes.func.isRequired,
  openContacts: PropTypes.func.isRequired,
};

GroupsOverviewGrid.defaultProps = {
  groupIdParent: null,
};

const mapStateToProps = (state, props) => ({
  myDevices: getUnAssignedDevices(state, props),
});

export default connect(mapStateToProps)(
  withSnackbar(withPolyglot(GroupsOverviewGrid))
);
