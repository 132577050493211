import { createSelector } from '@reduxjs/toolkit';
import { CUParametersObjAPIResponse } from '../../../model/CUParameters/CUParameters.model';
import { TranslationControlUnitObject } from '../../../model/CUTranslation/CUTranslation.model';
import { DeviceControlParameterMetadataAPIResponse } from '../../../model/device/device.model';
import { getControlUnitById } from '../../controlUnit/selectors';
import { getCUTranslationParametersFromControlUnit } from '../../CUTranslation/selectors';
import { RootState } from '../../store.model';

export const getCUParametersErrorState = (state: RootState) =>
  state.CUParameters.errorDeviceCUParameters;
export const getCUParametersLoadingState = (state: RootState) =>
  state.CUParameters.loadingDeviceCUParameters;
export const getCUParametersDicState = (state: RootState) =>
  state.CUParameters.dictionaryCUParameters;

export const getCUParametersFromDevice = (
  state: RootState,
  props: { deviceId: string }
) => getCUParametersDicState(state)[props.deviceId];

export const getCUParametersFromControlUnit = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) => getCUParametersFromDevice(state, props)?.[props.controlUnitId];

export const getCUParametersMetadataFromControlUnit = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) => getControlUnitById(state, props)?.parameters;

const composeParams = (
  parametersMetadata:
    | Record<string, DeviceControlParameterMetadataAPIResponse>
    | undefined,
  parametersValues: CUParametersObjAPIResponse | undefined,
  translationsParameters = {} as Record<
    string,
    TranslationControlUnitObject | undefined
  >
) => {
  return Object.entries(parametersMetadata ?? {}).map(
    ([key, parameterMetadataLevel]) => ({
      id: key,
      titleTranslation:
        translationsParameters[key]?.longText ??
        translationsParameters[key]?.shortText,
      values: parameterMetadataLevel.parameters.map((parameterMetadata) => ({
        ...parameterMetadata,
        value: parametersValues?.parameters[key].find(
          (parameterValue) => parameterValue.name === parameterMetadata.name
        )?.value,
        titleTranslation:
          translationsParameters[parameterMetadata.name]?.shortText,
        descriptionTranslation:
          translationsParameters[parameterMetadata.name]?.longText,
        selections: translationsParameters[parameterMetadata.name]?.selections,
        bits: translationsParameters[parameterMetadata.name]?.bits,
      })),
    })
  );
};

export type ComposedParameters = ReturnType<typeof composeParams>;
export type ComposedParameterLevel = ComposedParameters[0];
export type ComposedParametersValue = ComposedParameters[0]['values'][0];

export const getCUParametersComposedFromControlUnit = createSelector(
  [
    getCUParametersMetadataFromControlUnit,
    getCUParametersFromControlUnit,
    getCUTranslationParametersFromControlUnit,
  ],
  composeParams
);

export const getLoadingCUParametersFromControlUnit = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) => {
  return getCUParametersLoadingState(state)[props.deviceId]?.[
    props.controlUnitId
  ];
};

export const getErrorCUParametersFromControlUnit = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) => {
  return getCUParametersErrorState(state)[props.deviceId]?.[
    props.controlUnitId
  ];
};
