import { DeviceControlActionMetadataAPIResponse } from '../../../device/device.model';
import { DigitalChannelEvent } from '../../../monitoring/monitoring.model';

/**
 * metadata is the only sure source from this list, other data will be load in time
 */
export interface ActionExtendedValue extends Partial<DigitalChannelEvent> {
  metadata: DeviceControlActionMetadataAPIResponse;
}
export const mergeActionMetadataAndEvent = (
  eventsMetadata: DeviceControlActionMetadataAPIResponse[] | undefined,
  valueEvents: Record<string, DigitalChannelEvent | undefined> | undefined
): ActionExtendedValue[] => {
  return (
    eventsMetadata?.map((metadata) => {
      const event = valueEvents?.[metadata.id];
      return { ...event, metadata };
    }) ?? []
  );
};
