import React, { Component } from 'react';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import { connect } from 'react-redux';
import mapAlarms from '../../../../assets/mapAlarms.png';
import deviceIcon from '../../../../assets/mapIconDevice.png';
import deviceIconNoCloudConnectivity from '../../../../assets/mapIconDeviceNoCloudConnectivity.png';
import deviceIconAlertCloudConnectivity from '../../../../assets/mapIconDeviceAlertCloudConnectivity.png';
import deviceIconAlertNoCloudConnectivity from '../../../../assets/mapIconDeviceAlertNoCloudConnectivity.png';
import deviceIconAlertInProgress from '../../../../assets/mapIconDeviceAlertInProgress.png';
import getAddressesCoordinates from '../../../../redux/addressesCoordinates/actions';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../handlingErrors';
import { withPolyglot } from '../../../../i18n';
import Util from '../../../../util/Util';
import {
  STATE_OK,
  STATE_OK_INPROGRESS,
  STATE_ALERT,
  STATE_ALERT_INPROGRESS,
} from '../Devices/components/Constants';
import './index.css';
import { getDevices } from '../../../../redux/devices/selectors';
import Polyglot from 'node-polyglot';
import { RootState } from '../../../../redux/store.model';
import { Device } from '../../../../model/device/device.model';
import DeviceModel from '../../../../model/device/Model';

const proxyBackendURL = new Util().getCommanderProxyURL();
const mapboxToken =
  'pk.eyJ1IjoiZHNlcm5haGlndWl0YSIsImEiOiJjanp0ajVoaXEwMHllM2NsMmhkbHU3cXNzIn0.gi85dWmTZgd3xlcgNCy2ww';
/* The stuttgart longitude and latitude are used to center the map, in the case
that no devices are defined */
const LONGITUDE_STUTTGART = 9.18;
const LATITUDE_STUTTGART = 48.77;

const cssDeviceStates: Record<string, string> = {
  STATE_OK: 'ok',
  STATE_OK_INPROGRESS: 'ok_inprogress',
  STATE_ALERT: 'alert',
  STATE_ALERT_INPROGRESS: 'alert_inprogress',
};

type AddressCoordinate = any;
type Coordinate = any;
type MarkerType = {
  id: any;
  coordinates: Coordinate[];
  devices: Array<Device & { coordinates: Coordinate[] }>;
};
type Props = {
  polyglot: Polyglot;
  accessToken: string;
  allDevices: Device[];
  addressesCoordinates: AddressCoordinate[];
  getAddressesCoordinates: (devices: Device[]) => unknown;
  activatedLoadingGroupsDevices: boolean;
} & HandlingErrorWrappedProps;

type State = {
  viewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  markers: MarkerType[];
  focusedMarker?: MarkerType | null;
};

/**
 * Get center
 * This function returns the coordinates where the map should be centered
 * @param array longitudes: array with all the device's longitudes
 * @param array latitudes: array with all the device's latitudes
 * @return object [centerLongitud, centerLatitude]
 */
export const getCenter = (longitudes: number[], latitudes: number[]) => {
  /* Definition map: center [longitude (horizontal), latitud(vertical)] */
  if (longitudes.length === 0 || latitudes.length === 0) {
    return [LONGITUDE_STUTTGART, LATITUDE_STUTTGART];
  }
  longitudes.sort((a, b) => a - b);
  latitudes.sort((a, b) => a - b);
  const centerLongitud =
    (longitudes[0] + longitudes[longitudes.length - 1]) / 2;
  const centerLatitude = (latitudes[0] + latitudes[latitudes.length - 1]) / 2;
  return [centerLongitud, centerLatitude];
};

/**
 * Get zoom
 * @param array longitudes
 * @return integer zoom: [0, 16]
 */
export const getZoom = (longitudes: number[]) => {
  if (longitudes.length === 0) {
    return 1;
  }
  longitudes.sort((a, b) => a - b);
  /* calculates zoom */
  let zoom =
    Math.ceil(
      (180 - (longitudes[longitudes.length - 1] - longitudes[0])) / 22.5
    ) - 3;
  zoom = zoom < 0 ? 0 : zoom;
  if (longitudes[longitudes.length - 1] - longitudes[0] < 1) {
    zoom = 16;
  }
  return zoom;
};

/**
 * Calculate viewport
 * @param array markers
 * @return object { latitude, longitude, zoom }
 */
const calculateViewport = (markers: MarkerType[]) => {
  const longitudes: number[] = [];
  const latitudes: number[] = [];

  markers.forEach((m: MarkerType) => {
    longitudes.push(m.coordinates[0].center[0]);
    latitudes.push(m.coordinates[0].center[1]);
  });

  const zoom = getZoom(longitudes);
  const [longitude, latitude] = getCenter(longitudes, latitudes);
  return { latitude, longitude, zoom };
};

/**
 * Get device icon
 * @param string state
 * @param url cloudConnectivity
 * @return url icon
 */
const getDeviceIcon = (state: string | undefined, cloudConnectivity: any) => {
  let icon = deviceIcon;
  /* select icon to be shown */
  if (state === STATE_ALERT) {
    icon = cloudConnectivity
      ? deviceIconAlertCloudConnectivity
      : deviceIconAlertNoCloudConnectivity;
  } else if (state === STATE_ALERT_INPROGRESS) {
    icon = deviceIconAlertInProgress;
  } else if (state === STATE_OK_INPROGRESS) {
    icon = deviceIconAlertInProgress;
  } else if (state === STATE_OK) {
    icon = cloudConnectivity ? deviceIcon : deviceIconNoCloudConnectivity;
  }

  return icon;
};

export class Map extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.updateViewport = this.updateViewport.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = {
      viewport: {
        latitude: LATITUDE_STUTTGART,
        longitude: LONGITUDE_STUTTGART,
        zoom: 8,
      },
      markers: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * Get device markers
   * @return array markerComponents
   */
  getDeviceMarkers() {
    const { markers } = this.state;
    const { polyglot } = this.props;
    const markerComponents: JSX.Element[] = [];

    markers.forEach((marker) => {
      const icon = marker.devices.map((d) =>
        getDeviceIcon(d.status, d.cloudConnectivity)
      )[0];
      markerComponents.push(
        <Marker
          key={marker.id}
          latitude={marker.coordinates[0].center[1]}
          longitude={marker.coordinates[0].center[0]}
        >
          <img
            className="marker-icon"
            src={icon}
            onClick={() => this.markerClicked(marker)}
            alt={polyglot.t('map.marker_icon')}
          />
        </Marker>
      );
    });

    return markerComponents;
  }

  /**
   * Get popup
   * @return object|null
   */
  getPopup() {
    const { focusedMarker } = this.state;
    const { accessToken, polyglot } = this.props;
    if (!focusedMarker) {
      return null;
    }
    return (
      <Popup
        latitude={focusedMarker.coordinates[0].center[1]}
        longitude={focusedMarker.coordinates[0].center[0]}
        closeButton={false}
        closeOnClick={false}
        onClose={() => this.setState({ focusedMarker: null })}
      >
        <div className="device-popup-wrapper">
          {focusedMarker.devices.map((device) => {
            let deviceCard = (
              <div className="content">
                <div>
                  <img
                    src={DeviceModel.getDeviceIcon(device.iconURL)}
                    className="icon-device"
                    alt={polyglot.t('map.icon_device')}
                  />
                </div>
                <div>
                  <b>{device.name}</b>
                  <br />
                  {device.street ? device.street : ''}{' '}
                  {device.zipcode ? device.zipcode : ''} <br />
                  {device.city ? device.city : ''}
                </div>
              </div>
            );

            if (device.cloudConnectivity) {
              deviceCard = (
                <a
                  href={`${proxyBackendURL}/${device.ip}/?token=${accessToken}`}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                >
                  {deviceCard}
                </a>
              );
            }

            return (
              // eslint-disable-next-line react/jsx-key
              <div
                className={`popup-map-device ${
                  cssDeviceStates[device.status || '']
                }`}
              >
                <div className="icons-bar">
                  {device.incidents.length > 0 && (
                    <a
                      href={`https://${window.location.hostname}/incidentmanager/device/${device.uuid}`}
                    >
                      <img
                        src={mapAlarms}
                        className="icon"
                        alt={polyglot.t('map.map_alarms')}
                      />
                    </a>
                  )}
                  <img
                    src={device.cloudConnectivityIcon}
                    className="icon"
                    alt={polyglot.t('map.icon_cloud_connectivity')}
                  />
                </div>
                {deviceCard}
              </div>
            );
          })}
        </div>
      </Popup>
    );
  }

  /**
   * Marker clicked
   * @param marker
   */
  markerClicked(marker: MarkerType) {
    this.setState({ focusedMarker: marker });
  }

  updateViewport(viewport: State['viewport']) {
    this.setState({ viewport });
  }

  /**
   * Load data
   * this function load the coordinates of the devices
   */
  async loadData() {
    const { handlingErrorsApi } = this.props;
    try {
      const { allDevices } = this.props;
      await this.props.getAddressesCoordinates(allDevices);

      /* addressesCoordinates has a dictionary with addresses and coordinates */
      const { addressesCoordinates } = this.props;

      /* Create an array with the devices where coordinates are founded */
      let devicesWithCoordinates = allDevices
        .map((d) => {
          const entry = addressesCoordinates.find(
            (dC) =>
              dC.zipcode === (d.zipcode ? d.zipcode.trim() : '') &&
              dC.street === (d.street ? d.street.trim() : '') &&
              dC.country === (d.country ? d.country.trim() : '') &&
              dC.city === (d.city ? d.city.trim() : '')
          );
          return {
            ...d,
            coordinates: entry?.coordinates,
          };
        })
        .filter((d) => d.coordinates);
      /* group devices in the same address { coordinates, devices: [] } */
      const markers: MarkerType[] = devicesWithCoordinates
        .map((d) => {
          const devices = devicesWithCoordinates.filter(
            (dD) =>
              dD.coordinates[0].center[0] === d.coordinates[0].center[0] &&
              dD.coordinates[0].center[1] === d.coordinates[0].center[1]
          );

          devicesWithCoordinates = devicesWithCoordinates.filter(
            (dD) =>
              dD.coordinates[0].center[0] !== d.coordinates[0].center[0] &&
              dD.coordinates[0].center[1] !== d.coordinates[0].center[1]
          );
          if (devices.length > 0) {
            return {
              coordinates: devices[0].coordinates,
              devices,
            };
          }
          return null;
        })
        .filter((m) => !!m) as MarkerType[];
      const viewport = calculateViewport(markers);
      this.setState({ markers, viewport });
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  render() {
    const { viewport } = this.state;
    const markers = this.getDeviceMarkers();
    const popup = this.getPopup();
    return (
      <ReactMapGL
        {...viewport}
        width="100vw"
        height="84vh"
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxApiAccessToken={mapboxToken}
        onViewportChange={this.updateViewport}
        onClick={() => this.setState({ focusedMarker: null })}
      >
        {markers}
        {popup}
      </ReactMapGL>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  allDevices: getDevices(state),
  addressesCoordinates: state.addressesCoordinates.addressesCoordinates,
  accessToken: state.auth.accessToken,
  activatedLoadingGroupsDevices: !!state.groups.activatedLoadingGroupsDevices,
});

export default connect(mapStateToProps, { getAddressesCoordinates })(
  withPolyglot(withHandlingErrors(Map))
);
