export type RecordLVL3<
  K1 extends keyof any,
  K2 extends keyof any,
  K3 extends keyof any,
  V
> = Record<K1, RecordLVL2<K2, K3, V | undefined> | undefined>;

export type RecordLVL2<K1 extends keyof any, K2 extends keyof any, V> = Record<
  K1,
  Record<K2, V | undefined> | undefined
>;

export const forEachLevel2 = <K1 extends keyof any, K2 extends keyof any, V>(
  dic: RecordLVL2<K1, K2, V>,
  callback: (value: V, k1: string, k2: string) => unknown
) => {
  Object.entries<Record<K2, V | undefined> | undefined>(dic).forEach(
    ([k1, dicLvl2]) =>
      Object.entries<V | undefined>(dicLvl2 ?? {}).forEach(([k2, value]) => {
        if (value) callback(value, k1, k2);
      })
  );
};
