import { ShowLevelOption } from '../../../redux/groups/selectors/getLevelOfView';
import { getTranslateManyDevicesFunction } from '../../../util/CUTranslation/getTranslateFunction';
import { Sensordata, SensordataSTChartLine } from '../monitoring.model';
import { getLabelName } from './getLabelName';

export function mapSensordataToSTChartLine(
  sensordata: Sensordata,
  deviceId: string,
  deviceName: string,
  CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
  showLevelOption: ShowLevelOption
): SensordataSTChartLine {
  const { values, ...sensordataOtherData } = sensordata;
  return {
    ...sensordataOtherData,
    deviceId,
    deviceName,
    name: getLabelName(
      showLevelOption,
      CUsTranslate(
        sensordata.channel,
        deviceId || '',
        sensordata.controlunit
      ) as string,
      sensordata.controlunitName,
      deviceName
    ),
    data: values.map((value) => ({
      // Unix timestamp (seconds)
      timestamp: Math.floor(value.timestamp / 1000),
      val: value.value,
    })),
  };
}
