import Model from '../../../model';
import GET_ADDRESSES_COORDINATES from './Types';

/**
 * Get addresses coordinates
 * @param class restClient
 * @param class polyglot
 * @param array devices
 */
const getAddressesCoordinates = (devices) => async (dispatch, getState) => {
  const model = new Model();
  const { addressesCoordinates } = getState().addressesCoordinates;
  let devicesAddresses = [];
  /* filter the addresses that the coordinates are already searched */
  if (addressesCoordinates.length === 0) {
    devicesAddresses = devices.slice();
  } else {
    devices.forEach((d) => {
      const entry = addressesCoordinates.find(
        (dC) =>
          dC.zipcode === (d.zipcode !== undefined ? d.zipcode.trim() : '') &&
          dC.street === (d.street !== undefined ? d.street.trim() : '') &&
          dC.country === (d.country !== undefined ? d.country.trim() : '') &&
          dC.city === (d.city !== undefined ? d.city.trim() : '')
      );
      if (entry === undefined) {
        devicesAddresses.push(d);
      }
    });
  }

  /* filter the addresses to send only the devices with valid address */
  let tempDevicesAddresses = [];
  devicesAddresses.forEach((d) => {
    if (
      d.country &&
      d.country.trim() !== '' &&
      d.city &&
      d.city.trim() !== '' &&
      ((d.street && d.street.trim() !== '') ||
        (d.zipcode && d.zipcode.trim() !== ''))
    ) {
      tempDevicesAddresses.push(d);
    }
  });
  devicesAddresses = tempDevicesAddresses.slice();

  /* remove duplicates from devicesAddresses to avoid to search two time the same address */
  tempDevicesAddresses = [];
  devicesAddresses.forEach((d) => {
    const entry = tempDevicesAddresses.find(
      (dA) =>
        (dA.zipcode ? dA.zipcode.trim() : '') ===
          (d.zipcode ? d.zipcode.trim() : '') &&
        (dA.street ? dA.street.trim() : '') ===
          (d.street ? d.street.trim() : '') &&
        (dA.country ? dA.country.trim() : '') ===
          (d.country ? d.country.trim() : '') &&
        (dA.city ? dA.city.trim() : '') === (d.city ? d.city.trim() : '')
    );
    if (entry === undefined) {
      tempDevicesAddresses.push(d);
    }
  });
  devicesAddresses = tempDevicesAddresses.slice();
  devicesAddresses = await model.getDevicesCoordinates(devicesAddresses);
  /* the newAddressesCoordinates array needs only the address and the coordinates */
  const newAddressesCoordinates = devicesAddresses.map((d) => ({
    street: d.street !== undefined ? d.street.trim() : '',
    zipcode: d.zipcode !== undefined ? d.zipcode.trim() : '',
    city: d.city !== undefined ? d.city.trim() : '',
    country: d.country !== undefined ? d.country.trim() : '',
    coordinates: d.coordinates,
  }));

  dispatch({
    type: GET_ADDRESSES_COORDINATES,
    payload: newAddressesCoordinates,
  });
};

export default getAddressesCoordinates;
