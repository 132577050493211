import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { warmUpGroups } from '../actions/thunks';
import {
  getGroupByPropGroupIdSelector,
  getGroups,
  isGroupsLoading,
} from '../selectors';

/**
 * Get and fetch group from groupID
 * @param deviceId
 * @returns [group, loading, error]
 * 
 * @usage
´´´ts
const [group, loading, error] = useGroupById("UUUID_GROUP")
´´´
 */
export function useGroupById(
  groupId: string | undefined,
  onError: (error: unknown) => unknown
) {
  const groups = useAppSelector(getGroups);
  const group = useAppSelector((state: RootState) =>
    getGroupByPropGroupIdSelector(state, { groupId })
  );

  const activatedLoadingGroupsDevices = useAppSelector(
    (state: RootState) => state.groups.activatedLoadingGroupsDevices
  );
  const groupsLoading = useAppSelector(isGroupsLoading);
  const [error, setError] = useState(undefined);
  const [fetched, setFetched] = useState(false);

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!fetched)
      Promise.resolve()
        .then(() => {
          if (!activatedLoadingGroupsDevices && groups?.length === 0) {
            return dispatch(warmUpGroups());
          }
        })
        .catch((error) => {
          setError(error);
          onError(error);
        })
        .finally(() => setFetched(true));
  });
  return [group, groupsLoading || !fetched, error] as const;
}
