/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class to transfor it
 */
export default class Calls {
  constructor(restCalls) {
    this.restCalls = restCalls;
  }

  /**
   * Set maintenance message device
   * @param string deviceId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageDevice(deviceId, message) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.setMaintenanceMessageDevice(
        deviceId,
        message,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Get maintenance messages device
   * This function gets all the maintenance messages of a device
   * @param string deviceId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesDevice(deviceId) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.getMaintenanceMessagesDevice(
        deviceId,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Update maintenance message device
   * @param string deviceId
   * @param string messageId
   * @param object message
   * @return bool
   */
  async updateMaintenanceMessageDevice(deviceId, messageId, message) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.updateMaintenanceMessageDevice(
        deviceId,
        messageId,
        message,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Delete maintenance message device
   * @param string deviceId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageDevice(deviceId, messageId) {
    const promise = new Promise(async (resolve, reject) => {
      this.restCalls.deleteMaintenanceMessageDevice(
        deviceId,
        messageId,
        (response) => resolve(response),
        (error) => reject(error),
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Set maintenance message group
   * @param string groupId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageGroup(groupId, message) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.setMaintenanceMessageGroup(
        groupId,
        message,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Get maintenance messages group
   * This function gets all the maintenance messages of a group
   * @param string groupId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesGroup(groupId) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.getMaintenanceMessagesGroup(
        groupId,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Update maintenance message group
   * @param string groupId
   * @param string messageId
   * @return bool
   */
  async updateMaintenanceMessageGroup(groupId, messageId, message) {
    const promise = new Promise((resolve, reject) => {
      this.restCalls.updateMaintenanceMessageGroup(
        groupId,
        messageId,
        message,
        (response) => resolve(response),
        reject,
        this.restClient
      );
    });
    return promise;
  }

  /**
   * Delete maintenance message group
   * @param string groupId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageGroup(groupId, messageId) {
    const promise = new Promise(async (resolve, reject) => {
      this.restCalls.deleteMaintenanceMessageGroup(
        groupId,
        messageId,
        (response) => resolve(response),
        (error) => reject(error),
        this.restClient
      );
    });
    return promise;
  }
}
