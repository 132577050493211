import { restClient as restClientHttp } from '../../rest';
import { ErrorRestST } from '../../rest/rest.model';
import Util from '../../util/Util';
import { SettingAPI, SettingsAllAPI } from './settings.model';

export default class SettingsCalls {
  readonly backendSettingsServiceURL = new Util().getSettingsServiceURL();
  constructor(private restClient = restClientHttp) {}

  async getSetting(settingName: string) {
    const url = `${this.backendSettingsServiceURL}/${settingName}`;
    return this.restClient.get<SettingAPI>(url).catch((error: ErrorRestST) => {
      if (error.response?.status === 404) {
        return undefined;
      }
      return Promise.reject(error);
    });
  }
  async getAllSetting() {
    const url = `${this.backendSettingsServiceURL}`;
    return this.restClient.get<SettingsAllAPI>(url);
  }

  async setSetting(settingName: string, value: string) {
    const url = `${this.backendSettingsServiceURL}/${settingName}`;
    const headers = {
      'Content-Type': 'text/plain',
    };
    return this.restClient.put(url, value, { headers });
  }
}
