import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import './confilctsMUI-Semantic.css';

export const componentsOverrides = {
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':disabled': {
          color: '#8b8a8ad1',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2185d0',
    },
    secondary: {
      // Nav color
      main: '#6f6f6fbf',
    },
  },
  typography: {
    subtitle2: {
      opacity: 0.8,
    },
  },
  components: componentsOverrides,
});

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
