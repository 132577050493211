import GET_ADDRESSES_COORDINATES from '../actions/Types';

const initialState = {
  addressesCoordinates: [] /* [street, zipcode, city, country, coordinates] */,
};

/**
 * Merge addresses coordinates
 * @param array addressesCoordinates
 * @param array newAddressesCoordinates
 * @param array merge both arrays
 */
const mergeAddressesCoordinates = (
  addressesCoordinates,
  newAddressesCoordinates
) => {
  addressesCoordinates = [...addressesCoordinates];
  newAddressesCoordinates.forEach((d) => {
    const entry = addressesCoordinates.find(
      (dC) =>
        dC.zipcode === (d.zipcode !== undefined ? d.zipcode.trim() : '') &&
        dC.street === (d.street !== undefined ? d.street.trim() : '') &&
        dC.country === (d.country !== undefined ? d.country.trim() : '') &&
        dC.city === (d.city !== undefined ? d.city.trim() : '')
    );
    if (entry === undefined) {
      addressesCoordinates.push(d);
    }
  });
  return addressesCoordinates;
};

export default function reducer(state = initialState, action) {
  let addressesCoordinates = [];
  switch (action.type) {
    case GET_ADDRESSES_COORDINATES:
      ({ addressesCoordinates } = state);
      return {
        ...state,
        addressesCoordinates: mergeAddressesCoordinates(
          addressesCoordinates,
          action.payload
        ),
      };
    default:
      return state;
  }
}
