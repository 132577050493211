import React, { useMemo } from 'react';
import { TYPE_CHART_TIMELINE, Chart, UiMessage } from 'stoerk-ui-components';
import { getLanguage } from '../../../../../../../../../i18n';
import Polyglot from 'node-polyglot';
import { STTimeLineChartData, TimeFrame } from '../../../monitoring.model';
import { isEmpty } from 'validate.js';
import filterDigitalChannelsByInternalTime from '../../../util/filterDigitalChannelsByInternalTime';

interface DigitalChannelsProps {
  data: STTimeLineChartData;
  polyglot: Polyglot;
  internalTimeFrame: TimeFrame;
  width: number;
  height: number;
  labels: any;
  controls: any[];
}

// eslint-disable-next-line react/display-name
export const DigitalChannels = React.memo((props: DigitalChannelsProps) => {
  const { data, polyglot, internalTimeFrame, labels, width, height } = props;
  const dataEmpty = isEmpty(data);
  const dataFilteredByInternalTime = useMemo(
    () => filterDigitalChannelsByInternalTime(data, internalTimeFrame),
    [data, internalTimeFrame]
  );

  if (dataEmpty)
    return (
      <UiMessage
        attached
        icon="warning"
        header=""
        content={polyglot.t(
          'device.monitoring.not_data_to_show_digital_channels'
        )}
      />
    );

  return (
    <Chart
      data={dataFilteredByInternalTime}
      type={TYPE_CHART_TIMELINE}
      // mode={mode}
      height={height}
      width={width}
      minDatetime={internalTimeFrame.start.unix()}
      maxDatetime={internalTimeFrame.end.unix()}
      zoom={true}
      language={getLanguage()}
      labels={labels}
    />
  );
});
