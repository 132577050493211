type Email = string;
export enum USER_REGISTER_STATUS {
  UNKNOWN = 'UNKNOWN',
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  DEACTIVATED = 'DEACTIVATED',
}

export type CheckUserResponse = Record<Email, USER_REGISTER_STATUS>;

/**
 * AuthorizeRedirectionResponseAPI:
 *
 * Example
 * ```json
 * {"Authcode":"ec3d76630543270c48c0db1e03a38a29"}
 * ```
 */
export interface AuthorizeRedirectionAPIResponse {
  Authcode: string;
}

/**
 * Example:
 * ```json
 * {
 *     "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJhdWQiOiJodHRwczovL2NvbW1hbmRlci1jbG91ZC5ldS9hcGkiLCJzdWIiOiIwN2UxM2Q5ZS0xYmY2LTQ1MzAtYTdjMS04NGZiY2FlYTg0NTMiLCJzY29wZSI6IntcIipcIjpbXCJhdXRob3JpemVkLXVzZXJcIl19IiwiaXNzIjoiYXV0aGVudGljYXRpb24tc2VydmljZUBjb21tYW5kZXItY2xvdWQuZXUiLCJhY3RpdmUiOiJ0cnVlIiwiZXhwIjoxNjMxMDQ4MDY0LCJpYXQiOjE2MzEwNDYyNjQsImVtYWlsIjoianVhbnNlLW5vLXVzZXItMDctMDktMjAyMUB5b3BtYWlsLmNvbSIsImNsaWVudF9pZCI6ImNsaWVudF9mbTlrNmUwNGZydjQybGVzNjlibGE2ZmF0ZSJ9.ivZcf8APEPtiZ54mVym1ZWKNwi8v7f_KyfqE4V6mS2yqygP-RuJvUCRaYG9z3v4df_c5xCOsSKAZWA9qVIHZtQ",
 *     "refresh_token": "e4af6d859d61d103a3b10b34c0b30bc8",
 *     "expires_in": 1800
 * }
 * ```
 */
export interface TokenAPIResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}
