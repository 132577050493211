import React, { Component } from 'react';
import { Grid, Image, Button, Popup, Segment } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { CardActions, Button as ButtonMUI } from '@mui/material';
import Polyglot from 'node-polyglot';
import BrowserUtil from '../../../../../util/BrowserUtil';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import SortUtil, { SortDirections } from '../../../../../util/SortUtil';
import ConnectGroupDeviceAssign from '../../GroupDeviceAssign';
import { deleteGroupDevice } from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import { withUserRightUtil } from '../../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import { Group } from '../../../../../model/group/group.model';
import { RootState } from '../../../../../redux/store.model';
import { getDevicesByIds } from '../../../../../redux/devices/selectors';
import { Device } from '../../../../../model/device/device.model';
import DeviceModel from '../../../../../model/device/Model';
import { STModal } from '../../../../commons/Modal';

import './index.css';

const browserUtil = new BrowserUtil();
const ASC = 'ASC';
const DESC = 'DESC';
type OwnProps = {
  group?: Group;
  closeEditGroup(...args: unknown[]): unknown;
};

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & OwnProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps &
  OpenSnackbarProps;

type State = {
  sort: {
    name: SortDirections;
  };
  devices: Device[];
  showMessageDeleteGroupDevice: boolean;
  showGroupDeviceAssign: boolean;
  deleteDeviceId: null | string;
};
/**
 * Group edit devices
 * the input for this class is an group object as below:
 * group = {
 *   id,
 *   name,
 *   iconURL,
 *   attributes: {},
 *   users: {},
 *  }
 * and will be transformed:
 * groupId
 * devices
 *
 * Behavior:
 * getDerivedStateFromProps and constructor (first props) => get props changes
 * componentDidMount (after constructor) and componentDidUpdate (after
 * getDerivedStateFromProps) => load the data
 */
export class GroupEditDevices extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    let devices = nextProps.devices;
    if (devices && devices.length > 0) {
      devices = SortUtil.multisort(devices, ['name'], [prevState.sort.name]);
    }

    return {
      devices,
    };
  }

  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  constructor(props: Props) {
    super(props);
    this.closeMessageDeleteGroupDevice =
      this.closeMessageDeleteGroupDevice.bind(this);
    this.openMessageDeleteGroupDevice =
      this.openMessageDeleteGroupDevice.bind(this);
    this.deleteGroupDevice = this.deleteGroupDevice.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.openGroupDeviceAssign = this.openGroupDeviceAssign.bind(this);
    this.closeGroupDeviceAssign = this.closeGroupDeviceAssign.bind(this);
    this.loadButtonDisplayPreferences =
      this.loadButtonDisplayPreferences.bind(this);
    let { devices } = props;
    if (devices && devices.length > 0) {
      devices = SortUtil.multisort(devices, ['name'], ['ASC']);
    }

    this.state = {
      sort: {
        name: ASC,
      },
      devices,
      showMessageDeleteGroupDevice: false,
      showGroupDeviceAssign: false,
      deleteDeviceId: null,
    };
  }

  /**
   * Component did update
   * will be run after getDerivedStateFromProps
   * the needed data will be load here
   */
  componentDidUpdate() {
    GroupEditDevices.fixBody();
  }

  loadButtonDisplayPreferences() {
    const { rightsUserUtil, group } = this.props;
    const showRemoveGroupDeviceBotton =
      rightsUserUtil.hasRightsToRemoveGroupDevice(group?.id);
    const showAssignGroupDeviceBotton =
      rightsUserUtil.hasRightsToAssignGroupDevice(group?.id);
    return { showRemoveGroupDeviceBotton, showAssignGroupDeviceBotton };
  }

  /**
   * Remove group user
   * this function call the rest api to remove a user from a group
   */
  async deleteGroupDevice(event: unknown, deviceId: string) {
    const { group, polyglot, openSnackbar, handlingErrorsApi } = this.props;
    let { devices } = this.state;
    try {
      if (!group) throw new Error('Group not found');

      await this.props.deleteGroupDevice(group.id, deviceId);
      devices = devices.filter((device) => device.uuid !== deviceId);
      this.setState({ devices });
      const message = {
        text: polyglot.t('group.remove_group_device_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
      this.closeMessageDeleteGroupDevice();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Sort by
   * @param string field : [name]
   */
  sortBy(field: 'name' = 'name') {
    const { sort } = this.state;
    let { devices } = this.state;
    sort[field] = sort[field] === ASC ? DESC : ASC;
    devices = SortUtil.multisort(devices, [field], [sort[field]]);
    this.setState({
      sort,
      devices,
    });
  }

  /**
   * Open message delete group device
   * this function opens a modal window with a message question
   */
  openMessageDeleteGroupDevice(event: unknown, deviceId: string) {
    this.setState({
      showMessageDeleteGroupDevice: true,
      deleteDeviceId: deviceId,
    });
  }

  /**
   * Close message delete group device
   */
  closeMessageDeleteGroupDevice() {
    this.setState({
      showMessageDeleteGroupDevice: false,
      deleteDeviceId: null,
    });
  }

  /**
   * Open group device assign
   * open modal window to assign devices to the group.
   */
  openGroupDeviceAssign() {
    this.setState({
      showGroupDeviceAssign: true,
    });
  }

  closeGroupDeviceAssign() {
    this.setState({ showGroupDeviceAssign: false });
  }

  render() {
    const { group, polyglot, closeEditGroup } = this.props;
    const {
      showGroupDeviceAssign,
      devices,
      sort,
      showMessageDeleteGroupDevice,
      deleteDeviceId,
    } = this.state;
    const { showRemoveGroupDeviceBotton, showAssignGroupDeviceBotton } =
      this.loadButtonDisplayPreferences();
    return (
      <>
        <ConnectGroupDeviceAssign
          groupId={group?.id}
          showGroupDeviceAssign={showGroupDeviceAssign}
          closeGroupDeviceAssign={this.closeGroupDeviceAssign}
        />
        <Segment>
          <Grid columns={4} className="groups-devices-list" container>
            {/* titles */}
            <Grid.Row className="device">
              <Grid.Column
                width={!browserUtil.getIsPhone() ? 2 : 3}
                verticalAlign="middle"
              />
              <Grid.Column
                width={!browserUtil.getIsPhone() ? 12 : 10}
                verticalAlign="middle"
              >
                {polyglot.t('group.devices.name')}
                <Button
                  basic
                  icon={sort.name === ASC ? 'caret down' : 'caret up'}
                  className="sort"
                  onClick={() => this.sortBy()}
                />
              </Grid.Column>
            </Grid.Row>
            {devices &&
              devices.map((device, index) => (
                <Grid.Row key={device.uuid} id={`device${index}`}>
                  {/* Avatar */}
                  <Grid.Column
                    width={!browserUtil.getIsPhone() ? 2 : 3}
                    verticalAlign="middle"
                  >
                    <Image
                      src={DeviceModel.getDeviceIcon(device.iconURL)}
                      size="mini"
                    />
                  </Grid.Column>
                  {/* name */}
                  <Grid.Column
                    width={!browserUtil.getIsPhone() ? 12 : 10}
                    verticalAlign="middle"
                  >
                    {device.name}
                  </Grid.Column>
                  {/* bottom */}
                  <Grid.Column
                    textAlign="right"
                    className="toolbar-buttons"
                    width={2}
                  >
                    <Button.Group>
                      {/* Button delete */}
                      {!browserUtil.getIsMobile()
                        ? showRemoveGroupDeviceBotton && (
                            <Popup
                              trigger={
                                <Button
                                  icon="delete"
                                  size="small"
                                  onClick={(event) =>
                                    this.openMessageDeleteGroupDevice(
                                      event,
                                      device.uuid
                                    )
                                  }
                                />
                              }
                              content={polyglot.t(
                                'group.tooltip.remove_group_device'
                              )}
                            />
                          )
                        : showRemoveGroupDeviceBotton && (
                            <Button
                              icon="delete"
                              size="small"
                              onClick={(event) =>
                                this.openMessageDeleteGroupDevice(
                                  event,
                                  device.uuid
                                )
                              }
                            />
                          )}
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
              ))}
          </Grid>
          <Grid padded="vertically">
            <Grid.Row />
            <Grid.Row>
              <Grid.Column width={16}>
                {!browserUtil.getIsMobile()
                  ? showAssignGroupDeviceBotton && (
                      <Popup
                        trigger={
                          <Button
                            primary
                            circular
                            icon="add"
                            size="huge"
                            className="raised-button"
                            floated="right"
                            onClick={this.openGroupDeviceAssign}
                            id="ButtonAssignGroupDevice"
                          />
                        }
                        content={polyglot.t(
                          'group.tooltip.open_window_assign_device'
                        )}
                      />
                    )
                  : showAssignGroupDeviceBotton && (
                      <Button
                        primary
                        circular
                        icon="add"
                        size="huge"
                        className="raised-button"
                        floated="right"
                        onClick={this.openGroupDeviceAssign}
                        id="ButtonAssignGroupDevice"
                      />
                    )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <ButtonMUI onClick={closeEditGroup}>
            {polyglot.t('group.close_button_title')}
          </ButtonMUI>
        </CardActions>

        {/* Modal window called when the user wants to delete device*/}
        <STModal
          open={showMessageDeleteGroupDevice}
          onClose={this.closeMessageDeleteGroupDevice}
          buttonActions={
            <>
              <ButtonMUI onClick={this.closeMessageDeleteGroupDevice}>
                {polyglot.t('general.no')}
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                color="error"
                onClick={(event) =>
                  this.deleteGroupDevice(event, deleteDeviceId as string)
                }
              >
                {polyglot.t('general.yes')}
              </ButtonMUI>
            </>
          }
        >
          <p>{polyglot.t('group.remove_group_device_message')}</p>
        </STModal>
      </>
    );
  }
}
const mapStateToProps = (state: RootState, props: OwnProps) => ({
  devices: getDevicesByIds(state, {
    devicesIds: props.group?.devices || [],
  }),
});

const connector = connect(mapStateToProps, { deleteGroupDevice });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, { deleteGroupDevice })(
  withHandlingErrors(
    withUserRightUtil(withSnackbar(withPolyglot(GroupEditDevices)))
  )
);
