import React, { Component } from 'react';
import { Tab, Image } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import ISO3316Util from '../../../../../../../util/ISO3316Util';
import BrowserUtil from '../../../../../../../util/BrowserUtil';
import DeviceIcon from '../../../../../../../assets/IconST7Landscape.svg';
import ConnectDeviceRemove from '../../DeviceRemove';
import ConnectRemoveGroupDevice from '../../RemoveGroupDevice';
import {
  putDevice,
  deleteDeviceIcon,
} from '../../../../../../../redux/devices/actions/thunks';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../../../../../../handlingErrors';
import { Device } from '../../../../../../../model/device/device.model';
import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import TextFieldFile from '../../../../../../commons/TextFieldFile';
import { IconUpload } from '../../../../../../../redux/groups/groups.model';

const Kbyte = 1024;
const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB
const browserUtil = new BrowserUtil();

type Props = ConnectedComponentProps &
  PolyglotComponentProps &
  OpenSnackbarProps &
  HandlingErrorWrappedProps &
  RightsUserUtilComponentProps &
  OwnProps;

type OwnProps = {
  device: Device;
  groupId?: string;
  closeEditDevice(...args: unknown[]): unknown;
  setIsPossibleToChangeTab(...args: unknown[]): unknown;
};

type State = {
  deviceId: string;
  device: any;
  showImageLoad: boolean;
  iconUpload: {
    fileName?: string;
    toolTip?: string;
    file?: File;
    imagePreviewUrl?: string;
  };
  errorText: string;
  showRemoveDevice: boolean;
  showRemoveGroupDevice: boolean;
  showButtonDeleteDevice: boolean;
};
export class Details extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.openRemoveDevice = this.openRemoveDevice.bind(this);
    this.closeRemoveDevice = this.closeRemoveDevice.bind(this);
    this.openRemoveGroupDevice = this.openRemoveGroupDevice.bind(this);
    this.closeRemoveGroupDevice = this.closeRemoveGroupDevice.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.imageValidation = this.imageValidation.bind(this);
    this.deleteIcon = this.deleteIcon.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    const device = JSON.parse(JSON.stringify(props.device));
    this.state = {
      deviceId: props.device.uuid,
      device,
      showImageLoad: false,
      iconUpload: {
        fileName: '',
        toolTip: '',
        imagePreviewUrl: '',
      },
      errorText: '',
      showRemoveDevice: false,
      showRemoveGroupDevice: false,
      showButtonDeleteDevice: false,
    };
  }

  async componentDidMount() {
    const { showButtonDeleteDevice } = this.loadButtonsToDisplay();
    this.setState({
      showButtonDeleteDevice,
    });
  }

  componentDidUpdate() {
    /**
     * Bug iOS Mobile devices: by modal windows with input fields, the property
     * position <> fixed produces a weird behavior: change from a input to other
     * input makes that the window spring (scroll down in the window behind) and the
     * cursor is placed in the wrong position
     */
    if (
      browserUtil.getIsiPad() ||
      browserUtil.getIsiPhone() ||
      browserUtil.getIsiPod()
    ) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
  }
  /**
   * Reset iconUpload if the icon change
   * @returns
   */
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.device.iconURL !== prevState.device.iconURL) {
      return {
        device: { ...prevState.device, iconURL: nextProps.device.iconURL },
      };
    }

    return null;
  }

  /**
   * Load buttons to display
   * @return object { showButtonDeleteDevice }
   */
  loadButtonsToDisplay() {
    const { groupId, rightsUserUtil } = this.props;
    let showButtonDeleteDevice = false;
    if (groupId) {
      showButtonDeleteDevice =
        rightsUserUtil.hasRightsToRemoveGroupDevice(groupId);
    } else {
      showButtonDeleteDevice = rightsUserUtil.isAuthorizedUser();
    }
    return { showButtonDeleteDevice };
  }

  /**
   * Open remove device
   */
  openRemoveDevice() {
    this.setState({ showRemoveDevice: true });
  }

  /**
   * Close remove device
   */
  closeRemoveDevice() {
    this.setState({ showRemoveDevice: false });
  }

  /**
   * Open remove group device
   */
  openRemoveGroupDevice() {
    this.setState({ showRemoveGroupDevice: true });
  }

  /**
   * Close remove group device
   */
  closeRemoveGroupDevice() {
    this.setState({ showRemoveGroupDevice: false });
  }

  /**
   * Update device
   * @param object event
   * @param string event
   */
  updateDevice(
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | SelectChangeEvent<string>,
    field: string
  ) {
    const { device } = this.state;
    const { setIsPossibleToChangeTab } = this.props;
    device[field] = event.target.value;
    this.setState({ device });
    setIsPossibleToChangeTab(false);
  }

  /**
   * Delete icon
   * this function deletes/reset the current icon
   */
  async deleteIcon() {
    const { polyglot, openSnackbar, handlingErrorsApi } = this.props;
    const { deviceId } = this.state;
    try {
      await this.props.deleteDeviceIcon(deviceId);
      const message = {
        text: polyglot.t('device.delete_icon_successful_message'),
        type: 'ok',
      };
      const { device } = this.state;
      this.setState({ device });
      openSnackbar(message);
    } catch (error: any) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Save chages
   * 1. call rest api to send the device data
   * 2. call rest api to send the icon
   * by upload the icon if we receive as error the status code = 400 => means problems with the size
   */
  async saveChanges() {
    const {
      setIsPossibleToChangeTab,
      openSnackbar,
      polyglot,
      handlingErrorsApi,
    } = this.props;
    const { deviceId, device, iconUpload } = this.state;
    try {
      await this.props.putDevice(
        deviceId,
        device,
        iconUpload.fileName ? (iconUpload as IconUpload) : undefined
      );
      setIsPossibleToChangeTab(true);
      /* Snackbar message ok */
      const message = {
        header: '',
        text: polyglot.t('device.save_successful_message'),
        type: 'ok',
      };
      this.setState({
        iconUpload: {
          fileName: '',
          toolTip: '',
          file: undefined,
          imagePreviewUrl: '',
        },
      });
      openSnackbar(message);
    } catch (error: any) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Image validation
   *
   * 1. is image
   * 2. size
   * @param file image
   * @return boolean
   */
  imageValidation(image: { type: string | string[]; size: number }) {
    const { polyglot, openSnackbar } = this.props;
    let message: any = {};
    this.setState({ errorText: '' });

    if (image.type.indexOf('image') === -1) {
      this.setState({ errorText: `${polyglot.t('error.only_format_images')}` });
      /* Snackbar message error */
      message = {
        header: '',
        text: polyglot.t('error.only_format_images'),
        type: 'error',
      };
      openSnackbar(message);
      return false;
    }
    if (image.size > maxSizeImageKbyte * Kbyte) {
      this.setState({
        errorText: `${polyglot.t(
          'error.images_size_restriction'
        )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`,
      });

      /* Snackbar message error */
      message = {
        header: '',
        text: `${polyglot.t(
          'error.images_size_restriction'
        )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`,
        type: 'error',
      };
      openSnackbar(message);
      return false;
    }
    return true;
  }

  /**
   * Image change
   *
   * saves the file data in state variables
   * show a preview of the file
   * @param file
   */
  imageChange(file: React.ChangeEvent<HTMLInputElement>) {
    const iconFile = file.target.files && file.target.files[0];

    if (iconFile && this.imageValidation(iconFile)) {
      const iconUpload = {
        fileName: iconFile.name,
        toolTip: `upload file ${iconFile.name}`,
        file: iconFile,
      };

      this.setState({
        iconUpload,
        showImageLoad: true,
      });

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const iconUpload2: IconUpload = {
          ...iconUpload,
          imagePreviewUrl: fileReader.result as string,
        };

        this.setState({
          iconUpload: iconUpload2,
          showImageLoad: false,
        });
      };
      fileReader.readAsDataURL(iconFile);
    } else {
      this.setState({
        iconUpload: {},
      });
    }
  }

  render() {
    const {
      showButtonDeleteDevice,
      device,
      showRemoveGroupDevice,
      showRemoveDevice,
      errorText,
      iconUpload,
      showImageLoad,
    } = this.state;
    const { groupId, closeEditDevice, polyglot } = this.props;
    const deviceRemove = groupId ? (
      <ConnectRemoveGroupDevice
        // @ts-ignore FIXME:  ConnectRemoveGroupDevice not type
        deviceId={device.uuid}
        showRemoveGroupDevice={showRemoveGroupDevice}
        closeRemoveGroupDevice={this.closeRemoveGroupDevice}
        closeEditDevice={closeEditDevice}
        groupId={groupId}
      />
    ) : (
      <ConnectDeviceRemove
        // @ts-ignore FIXME:  ConnectDeviceRemove not type
        deviceId={device.uuid}
        showRemoveDevice={showRemoveDevice}
        closeRemoveDevice={this.closeRemoveDevice}
        closeEditDevice={closeEditDevice}
      />
    );
    return (
      <div>
        <Tab.Pane
          attached={false}
          style={{ border: 'none', boxShadow: 'none' }}
        >
          {showButtonDeleteDevice && deviceRemove}
          <Paper>
            <Grid container spacing={2} sx={{ padding: 1 }}>
              {/* input name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onChange={(event) => this.updateDevice(event, 'name')}
                  value={device.name !== undefined ? device.name : ''}
                  label={polyglot.t('device.name')}
                />
              </Grid>
              {/* input street */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onChange={(event) => this.updateDevice(event, 'street')}
                  value={device.street !== undefined ? device.street : ''}
                  label={polyglot.t('device.street')}
                />
              </Grid>
              {/* input zip code and city */}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  onChange={(event) => this.updateDevice(event, 'zipcode')}
                  value={device.zipcode !== undefined ? device.zipcode : ''}
                  label={polyglot.t('device.zipcode')}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  onChange={(event) => this.updateDevice(event, 'city')}
                  value={device.city !== undefined ? device.city : ''}
                  label={polyglot.t('device.city')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{polyglot.t('device.country')}</InputLabel>
                  <Select
                    label={polyglot.t('device.country')}
                    onChange={(event) => this.updateDevice(event, 'country')}
                    value={device.country || ''}
                  >
                    {ISO3316Util.getAllCountries(polyglot.locale()).map(
                      (country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Stack paddingX={2}>
                  <Image
                    src={device.iconURL || DeviceIcon}
                    alt={polyglot.t('device.tooltip.current_icon')}
                    size="small"
                    className="device-icon"
                    centered
                    wrapped
                  />
                  {iconUpload.imagePreviewUrl && (
                    <Image
                      src={iconUpload.imagePreviewUrl}
                      alt={iconUpload.toolTip}
                      size="small"
                      className="device-icon"
                      centered
                      wrapped
                      ma
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item container xs={8} alignContent="center">
                <TextFieldFile
                  fullWidth
                  id={'device-edit-icon'}
                  label={polyglot.t('device.icon')}
                  onChange={this.imageChange}
                  error={!!errorText}
                  helperText={errorText}
                />
                {device.iconURL && device.iconURL !== DeviceIcon && (
                  <Button onClick={this.deleteIcon} color="error">
                    {polyglot.t('device.delete_icon')}
                  </Button>
                )}
                {showImageLoad && <CircularProgress />}
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent="end">
                  {showButtonDeleteDevice && (
                    <Button
                      id="ButtonRemoveDevice"
                      color="error"
                      onClick={
                        groupId
                          ? this.openRemoveGroupDevice
                          : this.openRemoveDevice
                      }
                    >
                      {polyglot.t('device.remove_button_title')}
                    </Button>
                  )}
                  <Button onClick={this.saveChanges} variant={'contained'}>
                    {polyglot.t('device.save_button_title')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Tab.Pane>
      </div>
    );
  }
}

const connector = connect(null, {
  putDevice,
  deleteDeviceIcon,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withSnackbar(withPolyglot(withUserRightUtil(connector(Details))))
);
