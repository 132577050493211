import validate from 'validate.js';
import { getPolyglot } from '../i18n';
import Polyglot from 'node-polyglot';
import { Subtract } from 'utility-types';
import React from 'react';
/**
 * Validation
 *
 * Help class to validate input fields
 */
class ValidationUtil {
  [x: string]: any;
  constructor(polyglot: Polyglot) {
    this.activatedPositiveControlFeedbackClass =
      'green checkmark icon form-control-feedback-cloud';
    this.activatedNegativeControlFeedbackClass =
      'red remove icon form-control-feedback-cloud';
    this.emailValidationConstraints = {
      address: {
        email: true,
      },
    };
    this.nameValidationConstraint = {
      name: {
        presence: { allowEmpty: false },
      },
    };

    this.validationErrorMessages = {
      groupName: polyglot.t('error.formular.enter_valid_group_name'),
      email: polyglot.t('error.formular.enter_valid_email'),
      passwordConfirmation: polyglot.t('error.formular.password_confirmation'),
      number: polyglot.t('error.formular.number'),
    };
    this.defaultErrorMessage = polyglot.t('error.formular.enter_valid_data');
  }

  determineFormFeedbackClass(evaluatedField: any) {
    if (evaluatedField === null) {
      return 'hidden';
    }

    if (evaluatedField) {
      return this.activatedPositiveControlFeedbackClass;
    }
    return this.activatedNegativeControlFeedbackClass;
  }

  /**
   * Get error message
   * this function a error message
   */
  getErrorMessage(field: string) {
    if (this.validationErrorMessages[field] === undefined) {
      return this.defaultErrorMessage;
    }
    return this.validationErrorMessages[field];
  }

  /**
   * Validate email address
   * @param string address: email address
   * @param boolean isRequired
   * @return boolean
   */
  validateEmailAddress(address: any, isRequired: any) {
    if (!address && isRequired) {
      return false;
    }

    if (!address && !isRequired) {
      return true;
    }

    const validationResult =
      validate({ address }, this.emailValidationConstraints) === undefined;
    return validationResult;
  }

  validateName(name: any) {
    const validationResult =
      validate({ name }, this.nameValidationConstraint) === undefined;
    return validationResult;
  }

  checkPasswordConfirmation(pass: any, confirm: any) {
    this.pass = pass;
    this.confirm = confirm;
    if (!this.pass) {
      return false;
    }
    return this.pass === this.confirm;
  }

  /**
   * Validate number
   * @param integer number
   * @return bool
   */
  static validateNumber(number: any) {
    const numberNumber = Number(number);
    return Number.isInteger(numberNumber);
  }

  /**
   * Validate decimal number or integers
   * @param integer number
   * @return bool
   */
  static validateDecimalNumber(number: any) {
    const numberNumber = Number(number);
    if (Number.isNaN(numberNumber)) {
      return false;
    }
    return typeof numberNumber === 'number';
  }
}

export interface ValidationUtilComponentProps {
  validationUtil: ValidationUtil;
}

export function withValidationUtil<P extends ValidationUtilComponentProps>(
  WrappedComponent: React.ComponentType<P>
) {
  const WrappedComponentWithValidationUtil = (
    props: Subtract<P, ValidationUtilComponentProps>
  ) => {
    const polyglot = getPolyglot();
    return (
      <WrappedComponent
        {...(props as P)}
        validationUtil={new ValidationUtil(polyglot)}
      />
    );
  };

  return WrappedComponentWithValidationUtil;
}

export default ValidationUtil;
