import {
  IconButton,
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';

const Input = styled('input')({
  display: 'none',
});

export default function TextFieldFile(props: TextFieldProps) {
  const { id, onChange, value, InputProps, ...otherProps } = props;
  const [idState] = useState(id || uniqueId('text-field-file-'));
  const [file, setFile] = useState<File | null>(value as File);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    const fileEvent = e.target.files ? e.target.files[0] : null;
    setFile(fileEvent);
  };
  return (
    <TextField
      {...otherProps}
      id={idState}
      value={(file && file.name) || ''}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <label htmlFor={`${idState}-icon-button-file`}>
              <Input
                accept="image/*"
                id={`${idState}-icon-button-file`}
                type="file"
                onChange={handleOnChange}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </InputAdornment>
        ),
      }}
    />
  );
}
