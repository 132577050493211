import { Paper } from '@mui/material';
import moment from 'moment';
import {
  BoxMainSensor,
  MODE_CLOUD_LIGHT,
  RotationList,
} from 'stoerk-ui-components';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../../i18n';
import { DEFAULT_VALUE_SENSOR } from '../../../../../../../../../../../../model/controlUnit/util/constants';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../../../../../../../model/controlUnit/util/sensor/getValueStrFromSensor';
import { SensordataNewestMixed } from '../../../../../../../../../../../../model/monitoring/monitoring.model';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';

export interface MainSensorProps extends PolyglotComponentProps {
  mainSensor?: SensordataNewestMixed;
  isOn?: boolean;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
  type: ReturnType<typeof useCurrentStateByControlUnitId>['type'];
  hasAlarms: ReturnType<typeof useCurrentStateByControlUnitId>['hasAlarms'];
  currentAlarms: ReturnType<
    typeof useCurrentStateByControlUnitId
  >['currentAlarms'];
}
export function MainSensor(props: MainSensorProps) {
  const {
    mainSensor,
    type,
    CUTranslate,
    isOn,
    currentAlarms,
    hasAlarms,
    polyglot,
  } = props;

  const value = getValueStrFromSensor(mainSensor, isOn, polyglot);
  const name =
    CUTranslate(mainSensor?.metadata?.name ?? mainSensor?.channel ?? '') ??
    DEFAULT_VALUE_SENSOR;

  const unitValue = getUnitStrFromSensor(mainSensor, isOn);
  const title = mainSensor?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        mainSensor?.timestamp
      ).fromNow()}`
    : undefined;

  const titleAlarm = hasAlarms ? (
    <RotationList
      list={currentAlarms.map((item) =>
        CUTranslate(item?.name ?? item?.eventID ?? '', 'ram', 'longText')
      )}
    />
  ) : undefined;

  return (
    <div className="ControlUnit-MainSensor-container">
      <Paper variant="outlined" sx={{ padding: 2, paddingBottom: 0 }}>
        <BoxMainSensor
          title={title}
          value={value}
          titleValue={name}
          unitValue={unitValue}
          type={type}
          mode={MODE_CLOUD_LIGHT}
          titleAlarm={titleAlarm}
        />
      </Paper>
    </div>
  );
}

export default withPolyglot(MainSensor);
