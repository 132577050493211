import Polyglot from 'node-polyglot';
import {
  STATE_DEACTIVATED,
  STATE_NOT_AVAILABLE,
  STATE_OVERFLOW,
  STATE_UNDERFLOW,
} from './constants';

/**
 * Decode state
 * @param float stateNew => event.sn
 * @param object polygot
 * @return string stateDescription
 */
export const decodeState = (stateNew: number, polyglot: Polyglot) => {
  let stateDescription;
  /* bitweise operations */
  if (stateNew === 0) {
    return undefined;
  } else if ((stateNew & STATE_NOT_AVAILABLE) === STATE_NOT_AVAILABLE) {
    stateDescription = polyglot.t('device.events.state.not_available');
  } else if ((stateNew & STATE_DEACTIVATED) === STATE_DEACTIVATED) {
    stateDescription = polyglot.t('device.events.state.disabled');
  } else if ((stateNew & STATE_OVERFLOW) === STATE_OVERFLOW) {
    stateDescription = polyglot.t('device.events.state.overflow');
  } else if ((stateNew & STATE_UNDERFLOW) === STATE_UNDERFLOW) {
    stateDescription = polyglot.t('device.events.state.underflow');
  }
  return stateDescription;
};
