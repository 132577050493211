import moment from 'moment';
import { Light, TurnOnOff } from 'stoerk-ui-components';
import {
  Box,
  Paper,
  Typography,
  Button,
  ListItemButton,
  ListItemText,
  ListItem,
  List,
  ListItemIcon,
  Divider,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useState } from 'react';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../i18n';
import { getSetting, settings } from '../../../mocks/settings';
import { useTick } from '../../../util/hook/useTick';
import { FlexeserveZone } from '../../../model';
import { STModal } from '../../../../../../../components/commons/Modal';

const LIGHT_COLOR = '#ede34c';
export interface FlexeserveZoneCellHeaderProps extends PolyglotComponentProps {
  zone: FlexeserveZone;
  setSettingZone: (setting: string, zone: FlexeserveZone, i: number) => void;
  index: number;
}
export const FlexeserveZoneCellHeader = withPolyglot(
  (props: FlexeserveZoneCellHeaderProps) => {
    const { zone, polyglot, setSettingZone, index } = props;
    const [openModal, setOpenModal] = useState(false);
    const onClickHeaderCell = () => {
      setOpenModal(true);
    };
    const onCloseModal = () => {
      setOpenModal(false);
    };
    const onClickSetting = (setting: string) => {
      setSettingZone(setting, zone, index);
      onCloseModal();
    };

    return (
      <>
        <Button sx={{ padding: 0 }} onClick={onClickHeaderCell}>
          <Paper
            className="FlexeserveLayoutGrid-cell FlexeserveLayoutGrid-columnHeader cell columnHeader"
            sx={{
              height: (theme) => theme.spacing(12),
              minWidth: (theme) => theme.spacing(24),
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              margin: (theme) => theme.spacing(1),
            }}
          >
            <FlexeserveZoneCellHeaderBody {...props} />
          </Paper>
        </Button>
        <STModal
          open={openModal}
          onClose={onCloseModal}
          title={polyglot.t('nuttall.flexeserve.select_setting')}
          fullWidth
          maxWidth="sm"
        >
          <List>
            {settings.map((item) => (
              <ListItem disablePadding key={item.displayName}>
                <ListItemButton
                  onClick={() => onClickSetting(item.settingName)}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.displayName}
                    secondary={`${item.temperatureValue}${item.temperatureUnit}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}

            {!settings.length ? (
              <>
                <Box sx={{ margin: 2 }}>
                  <Typography>
                    {polyglot.t('nuttall.flexeserve.no_settings_available')}
                  </Typography>
                </Box>
                <Divider sx={{ margin: 2 }} />
              </>
            ) : null}
            <ListItem disablePadding>
              <ListItemButton onClick={() => onClickSetting('ZONE_OFF')}>
                <ListItemIcon
                  sx={{
                    paddingRight: 1,
                    '&& svg': (theme) => ({
                      fill: (theme) => theme.palette.text.primary,
                      height: theme.typography.h5.fontSize,
                    }),
                  }}
                >
                  <TurnOnOff />
                </ListItemIcon>
                <ListItemText
                  primary={polyglot.t('nuttall.flexeserve.setting_off')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => onClickSetting('ZONE_LIGHT')}>
                <ListItemIcon
                  sx={{
                    paddingRight: 1,
                    '&& svg': (theme) => ({
                      fill: '#ede34c',
                      height: theme.typography.h5.fontSize,
                    }),
                  }}
                >
                  <Light />
                </ListItemIcon>
                <ListItemText
                  primary={polyglot.t('nuttall.flexeserve.setting_light')}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </STModal>
      </>
    );
  }
);

const FlexeserveZoneCellHeaderBody = (props: FlexeserveZoneCellHeaderProps) => {
  const { zone, polyglot } = props;

  const warning = useTick(
    () => zone.warning && moment(zone.warning).isSameOrAfter(moment()),
    1000,
    [zone.warning]
  );

  // Show when light is active
  if (zone.light)
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h3"
          component={'span'}
          sx={{
            '&& svg': (theme) => ({
              fill: LIGHT_COLOR,
              height: theme.typography.h3.fontSize,
            }),
          }}
        >
          <Light />
        </Typography>
      </Box>
    );
  if (!zone.active)
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h3"
          component={'span'}
          sx={{
            '&& svg': (theme) => ({
              fill: (theme) => theme.palette.text.primary,
              height: theme.typography.h3.fontSize,
            }),
          }}
        >
          <TurnOnOff />
        </Typography>
      </Box>
    );
  const setting = getSetting(zone.settingName);
  if (!setting) return null;

  return (
    <React.Fragment>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" component={'span'}>
          {props.zone.settingName}
        </Typography>
      </Box>

      {/* Without settings name */}

      <Typography sx={{ marginBottom: 1 }}>
        {setting?.temperatureValue} {setting?.temperatureUnit}
      </Typography>

      <Box
        className="ColumnHeader-status"
        title={`${polyglot.t('nuttall.flexeserve.status')}: ${
          warning
            ? polyglot.t('nuttall.flexeserve.status_warning')
            : polyglot.t('nuttall.flexeserve.status_ok')
        }`}
        sx={{
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          position: 'absolute',
          borderRadius: '10px',
          backgroundColor: warning ? 'warning.main' : 'success.main',
          height: (theme) => theme.spacing(2),
          width: (theme) => theme.spacing(2),
        }}
      ></Box>
    </React.Fragment>
  );
};
