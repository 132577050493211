import React, { Component } from 'react';
import { Button, Segment, Header, Icon } from 'semantic-ui-react';
import withStyles, { WithStylesProps } from 'react-jss';
import Polyglot from 'node-polyglot';

import BrowserUtil from '../../../../util/BrowserUtil';
import { withPolyglot } from '../../../../i18n';
import { STModal } from '../../../../components/commons/Modal';

const browserUtil = new BrowserUtil();
const isMobile = browserUtil.getIsMobile();
const styles = {
  phone: { 'background-color': '#d94d15 !important' },
  icon: { color: '#d94d15 !important' },
};

export type Props = {
  polyglot: Polyglot;
  showContactUs: boolean;
  closeContactUs(...args: unknown[]): unknown;
} & WithStylesProps<typeof styles>;

interface State {
  showContactUs: boolean;
}

class ContactUs extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    return {
      showContactUs: nextProps.showContactUs,
    };
  }

  constructor(props: Props) {
    super(props);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.state = {
      showContactUs: props.showContactUs,
    };
  }

  closeContactUs() {
    this.props.closeContactUs();
  }

  render() {
    const { classes } = this.props;
    return (
      <STModal
        open={this.state.showContactUs}
        onClose={this.closeContactUs}
        title={this.props.polyglot.t('contact_us.title')}
        fullWidth
        maxWidth="md"
        buttonActions={
          <Button onClick={this.closeContactUs}>
            {this.props.polyglot.t('contact_us.close_button_title')}
          </Button>
        }
      >
        <Header as="h5" textAlign="center">
          {this.props.polyglot.t('contact_us.description_header')}
        </Header>
        <p>{this.props.polyglot.t('nuttall.contact_us.description_body')}</p>
        <Segment.Group className={isMobile ? 'vertical' : 'horizontal'}>
          <Segment>
            <Header as="h4" icon textAlign="center">
              <Icon name="marker" size="huge" className={classes.icon} />
              <Header.Subheader>
                <p>
                  {this.props.polyglot.t(
                    'nuttall.contact_us.address_street_number'
                  )}
                </p>
                <p>
                  {this.props.polyglot.t('nuttall.contact_us.address_zip_city')}
                </p>
              </Header.Subheader>
            </Header>
          </Segment>

          <Segment inverted className={classes.phone}>
            <Header as="h4" icon textAlign="center" inverted>
              <Icon name="phone" size="huge" className="inverted" />
              <Header.Subheader>
                <p>{this.props.polyglot.t('nuttall.contact_us.phone')}</p>
                <p>{this.props.polyglot.t('nuttall.contact_us.fax')}</p>
              </Header.Subheader>
            </Header>
          </Segment>

          <Segment>
            <Header as="h4" icon textAlign="center">
              <Icon name="mail" size="huge" className={classes.icon} />
              <Header.Subheader>
                <p>{this.props.polyglot.t('nuttall.contact_us.email')}</p>
                <p>{this.props.polyglot.t('nuttall.contact_us.website')}</p>
              </Header.Subheader>
            </Header>
          </Segment>
        </Segment.Group>
      </STModal>
    );
  }
}

export default withStyles(styles)(withPolyglot(ContactUs));
