import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { Button } from '@mui/material';
import ValidationUtil from '../../../../util/ValidationUtil';
import BrowserUtil from '../../../../util/BrowserUtil';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../util/rights';
import ConnectedGroupEditDetails from './Details';
import ConnectGroupEditContacts from './Contacts';
import ConnectGroupEditUsers from './Users';
import ConnectGroupEditDevices from './Devices';
import ConnectGroupIncidentConfigurationEdit from '../../../incidentManager/groupIncidentConfigurationEdit';
import MaintenanceMessages from './MaintenanceMessages/Configuration';
import GroupIconDefault from '../../../../assets/GroupIconDefaultLandscape.svg';
import {
  getMaintenanceMessagesGroup,
  deleteMaintenanceMessageGroup,
  updateMaintenanceMessageGroup,
  setMaintenanceMessageGroup,
} from '../../../../redux/maintenanceMessage/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import { OpenSnackbarProps } from '../../../../handlingErrors';
import { RootState } from '../../../../redux/store.model';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import { STDialog, ModalTabs } from '../../../commons/Modal';
import { IconUpload } from '../../../../redux/groups/groups.model';

const Kbyte = 1024;
const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB
const browserUtil = new BrowserUtil();
/**
 * OwnProps is need because withSnackbar is bad typed and not support the inference
 */
type OwnProps = {
  closeEditGroup(): unknown;
  showEditGroup: boolean;
  groupId?: string;
};

type State = {
  animation: null | string;
  duration: number;
  visible: boolean;
  iconUpload?: IconUpload;
  allowToChangeTab: boolean;
  activeIndex: number;
  newActiveIndex?: number;
  showDataNoSavedModal: boolean;
  showImageLoad?: boolean;
};
/**
 * Group edit
 */
export class GroupEdit extends Component<Props, State> {
  validationUtil: ValidationUtil;
  constructor(props: Props) {
    super(props);
    this.activeTransition = this.activeTransition.bind(this);
    this.imageValidation = this.imageValidation.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.closeEditGroup = this.closeEditGroup.bind(this);
    this.setIsPossibleToChangeTab = this.setIsPossibleToChangeTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.remainTab = this.remainTab.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    this.state = {
      animation: null,
      duration: 500,
      visible: true,
      allowToChangeTab: true,
      activeIndex: 0,
      showDataNoSavedModal: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    /**
     * Bug iOS devices: allow the scrollbar to large windows
     */
    if (
      browserUtil.getIsiPad() ||
      browserUtil.getIsiPhone() ||
      browserUtil.getIsiPod()
    ) {
      const modal = document.getElementById('modal');
      if (modal !== null) {
        modal.classList.add('modal-ios');
      }
    }
    const { group } = this.props;
    if (prevProps.group?.id !== group?.id) {
      this.setState({ iconUpload: undefined });
    }
  }

  onTabsChange = (event: React.SyntheticEvent<Element, Event>, data: any) => {
    const { allowToChangeTab } = this.state;
    if (allowToChangeTab) {
      this.setState({
        activeIndex: data,
      });
    } else {
      this.setState({
        showDataNoSavedModal: true,
        newActiveIndex: data,
      });
    }
  };

  /**
   * On change change
   *
   * saves the file data in state variables
   * show a preview of the file
   * @param file
   */
  onChangeImage(file: { target: { files: File[] } }) {
    const iconFile: File = file.target.files[0];

    if (this.imageValidation(iconFile)) {
      this.setState({
        iconUpload: {
          file: iconFile,
          fileName: iconFile.name,
          imagePreviewUrl: '',
        },
        showImageLoad: true,
      });

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        this.setState({
          iconUpload: {
            file: iconFile,
            fileName: iconFile.name,
            imagePreviewUrl: fileReader.result as string,
          },
          showImageLoad: false,
          allowToChangeTab: false,
        });
      };
      fileReader.readAsDataURL(iconFile);
    }
  }

  /**
   * Set is possible to change tab
   * @param bool allowToChangeTab
   */
  setIsPossibleToChangeTab(allowToChangeTab: boolean) {
    this.setState({
      allowToChangeTab,
    });
  }

  /**
   * Change tab:
   * this function is called if the user wants to leave this panel without
   * to save the changes
   */
  changeTab() {
    const { newActiveIndex } = this.state;
    this.setState({
      allowToChangeTab: true,
      activeIndex: newActiveIndex as number,
      showDataNoSavedModal: false,
    });
  }

  /**
   * Remain tab
   * this function is called if the user wants to remain in the panel to save the changes
   */
  remainTab() {
    this.setState({
      allowToChangeTab: false,
      showDataNoSavedModal: false,
    });
  }

  /**
   * Image validation
   *
   * 1. is image
   * 2. size
   * @param file image
   * @return boolean
   */
  imageValidation(image: File) {
    const { polyglot, openSnackbar } = this.props;
    if (image.type.indexOf('image') === -1) {
      /* Snackbar message error */
      const message = {
        text: polyglot.t('error.only_format_images'),
        type: 'error',
      };
      openSnackbar(message);

      return false;
    }
    if (image.size > maxSizeImageKbyte * Kbyte) {
      /* Snackbar message error */
      const message = {
        text: `${polyglot.t(
          'error.images_size_restriction'
        )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`,
        type: 'error',
      };
      openSnackbar(message);

      return false;
    }

    return true;
  }

  /**
   * Active Transition
   */
  activeTransition() {
    const visible = true;
    const animation = visible ? 'shake' : null;
    this.setState({ visible, animation });
  }

  closeEditGroup() {
    const { closeEditGroup } = this.props;
    document.body.style.position = '';
    document.body.style.width = '';
    const modal = document.getElementById('modal');
    if (modal !== null) {
      modal.classList.remove('modal-ios');
    }
    closeEditGroup();
  }

  render() {
    const { iconUpload, showImageLoad, activeIndex, showDataNoSavedModal } =
      this.state;
    const {
      group,
      rightsUserUtil,
      messagesGroups,
      messagesGroupsLoading,
      polyglot,
      showEditGroup,
    } = this.props;
    if (!group) return <p>No group</p>;
    const showMaintenanceMessages =
      group && rightsUserUtil.hasRightsToReadMessagesGroup(group.id);
    const GroupEditDetailsForm = (
      <ConnectedGroupEditDetails
        // @ts-ignore: FIXME: ConnectedGroupEditDetails not typed
        group={group}
        onChangeImage={this.onChangeImage}
        iconUpload={iconUpload}
        showImageLoad={showImageLoad}
        activeTransition={this.activeTransition}
        closeEditGroup={this.closeEditGroup}
        setIsPossibleToChangeTab={this.setIsPossibleToChangeTab}
      />
    );
    const GroupEditContactsForm = (
      <ConnectGroupEditContacts
        group={group}
        activeTransition={this.activeTransition}
        closeEditGroup={this.closeEditGroup}
        setIsPossibleToChangeTab={this.setIsPossibleToChangeTab}
      />
    );
    const GroupEditUsersForm = (
      <ConnectGroupEditUsers
        group={group}
        closeEditGroup={this.closeEditGroup}
      />
    );

    const GroupEditDevicesForm = (
      <ConnectGroupEditDevices
        group={group}
        closeEditGroup={this.closeEditGroup}
      />
    );

    const GroupEditIncidentConfigurationForm = (
      <ConnectGroupIncidentConfigurationEdit
        groupId={group?.id}
        usersIds={group?.users ?? []}
        activeTransition={this.activeTransition}
        closeEditGroup={this.closeEditGroup}
        setIsPossibleToChangeTab={this.setIsPossibleToChangeTab}
      />
    );

    const GroupEditMaintenanceMessagesForm = (
      <MaintenanceMessages
        id={group?.id}
        hasRightsToUpdate={rightsUserUtil.hasRightsToUpdateMessageGroup(
          group?.id
        )}
        hasRightsToDelete={rightsUserUtil.hasRightsToDeleteMessageGroup(
          group?.id
        )}
        showCloseWindow
        closeWindow={this.closeEditGroup}
        getMaintenanceMessages={this.props.getMaintenanceMessagesGroup}
        deleteMaintenanceMessage={this.props.deleteMaintenanceMessageGroup}
        updateMaintenanceMessage={this.props.updateMaintenanceMessageGroup}
        setMaintenanceMessage={this.props.setMaintenanceMessageGroup}
        messages={messagesGroups}
        loading={messagesGroupsLoading}
      />
    );

    const tabs = [
      {
        title: polyglot.t('group.tabs.details'),
        content: GroupEditDetailsForm,
      },
      {
        title: polyglot.t('group.tabs.contacts'),
        content: GroupEditContactsForm,
      },
      {
        title: polyglot.t('group.tabs.users'),
        content: GroupEditUsersForm,
      },
      {
        title: polyglot.t('group.tabs.devices'),
        content: GroupEditDevicesForm,
      },
      {
        title: polyglot.t('group.tabs.incident_configuration'),
        content: GroupEditIncidentConfigurationForm,
      },
    ];

    if (showMaintenanceMessages) {
      tabs.push({
        title: polyglot.t('group.tabs.maintenance_messages_configuration'),
        content: GroupEditMaintenanceMessagesForm,
      });
    }

    let icon = group?.iconURL;
    if (iconUpload?.imagePreviewUrl) {
      icon = iconUpload.imagePreviewUrl;
    } else if (!icon) {
      icon = GroupIconDefault;
    }

    return (
      <div>
        <ModalTabs
          open={showEditGroup}
          onClose={this.closeEditGroup}
          title={group?.name}
          iconURL={icon}
          tabs={tabs}
          onChange={this.onTabsChange}
          value={activeIndex}
        />

        {/* Modal window called when the user wants to go to other tab and there
          are unsaved changes */}
        <STDialog
          open={showDataNoSavedModal}
          onClose={this.remainTab}
          buttonActions={
            <>
              <Button color="error" onClick={this.changeTab}>
                {polyglot.t('general.yes')}
              </Button>
              <Button variant="contained" onClick={this.remainTab}>
                {polyglot.t('general.no')}
              </Button>
            </>
          }
        >
          <p>{polyglot.t('group.usaved_changes_message')}</p>
        </STDialog>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  messagesGroups: state.maintenanceMessage.groups,
  messagesGroupsLoading: state.maintenanceMessage.groupsLoading,
  group: getGroupByPropGroupIdSelector(state, props),
});

const connector = connect(mapStateToProps, {
  getMaintenanceMessagesGroup,
  deleteMaintenanceMessageGroup,
  updateMaintenanceMessageGroup,
  setMaintenanceMessageGroup,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

type Props = ConnectedComponentProps &
  OwnProps &
  OpenSnackbarProps &
  RightsUserUtilComponentProps &
  PolyglotComponentProps;

export default withPolyglot(
  withSnackbar(withUserRightUtil(connector(GroupEdit)))
);
