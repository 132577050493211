import React from 'react';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import { GroupEdit } from '../../../../../model/group/group.model';
import ErrorText from '../../../../commons/ErrorText';
import TextFieldFile from '../../../../commons/TextFieldFile';
import { IconUpload } from '../../../../../redux/groups/groups.model';

const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB
interface OwnProps {
  updateFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => unknown;
  group: GroupEdit;
  onChangeImage: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  iconUpload?: IconUpload;
  showImageLoad?: boolean;
  updateOtherFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    index: number
  ) => unknown;
  deleteIcon: () => unknown;
  deleteOtherFields: (e: unknown, index: number) => unknown;
  closeEditGroup: () => unknown;
  save: () => unknown;
}
interface Props
  extends ValidationUtilComponentProps,
    PolyglotComponentProps,
    OwnProps {}

/**
 * Group edit details desktop
 */
export function GroupEditDetailsView(props: Props) {
  const {
    group,
    updateFields,
    polyglot,
    validationUtil,
    onChangeImage,
    iconUpload,
    deleteIcon,
    showImageLoad,
    deleteOtherFields,
    updateOtherFields,
    save,
    closeEditGroup,
  } = props;
  return (
    <form>
      <Stack spacing={2}>
        <TextField
          label={polyglot.t('group.name')}
          value={group.name}
          onChange={(event) => updateFields(event, 'name')}
          required
          error={!validationUtil.validateName(group.name)}
          helperText={
            !validationUtil.validateName(group.name) && (
              <ErrorText>
                {validationUtil.getErrorMessage('groupName')}
              </ErrorText>
            )
          }
        />
        <TextFieldFile
          label={polyglot.t('group.avatar')}
          onChange={onChangeImage}
          value={iconUpload?.file}
          helperText={`${polyglot.t(
            'group.max_size'
          )}: ${maxSizeImageKbyte} ${polyglot.t('group.bytes')}`}
        />
        {group.iconURL !== null && (
          <div>
            <Button
              id="iconReset"
              color="error"
              size="small"
              onClick={deleteIcon}
            >
              {polyglot.t('group.delete_icon')}
            </Button>
          </div>
        )}
        {showImageLoad && <CircularProgress />}
        <Card>
          <CardContent>
            <Typography variant="subtitle1" paddingX={1}>
              {props.polyglot.t('group.fields.title')}
            </Typography>
            {group.otherFields &&
              group.otherFields.map((field, index) => (
                <Card key={index} sx={{ margin: 1, padding: 1 }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          id={`otherFieldsField${index}`}
                          label={polyglot.t('group.fields.field')}
                          value={field.field}
                          onChange={(event) =>
                            updateOtherFields(event, 'field', index)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextField
                          id={`otherFieldsValue${index}`}
                          label={polyglot.t('group.fields.value')}
                          value={field.value}
                          onChange={(event) =>
                            updateOtherFields(event, 'value', index)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(event) => deleteOtherFields(event, index)}
                      startIcon={<DeleteIcon />}
                    >
                      {polyglot.t('button.delete')}
                    </Button>
                  </CardActions>
                </Card>
              ))}
          </CardContent>
        </Card>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={closeEditGroup}>
            {polyglot.t('group.close_button_title')}
          </Button>
          <Button id="saveButton" onClick={save} variant="contained">
            {polyglot.t('group.save_button_title')}
          </Button>
        </CardActions>
      </Stack>
    </form>
  );
}

export default withPolyglot(withValidationUtil(GroupEditDetailsView));
