import { createAsyncThunk } from '@reduxjs/toolkit';
import IncidentsCalls from '../../../model/incidentManager/Calls';
import {
  IncidentAPIResponse,
  PublicIncidentAPIResponse,
} from '../../../model/incidentManager/incident.model';
import { ErrorRestST } from '../../../rest/rest.model';
import { actionsDevices } from '../../devices/slice';
import { AppDispatch, RootState } from '../../store.model';
import { nameReducer } from '../incidents.model';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------
export const fetchPublicIncidentsByDevicesIds = createAsyncThunk<
  Record<string, PublicIncidentAPIResponse[]>,
  string[],
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/fetchPublicIncidentsByDevicesIds`,
  async (devicesIds: string[], thunkAPI) => {
    // Check if the feature is enabled
    const featureEnabled =
      !!thunkAPI.getState().featureToggle['incident-manager'];
    if (!featureEnabled) return {};

    const incidentsCalls = new IncidentsCalls();
    const incidents = await incidentsCalls.getPublicIncidentsByIds(devicesIds);
    thunkAPI.dispatch(actionsDevices.updateIncidents(incidents));

    return incidents;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchIncidentsByDevicesIds = createAsyncThunk<
  Record<string, IncidentAPIResponse[]>,
  string[],
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/fetchIncidentsByDevicesIds`,
  async (devicesIds: string[], thunkAPI) => {
    // Check if the feature is enabled
    const featureEnabled =
      !!thunkAPI.getState().featureToggle['incident-manager'];
    if (!featureEnabled) return {};

    if (devicesIds.length === 0) return {};
    const incidentsCalls = new IncidentsCalls();
    const incidents = await incidentsCalls.getIncidentsByDeviceIds(devicesIds);
    thunkAPI.dispatch(actionsDevices.updateIncidents(incidents));

    return incidents;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const getIncidentByIncidentId = createAsyncThunk<
  IncidentAPIResponse,
  string,
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/getIncidentByIncidentId`,
  async (incidentId: string, thunkAPI) => {
    const incidentsCalls = new IncidentsCalls();
    const incident = await incidentsCalls.getIncidentByIncidentId(incidentId);
    // TODO: verify in need load device
    thunkAPI.dispatch(actionsDevices.updateIncident(incident));

    return incident;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);
