import TopBarMenuNuttall from '../../../customized/alan-nuttall-flexeserve-eu/components/commons/topBarMenu/TopBarMenu';
import { ToggleComponent } from '../../../featureToggle';
import TopBarMenu from './TopBarMenu';
export * from './TopBarMenu';

export default ToggleComponent(TopBarMenu, {
  StyledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': TopBarMenuNuttall,
  },
});
