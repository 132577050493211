import { CommanderControlUnitChannelFilterLogicalObject } from '../../components/GroupManager/components/Devices/components/Monitoring/util/filter.model';
import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';
import { mapControlUnitFilterLogicalObjectToAPIFilter } from './util/mapControlUnitFilterLogicalObjectToAPIFilter';
import {
  DigitalEventsControlUnits,
  Sensordata,
  SensordataNewest,
} from './monitoring.model';
import * as callTypes from './MonitoringCallTypes';

export class MonitoringCalls {
  readonly sensorDataServiceURL = new Util().getSensorDataServiceURL();
  readonly eventServiceURL = new Util().getEventServiceURL();
  constructor(private restClient = restClientHttp) {}

  getSensordataByDeviceID(
    deviceID: string,
    start = 0,
    end = 0,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject
  ) {
    const filter = mapControlUnitFilterLogicalObjectToAPIFilter(
      commanderFilterObject
    );

    if (filter === '{}') return Promise.resolve([]);

    const url = `${this.sensorDataServiceURL}/${deviceID}?start=${start}&end=${end}&filter=${filter}&onlyValid=true`;
    return this.restClient.callPromise<Sensordata[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getSensordataByDeviceID
    );
  }

  getEventsByID(
    deviceID: string,
    start = 0,
    end = 0,
    commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject
  ) {
    const filter = mapControlUnitFilterLogicalObjectToAPIFilter(
      commanderFilterObject
    );
    if (filter === '{}') return Promise.resolve([]);

    const url = `${this.eventServiceURL}/${deviceID}?start=${start}&end=${end}&filter=${filter}&onlyValid=true`;
    return this.restClient.callPromise<DigitalEventsControlUnits[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getSensordataByDeviceID
    );
  }

  getCurrentEventsByID(deviceID: string) {
    const url = `${this.eventServiceURL}/${deviceID}/newest`;
    return this.restClient.get<DigitalEventsControlUnits[]>(url);
  }

  getCurrentSensorDataByID(deviceID: string) {
    const url = `${this.sensorDataServiceURL}/${deviceID}/newest`;
    return this.restClient.get<SensordataNewest[]>(url);
  }
}
