import { DeviceControlUnitsEventsMetadataAPIResponse } from '../../device/device.model';
import { DigitalChannelEvent } from '../monitoring.model';
import { getDigitalEventValue } from './mapDigitalEvent';

export const filterAlarmsFromEvents = (
  eventMetadata: DeviceControlUnitsEventsMetadataAPIResponse | undefined,
  event: DigitalChannelEvent
) => {
  const active = getDigitalEventValue(event.value, eventMetadata);
  if (!active) return false;
  // a = alarm
  return event.groups?.includes('a');
};
