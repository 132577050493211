import React, { Component } from 'react';
import { Container, Loader, Grid } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import {
  TableSort,
  UiCheckbox,
  UiButton,
  withSnackbar,
} from 'stoerk-ui-components';
import moment from 'moment';
import './index.css';
import {
  getUnreadNotifications,
  setReadStatus,
  setAllNotificationsReadStatus,
} from '../../redux/notifications/actions';
import { initializeLoadUnreadNotificationsPeriodically } from '../../redux/notifications';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import {
  withUserRightUtil,
  RightsUserUtilComponentProps,
} from '../../util/rights';
import {
  withHandlingErrors,
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
} from '../../handlingErrors';
import { DATE_FORMAT } from '../../util/Constants';
import { RootState } from '../../redux/store.model';
import { NotificationsAPIResponse } from '../../model/notifications/notifications.model';
import fetchOwnRights from '../../redux/rights/actions';

type Props = HandlingErrorWrappedProps &
  RightsUserUtilComponentProps &
  OpenSnackbarProps &
  PolyglotComponentProps &
  ConnectedComponentProps;

type State = { showLoadingMessage: boolean };
/**
 * Notifications
 * Onle the unread notifications have been shown
 */
export class Notifications extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.markAllRead = this.markAllRead.bind(this);
    this.markRead = this.markRead.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = {
      showLoadingMessage: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * Load Data
   */
  async loadData() {
    try {
      const { activatedLoadingUnreadNotifications, fetchingRightsCycle } =
        this.props;
      this.setState({ showLoadingMessage: true });
      if (!fetchingRightsCycle) {
        await this.props.fetchOwnRights();
      }

      if (!activatedLoadingUnreadNotifications) {
        await this.props.getUnreadNotifications();
        initializeLoadUnreadNotificationsPeriodically();
      }
      this.setState({ showLoadingMessage: false });
    } catch (error) {
      this.setState({ showLoadingMessage: false });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Mark all notifications as read
   */
  async markAllRead() {
    try {
      const { polyglot, openSnackbar } = this.props;
      await this.props.setAllNotificationsReadStatus(true);
      const message = {
        header: '',
        text: polyglot.t(
          'notifications.mark_all_notifications_read_successful_message'
        ),
        type: 'ok',
      };
      openSnackbar(message);
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Mark read
   * @props int timestamp
   */
  async markRead(timestamp: number) {
    try {
      await this.props.setReadStatus(timestamp, true);
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Generate table data
   */
  generateTableData() {
    const { unreadNotifications } = this.props;
    return unreadNotifications.map((notification: NotificationsAPIResponse) => {
      const readIcon = (
        <UiCheckbox
          checked={false}
          onChange={() => this.markRead(notification.timestamp)}
        />
      );

      return {
        timestamp: moment(notification.timestamp).format(DATE_FORMAT),
        message: notification.message,
        read: readIcon,
      };
    });
  }

  render() {
    const { unreadNotifications, polyglot, rightsUserUtil } = this.props;
    const { showLoadingMessage } = this.state;
    const showMessages = rightsUserUtil.hasRightReadMessages();
    const tableData = this.generateTableData();
    const columnsFormat = {
      timestamp: {
        allowSort: true,
        title: polyglot.t('notifications.timestamp'),
      },
      message: {
        allowSort: true,
        title: polyglot.t('notifications.message'),
      },
      read: {
        allowSort: false,
        title: polyglot.t('notifications.read'),
      },
    };

    if (!showMessages && !showLoadingMessage) {
      return (
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                {polyglot.t('notifications.no_rights_read_notifications')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    if (showLoadingMessage) {
      return (
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Loader active inline />
                {polyglot.t('group.loading_data_message')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container>
        {unreadNotifications.length > 0 && (
          <Container
            textAlign="right"
            className="container-button-mark-all-read"
          >
            <UiButton
              label={polyglot.t('button.mark_all_read')}
              iconName="envelope open"
              onClick={() => this.markAllRead()}
              sizeButton="medium"
              primary
            />
          </Container>
        )}
        <TableSort
          data={tableData}
          columnsFormat={columnsFormat}
          rowsPage={25}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  unreadNotifications: state.notifications.unread || [],
  activatedLoadingUnreadNotifications:
    state.notifications.activatedLoadingUnreadNotifications,
  fetchingRightsCycle: state.auth.fetchingRightsCycle,
});

const connector = connect(mapStateToProps, {
  getUnreadNotifications,
  setReadStatus,
  setAllNotificationsReadStatus,
  fetchOwnRights,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withUserRightUtil(withSnackbar(withPolyglot(Notifications)))
  )
);
