import React from 'react';
import withStyles from 'react-jss';

import Footer from '../../../../components/footer/Footer';

const styles = {
  '@global': {
    '.add-device-button button, .add-device-button-fix button, .raised-button':
      {
        'box-shadow': '0 2px 5px 0 rgba(0, 0, 0, 0.26) !important',
        'background-color': '#d94d15 !important',
      },
    '.footer-navigation .menu': { background: 'rgba(0, 0, 0, 1) !important;' },
  },
};

const FooterWhitUrl = (props) => (
  <Footer {...props} urlAboutUs={'http://www.flexeserve.com/'} />
);
export default withStyles(styles)(FooterWhitUrl);
