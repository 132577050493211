import React, { Component } from 'react';
import { Grid, Segment, Checkbox } from 'semantic-ui-react';
import { Button } from '@mui/material';
import { TableSort } from 'stoerk-ui-components';
import { connect, ConnectedProps } from 'react-redux';
import Iframe from 'react-iframe';
import Model from '../../../../model';
import {
  fetchPairableCameras,
  fetchGroupCameras,
  pairCameras,
} from '../../../../redux/cameras/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../handlingErrors';
import { RootState } from '../../../../redux/store.model';
import { Camera } from '../../../../model/cameras/cameras.model';
import { STModal } from '../../../commons/Modal';

type Props = {
  group: string;
  show: boolean;
  allowedToPair?: boolean;
  close(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps;

type State = {
  selectedCams: Camera[];
  authLink?: string | null;
};

export class AddCamera extends Component<Props, State> {
  cameraModel: Model;
  constructor(props: Props) {
    super(props);
    this.save = this.save.bind(this);
    this.updateCameraSelection = this.updateCameraSelection.bind(this);
    this.authorize = this.authorize.bind(this);
    this.cameraModel = new Model();
    this.state = {
      selectedCams: [],
    };
  }

  componentDidUpdate() {
    const { show, allowedToPair, pairable, group } = this.props;
    if (show && allowedToPair && !pairable[group]) {
      this.props.fetchPairableCameras(group);
    }
  }

  getTableData() {
    const { polyglot, group, pairable } = this.props;
    const dataFormat = {
      checkbox: {
        allowSort: false,
        title: '',
      },
      title: {
        allowSort: true,
        title: polyglot.t('camera.title'),
      },
    };

    let cameras: Camera[] = [];
    if (group && pairable && pairable[group]) {
      cameras = pairable[group];
    }

    const data = cameras.map((cam) => ({
      checkbox: <Checkbox onChange={() => this.updateCameraSelection(cam)} />,
      title: cam.name,
    }));

    return { data, dataFormat };
  }

  updateCameraSelection(camera: Camera) {
    let { selectedCams } = this.state;
    const currentlySelected =
      selectedCams.filter((cam) => cam.id === camera.id).length > 0;
    if (currentlySelected) {
      selectedCams = selectedCams.filter((cam) => !cam.id === camera.id);
    } else {
      selectedCams.push(camera);
    }
    this.setState({ selectedCams });
  }

  async save() {
    const { close, handlingErrorsApi, group } = this.props;
    const { selectedCams } = this.state;
    try {
      await this.props.pairCameras(group, selectedCams);
      await this.props.fetchGroupCameras(group);
      this.setState({ selectedCams: [] });
      close();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  async authorize() {
    const { group } = this.props;
    const authLink = await this.cameraModel.getAuthURL(group);
    this.setState({ authLink });
  }

  render() {
    const { data, dataFormat } = this.getTableData();
    const { show, polyglot, close, group } = this.props;
    const { authLink } = this.state;
    return (
      <div>
        <STModal
          open={show}
          title={polyglot.t('camera.add')}
          onClose={close}
          buttonActions={
            <>
              <Button
                onClick={() => {
                  this.setState({ selectedCams: [] });
                  close();
                }}
              >
                {polyglot.t('device.cancel_button_title')}
              </Button>
              <Button variant="outlined" onClick={this.authorize}>
                {polyglot.t('camera.authorize_login')}
              </Button>
              <Button variant="contained" onClick={this.save}>
                {polyglot.t('device.save_button_title')}
              </Button>
            </>
          }
        >
          <Segment>
            <Grid container className="camera-overview">
              <div className="camera-table-container">
                <TableSort data={data} columnsFormat={dataFormat} />
              </div>
            </Grid>
          </Segment>
        </STModal>
        <STModal
          open={!!authLink}
          onClose={() => this.setState({ authLink: null })}
          title="authorize logi account"
          buttonActions={
            <Button
              variant="contained"
              onClick={() => {
                this.props.fetchPairableCameras(group);
                this.setState({ authLink: null });
              }}
            >
              {polyglot.t('device.save_button_title')}
            </Button>
          }
        >
          <Segment>
            <Grid container className="camera-overview">
              {authLink && (
                <Iframe
                  url={authLink}
                  width="350px"
                  height="350px"
                  position="relative"
                />
              )}
            </Grid>
          </Segment>
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  pairable: state.cameras.pairable as Record<string, string[]>,
});

const connector = connect(mapStateToProps, {
  fetchPairableCameras,
  fetchGroupCameras,
  pairCameras,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withHandlingErrors(withPolyglot(AddCamera)));
