import { useEffect } from 'react';
import { warmUpGroups } from '../../groups/actions/thunks';
import { useGroupById } from '../../groups/hooks/useGroupById';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getDevicesFromProps } from '../selectors';

/**
 * Use redux to get and fetch device
 * @param deviceId usually is the queryParam
 * @param onError callback when call fail
 * @returns
 */
export const useDevices = (
  deviceIds: string[],
  onError?: (error: unknown) => unknown
) => {
  const [, loadingGroups] = useGroupById(undefined, console.error);
  const devices = useAppSelector((state: RootState) =>
    getDevicesFromProps(state, { deviceIds })
  );
  const needFetch = devices.some(
    (device) => !device?.data && !device?.loadingData
  );
  const deviceLoading = devices.some((device) => device?.loadingData);

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (needFetch) {
      Promise.resolve(dispatch(warmUpGroups())).catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needFetch, dispatch]);
  return [devices, deviceLoading || needFetch || loadingGroups] as const;
};
