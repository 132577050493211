import React, { Component } from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BreadcrumbNew from '../commons/breadcrumbNew';
import GroupIconDefault from '../../assets/GroupIconDefaultLandscape.svg';
import DeviceIcon from '../../assets/IconST7Landscape.svg';
import { getGroupSelectedByQueryParamSelector } from '../../redux/groups/selectors';
import './index.css';
import { Device } from '../../model/device/device.model';
import { Group } from '../../model/group/group.model';
import { RootState } from '../../redux/store.model';
import { getDeviceSelected } from '../../redux/devices/selectors';
import IncidentManager from './IncidentManager';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';

interface Props
  extends RouteComponentProps<{ groupId?: string; deviceId?: string }>,
    PolyglotComponentProps,
    ConnectedComponentProps {}
type State = {};

/**
 * Incident manager
 * This class show a list of incidents for a device or a group
 */
export class IncidentManagerPage extends Component<Props, State> {
  /**
   * Header
   * @param object device
   * @param object group
   * @return object
   */
  static Header({ device, group }: { device?: Device; group?: Group }) {
    let iconURL;
    let name;
    if (device?.uuid) {
      ({ iconURL, name } = device);
      if (iconURL === undefined || !iconURL) {
        iconURL = DeviceIcon;
      }
    } else if (group && Object.keys(group).length > 0) {
      ({ iconURL, name } = group);
      if (iconURL === undefined || !iconURL) {
        iconURL = GroupIconDefault;
      }
    }
    return (
      <div className="incident-header-image-title">
        <div>
          <Image size="mini" verticalAlign="top" spaced="right" src={iconURL} />
        </div>
        <div>{name}</div>
      </div>
    );
  }

  render() {
    const { polyglot, history, match, device, group } = this.props;
    const { groupId, deviceId } = match.params;

    const sectionsNavigation = [
      { content: polyglot.t('button.back'), onClick: () => history.goBack() },
      { content: polyglot.t('incident_manager.title') },
    ];
    return (
      <div>
        <BreadcrumbNew sections={sectionsNavigation} />
        <Container className="incident-manager">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} verticalAlign="bottom">
                {((groupId !== undefined && groupId !== null) ||
                  (deviceId !== undefined && deviceId !== null)) && (
                  <IncidentManagerPage.Header device={device} group={group} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <IncidentManager />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string; deviceId?: string }>
) => ({
  group: getGroupSelectedByQueryParamSelector(state, props),
  device: getDeviceSelected(state, props),
});

const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withPolyglot(
  withRouter(connect(mapStateToProps)(IncidentManagerPage))
);
