import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { CommanderMonitoringFilterLogicalObject } from '../../../components/GroupManager/components/Devices/components/Monitoring/util/filter.model';
import { actionsFeatureToggle } from '../../../featureToggle';
import { MonitoringService } from '../../../model/monitoring/MonitoringService';
import SettingsCalls from '../../../model/settings/Calls';
import { ErrorRestST } from '../../../rest/rest.model';
import { ZipDeviceControlUnit } from '../../controlUnit/controlUnit.model';
import { getCUsTranslationsSelector } from '../../CUTranslation/selectors';
import { ShowLevelOption } from '../../groups/selectors/getLevelOfView';
import { AppDispatch, RootState } from '../../store.model';
import { nameReducer } from '../monitoring.model';
import { FilterStoredProps, getPatchFilter } from '../selectors';

export interface FetchMetricsArgs {
  devicesControlUnits: ZipDeviceControlUnit[];
  digitalFilterObjectEvents: CommanderMonitoringFilterLogicalObject;
  analogFilterObjectEvents: CommanderMonitoringFilterLogicalObject;
  start: number;
  end: number;
  showLevelOption: ShowLevelOption;
}

export const fetchMetrics = createAsyncThunk(
  `${nameReducer}/fetchSensorData`,
  (
    {
      devicesControlUnits,
      start,
      end,
      digitalFilterObjectEvents,
      analogFilterObjectEvents,
      showLevelOption,
    }: FetchMetricsArgs,
    thunkAPI
  ) => {
    const model = new MonitoringService();

    const CUsTranslate = getCUsTranslationsSelector(
      thunkAPI.getState() as RootState
    );

    const controlUnitState = (thunkAPI.getState() as RootState).controlUnit;

    return Promise.all([
      Promise.all(
        devicesControlUnits.map((deviceControlUnit) =>
          analogFilterObjectEvents[deviceControlUnit.deviceId]
            ? model.getSensordata(
                deviceControlUnit.deviceId,
                start,
                end,
                deviceControlUnit.deviceName ?? '',
                analogFilterObjectEvents[deviceControlUnit.deviceId] ?? {},
                CUsTranslate,
                showLevelOption
              )
            : Promise.resolve({})
        )
      ),
      Promise.all(
        devicesControlUnits.map((deviceControlUnit) =>
          digitalFilterObjectEvents[deviceControlUnit.deviceId]
            ? model.getDigitalChannels(
                deviceControlUnit.deviceId,
                moment(start).add(-1, 'day').valueOf(),
                moment(end).add(1, 'day').valueOf(),
                deviceControlUnit.deviceName ?? '',
                digitalFilterObjectEvents[deviceControlUnit.deviceId] ?? {},
                CUsTranslate,
                showLevelOption,
                controlUnitState
              )
            : Promise.resolve({})
        )
      ),
    ]);
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const updateFilter =
  (
    props: FilterStoredProps & { value: CommanderMonitoringFilterLogicalObject }
  ) =>
  (dispatch: AppDispatch) => {
    const path = getPatchFilter(props);
    const settingsCalls = new SettingsCalls();
    const stringValue = JSON.stringify(props.value);
    settingsCalls.setSetting(path, stringValue);
    dispatch(
      actionsFeatureToggle.updateToggle({ name: path, value: stringValue })
    );
  };
