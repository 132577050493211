import {
  GET_UNREAD_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  CHANGE_NOTIFICATION_READ_STATUS,
  CHANGE_ALL_NOTIFICATIONS_READ_STATUS,
  ACTIVATED_LOADING_UNREAD_NOTIFICATIONS,
} from '../actions/Types';

const initialState = {
  unread: [],
  all: [],
  activatedLoadingUnreadNotifications: false /* this variable is used when
  the unread notifications are loaded and a is called periodically */,
};

/**
 * Update notifications
 * @param array notifications
 * @param array timestamps
 * @param string newStatus
 */
const updateNotifications = (notifications, timestamps, newStatus) =>
  notifications.map((notification) => {
    const updated = {
      ...notification,
    };

    if (
      timestamps.find((timestamp) => timestamp === updated.timestamp) !==
      undefined
    ) {
      updated.unread = newStatus;
    }

    return updated;
  });

export default function reducer(state = initialState, action) {
  let timestamp;
  let newStatus;
  let allUpdated;
  let unreadUpdated;
  switch (action.type) {
    case GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unread: action.payload,
      };
    case ACTIVATED_LOADING_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        activatedLoadingUnreadNotifications: action.payload,
      };
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        all: action.payload,
        unread: action.payload.filter((notification) => notification.unread),
      };

    case CHANGE_NOTIFICATION_READ_STATUS:
      ({ timestamp } = action.payload);
      ({ newStatus } = action.payload);

      allUpdated = updateNotifications(state.all, [timestamp], newStatus);
      unreadUpdated = state.unread.filter((n) => n.timestamp !== timestamp);
      return {
        ...state,
        all: allUpdated,
        unread: unreadUpdated,
      };

    case CHANGE_ALL_NOTIFICATIONS_READ_STATUS:
      ({ newStatus } = action.payload);
      allUpdated = state.all.map((n) =>
        Object.assign(n, { unread: newStatus })
      );
      return {
        ...state,
        all: allUpdated,
        unread: [],
      };
    default:
      return state;
  }
}
