/* eslint-disable eqeqeq */
import { createSelector } from '@reduxjs/toolkit';
import { getControlUnitById } from '.';
import { getUsedSetPointById } from '../../../model/controlUnit/util/setPoint/getUsedSetPointById';
import { getMixedCurrentEventsFromId } from './events';

export const getSetPointSelectorById = createSelector(
  [getControlUnitById],
  (controlUnit) => {
    return controlUnit?.setpoints;
  }
);

export const getUsedSetPointSelectorById = createSelector(
  [getSetPointSelectorById, getMixedCurrentEventsFromId],
  getUsedSetPointById
);
