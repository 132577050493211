import {
  TYPE_INFORMATION,
  TYPE_NEGATIVE,
  TYPE_POSITIVE,
} from 'stoerk-ui-components';

export function getTypeFromMainSenor(
  loading: boolean,
  hasCurrentAlarms: number,
  isOn: boolean | undefined
) {
  // even if is off or event On is not found
  if (!isOn) return TYPE_INFORMATION;
  if (loading) return TYPE_INFORMATION;
  if (hasCurrentAlarms) return TYPE_NEGATIVE;

  return TYPE_POSITIVE;
}
