import { Box } from '@mui/material';
import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import GroupIncidentsPage from './GroupIncidentsPage/GroupIncidentsPage';
import GroupManager from './GroupManager';
import GroupMonitoringPage from './GroupMonitoringPage/GroupMonitoringPage';
import GroupRecipesPage from './GroupRecipesPage/GroupRecipesPage';

const routes = [
  { path: 'history', render: GroupMonitoringPage },
  { path: 'incidents', render: GroupIncidentsPage },
  { path: 'recipes', render: GroupRecipesPage },
  { path: '', render: GroupManager },
];
interface GroupManagerRoutesProps extends RouteComponentProps {}
export function GroupManagerRoutesSimple(props: GroupManagerRoutesProps) {
  return (
    <Box sx={{ mt: 1 }}>
      <Switch>
        {routes.map(({ path, render }) => (
          <Route
            path={`${props.match.path}/${path}`}
            component={render}
            key={path}
            exact
          />
        ))}
      </Switch>
    </Box>
  );
}

export default withRouter(GroupManagerRoutesSimple);
