import React, { Component } from 'react';
import Moment from 'moment';
import { Image, Grid, Icon, Container, Loader } from 'semantic-ui-react';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConnectEdit from './Edit';
import ConnectHistory from './History';
import BreadcrumbNew from '../../commons/breadcrumbNew';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import ValidationUtil from '../../../util/ValidationUtil';
import { fetchUser, fetchUsers } from '../../../redux/users/actions';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import { getIncidentByIncidentId } from '../../../redux/incidents/actions/asyncThunks';
import { getDeviceByDeviceId } from '../../../redux/devices/actions/thunks';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import {
  INCIDENT_STATUS_RESOLVED,
  INCIDENT_STATUS,
  INCIDENT_CATEGORY,
} from '../Constants';
import './index.css';
import { IncidentAPIResponse } from '../../../model/incidentManager/incident.model';
import { UserAPIResponse } from '../../../model/user/user.model';
import {
  getDeviceByIncidentIdFromQueryParam,
  getGroupByIncidentIdFromQueryParam,
  getIncidentByIncidentIdFromQueryParam,
} from '../../../redux/incidents/selectors';
import { RootState } from '../../../redux/store.model';
import IncidentModel from '../../../model/incidentManager/Model';
import DeviceModel from '../../../model/device/Model';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {} & ConnectedComponentProps &
  HandlingErrorWrappedProps &
  PolyglotComponentProps &
  RightsUserUtilComponentProps &
  RouteComponentProps<{ groupId?: string; incidentId: string }>;

type State = {
  incidentId: string;
  groupId: string | undefined;
  userAssigned?: UserAPIResponse;
  readIncidentRights: boolean;
  showLoadingMessage: boolean;
  showNoRightsMessage: boolean;
};
/**
 * Incident details
 * This class show a list of incidents for a device
 * @param object device
 * @param object incident
 * @param array usersGroup: list of users available to assign the incident
 */
export class IncidentDetails extends Component<Props, State> {
  validationUtil: ValidationUtil;
  /**
   * Get date close incident
   * to get the data when the incident was resolved, we need to check the history and
   * find the first record with status closed
   */
  static getDateCloseIncident(history: IncidentAPIResponse['history']) {
    if (!history) return undefined;

    const found = history.find(
      (record) => record.status === INCIDENT_STATUS_RESOLVED
    );
    return found?.timestamp;
  }

  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.validationUtil = new ValidationUtil(props.polyglot);
    const { match } = props;
    const { groupId, incidentId } = match.params;
    this.state = {
      incidentId,
      groupId,
      userAssigned: undefined,
      readIncidentRights: false,
      showLoadingMessage: false,
      showNoRightsMessage: false,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load users
   * this function loads the users from the group including the assignedUser
   * If the group was obtained from the groups structure then the users are already
   * loaded, if not the field group.users has only the ids from the users.
   * In this case the users will be loaded
   * @param string assignedUserId
   * @return object { userAssigned, users}: users include the userAssigned
   */
  async loadUsers(assignedUserId?: string) {
    const { incident, users } = this.props;
    const missingUsersIds: string[] = incident
      ? IncidentModel.getAllUsersIdFromIncident(incident).filter(
          (userId) => !users.find((user) => user.id === userId)
        )
      : [];
    let userAssigned;
    if (missingUsersIds.length > 0) {
      await this.props.fetchUsers(missingUsersIds);
      const { users } = this.props;
      if (assignedUserId) {
        userAssigned = users.find((u) => u.id === assignedUserId);
      }
    }
    return {
      userAssigned,
    };
  }

  /**
   * Load data:
   * the incident, device, group and rights to update and see the group will be loaded
   */
  async loadData() {
    const { rightsUserUtil, handlingErrorsApi } = this.props;
    try {
      const { incidentId, groupId } = this.state;
      this.setState({ showLoadingMessage: true });
      /* Load the incident: from the groups structure */
      await Promise.all([
        this.props.getIncidentByIncidentId(incidentId),
        this.props.warmUpGroups(),
      ]);
      /* get the group in the variable this.props.group */
      const { incident } = this.props;
      if (incident) {
        /* Load the device*/
        await this.props.getDeviceByDeviceId(incident.device);
        /* load the users */
        const { userAssigned } = await this.loadUsers(incident?.assignedUser);
        /* rights */
        const readIncidentRights = rightsUserUtil.hasRightsToReadIncident(
          incident.device,
          groupId
        );
        this.setState({
          userAssigned: userAssigned,
          readIncidentRights,
          showLoadingMessage: false,
          showNoRightsMessage: true,
        });
      } else {
        this.setState({ showLoadingMessage: false });
      }
    } catch (error) {
      this.setState({
        showLoadingMessage: false,
        showNoRightsMessage: false,
      });
      handlingErrorsApi(error);
    }
  }

  render() {
    const { incident, device, polyglot, history, users } = this.props;
    const {
      userAssigned,
      groupId,
      readIncidentRights,
      showLoadingMessage,
      showNoRightsMessage,
    } = this.state;
    if (showLoadingMessage || !incident) {
      return (
        <div className="loading-data-window">
          <Grid>
            <Grid.Column width="16" textAlign="center" verticalAlign="middle">
              <Loader active inline />
              {polyglot.t('group.loading_data_message')}
            </Grid.Column>
          </Grid>
        </div>
      );
    }

    if (!readIncidentRights && showNoRightsMessage) {
      return (
        <div className="loading-data-window">
          <Grid>
            <Grid.Column width="16" textAlign="center" verticalAlign="middle">
              {polyglot.t('incident_manager.no_rights_read_incidents')}
            </Grid.Column>
          </Grid>
        </div>
      );
    }

    const timeClosedIncident = IncidentDetails.getDateCloseIncident(
      incident?.history
    );
    const sectionsNavigation = [
      { content: polyglot.t('button.back'), onClick: () => history.goBack() },
      { content: polyglot.t('incident_manager.incident') },
    ];

    return (
      <div>
        <BreadcrumbNew sections={sectionsNavigation} />
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column
                width={6}
                className="header-information"
                textAlign="left"
              >
                {/* Image device and name */}
                <Image
                  size="mini"
                  verticalAlign="top"
                  spaced="right"
                  src={DeviceModel.getDeviceIcon(device?.iconURL)}
                />
                {device ? device.name : ''}
              </Grid.Column>

              <Grid.Column width={10} textAlign="right">
                {/* time begin incident */}
                <p className="header-information">
                  <Icon name="time" color="red" />
                  {Moment(incident.reportTimestamp).format('L LTS')}
                </p>
                {/* time end incident */}
                {timeClosedIncident && (
                  <p className="header-information">
                    <Icon name="time" color="green" />
                    {Moment(timeClosedIncident).format('L LTS')}
                  </p>
                )}
                {/* status incident */}
                <p className="header-information">
                  {`${polyglot.t('incident_manager.titles_columns.status')}:`}
                  <b>
                    {incident.status &&
                      ` ${polyglot.t(
                        `incident_manager.status_options.${
                          INCIDENT_STATUS[incident.status].polyglot
                        }`
                      )}`}
                  </b>
                </p>
                {/* user assigned */}
                <p className="header-information">
                  {`${polyglot.t('incident_manager.assigned_user')}:`}
                  <b>
                    {userAssigned?.name
                      ? userAssigned.name
                      : userAssigned?.email}
                  </b>
                </p>
                {/* category */}
                <p className="header-information">
                  {INCIDENT_CATEGORY[incident.category] !== undefined &&
                  polyglot.t(
                    `incident_manager.category_options.${
                      INCIDENT_CATEGORY[incident.category].polyglot
                    }`
                  ) ? (
                    <div>
                      {`${polyglot.t('incident_manager.category')}:`}
                      <b>
                        {polyglot.t(
                          `incident_manager.category_options.${
                            INCIDENT_CATEGORY[incident.category].polyglot
                          }`
                        )}
                      </b>
                    </div>
                  ) : (
                    <div>
                      {`${polyglot.t('incident_manager.category')}:`}
                      <b>{incident.category}</b>
                    </div>
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Box marginY={3}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  {polyglot.t('incident_manager.edit_dialog_title')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ConnectEdit
                  incident={incident}
                  groupId={groupId}
                  users={users}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  {polyglot.t('incident_manager.history')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {incident.history && incident.history.length > 0 && (
                  <ConnectHistory
                    users={users}
                    historyIncident={incident.history}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string; incidentId: string }>
) => ({
  user: state.users.item,
  users: state.users.items,
  incident: getIncidentByIncidentIdFromQueryParam(state, props),
  device: getDeviceByIncidentIdFromQueryParam(state, props),
  group: getGroupByIncidentIdFromQueryParam(state, props),
});

const connector = connect(mapStateToProps, {
  fetchUser,
  fetchUsers,
  getIncidentByIncidentId,
  getDeviceByDeviceId,
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withUserRightUtil(withPolyglot(withRouter(IncidentDetails)))
  )
);
