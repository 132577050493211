import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Container, Segment, Header, Table, Icon } from 'semantic-ui-react';
import { UiButton, withSnackbar } from 'stoerk-ui-components';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
} from '../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { RootState } from '../../redux/store.model';
import { withUserRightUtil } from '../../util/rights';

type Props = {
  closeWindow(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps &
  OpenSnackbarProps;

/**
 * New voucher
 * This page shows the created voucher and give the possibility to copy the code
 * in the clipboard
 */
export class NewVoucher extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.copyClipboard = this.copyClipboard.bind(this);
  }

  /**
   * Copy clipboard
   */
  copyClipboard() {
    const { voucher, openSnackbar, polyglot } = this.props;
    navigator.clipboard.writeText(voucher.code);
    const message = {
      text: polyglot.t('voucher.voucher_code_copied_clipboard'),
      type: 'ok',
    };
    openSnackbar(message);
  }

  render() {
    const { polyglot, voucher, closeWindow } = this.props;
    return (
      <Container>
        <Segment>
          <Header as="h2">{polyglot.t('voucher.title_new_voucher')}</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{polyglot.t('voucher.code_voucher')}</Table.Cell>
                <Table.Cell>{voucher.code}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Icon name="copy" onClick={this.copyClipboard} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{polyglot.t('voucher.days_validity')}</Table.Cell>
                <Table.Cell colSpan="2">{voucher.validityPeriod}</Table.Cell>
              </Table.Row>
              {voucher.quota && (
                <Table.Row>
                  <Table.Cell>{polyglot.t('voucher.users')}</Table.Cell>
                  <Table.Cell colSpan="2">{voucher.quota.users}</Table.Cell>
                </Table.Row>
              )}
              {voucher.quota && (
                <Table.Row>
                  <Table.Cell>{polyglot.t('voucher.devices')}</Table.Cell>
                  <Table.Cell colSpan="2">{voucher.quota.devices}</Table.Cell>
                </Table.Row>
              )}
              {voucher.modules && (
                <Table.Row>
                  <Table.Cell>{polyglot.t('voucher.modules')}</Table.Cell>
                  <Table.Cell>
                    {voucher.modules.map((module: any) => (
                      <span key={module}>{module}</span>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <UiButton
            iconName="close"
            size="small"
            id="closeButton"
            showLabels
            label={polyglot.t('button.close')}
            onClick={closeWindow}
          />
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  voucher: state.billing.voucher,
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withUserRightUtil(withSnackbar(withPolyglot(NewVoucher)))
);
