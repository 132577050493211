import { AnyAction, combineReducers } from 'redux';
import userReducer from '../users/reducer';
import roleReducer from '../roles/reducer';
import incidentConfigurationReducer from '../incidentConfiguration/reducer';
import integrationRegistryReducer from '../integrationRegistry/reducer';
import eventsReducer from '../events/reducer';
import rightsReducer from '../rights/reducer';
import notificationsReducer from '../notifications/reducer';
import maintenanceMessageReducer from '../maintenanceMessage/reducer';
import addressesCoordinatesReducer from '../addressesCoordinates/reducer';
import cameraReducer from '../cameras/reducer';
import recipesReducer from '../recipes/reducer';
import authReducer from '../auth/reducer';
import billingReducer from '../billing/reducer';

import { DESTROY_SESSION } from '../auth/actions/Types';
import { nameReducerGroups, reducerGroups } from '../groups/slice';
import { nameReducerDevices, reducerDevices } from '../devices/slice';
import { nameReducerMonitoring, reducerMonitoring } from '../monitoring/slice';
import { nameReducerIncidents, reducerIncidents } from '../incidents/slice';
import {
  nameReducerFeatureToggle,
  reducerFeatureToggle,
} from '../../featureToggle/redux/slice';
import {
  nameReducerControlUnit,
  reducerControlUnit,
} from '../controlUnit/slice/controlUnitSlice';
import {
  nameReducerCUTranslation,
  reducerCUTranslation,
} from '../CUTranslation/slice/CUTranslationSlice';
import {
  nameReducerCUParameters,
  reducerCUParameters,
} from '../CUParameters/slice/CUParametersSlice';

const appReducer = combineReducers({
  auth: authReducer,
  [nameReducerGroups]: reducerGroups,
  [nameReducerDevices]: reducerDevices,
  [nameReducerControlUnit]: reducerControlUnit,
  users: userReducer,
  roles: roleReducer,
  incidentConfiguration: incidentConfigurationReducer,
  events: eventsReducer,
  integrationRegistry: integrationRegistryReducer,
  rights: rightsReducer,
  [nameReducerIncidents]: reducerIncidents,
  notifications: notificationsReducer,
  maintenanceMessage: maintenanceMessageReducer,
  addressesCoordinates: addressesCoordinatesReducer,
  cameras: cameraReducer,
  recipes: recipesReducer,
  billing: billingReducer,
  [nameReducerMonitoring]: reducerMonitoring,
  [nameReducerFeatureToggle]: reducerFeatureToggle,
  [nameReducerCUTranslation]: reducerCUTranslation,
  [nameReducerCUParameters]: reducerCUParameters,
});

const rootReducer: typeof appReducer = (state, action: AnyAction) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }
  // eslint-disable-next-line no-console
  // console.log({ state, action });

  return appReducer(state, action);
};

export default rootReducer;
