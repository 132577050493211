import React from 'react';
import Header from '../../../../components/header/Header';
import logo from '../../assets/alan-nuttall-flexeserve-eu__LogoNuttallCloud.png';

type HeaderType = typeof Header;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore exotic component
const HeaderNuttall: HeaderType = (props) => (
  <Header {...props} title="" logoIcon={logo} />
);
export default HeaderNuttall;
