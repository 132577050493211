import { getPolyglot } from '../i18n';

const convertSecondsToMinutes = 60;
const convertSecondsToHours = 60 * 60;
const convertSecondsToDays = 24 * 60 * 60;
const convertSecondsToYears = 365 * 24 * 60 * 60;
const convertMinutesToHours = 60;

export const secondId = 's';
export const minuteId = 'min';
export const hourId = 'h';
export const dayId = 'd';
export const yearId = 'y';

const polyglot = getPolyglot();

/**
 * Time units
 * This function returns an array with time units and names from a min unit defined
 * in the param fromMinTimeUnit
 * @param string fromMinTimeUnit = [secondId, minuteId, hourId, dayId, yearId]
 * @return array: [{id, convertToSeconds, name}]
 */
export const timeUnits = (fromMinTimeUnit = secondId) => {
  const times = [
    { id: secondId, convertToSeconds: 1, name: polyglot.t('general.second') },
    {
      id: minuteId,
      convertToSeconds: convertSecondsToMinutes,
      name: polyglot.t('general.minute'),
    },
    {
      id: hourId,
      convertToSeconds: convertSecondsToHours,
      name: polyglot.t('general.hour'),
    },
    {
      id: dayId,
      convertToSeconds: convertSecondsToDays,
      name: polyglot.t('general.day'),
    },
    {
      id: yearId,
      convertToSeconds: convertSecondsToYears,
      name: polyglot.t('general.year'),
    },
  ];
  const min = times.map((t) => t.id).indexOf(fromMinTimeUnit);
  return times.slice(min < 0 ? 0 : min);
};

/**
 * Convert seconds in bigger unit of time
 * This function converts the seconds in years, days, hours, minutes and seconds
 * @param int seconds
 * @return array[objects] {yearId:, dayId:, hourId:, minuteId:, secondId: }
 */
export const convertSecondsToUnitTimes = (inputSeconds) => {
  let years = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  const ref = [
    convertSecondsToYears,
    convertSecondsToDays,
    convertSecondsToHours,
    convertSecondsToMinutes,
    1,
  ];
  let input = inputSeconds;
  for (let cont = 0; cont < ref.length; cont += 1) {
    const value = ref[cont];
    while (value <= input) {
      input -= value;
      if (cont === 0) years += 1;
      if (cont === 1) days += 1;
      if (cont === 2) hours += 1;
      if (cont === 3) minutes += 1;
      if (cont === 4) seconds += 1;
    }
  }
  return {
    [yearId]: years,
    [dayId]: days,
    [hourId]: hours,
    [minuteId]: minutes,
    [secondId]: seconds,
  };
};

/**
 * convert seconds to human readable
 * @param int seconds
 * @return string interval = # years # days # hours # minutes # seconds
 */
export const convertSecondsToHumanReadable = (seconds, polyglot) => {
  let interval = '';
  const time = convertSecondsToUnitTimes(seconds);
  if (time[yearId] > 0) {
    interval = time[yearId];
    interval = `${interval} ${
      time[yearId] > 1
        ? polyglot.t('general.years')
        : polyglot.t('general.year')
    }`;
  }
  if (time[dayId] > 0) {
    interval = `${interval} ${time[dayId]}`;
    interval = `${interval} ${
      time[dayId] > 1 ? polyglot.t('general.days') : polyglot.t('general.day')
    }`;
  }
  if (time[hourId] > 0) {
    interval = `${interval} ${time[hourId]}`;
    interval = `${interval} ${
      time[hourId] > 1
        ? polyglot.t('general.hours')
        : polyglot.t('general.hour')
    }`;
  }
  if (time[minuteId] > 0) {
    interval = `${interval} ${time[minuteId]}`;
    interval = `${interval} ${
      time[minuteId] > 1
        ? polyglot.t('general.minutes')
        : polyglot.t('general.minute')
    }`;
  }
  if (time[secondId] > 0) {
    interval = `${interval} ${time[secondId]}`;
    interval = `${interval} ${
      time[secondId] > 1
        ? polyglot.t('general.seconds')
        : polyglot.t('general.second')
    }`;
  }
  return interval.trim();
};

/**
 * Convert seconds to greater unit time
 * This function convert the seconds to the next possible unit time. if it is not
 * possible to convert to only a unit time, then the returned values will be seconds
 * @param int seconds
 * @return object { unitTime:, value}
 */
export const convertSecondsToGreaterUnitTime = (seconds) => {
  const nextUnitTime = { unitTime: secondId, value: seconds };
  const unitTimes = convertSecondsToUnitTimes(seconds);
  /* if the field unitTimes.seconds is not 0, then the values could be not convert to
  a only bigger unit time */
  if (unitTimes[secondId] > 0) {
    return nextUnitTime;
  }

  if (Object.values(unitTimes).filter((v) => v !== 0).length === 1) {
    Object.keys(unitTimes).forEach((key) => {
      if (unitTimes[key] > 0) {
        nextUnitTime.unitTime = key;
        nextUnitTime.value = unitTimes[key];
      }
    });
    return nextUnitTime;
  }

  if (unitTimes[minuteId] > 0) {
    /* convert to minutes */
    nextUnitTime.unitTime = minuteId;
    nextUnitTime.value =
      unitTimes[yearId] * 365 * 24 * 60 +
      unitTimes[dayId] * 24 * 60 +
      unitTimes[hourId] * 60 +
      unitTimes[minuteId];
    return nextUnitTime;
  }

  if (unitTimes[hourId] > 0) {
    /* convert to hours */
    nextUnitTime.unitTime = hourId;
    nextUnitTime.value =
      unitTimes[yearId] * 365 * 24 + unitTimes[dayId] * 24 + unitTimes[hourId];
    return nextUnitTime;
  }

  if (unitTimes[dayId] > 0) {
    /* convert to days */
    nextUnitTime.unitTime = yearId;
    nextUnitTime.value = unitTimes[yearId] * 365 + unitTimes[dayId];
    return nextUnitTime;
  }

  return nextUnitTime;
};

/**
 * Convert seconds greater to minutes
 * @param int secods
 * @return object unitTime {unitTime, value}
 */
export const convertSecondsGreaterToMinutes = (seconds) => {
  const unitTime = convertSecondsToGreaterUnitTime(seconds);
  if (unitTime.unitTime === secondId) {
    const valueHours = unitTime.value / convertSecondsToHours;
    return { unitTime: hourId, value: valueHours };
  }

  if (unitTime.unitTime === minuteId) {
    const valueHours = unitTime.value / convertMinutesToHours;
    return { unitTime: hourId, value: valueHours };
  }
  return unitTime;
};
