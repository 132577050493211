import React from 'react';
import { MODE_CLOUD_LIGHT, SingleData } from 'stoerk-ui-components';
import { getRightUserUtilSelector } from '../../../../../../../../../../../../redux/rights/selectors';
import { useAppSelector } from '../../../../../../../../../../../../redux/store.model';
import { ParameterProps } from './Parameter';
import { getTitle, getValue } from './util';

interface ParameterViewProps extends ParameterProps {
  onClickEdit: () => void;
}
export function ParameterView(props: ParameterViewProps) {
  const { parameter, onClickEdit, groupId, deviceId } = props;

  const value = getValue(parameter);
  const title = getTitle(parameter);
  const showEditButton = useAppSelector(
    getRightUserUtilSelector
  ).hasRightsToUpdateParameter(deviceId, groupId);

  return (
    <SingleData
      key={parameter.name}
      title={title}
      value={value}
      showIconInfo={!!parameter.descriptionTranslation}
      information={parameter.descriptionTranslation}
      showIconUpdate={showEditButton}
      onClickIconUpdate={onClickEdit}
      mode={MODE_CLOUD_LIGHT}
    />
  );
}
