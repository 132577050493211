import React, { Component } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { TableSort } from 'stoerk-ui-components';
import { connect, ConnectedProps } from 'react-redux';
import ConnectAddCamera from './AddCamera';
import ImageView from './Image';
import VideoView from './Video';
import { fetchGroupCameras } from '../../../../redux/cameras/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../handlingErrors';
import './index.css';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import { RootState } from '../../../../redux/store.model';
import { STModal } from '../../../commons/Modal';
import { Button as MUIButton } from '@mui/material';

type Props = {
  showCameras?: boolean;
  close(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  RightsUserUtilComponentProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps;

type State = {
  showAddWindow: boolean;
  showImageWindow: boolean;
  showVideoWindow?: boolean;
  camera: any;
};
export class Cameras extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.closeAddWindow = this.closeAddWindow.bind(this);
    this.closeImageWindow = this.closeImageWindow.bind(this);
    this.closeVideoWindow = this.closeVideoWindow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = {
      showAddWindow: false,
      showImageWindow: false,
      camera: '',
    };
  }

  componentDidMount() {
    const { showCameras, group, groupCameras } = this.props;
    if (showCameras && group?.id) {
      if (!groupCameras[group.id]) {
        this.loadData(group.id);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { showCameras, group, groupCameras } = this.props;
    if (showCameras && group?.id && group.id !== prevProps.group?.id) {
      if (!groupCameras[group.id]) {
        this.loadData(group.id);
      }
    }
  }

  getTableData() {
    const { polyglot, group, groupCameras } = this.props;
    const dataFormat = {
      title: {
        allowSort: true,
        title: polyglot.t('camera.title'),
      },
      actionButtons: {
        allowSort: false,
        title: '',
      },
    };

    let cameras = [];
    if (group && group.id && groupCameras && groupCameras[group.id]) {
      cameras = groupCameras[group.id];
    }

    const data = cameras.map((cam: any) => ({
      title: cam.name,
      actionButtons: (
        <Button.Group>
          <Button
            id="showImageButton"
            icon="image"
            size="small"
            basic
            onClick={() => this.showImage(cam)}
          />
          <Button
            id="showVideoButton"
            icon="video"
            size="small"
            basic
            onClick={() => this.showVideo(cam)}
          />
        </Button.Group>
      ),
    }));

    return { data, dataFormat };
  }

  /**
   * Load data
   * @param string groupId
   */
  async loadData(groupId: string) {
    try {
      await this.props.fetchGroupCameras(groupId);
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  showVideo(camera: any) {
    this.setState({ camera, showVideoWindow: true });
  }

  showImage(camera: any) {
    this.setState({ camera, showImageWindow: true });
  }

  closeAddWindow() {
    this.setState({ showAddWindow: false });
  }

  closeImageWindow() {
    this.setState({ showImageWindow: false });
  }

  closeVideoWindow() {
    this.setState({ showVideoWindow: false });
  }

  render() {
    const { data, dataFormat } = this.getTableData();
    const { showAddWindow, showImageWindow, camera, showVideoWindow } =
      this.state;
    const { group, showCameras, polyglot, close, rightsUserUtil } = this.props;
    const allowedToPair =
      group && rightsUserUtil.hasRightsToPairCamera(group.id);
    if (!group) return null;
    return (
      <div>
        <ConnectAddCamera
          show={showAddWindow}
          close={this.closeAddWindow}
          group={group.id}
          allowedToPair={allowedToPair}
        />
        <ImageView
          show={showImageWindow}
          camera={camera}
          close={this.closeImageWindow}
          group={group.id}
        />
        <VideoView
          show={showVideoWindow}
          camera={camera}
          close={this.closeVideoWindow}
          group={group.id}
        />
        <STModal
          open={Boolean(showCameras)}
          title={polyglot.t('camera.overview')}
          onClose={close}
          buttonActions={
            <MUIButton onClick={close}>
              {polyglot.t('device.cancel_button_title')}
            </MUIButton>
          }
        >
          <Segment>
            <Grid container className="camera-overview">
              <div className="camera-table-container">
                <TableSort data={data} columnsFormat={dataFormat} />
              </div>
              <Grid.Row key="addCam" className="add-card">
                {allowedToPair && (
                  <Grid.Column width={16}>
                    <Button
                      primary
                      circular
                      icon="add"
                      size="huge"
                      className="raised-button"
                      floated="right"
                      onClick={() => this.setState({ showAddWindow: true })}
                      id="ButtonAddCamera"
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Segment>
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: { groupId?: string | undefined }
) => ({
  // FIXME: remove type when cameras has types
  groupCameras: state.cameras.groups as Record<string, any>,
  group: getGroupByPropGroupIdSelector(state, props),
});

const connector = connect(mapStateToProps, { fetchGroupCameras });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, { fetchGroupCameras })(
  withHandlingErrors(withUserRightUtil(withPolyglot(Cameras)))
);
