import { FlexeserveSetting } from '../model';
export function getSetting(settingName: string) {
  return settings.find((setting) => setting.settingName === settingName);
}
export const settings: FlexeserveSetting[] = [
  {
    displayName: 'Low',
    settingName: 'Std Low',
    temperatureValue: 65,
    temperatureUnit: '°C',
    show: true,
  },
  {
    displayName: 'Medium',
    settingName: 'Std Medium',
    temperatureValue: 85,
    temperatureUnit: '°C',
    show: true,
  },
  {
    displayName: 'High',
    settingName: 'Std High',
    temperatureValue: 90,
    temperatureUnit: '°C',
    show: true,
  },
  {
    displayName: 'User 1 settings',
    settingName: 'User 1',
    temperatureValue: 66.6,
    temperatureUnit: '°C',
    show: true,
  },
  {
    displayName: 'User 2 settings',
    settingName: 'User 2',
    temperatureValue: 120.6,
    temperatureUnit: '°C',
    show: true,
  },
];
