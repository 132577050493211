// eslint-disable @typescript-eslint/no-redeclare
import React from 'react';
import { Subtract } from 'utility-types';

import Polyglot from 'node-polyglot';

import englishTranslation from './en.json';
import germanTranslation from './de.json';
import spanishTranslation from './es.json';
import frenchTranslation from './fr.json';
export type LanguagesSported = 'de' | 'en' | 'fr' | 'nl' | 'es';

/**
 * get I 18n
 * Load the translations
 */
const locales: Record<string, object> = {
  en: englishTranslation,
  de: germanTranslation,
  es: spanishTranslation,
  fr: frenchTranslation,
};

const locale = 'en';
let language: LanguagesSported =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore not safe type
  navigator.userLanguage;
if (!locales[language]) {
  language = language.toLowerCase().split(/[_-]+/)[0] as LanguagesSported;
}
/* if the language is not found in the availables locales, then we should use
be default en */
if (!locales[language]) {
  language = 'en';
}

const polyglot = new Polyglot({ phrases: locales[language], locale });

export const getLanguage = () => language;

export const getPolyglot = () => polyglot;

export interface PolyglotComponentProps {
  polyglot: Polyglot;
}

export function withPolyglot<P extends PolyglotComponentProps>(
  WrappedComponent: React.ComponentType<P>
) {
  const WrappedComponentWithPolyglot = (
    props: Subtract<P, PolyglotComponentProps>
  ) => <WrappedComponent {...(props as P)} polyglot={polyglot} />;

  return WrappedComponentWithPolyglot;
}
