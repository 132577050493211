import { Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { MODE_CLOUD_LIGHT, SingleData } from 'stoerk-ui-components';
import { ComposedParametersValue } from '../../../../../../../../../../../../redux/CUParameters/selectors';
import { ParameterProps } from './Parameter';
import { ParameterEditControl } from './ParameterEditControl/ParameterEditControl';
import { getTitle } from './util';

export interface ParameterEditProps extends ParameterProps {
  onClickClose: () => void;
  onSave: (parameter: ComposedParametersValue, newValue: string) => void;
  loading?: boolean;
}
export function ParameterEdit(props: ParameterEditProps) {
  const {
    parameter,
    loading,
    onClickClose,
    onSave,
    polyglot,
    handlingErrorsApi,
  } = props;

  const title = getTitle(parameter);
  const [temporalValue, setTemporalValue] = useState(parameter.value || '');
  const [errorForm, setErrorForm] = useState<string | null | undefined>(
    undefined
  );
  const onClickSave = () => {
    if (errorForm) return handlingErrorsApi(new Error(errorForm));
    onSave(parameter, temporalValue);
  };
  const onChange = (value: string, error?: string) => {
    setTemporalValue(value);
    setErrorForm(error);
  };
  return (
    <>
      <SingleData
        key={parameter.name}
        title={title}
        value={
          <ParameterEditControl
            {...props}
            value={temporalValue}
            onChangeValue={onChange}
          />
        }
        description={parameter.descriptionTranslation}
        mode={MODE_CLOUD_LIGHT}
      />
      <Stack
        direction={'row-reverse'}
        sx={{ margin: 2, marginTop: 0 }}
        spacing={1}
      >
        <Button onClick={onClickSave} variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : polyglot.t('button.save')}
        </Button>
        <Button onClick={onClickClose} disabled={loading}>
          {polyglot.t('button.cancel')}
        </Button>
      </Stack>
    </>
  );
}
