import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Paper } from '@mui/material';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../i18n';
import { Device } from '../../../../../../../model/device/device.model';
import DeviceModel from '../../../../../../../model/device/Model';
import ListItem from '../../../../../../commons/ListItem';
import TooltipLight from '../../../../../../commons/TooltipLight';
import { STButtonIcon } from '../../../../../../commons/STButtonIcon';
import { deviceButtonsRightsSelector } from '../../../../../../../redux/devices/selectors/devicesRightsSelectors';
import { RootState } from '../../../../../../../redux/store.model';
import Util from '../../../../../../../util/Util';
import ConnectDeviceEdit from '../../DeviceEdit';
import DeviceInformation from '../../DeviceInformation';

const proxyBackendURL = new Util().getCommanderProxyURL();

interface DeviceCardProps
  extends PolyglotComponentProps,
    RouteComponentProps,
    OwnProps,
    ConnectedComponentProps {}
interface OwnProps {
  device: Device;
  groupId?: string;
  moduleSelected: string | '';
}

export function DeviceCardSimple(props: DeviceCardProps) {
  const {
    device,
    groupId,
    history,
    moduleSelected,
    featureToggle,
    accessToken,
  } = props;

  const openDeviceDetail = () => {
    if (moduleSelected === '') return;
    let url = `/devicemanager/device/${device.uuid}/device-detail`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail`;
    }
    history.push(url);
  };

  const openDeviceUI = () => {
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  };

  const onClickItem = !!featureToggle.ControlUnitsOverview
    ? openDeviceDetail
    : openDeviceUI;
  const disabled = !!featureToggle.ControlUnitsOverview
    ? undefined
    : !device.cloudConnectivity;

  return (
    <Paper variant="outlined" sx={{ marginX: 2, paddingRight: 1 }}>
      <ListItem
        key={device.uuid}
        id={device.uuid}
        name={device.name}
        loading={device.loadingData}
        iconURL={DeviceModel.getDeviceIcon(device.iconURL)}
        className={DeviceModel.getClassesNamesFromDevice(device)}
        onClick={onClickItem}
        disabled={disabled}
        buttons={
          <Button.Group>
            <DeviceEditButton {...props} />
            <DeviceUIButton {...props} />
            <DeviceHistoryButton {...props} />
            <DeviceAlarmsButton {...props} />
            <DeviceCloudConnectivityIcon {...props} />
            <DeviceInfoButton {...props} />
          </Button.Group>
        }
      />
    </Paper>
  );
}
const sizeIcons = 'medium';

function DeviceEditButton(props: DeviceCardProps) {
  const { device, polyglot, groupId, showDeviceEditIcon } = props;
  const [showEditDevice, setShowEditDevice] = useState(false);
  if (!showDeviceEditIcon) return null;
  const openEditDevice = () => {
    setShowEditDevice(true);
  };

  const closeEditDevice = () => setShowEditDevice(false);

  return (
    <React.Fragment>
      <ConnectDeviceEdit
        deviceId={device.uuid}
        showEditDevice={showEditDevice}
        closeEditDevice={closeEditDevice}
        groupId={groupId}
      />
      <TooltipLight title={polyglot.t('device.tooltip.open_window_edit')}>
        <STButtonIcon
          icon="write"
          size={sizeIcons}
          loading={device.loadingData}
          onClick={openEditDevice}
        />
      </TooltipLight>
    </React.Fragment>
  );
}

/* Button ui device with tooltip and modal */
function DeviceUIButton(props: DeviceCardProps) {
  const { device, polyglot, accessToken } = props;
  const openDeviceUI = () => {
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  };

  return (
    <TooltipLight title={polyglot.t('device.tooltip.open_window_ui')}>
      <STButtonIcon
        icon="calculator"
        size={sizeIcons}
        onClick={openDeviceUI}
        disabled={!device.cloudConnectivity}
        loading={device.loadingData}
      />
    </TooltipLight>
  );
}

/* Button history device with tooltip and modal */
function DeviceHistoryButton(props: DeviceCardProps) {
  const {
    device,
    polyglot,
    showDeviceSensordataIcon,
    groupId,
    history,
    moduleSelected,
  } = props;
  if (!showDeviceSensordataIcon) return null;
  const selected = moduleSelected === 'history';

  const openDeviceSensordata = () => {
    if (selected) return;
    let url = `/devicemanager/device/${device.uuid}/device-detail/history`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/history`;
    }
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.tooltip.open_window_sensordata')}>
      <STButtonIcon
        icon="chart line"
        size={sizeIcons}
        onClick={openDeviceSensordata}
        loading={device.loadingData}
        selected={selected}
      />
    </TooltipLight>
  );
}

function DeviceAlarmsButton(props: DeviceCardProps) {
  const {
    device,
    polyglot,
    showButtonReadIncidents,
    groupId,
    history,
    moduleSelected,
  } = props;
  if (!showButtonReadIncidents) return null;
  const selected = moduleSelected === 'incidents';
  const openIncidentManager = () => {
    if (selected) return;
    let url = `/devicemanager/device/${device.uuid}/device-detail/incidents`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/incidents`;
    }
    history.push(url);
  };

  return (
    <TooltipLight title={polyglot.t('device.tooltip.open_window_alarms')}>
      <STButtonIcon
        icon="warning sign"
        size={sizeIcons}
        onClick={openIncidentManager}
        loading={device.loadingIncidentsUnresolved}
        badge={device.incidents.length}
        selected={selected}
      />
    </TooltipLight>
  );
}
function DeviceCloudConnectivityIcon(props: DeviceCardProps) {
  const { device, polyglot } = props;

  return (
    <TooltipLight title={device.cloudConnectivityTooltip}>
      <STButtonIcon
        cursor="initial"
        loading={device.loadingStatus}
        size={sizeIcons}
        disableRipple
        disableTouchRipple
      >
        <i className="icon">
          <img
            src={device.cloudConnectivityIcon}
            className="icon-status"
            alt={`${device.cloudConnectivityTooltip} ${polyglot.t(
              'device.icon'
            )}`}
          />
        </i>
      </STButtonIcon>
    </TooltipLight>
  );
}

/* Button ui device with tooltip and modal */
function DeviceInfoButton(props: DeviceCardProps) {
  const { device, polyglot, showDeviceInfo } = props;
  const [showDeviceInformation, setShowDeviceInformation] = useState(false);
  if (!showDeviceInfo) return null;
  const openDeviceInformation = () => {
    setShowDeviceInformation(true);
  };
  const closeDeviceInformation = () => {
    setShowDeviceInformation(false);
  };

  return (
    <React.Fragment>
      <DeviceInformation
        showDeviceInformation={showDeviceInformation}
        closeDeviceInformation={closeDeviceInformation}
        device={device}
      />
      <TooltipLight title={polyglot.t('device.tooltip.device_information')}>
        <STButtonIcon
          icon="question circle outline"
          size={sizeIcons}
          onClick={openDeviceInformation}
          loading={device.loadingData}
        />
      </TooltipLight>
    </React.Fragment>
  );
}
const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...deviceButtonsRightsSelector(state, props),
  accessToken: state.auth.accessToken,
  featureToggle: state.featureToggle,
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedDeviceCard = withPolyglot(
  withRouter(connector(DeviceCardSimple))
);
export default ConnectedDeviceCard;
