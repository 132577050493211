/**
 * IETF Languages Util
 * return the supported languages
 */
const IETFLanguages = ['en', 'de', 'fr', 'es'];

export default class IETFLanguagesUtil {
  constructor(polyglot) {
    this.polyglot = polyglot;
    this.languages = IETFLanguages;
  }

  getLanguageName(language) {
    return this.polyglot.t(`languages.${language}`);
  }

  getAllLanguages() {
    const codes = [];
    this.languages.forEach((language) => {
      codes.push({
        code: language,
        name: this.getLanguageName(language),
      });
    });

    codes.sort((a, b) => (a.name < b.name ? -1 : 1));
    return codes;
  }
}
