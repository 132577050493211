import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BreadcrumbNew from '../commons/breadcrumbNew';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { getGroups } from '../../redux/groups/selectors';
import GroupModel from '../../model/group/Model';
import { RootState } from '../../redux/store.model';
import RecipeManager from './RecipeManager';
import { Container, Paper } from '@mui/material';

type Props = {} & ConnectedComponentProps &
  PolyglotComponentProps &
  RouteComponentProps<{ groupId: string }>;

export function RecipeManagerPage(props: Props) {
  const { groups, match, polyglot, history } = props;
  const { groupId } = match.params;
  const group = groups.filter((g) => g.id === groupId)[0];
  /**
   * Navigation
   * if the groups are loaded then the breadcrumb will have a link to the devices manager
   */
  let parentId: string | null | undefined;
  if (groups.length > 0) {
    parentId = GroupModel.getMainParentGroupId(groupId, groups);
  }
  const sectionsNavigation = [
    {
      content:
        parentId !== undefined
          ? polyglot.t('nav.menu.devicemanager')
          : polyglot.t('button.back'),
      onClick:
        parentId !== undefined
          ? () =>
              history.push(
                `/devicemanager/${parentId !== null ? parentId : ''}`
              )
          : () => history.goBack(),
    },
    {
      content: `${polyglot.t('recipe_manager.title')} ${
        group && group.name !== undefined ? group.name : ''
      }`,
    },
  ];
  return (
    <React.Fragment>
      <BreadcrumbNew sections={sectionsNavigation} />

      <Container maxWidth="lg" className="RecipeManagerPage">
        <Paper
          variant="outlined"
          // Remove paper in mobile
          sx={{
            padding: { md: 2 },
            borderWidth: { xs: '0px', md: '1px' },
          }}
        >
          <RecipeManager />
        </Paper>
      </Container>
    </React.Fragment>
  );
}
const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
});

const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withPolyglot(withRouter(RecipeManagerPage)));
