import { LanguagesSported } from '../../i18n';

/**
 * IncidentAPIResponse
 * extracted from
 * http://gitlab.st.local/stoerk/cloud/incident-manager/-/blob/master/incident-manager/src/main/java/com/stoerk/incident/management/IncidentBaseData.java
 * 
 * ```json
{
    "summary": "clean condesor",
    "priority": "MEDIUM",
    "category": "MAINTENANCE",
    "status": "NEW",
    "assignedUser": "YmMzNGZiNTQtMTc0MS00ZmM5LTkyMDEtYWZlYTRkMjZkMDRh",
    "description": "Remember to use a vaccumcleaner",
    "id": "NGFlZDQ2OTgtY2ViOC00MjA0LTk5YTYtNzQxODMyYmFiZGY5",
    "reportTimestamp": 1620117097561,
    "device": "ZjZmMzAwZjktZTBmNC00NWRjLWE3ZTUtNWI5MTU4NjljZDMz",
    "history": [
        {
            "summary": "clean condesor",
            "priority": "MEDIUM",
            "category": "MAINTENANCE",
            "status": "NEW",
            "assignedUser": "YmMzNGZiNTQtMTc0MS00ZmM5LTkyMDEtYWZlYTRkMjZkMDRh",
            "description": "Remember to use a vaccumcleaner",
            "timestamp": 1620117100102,
            "attributes": {
                "summary": "clean condesor",
                "priority": "MEDIUM",
                "category": "MAINTENANCE",
                "status": "NEW",
                "assignedUser": "YmMzNGZiNTQtMTc0MS00ZmM5LTkyMDEtYWZlYTRkMjZkMDRh",
                "description": "Remember to use a vaccumcleaner"
            }
        }
    ],
    "localizedDescriptions": {
        "en": "Remember to use a vaccumcleaner"
    },
    "localizedSummaries": {
        "en": "clean condesor"
    }
}
 * ```
 */
export interface IncidentAPIResponse {
  summary: string;
  priority: INCIDENT_PRIORITY_TYPES;
  /** maybe unknown or undocumented categories */
  category: INCIDENT_CATEGORIES_TYPES;
  status: INCIDENT_STATUS_TYPES;
  /** id base64 */
  assignedUser: string;
  description: string;
  /** id base64 */
  id: string;
  /** timestamp */
  reportTimestamp: number;
  /** id base64 */
  device: string;
  history: IncidentHistoryAPIResponse[];
  /** other languages are unknown */
  localizedDescriptions: Record<LanguagesSported, string | undefined>;
  /** other languages are unknown */
  localizedSummaries: Record<LanguagesSported, string | undefined>;
  cu?: string;
  comment?: string;
}

export interface IncidentHistoryAPIResponse {
  summary: string;
  priority: INCIDENT_PRIORITY_TYPES;
  category: INCIDENT_CATEGORIES_TYPES;
  status: INCIDENT_STATUS_TYPES;
  /** id base64 */
  assignedUser?: string;
  user?: string;

  description: string;
  /** timestamp */
  timestamp: number;
  comment?: string;
  attributes: {
    summary: string;
    priority: INCIDENT_PRIORITY_TYPES;
    category: INCIDENT_CATEGORIES_TYPES;
    status: INCIDENT_STATUS_TYPES;
    /** id base64 */
    assignedUser: string;
    description: string;
  };
}

/**
 * 
 * Example:
 * ```json
{
  category: 'ALARM';
  description: '_disconn';
  id: 'N2RiODNhMDItODI3My00OTcxLTlmNGEtY2YxODk5MmJiYzg2';
  priority: 'MEDIUM';
  status: 'NEW';
  summary: '_disconn';
}
 * ```
 */
export interface IncidentUpdateAPIRequest {
  category: INCIDENT_CATEGORIES_TYPES;
  description: string;
  id: string;
  priority: INCIDENT_PRIORITY_TYPES;
  status: INCIDENT_STATUS_TYPES;
  summary: string;
  comment?: string;
  /** user.id */
  assignedUser?: string;
}

/**
 * Example
 * ```json
{
    "size": 24191,
    "fileName": "wtf.jpg",
    "url": "https://commander-cloud.eu/api/incidents/N2RiODNhMDItODI3My00OTcxLTlmNGEtY2YxODk5MmJiYzg2/attachments/d3RmLmpwZw=="
}
 * ```
 */
export interface IncidentsAttachmentsAPIResponse {
  size: number;
  fileName: string;
  url: string;
}

export enum INCIDENT_STATUS_TYPES {
  NEW = 'NEW',
  INPROGRESS = 'INPROGRESS',
  RESOLVED = 'RESOLVED',
}

export enum INCIDENT_PRIORITY_TYPES {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
/** at the moment I don't know any more types of them */
export enum INCIDENT_CATEGORIES_TYPES {
  MAINTENANCE = 'MAINTENANCE',
  ALARM = 'ALARM',
}

/**
 * example:
 * ```json
 *
{
  "device": "MWQyMWU4MzctYjAyMS00MmM2LTkzMjItZTIzNTk5ODExMWIx",
  "id": "OTNhZWY1NjgtMDQzMy00NTk0LWFmMzMtZDhhMmEyODBiYzE0",
  "priority": "MEDIUM",
  "status": "NEW",
};

 * ```
 */
export interface PublicIncidentAPIResponse {
  device: string;
  id: string;
  priority: INCIDENT_PRIORITY_TYPES;
  status: INCIDENT_STATUS_TYPES;
  summary: string;
  /** timestamp */
  reportTimestamp: number;
  /** other languages are unknown */
  localizedSummaries: Record<LanguagesSported, string | undefined>;
}

/**
 * Example:
 * ```json
  {
    "groupID": "NGY5MTVhMDctMDg0OC00ZjMzLWExNDEtNmYxNTUyNTJjYjM2",
    "defaultAssignee": "MTkzZGE0NTktMWU3Yi00MjNhLWEzMzItN2NmODI2MDNlMjM3",
    "defaultDescriptions": {},
    "diagnosisOptions": [
        "Test diagnostic",
        "Test 2 diagnostic",
        "Test 3"
    ],
    "notificationConfig": [
        {
            "type": "user",
            "integrationConfig": {
                "user": "Y2ViMDVkODktNjY1NS00ODVkLTlmMDgtYzdjODY3ZGEyYmE2"
            }
        }
    ]
  }
 * ```
 */
export interface IncidentConfigAPI {
  groupID: string;
  defaultAssignee?: string | null;
  defaultDescriptions: any;
  diagnosisOptions: string[];
  notificationConfig: IncidentConfigNotificationConfigAPI[];
}

export interface IncidentConfigNotificationConfigAPI {
  type: string;
  integrationConfig: {
    user: string;
  };
}
