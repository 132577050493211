/* eslint-disable eqeqeq */
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { SnackbarProps, withSnackbar } from 'stoerk-ui-components';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../../../../../../../handlingErrors';
import {
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../../../../../../../i18n';
import {} from '../../../../../../../../../../../../model/controlUnit/util/constants';
import { setCUParameters } from '../../../../../../../../../../../../redux/CUParameters/action/thunks';
import { ComposedParametersValue } from '../../../../../../../../../../../../redux/CUParameters/selectors';
import { useAppDispatch } from '../../../../../../../../../../../../redux/store.model';
import { QueryParamControlUnitPage } from '../../CUPage.model';
import { ParameterEdit } from './ParameterEdit';
import { ParameterView } from './ParameterView';

export interface ParameterProps
  extends QueryParamControlUnitPage,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    SnackbarProps {
  parameter: ComposedParametersValue;
  parameterLevelId: string;
}

function Parameter(props: ParameterProps) {
  const {
    deviceId = '',
    controlUnitId = '',
    parameterLevelId,
    polyglot,
    openSnackbar,
    handlingErrorsApi,
  } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClickOpenEdit = () => setShowEdit(true);
  const onClickCloseEdit = () => setShowEdit(false);
  const dispatch = useAppDispatch();
  const onSave = (parameter: ComposedParametersValue, newValue: string) => {
    setLoading(true);
    dispatch(
      setCUParameters({
        deviceId,
        controlUnitId,
        parameterLevelName: parameterLevelId,
        parameterName: parameter.name,
        parameterValue: newValue,
      })
    )
      .unwrap()
      .then(() => {
        const message = {
          text: polyglot.t('device.parameters.save_value_successfully'),
          type: 'ok',
        };
        openSnackbar(message);
        onClickCloseEdit();
      })
      .catch(handlingErrorsApi)
      .finally(() => setLoading(false));
  };
  return (
    <Paper variant="outlined">
      {showEdit ? (
        <ParameterEdit
          {...props}
          loading={loading}
          onClickClose={onClickCloseEdit}
          onSave={onSave}
        />
      ) : (
        <ParameterView {...props} onClickEdit={onClickOpenEdit} />
      )}
    </Paper>
  );
}

export default withHandlingErrors(withPolyglot(withSnackbar(Parameter)));
