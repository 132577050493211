/**
 * FIXME: WARN Mutation founded in this file
 */
import {
  FETCH_RECIPES_USER,
  FETCH_RECIPES_GROUP,
  POST_RECIPE_GROUP,
  PUT_RECIPE_GROUP,
  LOADING_RECIPES_USER,
  LOADING_RECIPES_GROUP,
  RECIPES_TRANSMISSIONS,
} from '../actions/Types';

const initialState = {
  recipesUser: [],
  recipesGroups: {},
  loadingRecipesUser: false,
  loadingRecipesGroup: false,
  transmission: {},
};

/**
 * Merge recipe group
 * @param object recipe
 * @param array recipesGroup
 * @param array recipesGroup: array with the updated recipes
 */
export const mergeRecipeGroup = (recipe, recipesGroup) => {
  const recipeUpdate = recipesGroup.find((r) => r.id === recipe.id);
  if (recipeUpdate !== undefined) {
    Object.keys(recipe).forEach((field) => {
      recipeUpdate[field] = recipe[field];
    });
  }
  return recipesGroup;
};

export default function reducer(state = initialState, action) {
  let recipesGroup;
  switch (action.type) {
    case FETCH_RECIPES_USER:
      return {
        ...state,
        recipesUser: action.payload,
      };
    case LOADING_RECIPES_USER:
      return {
        ...state,
        loadingRecipesUser: action.payload,
      };
    case FETCH_RECIPES_GROUP:
      return {
        ...state,
        recipesGroups: Object.assign({}, state.recipesGroups, {
          [action.payload.groupId]: action.payload.recipes,
        }),
      };
    case LOADING_RECIPES_GROUP:
      return {
        ...state,
        loadingRecipesGroup: action.payload,
      };
    case POST_RECIPE_GROUP:
      recipesGroup =
        state.recipesGroups[action.payload.groupId] !== undefined
          ? state.recipesGroups[action.payload.groupId]
          : [];

      recipesGroup.push(action.payload.recipe);
      return {
        ...state,
        recipesGroups: Object.assign({}, state.recipesGroups, {
          [action.payload.groupId]: recipesGroup,
        }),
      };
    case PUT_RECIPE_GROUP:
      recipesGroup = state.recipesGroups[action.payload.groupId].slice();
      recipesGroup = mergeRecipeGroup(action.payload.recipe, recipesGroup);
      return {
        ...state,
        recipesGroups: Object.assign(state.recipesGroups, {
          [action.payload.groupId]: recipesGroup,
        }),
      };
    case RECIPES_TRANSMISSIONS:
      return {
        ...state,
        transmission: action.payload,
      };
    default:
      return state;
  }
}
