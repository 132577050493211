import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useCurrentStateByControlUnitId } from '../../../../../../../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';

interface StatusProps {
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export default function Status(props: StatusProps) {
  return (
    <div className="ControlUnit-Status-container">
      <Paper variant="outlined" sx={{ padding: 1 }}>
        <Typography variant="body1">Status items:</Typography>
        <p> Work in progress</p>
      </Paper>
    </div>
  );
}
