import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider } from 'semantic-ui-react';
import { Button, Typography } from '@mui/material';
import GroupIconDefault from '../../../assets/GroupIconDefaultLandscape.svg';
import { withPolyglot } from '../../../i18n';
import { STModal } from '../../commons/Modal';

/**
 * Contacts
 * This class show the contact list of a group
 */
const Contacts = (props) => {
  const { group, showContacts } = props;
  if (Object.keys(group).length === 0) {
    return null;
  }
  return (
    <div>
      <STModal
        open={showContacts}
        title={group.name}
        iconURL={group.iconURL ? group.iconURL : GroupIconDefault}
        onClose={props.closeContacts}
        buttonActions={
          <Button onClick={props.closeContacts}>
            {props.polyglot.t('group.close_button_title')}
          </Button>
        }
      >
        <Typography variant="h6" textAlign="center">
          {props.polyglot.t('group.contact_list')}
        </Typography>
        {group.attributes.contacts.map((contact, index) => (
          <div key={index.toString()}>
            {contact.name && (
              <div>
                <Icon name="user" size="small" />
                {contact.name}
              </div>
            )}
            {contact.phone && (
              <div>
                <Icon name="phone" size="small" />
                {contact.phone}
              </div>
            )}
            {contact.email && (
              <div>
                <Icon name="mail" size="small" />
                {contact.email}
              </div>
            )}
            {group.attributes.contacts.length > index + 1 && <Divider />}
          </div>
        ))}
      </STModal>
    </div>
  );
};

Contacts.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    iconURL: PropTypes.string,
    attributes: PropTypes.shape({
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  showContacts: PropTypes.bool.isRequired,
  closeContacts: PropTypes.func.isRequired,
};

export default withPolyglot(React.memo(Contacts));
