import React from 'react';
import { connect } from 'react-redux';
import { Grid, Popup, Icon, Button } from 'semantic-ui-react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getLanguage, withPolyglot } from '../../../i18n';
import { getGroups } from '../../../redux/groups/selectors';
import SortUtil from '../../../util/SortUtil';
import { DATE_FORMAT } from '../../../util/Constants';
import { ICON_PRIORITY } from '../Constants';
import './index.css';
import {
  getAllPublicIncidents,
  getIsLoadingAllPublicIncidents,
} from '../../../redux/incidents/selectors';
import Polyglot from 'node-polyglot';
import { Group } from '../../../model/group/group.model';
import { PublicIncidentAPIResponse } from '../../../model/incidentManager/incident.model';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { RootState } from '../../../redux/store.model';
import { STButtonIcon } from '../../commons/STButtonIcon';

type Props = {
  polyglot: Polyglot;
  groups: Group[];
  incidents: PublicIncidentAPIResponse[];
  loading?: boolean;
} & RouteComponentProps;

// eslint-disable-next-line react/prefer-stateless-function
export class IncidentsBox extends React.PureComponent<Props> {
  state = { open: false };
  onClickShowAll = () => {
    this.setState({ open: false });
    this.props.history.push('/incidentmanager');
  };
  render() {
    let { incidents } = this.props;
    const { polyglot, loading } = this.props;
    const totalIncidents = incidents.length;
    if (incidents.length > 0) {
      /* sort by the newest */
      incidents = SortUtil.multisort(incidents, ['reportTimestamp'], ['DESC']);
      incidents = incidents.slice(0, 5);
    }
    const trigger = (
      <STButtonIcon
        loading={loading}
        badge={totalIncidents}
        color="inherit"
        onClick={() => this.setState({ open: !this.state.open })}
      >
        <Icon name="warning sign" />
      </STButtonIcon>
    );
    return (
      <Popup
        trigger={trigger}
        on={['click']}
        position="bottom left"
        open={this.state.open}
      >
        <Grid className="incident-box">
          {incidents.length <= 0 ? (
            <Grid.Row>
              <Grid.Column>
                {polyglot.t('incident_manager.no_new_incidents')}
              </Grid.Column>
            </Grid.Row>
          ) : (
            incidents.map((incident, index) => {
              /** Title: the title is found under the fields: summary or
            localizedSummaries {[en]: , [fr] }. First it will check if the title is available
            in the field localizedSummaries[locale]. If this is not available, then it will
            check if the field summary is not empty. If this is empty, then it will be used
            the first entry in the object localizedSummaries
            */
              let title;
              if (
                incident.localizedSummaries &&
                Object.keys(incident.localizedSummaries).length > 0 &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore languges bad typed
                incident.localizedSummaries[getLanguage()]
              ) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore languges bad typed
                title = incident.localizedSummaries[getLanguage()];
              } else {
                title = incident.summary ? incident.summary : '';
              }

              if (
                !title &&
                incident.localizedSummaries &&
                Object.keys(incident.localizedSummaries).length > 0
              ) {
                title =
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore languges bad typed
                  incident.localizedSummaries[
                    Object.keys(incident.localizedSummaries)[0]
                  ];
              }
              return (
                <Grid.Row key={index.toString()}>
                  {ICON_PRIORITY[incident.priority] && (
                    <Icon
                      name={
                        ICON_PRIORITY[incident.priority].icon as SemanticICONS
                      }
                      style={{ color: ICON_PRIORITY[incident.priority].color }}
                    />
                  )}
                  {moment(incident.reportTimestamp).format(DATE_FORMAT)}
                  <br />
                  {title}
                </Grid.Row>
              );
            })
          )}
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button onClick={this.onClickShowAll} primary>
                {polyglot.t('button.show_all')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Popup>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
  incidents: getAllPublicIncidents(state),
  loading: getIsLoadingAllPublicIncidents(state),
});

export default connect(
  mapStateToProps,
  {}
)(withPolyglot(withRouter(IncidentsBox)));
