import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import logo from '../../assets/stoerkIcon.png';
import ConnectNotificationBox from '../notifications/box';
import ConnectIncidentsBox from '../incidentManager/box';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../util/rights';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { logOut } from '../../redux/auth/actions';
import './index.css';
import { OpenSnackbarProps } from '../../handlingErrors';
import { RootState } from '../../redux/store.model';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import ConnectedAvatarMenu from './AvatarMenu';

type OwnProps = {
  logoIcon?: string;
  title?: string;
};
type Props = OwnProps &
  ConnectedComponentProps &
  RightsUserUtilComponentProps &
  OpenSnackbarProps &
  RouteComponentProps &
  PolyglotComponentProps;

export class Header extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCreateVoucher: false,
      showBoxNotifications: false,
      showBoxIncidents: false,
    };
  }
  logOut = async () => {
    const { history, openSnackbar } = this.props;
    try {
      await this.props.logOut();
      history.push('/');
    } catch (error: any) {
      const message = { header: '', text: error, type: 'error' };
      openSnackbar(message);
    }
  };

  render() {
    const {
      loggedIn,
      polyglot,
      history,
      avatarLocation,
      userName,
      userEmail,
      rightsUserUtil,
      title = polyglot.t('nav.title'),
      logoIcon = logo,
    } = this.props;
    const showCreateVoucher = rightsUserUtil.hasRightsToCreateVoucher();
    const showBoxNotifications = rightsUserUtil.hasRightReadMessages();
    const showBoxIncidents = rightsUserUtil.hasRightReadIncidents();
    const userNameShow = !userName ? userEmail : userName;

    return (
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Link to="/">
            <IconButton
              size="small"
              edge="start"
              aria-label="home"
              sx={{ mr: 1 }}
            >
              <img src={logoIcon} alt="logo" className="logo" />
            </IconButton>
          </Link>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              {title}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex' }}>
            {loggedIn && showBoxNotifications && <ConnectNotificationBox />}
            {loggedIn && showBoxIncidents && <ConnectIncidentsBox />}
            {loggedIn && (
              <ConnectedAvatarMenu
                avatarLocation={avatarLocation}
                userNameShow={userNameShow}
                showCreateVoucher={showCreateVoucher}
                logOut={this.logOut}
              />
            )}
            {!loggedIn && (
              <Button
                onClick={() => history.push('/login')}
                variant="contained"
                color="primary"
                className="login"
                endIcon={<Icon name="sign in" style={{ height: '35px' }} />}
              >
                {polyglot.t('nav.login')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
  userName: state.auth.userName,
  userEmail: state.auth.userEmail,
  avatarLocation: state.auth.avatarLocation,
  rights: state.rights.items,
});
const connector = connect(mapStateToProps, { logOut });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withSnackbar(
  withRouter(withPolyglot(withUserRightUtil(connector(Header))))
);
