import GroupModel from './group/Model';
import GroupCalls from './group/Calls';
import DeviceModel from './device/Model';
import DeviceCalls from './device/Calls';
import UserModel from './user/Model';
import UserCalls from './user/Calls';
import RightModel from './right/Model';
import RightCalls from './right/Calls';
import IntegrationRegistryCalls from './integrationRegistry/Calls';
import IncidentManagerCalls from './incidentManager/Calls';
import RestCalls from '../rest/RestCalls';
import NotificationCalls from './notifications/Calls';
import MaintenanceMessageCalls from './maintenanceMessage/Calls';
import DevicesCoordinatesCalls from './deviceCoordinates/Calls';
import BillingCalls from './billing/Calls';
import CameraCalls from './cameras/Calls';
import RecipeCalls from './recipes/Calls';
import AuthCalls from './auth/Calls';
import { restClient } from '../rest';
import { getPolyglot } from '../i18n';

/**
 * WARN: File with 900 lines!
 * Stop doing in this way please!
 */
/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class Model {
  restCalls: RestCalls;
  authCalls: AuthCalls;
  billingCalls: BillingCalls;
  cameraCalls: CameraCalls;
  deviceCalls: DeviceCalls;
  deviceModel: DeviceModel;
  devicesCoordinatesCalls: DevicesCoordinatesCalls;
  groupModel: GroupModel;
  groupCalls: GroupCalls;
  incidentManagerCalls: IncidentManagerCalls;
  integrationRegistryCalls: IntegrationRegistryCalls;
  maintenanceMessageCalls: MaintenanceMessageCalls;
  notificationCalls: NotificationCalls;
  recipeCalls: RecipeCalls;
  rightCalls: RightCalls;
  rightModel: RightModel;
  userCalls: UserCalls;
  userModel: UserModel;
  constructor() {
    const polyglot = getPolyglot();
    this.restCalls = new RestCalls(restClient);
    this.authCalls = new AuthCalls();
    this.billingCalls = new BillingCalls(restClient);
    this.cameraCalls = new CameraCalls(this.restCalls);
    this.deviceCalls = new DeviceCalls(this.restCalls);
    this.deviceModel = new DeviceModel(polyglot);
    this.devicesCoordinatesCalls = new DevicesCoordinatesCalls(this.restCalls);
    this.groupModel = new GroupModel();
    this.groupCalls = new GroupCalls();
    this.incidentManagerCalls = new IncidentManagerCalls();
    this.integrationRegistryCalls = new IntegrationRegistryCalls(
      this.restCalls
    );
    this.maintenanceMessageCalls = new MaintenanceMessageCalls(this.restCalls);
    this.notificationCalls = new NotificationCalls(restClient);
    this.recipeCalls = new RecipeCalls(this.restCalls);
    this.rightCalls = new RightCalls(restClient);
    this.rightModel = new RightModel();
    this.userCalls = new UserCalls(restClient);
    this.userModel = new UserModel(polyglot);
  }

  /**
   * Delete associated device
   * this function deletes a device to the user
   * @param string cloudConnectID
   */
  async deleteAssociatedDevice(deviceId: any) {
    await this.deviceCalls.deleteAssociatedDevice(deviceId);
    return deviceId;
  }

  /**
   * Get integration registry
   * @return array
   */
  async getIntegrationRegistry() {
    return this.integrationRegistryCalls.getIntegrationRegistry();
  }

  /**
   * Set maintenance message device
   * @param string deviceId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageDevice(deviceId: any, message: any) {
    return this.maintenanceMessageCalls.setMaintenanceMessageDevice(
      deviceId,
      message
    );
  }

  /**
   * Get maintenance messages device
   * @param string deviceId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesDevice(deviceId: any) {
    return this.maintenanceMessageCalls.getMaintenanceMessagesDevice(deviceId);
  }

  /**
   * Update maintenance message device
   * @param string deviceId
   * @param string messageId
   * @param object message
   * @return bool
   */
  async updateMaintenanceMessageDevice(
    deviceId: any,
    messageId: any,
    message: any
  ) {
    return this.maintenanceMessageCalls.updateMaintenanceMessageDevice(
      deviceId,
      messageId,
      message
    );
  }

  /**
   * Delete maintenance message device
   * @param string deviceId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageDevice(deviceId: any, messageId: any) {
    return this.maintenanceMessageCalls.deleteMaintenanceMessageDevice(
      deviceId,
      messageId
    );
  }

  /**
   * Set maintenance message group
   * @param string groupId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageGroup(groupId: any, message: any) {
    return this.maintenanceMessageCalls.setMaintenanceMessageGroup(
      groupId,
      message
    );
  }

  /**
   * Get maintenance messages group
   * @param string groupId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesGroup(groupId: any) {
    return this.maintenanceMessageCalls.getMaintenanceMessagesGroup(groupId);
  }

  /**
   * Update maintenance message group
   * @param string groupId
   * @param string messageId
   * @param object message
   * @return bool
   */
  async updateMaintenanceMessageGroup(
    groupId: any,
    messageId: any,
    message: any
  ) {
    return this.maintenanceMessageCalls.updateMaintenanceMessageGroup(
      groupId,
      messageId,
      message
    );
  }

  /**
   * Delete maintenance message group
   * @param string groupId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageGroup(groupId: any, messageId: any) {
    return this.maintenanceMessageCalls.deleteMaintenanceMessageGroup(
      groupId,
      messageId
    );
  }

  /**
   * Get device coordinates
   * @param array devices: [{street, zipcode, country}]
   * @return array devicesCoordinates [{street, zipcode, country, city, coordinates}]
   */
  async getDevicesCoordinates(devices: any[]) {
    return this.devicesCoordinatesCalls.getDevicesCoordinates(devices);
  }

  async getGroupCameras(groupID: any) {
    return this.cameraCalls.getGroupCameras(groupID);
  }

  async getPairableCameras(groupID: any) {
    return this.cameraCalls.getPairableCameras(groupID);
  }

  async pairCameras(groupID: any, cameras: any) {
    return this.cameraCalls.pairCameras(groupID, cameras);
  }

  async getCameraImage(groupID: undefined, cameraID: undefined) {
    return this.cameraCalls.getCameraImage(groupID, cameraID);
  }

  async getCameraVideo(groupID: any, cameraID: any) {
    return this.cameraCalls.getCameraVideo(groupID, cameraID);
  }

  async getAuthURL(groupID: any) {
    return this.cameraCalls.getAuthURL(groupID);
  }

  /**
   * Fetch recipes group
   * @param string groupId
   * @return array recipes
   */
  async fetchRecipesGroup(groupId: any) {
    return this.recipeCalls.fetchRecipesGroup(groupId);
  }

  /**
   * Fetch recipes associated to the user
   * @return array recipes
   */
  async fetchRecipesUser() {
    return this.recipeCalls.fetchRecipesUser();
  }

  /**
   * Post recipe group
   * Upload a new recipe file to a group
   * @param string groupId
   * @param object recipe
   * @return
   */
  async postRecipeGroup(groupId: any, recipe: any) {
    return this.recipeCalls.postRecipeGroup(groupId, recipe);
  }

  /**
   * Put recipe group
   * Update an existing recipe
   * @param string groupId
   * @param object recipe
   * @return
   */
  async putRecipeGroup(groupId: any, recipeId: any, recipe: any) {
    return this.recipeCalls.putRecipeGroup(groupId, recipeId, recipe);
  }

  /**
   * Recipes transmission
   * @param string groupId
   * @param array recipesIds
   * @param array devicesIdsBlackList: list of device which will be not get the recipes
   */
  async recipesTransmission(
    groupId: any,
    recipesIds: any,
    devicesIdsBlackList: any
  ) {
    return this.recipeCalls.recipesTransmission(
      groupId,
      recipesIds,
      devicesIdsBlackList
    );
  }
}

export default Model;
