import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { ParameterEditControlProps } from './ParameterEditControl';

export default function ParameterEditControlSelection(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value, polyglot } = props;
  if (!parameter.selections) return null;
  const id = `select-parameter-${parameter.name}`;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(event.target.value);
  };
  return (
    <TextField
      id={id}
      inputProps={{ 'data-testid': `input-${id}` }}
      select
      label={polyglot.t('device.parameters.values')}
      value={[value]}
      onChange={handleChange}
      fullWidth
    >
      {parameter?.selections.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {`${option.id}: ${option.text}`}
        </MenuItem>
      ))}
    </TextField>
  );
}
