import React from 'react';
import { Button } from 'semantic-ui-react';
import { Grid } from '@mui/material';

import DeviceModel from '../../../../../../model/device/Model';

import './index.css';
import { DevicesOverviewProps } from './DeviceOverview.util';
import ListItem from '../../../../../commons/ListItem';

/**
 * Devices overview list
 * this class show a list overview with all the associated devices
 * the devices array should have the following structure:
 * {  cloudConnectivity,
 *    cloudConnectivityIcon,
 *    cloudConnectivityTooltip,
 *    iconURL,
 *    lastPing,
 *    name,
 *    state: { state: }
 *    status: [ok, ok_inprogress, alert, alert_inprogress]
 *    incidents: []
 *    uuid
 *  }
 *  if the array incidents.length > 0 then will be show the icon alarms
 *
 */
// eslint-disable-next-line react/prefer-stateless-function
export class DevicesOverviewList extends React.PureComponent<DevicesOverviewProps> {
  render() {
    const {
      devices,
      getDeviceLabel,
      showDeviceEditIcon,
      getDeviceEditIcon,
      getDeviceUIIcon,
      getDeviceSensordataIcon,
      showDeviceSensordataIcon,
      getDeviceCloudConnectivityIcon,
      getDeviceInfoIcon,
      deviceInfoEnabled = false,
      showButtonReadIncidentsDevices = {},
      getDeviceAlarmsIcon,
      onClickItem,
      itemDisabled,
    } = this.props;
    return (
      <Grid container className="devices-overview-list">
        {devices.map((device) => (
          <ListItem
            key={device.uuid}
            id={device.uuid}
            name={device.name}
            label={getDeviceLabel(device)}
            loading={device.loadingData}
            iconURL={DeviceModel.getDeviceIcon(device.iconURL)}
            className={DeviceModel.getClassesNamesFromDevice(device)}
            onClick={(event: any) => onClickItem(event, device)}
            disabled={itemDisabled(device)}
            buttons={
              <Button.Group>
                {
                  /* Button edit device with a popup als tooltip */
                  showDeviceEditIcon(device.uuid) && getDeviceEditIcon(device)
                }

                {
                  /* Button ui device with a popup als tooltip */
                  getDeviceUIIcon(device)
                }

                {showDeviceSensordataIcon && getDeviceSensordataIcon(device)}

                {
                  /* Button alarms  a popup als tooltip */
                  showButtonReadIncidentsDevices[device.uuid] &&
                    getDeviceAlarmsIcon(device)
                }

                {
                  /* Icon status device with a popup als tooltip */
                  getDeviceCloudConnectivityIcon(device)
                }
                {deviceInfoEnabled && getDeviceInfoIcon(device)}
              </Button.Group>
            }
          />
        ))}
      </Grid>
    );
  }
}

export default DevicesOverviewList;
