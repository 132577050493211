import cookie from 'react-cookie';
import {
  SET_LOGGED_IN,
  GET_USER_INFO,
  UPDATE_USER_INFO,
  PASSWORD_TOKEN_VALIDATION,
  UPDATE_PASSWORD,
  GET_AUTH_CODE,
  GET_ACCESS_TOKEN,
  GET_REFRESH_TOKEN,
  LOGOUT,
  LOGIN,
  REFRESH_TOKEN,
  FETCHING_TOKEN,
  FETCHING_RIGHTS_CYCLE,
  REGISTER_CLIENT,
  RESET_PASSWORD,
  ACCOUNT_ACTIVATION,
  DESTROY_SESSION,
} from './Types';
import fetchOwnRights, { fetchRightsPeriodically } from '../../rights/actions';
import { activatedLoadingUnreadNotifications } from '../../notifications/actions';
import Util from '../../../util/Util';
import { actionsGroups } from '../../groups/slice';
import UserCalls from '../../../model/user/Calls';
import AuthCalls from '../../../model/auth/Calls';
import { abortCallsRestClient } from '../../../rest/RestClient';
import { getSavedFeatureToggles } from '../../../featureToggle/redux/thunks';
import getModuleByWorkspace from '../../module/actions';
import { getMyUserId } from '../selectors';
import { loadSessionOneSignal } from '../../../util/OneSignal/init';

const util = new Util();

/**
 * Set login
 * This function is called by starting the APP
 *
 * @param bool loggedIn: true if the user is logged
 */
export const setLogin = (loggedIn) => (dispatch) => {
  dispatch({
    type: SET_LOGGED_IN,
    payload: {
      loggedIn,
    },
  });
  if (loggedIn) {
    dispatch({
      type: FETCHING_RIGHTS_CYCLE,
      payload: true,
    });
  } else {
    dispatch({
      type: FETCHING_RIGHTS_CYCLE,
      payload: false,
    });
  }
};

/**
 * Persist auth code
 * This function saves the authcode in the local storage and als cookie
 * @param string authCode
 * @param bool keepLogin
 */
export const persistAuthCode = (authCode, keepLogin) => {
  if (!authCode) {
    return;
  }
  const authcodeStoreKey = util.getAuthcodeStoreKey();
  if (keepLogin) {
    /**
     * local storage Items do not expire automatically, so we only store the authentication
     * information permanently if the user decides to stay logged in. This is
     * mainly relevant for pinned Apps on iOS.
     */
    localStorage.setItem(authcodeStoreKey, authCode);
  } else {
    /* delete the authCode from the localstore to avoid to have old keys */
    localStorage.removeItem(authcodeStoreKey);
  }
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 1000);
  cookie.save(authcodeStoreKey, authCode, {
    path: '/',
    expires: d,
  });
};

/**
 * Persist refresh token
 * @param string refreshToken
 * @param bool keepLogin
 */
export const persistRefreshToken = (refreshToken, keepLogin) => {
  const refreshTokenKey = util.getRefreshTokenStoreKey();
  if (keepLogin) {
    /**
     * local storage Items do not expire automatically, so we only store the authentication
     * information permanently if the user decides to stay logged in. This is
     * mainly relevant for pinned Apps on iOS.
     */
    localStorage.setItem(refreshTokenKey, refreshToken);
  } else {
    /* delete the refreshToken from the localstore to avoid to have old keys */
    localStorage.removeItem(refreshTokenKey);
  }
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 1000);
  cookie.save(refreshTokenKey, refreshToken, {
    path: '/',
    expires: d,
  });
};

/**
 * Remove persisted authcode
 */
export const removePersistedAuthCode = () => {
  const authcodeStoreKey = util.getAuthcodeStoreKey();
  localStorage.removeItem(authcodeStoreKey);
  cookie.remove(authcodeStoreKey, { path: '/' });
};

/**
 * Remove persisted refresh token
 */
export const removePersistedRefreshToken = () => {
  const refreshTokenKey = util.getRefreshTokenStoreKey();
  localStorage.removeItem(refreshTokenKey);
  cookie.remove(refreshTokenKey, { path: '/' });
};

/**
 * Remove persisted access token
 */
export const removePersistedAccessToken = () => {
  const accessTokenStoreKey = util.getAccessTokenStoreKey();
  cookie.remove(accessTokenStoreKey, { path: '/' });
  localStorage.removeItem(accessTokenStoreKey);
};

/**
 * Update token
 * @param string authToken
 * @param string refreshToken
 * @param expiration
 * @param bool keepLogin
 */
export const updateToken = (authToken, refreshToken, expiration, keepLogin) => {
  const accessTokenStoreKey = util.getAccessTokenStoreKey();
  const d = new Date();
  if (keepLogin) {
    /**
     * local storage Items do not expire automatically, so we only store the authentication
     * information permanently if the user decides to stay logged in. This is
     * mainly relevant for pinned Apps on iOS.
     */
    localStorage.setItem(accessTokenStoreKey, refreshToken);
  } else {
    /* delete the refreshToken from the localstore to avoid to have old keys */
    localStorage.removeItem(accessTokenStoreKey);
  }
  d.setTime(d.getTime() + expiration * 1000);
  cookie.save(accessTokenStoreKey, authToken, {
    path: '/',
    expires: d,
  });

  persistRefreshToken(refreshToken, keepLogin);
};

/**
 * Refresh token action
 */
export const refreshTokenAction =
  (authCode, refreshToken, keepLogin) => async (dispatch) => {
    const authCalls = new AuthCalls();
    dispatch({
      type: FETCHING_TOKEN,
      payload: true,
    });

    if (refreshToken) {
      /* the call passwordTokenValidation returns false or true but as string */
      const { token, newRefreshToken, expiration } =
        await authCalls.refreshToken(refreshToken);
      updateToken(token, newRefreshToken, expiration, keepLogin);
      dispatch(setLogin(true));
      dispatch({
        type: REFRESH_TOKEN,
        payload: {
          accessToken: token,
          refreshToken: newRefreshToken,
        },
      });
    } else {
      const { token, refreshToken, expiration } = await authCalls.getToken(
        authCode
      );
      updateToken(token, refreshToken, expiration, keepLogin);
      dispatch({
        type: REFRESH_TOKEN,
        payload: {
          accessToken: token,
          refreshToken: refreshToken,
        },
      });
    }
    dispatch({
      type: FETCHING_TOKEN,
      payload: false,
    });
  };

/**
 * Log out
 */
export const logOut = () => async (dispatch) => {
  removePersistedAccessToken();
  removePersistedAuthCode();
  removePersistedRefreshToken();
  /* stop the periodically loading of the rights */
  await dispatch(setLogin(false));
  /* stop the periodically loading of the groups and devices */
  await dispatch(actionsGroups.activatedLoadingGroupsDevices(false));
  await dispatch(activatedLoadingUnreadNotifications(false));
  abortCallsRestClient();
  dispatch({ type: DESTROY_SESSION });
  dispatch({
    type: LOGOUT,
    payload: '',
  });
};

/**
 * Password token validation
 * @param string token
 */
export const passwordTokenValidation = (token) => async (dispatch) => {
  const authCalls = new AuthCalls();
  /* the call passwordTokenValidation returns false or true but as string */
  const passwordTokenValid = await authCalls.passwordTokenValidation(token);
  dispatch({
    type: PASSWORD_TOKEN_VALIDATION,
    payload: passwordTokenValid,
  });
};

/**
 * Update password
 * @param string token
 * @param string password
 */
export const updatePassword = (token, password) => async (dispatch) => {
  const authCalls = new AuthCalls();
  await authCalls.updatePassword(token, password);
  dispatch({
    type: UPDATE_PASSWORD,
    payload: true,
  });
};

/**
 * Get user id key
 */
export const getUserIdKey = () => async (dispatch) => {
  const userCalls = new UserCalls();
  const user = await userCalls.getUserInformation();
  dispatch({
    type: GET_USER_INFO,
    payload: {
      avatarLocation: user.avatarLocation,
      userName: user.name,
      userEmail: user.email,
      id: user.id,
    },
  });
};

/**
 * Update user info
 * @param string userName
 * @param string avatarLocation
 */
export const updateUserInfo =
  (userName, avatarLocation) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_INFO,
      payload: {
        avatarLocation,
        userName,
      },
    });
  };

/**
 * Log In:
 * This function get the authcode, token, userId and rights
 * @param string email
 * @param string password
 * @param bool keeplogin
 */
export const login = (email, password, keepLogin) => async (dispatch) => {
  const authCalls = new AuthCalls();
  const authCode = await authCalls.login(email, password);
  await persistAuthCode(authCode, keepLogin);
  await dispatch(refreshTokenAction(authCode, null, keepLogin));
  await dispatch(initializeUserInformation());
  dispatch({
    type: LOGIN,
    payload: {
      keepLogin,
      authCode,
    },
  });
  await dispatch(setLogin(true));
};

export const loadSession = () => async (dispatch, getState) => {
  /* fill in the variable accessToken */
  await dispatch(getAccessToken());
  /* fill in the variable authCode */
  await dispatch(getAuthCode());
  /* fill in the variable refreshToken */
  await dispatch(getRefreshToken());

  const {
    auth: { authCode, refreshToken, keepLogin },
  } = getState();
  if (authCode && refreshToken) {
    await dispatch(refreshTokenAction(authCode, refreshToken, keepLogin));
  }
  const {
    auth: { loggedIn },
  } = getState();
  if (loggedIn) {
    await dispatch(initializeUserInformation());
  }
};

export const initializeUserInformation = () => async (dispatch, getState) => {
  return Promise.all([
    dispatch(getUserIdKey()),
    dispatch(fetchOwnRights()),
    dispatch(getModuleByWorkspace()),
    dispatch(getSavedFeatureToggles()),
    dispatch(fetchRightsPeriodically()),
  ]).then(() => {
    const userId = getMyUserId(getState());
    // not wait for OneSignal validations
    loadSessionOneSignal(userId);
  });
};

/**
 * Get access token
 * this function get the access token from the cookies
 */
export const getAccessToken = () => async (dispatch) => {
  const accessTokenStoreKey = util.getAccessTokenStoreKey();
  let accessToken = cookie.load(accessTokenStoreKey, { path: '/' }) || '';
  /* if the cookie is empty, then we will try to get the access token from the localStorage */
  if (!accessToken) {
    accessToken = localStorage.getItem(accessTokenStoreKey);
  }
  dispatch({
    type: GET_ACCESS_TOKEN,
    payload: accessToken,
  });
};

/**
 * Get auth code
 * this function get that auth code from the local store/cookies
 */
export const getAuthCode = () => async (dispatch) => {
  const authcodeStoreKey = util.getAuthcodeStoreKey();
  let authCode = localStorage.getItem(authcodeStoreKey);
  let keepLogin = false;
  if (authCode) {
    keepLogin = true;
  } else {
    authCode = cookie.load(authcodeStoreKey, { path: '/' });
  }

  dispatch({
    type: GET_AUTH_CODE,
    payload: {
      authCode,
      keepLogin,
    },
  });
};

/**
 * Get refresh token
 * this function get the refresh token from the local storage/cookies
 */
export const getRefreshToken = () => async (dispatch) => {
  const refreshTokenKey = util.getRefreshTokenStoreKey();
  let refreshToken = localStorage.getItem(refreshTokenKey);
  let keepLogin = false;
  if (refreshToken) {
    keepLogin = true;
  } else {
    refreshToken = cookie.load(refreshTokenKey, { path: '/' });
  }
  dispatch({
    type: GET_REFRESH_TOKEN,
    payload: {
      refreshToken,
      keepLogin,
    },
  });
};

/**
 * Register client
 * @param string email
 * @param string password
 */
export const registerClient = (email, password) => async (dispatch) => {
  const authCalls = new AuthCalls();
  await authCalls.registerClient(email, password);
  dispatch({
    type: REGISTER_CLIENT,
    payload: true,
  });
};

/**
 * Reset password
 * @param string email
 */
export const resetPassword = (email) => async (dispatch) => {
  const authCalls = new AuthCalls();
  await authCalls.resetPassword(email);
  dispatch({
    type: RESET_PASSWORD,
    payload: true,
  });
};

/**
 * Account activation
 * @param string token
 */
export const accountActivation = (token) => async (dispatch) => {
  const authCalls = new AuthCalls();
  await authCalls.accountActivation(token);
  dispatch({
    type: ACCOUNT_ACTIVATION,
    payload: true,
  });
};

/**
 * InviteUser
 * @param string[] emails
 */
export const inviteUsers = (emails) => async (dispatch) => {
  const authCalls = new AuthCalls();
  await Promise.all(emails.map((email) => authCalls.inviteUser(email)));
};
