import { restClient as restClientHttp } from '../../rest';
import { RestClientFacade } from '../../rest/rest.model';
import Util from '../../util/Util';
import { DEVICE_STATUS_TYPE } from '../device/device.model';

import * as callTypes from './CallTypes';
import {
  GroupAPIResponse,
  GroupCreateAPI,
  GroupCreateAPIResponse,
  GroupUpdateResponse,
} from './group.model';
import GroupModel from './Model';
import { cleanUndefined } from '../../util/CleanUndefined';
import { IconUpload } from '../../redux/groups/groups.model';

/**
 */
class Calls {
  readonly backendGroupServiceURL = new Util().getGroupServiceURL();
  constructor(private restClient: RestClientFacade = restClientHttp) {}

  /**
   * Get tree groups not populated
   */
  getGroupList(): Promise<GroupAPIResponse[]> {
    const url = `${this.backendGroupServiceURL}`;
    return this.restClient.callPromise(
      {
        url,
        method: 'GET',
      },
      callTypes.getGroups
    );
  }

  /**
   * Get users by groupId
   * @param string groupId
   */
  async getUsersByGroupId(groupId: string) {
    const url = `${this.backendGroupServiceURL}/${groupId}/users`;
    return this.restClient.callPromise<string[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getUsersByGroupId
    );
  }

  /**
   * Get group
   * @param string groupId
   * @return object group
   */
  async getGroup(groupId: string) {
    const url = `${this.backendGroupServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to send the commander data
    return this.restClient.callPromise<GroupAPIResponse>(
      {
        url,
        method: 'GET',
        headers,
      },
      callTypes.getGroup
    );
  }

  /**
   * Create group
   * @param array groupData
   * @return promise
   */
  createGroup(groupData: GroupCreateAPI): Promise<GroupCreateAPIResponse> {
    const url = `${this.backendGroupServiceURL}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    return this.restClient.callPromise(
      {
        url,
        method: 'POST',
        headers,
        entity: groupData,
      },
      callTypes.deleteGroupDevice
    );
  }

  /**
   * Update group
   * @param array groupData
   * @return promise
   */
  updateGroup(groupId: string, groupData: Partial<GroupCreateAPI>) {
    const url = `${this.backendGroupServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to send the commander data
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: groupData,
      },
      callTypes.updateGroup
    );
  }

  /**
   * Save avatar
   * @param string groupId
   * @param object iconUpload
   * @return promise
   */
  saveAvatar(groupId: string, iconUpload: any) {
    const form = new FormData();
    form.append('file', iconUpload.file, iconUpload.fileName);
    const url = `${this.backendGroupServiceURL}/${groupId}/icon`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: form,
      },
      callTypes.deleteGroupDevice
    );
  }

  /**
   * Post group
   * create a new group
   * @param object groupData
   * @param object iconUpload
   * @return object groupData
   */
  async postGroup(
    groupData: GroupCreateAPI,
    users: string[],
    iconUpload?: { imagePreviewUrl: any }
  ) {
    const newGroup = await this.createGroup(groupData);
    if (iconUpload && newGroup.newID) {
      await this.saveAvatar(newGroup.newID, iconUpload);
    }
    const group: GroupAPIResponse = {
      id: newGroup.newID,
      name: groupData.name,
      attributes: groupData.attributes,
      devices: [],
      users,
      childGroups: [],
      /* @TODO: the name of the picture */
      /* @TODO: if the assigned iconURL to the group makes problems, we should
    call the group and get all the information */
      iconURL: iconUpload?.imagePreviewUrl,
      /* Since the group is new we must assign the status Ok to the group */
      status: DEVICE_STATUS_TYPE.OK,
    };

    return group;
  }

  /**
   * Delete group
   * @param string groupId
   * @return promise
   */
  async deleteGroup(groupId: any) {
    const url = `${this.backendGroupServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callTypes.deleteGroup
    );
  }

  /**
   * Delete group device
   * @param string groupId
   * @param string deviceId
   */
  async deleteGroupDevice(groupId: any, deviceId: any) {
    const url = `${this.backendGroupServiceURL}/${groupId}/devices/${deviceId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    // call rest api to remove a device from a group
    return this.restClient.callPromise(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callTypes.deleteGroupDevice
    );
  }

  /**
   * Put group
   * update a group
   * @param string groupId
   * @param object groupData
   * @param object iconUpload
   * @return promise
   */
  async putGroup(
    groupId: string,
    groupData: Partial<GroupCreateAPI>,
    iconUpload?: IconUpload
  ): Promise<GroupUpdateResponse> {
    const model = new GroupModel();
    await this.updateGroup(groupId, groupData);

    if (iconUpload) {
      await this.saveAvatar(groupId, iconUpload);
    }
    /* @TODO the name of the picture */

    const group = {
      id: groupId,
      name: groupData.name || undefined,
      attributes: model.parseAttributes(groupData.attributes),
      iconURL: iconUpload?.imagePreviewUrl,
    };

    return cleanUndefined(group);
  }

  /**
   * Delete group icon
   * @param string groupId
   * @return promise
   */
  async deleteGroupIcon(groupId: string) {
    const url = `${this.backendGroupServiceURL}/${groupId}/icon`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to delete/reset the icon
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callTypes.createGroup
    );
  }

  /**
   * Delete group user
   * @param string groupId
   * @param string userId
   */
  async deleteGroupUser(groupId: string, userId: string) {
    const url = `${this.backendGroupServiceURL}/${groupId}/users/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    // call rest api to remove a device from a group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callTypes.deleteGroupDevice
    );
  }

  /**
   * Put group users
   * @param string groupId
   * @param array usersId
   */
  async putGroupUsers(groupId: string, usersId: string[]) {
    const url = `${this.backendGroupServiceURL}/${groupId}/users`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: usersId,
      },
      callTypes.putGroupUsers
    );
  }

  /**
   * Put group add device
   * @param string groupId
   * @param array devicesId
   */
  async putGroupAddDevices(groupId: any, devicesId: any) {
    const url = `${this.backendGroupServiceURL}/${groupId}/devices`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to asign a device to a group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'PUT',
        headers,
        entity: devicesId,
      },
      callTypes.putGroupAddDevices
    );
  }
}

export default Calls;
