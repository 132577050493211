import { createSelector } from '@reduxjs/toolkit';
import { getTranslateManyDevicesFunction } from '../../../util/CUTranslation/getTranslateFunction';
import { ZipDeviceControlUnit } from '../../controlUnit/controlUnit.model';
import { RootState } from '../../store.model';

export const getLoadingState = (state: RootState) =>
  state.CUtranslations.loadingDeviceCUTranslation;
export const getTranslationState = (state: RootState) =>
  state.CUtranslations.dictionaryCUTranslation;

export const getCUsTranslationsSelector = createSelector(
  [getTranslationState],
  getTranslateManyDevicesFunction
);
export const getCUTranslationFromDevice = (
  state: RootState,
  props: { deviceId: string }
) => getTranslationState(state)[props.deviceId];

export const getCUTranslationParametersFromControlUnit = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) =>
  getTranslationState(state)[props.deviceId]?.[props.controlUnitId]
    ?.translations.parameterTranslations;

export const getLoadingCUTranslationFromDevice = (
  state: RootState,
  props: { deviceId: string }
) => {
  const loadingDeviceCUTranslation =
    state.CUtranslations.loadingDeviceCUTranslation[props.deviceId];
  return loadingDeviceCUTranslation
    ? Object.values(loadingDeviceCUTranslation).every((loading) => loading)
    : loadingDeviceCUTranslation;
};

const getZipDeviceControlUnitsFromProps = (
  _state: any,
  props: { zipDeviceControlUnits: ZipDeviceControlUnit[] }
) => props.zipDeviceControlUnits;
export const getNeedFetchCUTranslationFromDevices = createSelector(
  [getLoadingState, getZipDeviceControlUnitsFromProps],
  (loadingDeviceCUTranslation, zipDeviceControlUnits) => {
    return zipDeviceControlUnits.some((zipDeviceControlUnit) =>
      zipDeviceControlUnit.controlUnits?.some(
        (item) =>
          loadingDeviceCUTranslation[zipDeviceControlUnit.deviceId]?.[
            item.id
          ] === undefined
      )
    );
  }
);

export const getLoadingCUTranslationFromDevices = createSelector(
  [getLoadingState, getZipDeviceControlUnitsFromProps],
  (loadingDeviceCUTranslation, zipDeviceControlUnits) => {
    return zipDeviceControlUnits.some((zipDeviceControlUnit) =>
      zipDeviceControlUnit.controlUnits?.some(
        (item) =>
          loadingDeviceCUTranslation[zipDeviceControlUnit.deviceId]?.[item.id]
      )
    );
  }
);
