import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import './index.css';
import { Button, Divider, Typography, Menu, MenuItem } from '@mui/material';
import BrowserUtil from '../../util/BrowserUtil';
import { connect, ConnectedProps } from 'react-redux';
import { getApplicationsFeatureToggle } from '../../featureToggle';
import { RootState } from '../../redux/store.model';

const browserUtil = new BrowserUtil();

/**
 * const int widthMaxToShowUserNameHeader: this variable will be used to show the
 * name of the user in the header or as option in the menu
 */
const widthMaxToShowUserNameHeader = 640;
interface AvatarMenuProps
  extends PolyglotComponentProps,
    ConnectedComponentProps {
  userNameShow: string;
  avatarLocation?: string;
  showCreateVoucher?: boolean;
  logOut: () => unknown;
}

function AvatarMenu(props: AvatarMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    avatarLocation,
    userNameShow,
    showCreateVoucher,
    logOut,
    polyglot,
    listApps,
  } = props;
  return (
    <div>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        color="inherit"
        sx={{ textTransform: 'inherit' }}
        size="large"
        endIcon={
          avatarLocation ? (
            <img src={avatarLocation} alt="" className="avatar" />
          ) : (
            <Icon name="user" className="avatar" />
          )
        }
      >
        {browserUtil.getOuterWidth() > widthMaxToShowUserNameHeader &&
          userNameShow}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to="/userprofile"
          onClick={handleClose}
          className="button"
          color="inherit"
        >
          <Typography color="MenuText">
            <MenuItem>{polyglot.t('nav.menu.user_profile')}</MenuItem>
          </Typography>
        </Link>
        {showCreateVoucher && (
          <Link to="/createVoucher" onClick={handleClose}>
            <MenuItem>
              <Typography color="MenuText">
                {polyglot.t('nav.menu.create_voucher')}
              </Typography>
            </MenuItem>
          </Link>
        )}
        {listApps.map((stringApp) => (
          <Link to={`/${stringApp}`} onClick={handleClose} key={stringApp}>
            <MenuItem>
              <Typography color="MenuText">
                {polyglot.t(`nav.menu.${stringApp}`)}
              </Typography>
            </MenuItem>
          </Link>
        ))}

        <Divider />

        <MenuItem
          onClick={() => {
            logOut();
            handleClose();
          }}
        >
          {polyglot.t('nav.logout')}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  listApps: getApplicationsFeatureToggle(state),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedAvatarMenu = connector(withPolyglot(AvatarMenu));

export default ConnectedAvatarMenu;
