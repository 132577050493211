import { useRouteMatch } from 'react-router-dom';
import { useControlUnitsByIds } from './useControlUnitsByIds';
import { useGroupById } from '../../groups/hooks/useGroupById';
import { useMemo } from 'react';

export function useDevicesControlUnits() {
  const match = useRouteMatch<{
    deviceId?: string;
    groupId?: string;
    controlUnitId?: string;
  }>();
  const {
    params: { groupId, deviceId, controlUnitId },
  } = match;
  const [, groupsLoading, groupError] = useGroupById(groupId, (error) => {
    console.error(error);
  });

  const [
    devicesControlUnitsSource,
    devicesControlUnitsLoading,
    devicesControlUnitsError,
  ] = useControlUnitsByIds();

  // filter by QueryParam
  const devicesControlUnits = useMemo(() => {
    let devicesControlUnitsTemp = devicesControlUnitsSource;
    // deviceId
    if (deviceId)
      devicesControlUnitsTemp = devicesControlUnitsSource.filter(
        (devicesControlUnit) => devicesControlUnit.deviceId === deviceId
      );
    // controlUnitId
    if (controlUnitId)
      devicesControlUnitsTemp = devicesControlUnitsSource.map(
        (devicesControlUnit) => ({
          ...devicesControlUnit,
          controlUnits: devicesControlUnit.controlUnits?.filter(
            // eslint-disable-next-line eqeqeq
            (controlUnit) => controlUnit.id == controlUnitId
          ),
        })
      );
    return devicesControlUnitsTemp;
  }, [devicesControlUnitsSource, deviceId, controlUnitId]);

  const loading = groupsLoading || devicesControlUnitsLoading;
  const error = groupError || devicesControlUnitsError;

  return [devicesControlUnits, loading, error] as const;
}
