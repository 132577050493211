import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

export default class ImpressumPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    return (
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h2>Impressum</h2>
              <p>
                STÖRK-TRONIC, Störk GmbH &amp; Co. KG
                <br />
                Untere Waldplätze 6
                <br />
                D-70569 Stuttgart (Vaihingen)
                <br />
                Telefon: +49 (0)711 / 68 66 1-0
                <br />
                Telefax: +49 (0)711 / 68 66 1-44
                <br />
                <a href="mailto:info@stoerk-tronic.com">
                  info@stoerk-tronic.com
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Registergericht: Amtsgericht Stuttgart HRA Nr. 12442</p>
              <p>
                Persönlich haftende Gesellschafterin:
                <br />
                STÖRK-TRONIC-Verwaltungs-GmbH
                <br />
                Vertretungsberechtigter Geschäftsführer:
                <br />
                Gabor Kleimann, MA, MBA
              </p>
              <p>
                Ust-Ident-Nummer: DE 147637585
                <br />
                WEEE-Reg.-Nr.: DE 88527866
              </p>
              <p>&nbsp;</p>
              <h3>Erklärung zum Copyright</h3>
              <p>
                Copyright – STÖRK-TRONIC, Störk GmbH &amp; Co. KG. Alle Rechte
                vorbehalten. Dokumente, Grafiken, Software und Quelltexte, die
                Bestandteile dieser Website darstellen und von diesem Server
                heruntergeladen werden können, sind urheberrechtlich für die
                Störk-Tronic, Störk GmbH &amp; Co. KG geschützt. Sofern nicht
                anders vermerkt, bedürfen die Verwendung, Reproduktion und
                Weitergabe dieser Dokumente, Grafiken und Software der
                schriftlichen Genehmigung der Störk-Tronic, Störk GmbH &amp; Co.
                KG
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

ImpressumPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};
