import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Card, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { BoxPictureData } from 'stoerk-ui-components';
import RecipeIconDefault from '../../../../../assets/RecipeIconDefault.svg';

/**
 * Select recipes
 */
export class SelectRecipes extends Component {
  constructor(props) {
    super(props);
    this.showRecipes = this.showRecipes.bind(this);
  }

  showRecipes() {
    const { recipesGroup } = this.props;
    /* Show recipe list */
    const recipes = recipesGroup.map((recipe, index) => (
      <BoxPictureData
        key={index.toString()}
        title={recipe.name}
        picture={recipe.icon ? recipe.icon : RecipeIconDefault}
        size="small"
        icons={[
          {
            button: (
              <Checkbox
                onClick={(event, data) =>
                  this.props.selectRecipes(recipe, data)
                }
              />
            ),
          },
        ]}
      />
    ));
    return recipes;
  }

  render() {
    return (
      <Container fluid>
        <Card.Group textAlign="left">{this.showRecipes()}</Card.Group>
      </Container>
    );
  }
}

SelectRecipes.propTypes = {
  recipesGroup: PropTypes.arrayOf(Object).isRequired,
  selectRecipes: PropTypes.func.isRequired,
};

export default connect()(SelectRecipes);
