import countries from 'i18n-iso-countries/entry-node';

export default class ISO3316Util {
  static getCountryname(countryCode, locale) {
    return countries.getName(countryCode, locale);
  }

  static getAllCountries(locale) {
    const codes = [];
    countries.langs();
    countries.getNames('en');
    Object.keys(countries.getAlpha2Codes()).forEach((code) => {
      codes.push({
        code,
        name: countries.getName(code, locale),
      });
    });
    codes.sort((a, b) => (a.name < b.name ? -1 : 1));
    return codes;
  }
}
