import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Grid } from '@mui/material';
import { UiInputSemantic } from 'stoerk-ui-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import BrowserUtil from '../../../util/BrowserUtil';
import ButtonAdd from '../buttonAdd';
import './index.css';

export const VIEW_GRID = 'viewGrid';
export const VIEW_LIST = 'viewList';
export const VIEW_GRID_SMALL = 'viewGridSmall';
export const VIEW_MAP = 'viewMap';
export const SORT_FIELD_NAME = 'name';
export const SORT_FIELD_STATUS = 'statusValue';
export const ASC = 'ASC';
export const DESC = 'DESC';

const browserUtil = new BrowserUtil();

/**
 * TopBarMenu
 * This component shows a top bar menu with the following components:
 * * Search
 * * Button view list
 * * Button view grid
 * * Button view grid small
 * * Button map
 * * Button sort by name
 * * Button sort by status
 * * Button add
 *
 * The elements: search, button map,  button sort by status and button add are optional
 */
export class TopBarMenu extends Component {
  constructor(props) {
    super(props);
    this.changeViewMap = this.changeViewMap.bind(this);
  }

  /**
   * Open map page
   */
  changeViewMap() {
    const { history } = this.props;
    history.push('/map');
  }

  render() {
    const {
      showSearch,
      onSearchChange,
      changeViewList,
      changeViewGrid,
      changeViewGridSmall,
      selectedView,
      showSortByStatus,
      sort,
      sortField,
      sortByName,
      sortByStatus,
      onClickAddButton,
      menuAddButton,
      showAddButton,
      showViewMap,
      buttons,
    } = this.props;
    const size = 'large';
    return (
      <Grid container spacing={2} mb={1}>
        {/* Search button */}
        <Grid item xs={12} md>
          {showSearch && (
            <UiInputSemantic
              onChange={onSearchChange}
              icon="search"
              fluid
              id="search"
            />
          )}
        </Grid>
        {/* View list and grid */}
        <Grid
          item
          className="wrapper-menu-device-manager"
          justifyContent={{ xs: 'flex-end', md: 'initial' }}
          flex={{ xs: 1, md: 'initial' }}
        >
          <div>
            <Button.Group className="top-bar-menu">
              <Button
                icon="list layout"
                size={size}
                onClick={changeViewList}
                className={selectedView === VIEW_LIST ? 'selected' : ''}
                id="ButtonViewList"
              />
              <Button
                icon="block layout"
                size={size}
                onClick={changeViewGrid}
                className={selectedView === VIEW_GRID ? 'selected' : ''}
                id="ButtonViewGrid"
              />
              <Button
                icon="grid layout"
                size={size}
                onClick={changeViewGridSmall}
                className={selectedView === VIEW_GRID_SMALL ? 'selected' : ''}
                id="ButtonViewGridSmall"
              />
              {showViewMap && (
                <Button
                  icon="map"
                  size={size}
                  onClick={this.changeViewMap}
                  className={selectedView === VIEW_MAP ? 'selected' : ''}
                  id="ButtonViewMap"
                />
              )}
              <Button
                icon={
                  sort[SORT_FIELD_NAME] === ASC
                    ? 'sort alphabet ascending'
                    : 'sort alphabet descending'
                }
                size={size}
                onClick={sortByName}
                className={sortField === SORT_FIELD_NAME ? 'selected' : ''}
                id="sortName"
              />
              {showSortByStatus && (
                <Button
                  icon={
                    sort[SORT_FIELD_STATUS] === ASC
                      ? 'sort content ascending'
                      : 'sort content descending'
                  }
                  size={size}
                  onClick={sortByStatus}
                  className={sortField === SORT_FIELD_STATUS ? 'selected' : ''}
                  id="sortStatusValue"
                />
              )}
            </Button.Group>
            {buttons.length > 0 && ' '}
            {buttons.length > 0 &&
              buttons.map((button) => (
                // eslint-disable-next-line react/jsx-key
                <div
                  className="top-bar-menu"
                  style={{ display: 'inline-flex', flexDirection: 'row' }}
                >
                  {button}{' '}
                </div>
              ))}
          </div>
          <div>
            {!browserUtil.getIsPhone() && showAddButton && (
              <ButtonAdd
                onClick={onClickAddButton}
                menu={menuAddButton}
                raised={false}
              />
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

TopBarMenu.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  showSearch: PropTypes.bool,
  onSearchChange: PropTypes.func,
  changeViewList: PropTypes.func.isRequired,
  changeViewGrid: PropTypes.func.isRequired,
  changeViewGridSmall: PropTypes.func.isRequired,
  showViewMap: PropTypes.bool,
  selectedView: PropTypes.string,
  onClickAddButton: PropTypes.func,
  menuAddButton: PropTypes.arrayOf(Object),
  showAddButton: PropTypes.bool,
  showSortByStatus: PropTypes.bool,
  sort: PropTypes.any,
  sortField: PropTypes.string,
  sortByName: PropTypes.func.isRequired,
  sortByStatus: PropTypes.func.isRequired,
  buttons:
    PropTypes.arrayOf(Object) /* array with other buttons [{icon, onClick}] */,
};

TopBarMenu.defaultProps = {
  showSearch: false,
  onSearchChange: null,
  selectedView: VIEW_LIST,
  onClickAddButton: null,
  menuAddButton: null,
  showAddButton: false,
  showSortByStatus: false,
  sort: {},
  sortField: '',
  showViewMap: false,
  buttons: [],
};

export default withRouter(TopBarMenu);
