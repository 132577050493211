import { restClient as restClientHttp } from '../../rest';
import Util from '../../util/Util';
import { VoucherAPICreate } from './billing.model';
import * as callTypes from './CallTypes';

/**
 * Model interface class:
 */
export default class BillingCalls {
  readonly backendBillingServiceURL =
    new Util().getBillingManagmentServiceURL();
  constructor(private restClient = restClientHttp) {}

  /**
   * Get modules
   *
   * @deprecated currently is not working. itt need a fix in back-side
   * [P900004016-1026](http://jira.st.local/jira/browse/P900004016-1026)
   */
  async getModules() {
    const url = `${this.backendBillingServiceURL}/modules`;
    return this.restClient.callPromise(
      {
        url,
        method: 'GET',
      },

      callTypes.getModules
    );
  }

  async addVoucher(voucher: VoucherAPICreate) {
    const url = `${this.backendBillingServiceURL}/voucher`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.restClient.callPromise(
      {
        url,
        method: 'POST',
        headers,
        entity: voucher,
      },
      callTypes.addVoucher
    );
  }
}
