import React from 'react';
import withStyles, { WithStylesProps, Styles } from 'react-jss';
import { Button, Loader, StrictGridRowProps } from 'semantic-ui-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Menu,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid as MUIGrid,
} from '@mui/material';
import { uniqueId } from 'lodash';

const styles: Styles = {
  row: { minHeight: '50px', alignItems: 'center' },
  columnCenter: {
    height: '100%',
    display: 'flex!important',
    justifyContent: 'center',
    'flex-direction': 'column',
    flex: 1,
  },
  columnIcon: {
    position: 'relative',
    alignItems: 'center',
  },
  icon: {
    maxHeight: '35px',
    maxWidth: '100%',
    'object-fit': 'contain',
    marginLeft: '10px',
    marginRight: '10px',
  },
};

interface ListItemProps extends WithStylesProps<typeof styles> {
  id?: string;
  name?: React.ReactNode;
  label?: React.ReactNode;
  buttons?: React.ReactNode;
  iconURL?: string;
  iconNode?: React.ReactNode;
  className?: StrictGridRowProps['className'];
  loading?: boolean;
  /** Item is clickable */
  disabled?: boolean;
  onClick?: (event: any) => unknown;
}

export function ListItem(props: ListItemProps) {
  const {
    loading,
    iconURL,
    iconNode,
    className,
    disabled,
    onClick,
    label,
    name,
    id,
    buttons,
    classes,
  } = props;
  const onClickItem = (event: any) => !disabled && onClick && onClick(event);

  /** Tooltip */
  const theme = useTheme();
  const useTooltip = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [idTooltip] = React.useState(uniqueId('ListItem-Tooltip-'));

  return (
    <MUIGrid
      id={id}
      container
      item
      xs={12}
      className={`ListItem ${className} ${classes.row}`}
      sx={{ margin: 0 }}
      columnSpacing={1}
    >
      {/* icon */}
      <MUIGrid
        item
        xs={2}
        md={1}
        className={`${classes.columnIcon} ${classes.columnCenter} ${
          !disabled ? 'link' : ''
        }`}
        onClick={onClickItem}
      >
        {label}
        {loading ? (
          <Loader active inline />
        ) : (
          <>
            {iconURL && (
              <img src={iconURL} alt={'item icon'} className={classes.icon} />
            )}
            {iconNode}
          </>
        )}
      </MUIGrid>

      {/* Device name */}
      <MUIGrid
        item
        xs={9}
        sm={4}
        md={6}
        textAlign="left"
        className={`description ${classes.columnCenter} ${
          !disabled ? 'link' : ''
        }`}
        onClick={onClickItem}
      >
        {name}
      </MUIGrid>

      {useTooltip ? (
        buttons && (
          <MUIGrid
            item
            xs={1}
            textAlign="right"
            className={classes.columnCenter}
          >
            <IconButton
              id={`${idTooltip}-menu-button`}
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              edge="start"
              onClick={handleClick}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${idTooltip}-menu`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': `${idTooltip}-menu-button`,
              }}
            >
              <Button.Group onClick={handleClose}>{buttons}</Button.Group>
            </Menu>
          </MUIGrid>
        )
      ) : (
        <MUIGrid
          item
          xs="auto"
          className={classes.columnCenter}
          alignItems="end"
        >
          <Button.Group>{buttons}</Button.Group>
        </MUIGrid>
      )}
    </MUIGrid>
  );
}

export default withStyles(styles)(ListItem);
