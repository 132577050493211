import { createSelector } from '@reduxjs/toolkit';
import { getControlUnitById } from '.';
import { mergeActionMetadataAndEvent } from '../../../model/controlUnit/util/action/mergeActionMetadataAndEvent';
import { getControlUnitCurrentEventsById } from './events';

export const getActionsMetadataSelectorById = createSelector(
  [getControlUnitById],
  (controlUnit) => {
    return controlUnit?.actions;
  }
);

export const getActionExtendedSelectorById = createSelector(
  [getActionsMetadataSelectorById, getControlUnitCurrentEventsById],
  mergeActionMetadataAndEvent
);
