import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './index.css';

/**
 * Button add
 * This component works in two ways:
 * 1. if the onClick parameter is sent then the button open a formular to add
 * 2. if the onClicl paramter is not sent then the parameter menu should be sent with
 *    the menu options that the button add should be displayed
 */
// eslint-disable-next-line react/prefer-stateless-function
export default class ButtonAdd extends React.PureComponent {
  render() {
    const { onClick, raised, menu } = this.props;
    /* The raised button could be a simple button to open a formular or a button to
    open a menu */
    let raisedButton = <Button primary circular icon="add" size="large" />;
    if (onClick) {
      raisedButton = (
        <raisedButton.type {...raisedButton.props} {...{ onClick }} />
      );
    } else {
      raisedButton = (
        <Dropdown
          trigger={<span>{raisedButton}</span>}
          options={menu}
          pointing={raised ? 'bottom right' : 'top right'}
          icon={null}
        />
      );
    }

    return (
      <div className={raised ? 'add-device-button' : 'add-device-button-fix'}>
        {raisedButton}
      </div>
    );
  }
}

ButtonAdd.propTypes = {
  onClick: PropTypes.func,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      key: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  raised: PropTypes.bool,
};

ButtonAdd.defaultProps = {
  onClick: null,
  menu: [],
  raised: false,
};
