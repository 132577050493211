import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

export default class ImpressumPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    return (
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h2>Impressum</h2>
              <p>
                ©THE ALAN NUTTALL PARTNERSHIP LTD. All rights reserved.
                <br />
                THE ALAN NUTTALL PARTNERSHIP LTD | FOODSERVICE SOLUTIONS
                <br />
                Orchard House, Dodwells Road, Hinckley, Leicestershire, United
                Kingdom LE10 3BZ
                <br />
                <br />
                [t] +44 (0) 1455 638300
                <br />
                [e]{' '}
                <a href="mailto:info@stoerk-tronic.com">info@flexeserve.com</a>
                <br />
                Group website: www.nuttalls.co.uk
                <br />
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

ImpressumPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};
