import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  getDigitalChannelDataSelector,
  getFilterStored,
  getMonitoringLoading,
  getSensordataSelector,
} from '../../../../../../../redux/monitoring/selectors';
import {
  fetchMetrics,
  updateFilter,
} from '../../../../../../../redux/monitoring/thunk';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../redux/store.model';
import {
  CommanderMonitoringFilterLogicalObject,
  getFilterLogicalObjectFromZipDeviceControlUnits,
} from '../util/filter.model';
import { filterAnalogChannels } from '../util/filterAnalogChannels';
import { filterDigitalChannels } from '../util/filterDigitalChannels';
import { useDevicesControlUnits } from '../../../../../../../redux/controlUnit/hooks/useDevicesControlUnits';
import { STTimeLineChartData, TimeFrame } from '../monitoring.model';
import { filterExtrapolatedDigitalChannelData } from '../util/filterExtrapolatedDigitalChannelData';
import { useCUsTranslate } from '../../../../../../../util/CUTranslation/hook/useCUsTranslate';
import { checkDefaultChannels } from '../util/checkDefaultChannels';
import { getLevelOfView } from '../../../../../../../redux/groups/selectors/getLevelOfView';

export interface UseMetricsProps {
  initialTimeFrameStart?: Moment;
  initialTimeFrameEnd?: Moment;
}

export const useMetrics = (props: UseMetricsProps) => {
  const {
    initialTimeFrameStart = moment().subtract(6, 'hours'),
    initialTimeFrameEnd = moment(),
  } = props;
  const match = useRouteMatch<{
    deviceId?: string;
    groupId?: string;
    controlUnitId?: string;
  }>();
  const showLevelView = getLevelOfView(match.params);
  const dispatch = useAppDispatch();
  const loadingMetrics = useAppSelector(getMonitoringLoading);
  const [devicesControlUnits, loadingDevicesControlUnits] =
    useDevicesControlUnits();
  const [, loadingCUsTranslate] = useCUsTranslate();
  const [readyToFetch, setReadyToFetch] = useState(false);
  const loading = loadingMetrics !== false || !readyToFetch;

  // Filter controls
  const [timeFrame, setTimeFrame] = useState<TimeFrame>({
    start: initialTimeFrameStart,
    end: initialTimeFrameEnd,
  });

  const defaultFilterObjectEventsStored = useAppSelector(
    (store) => getFilterStored(store, { ...match.params, type: 'events' }),
    isEqual
  );
  const defaultFilterObjectChannelsStored = useAppSelector(
    (store) => getFilterStored(store, { ...match.params, type: 'channels' }),
    isEqual
  );
  const defaultFilterObjectChannels = useMemo(
    () =>
      // Hydrate stored value
      defaultFilterObjectChannelsStored
        ? defaultFilterObjectChannelsStored
        : // Get default
          checkDefaultChannels(
            getFilterLogicalObjectFromZipDeviceControlUnits(
              devicesControlUnits,
              'channels',
              false
            )
          ),
    // I don't want update this every time value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesControlUnits]
  );
  const defaultFilterObjectEvents = useMemo(
    () =>
      // Hydrate stored value
      defaultFilterObjectEventsStored
        ? defaultFilterObjectEventsStored
        : // Get default
          checkDefaultChannels(
            getFilterLogicalObjectFromZipDeviceControlUnits(
              devicesControlUnits,
              'events',
              false
            )
          ),
    // I don't want update this every time value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesControlUnits]
  );
  const digitalControls = useState<CommanderMonitoringFilterLogicalObject>(
    defaultFilterObjectEvents
  );
  const analogControls = useState<CommanderMonitoringFilterLogicalObject>(
    defaultFilterObjectChannels
  );
  const [digitalFilterObjectEvents, setDigitalFilterObject] = digitalControls;
  const [analogFilterObjectEvents, setAnalogFilterObject] = analogControls;
  const externalSetDigitalFilterObject: typeof setDigitalFilterObject = (
    value
  ) => {
    setDigitalFilterObject(value);
    dispatch(
      updateFilter({
        ...match.params,
        type: 'events',
        value: value as CommanderMonitoringFilterLogicalObject,
      })
    );
  };
  const externalSetAnalogFilterObject: typeof setAnalogFilterObject = (
    value
  ) => {
    setAnalogFilterObject(value);
    dispatch(
      updateFilter({
        ...match.params,
        type: 'channels',
        value: value as CommanderMonitoringFilterLogicalObject,
      })
    );
  };

  // getting data

  const sensordata = useAppSelector((state) =>
    getSensordataSelector(state, { match })
  );
  const digitalChannels = useAppSelector((state) =>
    getDigitalChannelDataSelector(state, { match })
  );
  const sensordataFiltered = useMemo(() => {
    if (loading) return [];
    return filterAnalogChannels(sensordata, analogFilterObjectEvents);
  }, [sensordata, analogFilterObjectEvents, loading]);

  const digitalChannelsFiltered = useMemo(() => {
    if (loading) return {} as STTimeLineChartData;
    return filterExtrapolatedDigitalChannelData(
      filterDigitalChannels(digitalChannels, digitalFilterObjectEvents),
      timeFrame.start.unix(),
      timeFrame.end.unix()
    );
  }, [
    digitalChannels,
    digitalFilterObjectEvents,
    loading,
    timeFrame.end,
    timeFrame.start,
  ]);

  // Fetch
  useEffect(() => {
    if (
      !(loadingDevicesControlUnits || loadingCUsTranslate) &&
      readyToFetch !== true
    ) {
      setDigitalFilterObject(defaultFilterObjectEvents);
      setAnalogFilterObject(defaultFilterObjectChannels);
      setReadyToFetch(true);
    }
  }, [
    defaultFilterObjectChannels,
    defaultFilterObjectEvents,
    loadingCUsTranslate,
    loadingDevicesControlUnits,
    readyToFetch,
    setAnalogFilterObject,
    setDigitalFilterObject,
  ]);
  useEffect(() => {
    if (readyToFetch) {
      dispatch(
        fetchMetrics({
          devicesControlUnits,
          start: timeFrame.start.valueOf(),
          end: timeFrame.end.valueOf(),
          analogFilterObjectEvents,
          digitalFilterObjectEvents,
          showLevelOption: showLevelView,
        })
      );
    }
    // ignore devicesControlUnits as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeFrame,
    dispatch,
    readyToFetch,
    analogFilterObjectEvents,
    digitalFilterObjectEvents,
  ]);

  return {
    timeControls: { timeFrame, setTimeFrame },
    digitalControls: [
      digitalFilterObjectEvents,
      externalSetDigitalFilterObject,
    ] as typeof digitalControls,
    analogControls: [
      analogFilterObjectEvents,
      externalSetAnalogFilterObject,
    ] as typeof analogControls,
    metrics: {
      sensordata: sensordataFiltered,
      digitalChannels: digitalChannelsFiltered,
    },
    loading,
  };
};
