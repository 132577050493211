import { PublicIncidentAPIResponse } from '../incidentManager/incident.model';

export interface DeviceDataAPIResponse {
  uuid: string;
  name: string;
  username: string;
  /** cloudfront url */
  iconURL?: string | null;
  /** timestamp */
  registration: number;
  /** timestamp */
  lastPing?: number;
  /** ip format e.g. `10.12.4.148`  */
  ip: string;
  street?: string;
  city?: string;
  country?: string;
  zipcode?: string;
}

export interface DeviceAPIMerged {
  uuid: string;
  data: DeviceDataAPIResponse;
  state?: DeviceStatusAPIResponse;
  incidents: PublicIncidentAPIResponse[];
}

export interface DeviceCloudConnectivityAttributes {
  cloudConnectivity: boolean;
  cloudConnectivityIcon: string;
  cloudConnectivityTooltip: string;
}

export interface StatusParsed {
  status: DEVICE_STATUS_TYPE;
  /** 1-8 */
  statusValue: number;
}

export interface Device
  extends DeviceCloudConnectivityAttributes,
    /** if not have state, would not have StatusParsed */
    Partial<StatusParsed>,
    Partial<DeviceDataAPIResponse> {
  uuid: string;
  data: DeviceDataAPIResponse | undefined;
  state: DeviceStatusAPIResponse | undefined;
  incidents: PublicIncidentAPIResponse[];
  loadingData?: boolean;
  loadingStatus?: boolean;
  loadingIncidentsUnresolved?: boolean;
  label?: string;
}

export interface DeviceStatusAPIResponse {
  deviceID: string;
  state: DEVICE_STATUS_TYPE;
  mqttConnected?: boolean;
  vpnConnected?: boolean;
  connected?: boolean;
}

export enum DEVICE_STATUS_TYPE {
  NEW = 'NEW',
  ALERT = 'ALERT',
  ALERT_INPROGRESS = 'ALERT_INPROGRESS',
  OK = 'OK',
  OK_INPROGRESS = 'OK_INPROGRESS',
  INPROGRESS = 'INPROGRESS',
}

/**
 * Example:
 * ```json
 {
  "id": "1011",
  "name": "REGLER 1",
  "channels": [
      {
          "id": "F01",
          "unit": "DEGREECELCIUS",
          "minVal": 3276,
          "maxVal": 0
      },
      {
          "id": "F02",
          "unit": "NON",
          "minVal": 32767,
          "maxVal": 0
      },
     ...
  ],
  "events": [
      {
          "id": "Alarm",
          "name": null,
          "type": "NUMBER",
          "groups": [
              "a",
              "r",
              "e1",
              "e2",
              "e3",
              "k1",
              "k2",
              "h1"
          ],
          "cat": "ALARM",
          "minVal": 0,
          "maxVal": 0
      },
      {
          "id": "DOE",
          "name": null,
          "type": "NUMBER",
          "groups": [
              "a",
              "r",
              "h1"
          ],
          "cat": "ALARM",
          "minVal": 0,
          "maxVal": 0
      },
     ...
  ],
  "version": {
      "progNum": "209",
      "progVar": "1",
      "progVer": "50",
      "unitId": "0",
      "year": "2018",
      "month": "2",
      "day": "27",
      "seq": "231",
      "prod": "900317125"
  },
  "setpoints": [
      {
          "name": "~s_0",
          "unit": "DEGREECELCIUS",
          "min": 700,
          "max": 950
      },
      {
          "name": "~s_1",
          "unit": "NON",
          "min": -99,
          "max": 999
      }
  ],
  "parameters": {
      "A--": {
          "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_A--",
          "parameters": [
              {
                  "name": "A--",
                  "type": "INT",
                  "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_A--",
                  "min": -99,
                  "max": 999
              },
              {
                  "name": "A 0",
                  "type": "INT",
                  "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_A 0",
                  "min": 0,
                  "max": 3
              },
              ...
          ]
      },
      "P--": {
          "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_P--",
          "parameters": [
              {
                  "name": "P--",
                  "type": "INT",
                  "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_P--",
                  "min": -99,
                  "max": 999
              },
              {
                  "name": "P 1",
                  "type": "FLOAT",
                  "description": "TXT_XML_<ValueTexts/900317125|xml>_PARAM_P 1",
                  "min": -1,
                  "max": 1
              },
              ...
          ]
      },
    ...
  },
  "actions": [
      {
          "id": "On",
          "changeable": true
      },
      {
          "id": "ControlLoop1",
          "changeable": true
      },
     ...
  ],
  "ramvalues": [{
    "id": 'F01';
    "name": 'H10';
    "unit": 'DEGREECELCIUS';
    "unitString": '°C';
    "exp": 0;
    "minVal": 3276.7;
    "maxVal": 0;
    "decimals": 2;
  }]
}
 * ```
 */
export interface DeviceControlUnitsAPIResponse {
  id: string;
  name: string;
  channels: DeviceControlUnitsChannelMetadataAPIResponse[];
  state: 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';
  detailedState: null | 'NON' | 'UNINSTALLED' | 'DISCONNECTED' | 'INSTALLED';
  events: DeviceControlUnitsEventsMetadataAPIResponse[];
  version: {
    progNum: '209';
    progVar: '1';
    progVer: '50';
    unitId: '0';
    year: '2018';
    month: '2';
    day: '27';
    seq: '231';
    prod: '900317125';
  };
  setpoints: DeviceControlSetPointsMetadataAPIResponse[];
  parameters: Record<string, DeviceControlParameterMetadataAPIResponse>;
  actions: DeviceControlActionMetadataAPIResponse[];
  ramvalues: DeviceControlRamValuesMetadataAPIResponse[];
}

export interface DeviceControlUnitsChannelMetadataAPIResponse {
  id: string;
  name: string | null;
  /** DEGREECELCIUS, NON, PERCENT */
  unit: string;
  /** '°C' */
  unitString: string;
  minVal: number;
  maxVal: number;
  decimals: number;
}

export interface DeviceControlUnitsEventsMetadataAPIResponse {
  id: string;
  name: string | null;
  /** "NUMBER" */
  type: string;
  groups: string[];
  /** "ALARM" */
  cat: string;
  minVal: number;
  maxVal: number;
}

export interface DeviceControlSetPointsMetadataAPIResponse {
  id: string;
  name: string;
  /** DEGREECELCIUS, NON, PERCENT */
  unit: string;
  min: number;
  max: number;
  unitString: null | string;
  /** used for scientific notation, by default is ´0´ */
  exp: number;
  /** used to specified the precision or number of decimals to show, by default is ´0´ */
  dec: number;
}

export interface DeviceControlParameterMetadataAPIResponse {
  description: string | null;
  parameters: {
    name: string;
    /** "INT". "FLOAT" */
    type: 'INT' | 'FLOAT' | 'TIME' | 'DATE';
    description: string | null;
    min: number;
    max: number;
    unit: string;
    unitString: null;
    exp: 0;
    decimals: number;
  }[];
}

export interface DeviceControlActionMetadataAPIResponse {
  id: string;
  name: undefined;
  changeable: boolean;
}

/**
 * 
```json
{
  "id": "F01",
  "name": "H10",
  "unit": "DEGREECELCIUS",
  "unitString": "°C",
  "exp": 0,
  "minVal": 3276.7,
  "maxVal": 0,
  "decimals": 2,
}
```
 */
export interface DeviceControlRamValuesMetadataAPIResponse {
  id: string;
  name: string;
  unit: string;
  unitString: string;
  exp: number;
  minVal: number;
  maxVal: number;
  decimals: number;
}
