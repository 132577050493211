import React, { Component } from 'react';
import { Grid, Loader, Card, Image } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import {
  BoxPictureData,
  SIZE_SMALL,
  SIZE_MEDIUM,
  UiTable,
  UiButton,
  withSnackbar,
} from 'stoerk-ui-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SortUtil, { SortDirections } from '../../util/SortUtil';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../util/rights';
import {
  HandlingErrorWrappedProps,
  OpenSnackbarProps,
  withHandlingErrors,
} from '../../handlingErrors';
import ConnectTopBarMenu, {
  VIEW_LIST,
  VIEW_GRID,
  VIEW_GRID_SMALL,
  SORT_FIELD_NAME,
  ASC,
  DESC,
} from '../commons/topBarMenu';
import {
  fetchRecipesGroup,
  postRecipeGroup,
} from '../../redux/recipes/actions';
import { warmUpGroups } from '../../redux/groups/actions/thunks';
import { fetchGroup } from '../../redux/groups/actions/thunks-creator';
import { getLanguage, PolyglotComponentProps, withPolyglot } from '../../i18n';
import RecipeIconDefault from '../../assets/RecipeIconDefault.svg';
import { getGroups, isGroupsLoading } from '../../redux/groups/selectors';
import { RootState } from '../../redux/store.model';
import { RecipeAPIResponse } from '../../model/recipes/recipes.model';

type Props = {} & ConnectedComponentProps &
  PolyglotComponentProps &
  OpenSnackbarProps &
  RouteComponentProps<{ groupId: string }> &
  HandlingErrorWrappedProps &
  RightsUserUtilComponentProps;

type State = {
  showLoadingMessage: boolean;
  view: string;
  sortDirections: {
    name: SortDirections;
  };
  value: null | string;
  rightsToTransmitRecipe: boolean;
  rightsToUpdateRecipe: boolean;
  rightsToReadRecipe?: boolean;
  showNoRightsMessage: boolean;
};
/**
 * Recipe manager
 * This class show a list of recipes for a device or a group
 */
export class RecipeManager extends Component<Props, State> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.showRecipes = this.showRecipes.bind(this);
    this.uploadRecipe = this.uploadRecipe.bind(this);
    this.fileInputRef = React.createRef();
    /* the loading message will be shown only by the component mount */
    this.state = {
      showLoadingMessage: true,
      view: VIEW_LIST,
      sortDirections: { [SORT_FIELD_NAME]: ASC },
      value: null,
      rightsToTransmitRecipe: false,
      rightsToUpdateRecipe: false,
      showNoRightsMessage: false,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load data
   * The data to be shown here, it is obtained from the groups structre.
   * if the groups are empty then it will be load again.
   * Get the incidents by device or group
   */
  async loadData() {
    try {
      const { rightsUserUtil, match, groups } = this.props;
      const { groupId } = match.params;
      await this.props.fetchRecipesGroup(groupId);
      /* get group */
      if (!groups || groups === undefined || groups.length === 0) {
        await this.props.fetchGroups();
      }
      await this.props.fetchGroup({ groupId });
      const rightsToTransmitRecipe = rightsUserUtil.hasRightsToTransmitRecipe(
        null,
        groupId
      );
      const rightsToUpdateRecipe = rightsUserUtil.hasRightsToUpdateRecipe(
        null,
        groupId
      );
      const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
        null,
        groupId
      );
      this.setState({
        showLoadingMessage: false,
        rightsToTransmitRecipe,
        rightsToUpdateRecipe,
        rightsToReadRecipe,
        showNoRightsMessage: true,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      this.setState({
        showLoadingMessage: false,
      });
      handlingErrorsApi(error);
    }
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { history } = this.props;
    history.goBack();
  }

  /* loadRecipeContent(file) {
    return new Promise(((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const recipe = JSON.parse(fileReader.result);
        resolve(recipe);
      };
      fileReader.readAsText(file)
    }));
  } */

  async uploadRecipe(e: { target: { files: FileList | null } }) {
    const { openSnackbar, polyglot, handlingErrorsApi } = this.props;
    try {
      const { match } = this.props;
      const { groupId } = match.params;
      const file = e.target.files && e.target.files[0];
      if (!file) throw new Error('No file selected');

      const recipe = {
        fileName: file.name,
        file: file,
      };
      await this.props.postRecipeGroup(groupId, recipe);
      const message = {
        text: polyglot.t('recipe_manager.add_recipe_group_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
      await this.props.fetchRecipesGroup(groupId);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Show recipes
   * this function shows the recipes in a list or grid view
   */
  showRecipes() {
    const { recipesGroups, match } = this.props;
    const { groupId } = match.params;
    let recipesGroup =
      recipesGroups[groupId] !== undefined ? recipesGroups[groupId] : [];
    const { value, sortDirections, view } = this.state;
    /* search */
    if (value) {
      const re = new RegExp(value, 'i');
      recipesGroup = recipesGroup.filter((g) => re.test(g.name));
    }
    /* Sort */
    recipesGroup =
      recipesGroup.length > 0
        ? SortUtil.multisort(
            recipesGroup,
            [SORT_FIELD_NAME],
            [sortDirections[SORT_FIELD_NAME]]
          )
        : [];

    const getTranslatedName = (recipe: RecipeAPIResponse) => {
      if (recipe.translatedName && recipe.translatedName[getLanguage()]) {
        return recipe.translatedName[getLanguage()];
      }
      return recipe.name;
    };

    const getTranslatedDescription = (recipe: RecipeAPIResponse) => {
      if (
        recipe.translatedDescription &&
        recipe.translatedDescription[getLanguage()]
      ) {
        return recipe.translatedDescription[getLanguage()];
      }
      return '';
    };

    /* Show recipe list */
    let recipes;
    if (view === VIEW_LIST) {
      const content = recipesGroup.map((recipe, index) => ({
        key: index.toString(),
        columns: [
          {
            content: (
              <Image
                src={recipe.icon ? recipe.icon : RecipeIconDefault}
                size="mini"
              />
            ),
            width: 1,
          },
          {
            content: getTranslatedName(recipe),
          },
          {
            content: getTranslatedDescription(recipe),
          },
        ],
      }));
      recipes = <UiTable content={content} basic />;
    } else {
      recipes = recipesGroup.map((recipe, index) => (
        <BoxPictureData
          key={index.toString()}
          title={getTranslatedName(recipe)}
          description={getTranslatedDescription(recipe)}
          picture={recipe.icon ? recipe.icon : RecipeIconDefault}
          size={view === VIEW_GRID ? SIZE_MEDIUM : SIZE_SMALL}
        />
      ));
      recipes = <Card.Group textAlign="left">{recipes}</Card.Group>;
    }
    return recipes;
  }

  /**
   * Sort field
   * This function set th
   * @param string sortField
   */
  sort() {
    const { sortDirections } = this.state;
    sortDirections[SORT_FIELD_NAME] =
      sortDirections[SORT_FIELD_NAME] === ASC ? DESC : ASC;
    this.setState({
      sortDirections,
    });
  }

  render() {
    const { loadingGroups, recipesGroups, match, polyglot, history } =
      this.props;
    const {
      rightsToTransmitRecipe,
      rightsToUpdateRecipe,
      rightsToReadRecipe,
      showNoRightsMessage,
    } = this.state;

    const { groupId } = match.params;
    const recipesGroup =
      recipesGroups[groupId] !== undefined ? recipesGroups[groupId] : [];
    const { showLoadingMessage, view, sortDirections } = this.state;

    /* The top bar shows the buttons: upload recipe and download recipes */
    const buttons = [];
    if (rightsToUpdateRecipe) {
      const button = (
        <div>
          <UiButton
            iconName="cloud upload"
            onClick={() => this.fileInputRef.current?.click()}
            sizeButton="large"
            showLabels={false}
          />
          <input
            ref={this.fileInputRef}
            type="file"
            hidden
            onChange={this.uploadRecipe}
          />
        </div>
      );
      buttons.push(button);
    }

    if (rightsToTransmitRecipe && recipesGroup.length > 0) {
      buttons.push(
        <UiButton
          iconName="download"
          onClick={() => history.push(`/recipemanager/export/group/${groupId}`)}
          sizeButton="large"
          showLabels={false}
        />
      );
    }
    /* if the user has not rights to transmit the recipe */
    if (!rightsToReadRecipe && showNoRightsMessage) {
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                {polyglot.t('recipe_manager.no_rights_read_recipes')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        {showLoadingMessage && loadingGroups && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Loader active inline />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <ConnectTopBarMenu
          showSearch
          onSearchChange={(event: { target: { value: string } }) =>
            this.setState({ value: event.target.value })
          }
          changeViewList={() => this.setState({ view: VIEW_LIST })}
          changeViewGrid={() => this.setState({ view: VIEW_GRID })}
          changeViewGridSmall={() => this.setState({ view: VIEW_GRID_SMALL })}
          selectedView={view}
          showAddButton={false}
          sort={sortDirections}
          sortField={SORT_FIELD_NAME}
          sortByName={() => this.sort()}
          buttons={buttons}
        />
        {this.showRecipes()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  recipesGroups: state.recipes.recipesGroups as Record<
    string,
    RecipeAPIResponse[]
  >,
  loadingRecipesGroup: state.recipes.loadingRecipesGroup,
  groups: getGroups(state),
  loadingGroups: isGroupsLoading(state),
});

const connector = connect(mapStateToProps, {
  fetchRecipesGroup,
  fetchGroup,
  fetchGroups: warmUpGroups,
  postRecipeGroup,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withUserRightUtil(withSnackbar(withPolyglot(withRouter(RecipeManager))))
  )
);
