import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react';

export default function InformationMessageBox(props) {
  const { message, locale } = props;
  if (!message || Object.keys(message).length === 0) {
    return null;
  }

  const text = message[locale];
  return (
    <Message icon color="yellow" className="information-message">
      <Icon name="info" size="mini" />
      <Message.Content>
        <p>{text}</p>
      </Message.Content>
    </Message>
  );
}

InformationMessageBox.propTypes = {
  message: PropTypes.objectOf(Object).isRequired,
  locale: PropTypes.string.isRequired,
};
