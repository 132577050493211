/**
 * transform  'Content-Type': 'json' to  'Content-Type': 'application/x-www-form-urlencoded',
 */
export const objectToFormURLEncoded = <T>(object: T) => {
  const formData = new URLSearchParams();
  for (const [key, value] of Object.entries(object)) {
    formData.append(key, value);
  }
  return formData;
};
