import React from 'react';
import { ParameterEditProps } from '../ParameterEdit';
import ParameterEditControlBitMultipleSelection from './ParameterEditControlBitMultipleSelection';
import ParameterEditControlDate from './ParameterEditControlDate';
import ParameterEditControlSelection from './ParameterEditControlSelection';
import ParameterEditControlSlider from './ParameterEditControlSlider';
import ParameterEditControlSwitch from './ParameterEditControlSwitch';
import ParameterEditControlTime from './ParameterEditControlTime';

export interface ParameterEditControlProps extends ParameterEditProps {
  value: string;
  onChangeValue: (value: string, error?: string) => unknown;
}
export function ParameterEditControl(props: ParameterEditControlProps) {
  const { parameter } = props;

  if (parameter.selections) {
    return <ParameterEditControlSelection {...props} />;
  }
  if (parameter.bits) {
    return <ParameterEditControlBitMultipleSelection {...props} />;
  }
  if (parameter.type === 'TIME') {
    return <ParameterEditControlTime {...props} />;
  }
  if (parameter.type === 'DATE') {
    return <ParameterEditControlDate {...props} />;
  }
  if (parameter.min === 0 && parameter.max === 1) {
    return <ParameterEditControlSwitch {...props} />;
  }
  return <ParameterEditControlSlider {...props} />;
}
