import RightCalls from '../../../model/right/Calls';
import RightModel from '../../../model/right/Model';
import { FETCH_ROLES } from './Types';

const fetchRoles = () => async (dispatch) => {
  const rightCalls = new RightCalls();
  let roles = await rightCalls.getRoles();
  roles = RightModel.sortRoles(roles);
  dispatch({
    type: FETCH_ROLES,
    payload: roles,
  });
};

export default fetchRoles;
