import React, { Component } from 'react';
import { Container, Grid, Loader } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UiStepGroup, UiButton, UiTable } from 'stoerk-ui-components';
import BreadcrumbNew from '../../commons/breadcrumbNew';
import ConnectSelectRecipes from './components/selectRecipes';
import ConnectSelectDevices from './components/selectDevices';
import {
  fetchRecipesGroup,
  recipesTransmission,
} from '../../../redux/recipes/actions';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import {
  getDevicesFromGroupSelectedByQueryParamSelector,
  getGroups,
  getGroupSelectedByQueryParamSelector,
  isGroupsLoading,
} from '../../../redux/groups/selectors';
import { withPolyglot } from '../../../i18n';
import { withUserRightUtil } from '../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import RightsUserUtil from '../../../util/rights/RightsUserUtil';
import './index.css';
import Polyglot from 'node-polyglot';
import { Device } from '../../../model/device/device.model';
import { RootState } from '../../../redux/store.model';
import { RecipeAPIResponse } from '../../../model/recipes/recipes.model';

const STEP_1 = 'select_recipes';
const STEP_2 = 'select_devices';
const STEP_3 = 'transfer_recipes';

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & ConnectedComponentProps &
  HandlingErrorWrappedProps &
  RouteComponentProps<{ groupId?: string | undefined }>;

type State = {
  showLoadingMessage: boolean;
  showNoRightsMessage: boolean;
  step: string;
  selectedRecipes: RecipeAPIResponse[];

  selectedDevices: Device[];
  stepsRecipesExport: {
    id: string;
    active: boolean;
    completed: boolean;
    description: string;
    title: string;
  }[];
};

/**
 * Recipe manager
 * This class show a list of recipes for a device or a group and allows to export
 * the recipes to the devices
 */
export class RecipeManagerExport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.selectRecipes = this.selectRecipes.bind(this);
    this.selectDevices = this.selectDevices.bind(this);
    this.viewStep1SelectRecipes = this.viewStep1SelectRecipes.bind(this);
    this.viewStep2SelectDevices = this.viewStep2SelectDevices.bind(this);
    this.viewStep3TransferRecipes = this.viewStep3TransferRecipes.bind(this);
    this.step2SelectDevices = this.step2SelectDevices.bind(this);
    this.step3TransferRecipes = this.step3TransferRecipes.bind(this);
    const stepsRecipesExport = [
      {
        id: STEP_1,
        active: true,
        completed: false,
        description: '',
        title: props.polyglot.t('recipe_manager.select_recipes'),
      },
      {
        id: STEP_2,
        active: false,
        completed: false,
        description: '',
        title: props.polyglot.t('recipe_manager.select_devices'),
      },
      {
        id: STEP_3,
        active: false,
        completed: false,
        description: '',
        title: props.polyglot.t('recipe_manager.transfer_recipes'),
      },
    ];
    this.state = {
      showLoadingMessage: true,
      showNoRightsMessage: false,
      step: STEP_1,
      selectedRecipes: [] /* selected recipes */,
      selectedDevices: [],
      stepsRecipesExport,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load data
   * The data to be shown here, it is obtained from the groups structre.
   * if the groups are empty then it will be load again.
   * Get the incidents by device or group
   */
  async loadData() {
    try {
      const { match, rightsUserUtil } = this.props;
      const { groupId } = match.params;
      const { groups, group } = this.props;
      /* get group */
      if (!groups || groups.length === 0) {
        await this.props.fetchGroups();
      }
      const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
        null,
        group?.id
      );
      if (rightsToReadRecipe) {
        await this.props.fetchRecipesGroup(groupId);
      }
      this.setState({
        showLoadingMessage: false,
        showNoRightsMessage: true,
      });
      /* get all the devices belong to this group */
    } catch (error) {
      this.setState({ showLoadingMessage: false });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { history } = this.props;
    history.goBack();
  }

  /**
   * Select recipes
   * @param object recipe
   * @param object data = {checked: true/false}
   */
  selectRecipes(recipe: RecipeAPIResponse, data: { checked: any }) {
    let { selectedRecipes } = this.state;
    if (data.checked) {
      if (selectedRecipes.find((r) => r.id === recipe.id) === undefined) {
        selectedRecipes = [...selectedRecipes, recipe];
      }
    } else {
      selectedRecipes = selectedRecipes.filter((r) => r.id !== recipe.id);
    }

    this.setState({ selectedRecipes });
  }

  /**
   * Select devices
   * @param object device
   * @param object data = {checked: true/false}
   */
  selectDevices(device: Device, data: { checked: any }) {
    let { selectedDevices } = this.state;
    if (data.checked) {
      if (selectedDevices.find((d) => d.uuid === device.uuid) === undefined) {
        selectedDevices.push(device);
      }
    } else {
      selectedDevices = selectedDevices.filter((d) => d.uuid !== device.uuid);
    }

    this.setState({ selectedDevices });
  }

  /**
   * View step 1 select recipes
   */
  viewStep1SelectRecipes() {
    const { recipesGroups, polyglot, group } = this.props;
    const recipesGroup = (group && recipesGroups[group.id]) || [];
    const { selectedRecipes } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column>
            <ConnectSelectRecipes
              recipesGroup={recipesGroup}
              selectRecipes={this.selectRecipes}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="right">
            {selectedRecipes.length > 0 && (
              <UiButton
                showLabels
                label={polyglot.t('button.next')}
                iconName="arrow right"
                onClick={() => this.step2SelectDevices()}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /**
   * Step 2 select devices
   * this function starts the step 2
   */
  step2SelectDevices() {
    const { stepsRecipesExport } = this.state;
    stepsRecipesExport[0].completed = true;
    stepsRecipesExport[1].active = true;
    this.setState({ step: STEP_2, stepsRecipesExport });
  }

  /**
   * View step 2 select devices
   */
  viewStep2SelectDevices() {
    const { selectedDevices } = this.state;
    const { devices, polyglot } = this.props;
    if (devices.length === 0) {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              {polyglot.t('recipe_manager.not_transfer_due_to_no_devices')}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column>
            <ConnectSelectDevices
              devicesGroup={devices}
              selectDevices={this.selectDevices}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {selectedDevices.length > 0 && (
            <Grid.Column textAlign="right">
              <UiButton
                showLabels
                label={polyglot.t('button.transfer_recipes')}
                iconName="arrow right"
                onClick={() => this.step3TransferRecipes()}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }

  /**
   * Step 3 transfer recipes
   * this function starts the step 3
   */
  async step3TransferRecipes() {
    try {
      const { stepsRecipesExport, selectedDevices, selectedRecipes } =
        this.state;
      const { devices, group } = this.props;
      const recipesIds = selectedRecipes.map((r) => r.id);
      const devicesIdsBlackList: string[] = [];

      devices.forEach((device) => {
        if (
          selectedDevices.find((sd) => sd.uuid === device.uuid) === undefined
        ) {
          devicesIdsBlackList.push(device.uuid);
        }
      });
      await this.props.recipesTransmission(
        group?.id || '',
        recipesIds,
        devicesIdsBlackList
      );
      stepsRecipesExport[1].completed = true;
      stepsRecipesExport[2].active = true;
      stepsRecipesExport[2].completed = true;
      this.setState({ step: STEP_3, stepsRecipesExport });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * View step 3 transfer recipes
   */
  viewStep3TransferRecipes() {
    const { polyglot } = this.props;
    const { selectedRecipes, selectedDevices } = this.state;
    const headerDevices = [
      {
        content: polyglot.t('recipe_manager.selected_devices'),
      },
    ];
    let contentDevices: { key: string; columns: { content: any }[] }[] = [];
    if (selectedDevices.length > 0) {
      contentDevices = selectedDevices.map((device, index) => ({
        key: index.toString(),
        columns: [
          {
            content: device.name,
          },
        ],
      }));
    }

    const headerRecipes = [
      {
        content: polyglot.t('recipe_manager.selected_recipes'),
      },
    ];
    let contentRecipes: { key: string; columns: { content: any }[] }[] = [];
    if (selectedRecipes.length > 0) {
      contentRecipes = selectedRecipes.map((recipe, index) => ({
        key: index.toString(),
        columns: [
          {
            content: recipe.name,
          },
        ],
      }));
    }

    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column>
            {polyglot.t('recipe_manager.recipes_transfer_successfully')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <UiTable header={headerRecipes} content={contentRecipes} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <UiTable header={headerDevices} content={contentDevices} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const {
      step,
      stepsRecipesExport,
      showLoadingMessage,
      showNoRightsMessage,
    } = this.state;
    const { loadingGroups, polyglot, history, group, rightsUserUtil } =
      this.props;
    /* the rights to transmit recipe need to be loaded */
    const rightsToTransmitRecipe = rightsUserUtil.hasRightsToTransmitRecipe(
      null,
      group?.id
    );
    const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
      null,
      group?.id
    );
    const sectionsNavigation = [
      {
        content: `${polyglot.t('recipe_manager.title')} ${
          group && group.name !== undefined ? group.name : ''
        }`,
        onClick: () => history.push(`/recipemanager/group/${group?.id}`),
      },
      { content: `${polyglot.t('recipe_manager.transfer_recipes')}` },
    ];

    /* if the user has not rights to transmit the recipe */
    if (
      (!rightsToTransmitRecipe || !rightsToReadRecipe) &&
      showNoRightsMessage
    ) {
      const message = !rightsToReadRecipe
        ? polyglot.t('recipe_manager.no_rights_read_recipes')
        : polyglot.t('recipe_manager.no_rights_trasmit_recipes');
      return (
        <div>
          <BreadcrumbNew sections={sectionsNavigation} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                {message}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <BreadcrumbNew sections={sectionsNavigation} />
        {loadingGroups && showLoadingMessage && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Loader active inline />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Container fluid textAlign="center">
          <UiStepGroup
            id="recipeExport"
            ordered
            steps={
              stepsRecipesExport !== undefined && stepsRecipesExport.length > 0
                ? stepsRecipesExport.slice()
                : []
            }
          />
          {step === STEP_1 && this.viewStep1SelectRecipes()}
          {step === STEP_2 && this.viewStep2SelectDevices()}
          {step === STEP_3 && this.viewStep3TransferRecipes()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ groupId?: string | undefined }>
) => ({
  recipesGroups: state.recipes.recipesGroups as Record<
    string,
    RecipeAPIResponse[]
  >,
  loadingRecipesGroup: state.recipes.loadingRecipesGroup,
  devices: getDevicesFromGroupSelectedByQueryParamSelector(state, props),
  group: getGroupSelectedByQueryParamSelector(state, props),
  groups: getGroups(state),
  loadingGroups: isGroupsLoading(state),
});

const connector = connect(mapStateToProps, {
  fetchRecipesGroup,
  fetchGroups: warmUpGroups,
  recipesTransmission,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(withUserRightUtil(RecipeManagerExport))))
);
