/**
 * Code calls
 */
export const getUserDataById = 'user/getUserDataById';
export const getUserInformation = 'user/getUserInformation';
export const updateUserData = 'user/updateUserData';
export const saveUserAvatar = 'user/saveUserAvatar';
export const resetAvatar = 'user/resetAvatar';
export const getAvailableUsersToAssignGroup =
  'user/getAvailableUsersToAssignGroup';
