import { createAsyncThunk } from '@reduxjs/toolkit';
import CUParametersCalls from '../../../model/CUParameters/CUParametersCalls';
import { ErrorRestST } from '../../../rest/rest.model';
import { nameReducer } from '../CUParameters.model';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchCUParameters = createAsyncThunk(
  `${nameReducer}/fetchCUParameters`,
  async (props: { deviceId: string; controlUnitId: number | string }) => {
    const { controlUnitId, deviceId } = props;
    const cUParametersCalls = new CUParametersCalls();

    return await cUParametersCalls.getCUParametersFromCU(
      deviceId,
      controlUnitId
    );
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const setCUParameters = createAsyncThunk(
  `${nameReducer}/setCUParameters`,
  async (props: {
    deviceId: string;
    controlUnitId: number | string;
    parameterLevelName: string;
    parameterName: string;
    parameterValue: string;
  }) => {
    const {
      controlUnitId,
      deviceId,
      parameterLevelName,
      parameterName,
      parameterValue,
    } = props;
    const cUParametersCalls = new CUParametersCalls();

    await cUParametersCalls.setCUParameters(
      deviceId,
      controlUnitId,
      parameterLevelName,
      parameterName,
      parameterValue
    );
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);
