import OneSignal from 'react-onesignal';
import { getConfigurationByDomain } from './getConfigurationByDomain';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore add OneSignalObj to debug
window.OneSignalObj = OneSignal || [];

export async function initOneSignal() {
  return OneSignal.init(getConfigurationByDomain());
}

export async function loadSessionOneSignal(userId: string) {
  await OneSignal.showSlidedownPrompt();
  return OneSignal.setExternalUserId(userId);
}
