import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'semantic-ui-react';
import './index.css';

// eslint-disable-next-line react/prefer-stateless-function
export default class BreadcrumbNew extends React.PureComponent {
  render() {
    const { sections } = this.props;
    const sectionsBreadcrumb = [];
    sections.forEach((s, index) => {
      const newSection = { key: s.key ? s.key : index, content: s.content };
      if (index === sections.length - 1) {
        Object.assign(newSection, { active: true });
      } else if (s.href) {
        Object.assign(newSection, { href: s.href });
      } else if (s.onClick) {
        Object.assign(newSection, { onClick: s.onClick });
      }
      sectionsBreadcrumb.push(newSection);
    });
    return (
      <Breadcrumb
        className="navigation-breadcrumb-new"
        icon="right angle"
        sections={sectionsBreadcrumb}
      />
    );
  }
}

BreadcrumbNew.propTypes = {
  sections: PropTypes.arrayOf(Object).isRequired,
};
