import { ComponentType } from 'react';
import AppAlanNuttall from '../customized/alan-nuttall-flexeserve-eu/App/App';
import AppFoursFringand from '../customized/fours-fringand-fr/App/App';
import AppHengel from '../customized/hengel-eu/App/App';
import AppOFC from '../customized/ofc-serenity-eu/App/App';
import { ToggleComponent } from '../featureToggle';
import AppDefault from './App';

const App: ComponentType<{}> = ToggleComponent(AppDefault, {
  StyledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': AppAlanNuttall,
    'ofc-serenity-eu': AppOFC,
    'hengel-eu': AppHengel,
    'fours-fringand-fr': AppFoursFringand,
  },
});

export default App;
