import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Image, Menu, Header } from 'semantic-ui-react';

import BrowserUtil from '../../util/BrowserUtil';
import imagePageNotFound from '../../assets/PageNotFoundCloud.gif';
import imagePageNotFoundMobile from '../../assets/PageNotFoundCloudMobile.gif';
import imagePageNotFoundMobileLandscape from '../../assets/PageNotFoundCloudMobileLandscape.gif';
import { withPolyglot } from '../../i18n';
import './index.css';

const browserUtil = new BrowserUtil();
let isPhone = browserUtil.getIsPhone();
let isTablet = browserUtil.getIsTablet();

class NotFound extends Component {
  static selectBackgroud() {
    /* Ratio screen size: this variable has the relation between the width and the
     * height of the screen. Phone and monitor (16:9 = 56.25), Tablet (3:4 = 75)
     */
    let ratioScreenSize = 0;
    if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
    } else {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterWidth() * 100) / browserUtil.getOuterHeight(),
        0
      );
    }

    if (
      isTablet &&
      ratioScreenSize < browserUtil.defaultRationScreenSizeTablet
    ) {
      isPhone = true;
      isTablet = false;
    }
    let imageBackground = '';

    if (isPhone) {
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imageBackground = imagePageNotFoundMobileLandscape;
      } else {
        imageBackground = imagePageNotFoundMobile;
      }
    } else if (isTablet) {
      if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
        imageBackground = imagePageNotFound;
      } else {
        imageBackground = imagePageNotFoundMobile;
      }
    } else {
      imageBackground = imagePageNotFound;
    }
    return imageBackground;
  }

  render() {
    const { polyglot } = this.props;
    return (
      <Container fluid textAlign="center" className="wrapper-no-found-page">
        <Image src={NotFound.selectBackgroud()} fluid />
        <div className="info-page-not-found">
          <Header as="h3">{polyglot.t('not_found.title')}</Header>
          <Menu borderless compact className="menu-page-not-found">
            <Menu.Item
              name={polyglot.t('not_found.menu.home')}
              href="/landingPage"
              link
            />
            <Menu.Item
              name={polyglot.t('not_found.menu.login')}
              href="/login"
              link
            />
            <Menu.Item
              name={polyglot.t('not_found.menu.register')}
              href="/signup"
              link
            />
          </Menu>
        </div>
      </Container>
    );
  }
}

NotFound.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(NotFound);
