import { restClient as restClientHttp } from '../../rest';
import { RestClientFacade } from '../../rest/rest.model';
import Util from '../../util/Util';

import * as callTypes from './ModuleCallTypes';

export class ModuleCalls {
  readonly workSpaceManagementServiceURL =
    new Util().getWorkSpaceManagementServiceURL();
  constructor(private restClient: RestClientFacade = restClientHttp) {}

  getModule(moduleName: string): Promise<boolean> {
    const url = `${this.workSpaceManagementServiceURL}/module/assigned/${moduleName}`;
    return this.restClient
      .callPromise<Record<string, boolean>>(
        {
          url,
          method: 'GET',
        },
        callTypes.getModule
      )
      .then((data) => data[moduleName]);
  }
}
