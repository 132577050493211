import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { withSnackbar } from 'stoerk-ui-components';
import { deleteGroupDevice } from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import { withHandlingErrors } from '../../../../../handlingErrors';
import { getGroups } from '../../../../../redux/groups/selectors';
import { getNumDevices } from '../../../../../redux/devices/selectors';
import GroupModel from '../../../../../model/group/Model';
import { STModal } from '../../../../commons/Modal';

/**
 * Remove group device
 * this function remove a device from the group
 */
export class RemoveGroupDevice extends Component {
  constructor(props) {
    super(props);
    this.removeGroupDevice = this.removeGroupDevice.bind(this);
  }

  /**
   * Remove group device
   * this funcion call the backend to remove a device from a group
   */
  async removeGroupDevice() {
    const {
      groupId,
      deviceId,
      polyglot,
      openSnackbar,
      handlingErrorsApi,
      closeRemoveGroupDevice,
      closeEditDevice,
    } = this.props;
    try {
      await this.props.deleteGroupDevice(groupId, deviceId);
      const message = {
        text: polyglot.t('device.remove_successful_message'),
        type: 'ok',
      };
      openSnackbar(message);
      closeRemoveGroupDevice();
      closeEditDevice();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  render() {
    const {
      showRemoveGroupDevice,
      polyglot,
      closeRemoveGroupDevice,
      groups,
      groupId,
    } = this.props;
    const group = GroupModel.getGroupByGroupId(groupId, groups);
    const dialogText = polyglot
      .t('device.remove_group_device_dialog_text')
      .replace('#text', group ? `"${group.name}"` : '');
    return (
      <STModal
        open={showRemoveGroupDevice}
        onClose={closeRemoveGroupDevice}
        title={polyglot.t('device.remove_dialog_title')}
        buttonActions={
          <>
            <Button onClick={closeRemoveGroupDevice}>
              {polyglot.t('device.cancel_button_title')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={this.removeGroupDevice}
            >
              {polyglot.t('device.remove_button_title')}
            </Button>
          </>
        }
      >
        <p>{dialogText}</p>
      </STModal>
    );
  }
}

RemoveGroupDevice.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  deviceId: PropTypes.string,
  showRemoveGroupDevice: PropTypes.bool.isRequired,
  closeRemoveGroupDevice: PropTypes.func.isRequired,
  closeEditDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteGroupDevice: PropTypes.func.isRequired,
};

RemoveGroupDevice.defaultProps = {
  deviceId: '',
  groupId: null,
};

const mapStateToProps = (state) => ({
  totalDevices: getNumDevices(state),
  groups: getGroups(state),
});

export default connect(mapStateToProps, { deleteGroupDevice })(
  withHandlingErrors(withSnackbar(withPolyglot(RemoveGroupDevice)))
);
