/* eslint-disable prefer-const */
import React, { useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { withPolyglot, PolyglotComponentProps } from '../../../../i18n';
import ElevationScroll from '../ElevationScroll';

interface STDialogProps extends PolyglotComponentProps, DialogProps {
  open: boolean;
  title?: string;

  onClose?: () => unknown;
  onSave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
  disableSave?: boolean;
  buttonActions?: JSX.Element;
  children: React.ReactNode;
}

export function STDialog(props: STDialogProps) {
  let {
    polyglot,
    title,
    onClose,
    onSave,
    buttonActions,
    disableSave,
    children,
    ...dialogProps
  } = props;

  if (!buttonActions)
    buttonActions = (
      <>
        <Button type="button" onClick={onClose}>
          {polyglot.t('button.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onSave}
          disabled={disableSave}
        >
          {polyglot.t('button.save')}
        </Button>
      </>
    );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let contentRef = useRef(null);

  return (
    <Dialog
      onClose={onClose}
      fullScreen={fullScreen}
      {...dialogProps}
      aria-labelledby="form-dialog-title"
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar position="static" variant="elevation">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
      <DialogActions>{buttonActions}</DialogActions>
    </Dialog>
  );
}

export default withPolyglot(STDialog);
