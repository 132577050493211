import { createSelector } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import { getControlUnitSelectedByQueryParamSelector } from '../../../../../../../../../../redux/controlUnit/selectors';
import { getDeviceSelectedName } from '../../../../../../../../../../redux/devices/selectors';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../../../../../../../../redux/groups/selectors';
import { RootState } from '../../../../../../../../../../redux/store.model';
import {
  getLastPath,
  navigationPath,
  NavigationPathProps,
} from '../../../../../../../../navigations/navigationPath';

const dicTitles: Record<string, string> = {
  events: 'device.events.events',
  parameters: 'device.parameters.title',
  history: 'device.monitoring.title',
};

const getProps = (
  _: RootState,
  props: NavigationPathProps &
    RouteComponentProps<{
      groupId?: string;
      controlUnitId: string;
      deviceId: string;
    }>
) => props;

export const getDeviceSectionsNavigationSelector = createSelector(
  [
    getProps,
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDeviceSelectedName,
    getControlUnitSelectedByQueryParamSelector,
  ],
  (props, groups, group, deviceName, controlUnit) => {
    const {
      polyglot,
      history,
      location: { pathname },
      match: {
        params: { controlUnitId, deviceId, groupId },
      },
    } = props;

    /** Commander */
    const navigators = navigationPath(props, groupId, groups, group).concat([
      {
        content: deviceName || '...',
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/`;
          }
          history.push(url);
        },
      },
    ]);

    /** ControlUnit */
    if (controlUnitId) {
      navigators.push({
        content: controlUnit?.name ?? '...',
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}`;
          }
          history.push(url);
        },
      });
    }

    /** module selected */
    const moduleSelected = getLastPath(pathname);

    if (
      moduleSelected &&
      Object.keys(dicTitles).includes(moduleSelected ?? '')
    ) {
      navigators.push({
        content: polyglot.t(dicTitles[moduleSelected] ?? ''),
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/${moduleSelected}`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/${moduleSelected}`;
          }
          history.push(url);
        },
      });
    }
    return navigators;
  }
);
