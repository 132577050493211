import {
  INCIDENT_CATEGORIES_TYPES,
  INCIDENT_STATUS_TYPES,
} from '../../model/incidentManager/incident.model';

export const INCIDENT_STATUS_NEW = 'NEW';
export const INCIDENT_STATUS_INPROGRESS = 'INPROGRESS';
export const INCIDENT_STATUS_RESOLVED = 'RESOLVED';

export const INCIDENT_PRIORITY_LOW = 'LOW';
export const INCIDENT_PRIORITY_MEDIUM = 'MEDIUM';
export const INCIDENT_PRIORITY_HIGH = 'HIGH';

export const INCIDENT_CATEGORY_MAINTENANCE = 'MAINTENANCE';
export const INCIDENT_CATEGORY_ALARM = 'ALARM';

export const ICON_PRIORITY = {
  [INCIDENT_PRIORITY_LOW]: {
    icon: 'long arrow alternate down',
    polyglot: 'low',
    color: '#E67E22',
  },
  [INCIDENT_PRIORITY_MEDIUM]: {
    icon: 'long arrow alternate down',
    polyglot: 'medium',
    color: '#E74C3C',
  },
  [INCIDENT_PRIORITY_HIGH]: {
    icon: 'long arrow alternate down',
    polyglot: 'high',
    color: '#bd0000',
  },
};

export const INCIDENT_STATUS = {
  [INCIDENT_STATUS_TYPES.NEW]: { polyglot: 'new' },
  [INCIDENT_STATUS_TYPES.INPROGRESS]: { polyglot: 'inprogress' },
  [INCIDENT_STATUS_TYPES.RESOLVED]: { polyglot: 'resolved' },
};

export const INCIDENT_CATEGORY = {
  [INCIDENT_CATEGORIES_TYPES.MAINTENANCE]: { polyglot: 'maintenance' },
  [INCIDENT_CATEGORIES_TYPES.ALARM]: { polyglot: 'alarm' },
};
