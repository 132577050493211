/**
 * Code calls
 */
export const callTypeRemoveAssociatedDevice = 'REMOVE_ASSOCIATED_DEVICE';
export const callTypeGetCommanders = 'GET_COMMANDERS';
export const callTypeGetCommanderById = 'GET_COMMANDER_BY_ID';
export const callTypeGetControlUnitsByDeviceId =
  'GET_CONTROL_UNITS_BY_DEVICE_ID';
export const callTypeSaveUserAvatar = 'SAVE_USER_AVATAR';
export const callTypeUpdateGroup = 'UPDATE_GROUP';
export const callTypeDeleteGroup = 'DELETE_GROUP';
export const callTypeSaveGroupAvatar = 'SAVE_GROUP_AVATAR';
export const callTypeDeleteRoleGroupUser = 'DELETE_ROLE_GROUP_USER';
export const callTypeAddUserRights = 'ADD_USER_RIGHTS';
export const callTypeDeleteUserRights = 'DELETE_USER_RIGHTS';
export const callTypeGetAvailableUsersToAssignGroup =
  'GET_AVAILABLE_USERS_TO_ASSIGN_GROUP';
export const callTypeAddAttachmentIncident = 'ADD_ATTACHMENT_INCIDENT';
export const callTypeGetAttachmentIncidentContent =
  'GET_ATTACHMENT_INCIDENT_CONTENT';
export const callTypeGetIntegrationRegistry = 'GET_INTEGRATION_REGISTRY';
export const callTypeSetMaintenanceMessageDevice =
  'SET_MAINTENANCE_MESSAGE_DEVICE';
export const callTypeSetMaintenanceMessageGroup =
  'SET_MAINTENANCE_MESSAGE_GROUP';
export const callTypeSetMaintenanceMessageVendor =
  'SET_MAINTENANCE_MESSAGE_VENDOR';
export const callTypeUpdataMaintenanceMessageDevice =
  'UPDATE_MAINTENANCE_MESSAGE_DEVICE';
export const callTypeUpdataMaintenanceMessageGroup =
  'UPDATE_MAINTENANCE_MESSAGE_GROUP';
export const callTypeUpdataMaintenanceMessageVendor =
  'UPDATE_MAINTENANCE_MESSAGE_VENDOR';
export const callTypeGetMaintenanceMessagesDevice =
  'GET_MAINTENANCE_MESSAGE_DEVICE';
export const callTypeGetMaintenanceMessagesGroup =
  'GET_MAINTENANCE_MESSAGE_GROUP';
export const callTypeGetMaintenanceMessagesVendor =
  'GET_MAINTENANCE_MESSAGE_VENDOR';
export const callTypeDeleteMaintenanceMessageDevice =
  'DELETE_MAINTENANCE_MESSAGE_DEVICE';
export const callTypeDeleteMaintenanceMessageGroup =
  'DELETE_MAINTENANCE_MESSAGE_GROUP';
export const callTypeDeleteMaintenanceMessageVendor =
  'DELETE_MAINTENANCE_MESSAGE_VENDOR';
export const callTypeGetCameras = 'GET_CAMERAS';
export const callTypeGetPairableCameras = 'GET_PAIRABLE_CAMERAS';
export const callTypePairCamera = 'PAIR_CAMERA';
export const callTypeSetRecipeGroup = 'SET_RECIPE_GROUP';
export const callTypeGetRecipesGroup = 'GET_RECIPES_GROUP';
export const callTypeGetRecipesUser = 'GET_RECIPES_USER';
export const callTypeRecipesTransmission = 'RECIPES_TRANSMISSIONS';
export const callTypeGetDevicesCoordinates = 'GET_DEVICES_COORDINATES';
export const callTypeResetAvatar = 'RESET_AVATAR';
