import { Box } from '@mui/material';
import React from 'react';
import { ConnectedMonitoringScreen } from '../../../Monitoring/ConnectedMonitoringScreen';

const ConnectedMonitoringScreenRouter = ConnectedMonitoringScreen;
export function DeviceMonitoringPage() {
  return (
    <Box sx={{ mb: 2 }}>
      <ConnectedMonitoringScreenRouter />
    </Box>
  );
}
