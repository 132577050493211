export const getGroups = 'group/getGroups';
export const getGroup = 'group/getGroup';
export const deleteGroupDevice = 'group/deleteGroupDevice';
export const deleteGroupUser = 'group/deleteGroupUser';
export const createGroup = 'group/createGroup';
export const saveAvatar = 'group/saveAvatar';
export const updateGroup = 'group/updateGroup';
export const putGroupUsers = 'group/putGroupUsers';
export const getUsersByGroupId = 'group/getUsersByGroupId';
export const putGroupAddDevices = 'group/putGroupAddDevices';
export const deleteGroup = 'group/deleteGroup';
export const deleteGroupIcon = 'group/deleteGroupIcon';
